import React, {useContext, useEffect, useState} from 'react';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import {Alert, Grid} from '@mui/material';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCardFilialeWoche';
import {FilterContext} from "../../../provider/FilterProvider";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigChartCard from "../../../components/card/BigChartCard";
import {getAlertSeverity} from "../../../components/utility/AlertPopup";
import TAPerformanceKunden from "../../../components/scorecards/tiefenanalyse/TAPerformanceKunden";
import {tausenderTrennung} from "../../../utils/mathStuff";
import PercentIcon from '@mui/icons-material/Percent';
import StarIcon from '@mui/icons-material/Star';


const Kundenanzahl = () => {
  const [comparisonCardData, setComparisonCardData] = useState([]);
  const [dataCardData, setDataCardData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const {filterState} = useContext(FilterContext);
  const activeCategory = 'Kundenanzahl';

  useEffect(() => {
    if (filterState.filiale && filterState.datum) {
      fetchData();
    }
  }, [filterState.filiale, filterState.datum]);

  const fetchData = async () => {
    try {
      // Assuming filterState.datum.value is in ISO week format (e.g., '2024-W50')
      const endDate = dayjs(filterState.datum?.value, 'GGGG-[W]WW'); // ISO week format
      const startDate = endDate.subtract(12, 'week'); // Last 12 weeks

      const response = await axiosInstance.get('scorecard_week/performance-woche/', { // Changed 'monat' to 'woche'
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const responseUnternehmen = await axiosInstance.get('scorecard_week/performance-unternehmen-woche/', { // Changed 'monat' to 'woche'
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD')
        }
      });

      console.log("branchResponse", response);
      console.log("companyResponse", responseUnternehmen);

      // Map branch and company data
      const branchData = response.data.results.map(item => ({
        x: item.datum,
        Filiale: item.kundenanzahl
      }));

      const companyData = responseUnternehmen.data.results.map(item => ({
        x: item.datum,
        Unternehmen: item.kundenanzahl
      }));

      // Extract all unique x-values
      const allXValues = Array.from(new Set([...branchData.map(d => d.x), ...companyData.map(d => d.x)]))
          .sort((a, b) => dayjs(a, 'GGGG-[W]WW').isAfter(dayjs(b, 'GGGG-[W]WW')) ? 1 : -1);

      // Merge data based on x-values
      const mergedData = allXValues.map(x => {
        const branch = branchData.find(d => d.x === x);
        const company = companyData.find(d => d.x === x);
        return {
          x,
          Filiale: branch ? branch.Filiale : null,        // Use null or 0 if missing
          Unternehmen: company ? company.Unternehmen : null
        };
      });

      // Log to verify
      console.log("All X Values:", allXValues);
      console.log("Merged Data:", mergedData);

      // Prepare DataCard Data
      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Umsatz: item.scoreUmsatz,
            Kundenanzahl: item.scoreKundenanzahl,
            Kundenbon: item.scoreKundenbon,
            Leistung: item.scoreLeistung,
          }));

      // Prepare ComparisonCard Data
      const additionalComparisonCardData = responseUnternehmen.data.results
          .slice(-1)
          .map(item => ({
            gesamtKundenanzahl: item.kundenanzahl,
            gesamtKundenanzahlWachstumW: item.kundenanzahlWachstumW, // Changed from 6M to 6W
            scoreKundenanzahlWachstumW: item.scoreKundenanzahlWachstumW, // New field
            gesamtKundenanzahlWachstumJ: item.kundenanzahlWachstumJ,
            scoreKundenanzahlWachstumJ: item.scoreKundenanzahlWachstumJ,
          }));

      console.log("additionalComparisonCardData", additionalComparisonCardData);

      const baseComparisonCardData = response.data.results.slice(-1).map(item => ({
        ...item,
        kundenanzahl: item.kundenanzahl, // Ensure kundenanzahl is included
      }));

      const comparisonCardData = [...baseComparisonCardData, ...additionalComparisonCardData];

      console.log("comparisonCardData", comparisonCardData);

      // Update state
      setComparisonCardData(comparisonCardData);
      setDataCardData(dataCardData);
      setLineChartData(mergedData); // Use mergedData
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  return (
      <FilialeWocheLayout>
        <Grid container justifyContent="flex-end" alignItems="stretch">
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{overflowX: 'auto'}}>
              {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                  <Grid item xs={12} md={6} lg={3} key={index}
                        className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}>
                    <DataCard
                        data={dataCardData}
                        category={category}
                        sector={"performance"}
                        categoryData={activeCategory === category ? `${comparisonCardData.length > 0 ? tausenderTrennung(comparisonCardData[0].kundenanzahl) : null} ` : ''}
                    />
                  </Grid>
              ))}
            </Stack>
          </Grid>

          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} mt={2} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
              <Alert
                  severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreKundenanzahl"] : null)}
              >
                {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackKundenanzahl"] : 'Keine Rückmeldung verfügbar'}
              </Alert>

              {/* Modified the first ComparisonCard to include additional fields */}
              <ComparisonCard
                  zeitraum="Vorjahr"
                  data={comparisonCardData}
                  scoreField="scoreKundenanzahl"
                  percentageField="kundenanzahlWachstumJ"
                  rangField="rangKundenanzahlWachstumJ"
                  extraFields={[
                    {
                      valueField: 'kundenanzahl',
                      icon: <StarIcon sx={{color: 'primary.main'}}/>,
                      label: 'Kundenanzahl'
                    },
                    {
                      valueField: 'scoreKundenanzahlWachstumJ',
                      icon: <PercentIcon sx={{color: 'secondary.main'}}/>,
                      label: 'Score Wachstum J'
                    },
                    {
                      valueField: 'kundenanzahlWachstumJ',
                      icon: <PercentIcon sx={{color: 'secondary.main'}}/>,
                      label: 'Wachstum J'
                    },
                    {
                      valueField: 'scoreKundenanzahlWachstum6W',
                      icon: <PercentIcon sx={{color: 'secondary.main'}}/>,
                      label: 'Score Wachstum 6W'
                    },
                    {
                      valueField: 'kundenanzahlWachstum6W',
                      icon: <PercentIcon sx={{color: 'secondary.main'}}/>,
                      label: 'Wachstum 6W'
                    },
                  ]}
                  TiefenanalyseComponent={TAPerformanceKunden}
              />

              {/* Additional ComparisonCards */}
              <ComparisonCard
                  zeitraum="∅ der letzten 6 Wochen"
                  data={comparisonCardData}
                  scoreField="scoreKundenanzahlWachstum6W"
                  percentageField="kundenanzahlWachstum6W"
                  rangField="rangKundenanzahlWachstum6W"
                  extraFields={[
                    // Add fields specific to this comparison if needed
                  ]}
                  TiefenanalyseComponent={TAPerformanceKunden}
              />
              <ComparisonCard
                  zeitraum="Vormonat"
                  data={comparisonCardData}
                  scoreField="scoreKundenanzahlWachstumW"
                  percentageField="kundenanzahlWachstumW"
                  rangField="rangKundenanzahlWachstumW"
                  extraFields={[
                    // Add fields specific to this comparison if needed
                  ]}
                  TiefenanalyseComponent={TAPerformanceKunden}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <BigChartCard
                  data={lineChartData}
                  title={"Kundenentwicklung Unternehmen und Filiale"}
                  infoContent={
                    <>
                      <p>Kundenverlauf - Informationen</p>
                      <p>Dieses Diagramm zeigt den Verlauf der Kunden deiner ausgewählten Filiale im Vergleich zum gesamten Unternehmen.</p>
                      <p><strong>Filiale:</strong> Die dunklere Linie zeigt die Kundenentwicklung deiner Filiale über die Zeit.</p>
                      <p><strong>Unternehmen:</strong> Die hellere Linie repräsentiert die Kundenentwicklung des gesamten Unternehmens.</p>
                      <p>So kannst du Trends und Unterschiede zwischen deiner Filiale und dem Unternehmen erkennen.</p>
                    </>
                  }
              />
            </Grid>
          </Grid>
        </Grid>
        <ScorecardsNotes category="Performance"/>
      </FilialeWocheLayout>
  );
};

export default Kundenanzahl;
