// src/pages/unternehmenwoche/performance/umsatz.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack, CircularProgress, Typography, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/UnternehmenWocheLayout';
import DataCard from '../../../components/card/scorecardsmonat/DataCardWochePage';
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche'; // Import der Card-Komponente
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import FilialePopup from '../../../components/popup/unternehmenWoche/FilialePopup';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import { tausenderTrennung } from '../../../utils/mathStuff';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/de'; // Importiert die deutsche Lokale
import { useTheme } from '@mui/material'; // Hinzugefügt

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.locale('de'); // Setzt die Lokale auf Deutsch

// Definition der Spalten für die Haupttabelle (VariableTreeMUI)
const mainTableColumns = [
    { field: 'verkaufsgebiet', headerName: 'Verkaufsgebiet', align: 'left' },
    { field: 'filiale', headerName: 'Filiale', align: 'left' },
    {
        field: 'score',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? tausenderTrennung(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'umsatz',
        headerName: 'Umsatz',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)} €` : '',
        hide: false,
    },
    {
        field: 'umsatzWachstum6W',
        headerName: '∅ 6W',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)}%` : '',
        hide: false,
    },
    {
        field: 'umsatzWachstumVJ',
        headerName: 'VJ',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)}%` : '',
        hide: false,
    },
];

// Definition der Spalten für die Popup-Tabelle (BigTableCard)
const popupTableColumns = [
    { accessorKey: 'datum', header: 'Datum', align: 'left' },
    {
        accessorKey: 'umsatz',
        header: 'Umsatz',
        align: 'right',
        valueFormatter: (value) =>
            value !== null && value !== undefined ? `${tausenderTrennung(value, 2)} €` : '',
    },
    {
        accessorKey: 'umsatzVJ',
        header: 'Umsatz Vorjahr',
        align: 'right',
        valueFormatter: (value) =>
            value !== null && value !== undefined ? `${tausenderTrennung(value, 2)} €` : '',
    },
    {
        accessorKey: 'vj',
        header: 'VJ%',
        align: 'right',
        valueFormatter: (value) => {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00%';
            return `${number.toFixed(2).replace('.', ',')}%`;
        },
    },
];

// Aggregationsfunktionen für die Haupttabelle
const aggregationFunctions = {
    score: (items) => {
        const totalScore = items.reduce((sum, item) => sum + (item.score || 0), 0);
        const count = items.length;
        return count > 0 ? parseFloat((totalScore / count).toFixed(2)) : 0.0;
    },
    umsatz: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        return totalUmsatz;
    },
    umsatzWachstum6W: (items) => {
        const validItems = items.filter(
            (item) => item.umsatzWachstum6W !== undefined && item.umsatzWachstum6W !== null
        );
        const totalWachstum6W = validItems.reduce((sum, item) => sum + (parseFloat(item.umsatzWachstum6W) || 0), 0);
        const count = validItems.length;
        return count > 0 ? parseFloat((totalWachstum6W / count).toFixed(2)) : 0.0;
    },
    umsatzWachstumVJ: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        const totalUmsatzVJ = items.reduce((sum, item) => sum + (item.umsatzVJ || 0), 0);
        return totalUmsatzVJ > 0 ? parseFloat(((totalUmsatz / totalUmsatzVJ - 1) * 100).toFixed(2)) : 0.0;
    },
};

const Umsatz = () => {
    const { filterState } = useContext(FilterContext);
    const theme = useTheme(); // Hinzugefügt
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        kundenanzahl: 0,
        kundenbon: 0,
        leistung: 0,
    });
    const [growthData, setGrowthData] = useState({
        umsatzWachstumJ: '0.00',
        kundenanzahlWachstumJ: '0.00',
        kundenbonWachstum: '0.00',
        leistungsWachstum: '0.00',
    });
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupChartData, setPopupChartData] = useState([]);
    const [popupTableData, setPopupTableData] = useState([]);
    const [popupLines, setPopupLines] = useState([]); // Neues State für Popup-Charts
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (filterState.datumWoche) {
            fetchData();
            fetchChartData();
        }
    }, [filterState.datumWoche, filterState.bestehendeFlaeche]); // Aktualisierte Dependency-Liste

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            await Promise.all([fetchPerformanceData(), fetchTableData()]);
        } catch (err) {
            setError(true);
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    // Funktion zur Aggregation der aktuellen Woche und Berechnung der Wachstumsraten
    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const aggregatedData = response.data.results.reduce(
                (acc, item) => {
                    acc.umsatz += item.umsatz || 0;
                    acc.kundenanzahl += item.kundenanzahl || 0;
                    acc.produktivstunden += item.produktivstunden || 0;
                    acc.umsatzVJ += item.umsatzVJ || 0;
                    acc.kundenanzahlVJ += item.kundenanzahlVJ ||

 0;
                    acc.produktivstundenVJ += item.produktivstundenVJ || 0;
                    return acc;
                },
                {
                    umsatz: 0,
                    kundenanzahl: 0,
                    produktivstunden: 0,
                    umsatzVJ: 0,
                    kundenanzahlVJ: 0,
                    produktivstundenVJ: 0,
                }
            );

            // Berechnung von Kundenbon und Leistung
            const kundenbon =
                aggregatedData.kundenanzahl > 0 ? aggregatedData.umsatz / aggregatedData.kundenanzahl : 0;
            const leistung =
                aggregatedData.produktivstunden > 0
                    ? aggregatedData.umsatz / aggregatedData.produktivstunden
                    : 0;

            // Berechnung der Wachstumsraten basierend auf VJ-Feldern
            const umsatzWachstumJ =
                aggregatedData.umsatzVJ > 0
                    ? ((aggregatedData.umsatz / aggregatedData.umsatzVJ - 1) * 100).toFixed(2)
                    : '0.00';
            const kundenanzahlWachstumJ =
                aggregatedData.kundenanzahlVJ > 0
                    ? ((aggregatedData.kundenanzahl / aggregatedData.kundenanzahlVJ - 1) * 100).toFixed(2)
                    : '0.00';
            const kundenbonVJ =
                aggregatedData.kundenanzahlVJ > 0
                    ? aggregatedData.umsatzVJ / aggregatedData.kundenanzahlVJ
                    : 0;
            const kundenbonWachstum =
                kundenbonVJ > 0 ? ((kundenbon / kundenbonVJ - 1) * 100).toFixed(2) : '0.00';
            const leistungVJ =
                aggregatedData.produktivstundenVJ > 0
                    ? aggregatedData.umsatzVJ / aggregatedData.produktivstundenVJ
                    : 0;
            const leistungsWachstum =
                leistungVJ > 0 ? ((leistung / leistungVJ - 1) * 100).toFixed(2) : '0.00';

            setPerformanceData({
                umsatz: tausenderTrennung(aggregatedData.umsatz),
                kundenanzahl: tausenderTrennung(aggregatedData.kundenanzahl),
                kundenbon: tausenderTrennung(kundenbon, 2),
                leistung: tausenderTrennung(leistung, 2),
            });

            setGrowthData({
                umsatzWachstumJ: umsatzWachstumJ,
                kundenanzahlWachstumJ: kundenanzahlWachstumJ,
                kundenbonWachstum: kundenbonWachstum,
                leistungsWachstum: leistungsWachstum,
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
            throw error;
        }
    };

    // Funktion zur Aggregation der Tabellendaten
    const fetchTableData = async () => {
        try {
            // Aktuelle Woche
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);

            // Letzte 6 Wochen
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            // Daten der letzten 6 Wochen abrufen
            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            // Aggregation der Daten pro Filiale über die letzten 6 Wochen
            const dataPerFiliale = {};

            responses.forEach((response) => {
                response.data.results.forEach((item) => {
                    const filiale = item.filiale;
                    if (!dataPerFiliale[filiale]) {
                        dataPerFiliale[filiale] = {
                            umsatz: 0,
                            umsatzVJ: 0,
                        };
                    }
                    dataPerFiliale[filiale].umsatz += item.umsatz || 0;
                    dataPerFiliale[filiale].umsatzVJ += item.umsatzVJ || 0;
                });
            });

            // Daten der aktuellen Woche abrufen
            const currentWeekResponse = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const formattedData = currentWeekResponse.data.results
                .filter((item) => item.kundenanzahl > 0 || item.scoreUmsatz > 0)
                .map((item, index) => {
                    const filiale = item.filiale;
                    const data6W = dataPerFiliale[filiale] || {
                        umsatz: 0,
                        umsatzVJ: 0,
                    };
                    const umsatzWachstum6W =
                        data6W.umsatzVJ > 0
                            ? ((data6W.umsatz / data6W.umsatzVJ - 1) * 100).toFixed(2)
                            : '0.00';

                    return {
                        id: index, // Eindeutige ID hinzufügen
                        verkaufsgebiet: item.verkaufsgebiet || 'Unbekannt',
                        filiale: item.filiale,
                        score: item.scoreUmsatz || 0,
                        umsatz: item.umsatz || 0,
                        umsatzVJ: item.umsatzVJ || 0,
                        umsatzWachstum6W: umsatzWachstum6W,
                        umsatzWachstumVJ:
                            item.umsatzVJ > 0
                                ? ((item.umsatz / item.umsatzVJ - 1) * 100).toFixed(2)
                                : '0.00',
                    };
                });

            setTableData(formattedData);
        } catch (error) {
            console.error('Error fetching table data:', error);
            throw error;
        }
    };

    // Funktion zur Aggregation der Chartdaten bleibt unverändert
    const fetchChartData = async () => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const chartDataArr = responses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        acc.umsatz += item.umsatz || 0;
                        acc.umsatzVJ += item.umsatzVJ || 0;
                        return acc;
                    },
                    { umsatz: 0, umsatzVJ: 0 }
                );

                return {
                    x: week,
                    Umsatz: aggregatedData.umsatz,
                    'Umsatz Vorjahr': aggregatedData.umsatzVJ,
                };
            });

            setChartData(chartDataArr);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    // Handler für das Klicken auf eine Tabellenzeile bleibt unverändert
    const handleRowClick = async (row) => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);

            const weekStart = currentWeek.startOf('isoWeek');
            const weekEnd = currentWeek.endOf('isoWeek');

            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const chartResponses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            filiale: row.filiale,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const popupChartDataArr = chartResponses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        acc.umsatz += item.umsatz || 0;
                        acc.umsatzVJ += item.umsatzVJ || 0;
                        return acc;
                    },
                    { umsatz: 0, umsatzVJ: 0 }
                );

                return {
                    x: week,
                    Umsatz: aggregatedData.umsatz,
                    'Umsatz Vorjahr': aggregatedData.umsatzVJ,
                };
            });

            const tableResponse = await axiosInstance.get('/fact/kundenstatistik/', {
                params: { filiale: row.filiale, jahrKw: filterState.datumWoche?.value },
            });

            const popupTableDataArr = tableResponse.data.results
                .filter((item) => {
                    const itemDate = dayjs(item.datum);
                    return itemDate.isBetween(weekStart, weekEnd, 'day', '[]');
                })
                .sort((a, b) => (dayjs(a.datum).isAfter(dayjs(b.datum)) ? 1 : -1))
                .map((item) => ({
                    id: `${item.filiale}-${item.datum}`,
                    datum: dayjs(item.datum).format('dddd DD.MM.YY'),
                    umsatz: item.umsatz || 0,
                    umsatzVJ: item.umsatzVJ || 0,
                    vj:
                        item.umsatzVJ > 0
                            ? ((item.umsatz / item.umsatzVJ - 1) * 100).toFixed(2)
                            : '0.00',
                }));

            const formattedPopupTableDataArr = popupTableDataArr.map((item) => ({
                ...item,
                vj: `${item.vj.replace('.', ',')}%`,
                umsatz: tausenderTrennung(item.umsatz, 2),
                umsatzVJ: tausenderTrennung(item.umsatzVJ, 2),
            }));

            const popupChartLines = [
                { dataKey: 'Umsatz', name: 'Umsatz', color: theme.palette.primary.main },
                {
                    dataKey: 'Umsatz Vorjahr',
                    name: 'Umsatz Vorjahr',
                    color: theme.palette.chart.pink,
                },
            ];

            setPopupChartData(popupChartDataArr);
            setPopupTableData(formattedPopupTableDataArr);
            setPopupLines(popupChartLines);
            setPopupOpen(true);
        } catch (error) {
            console.error('Error fetching popup data:', error);
        }
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const mainChartLines = [
        { dataKey: 'Umsatz', name: 'Umsatz', color: theme.palette.primary.main },
        {
            dataKey: 'Umsatz Vorjahr',
            name: 'Umsatz Vorjahr',
            color: theme.palette.chart.pink,
        },
    ];

    return (
        <Layout>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error">Fehler beim Laden der Daten.</Typography>
                </Box>
            ) : (
                <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
                    {/* Data Cards */}
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                            {/* Umsatz-Datenkarte (aktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                                <DataCard
                                    category="Umsatz"
                                    sector="performance"
                                    value={`${performanceData.umsatz} €`}
                                    subHeaders={[
                                        `${tausenderTrennung(growthData.umsatzWachstumJ, 2)}% VJ`,
                                    ]}
                                />
                            </Grid>
                            {/* Kundenanzahl-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Kundenanzahl"
                                    sector="performance"
                                    value={performanceData.kundenanzahl}
                                    subHeaders={[
                                        `${tausenderTrennung(growthData.kundenanzahlWachstumJ, 2)}% VJ`,
                                    ]}
                                />
                            </Grid>
                            {/* Kundenbon-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Kundenbon"
                                    sector="performance"
                                    value={`${performanceData.kundenbon} €`}
                                    subHeaders={[
                                        `${tausenderTrennung(growthData.kundenbonWachstum, 2)}% VJ`,
                                    ]}
                                />
                            </Grid>
                            {/* Leistung-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Leistung"
                                    sector="performance"
                                    value={`${performanceData.leistung} €`}
                                    subHeaders={[
                                        `${tausenderTrennung(growthData.leistungsWachstum, 2)}% VJ`,
                                    ]}
                                />
                            </Grid>
                        </Stack>
                    </Grid>

                    {/* Haupt Line Chart mit Card-Komponente */}
                    <Grid item xs={12} mt={3}>
                        <BigChartCardUnternehmenWoche
                            data={chartData}
                            title="Umsatz und Umsatz Vorjahr der letzten 6 Wochen"
                            lines={mainChartLines}
                            height={400}
                            infoContent="Dies ist eine Info über den Umsatzchart."
                        />
                    </Grid>

                    {/* Haupttabelle */}
                    <Grid item xs={12} mt={3}>
                        <VariableTreeMUI
                            title="Übersicht nach Verkaufsgebieten"
                            data={tableData}
                            columns={mainTableColumns}
                            initialGrouping={['verkaufsgebiet']}
                            groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                            aggregationFunctions={aggregationFunctions}
                            onRowClick={handleRowClick}
                        />
                    </Grid>

                    {/* Popup */}
                    <FilialePopup
                        open={popupOpen}
                        onClose={closePopup}
                        chartData={popupChartData}
                        tableData={popupTableData}
                        tableColumns={popupTableColumns}
                        chartTitle="Umsatzentwicklung der letzten 6 Wochen"
                        tableTitle="Tagesübersicht"
                        lines={popupLines}
                    />
                </Grid>
            )}
        </Layout>
    );
};

export default Umsatz;
