import React, { useState, useEffect } from 'react';
import {
    Box, Typography, List, ListItem, ListItemText, CardHeader, Paper,
    ListItemIcon, CircularProgress, Divider, IconButton, Dialog, DialogTitle,
    DialogContent, DialogActions, Button, Card, CardContent, Switch, TextField
} from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilialcheckHinzufuegen from './FilialcheckHinzufuegen';
import FilialcheckKonfiguration from '../Filialcheck/FilialcheckKofiguration';

const FilialchecksListe = ({ refresh }) => {
    const [filialchecks, setFilialchecks] = useState([]);
    const [loading, setLoading] = useState(true);

    // Dialoge und dazugehörige States
    const [dialogOpen, setDialogOpen] = useState(false);  
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    
    // Delete-Dialog
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);

    const [selectedCheck, setSelectedCheck] = useState(null);
    const [editingCheckId, setEditingCheckId] = useState(null);
    const [editedName, setEditedName] = useState('');

    const fetchFilialchecks = async () => {
        try {
            const response = await axiosInstance.get('/filialcheck/checks/');
            if (Array.isArray(response.data)) {
                setFilialchecks(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setFilialchecks(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Filialchecks:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilialchecks();
    }, [refresh]);

    // Hinzufügen-Dialog
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        fetchFilialchecks(); 
    };

    // Konfigurations-Dialog
    const handleEditDialogOpen = (check) => {
        setSelectedCheck(check);  
        setEditDialogOpen(true);  
    };
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        fetchFilialchecks();
    };

    // Delete-Dialog öffnen
    const handleDeleteClick = (id) => {
        const checkToDelete = filialchecks.find((check) => check.id === id);
        setDeleteCandidate(checkToDelete || null);
        setDeleteDialogOpen(true);
    };

    // Den eigentlichen Löschvorgang starten (wird vom Dialog aus bestätigt)
    const handleDeleteConfirm = async () => {
        if (!deleteCandidate) return;

        try {
            await axiosInstance.delete(`/filialcheck/checks/${deleteCandidate.id}/`);

            // Filtere den gelöschten Check aus der Liste
            setFilialchecks((prev) => prev.filter((c) => c.id !== deleteCandidate.id));
        } catch (error) {
            console.error('Fehler beim Löschen des Filialchecks:', error);
        } finally {
            // Dialog schließen und Candidate zurücksetzen
            setDeleteDialogOpen(false);
            setDeleteCandidate(null);
        }
    };

    // Dialog einfach abbrechen
    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setDeleteCandidate(null);
    };

    // Switch Toggle (is_active)
    const handleToggleActive = async (check, event) => {
        event.stopPropagation();
        try {
            await axiosInstance.patch(`/filialcheck/checks/${check.id}/`, {
                is_active: !check.is_active
            });
            setFilialchecks((prev) =>
                prev.map((c) =>
                    c.id === check.id ? { ...c, is_active: !check.is_active } : c
                )
            );
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    // Name inline bearbeiten
    const handleNameEdit = (check) => {
        setEditingCheckId(check.id);
        setEditedName(check.name);
    };

    const handleNameUpdate = async (check) => {
        try {
            await axiosInstance.patch(`/filialcheck/checks/${check.id}/`, {
                name: editedName
            });
            // Update local state
            setFilialchecks((prev) =>
                prev.map((c) => (c.id === check.id ? { ...c, name: editedName } : c))
            );
        } catch (error) {
            console.error('Fehler beim Ändern des Namens:', error);
        } finally {
            setEditingCheckId(null);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Paper>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                }}
            >
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Liste der Filialchecks
                        </Typography>
                    }
                    action={
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddCircleIcon />}
                            onClick={handleDialogOpen}
                            sx={{
                                borderRadius: '50px',
                                fontSize: '0.75rem',
                                padding: '2px 8px',
                                minWidth: 'auto',
                            }}
                        >
                            Filialcheck hinzufügen
                        </Button>
                    }
                />
                <CardContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {Array.isArray(filialchecks) && filialchecks.length > 0 ? (
                            filialchecks.map((check) => (
                                <React.Fragment key={check.id}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ChecklistIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                editingCheckId === check.id ? (
                                                    <TextField
                                                        value={editedName}
                                                        onChange={(e) => setEditedName(e.target.value)}
                                                        onBlur={() => handleNameUpdate(check)}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleNameUpdate(check);
                                                            }
                                                        }}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography onClick={() => handleNameEdit(check)}>
                                                        {check.name}
                                                    </Typography>
                                                )
                                            }
                                            secondary={check.is_active ? 'Aktiv' : 'Passiv'}
                                        />
                                        <Switch
                                            edge="end"
                                            checked={check.is_active}
                                            onChange={(e) => handleToggleActive(check, e)}
                                            inputProps={{ 'aria-label': 'Aktiv/Passiv umschalten' }}
                                        />
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditDialogOpen(check);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteClick(check.id);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="h6">Keine Filialchecks gefunden</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>

            {/* Dialog: Filialcheck hinzufügen */}
            <FilialcheckHinzufuegen open={dialogOpen} handleClose={handleDialogClose} />

            {/* Dialog: Filialcheck Konfiguration */}
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Filialcheck Konfiguration</DialogTitle>
                <DialogContent>
                    {selectedCheck && (
                        <FilialcheckKonfiguration
                            open={editDialogOpen}
                            handleClose={handleEditDialogClose}
                            checkId={selectedCheck.id}
                            checkName={selectedCheck.name}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog: Bestätigen vor Löschen */}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Filialcheck löschen</DialogTitle>
                <DialogContent>
                    {deleteCandidate ? (
                        <Typography>
                            Möchtest du den Filialcheck <strong>{deleteCandidate.name}</strong> und
                            alle zugehörigen Einträge wirklich löschen?
                        </Typography>
                    ) : (
                        <Typography>Unbekannter Check?</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error" variant="contained">
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default FilialchecksListe;