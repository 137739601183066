import React from 'react';
import { Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Droppable, Draggable } from '@hello-pangea/dnd';

const StepList = ({
    topicId,
    steps,
    handleDeleteStep,
    handleEditStep,
    handleAddStepPlaceholderClick,
}) => {
    if (!topicId) {
        // Wenn kein topicId übergeben wurde, breche ab oder zeige Hinweis an
        return <ListItemText secondary="Fehler: topicId nicht übergeben." />;
    }

    return (
        <Droppable droppableId={topicId.toString()} type="step">
            {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <List sx={{ width: '100%' }}>
                        {steps.length > 0 ? steps.map((step, index) => (
                            <Draggable key={step.id} draggableId={`step-${step.id}`} index={index}>
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            p: 2,
                                            mb: 1,
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditStep(step)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStep(step)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText primary={step.description} />
                                        </ListItem>
                                    </Box>
                                )}
                            </Draggable>
                        )) : (
                            <ListItemText secondary="Keine Schritte vorhanden." />
                        )}
                        {provided.placeholder}
                    </List>
                    <Box
                        sx={{
                            border: '2px dashed #ddd',
                            borderRadius: '8px',
                            padding: '16px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            color: '#888',
                            mt: 2,
                            width: '100%',
                            '&:hover': { backgroundColor: '#f5f5f5' },
                            boxSizing: 'border-box',
                        }}
                        onClick={() => handleAddStepPlaceholderClick(topicId)}
                    >
                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Neuen Schritt hinzufügen
                    </Box>
                </Box>
            )}
        </Droppable>
    );
};

export default StepList;
