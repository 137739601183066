import React, { useState } from 'react';
import {
    Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
    Stepper, Step, StepLabel, Alert
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import SectionAndQuestionBuilder from '../SectionAndQuestionBuilder'; // Importiere hier denselben Builder

const steps = ['Allgemeine Informationen', 'Sektionen & Felder definieren', 'Abschluss'];

function FormularHinzufuegenDialog({ open, handleClose }) {
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [sections, setSections] = useState([]);

    const handleNext = () => {
        if (activeStep === 0 && name.trim() === '') {
            setError('Bitte geben Sie einen Namen für das Formular ein.');
            return;
        }
        setError(null);
        setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
    };

    const handleSaveAndClose = async () => {
        const payload = {
            name: name,
            description: description,
            sections: sections.map((sec, sIndex) => ({
                name: sec.name,
                description: sec.description,
                order: sIndex,
                fields: sec.fields.map((f, fIndex) => ({
                    label: f.label,
                    field_type: f.field_type,
                    required: f.required,
                    help_text: f.help_text,
                    options: f.options,
                    order: fIndex
                }))
            }))
        };

        try {
            await axiosInstance.post('/aufgaben/form-templates/', payload);
            resetForm();
            handleClose();
        } catch (err) {
            console.error('Fehler beim Erstellen des Formulars:', err);
            setError('Fehler beim Erstellen des Formulars. Bitte versuchen Sie es erneut.');
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setSections([]);
        setError(null);
    };

    const handleCloseWithConfirmation = () => {
        if (name || description || sections.length > 0) {
            setOpenConfirmDialog(true);
        } else {
            handleClose();
        }
    };

    const handleConfirmClose = () => {
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };

    const handleCancelClose = () => {
        setOpenConfirmDialog(false);
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box component="form" sx={{ width: '100%' }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Name des Formulars"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Beschreibung"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Box>
                );
            case 1:
                return (
                    <SectionAndQuestionBuilder
                        sections={sections}
                        onChange={setSections}
                    />
                );
            case 2:
                let fieldCount = 0;
                sections.forEach(s => { fieldCount += s.fields.length; });
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>Abschluss</Typography>
                        <Typography variant="body2" gutterBottom>
                            Überprüfen Sie Ihre Eingaben. Wenn alles korrekt ist, schließen Sie den Prozess ab.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>Formularname: {name || 'Kein Name'}</Typography>
                        <Typography variant="subtitle1">Beschreibung: {description || 'Keine Beschreibung'}</Typography>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Anzahl Sektionen: {sections.length}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1 }}>
                            Anzahl Felder gesamt: {fieldCount}
                        </Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseWithConfirmation}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Neues Formular hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {renderStepContent(activeStep)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleCloseWithConfirmation}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmDialog}
                onClose={handleCancelClose}
            >
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchten Sie wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Nein
                    </Button>
                    <Button onClick={handleConfirmClose} color="secondary">
                        Ja, abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormularHinzufuegenDialog;
