import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Button,
  Dialog,
  IconButton,
  Divider,
  Chip,
  LinearProgress
} from '@mui/material';
import PollIcon from '@mui/icons-material/Poll';
import { Close as CloseIcon } from '@mui/icons-material';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import axiosInstance from '../../../services/axiosInstance';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Layout from '../../../components/layout/Abfragen/AbfragenLayout';

const db_green = '#C3D98C';
const db_green_light = '#D1E69B';
const db_green_dark = '#B6CB83';
const db_petrol = '#395D67';
const db_petrol_light = '#5E827B';
const db_petrol_dark = '#3A5B5D';
const db_grey = '#737373';
const db_grey_light = '#F5F5F5';
const db_mint = '#E7EBE5';
const db_error = '#D03B56';
const db_red = '#D05F3B';
const db_orange = '#D48E3A';
const db_pink = '#CA6790';

const ResponseOverview = () => {
  const [loading, setLoading] = useState(true);
  const [polls, setPolls] = useState([]);
  const [answers, setAnswers] = useState([]);

  // --- Modal: Poll-Details ---
  const [selectedPollId, setSelectedPollId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // --- Modal: Frage-Details ---
  const [questionDetailOpen, setQuestionDetailOpen] = useState(false);
  const [detailQuestion, setDetailQuestion] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const pollResp = await axiosInstance.get('/abfragen/polls/');
        const loadedPolls = pollResp.data.results || pollResp.data;

        const ansResp = await axiosInstance.get('/abfragen/answers/');
        const loadedAnswers = ansResp.data.results || ansResp.data;

        setPolls(loadedPolls);
        setAnswers(loadedAnswers);
      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Poll nach ID holen
  const getPollById = (id) => polls.find((p) => p.id === id);

  // Sterneanzeige
  const Stars = ({ value }) => {
    const maxStars = 10;
    const filledStars = Math.round(value);
    return (
      <Box sx={{ display: 'flex', mt: 1 }}>
        {Array.from({ length: maxStars }, (_, i) =>
          i < filledStars ? (
            <StarIcon key={i} sx={{ color: db_petrol }} />
          ) : (
            <StarBorderIcon key={i} sx={{ color: db_petrol }} />
          )
        )}
      </Box>
    );
  };

  // Diagramm-Helfer
  function polarToCartesian(cx, cy, r, angleInDegrees) {
    const rad = (angleInDegrees - 90) * (Math.PI / 180);
    return {
      x: cx + r * Math.cos(rad),
      y: cy + r * Math.sin(rad),
    };
  }
  function describeArc(cx, cy, r, startAngle, endAngle) {
    const start = polarToCartesian(cx, cy, r, endAngle);
    const end = polarToCartesian(cx, cy, r, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
    return [
      'M',
      start.x,
      start.y,
      'A',
      r,
      r,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(' ');
  }

  // Kreisdiagramm
  function MultiSegmentCircle({ data, size = 100, colors = [] }) {
    const cx = 50;
    const cy = 50;
    const r = 40;

    let startAngle = 0;
    const arcs = data.map((item, index) => {
      const endAngle = startAngle + item.fraction * 360;
      const path = describeArc(cx, cy, r, startAngle, endAngle);
      const color = colors[index] || db_grey;
      startAngle = endAngle;
      return { path, color };
    });

    return (
      <svg width={size} height={size} viewBox="0 0 100 100">
        {arcs.map((arc, i) => (
          <path
            key={i}
            d={arc.path}
            fill="none"
            stroke={arc.color}
            strokeWidth={10}
          />
        ))}
      </svg>
    );
  }

  // Frage-Block Render
  const renderQuestionBlock = (q, qAnswers) => {
    const responseCount = qAnswers.length;
    let content;

    if (q.question_type === 'single_choice') {
      // SINGLE CHOICE
      const optionCount = {};
      q.options.forEach((opt) => {
        optionCount[opt.id] = 0;
      });
      qAnswers.forEach((a) => {
        if (a.selected_option) {
          optionCount[a.selected_option] =
            (optionCount[a.selected_option] || 0) + 1;
        }
      });

      let maxCount = 0;
      Object.values(optionCount).forEach((v) => {
        if (v > maxCount) maxCount = v;
      });
      const highestPct =
        responseCount > 0 ? (maxCount / responseCount) * 100 : 0;

      const dataForPie = q.options.map((opt) => {
        const count = optionCount[opt.id];
        const fraction = responseCount > 0 ? count / responseCount : 0;
        return {
          label: opt.option_text,
          count,
          fraction,
        };
      });
      const circleColors = [db_petrol_light, db_petrol, db_red, db_orange, db_pink];

      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ flexGrow: 1, mr: 2 }}>
            {q.options.map((opt) => (
              <Typography key={opt.id} variant="body2" sx={{ mb: 0.5 }}>
                {opt.option_text}: {optionCount[opt.id]}
              </Typography>
            ))}
            <Typography variant="body2" sx={{ color: db_grey, mt: 2 }}>
              Antworten: {responseCount}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <MultiSegmentCircle
              data={dataForPie}
              size={100}
              colors={circleColors}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {Math.round(highestPct)}%
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    } else if (q.question_type === 'text') {
      // TEXT
      const allAnswers = qAnswers.filter((ans) => ans.text_answer);
      const responseLabel = responseCount === 1 ? 'Antwort' : 'Antworten';

      content = (
        <Box>
          <Box sx={{ mt: 2 }}>
            {allAnswers.length > 0 ? (
              allAnswers.map((ans, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="h4">{ans.text_answer}</Typography>
                  <Typography variant="body2" sx={{ color: db_grey }}>
                    {ans.user}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Keine Antwort
              </Typography>
            )}
          </Box>
          <Typography variant="body2" sx={{ color: db_grey, mt: 2 }}>
            {responseLabel}: {responseCount}
          </Typography>
        </Box>
      );
    } else if (q.question_type === 'rating') {
      // RATING
      if (responseCount === 0) {
        content = <Typography>Keine Antworten</Typography>;
      } else {
        const ratings = qAnswers.map((a) => a.rating_answer);
        const avg = ratings.reduce((sum, val) => sum + val, 0) / responseCount;
        const distribution = {};
        for (let lvl = q.min_rating; lvl <= q.max_rating; lvl++) {
          distribution[lvl] = 0;
        }
        ratings.forEach((r) => {
          distribution[r] = (distribution[r] || 0) + 1;
        });

        content = (
          <Box>
            <Typography variant="h3" sx={{ mb: 1 }}>
              {avg.toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ color: db_grey }}>
              Durchschnittliche Bewertung
            </Typography>
            <Stars value={avg} />

            <Box sx={{ display: 'flex', mt: 2 }}>
              <Box sx={{ mr: 4, display: 'flex', flexDirection: 'column' }}>
                {Object.keys(distribution)
                  .reverse()
                  .map((lvl) => (
                    <Box
                      key={lvl}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '32px',
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2">Stufe {lvl}</Typography>
                    </Box>
                  ))}
              </Box>
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {Object.keys(distribution)
                  .reverse()
                  .map((lvl) => {
                    const count = distribution[lvl];
                    const pct =
                      responseCount > 0 ? (count / responseCount) * 100 : 0;
                    return (
                      <Box
                        key={lvl}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '32px',
                          mb: 1,
                        }}
                      >
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress variant="determinate" value={pct} />
                        </Box>
                        <Typography variant="body2">{count}</Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>

            <Typography variant="body2" sx={{ mt: 2 }}>
              {responseCount} Antworten
            </Typography>
          </Box>
        );
      }
    } else if (q.question_type === 'date') {
      // DATE
      const allAnswers = qAnswers.filter((ans) => ans.date_answer);
      const responseLabel = responseCount === 1 ? 'Antwort' : 'Antworten';

      content = (
        <Box>
          <Box sx={{ mt: 2 }}>
            {allAnswers.length > 0 ? (
              allAnswers.map((ans, index) => {
                const dateFormatted = dayjs(ans.date_answer).isValid()
                  ? dayjs(ans.date_answer).format('DD.MM.YYYY')
                  : 'Ungültiges Datum';
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Typography variant="h4">{dateFormatted}</Typography>
                    <Typography variant="body2" sx={{ color: db_grey }}>
                      {ans.user}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Keine Antwort
              </Typography>
            )}
          </Box>
          <Typography variant="body2" sx={{ color: db_grey, mt: 2 }}>
            {responseLabel}: {responseCount}
          </Typography>
        </Box>
      );
    }

    return content;
  };

  // Modal-Logik
  const handleOpenModal = (pollId) => {
    setSelectedPollId(pollId);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setSelectedPollId(null);
    setModalOpen(false);
  };

  // Frage-Details
  const handleOpenQuestionDetails = (question) => {
    setDetailQuestion(question);
    setQuestionDetailOpen(true);
  };
  const handleCloseQuestionDetails = () => {
    setDetailQuestion(null);
    setQuestionDetailOpen(false);
  };

  // Hilfsfunktionen zum Filtern
  const selectedPoll = selectedPollId ? getPollById(selectedPollId) : null;
  const filteredAnswers = selectedPollId
    ? answers.filter((a) => a.poll === selectedPollId)
    : [];

  // Nützliche Helper-Funktion, um firstQAnswers zu ermitteln
  const getFirstQuestionAnswersForPoll = (pollId) => {
    const poll = getPollById(pollId);
    if (!poll || !poll.questions?.length) return 0;
    const firstQuestion = poll.questions[0];
    const pollAnswers = answers.filter((a) => a.poll === pollId);
    const firstQAnswers = pollAnswers.filter((a) => a.question === firstQuestion.id);
    return firstQAnswers.length;
  };

  const getFirstQuestionAnswersForSelectedPoll = () => {
    if (!selectedPoll) return 0;
    if (!selectedPoll.questions?.length) return 0;
    const firstQuestion = selectedPoll.questions[0];
    const firstQAnswers = filteredAnswers.filter(
      (a) => a.question === firstQuestion.id
    );
    return firstQAnswers.length;
  };

  // Excel-Export-Funktionen
  const handleExportToExcel = (question) => {
    const questionAnswers = filteredAnswers.filter((ans) => ans.question === question.id);
    const dataForXlsx = questionAnswers.map((ans) => {
      let userAnswer = '';
      if (question.question_type === 'single_choice') {
        const chosenOption = question.options.find(
          (opt) => opt.id === ans.selected_option
        );
        userAnswer = chosenOption ? chosenOption.option_text : 'Keine Auswahl';
      } else if (question.question_type === 'text') {
        userAnswer = ans.text_answer || '';
      } else if (question.question_type === 'rating') {
        userAnswer = `Bewertung: ${ans.rating_answer}`;
      } else if (question.question_type === 'date') {
        const validDate = dayjs(ans.date_answer).isValid();
        userAnswer = validDate
          ? dayjs(ans.date_answer).format('DD.MM.YYYY')
          : 'Ungültiges Datum';
      }

      return {
        Nutzer: ans.user || 'Unbekannt',
        Antwort: userAnswer,
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataForXlsx, {
      header: ['Nutzer', 'Antwort'],
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Antworten');
    XLSX.writeFile(wb, `Frage_${question.id}_Export.xlsx`);
  };

  const handleExportPollToExcel = (poll) => {
    const pollAnswers = answers.filter((a) => a.poll === poll.id);
    const uniqueUsers = [...new Set(pollAnswers.map((a) => a.user))];

    let rowId = 1;
    const dataForXlsx = [];

    for (const user of uniqueUsers) {
      const row = {
        Id: rowId++,
        'E-Mail': user,
        Name: '',
      };

      poll.questions.forEach((question) => {
        const userAnswersToThisQuestion = pollAnswers.filter(
          (a) => a.user === user && a.question === question.id
        );
        let answerText = '';

        if (userAnswersToThisQuestion.length > 0) {
          const ans = userAnswersToThisQuestion[0];
          switch (question.question_type) {
            case 'single_choice': {
              const chosenOption = question.options.find(
                (opt) => opt.id === ans.selected_option
              );
              answerText = chosenOption
                ? chosenOption.option_text
                : 'Keine Auswahl';
              break;
            }
            case 'text':
              answerText = ans.text_answer || '';
              break;
            case 'rating':
              answerText = ans.rating_answer
                ? `Bewertung: ${ans.rating_answer}`
                : '';
              break;
            case 'date':
              answerText = dayjs(ans.date_answer).isValid()
                ? dayjs(ans.date_answer).format('DD.MM.YYYY')
                : 'Ungültiges Datum';
              break;
            default:
              answerText = '';
          }
        }

        row[question.question_text] = answerText;
      });

      dataForXlsx.push(row);
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataForXlsx);
    XLSX.utils.book_append_sheet(wb, ws, poll.title);
    XLSX.writeFile(wb, `${poll.title}_umfrage.xlsx`);
  };

  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
          minHeight: '100vh',
        }}
      >
        <PageHeader
          title="Auswertungen"
          subtitle="Hier können Sie die Antworten auf Ihre Umfragen einsehen und auswerten."
          Icon={PollIcon} // Übergabe des Icons
        />

        {polls.length === 0 ? (
          <Typography>Keine Umfragen verfügbar.</Typography>
        ) : (
          polls.map((poll) => {
            // Anzahl der Antworten der *ersten Frage* (statt alle Antworten):
            const firstQAnswersCount = getFirstQuestionAnswersForPoll(poll.id);

            return (
              <Paper
                key={poll.id}
                onClick={() => handleOpenModal(poll.id)}
                sx={{
                  p: 2,
                  mb: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',            // Mauszeiger zeigt "Hand" an
                  border: '2px solid #fff',     // Weißer „Rahmen“
                  transition: 'all 0.2s ease',  // Für weiche Übergänge
                  '&:hover': {
                    borderColor: '#ccc',
                    boxShadow: 3,
                  },
                }}
              >
                {/* Linke Seite: Poll-Titel */}
                <Typography variant="h6">{poll.title}</Typography>

                {/* Rechte Seite: Anzahl Antworten (erster Frage) & Aktiv/Inaktiv */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: db_grey }}
                  >
                    {/* Anzeigen: firstQAnswersCount */}
                    Antworten: {firstQAnswersCount}
                  </Typography>

                  {poll.is_manually_active ? (
                    <Chip
                      label="Aktiv"
                      sx={{
                        bgcolor: db_green_light,
                        color: db_green_dark,
                        fontWeight: 'bold',
                      }}
                    />
                  ) : (
                    <Chip
                      label="Inaktiv"
                      sx={{
                        bgcolor: db_error,
                        color: '#ffffff',
                        fontWeight: 'bold',
                      }}
                    />
                  )}
                </Box>
              </Paper>
            );
          })
        )}

        {/* Modal: Umfrage-Details */}
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 3,
              p: 3,
            }}
          >
            {selectedPoll && (
              <Box>
                {/* ERSTE ZEILE */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Links: UmfrageTitel */}
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {selectedPoll.title}
                  </Typography>

                  {/* Rechts: X-Button */}
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                {/* ZWEITE ZEILE */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                  }}
                >
                  {/* Links: Aktiv/Inaktiv */}
                  <Box>
                    {selectedPoll.is_manually_active ? (
                      <Chip
                        label="Aktiv"
                        sx={{
                          bgcolor: db_green_light,
                          color: db_green_dark,
                          fontWeight: 'bold',
                        }}
                      />
                    ) : (
                      <Chip
                        label="Inaktiv"
                        sx={{
                          bgcolor: db_error,
                          color: '#ffffff',
                          fontWeight: 'bold',
                        }}
                      />
                    )}
                  </Box>

                  {/* Rechts: "Antworten: X" (erste Frage) + Excel-Download-Button */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bold', color: db_grey }}
                    >
                      {/* Anzahl der Antworten an der ersten Frage dieses Polls */}
                      Antworten: {getFirstQuestionAnswersForSelectedPoll()}
                    </Typography>

                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => handleExportPollToExcel(selectedPoll)}
                      startIcon={<DownloadForOfflineOutlinedIcon />}
                      sx={{
                        borderRadius: '50px',
                        fontSize: '0.75rem',
                        padding: '2px 8px',
                        minWidth: 'auto',
                      }}
                    >
                      Excel Herunterladen
                    </Button>
                  </Box>
                </Box>

                {/* Umfrage-Inhalt */}
                <Paper
                  sx={{
                    p: 3,
                    mt: 3,
                    borderRadius: '12px',
                    backgroundColor: '#fff',
                  }}
                >
                  <Divider sx={{ mb: 2 }} />

                  {selectedPoll.questions.map((q, qIndex) => {
                    const qAnswers = filteredAnswers.filter(
                      (ans) => ans.question === q.id
                    );
                    return (
                      <Paper
                        key={qIndex}
                        sx={{
                          p: 3,
                          borderRadius: '12px',
                          background: db_grey_light,
                          mt: 2,
                        }}
                      >
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: db_grey, mb: 1 }}
                          >
                            {qIndex + 1}. {q.question_text}
                          </Typography>

                          {renderQuestionBlock(q, qAnswers)}

                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="outlined"
                              onClick={() => handleOpenQuestionDetails(q)}
                              sx={{
                                color: db_petrol,
                                borderColor: db_petrol,
                              }}
                            >
                              Mehr Details
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    );
                  })}
                </Paper>
              </Box>
            )}
          </Box>
        </Dialog>

        {/* Modal: Frage-Details */}
        <Dialog
          open={questionDetailOpen}
          onClose={handleCloseQuestionDetails}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 3,
              p: 3,
            }}
          >
            {detailQuestion && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Kopfzeile: Frage-Details und X-Button */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Frage-Details
                  </Typography>
                  <IconButton onClick={handleCloseQuestionDetails}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  {/* Linke Spalte */}
                  <Box sx={{ flex: 1, borderRight: '1px solid #ccc', pr: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Wer hat was geantwortet?
                    </Typography>
                    {detailQuestion &&
                      filteredAnswers.filter(
                        (ans) => ans.question === detailQuestion.id
                      ).length === 0 ? (
                      <Typography>Keine Antworten verfügbar.</Typography>
                    ) : (
                      filteredAnswers
                        .filter((ans) => ans.question === detailQuestion?.id)
                        .map((ans, idx) => {
                          let userAnswer = '';
                          if (detailQuestion.question_type === 'single_choice') {
                            const chosenOption = detailQuestion.options.find(
                              (opt) => opt.id === ans.selected_option
                            );
                            userAnswer = chosenOption
                              ? chosenOption.option_text
                              : 'Keine Auswahl';
                          } else if (detailQuestion.question_type === 'text') {
                            userAnswer = ans.text_answer || '';
                          } else if (detailQuestion.question_type === 'rating') {
                            userAnswer = `Bewertung: ${ans.rating_answer}`;
                          } else if (detailQuestion.question_type === 'date') {
                            if (dayjs(ans.date_answer).isValid()) {
                              userAnswer = dayjs(ans.date_answer).format(
                                'DD.MM.YYYY'
                              );
                            } else {
                              userAnswer = 'Ungültiges Datum';
                            }
                          }

                          return (
                            <Paper
                              key={idx}
                              sx={{ p: 1, mb: 1, background: db_grey_light }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 'bold' }}
                              >
                                Nutzer: {ans.user || 'Unbekannt'}
                              </Typography>
                              <Typography variant="body2">
                                Antwort: {userAnswer}
                              </Typography>
                            </Paper>
                          );
                        })
                    )}
                  </Box>

                  {/* Rechte Spalte */}
                  <Box sx={{ flex: 1, pl: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Frage und mögliche Optionen
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {detailQuestion.question_text}
                    </Typography>
                    {detailQuestion.question_type === 'single_choice' &&
                      detailQuestion.options && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            Mögliche Antworten:
                          </Typography>
                          <ul>
                            {detailQuestion.options.map((opt) => (
                              <li key={opt.id}>
                                <Typography variant="body2">
                                  {opt.option_text}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}

                    {detailQuestion.question_type === 'rating' && (
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Bewertungsbereich: {detailQuestion.min_rating} -{' '}
                        {detailQuestion.max_rating}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Button: Einzelfrage in Excel */}
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleExportToExcel(detailQuestion)}
                    sx={{
                      backgroundColor: db_petrol,
                    }}
                  >
                    In Excel herunterladen
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default ResponseOverview;
