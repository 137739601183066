import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VariableTable from '../table/VariableTable';  // Importiere die VariableTable-Komponente
import axiosInstance from '../../services/axiosInstance';

const ABCDialog = ({ open, onClose, filiale, monat }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [warengruppeData, setWarengruppeData] = useState({});

    // Formatter für Tausendertrennzeichen mit deutschem Format
    const numberFormatter = new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 0,
    });

    // Columns-Array inklusive Rang, Verkaufsmenge und Retourquote
    const [columns, setColumns] = useState([
        { accessorKey: 'rang', header: 'Rang' },                // Rang zuerst
        { accessorKey: 'artikel', header: 'Artikel' },
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'umsatz', header: 'Umsatz €' },
        { accessorKey: 'umsatzProzent', header: '%' },
        { accessorKey: 'umsatzKummuliert', header: 'Kummuliert %' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'retourmenge', header: 'Retourmenge Stk.' },
        { accessorKey: 'retourquote', header: 'Retourquote' },
        { accessorKey: 'ABCValue', header: 'ABC' },
    ]);

    useEffect(() => {
        console.log("ABCDialog props - filiale:", filiale, "monat:", monat, "open:", open);
        if (filiale && monat && open) {
            fetchData();
        }
    }, [filiale, monat, open]);

    const fetchData = async () => {
        try {
            console.log("Fetching data for filiale:", filiale, "monat:", monat);
            const response = await axiosInstance.get('scorecard_month_article/abc/', {
                params: {
                    filiale: filiale,
                    jahrMonat: monat,
                }
            });

            console.log("API Response:", response.data);

            if (response.data && response.data.results) {
                const formattedData = response.data.results
                    .map(item => {
                        // Berechnung der Retourquote
                        const denominator = (item.lieferwert + item.korrekturwert);
                        const retourquote = denominator !== 0 
                            ? (item.retourwert / denominator) * 100 
                            : 0;

                        // Umsatz und Verkaufsmenge formatiert mit Punkt als Tausender-Trennzeichen
                        const formattedUmsatz = numberFormatter.format(item.umsatz);
                        const formattedVerkaufsmenge = numberFormatter.format(item.verkaufsmenge);

                        return {
                            rang: item.rangUmsatz,
                            ABCValue: item.ABCValue,
                            artikel: item.artikelnummer.artikel,
                            warengruppe: item.warengruppe,
                            // Verkaufsmengen-Angabe
                            verkaufsmenge: formattedVerkaufsmenge,
                            // Retourmenge bleibt integer
                            retourmenge: item.retourmenge.toFixed(0),
                            // Retourquote als %
                            retourquote: retourquote.toFixed(2) + ' %',
                            // Umsatz mit Tausenderpunkt
                            umsatz: formattedUmsatz,
                            // Kumulierte Umsatz-Angabe mit %
                            umsatzKummuliert: (item.kumUmsatz * 100).toFixed(2) + ' %',
                            // Umsatzprozent mit %
                            umsatzProzent: (item.umsatzProzent * 100).toFixed(2) + ' %',
                        };
                    })
                    // Sortierung nach kumuliertem Umsatz (aufsteigend)
                    .sort((a, b) => {
                        // Hier aus dem Feld "umsatzKummuliert" den numerischen Wert herausziehen:
                        // "XX.XX %" -> parseFloat("XX.XX")
                        const aNum = parseFloat(a.umsatzKummuliert);
                        const bNum = parseFloat(b.umsatzKummuliert);
                        return aNum - bNum;
                    });

                // Group by Warengruppe und zähle A-Artikel
                const warengruppeData = response.data.results.reduce((acc, item) => {
                    if (item.ABCValue === 'A') {
                        const wgNummer = item.warengruppe || 'Unbekannt';
                        acc[wgNummer] = (acc[wgNummer] || 0) + 1;
                    }
                    return acc;
                }, {});

                console.log("Formatted Data:", formattedData);
                console.log("Warengruppe Data:", warengruppeData);

                setTableData(formattedData);
                setWarengruppeData(warengruppeData);
            } else {
                setTableData([]);
                setWarengruppeData({});
            }
        } catch (error) {
            console.error('Error fetching ABC data:', error);
            setTableData([]);
            setWarengruppeData({});
        }
    };

    const handleChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>
                ABC Analyse
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="ABC Analysis Tabs">
                    <Tab label="ABC Daten" />
                    <Tab label="Struktur ABC" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <VariableTable
                        columns={columns}
                        data={tableData}
                        muiTableBodyCellProps={{
                            sx: {
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                },
                            },
                        }}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">A-Artikel pro Warengruppe</Typography>
                        {Object.entries(warengruppeData).map(([wgNummer, count]) => (
                            <Typography key={wgNummer}>
                                Warengruppe {wgNummer}: {count} A-Artikel
                            </Typography>
                        ))}
                    </Box>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default ABCDialog;
