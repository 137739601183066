import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  FormControl,
  Slider,
  RadioGroup,
  Radio,
  Checkbox,
  Paper,
  Card,
  CardHeader,
  Divider
} from '@mui/material';

import {
  Add as AddIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
// Hier ggf. komplett entfernen oder ins Header-Layout verschieben:
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Layout from '../../../components/layout/Abfragen/AbfragenLayout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import QuizIcon from '@mui/icons-material/Quiz';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import StarRateIcon from '@mui/icons-material/StarRate';
import EventIcon from '@mui/icons-material/Event';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PollIcon from '@mui/icons-material/Poll';
import axiosInstance from '../../../services/axiosInstance';

/**
 * Frage-Typen, die im Menü erscheinen,
 * inklusive Icon und kurzer Beschreibung.
 */
const QUESTION_TYPES = [
  {
    title: 'Multiple Choice',
    description: 'Erstelle eine Frage mit mehreren Antwortmöglichkeiten',
    icon: <QuizIcon sx={{ color: 'primary.main', fontSize: 30, mr: 1 }} />,
  },
  {
    title: 'Textfrage',
    description: 'Füge eine offene Frage hinzu',
    icon: <TextFieldsIcon sx={{ color: 'primary.main', fontSize: 30, mr: 1 }} />,
  },
  {
    title: 'Bewertungsskala',
    description: 'Frage mit einer Skala (z. B. 1 bis 10)',
    icon: <StarRateIcon sx={{ color: 'primary.main', fontSize: 30, mr: 1 }} />,
  },
  {
    title: 'Datumsfrage',
    description: 'Wähle ein Datum als Antwort',
    icon: <EventIcon sx={{ color: 'primary.main', fontSize: 30, mr: 1 }} />,
  },
];

// Die Schritte im Stepper
const STEPS = ['Titel festlegen', 'Fragen hinzufügen', 'Berechtigung'];

const Index = () => {
  // -------------------- STATE --------------------
  const [activeStep, setActiveStep] = useState(0);
  const [savedSurveys, setSavedSurveys] = useState([]);
  const [surveyTitle, setSurveyTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [editingSurveyIndex, setEditingSurveyIndex] = useState(null);

  const [questionTypeAnchor, setQuestionTypeAnchor] = useState(null);

  const [newSurveyDialogOpen, setNewSurveyDialogOpen] = useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

  const [surveyPermission, setSurveyPermission] = useState('Alle');

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [selectedSurveyIndex, setSelectedSurveyIndex] = useState(null);
  const [answerState, setAnswerState] = useState({});

  // -------------------- LOAD DATA --------------------
  useEffect(() => {
    const loadSurveys = async () => {
      try {
        // Lädt vorhandene Umfragen vom Backend
        const response = await axiosInstance.get('/abfragen/polls/');
        const polls = response.data.results ? response.data.results : response.data;
        setSavedSurveys(polls);
      } catch (error) {
        console.error('Fehler beim Laden der Umfragen:', error);
      }
    };
    loadSurveys();
  }, []);

  // -------------------- STEP-CONTROL --------------------
  const canProceedFromStep = () => {
    if (activeStep === 0) {
      // Titel muss gefüllt sein
      return surveyTitle.trim().length > 0;
    } else if (activeStep === 1) {
      // Mindestens eine Frage, und Fragen dürfen nicht leer sein
      if (surveyQuestions.length === 0) return false;
      for (const q of surveyQuestions) {
        if (!q.name || q.name.trim().length === 0) {
          return false;
        }
        // Bei Multiple-Choice: mindestens 2 Optionen
        if (q.type === 'Multiple Choice') {
          if (!q.options || q.options.length < 2) return false;
          if (q.options[0].trim().length === 0 || q.options[1].trim().length === 0) {
            return false;
          }
        }
      }
      return true;
    } else if (activeStep === 2) {
      // Berechtigung muss gewählt sein
      return surveyPermission.length > 0;
    }
    return true;
  };

  const handleNext = async () => {
    if (!canProceedFromStep()) return;

    if (activeStep === 0) {
      setActiveStep(1);
    } else if (activeStep === 1) {
      setActiveStep(2);
    } else if (activeStep === 2) {
      // Schritt 3 => Speichern
      const isEdit = editingSurveyIndex !== null;
      const originalSurvey = isEdit ? savedSurveys[editingSurveyIndex] : null;

      // Wenn neu => Standard auf true, sonst den alten Wert übernehmen
      const isActiveValue = isEdit ? originalSurvey.is_manually_active : true;

      const newSurvey = {
        title: surveyTitle,
        questions: surveyQuestions,
        permission: surveyPermission,
        active: isActiveValue
      };

      // Mapping von "permission" auf "visibility" fürs Backend
      let finalVisibility = surveyPermission;
      if (surveyPermission === 'Admins') {
        finalVisibility = 'Spezifische Nutzer';
      } else if (surveyPermission === 'Manager') {
        finalVisibility = 'Spezifische Gruppen';
      }

      // Fragen in Backend-Format transformieren
      const transformedQuestions = newSurvey.questions.map((q) => {
        let question_type = 'text';
        if (q.type === 'Multiple Choice') {
          question_type = 'single_choice';
        } else if (q.type === 'Textfrage') {
          question_type = 'text';
        } else if (q.type === 'Bewertungsskala') {
          question_type = 'rating';
        } else if (q.type === 'Datumsfrage') {
          question_type = 'date';
        }

        const questionObj = {
          question_text: q.name,
          question_type: question_type,
        };

        if (question_type === 'rating') {
          questionObj.min_rating = 1;
          questionObj.max_rating = 10;
        }

        if (question_type === 'single_choice') {
          questionObj.options = q.options.map((opt) => ({ option_text: opt }));
        }

        return questionObj;
      });

      const finalSurvey = {
        title: newSurvey.title,
        description: '',
        visibility: finalVisibility,
        end_date: null,
        is_manually_active: newSurvey.active,
        questions: transformedQuestions,
      };

      try {
        let response;
        if (isEdit && originalSurvey && originalSurvey.id) {
          // Update-Mode
          response = await axiosInstance.put(`/abfragen/polls/${originalSurvey.id}/`, finalSurvey);
          console.log('Umfrage erfolgreich aktualisiert:', response.data);
          setSavedSurveys((prev) => {
            const updated = [...prev];
            updated[editingSurveyIndex] = response.data;
            return updated;
          });
        } else {
          // Neues Formular
          response = await axiosInstance.post('/abfragen/polls/', finalSurvey);
          console.log('Umfrage erfolgreich erstellt:', response.data);
          setSavedSurveys((prev) => [...prev, response.data]);
        }
      } catch (error) {
        console.error('Fehler beim Erstellen/Aktualisieren der Umfrage:', error);
      }

      closeSurveyDialogAndReset();
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  // -------------------- DIALOG-KONTROLLE --------------------
  const handleOpenNewSurveyDialog = () => {
    setSurveyTitle('');
    setTitleError(false);
    setSurveyQuestions([]);
    setSurveyPermission('Alle');
    setActiveStep(0);
    setEditingSurveyIndex(null);
    setNewSurveyDialogOpen(true);
  };

  const handleCloseNewSurveyDialog = () => {
    setConfirmCancelDialogOpen(true);
  };

  const handleConfirmCancel = () => {
    closeSurveyDialogAndReset();
  };

  const handleCancelCancel = () => {
    setConfirmCancelDialogOpen(false);
  };

  const closeSurveyDialogAndReset = () => {
    setSurveyTitle('');
    setTitleError(false);
    setSurveyQuestions([]);
    setSurveyPermission('Alle');
    setActiveStep(0);
    setEditingSurveyIndex(null);
    setConfirmCancelDialogOpen(false);
    setNewSurveyDialogOpen(false);
  };

  // -------------------- SURVEY-FUNKTIONEN --------------------
  const handleDeleteSurvey = async (index) => {
    const surveyToDelete = savedSurveys[index];
    if (surveyToDelete && surveyToDelete.id) {
      try {
        await axiosInstance.delete(`/abfragen/polls/${surveyToDelete.id}/`);
        console.log('Umfrage erfolgreich gelöscht');
        setSavedSurveys((prev) => prev.filter((_, i) => i !== index));
      } catch (error) {
        console.error('Fehler beim Löschen der Umfrage:', error);
      }
    } else {
      setSavedSurveys((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleEditSurvey = (index) => {
    const surveyToEdit = savedSurveys[index];
    setSurveyTitle(surveyToEdit.title);

    // Fragen in das "Editor"-Format zurück-mappen
    const editQuestions = (surveyToEdit.questions || []).map((q) => {
      let origType = '';
      if (q.question_type === 'single_choice') {
        origType = 'Multiple Choice';
      } else if (q.question_type === 'text') {
        origType = 'Textfrage';
      } else if (q.question_type === 'rating') {
        origType = 'Bewertungsskala';
      } else if (q.question_type === 'date') {
        origType = 'Datumsfrage';
      }
      let baseQ = { type: origType, name: q.question_text };
      if (q.question_type === 'single_choice') {
        baseQ.options = (q.options || []).map((opt) => opt.option_text);
        baseQ.multipleAnswers = false;
      }
      if (q.question_type === 'rating') {
        baseQ.value = 5;
      }
      if (q.question_type === 'date') {
        baseQ.selectedDate = null;
      }
      return baseQ;
    });

    let backVisibility = surveyToEdit.visibility;
    if (backVisibility === 'Spezifische Nutzer') {
      backVisibility = 'Admins';
    } else if (backVisibility === 'Spezifische Gruppen') {
      backVisibility = 'Manager';
    }

    setSurveyQuestions(editQuestions);
    setSurveyPermission(backVisibility || 'Alle');
    setEditingSurveyIndex(index);
    setActiveStep(0);
    setNewSurveyDialogOpen(true);
  };

  // -------------------- FRAGEN-FUNKTIONEN --------------------
  const handleOpenQuestionTypeMenu = (event) => {
    setQuestionTypeAnchor(event.currentTarget);
  };

  const handleCloseQuestionTypeMenu = () => {
    setQuestionTypeAnchor(null);
  };

  const handleSelectQuestionType = (type) => {
    handleCloseQuestionTypeMenu();

    let newQuestion;
    if (type.title === 'Multiple Choice') {
      newQuestion = {
        type: 'Multiple Choice',
        name: '',
        options: ['', ''],
        multipleAnswers: false,
      };
    } else if (type.title === 'Datumsfrage') {
      newQuestion = {
        type: 'Datumsfrage',
        name: '',
        selectedDate: null,
      };
    } else if (type.title === 'Textfrage') {
      newQuestion = {
        type: 'Textfrage',
        name: '',
      };
    } else if (type.title === 'Bewertungsskala') {
      newQuestion = {
        type: 'Bewertungsskala',
        name: '',
        value: 5,
      };
    } else {
      newQuestion = {
        type: type.title,
        name: '',
      };
    }

    setSurveyQuestions((prev) => [...prev, newQuestion]);
  };

  const updateQuestionField = (qIndex, field, value) => {
    setSurveyQuestions((prev) => {
      const updated = [...prev];
      updated[qIndex] = { ...updated[qIndex], [field]: value };
      return updated;
    });
  };

  const handleAddOption = (qIndex) => {
    setSurveyQuestions((prev) => {
      const updated = [...prev];
      const q = { ...updated[qIndex] };
      q.options = [...q.options, ''];
      updated[qIndex] = q;
      return updated;
    });
  };

  const handleRemoveOption = (qIndex, optIndex) => {
    setSurveyQuestions((prev) => {
      const updated = [...prev];
      const q = { ...updated[qIndex] };
      q.options = q.options.filter((_, i) => i !== optIndex);
      updated[qIndex] = q;
      return updated;
    });
  };

  const handleOptionChange = (qIndex, optIndex, value) => {
    setSurveyQuestions((prev) => {
      const updated = [...prev];
      const q = { ...updated[qIndex] };
      const newOptions = [...q.options];
      newOptions[optIndex] = value;
      q.options = newOptions;
      updated[qIndex] = q;
      return updated;
    });
  };

  const handleMultipleAnswersToggle = (qIndex) => {
    setSurveyQuestions((prev) => {
      const updated = [...prev];
      const q = { ...updated[qIndex] };
      q.multipleAnswers = !q.multipleAnswers;
      updated[qIndex] = q;
      return updated;
    });
  };

  const handleDeleteQuestion = (qIndex) => {
    setSurveyQuestions((prev) => prev.filter((_, i) => i !== qIndex));
  };

  // -------------------- TOGGLE ACTIVE --------------------
  const handleToggleActive = (index) => {
    const currentPoll = savedSurveys[index];
    const toggledValue = !currentPoll.is_manually_active;

    // Sofort UI updaten
    setSavedSurveys((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        is_manually_active: toggledValue,
      };
      return updated;
    });

    // Patch an Backend
    axiosInstance
      .patch(`/abfragen/polls/${currentPoll.id}/`, {
        is_manually_active: toggledValue,
      })
      .then((response) => {
        console.log('Aktivitätsstatus aktualisiert:', response.data);
      })
      .catch((err) => console.error('Fehler bei Aktivitäts-Update:', err));
  };

  // -------------------- RENDER STEP CONTENT --------------------
  const renderStepContent = () => {
    if (activeStep === 0) {
      return (
        <Box>
          <TextField
            fullWidth
            label="Titel der Abfrage *"
            variant="outlined"
            margin="normal"
            value={surveyTitle}
            onChange={(e) => setSurveyTitle(e.target.value)}
            error={titleError}
            helperText={titleError ? 'Bitte einen Titel eingeben.' : ''}
          />
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto', pr: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fragen hinzufügen
          </Typography>
          {surveyQuestions.length === 0 && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Noch keine Fragen hinzugefügt.
            </Typography>
          )}

          {surveyQuestions.map((q, i) => {
            const header = (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="h6">Frage {i + 1}</Typography>
                <IconButton size="small" color="error" onClick={() => handleDeleteQuestion(i)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            );

            if (q.type === 'Multiple Choice') {
              return (
                <Paper elevation={1} sx={{ p: 2, mt: 2, borderRadius: '8px' }} key={i}>
                  {header}
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Multiple Choice Frage
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Fragetext"
                    variant="outlined"
                    margin="normal"
                    value={q.name}
                    onChange={(e) => updateQuestionField(i, 'name', e.target.value)}
                    error={q.name.trim().length === 0}
                    helperText={q.name.trim().length === 0 ? 'Frage darf nicht leer sein' : ''}
                  />
                  {q.options.map((option, optIndex) => (
                    <Box key={optIndex} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <span style={{ marginRight: '8px' }}>○</span>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={option}
                        placeholder={`Option ${optIndex + 1}`}
                        onChange={(e) => handleOptionChange(i, optIndex, e.target.value)}
                        sx={{ flexGrow: 1, mr: 1 }}
                      />
                      {q.options.length > 2 && (
                        <IconButton onClick={() => handleRemoveOption(i, optIndex)} size="small">
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                  <Box sx={{ mt: 2 }}>
                    <Button variant="text" onClick={() => handleAddOption(i)} startIcon={<AddIcon />}>
                      Add option
                    </Button>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={q.multipleAnswers}
                          onChange={() => handleMultipleAnswersToggle(i)}
                        />
                      }
                      label="Mehrere Antworten zulassen"
                    />
                  </Box>
                </Paper>
              );
            }

            return (
              <Paper elevation={1} sx={{ p: 2, mt: 2, borderRadius: '8px' }} key={i}>
                {header}
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {q.type}
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Fragetext"
                  variant="outlined"
                  margin="normal"
                  value={q.name}
                  onChange={(e) => updateQuestionField(i, 'name', e.target.value)}
                  error={q.name.trim().length === 0}
                  helperText={q.name.trim().length === 0 ? 'Frage darf nicht leer sein' : ''}
                />
              </Paper>
            );
          })}

          <Divider sx={{ mt: 3, mb: 3 }} />

          <Box display="flex" alignItems="center" gap={1}>
            <TextField fullWidth label="Fragetitel (wird beim Hinzufügen gesetzt)" disabled />
            <IconButton color="primary" onClick={handleOpenQuestionTypeMenu}>
              <AddIcon />
            </IconButton>
          </Box>
          <Menu anchorEl={questionTypeAnchor} open={Boolean(questionTypeAnchor)} onClose={handleCloseQuestionTypeMenu}>
            {QUESTION_TYPES.map((type, idx) => (
              <MenuItem key={idx} onClick={() => handleSelectQuestionType(type)}>
                {type.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    } else if (activeStep === 2) {
      return (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Berechtigung
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Legen Sie fest, wer diese Abfrage sehen darf.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="permission-select-label">Berechtigung</InputLabel>
            <Select
              labelId="permission-select-label"
              label="Berechtigung"
              value={surveyPermission}
              onChange={(e) => setSurveyPermission(e.target.value)}
            >
              <MenuItem value="Alle">Alle</MenuItem>
              <MenuItem value="Admins">Nur Admins</MenuItem>
              <MenuItem value="Manager">Nur Manager</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    }
  };

  // -------------------- VORSCHAU --------------------
  const handleOpenPreview = (index) => {
    setSelectedSurveyIndex(index);
    setAnswerState({});
    setPreviewDialogOpen(true);
  };

  const handleClosePreview = () => {
    setSelectedSurveyIndex(null);
    setPreviewDialogOpen(false);
  };

  const handleAnswerChange = (qIndex, value) => {
    setAnswerState((prev) => ({ ...prev, [qIndex]: value }));
  };

  const handleSubmitAnswers = () => {
    console.log('Nutzerantworten:', answerState);
    setPreviewDialogOpen(false);
  };

  const renderPreviewContent = () => {
    if (selectedSurveyIndex === null) {
      return (
        <Typography variant="body2">
          Keine Umfrage ausgewählt.
        </Typography>
      );
    }

    const survey = savedSurveys[selectedSurveyIndex];

    // Fragen in "Vorschau"-Format umwandeln
    const previewQuestions = (survey.questions || []).map((q) => {
      let origType = '';
      if (q.question_type === 'single_choice') {
        origType = 'Multiple Choice';
      } else if (q.question_type === 'text') {
        origType = 'Textfrage';
      } else if (q.question_type === 'rating') {
        origType = 'Bewertungsskala';
      } else if (q.question_type === 'date') {
        origType = 'Datumsfrage';
      }
      let baseQ = { type: origType, name: q.question_text };
      if (q.question_type === 'single_choice') {
        baseQ.options = (q.options || []).map((opt) => opt.option_text);
        baseQ.multipleAnswers = false;
      }
      if (q.question_type === 'rating') {
        baseQ.value = 5;
      }
      if (q.question_type === 'date') {
        baseQ.selectedDate = null;
      }
      return baseQ;
    });

    return (
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {survey.title}
        </Typography>
        {previewQuestions.map((q, i) => {
          return (
            <Paper key={i} elevation={1} sx={{ p: 2, mb: 2, borderRadius: '8px' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Frage {i + 1}: {q.name}
              </Typography>

              {q.type === 'Multiple Choice' && (
                <Box>
                  {q.multipleAnswers ? (
                    q.options.map((opt, optIndex) => (
                      <FormControlLabel
                        key={optIndex}
                        control={
                          <Checkbox
                            checked={Array.isArray(answerState[i]) && answerState[i].includes(opt)}
                            onChange={(e) => {
                              const current = Array.isArray(answerState[i]) ? answerState[i] : [];
                              if (e.target.checked) {
                                handleAnswerChange(i, [...current, opt]);
                              } else {
                                handleAnswerChange(i, current.filter((o) => o !== opt));
                              }
                            }}
                          />
                        }
                        label={opt}
                      />
                    ))
                  ) : (
                    <RadioGroup
                      value={answerState[i] || ''}
                      onChange={(e) => handleAnswerChange(i, e.target.value)}
                    >
                      {q.options.map((opt, optIndex) => (
                        <FormControlLabel key={optIndex} value={opt} control={<Radio />} label={opt} />
                      ))}
                    </RadioGroup>
                  )}
                </Box>
              )}

              {q.type === 'Textfrage' && (
                <TextField
                  fullWidth
                  placeholder="Ihre Antwort"
                  variant="outlined"
                  margin="normal"
                  value={answerState[i] || ''}
                  onChange={(e) => handleAnswerChange(i, e.target.value)}
                />
              )}

              {q.type === 'Bewertungsskala' && (
                <Box sx={{ mt: 2 }}>
                  <Slider
                    min={1}
                    max={10}
                    value={answerState[i] || q.value || 5}
                    onChange={(event, newValue) => handleAnswerChange(i, newValue)}
                    marks={[
                      { value: 1, label: '1' },
                      { value: 10, label: '10' },
                    ]}
                    step={1}
                    valueLabelDisplay="auto"
                  />
                </Box>
              )}

              {q.type === 'Datumsfrage' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Bitte Datum wählen"
                    value={answerState[i] || null}
                    onChange={(newValue) => handleAnswerChange(i, newValue)}
                    renderInput={(params) => <TextField {...params} margin="normal" fullWidth />}
                  />
                </LocalizationProvider>
              )}
            </Paper>
          );
        })}
      </Box>
    );
  };

  // -------------------- RETURN UI --------------------
  return (
    // Paper verkleinert & nach rechts geschoben:
    <Layout>
          <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Abfragen- Settings" 
                    subtitle="Verwalten deine Abfragen und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                    Icon={PollIcon}  // Übergabe des Icons
                />


      {/* Äußere Box mit Hintergrund / Blur */}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70%',
          width : '70%',
          padding: '0px',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(10px)',
          
          
        }}
      >
        
        

        {/* Liste der Umfragen */}
        <Box sx={{ p: 3 }}>
          {savedSurveys.length > 0 && (
            <Box>
              {/* Button rechts neben "Gespeicherte Abfragen:" */}
              <CardHeader
              title={
                      <Typography variant="h6" component="div">
                        Gespeicherte Abfragen
                       </Typography>
                       }
                      action={<Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handleOpenNewSurveyDialog}
                        startIcon={<AddCircleIcon />}
                        sx={{
                          borderRadius: '50px',
                          fontSize: '0.75rem',
                          padding: '2px 8px',
                          minWidth: 'auto',
                        }}
                      >
                        Formular hinzufügen
                      </Button>}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                 
              </CardHeader>
                
              {savedSurveys.map((survey, index) => (
                <React.Fragment key={survey.id || index}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Typography variant="h7">
                          <ChecklistIcon
                            sx={{
                              verticalAlign: 'middle',
                              mr: 1,
                              color: 'grey.600',
                            }}
                          />
                          {survey.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {survey.created_at
                            ? `Erstellt am: ${new Date(survey.created_at).toLocaleDateString(
                                'de-DE',
                                {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}`
                            : ''}
                        </Typography>
                      </Box>
                      <Box
                        // Icons näher zusammen rücken => gap verringert
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        {/* Switch etwas nach links bringen durch labelPlacement */}
                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Switch
                              checked={survey.is_manually_active}
                              onChange={() => handleToggleActive(index)}
                              color="primary"
                            />
                          }
                          
                        />

                        <IconButton
                          size="medium"
                          onClick={() => handleOpenPreview(index)}
                          title="Vorschau"
                        >
                          <VisibilityIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="medium"
                          onClick={() => handleEditSurvey(index)}
                          title="Bearbeiten"
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="medium"
                          onClick={() => handleDeleteSurvey(index)}
                          title="Löschen"
                          sx={{ color: 'grey.600' }}
                        >
                          <DeleteIcon  />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>

                  
                  {index < savedSurveys.length - 1 && <Divider sx={{ my: 1 }} />}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      </Card>

      {/* Dialog: Neue Umfrage erstellen / Bearbeiten */}
      <Dialog
        open={newSurveyDialogOpen}
        onClose={handleCloseNewSurveyDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle>
          {editingSurveyIndex !== null ? 'Abfrage bearbeiten' : 'Neue Abfrage erstellen'}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 2 }}>
            {STEPS.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent()}
        </DialogContent>
        <DialogActions>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Zurück
          </Button>
          <Button onClick={handleCloseNewSurveyDialog}>Abbrechen</Button>
          <Button variant="contained" onClick={handleNext} disabled={!canProceedFromStep()}>
            {activeStep < STEPS.length - 1 ? 'Weiter' : 'Speichern'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog: Abbrechen-Bestätigung */}
      <Dialog open={confirmCancelDialogOpen} onClose={handleCancelCancel}>
        <DialogTitle>Abbrechen bestätigen</DialogTitle>
        <DialogContent>
          <Typography>
            Möchten Sie wirklich abbrechen? Alle bisher eingegebenen Daten gehen verloren.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCancel}>Nein</Button>
          <Button variant="contained" onClick={handleConfirmCancel}>
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog: Umfrage-Vorschau */}
      <Dialog open={previewDialogOpen} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>Umfrage-Vorschau</DialogTitle>
        <DialogContent>{renderPreviewContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Schließen</Button>
          <Button variant="contained" onClick={handleSubmitAnswers}>
            Absenden
          </Button>
        </DialogActions>
      </Dialog>
                  </Box>
      
    </Layout>

  );
};

export default Index;