// Frontend - Monatsübersicht.js

import React, { useState, useEffect } from 'react';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TableFooter, CircularProgress, TextField,
  Button, Grid, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions
} from '@mui/material';
import Layout from '../../../components/layout/Planning/Planning';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import axiosInstance from '../../../services/axiosInstance';
import { format, parseISO, getISOWeek } from 'date-fns';
import deLocale from 'date-fns/locale/de';

import {
  calculatePercentageDifference,
  tausenderTrennung,
} from '../../../utils/mathStuff';

import WochentagFilter from '../../../components/filter/WochentagFilter';

const WochenTage = [
  'Montag', 'Dienstag', 'Mittwoch',
  'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'
];

const Monatsübersicht = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    filialnummer: '',
    datum: ''
  });
  const [error, setError] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [filteredDialogData, setFilteredDialogData] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [planumsatzDesMonats, setPlanumsatzDesMonats] = useState(0);
  const [selectedWeekday, setSelectedWeekday] = useState(null);
  const [monatId, setMonatId] = useState(null);
  const [umsatzPredDesMonats, setUmsatzPredDesMonats] = useState(0);

  const [zielstundenleistung, setZielstundenleistung] = useState({
    'Montag': 0,
    'Dienstag': 0,
    'Mittwoch': 0,
    'Donnerstag': 0,
    'Freitag': 0,
    'Samstag': 0,
    'Sonntag': 0
  });

  const [openZSLDialog, setOpenZSLDialog] = useState(false);

  const fetchMonatsumsatz = async () => {
    if (!filters.filialnummer) {
      setLoading(false);
      setData([]);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('filialnummer', filters.filialnummer);
      if (filters.datum) {
        const [year, month] = filters.datum.split('-');
        queryParams.append('datum__year', year);
        queryParams.append('datum__month', month);
      }

      const response = await axiosInstance.get(
        `/planzahlen/monatsumsatz/?${queryParams.toString()}`
      );
      const result = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setData(result);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      setData([]);
      setError('Fehler beim Abrufen der Daten.');
    } finally {
      setLoading(false);
    }
  };

  const fetchZielstundenleistung = async () => {
    if (!filters.filialnummer) return;
    try {
      const response = await axiosInstance.get('/planzahlen/zielstundenleistung/', {
        params: { filialnummer: filters.filialnummer }
      });
      const result = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      
      let newZSL = { ...zielstundenleistung };
      result.forEach(item => {
        if (newZSL[item.wochentag] !== undefined) {
          newZSL[item.wochentag] = item.zielstundenleistung;
        }
      });
      setZielstundenleistung(newZSL);
    } catch (error) {
      console.error('Fehler beim Laden der Zielstundenleistung:', error);
    }
  };

  useEffect(() => {
    if (filters.filialnummer && filters.datum) {
      fetchMonatsumsatz();
      fetchZielstundenleistung();
    }
  }, [filters.filialnummer, filters.datum]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchMonatsumsatz();
    fetchZielstundenleistung();
  };

  const handlePlanumsatzChange = (e, row) => {
    const parsedValue = parseFloat(e.target.value);
    const newValue = !isNaN(parsedValue) ? Math.round(parsedValue) : 0;
    setData((prevData) =>
      prevData.map((item) =>
        item.index === row.index
          ? { ...item, planumsatz: newValue }
          : item
      )
    );
  };

  const handlePlanumsatzBlur = (row) => {
    const updatedRow = data.find(
      (item) => item.index === row.index
    );
    const planumsatz = Math.round(parseFloat(updatedRow.planumsatz)) || 0;
    updatePlanumsatz(row.index, planumsatz);
  };

  const updatePlanumsatz = async (pk, planumsatz) => {
    try {
      await axiosInstance.patch(
        `/planzahlen/monatsumsatz/${pk}/update_planumsatz/`,
        { planumsatz }
      );
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Planumsatzes:', error);
      setError('Fehler beim Aktualisieren des Planumsatzes.');
    }
  };

  const handleRoundUpToNextHundred = (row) => {
    const currentPlanumsatz = parseFloat(row.planumsatz) || 0;
    const roundedPlanumsatz = Math.ceil(currentPlanumsatz / 100) * 100;
    setData((prevData) =>
      prevData.map((item) =>
        item.index === row.index
          ? { ...item, planumsatz: roundedPlanumsatz }
          : item
      )
    );
    updatePlanumsatz(row.index, roundedPlanumsatz);
  };

  const handleIncreaseByPercentageOfForecast = (row, percentage) => {
    const umsatzPred = parseFloat(row.umsatzPred) || 0;
    const increaseAmount = umsatzPred * (percentage / 100);
    const newPlanumsatz = Math.round(
      (parseFloat(row.planumsatz) || 0) + increaseAmount
    );
    setData((prevData) =>
      prevData.map((item) =>
        item.index === row.index
          ? { ...item, planumsatz: newPlanumsatz }
          : item
      )
    );
    updatePlanumsatz(row.index, newPlanumsatz);
  };

  const updateTagesumsatz = async (datum, filialnummer, dataToUpdate) => {
    try {
      await axiosInstance.patch(
        `/planzahlen/tagesumsatz/update/`,
        {
          datum,
          filialnummer,
          ...dataToUpdate
        }
      );
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Daten:', error);
      setError('Fehler beim Aktualisieren der Daten.');
    }
  };

  const updateMonthlyPlanumsatz = async (monatId, newPlanumsatz) => {
    try {
      await axiosInstance.patch(
        `/planzahlen/monatsumsatz/${monatId}/update_planumsatz/`,
        { planumsatz: newPlanumsatz }
      );
      setData((prevData) =>
        prevData.map((item) =>
          item.index === monatId
            ? { ...item, planumsatz: newPlanumsatz }
            : item
        )
      );
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Monatsumsatzes:', error);
      setError('Fehler beim Aktualisieren des Monatsumsatzes.');
    }
  };

  const handleOpenDialog = async (row) => {
    try {
      setDialogLoading(true);
      const planumsatz_des_monats = parseFloat(row.planumsatz);
      const umsatzPred = parseFloat(row.umsatzPred);
      setPlanumsatzDesMonats(planumsatz_des_monats);
      setUmsatzPredDesMonats(umsatzPred);
      setMonatId(row.index);

      const queryParams = new URLSearchParams();
      queryParams.append('filialnummer', row.filialnummer);

      const dateObj = parseISO(row.datum);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const jahrMonat = `${year}.${month}`;
      queryParams.append('jahrMonat', jahrMonat);

      const response = await axiosInstance.get(
        `/planzahlen/tagesumsatz/?${queryParams.toString()}`
      );
      const tResult = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];

      const updatedResult = tResult.map(item => {
        const umsatzProzentVariabel = parseFloat(item.umsatzProzentVariabel);
        const umsatzProzentDefault = parseFloat(item.umsatzProzentDefault);
        const validUmsatzProzentVariabel = !isNaN(umsatzProzentVariabel)
          ? umsatzProzentVariabel
          : umsatzProzentDefault;
        return {
          ...item,
          umsatzProzentVariabel: validUmsatzProzentVariabel,
        };
      });

      const dialogDataWithPlanumsatz = updatedResult.map(item => {
        const umsatzProzentVariabel = parseFloat(item.umsatzProzentVariabel) || 0;
        const planumsatz_des_tages = planumsatz_des_monats * umsatzProzentVariabel;
        const roundedPlanumsatz = Math.round(planumsatz_des_tages);
        const zsl = zielstundenleistung[item.wochentag] || 0;
        return {
          ...item,
          planumsatz: roundedPlanumsatz,
          zielstundenleistung: zsl
        };
      });

      setDialogData(dialogDataWithPlanumsatz);
      setFilteredDialogData(dialogDataWithPlanumsatz);
      setSelectedWeekday(null);
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dialog-Daten:', error);
      setError('Fehler beim Abrufen der Dialog-Daten.');
    } finally {
      setDialogLoading(false);
    }
  };

  const handleDialogPlanumsatzChange = (e, index) => {
    const parsedValue = parseFloat(e.target.value);
    const newValue = !isNaN(parsedValue) ? Math.round(parsedValue) : 0;
    setFilteredDialogData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], planumsatz: newValue };
      return newData;
    });
  };

  const handleDialogPlanumsatzBlur = async (index) => {
    const item = filteredDialogData[index];
    const planumsatzFloat = Math.round(parseFloat(item.planumsatz)) || 0;

    const newTotalPlanumsatz = filteredDialogData.reduce((sum, it) => {
      return sum + (Math.round(parseFloat(it.planumsatz)) || 0);
    }, 0);

    await updateMonthlyPlanumsatz(monatId, newTotalPlanumsatz);
    setPlanumsatzDesMonats(newTotalPlanumsatz);

    const newUmsatzProzentVariabel = newTotalPlanumsatz
      ? planumsatzFloat / newTotalPlanumsatz
      : 0;

    setFilteredDialogData((prevData) => {
      const newData = prevData.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            umsatzProzentVariabel: newUmsatzProzentVariabel,
          };
        }
        return el;
      });
      return newData;
    });

    await updateTagesumsatz(item.datum, item.filialnummer, {
      planumsatz: planumsatzFloat,
      umsatzProzentVariabel: newUmsatzProzentVariabel
    });

    const updatedData = filteredDialogData.map((el) => {
      const p = Math.round(parseFloat(el.planumsatz)) || 0;
      const uVar = newTotalPlanumsatz ? p / newTotalPlanumsatz : 0;
      return {
        ...el,
        umsatzProzentVariabel: uVar,
      };
    });
    setFilteredDialogData(updatedData);
  };

  const handleDialogPlanstundenChange = (e, index) => {
    const parsedValue = parseFloat(e.target.value);
    const newValue = !isNaN(parsedValue) ? Math.round(parsedValue) : 0;
    setFilteredDialogData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], planstunden: newValue };
      return newData;
    });
  };

  const handleDialogPlanstundenBlur = (index) => {
    const item = filteredDialogData[index];
    const planstunden = Math.round(parseFloat(item.planstunden)) || 0;
    updateTagesumsatz(item.datum, item.filialnummer, { planstunden });
  };

  const handleWeekdayChange = (newOption) => {
    setSelectedWeekday(newOption);
    if (newOption) {
      setFilteredDialogData(dialogData.filter(item => item.wochentag === newOption.value));
    } else {
      setFilteredDialogData(dialogData);
    }
  };

  const handleReset = async () => {
    try {
      await updateMonthlyPlanumsatz(monatId, umsatzPredDesMonats);
      setPlanumsatzDesMonats(umsatzPredDesMonats);

      const updatedData = await Promise.all(dialogData.map(async (item) => {
        const umsatzProzentDefault = parseFloat(item.umsatzProzentDefault) || 0;
        const planumsatz = Math.round(umsatzPredDesMonats * umsatzProzentDefault);
        const umsatzProzentVariabel = umsatzProzentDefault;

        await updateTagesumsatz(item.datum, item.filialnummer, {
          planumsatz, umsatzProzentVariabel
        });

        return {
          ...item,
          planumsatz,
          umsatzProzentVariabel,
        };
      }));

      setDialogData(updatedData);
      setFilteredDialogData(updatedData);
      setSelectedWeekday(null);
    } catch (error) {
      console.error('Fehler beim Zurücksetzen:', error);
      setError('Fehler beim Zurücksetzen.');
    }
  };

  const updateZielstundenleistungBackend = async () => {
    if (!filters.filialnummer) return;
    try {
      const response = await axiosInstance.get('/planzahlen/zielstundenleistung/', {
        params: { filialnummer: filters.filialnummer }
      });
      const result = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];

      const existingMap = {};
      result.forEach(item => {
        existingMap[item.wochentag] = item;
      });

      for (const [tag, value] of Object.entries(zielstundenleistung)) {
        if (existingMap[tag]) {
          await axiosInstance.patch(`/planzahlen/zielstundenleistung/${existingMap[tag].id}/`, {
            zielstundenleistung: value
          });
        } else {
          await axiosInstance.post(`/planzahlen/zielstundenleistung/`, {
            filialnummer: filters.filialnummer,
            wochentag: tag,
            zielstundenleistung: value
          });
        }
      }

      console.log('Zielstundenleistung erfolgreich gespeichert');
      await fetchZielstundenleistung();
    } catch (error) {
      console.error('Fehler beim Speichern der Zielstundenleistung:', error);
    }
  };

  const calculateDialogTotals = () => {
    let totalUmsatzProzent = 0;
    let totalPlanumsatz = 0;
    let totalPlanstunden = 0;
    let totalProduktivstunden = 0;

    if (!filteredDialogData) return {
      totalUmsatzProzent: '0%',
      totalPlanumsatz: '0',
      totalPlanstunden: '0',
      totalProduktivstunden: '0'
    };

    filteredDialogData.forEach(row => {
      totalUmsatzProzent += parseFloat(row.umsatzProzentVariabel) || 0;
      totalPlanumsatz += Math.round(parseFloat(row.planumsatz)) || 0;
      totalPlanstunden += Math.round(parseFloat(row.planstunden)) || 0;
      totalProduktivstunden += parseFloat(row.produktivstunden) || 0;
    });

    return {
      totalUmsatzProzent: (totalUmsatzProzent * 100).toFixed(2) + '%',
      totalPlanumsatz: totalPlanumsatz.toString(),
      totalPlanstunden: totalPlanstunden.toString(),
      totalProduktivstunden: totalProduktivstunden.toString(),
    };
  };

  const calculateStundenleistung = (planumsatz, planstunden) => {
    const planumsatzFloat = Math.round(parseFloat(planumsatz)) || 0;
    const planstundenFloat = Math.round(parseFloat(planstunden)) || 0;
    if (!isNaN(planumsatzFloat) && !isNaN(planstundenFloat) && planstundenFloat !== 0) {
      return (planumsatzFloat / planstundenFloat).toFixed(2);
    } else {
      return '-';
    }
  };

  const calculateMainTotals = () => {
    let totalUmsatzVVJ = 0;
    let totalUmsatzVJ = 0;
    let totalUmsatzPred = 0;
    let totalPlanumsatz = 0;

    data.forEach(row => {
      totalUmsatzVVJ += parseFloat(row.umsatzVVJ) || 0;
      totalUmsatzVJ += parseFloat(row.umsatzVJ) || 0;
      totalUmsatzPred += parseFloat(row.umsatzPred) || 0;
      totalPlanumsatz += Math.round(parseFloat(row.planumsatz)) || 0;
    });

    return {
      totalUmsatzVVJ: totalUmsatzVVJ.toFixed(0),
      totalUmsatzVJ: totalUmsatzVJ.toFixed(0),
      totalUmsatzPred: totalUmsatzPred.toFixed(0),
      totalPlanumsatz: totalPlanumsatz.toFixed(0),
    };
  };

  // Gruppierung nach ISO-Woche
  const groupByWeek = (items) => {
    const groups = {};
    items.forEach(item => {
      const isoWeek = getISOWeek(parseISO(item.datum));
      if (!groups[isoWeek]) {
        groups[isoWeek] = [];
      }
      groups[isoWeek].push(item);
    });
    return groups;
  };

  const calculateWeekTotals = (weekItems) => {
    let weekPlanumsatz = 0;
    let weekProduktivstunden = 0;
    let weekPlanstunden = 0;
    let weekZielSum = 0;
    let zielCount = 0;

    weekItems.forEach(row => {
      weekPlanumsatz += Math.round(parseFloat(row.planumsatz)) || 0;
      weekProduktivstunden += parseFloat(row.produktivstunden) || 0;
      const ps = Math.round(parseFloat(row.planstunden)) || 0;
      weekPlanstunden += ps;
      
      // Zielleistung aufsummieren um den Durchschnitt zu bilden
      const zL = parseFloat(row.zielstundenleistung);
      if (!isNaN(zL) && zL > 0) {
        weekZielSum += zL;
        zielCount++;
      }
    });

    const weekZielleistung = zielCount > 0 ? (weekZielSum / zielCount).toFixed(2) : '-';
    const weekPlanleistung = (weekPlanstunden > 0) ? (weekPlanumsatz / weekPlanstunden).toFixed(2) : '-';

    return {
      weekPlanumsatz,
      weekProduktivstunden,
      weekPlanstunden,
      weekZielleistung,
      weekPlanleistung
    };
  };

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto',
        bgcolor: 'transparent'
      }}>
        <PageHeader
          title="Monatsübersicht"
          subtitle="Übersicht aller Filialen und deren geplante Umsätze pro Monat."
          Icon={AnnouncementOutlinedIcon}
        />

        {/* Filterformular */}
        <Box component="form"
          onSubmit={handleFilterSubmit}
          sx={{ mt: 4, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Filialnummer"
                name="filialnummer"
                value={filters.filialnummer}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Datum"
                name="datum"
                value={filters.datum}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                placeholder="YYYY-MM"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button type="submit"
                variant="contained"
                color="primary"
                fullWidth>
                Filtern
              </Button>
            </Grid>
          </Grid>
        </Box>

        {error && (
          <Box sx={{ color: 'red', mb: 2 }}>
            {error}
          </Box>
        )}

        {!filters.filialnummer && (
          <Box sx={{ mt: 4 }}>
            Bitte geben Sie eine Filialnummer ein und klicken Sie auf
            "Filtern", um die Daten anzuzeigen.
          </Box>
        )}

        {loading ? (
          <Box sx={{
            display: 'flex', justifyContent: 'center',
            alignItems: 'center', height: '50vh'
          }}>
            <CircularProgress />
          </Box>
        ) : data.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Filialnummer</TableCell>
                  <TableCell align="right">Monat</TableCell>
                  <TableCell align="right">Umsatz VVJ</TableCell>
                  <TableCell align="right">Umsatz VJ</TableCell>
                  <TableCell align="right">
                    Prognose
                  </TableCell>
                  <TableCell align="left">Geplanter Umsatz</TableCell>
                  <TableCell align="right">Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const key = row.index;
                  const filialnummer = row.filialnummer;
                  const datum = row.datum;
                  const umsatzVVJ = parseFloat(row.umsatzVVJ) || 0;
                  const umsatzVJ = parseFloat(row.umsatzVJ) || 0;
                  const umsatzPred = parseFloat(row.umsatzPred) || 0;
                  const planumsatz = Math.round(parseFloat(row.planumsatz)) || 0;

                  const percentageDifference =
                    calculatePercentageDifference(planumsatz, umsatzPred);
                  const percentageDifferenceFormatted =
                    percentageDifference !== null
                      ? `${Math.round(percentageDifference)} %`
                      : '-';
                  const percentageDifferenceColor =
                    percentageDifference >= 0 ? 'green' : 'red';

                  const formattedDate = format(
                    parseISO(datum),
                    'MMMM yyyy',
                    { locale: deLocale }
                  );

                  return (
                    <TableRow key={key}>
                      <TableCell>{filialnummer}</TableCell>
                      <TableCell align="right">{formattedDate}</TableCell>
                      <TableCell align="right">
                        {tausenderTrennung(umsatzVVJ, 0)}
                      </TableCell>
                      <TableCell align="right">
                        {tausenderTrennung(umsatzVJ, 0)}
                      </TableCell>
                      <TableCell align="right">
                        {tausenderTrennung(umsatzPred, 0)}
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            value={planumsatz}
                            onChange={(e) => handlePlanumsatzChange(e, row)}
                            onBlur={() => handlePlanumsatzBlur(row)}
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                              inputProps: { min: 0 },
                              sx: { 
                                height: '30px', 
                                padding: '0 8px' 
                              }
                            }}
                            sx={{ width: '125px' }}
                          />
                          <Box sx={{
                            ml: 1, display: 'flex',
                            alignItems: 'center'
                          }}>
                            <span style={{
                              color: percentageDifferenceColor
                            }}>
                              {percentageDifferenceFormatted}
                            </span>
                            <Button size="small"
                              onClick={() =>
                                handleRoundUpToNextHundred(row)
                              }>
                              +100
                            </Button>
                            <Button size="small"
                              onClick={() =>
                                handleIncreaseByPercentageOfForecast(
                                  row, 5
                                )
                              }>
                              +5%
                            </Button>
                            <Button size="small"
                              onClick={() =>
                                handleIncreaseByPercentageOfForecast(
                                  row, 10
                                )
                              }>
                              +10%
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() =>
                          handleOpenDialog(row)
                        }>
                          <SearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2}>Summe</TableCell>
                  <TableCell align="right">
                    {tausenderTrennung(
                      calculateMainTotals().totalUmsatzVVJ, 0
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {tausenderTrennung(
                      calculateMainTotals().totalUmsatzVJ, 0
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {tausenderTrennung(
                      calculateMainTotals().totalUmsatzPred, 0
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {tausenderTrennung(
                      calculateMainTotals().totalPlanumsatz, 0
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : filters.filialnummer ? (
          <Box sx={{ mt: 4 }}>
            Keine Daten gefunden.
          </Box>
        ) : null}

        {/* Dialog für Tagesdetails */}
        <Dialog open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="xl" fullWidth>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
            Details für Filiale {filteredDialogData &&
              filteredDialogData[0]?.filialnummer} im{' '}
            {filteredDialogData &&
              format(
                parseISO(filteredDialogData[0]?.datum),
                'MMMM yyyy',
                { locale: deLocale }
              )}
            </Box>
            <IconButton onClick={() => setOpenZSLDialog(true)}>
              <SettingsIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {dialogLoading ? (
              <Box sx={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress />
              </Box>
            ) : filteredDialogData && filteredDialogData.length > 0 ? (
              <>
                <Box sx={{ 
                  mb: 2, 
                  padding: '8px', 
                  borderRadius: '4px', 
                  fontWeight: 'bold'          
                }}>
                  Planumsatz: {' '}
                  {tausenderTrennung(planumsatzDesMonats, 0)}
                </Box>

                <WochentagFilter
                  options={WochenTage.map(tag => ({ label: tag, value: tag }))}
                  value={selectedWeekday}
                          onChange={handleWeekdayChange}
                />

                {(() => {
                  const grouped = groupByWeek(filteredDialogData);
                  const weeks = Object.keys(grouped).sort((a,b) => a - b);
                  
                  return (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Datum</TableCell>
                      <TableCell>Wochentag</TableCell>
                      <TableCell>Umsatz %</TableCell>
                      <TableCell>Planumsatz</TableCell>
                          <TableCell>∅ Produktivstunden</TableCell>
                      <TableCell>Planstunden</TableCell>
                          <TableCell>Zielleistung</TableCell>
                          <TableCell>Planleistung</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                        {weeks.map(week => {
                          const weekItems = grouped[week];
                          return (
                            <React.Fragment key={week}>
                              {weekItems.map((item, index) => {
                                const stundenleistungValue = calculateStundenleistung(
                                  item.planumsatz,
                                  item.planstunden
                                );
                                const stundenleistungNumber = parseFloat(stundenleistungValue);
                                const isWeekend = item.wochentag === 'Samstag' || item.wochentag === 'Sonntag';
                                const isUnderTarget = !isNaN(stundenleistungNumber) 
                                                      && stundenleistungNumber !== '-'
                                                      && stundenleistungNumber < item.zielstundenleistung;
                                
                                return (
                                  <TableRow
                                    key={item.datum + index}
                                    sx={{
                                      backgroundColor: isWeekend ? '#f0f0f0' : 'inherit'
                                    }}
                                  >
                        <TableCell>
                                      {format(parseISO(item.datum),'dd.MM.yyyy')}
                        </TableCell>
                        <TableCell>{item.wochentag}</TableCell>
                        <TableCell>
                                      {(parseFloat(item.umsatzProzentVariabel) * 100).toFixed(2)}%
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.planumsatz}
                            onChange={(e) =>
                                          handleDialogPlanumsatzChange(e, filteredDialogData.indexOf(item))
                            }
                            onBlur={() =>
                                          handleDialogPlanumsatzBlur(filteredDialogData.indexOf(item))
                            }
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                                          inputProps: { min: 0 },
                                          sx: { 
                                            height: '30px',    
                                            padding: '0 8px'
                                          }
                            }}
                                        sx={{ width: '125px' }}
                          />
                        </TableCell>
                        <TableCell>
                                      {tausenderTrennung(item.produktivstunden, 0)}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={item.planstunden}
                            onChange={(e) =>
                                          handleDialogPlanstundenChange(e, filteredDialogData.indexOf(item))
                            }
                            onBlur={() =>
                                          handleDialogPlanstundenBlur(filteredDialogData.indexOf(item))
                            }
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                                          inputProps: { min: 0 },
                                          sx: { 
                                            height: '30px', 
                                            padding: '0 8px'
                                          }
                            }}
                                        sx={{ width: '125px' }}
                          />
                        </TableCell>
                                    <TableCell>{item.zielstundenleistung}</TableCell>
                        <TableCell>
                                      {!isNaN(stundenleistungNumber) && stundenleistungNumber !== '-' ? (
                                        <Box
                                          sx={{
                                            display: 'inline-block',
                                            padding: '0 8px',
                                            borderRadius: '4px',
                                            backgroundColor: isUnderTarget ? '#f44336' : '#4caf50',
                                            color: '#fff',
                                            textAlign: 'center'
                                          }}
                                        >
                                          {stundenleistungValue}
                                        </Box>
                                      ) : (
                                        stundenleistungValue
                          )}
                        </TableCell>
                      </TableRow>
                                );
                              })}

                              {/* Wochensumme */}
                              {(() => {
                                const { weekPlanumsatz, weekProduktivstunden, weekPlanstunden, weekZielleistung, weekPlanleistung } = calculateWeekTotals(weekItems);
                                return (
                                  <TableRow sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                    <TableCell colSpan={1} align="left">Wochensumme KW {week}</TableCell>
                                    <TableCell colSpan={2}></TableCell>
                                    <TableCell>{tausenderTrennung(weekPlanumsatz,0)}</TableCell>
                                    <TableCell>{tausenderTrennung(weekProduktivstunden,0)}</TableCell>
                                    <TableCell>{tausenderTrennung(weekPlanstunden,0)}</TableCell>
                                    <TableCell>{weekZielleistung}</TableCell>
                                    <TableCell>{weekPlanleistung}</TableCell>
                                  </TableRow>
                                );
                              })()}
                            </React.Fragment>
                          );
                        })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2}>Summe</TableCell>
                      <TableCell>
                        {calculateDialogTotals().totalUmsatzProzent}
                      </TableCell>
                      <TableCell>
                        {tausenderTrennung(
                          calculateDialogTotals().totalPlanumsatz, 0
                        )}
                      </TableCell>
                      <TableCell>
                        {tausenderTrennung(
                          calculateDialogTotals().totalProduktivstunden, 0
                        )}
                      </TableCell>
                      <TableCell>
                        {tausenderTrennung(
                          calculateDialogTotals().totalPlanstunden, 0
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
                  );
                })()}
              </>
            ) : (
              <Box>Keine Daten gefunden.</Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReset} color="secondary">
              Reset
            </Button>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog für Zielstundenleistung */}
        <Dialog open={openZSLDialog}
          onClose={() => setOpenZSLDialog(false)}
          maxWidth="sm" fullWidth>
          <DialogTitle>
            Zielstundenleistung bearbeiten
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {Object.keys(zielstundenleistung).map((tag) => (
                <Grid item xs={12} sm={6} key={tag}>
                  <TextField
                    label={`Zielstundenl. ${tag}`}
                    type="number"
                    value={zielstundenleistung[tag]}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value) || 0;
                      setZielstundenleistung((prev) => ({ ...prev, [tag]: Math.round(val) }));
                    }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenZSLDialog(false)} color="secondary">
              Abbrechen
            </Button>
            <Button onClick={async () => {
              await updateZielstundenleistungBackend();
              setOpenZSLDialog(false);
            }} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default Monatsübersicht;
