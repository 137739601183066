import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Alert,
    CircularProgress,
    IconButton,
    Button,
    Badge,
    Avatar,
    Chip,
    Card,
    CardContent,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import Layout from '../../../components/layout/Filialfeed/Filialfeed';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PollIcon from '@mui/icons-material/Poll';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import axiosInstance from '../../../services/axiosInstance';
import { useTheme } from '@mui/material/styles';

const PAGE_SIZE = 5;

const Index = () => {
    const theme = useTheme();

    const tagColors = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart?.yellow || '#FFEB3B',
        theme.palette.chart?.orange || '#FF9800',
        theme.palette.chart?.red || '#F44336',
        theme.palette.chart?.pink || '#E91E63',
        theme.palette.chart?.purpleLight || '#AB47BC',
        theme.palette.chart?.purpleDark || '#7B1FA2',
    ];

    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [selectedTag, setSelectedTag] = useState('Alle');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newComments, setNewComments] = useState({});
    const [notifications, setNotifications] = useState(0);
    const [page, setPage] = useState(0);

    // Aktueller User + Lesebestätigung
    const [currentUser, setCurrentUser] = useState(null);
    const [readDialogOpen, setReadDialogOpen] = useState(false);
    const [dialogPostFilialeId, setDialogPostFilialeId] = useState(null);

    // 1) Lesebestätigung
    const handleMarkAsRead = async (postFilialeId) => {
        try {
            await axiosInstance.post(`/filialfeed/post-filiale/${postFilialeId}/mark-read/`);
            const pfResponse = await axiosInstance.get(`/filialfeed/post-filiale/${postFilialeId}/`);
            const updatedPostFiliale = pfResponse.data;

            // Zugehörigen Post neu laden
            const postId = updatedPostFiliale.post;
            const updatedPostResponse = await axiosInstance.get(`/filialfeed/posts/${postId}/`);
            const updatedPost = updatedPostResponse.data;

            setPosts((prev) => prev.map((p) => (p.id === postId ? updatedPost : p)));
        } catch (err) {
            console.error('Fehler beim Setzen der Lesebestätigung:', err);
        }
    };

    const handleOpenReadConfirmationDialog = (postFilialeId) => {
        setDialogPostFilialeId(postFilialeId);
        setReadDialogOpen(true);
    };

    const handleCloseReadConfirmationDialog = () => {
        setReadDialogOpen(false);
        setDialogPostFilialeId(null);
    };

    const handleConfirmRead = () => {
        if (dialogPostFilialeId) {
            handleMarkAsRead(dialogPostFilialeId);
        }
        handleCloseReadConfirmationDialog();
    };

    // 2) Aktuellen User laden (nur einmal)
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userResponse = await axiosInstance.get('/auth/user/');
                setCurrentUser(userResponse.data);
                console.log('Aktueller User:', userResponse.data);
            } catch (err) {
                console.error('Fehler beim Laden des aktuellen Nutzers:', err);
            }
        };
        fetchUser();
    }, []);

    // 3) Tags + Posts laden (nur einmal)
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Tags laden
                const tagsResponse = await axiosInstance.get('/filialfeed/tags/');
                const tagData = tagsResponse.data.results || tagsResponse.data;
                const tagNames = tagData.map((tag) => tag.name);

                const tagsWithColors = ['Alle', ...tagNames].map((tagName, index) => ({
                    name: tagName,
                    color: tagColors[index % tagColors.length],
                }));
                setTags(tagsWithColors);

                // Posts laden
                const postsResponse = await axiosInstance.get('/filialfeed/posts/');
                const postsData = postsResponse.data.results || postsResponse.data;

                const now = new Date();
                const visiblePosts = postsData.filter(
                    (p) => new Date(p.publish_date) <= now
                );
                visiblePosts.sort(
                    (a, b) => new Date(b.publish_date) - new Date(a.publish_date)
                );

                // Bild-URLs
                const postsWithImages = await Promise.all(
                    visiblePosts.map(async (post) => {
                        if (post.image_id) {
                            try {
                                const imgResp = await axiosInstance.get(
                                    `/pictures/get-image-url/${post.image_id}/`
                                );
                                return { ...post, image_url: imgResp.data.url };
                            } catch (error) {
                                console.error(
                                    `Fehler beim Laden des Bildes für Post ${post.id}:`,
                                    error
                                );
                                return { ...post, image_url: null };
                            }
                        }
                        return { ...post, image_url: null };
                    })
                );

                setPosts(postsWithImages);
                setLoading(false);
            } catch (err) {
                console.error('Fehler beim Laden der Daten:', err);
                setError('Fehler beim Laden der Daten.');
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Kein [tagColors] => nur 1x beim Mount

    // 4) Kommentar absenden
    const handleCommentSubmit = async (postId) => {
        const commentText = newComments[postId];
        if (!commentText) return;

        try {
            await axiosInstance.post('/filialfeed/comments/', {
                post: postId,
                content: commentText,
            });
            const updatedPostRes = await axiosInstance.get(`/filialfeed/posts/${postId}/`);
            const updatedPost = updatedPostRes.data;
            setPosts((prev) => prev.map((p) => (p.id === postId ? updatedPost : p)));
            setNewComments((prev) => ({ ...prev, [postId]: '' }));
        } catch (err) {
            console.error('Fehler beim Hinzufügen des Kommentars:', err);
        }
    };

    // 5) Poll-Vote
    const handleVote = async (postId, optionId) => {
        try {
            const post = posts.find((p) => p.id === postId);
            if (!post || !post.poll) return;

            await axiosInstance.post('/filialfeed/poll-votes/', {
                poll: post.poll.id,
                option: optionId,
            });
            const updatedPostRes = await axiosInstance.get(`/filialfeed/posts/${postId}/`);
            const updatedPost = updatedPostRes.data;
            setPosts((prev) => prev.map((p) => (p.id === postId ? updatedPost : p)));
        } catch (error) {
            console.error('Fehler beim Abstimmen:', error);
        }
    };

    // Filtern und Paginieren
    const filteredPosts =
        selectedTag === 'Alle'
            ? posts
            : posts.filter((p) => p.tag?.name === selectedTag);

    const startIndex = page * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const pagedPosts = filteredPosts.slice(startIndex, endIndex);

    return (
        <Layout>
            <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <PageHeader
                        title="FilialFeed"
                        subtitle="Eure zentrale Plattform für aktuelle Themen, direkten Austausch und schnelle Umfragen."
                        Icon={AnnouncementOutlinedIcon}
                    />
                    <IconButton color="primary">
                        <Badge badgeContent={notifications} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : pagedPosts.length === 0 ? (
                    <Typography>Keine Posts vorhanden.</Typography>
                ) : (
                    <>
                        <Box display="flex" overflow="auto" mb={2} sx={{ gap: 2 }}>
                            {tags.map((tag) => (
                                <Avatar
                                    key={tag.name}
                                    onClick={() => {
                                        setSelectedTag(tag.name);
                                        setPage(0);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                        width: 80,
                                        height: 80,
                                        bgcolor: selectedTag === tag.name ? tag.color : 'transparent',
                                        color: selectedTag === tag.name ? 'white' : tag.color,
                                        border: `2px solid ${tag.color}`,
                                    }}
                                >
                                    <Typography variant="caption" align="center" color="inherit">
                                        {tag.name}
                                    </Typography>
                                </Avatar>
                            ))}
                        </Box>

                        {pagedPosts.map((post) => {
                            const publishDate = new Date(post.publish_date);
                            const formattedDate = publishDate.toLocaleDateString('de-DE', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                            });

                            return (
                                <Card key={post.id} sx={{ mb: 3 }}>
                                    <CardContent>
                                        <Typography variant="h4">{post.title}</Typography>

                                        <Box mb={1}>
                                            <Chip
                                                label={post.tag?.name || 'Kein Tag'}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Box>

                                        <Typography variant="body2" sx={{ mb: 1 }}>
                                            Von: <strong>{post.sender}</strong> | Veröffentlicht am:{' '}
                                            <strong>{formattedDate}</strong>
                                        </Typography>

                                        {post.image_url && (
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                mb={2}
                                                sx={{ width: '300px', margin: '0 auto' }}
                                            >
                                                <img
                                                    src={post.image_url}
                                                    alt={post.title}
                                                    style={{ width: '100%', borderRadius: 8 }}
                                                />
                                            </Box>
                                        )}

                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                            component="div"
                                            dangerouslySetInnerHTML={{ __html: post.content }}
                                            sx={{ mt: 2 }}
                                        />

                                        {/* Umfrage */}
                                        {post.poll && (
                                            <Box mt={2}>
                                                <Typography variant="subtitle1">Umfrage</Typography>
                                                <Typography variant="body2">{post.poll.question}</Typography>
                                                {post.poll.options.map((option) => (
                                                    <Button
                                                        key={option.id}
                                                        variant={option.is_user_vote ? 'contained' : 'outlined'}
                                                        startIcon={<PollIcon />}
                                                        onClick={() => handleVote(post.id, option.id)}
                                                        sx={{ mr: 1, mt: 1 }}
                                                    >
                                                        {option.option_text} ({option.votes_count})
                                                    </Button>
                                                ))}
                                            </Box>
                                        )}

                                        {/* Kommentare */}
                                        <Box mt={2}>
                                            <Typography variant="subtitle2">Kommentare</Typography>
                                            {post.comments.map((comment) => (
                                                <Typography
                                                    key={comment.id}
                                                    variant="body2"
                                                    sx={{ pl: 2 }}
                                                >
                                                    <strong>{comment.user}</strong>: {comment.content}
                                                </Typography>
                                            ))}

                                            {post.allow_comments ? (
                                                <>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Neuen Kommentar hinzufügen..."
                                                        fullWidth
                                                        value={newComments[post.id] || ''}
                                                        onChange={(e) =>
                                                            setNewComments((prev) => ({
                                                                ...prev,
                                                                [post.id]: e.target.value,
                                                            }))
                                                        }
                                                        sx={{ mt: 1 }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => handleCommentSubmit(post.id)}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        Kommentar hinzufügen
                                                    </Button>
                                                </>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                    Die Kommentarfunktion ist für diesen Post deaktiviert.
                                                </Typography>
                                            )}
                                        </Box>

                                        {/* Lesebestätigung */}
                                        {post.requires_read_confirmation &&
                                            post.postfiliale_set &&
                                            post.postfiliale_set.length > 0 && (
                                                <Box mt={2}>
                                                    {currentUser?.user_type === 'FILIALE' ? (
                                                        (() => {
                                                            // Hier "all_filialen" oder "filialen", je nachdem wie 
                                                            // dein Serializer die Filial-IDs nennt
                                                            const userFilialIDs = currentUser?.all_filialen || [];

                                                            // Passenden PostFiliale-Eintrag finden
                                                            const matchingPF = post.postfiliale_set.find((pf) =>
                                                                userFilialIDs.includes(pf.filiale)
                                                            );

                                                            if (!matchingPF) {
                                                                return (
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Du gehörst nicht zu einer Filiale dieses Posts.
                                                                    </Typography>
                                                                );
                                                            }

                                                            if (matchingPF.is_read) {
                                                                return (
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Bereits gelesen am{' '}
                                                                        {new Date(matchingPF.read_at).toLocaleString()}
                                                                    </Typography>
                                                                );
                                                            } else {
                                                                return (
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            handleOpenReadConfirmationDialog(matchingPF.id)
                                                                        }
                                                                    >
                                                                        Lesebestätigung senden
                                                                    </Button>
                                                                );
                                                            }
                                                        })()
                                                    ) : (
                                                        <Typography variant="body2" color="text.secondary">
                                                            Nur Filial-Mitarbeiter können den Post bestätigen.
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                    </CardContent>
                                </Card>
                            );
                        })}

                        {/* Pagination Buttons */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                            <Button
                                variant="outlined"
                                disabled={page === 0}
                                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                            >
                                Vorherige Seite
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={endIndex >= filteredPosts.length}
                                onClick={() => setPage((prev) => prev + 1)}
                            >
                                Nächste Seite
                            </Button>
                        </Box>
                    </>
                )}
            </Box>

            <Dialog open={readDialogOpen} onClose={handleCloseReadConfirmationDialog}>
                <DialogTitle>Lesebestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du wirklich bestätigen, dass du diesen Post vollständig gelesen hast?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReadConfirmationDialog} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirmRead} variant="contained" color="primary">
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Index;
