import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import VariableTable from '../table/VariableTable';
import axiosInstance from '../../services/axiosInstance';
import moment from 'moment';
import 'moment/locale/de';

// Recharts
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';

const dayNameToPlural = {
  Montag: 'Montage',
  Dienstag: 'Dienstage',
  Mittwoch: 'Mittwoche',
  Donnerstag: 'Donnerstage',
  Freitag: 'Freitage',
  Samstag: 'Samstage',
  Sonntag: 'Sonntage'
};

const TagesreportMainArtikelPopup = ({ open, handleClose, warengruppe, filiale, datum }) => {
  // -----------------------------------------------------
  // (A) Bottom Article Table
  // - Rounded Retourmenge
  // - Now includes Umsatz with 2-decimal comma formatting
  // -----------------------------------------------------
  const [articleData, setArticleData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Update columns to include Umsatz
  const articleColumns = [
    { id: 'Artikelname',   accessorKey: 'Artikelname',   header: 'Artikelname' },
    { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge' },
    { id: 'Retourmenge',   accessorKey: 'Retourmenge',   header: 'Retourmenge' },
    { id: 'Umsatz',        accessorKey: 'Umsatz',        header: 'Umsatz' },
    { id: 'Retourquote',   accessorKey: 'Retourquote',   header: 'Retourquote' },
  ];

  const fetchArticleData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/fact/facts/', {
        params: {
          warengruppenbezeichnung: warengruppe,
          filiale,
          datum
        }
      });

      if (Array.isArray(response.data.results)) {
        const aggregatedData = response.data.results.map(item => {
          const verkaufsmenge = parseFloat(item.verkaufsmenge) || 0;
          const retourmengeRaw = parseFloat(item.retourmenge) || 0;
          const retourmengeRounded = Math.round(retourmengeRaw);

          // Umsatz with 2 decimals, comma for decimal separator
          const umsatzRaw = parseFloat(item.umsatz) || 0;
          const umsatzString = umsatzRaw.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });

          const total = verkaufsmenge + retourmengeRaw;
          // Retourquote with comma decimal
          const quote = total > 0 ? (retourmengeRaw / total) * 100 : 0;
          const retourquoteString = quote.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + ' %';

          return {
            Artikelname:   item.artikel,
            Verkaufsmenge: verkaufsmenge,
            Retourmenge:   retourmengeRounded,  // round to whole number
            Umsatz:        umsatzString,        // 2-decimal comma format
            Retourquote:   retourquoteString
          };
        });

        setArticleData(aggregatedData);
      } else {
        console.error('Unexpected response structure', response.data);
      }
    } catch (error) {
      console.error('Error fetching article data:', error);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------------------------------
  // (B) Chart & Pivot Table (unchanged, except from last code)
  // - Sums of Retourmenge + Umsatz for [datum-35 ... datum]
  // - Retourmenge is rounded in pivot table
  // - Umsatz uses comma decimals
  // -----------------------------------------------------
  const [chartData, setChartData] = useState([]);

  const fetchRetourmengeHistory = async () => {
    // Oldest first => newest last
    const offsets = [35, 28, 21, 14, 7, 0];
    const newChartData = [];

    for (let days of offsets) {
      const thisDateObj = moment(datum).subtract(days, 'days').locale('de');
      const formattedIso = thisDateObj.format('YYYY-MM-DD');
      const formattedDisplay = thisDateObj.format('DD.MM.YYYY');

      try {
        const resp = await axiosInstance.get('/fact/facts/', {
          params: {
            warengruppenbezeichnung: warengruppe,
            filiale,
            datum: formattedIso,
          }
        });

        let retourSum = 0;
        let umsatzSum = 0;
        if (resp.data?.results?.length) {
          resp.data.results.forEach(item => {
            retourSum += parseFloat(item.retourmenge) || 0;
            umsatzSum += parseFloat(item.umsatz) || 0; // Adjust if needed
          });
        }

        newChartData.push({
          date: formattedDisplay, // e.g. "22.01.2025"
          retourmengeSum: retourSum,
          umsatzSum: umsatzSum
        });
      } catch (err) {
        console.error('Error fetching offset data:', err);
        newChartData.push({
          date: formattedDisplay,
          retourmengeSum: 0,
          umsatzSum: 0
        });
      }
    }
    setChartData(newChartData);
  };

  // Day-of-week heading (in plural) for the chart
  const dayOfWeek = moment(datum).locale('de').format('dddd'); 
  const dayNameToPlural = {
    Montag: 'Montage',
    Dienstag: 'Dienstage',
    Mittwoch: 'Mittwoche',
    Donnerstag: 'Donnerstage',
    Freitag: 'Freitage',
    Samstag: 'Samstage',
    Sonntag: 'Sonntage'
  };
  const dayOfWeekPlural = dayNameToPlural[dayOfWeek] || dayOfWeek;

  // For the bottom table heading date
  const bottomHeadingDate = moment(datum).format('DD.MM.YYYY');

  useEffect(() => {
    if (open && warengruppe) {
      fetchArticleData();
      fetchRetourmengeHistory();
    }
  }, [open, warengruppe]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        Details für Warengruppe {warengruppe}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ maxHeight: '70vh' }}>
        {/* (1) The Chart */}
        <h3>Retourmenge der letzten 6 {dayOfWeekPlural}</h3>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="retourmengeSum"
                stroke="#1976d2" // MUI primary color
                name="Retourmenge"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* (2) Pivot Table */}
        <div style={{ marginBottom: '2rem', marginTop: '1rem' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #ccc' }}>
                <th style={{ textAlign: 'left', padding: '6px' }} />
                {chartData.map((point) => (
                  <th
                    key={point.date}
                    style={{
                      textAlign: 'center',
                      padding: '6px',
                      fontSize: '0.85rem'
                    }}
                  >
                    {point.date}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Retourmenge row (rounded to whole numbers) */}
              <tr style={{ borderBottom: '1px solid #eee' }}>
                <td style={{ textAlign: 'left', padding: '6px' }}>Retourmenge</td>
                {chartData.map((point) => {
                  const retourRounded = Math.round(point.retourmengeSum);
                  return (
                    <td
                      key={`retour_${point.date}`}
                      style={{ textAlign: 'center', padding: '6px' }}
                    >
                      {retourRounded}
                    </td>
                  );
                })}
              </tr>
              {/* Umsatz row (comma decimals) */}
              <tr style={{ borderBottom: '1px solid #eee' }}>
                <td style={{ textAlign: 'left', padding: '6px' }}>Umsatz</td>
                {chartData.map((point) => {
                  const umsatzComma = point.umsatzSum.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  return (
                    <td
                      key={`umsatz_${point.date}`}
                      style={{ textAlign: 'center', padding: '6px' }}
                    >
                      {umsatzComma}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>

        {/* (3) Bottom Table with heading (includes Umsatz now) */}
        <h3>Artikelebene für den {bottomHeadingDate}</h3>
        <VariableTable data={articleData} columns={articleColumns} />
      </DialogContent>
    </Dialog>
  );
};

export default TagesreportMainArtikelPopup;
