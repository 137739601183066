import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from "@mui/icons-material/Store";
import WindowIcon from '@mui/icons-material/Window';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';



const TelefonlisteLayout = ({children}) => {
  const menuItems = [
    {text: 'Übersicht', path: '/telefonliste/overview/index', icon: <WindowIcon/>, subModule: "overview"},
    {text: 'Konfiguration', path: '/telefonliste/settings/index', icon: <BuildOutlinedIcon/>, subModule: "settings"},


  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"telefonliste"} menuItems={menuItems} title={'Telefonliste'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default TelefonlisteLayout;
