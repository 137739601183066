import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import { Tab, Tabs, Box } from '@mui/material';

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
    if (value === null || value === undefined) return '';
    return value
        .toFixed(fractionDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const WarengruppePopup = ({ open, onClose, warengruppe, filterState }) => {
    const [tabIndex, setTabIndex] = useState(0);

    // Filial-Tab
    const [filialeData, setFilialeData] = useState([]);
    const [filialeColumns, setFilialeColumns] = useState([]);

    // Artikel-Tab
    const [artikelData, setArtikelData] = useState([]);
    const [artikelColumns, setArtikelColumns] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            fetchFilialeData();
            fetchArtikelData();
        }
        
    }, [open]);

    const fetchFilialeData = async () => {
        try {
            setLoading(true);
            const jahrKw = filterState.datumWoche?.value;

            // API-Aufruf für Filialdaten (z.B. /scorecard_week/ware-warengruppe-woche/)
            // Hier interpretieren wir "verkaufsmenge" anstelle von retourwert oder retourquote.
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: jahrKw,
                    warengruppe: warengruppe,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            // Verarbeitung der Daten
            const data = response.data.results.map((item, index) => ({
                id: index,
                filiale: item.filiale || 'Unbekannt',
                // Beispiel: verkaufsmenge ausliefern
                verkaufsmenge: formatNumber(item.verkaufsmenge || 0),
                // Ggf. wenn du Vorjahresmengen o.ä. hast:
                // verkaufsmengeVJ: formatNumber(item.verkaufsmengeVJ || 0),
            }));

            // Optional: Nur Filialen mit verkaufsmenge > 0
            const filteredData = data.filter(
                (row) => parseFloat(row.verkaufsmenge.replace(',', '.')) > 0
            );

            const columns = [
                { accessorKey: 'filiale', header: 'Filiale', align: 'left' },
                { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge', align: 'right' },
            ];

            setFilialeData(filteredData);
            setFilialeColumns(columns);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Filialdaten:', error);
            setLoading(false);
        }
    };

    const fetchArtikelData = async () => {
        try {
            setLoading(true);
            const jahrKw = filterState.datumWoche?.value;

            // API-Aufruf für Artikeldaten z.B. /scorecard_week_article/ware-artikel-woche/
            const response = await axiosInstance.get('/scorecard_week_article/ware-artikel-woche/', {
                params: {
                    jahrKw: jahrKw,
                    warengruppe: warengruppe,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            // Aggregation pro Artikel
            const aggregatedData = {};
            response.data.results.forEach((item) => {
                const artikel = item.artikelnummer?.artikel || 'Unbekannt';
                if (!aggregatedData[artikel]) {
                    aggregatedData[artikel] = {
                        verkaufsmenge: 0,
                    };
                }
                aggregatedData[artikel].verkaufsmenge += item.verkaufsmenge || 0;
            });

            // Umwandeln in ein Array
            const data = Object.keys(aggregatedData).map((artikel, index) => ({
                id: index,
                artikel,
                verkaufsmenge: formatNumber(aggregatedData[artikel].verkaufsmenge),
            }));

            const columns = [
                { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
                { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge', align: 'right' },
            ];

            setArtikelData(data);
            setArtikelColumns(columns);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Artikeldaten:', error);
            setLoading(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Popup
            open={open}
            title={`Details für Warengruppe ${warengruppe}`}
            onClose={onClose}
            fullWidth
            maxWidth={false}
        >
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Filiale" />
                <Tab label="Artikel" />
            </Tabs>
            <Box sx={{ mt: 2 }}>
                {tabIndex === 0 && (
                    <BigTableCard
                        data={filialeData}
                        columns={filialeColumns}
                        loading={loading}
                    />
                )}
                {tabIndex === 1 && (
                    <BigTableCard
                        data={artikelData}
                        columns={artikelColumns}
                        loading={loading}
                    />
                )}
            </Box>
        </Popup>
    );
};

export default WarengruppePopup;
