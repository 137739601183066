import React, { useEffect, useState } from 'react';
import { 
  Box, Grid, TextField, Paper, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, TablePagination, Typography, Divider
} from '@mui/material';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import FilterListIcon from '@mui/icons-material/FilterList';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import axiosInstance from '../../../services/axiosInstance';
import { Autocomplete, Checkbox } from '@mui/material';

// Date/Time Picker Imports
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const FilterOverview = () => {
  // Abschnitt 1: Filialen, Verkaufsgebiete, Aktiv-Filter
  const [filialenOptions, setFilialenOptions] = useState([]); 
  const [allFilialData, setAllFilialData] = useState([]);    
  const [selectedFiliale, setSelectedFiliale] = useState(null);
  const [selectedFilialenMulti, setSelectedFilialenMulti] = useState([]);

  const [verkaufsgebieteOptions, setVerkaufsgebieteOptions] = useState([]); 
  const [allVerkaufsgebieteData, setAllVerkaufsgebieteData] = useState([]);  
  const [selectedVerkaufsgebiet, setSelectedVerkaufsgebiet] = useState(null);
  const [selectedVerkaufsgebieteMulti, setSelectedVerkaufsgebieteMulti] = useState([]);

  const aktivOptions = [
    { name: 'alle', label: 'Alle' },
    { name: 'ja', label: 'Ja' },
    { name: 'nein', label: 'Nein' },
  ];
  const [selectedAktiv, setSelectedAktiv] = useState(aktivOptions[0]); 

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Abschnitt 2: Artikel & Warengruppen
  const [artikelOptions, setArtikelOptions] = useState([]);
  const [allArtikelData, setAllArtikelData] = useState([]);
  const [selectedArtikel, setSelectedArtikel] = useState(null);
  const [selectedArtikelMulti, setSelectedArtikelMulti] = useState([]);

  const [artikelPage, setArtikelPage] = useState(0);
  const [artikelRowsPerPage, setArtikelRowsPerPage] = useState(10);

  // Abschnitt 3: Jahr/Monat (Datum)
  const [jahrMonatOptions, setJahrMonatOptions] = useState([]);
  const [selectedJahrMonat, setSelectedJahrMonat] = useState(null);

  // Wochentag (Mehrfach) & Jahr/Woche (Einfach)
  const [wochentagOptions, setWochentagOptions] = useState([]);
  const [selectedWochentagMulti, setSelectedWochentagMulti] = useState([]);
  const [jahrWocheOptions, setJahrWocheOptions] = useState([]);
  const [selectedJahrWoche, setSelectedJahrWoche] = useState(null);

  // Date-Filter
  const [dateRange, setDateRange] = useState([null, null]);
  const [singleDate, setSingleDate] = useState(null);

  // Kalenderdaten
  const [allCalendarData, setAllCalendarData] = useState([]); // Alle Kalenderdaten einmal laden
  const [calendarData, setCalendarData] = useState([]);       // Gefilterte Kalenderdaten
  const [calendarPage, setCalendarPage] = useState(0);
  const [calendarRowsPerPage, setCalendarRowsPerPage] = useState(10);

  useEffect(() => {
    // Filialen-Daten laden
    axiosInstance.get('/stammdaten/stammdatenfiliale/')
      .then(response => {
        const data = response.data;
        if (data && data.results) {
          const options = data.results.map(item => ({
            name: item.filialnummer.toString(),
            label: item.filiale,
          }));
          setFilialenOptions(options);
          setAllFilialData(data.results);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Filialdaten:', err));

    // Verkaufsgebiete-Daten laden
    axiosInstance.get('/stammdaten/verkaufsgebiete/')
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          const vgOptions = data.map(item => ({
            name: item.vkg_ID,
            label: item.verkaufsgebiet,
          }));
          setVerkaufsgebieteOptions(vgOptions);
          setAllVerkaufsgebieteData(data);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Verkaufsgebiete:', err));

    // Artikel-Daten laden
    axiosInstance.get('/stammdaten/stammdatenartikel/')
      .then(response => {
        const data = response.data;
        if (data && data.results) {
          const artikelOpts = data.results.map(item => ({
            name: item.artikelnummer.toString(),
            label: item.artikel
          }));
          setArtikelOptions(artikelOpts);
          setAllArtikelData(data.results);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Artikeldaten:', err));

    // Jahr/Monat-Daten laden
    axiosInstance.get('/stammdaten/unique-jahr-monat/')
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          const jahrMonatOpts = data.map(value => ({ name: value, label: value }));
          setJahrMonatOptions(jahrMonatOpts);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Jahr/Monat-Daten:', err));

    // Wochentag-Daten laden
    axiosInstance.get('/stammdaten/unique-wochentag/')
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          const wochentagOpts = data.map(value => ({ name: value, label: value }));
          setWochentagOptions(wochentagOpts);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Wochentag-Daten:', err));

    // Jahr/Woche-Daten laden
    axiosInstance.get('/stammdaten/unique-jahr-woche/')
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          const jahrWocheOpts = data.map(value => ({ name: value, label: value }));
          setJahrWocheOptions(jahrWocheOpts);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Jahr/Woche-Daten:', err));

    // Kalenderdaten einmal komplett laden
    axiosInstance.get('/stammdaten/kalender/')
      .then(response => {
        const data = response.data;
        if (data && data.results) {
          setAllCalendarData(data.results);
        }
      })
      .catch(err => console.error('Fehler beim Laden der Kalenderdaten:', err));
  }, []);

  // Alle Filter auf Kalenderdaten anwenden
  useEffect(() => {
    let filtered = [...allCalendarData];

    // singleDate-Filter
    if (singleDate) {
      const selectedDateStr = dayjs(singleDate).format('YYYY-MM-DD');
      filtered = filtered.filter(item => dayjs(item.datum).format('YYYY-MM-DD') === selectedDateStr);
    }

    // dateRange-Filter
    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(item => {
        const itemDate = dayjs(item.datum);
        return itemDate.isSameOrAfter(dateRange[0], 'day') && itemDate.isSameOrBefore(dateRange[1], 'day');
      });
    }

    // Jahr/Monat-Filter
    if (selectedJahrMonat) {
      filtered = filtered.filter(item => item.jahrMonat === selectedJahrMonat.name);
    }

    // Jahr/Woche-Filter
    if (selectedJahrWoche) {
      filtered = filtered.filter(item => item.jahrKw === selectedJahrWoche.name);
    }

    // Wochentag-Filter (Mehrfach)
    if (selectedWochentagMulti.length > 0) {
      const wochentagValues = selectedWochentagMulti.map(w => w.name);
      filtered = filtered.filter(item => wochentagValues.includes(item.wochentag));
    }

    setCalendarData(filtered);
    setCalendarPage(0);
  }, [allCalendarData, singleDate, dateRange, selectedJahrMonat, selectedJahrWoche, selectedWochentagMulti]);

  // Handler für Filteränderungen
  const handleFilialeChange = (newValue) => {
    setSelectedFiliale(newValue);
    setPage(0); 
  };

  const handleFilialenMultiChange = (event, newValues) => {
    setSelectedFilialenMulti(newValues || []);
    setPage(0);
  };

  const handleVerkaufsgebietChange = (newValue) => {
    setSelectedVerkaufsgebiet(newValue);
    setPage(0);
  };

  const handleVerkaufsgebieteMultiChange = (event, newValues) => {
    setSelectedVerkaufsgebieteMulti(newValues || []);
    setPage(0);
  };

  const handleAktivChange = (newValue) => {
    setSelectedAktiv(newValue);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleArtikelChange = (newValue) => {
    setSelectedArtikel(newValue);
    setArtikelPage(0);
  };

  const handleArtikelMultiChange = (event, newValues) => {
    setSelectedArtikelMulti(newValues || []);
    setArtikelPage(0);
  };

  const handleArtikelChangePage = (event, newPage) => {
    setArtikelPage(newPage);
  };

  const handleArtikelChangeRowsPerPage = (event) => {
    setArtikelRowsPerPage(parseInt(event.target.value, 10));
    setArtikelPage(0);
  };

  const handleJahrMonatChange = (newValue) => {
    setSelectedJahrMonat(newValue);
  };

  const handleWochentagMultiChange = (event, newValues) => {
    setSelectedWochentagMulti(newValues || []);
  };

  const handleJahrWocheChange = (newValue) => {
    setSelectedJahrWoche(newValue);
  };

  // Daten für Filialen-Abschnitt filtern
  let baseData = [];
  if (selectedVerkaufsgebiet || selectedVerkaufsgebieteMulti.length > 0) {
    // Einzelverkaufsgebiet
    if (selectedVerkaufsgebiet) {
      const vg = allVerkaufsgebieteData.find(v => v.vkg_ID === selectedVerkaufsgebiet.name);
      if (vg && Array.isArray(vg.filialen)) {
        baseData = vg.filialen;
      }
    }
    // Mehrfachverkaufsgebiete
    if (selectedVerkaufsgebieteMulti.length > 0) {
      const allSelectedVG = [...(selectedVerkaufsgebiet ? [selectedVerkaufsgebiet] : []), ...selectedVerkaufsgebieteMulti];
      const uniqueVGIDs = Array.from(new Set(allSelectedVG.map(v => v.name)));
      let combinedData = [];
      uniqueVGIDs.forEach(vgId => {
        const vg = allVerkaufsgebieteData.find(v => v.vkg_ID === vgId);
        if (vg && Array.isArray(vg.filialen)) {
          combinedData = [...combinedData, ...vg.filialen];
        }
      });
      if (selectedVerkaufsgebiet) {
        baseData = [...baseData, ...combinedData];
      } else {
        baseData = combinedData;
      }
    }
  } else {
    // Keine Verkaufsgebiete gewählt -> alle Filialen aus allen VG
    allVerkaufsgebieteData.forEach(vg => {
      if (Array.isArray(vg.filialen)) {
        baseData = [...baseData, ...vg.filialen];
      }
    });
  }

  if (selectedFiliale) {
    baseData = baseData.filter(f => f.filialnummer.toString() === selectedFiliale.name);
  }

  if (selectedFilialenMulti.length > 0) {
    const selectedNames = selectedFilialenMulti.map(sel => sel.name);
    baseData = baseData.filter(f => selectedNames.includes(f.filialnummer.toString()));
  }

  if (selectedAktiv && selectedAktiv.name !== 'alle') {
    if (selectedAktiv.name === 'ja') {
      baseData = baseData.filter(f => f.aktiv === true);
    } else if (selectedAktiv.name === 'nein') {
      baseData = baseData.filter(f => f.aktiv === false);
    }
  }

  const uniqueFilialMap = new Map();
  for (const fil of baseData) {
    uniqueFilialMap.set(fil.filialnummer, fil);
  }
  const filteredData = Array.from(uniqueFilialMap.values());
  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Daten für Artikel-Abschnitt filtern
  let artikelBaseData = [...allArtikelData];

  if (selectedArtikel) {
    artikelBaseData = artikelBaseData.filter(a => a.artikelnummer.toString() === selectedArtikel.name);
  }

  if (selectedArtikelMulti.length > 0) {
    const selectedArtikelNames = selectedArtikelMulti.map(sel => sel.name);
    artikelBaseData = artikelBaseData.filter(a => selectedArtikelNames.includes(a.artikelnummer.toString()));
  }

  const artikelPaginatedData = artikelBaseData.slice(artikelPage * artikelRowsPerPage, artikelPage * artikelRowsPerPage + artikelRowsPerPage);

  // Kalenderdaten paginieren
  const calendarPaginatedData = calendarData.slice(calendarPage * calendarRowsPerPage, calendarPage * calendarRowsPerPage + calendarRowsPerPage);

  const handleCalendarChangePage = (event, newPage) => {
    setCalendarPage(newPage);
  };

  const handleCalendarChangeRowsPerPage = (event) => {
    setCalendarRowsPerPage(parseInt(event.target.value, 10));
    setCalendarPage(0);
  };

  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader 
          title="Filter-Vorlagen" 
          subtitle="Erstellen und verwalten Sie Filter für Ihre Qualitätschecks."
          Icon={FilterListIcon}
        />

        {/* Abschnitt 1: Filialen und Verkaufsgebiete */}
        <Typography variant="h5" sx={{ mt:3, mb:2 }}>Filialen & Verkaufsgebiete</Typography>
        <Grid container spacing={2}>
          {/* Filiale - Einfach */}
          <Grid item xs={12} md={3}>
            <DropdownFilter
              label="Filiale (Einfach)"
              options={filialenOptions}
              value={selectedFiliale}
              onChange={handleFilialeChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>
          
          {/* Filiale - Mehrfach (mit Checkbox) */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              id="filialen-multi-select"
              options={filialenOptions}
              getOptionLabel={(option) => option.label || ''}
              value={selectedFilialenMulti}
              onChange={handleFilialenMultiChange}
              isOptionEqualToValue={(option, val) => option.name === val.name}
              disableCloseOnSelect
              openOnFocus
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filialen (Mehrfach)"
                  size="small"
                />
              )}
              sx={{ width: 230, marginBottom: 2 }}
            />
          </Grid>

          {/* Verkaufsgebiet - Einfach */}
          <Grid item xs={12} md={3}>
            <DropdownFilter
              label="Verkaufsgebiet (Einfach)"
              options={verkaufsgebieteOptions}
              value={selectedVerkaufsgebiet}
              onChange={handleVerkaufsgebietChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>

          {/* Verkaufsgebiet - Mehrfach (mit Checkbox) */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              id="verkaufsgebiete-multi-select"
              options={verkaufsgebieteOptions}
              getOptionLabel={(option) => option.label || ''}
              value={selectedVerkaufsgebieteMulti}
              onChange={handleVerkaufsgebieteMultiChange}
              isOptionEqualToValue={(option, val) => option.name === val.name}
              disableCloseOnSelect
              openOnFocus
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Verkaufsgebiete (Mehrfach)"
                  size="small"
                />
              )}
              sx={{ width: 230, marginBottom: 2 }}
            />
          </Grid>

          {/* Aktiv - Einfach (Ja/Nein/Alle) */}
          <Grid item xs={12} md={3}>
            <DropdownFilter
              label="Aktiv-Status"
              options={aktivOptions}
              value={selectedAktiv}
              onChange={handleAktivChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>

          {/* Tabelle mit gefilterten Filialen + Pagination */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Filialnummer</TableCell>
                    <TableCell>Filiale</TableCell>
                    <TableCell>Verkaufsgebiet</TableCell>
                    <TableCell>Filialtyp</TableCell>
                    <TableCell>Aktiv</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((filiale) => (
                    <TableRow key={filiale.filialnummer}>
                      <TableCell>{filiale.filialnummer}</TableCell>
                      <TableCell>{filiale.filiale}</TableCell>
                      <TableCell>{filiale.verkaufsgebiet}</TableCell>
                      <TableCell>{filiale.filialtyp}</TableCell>
                      <TableCell>{filiale.aktiv ? 'Ja' : 'Nein'}</TableCell>
                    </TableRow>
                  ))}
                  {paginatedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Keine Filialen gefunden.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Zeilen pro Seite"
              />
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ my:4 }} />

        {/* Abschnitt 2: Artikel & Warengruppen */}
        <Typography variant="h5" sx={{ mt:3, mb:2 }}>Artikel & Warengruppen</Typography>
        <Grid container spacing={2}>
          {/* Artikel - Einfach */}
          <Grid item xs={12} md={3}>
            <DropdownFilter
              label="Artikel (Einfach)"
              options={artikelOptions}
              value={selectedArtikel}
              onChange={handleArtikelChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>

          {/* Artikel - Mehrfach (mit Checkbox) */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              id="artikel-multi-select"
              options={artikelOptions}
              getOptionLabel={(option) => option.label || ''}
              value={selectedArtikelMulti}
              onChange={handleArtikelMultiChange}
              isOptionEqualToValue={(option, val) => option.name === val.name}
              disableCloseOnSelect
              openOnFocus
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Artikel (Mehrfach)"
                  size="small"
                />
              )}
              sx={{ width: 230, marginBottom: 2 }}
            />
          </Grid>

          {/* Tabelle mit gefilterten Artikeln + Pagination */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Artikelnummer</TableCell>
                    <TableCell>Artikel</TableCell>
                    <TableCell>Artikelgruppe</TableCell>
                    <TableCell>Warengruppe</TableCell>
                    <TableCell>Gewicht</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {artikelPaginatedData.map((art) => (
                    <TableRow key={art.artikelnummer}>
                      <TableCell>{art.artikelnummer}</TableCell>
                      <TableCell>{art.artikel}</TableCell>
                      <TableCell>{art.artikelgruppennummer?.artikelgruppe}</TableCell>
                      <TableCell>{art.warengruppennummer?.warengruppe}</TableCell>
                      <TableCell>{art.gewicht}</TableCell>
                    </TableRow>
                  ))}
                  {artikelPaginatedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Keine Artikel gefunden.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={artikelBaseData.length}
                rowsPerPage={artikelRowsPerPage}
                page={artikelPage}
                onPageChange={handleArtikelChangePage}
                onRowsPerPageChange={handleArtikelChangeRowsPerPage}
                labelRowsPerPage="Zeilen pro Seite"
              />
            </TableContainer>
          </Grid>

          {/* Jahr/Monat Filter (Einfach) */}
          <Grid item xs={12} md={3} sx={{ mt:2 }}>
            <DropdownFilter
              label="Jahr/Monat auswählen"
              options={jahrMonatOptions}
              value={selectedJahrMonat}
              onChange={handleJahrMonatChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>

          {/* Wochentag Filter (Mehrfach) mit Checkbox */}
          <Grid item xs={12} md={3} sx={{ mt:2 }}>
            <Autocomplete
              multiple
              id="wochentag-multi-select"
              options={wochentagOptions}
              getOptionLabel={(option) => option.label || ''}
              value={selectedWochentagMulti}
              onChange={handleWochentagMultiChange}
              isOptionEqualToValue={(option, val) => option.name === val.name}
              disableCloseOnSelect
              openOnFocus
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Wochentage (Mehrfach)"
                  size="small"
                />
              )}
              sx={{ width: 230, marginBottom: 2 }}
            />
          </Grid>

          {/* Jahr/Woche Filter (Einfach) */}
          <Grid item xs={12} md={3} sx={{ mt:2 }}>
            <DropdownFilter
              label="Jahr/Woche auswählen"
              options={jahrWocheOptions}
              value={selectedJahrWoche}
              onChange={handleJahrWocheChange}
              useNameAsLabel={false}
              multiple={false}
            />
          </Grid>
        </Grid>

        {/* DateRangePicker und DatePicker */}
        <Grid container spacing={2} sx={{ mt:4 }}>
          <Grid item xs={12}>
            <h3>Datum / Zeitraum auswählen</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <DateRangePicker
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                slots={{ field: SingleInputDateRangeField }}
                calendars={1}
                slotProps={{
                  shortcuts: {
                    items: [
                      { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                      { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                      { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                      { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                      { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                      { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                      { label: 'Zurücksetzen', getValue: () => [null, null] },
                    ],
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px',
                    minWidth: '250px',
                    fontSize: '0.875rem',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.2rem',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt:2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <DatePicker
                label="Datum auswählen"
                value={singleDate}
                onChange={(newValue) => setSingleDate(newValue)}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px',
                    minWidth: '250px',
                    fontSize: '0.875rem',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.2rem',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {/* Tabelle mit Kalenderdaten (alle Datumsfilter wirken) */}
        <Grid container spacing={2} sx={{ mt:4 }}>
          <Grid item xs={12}>
            <Typography variant="h5">Kalenderdaten (gefiltert durch alle Datumsfilter)</Typography>
            <TableContainer component={Paper} sx={{ mt:2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Jahr</TableCell>
                    <TableCell>Wochentag</TableCell>
                    <TableCell>Jahr/Monat</TableCell>
                    <TableCell>Monatsname</TableCell>
                    <TableCell>Kalenderwoche</TableCell>
                    <TableCell>Jahr/KW</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calendarPaginatedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{dayjs(row.datum).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{row.jahr}</TableCell>
                      <TableCell>{row.wochentag}</TableCell>
                      <TableCell>{row.jahrMonat}</TableCell>
                      <TableCell>{row.monatsname}</TableCell>
                      <TableCell>{row.kalenderwoche}</TableCell>
                      <TableCell>{row.jahrKw}</TableCell>
                    </TableRow>
                  ))}
                  {calendarPaginatedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        Keine Kalenderdaten gefunden.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={calendarData.length}
                rowsPerPage={calendarRowsPerPage}
                page={calendarPage}
                onPageChange={handleCalendarChangePage}
                onRowsPerPageChange={handleCalendarChangeRowsPerPage}
                labelRowsPerPage="Zeilen pro Seite"
              />
            </TableContainer>
          </Grid>
        </Grid>

      </Box>
    </Layout>
  );
};

export default FilterOverview;
