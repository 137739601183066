import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, TextField, MenuItem, Grid } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

// MUI X Date-Picker
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';

// Layout & UI-Komponenten
import Layout from '../../../components/layout/Frequenz/frequenz';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BigTableCard from '../../../components/card/BigTableCard';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import BigChartCardFrequenz from '../../../components/card/frequenz/BigChartCardFrequenz';

// Services
import axiosInstance from '../../../services/axiosInstance';

// Neu: Für die Mehrfachauswahl der Wochentage
import Autocomplete from '@mui/material/Autocomplete';

const Kundenstatistik = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  // Filter states
  const [filiale, setFiliale] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  // Mehrfachauswahl Wochentage
  const [wochentage, setWochentage] = useState([]);
  const [displayOption, setDisplayOption] = useState('umsatz');

  // Options
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [wochentagOptions] = useState([
    { label: 'Montag', value: 'Montag' },
    { label: 'Dienstag', value: 'Dienstag' },
    { label: 'Mittwoch', value: 'Mittwoch' },
    { label: 'Donnerstag', value: 'Donnerstag' },
    { label: 'Freitag', value: 'Freitag' },
    { label: 'Samstag', value: 'Samstag' },
    { label: 'Sonntag', value: 'Sonntag' },
  ]);

  const [columns, setColumns] = useState([]);
  const [averageData, setAverageData] = useState([]); // Zustand für die Durchschnittsdaten

  // Daten abrufen
  const fetchData = async () => {
    setLoading(true);
    try {
      // ---------------------------------------------
      // 1) Manuell den Query-String mit URLSearchParams bauen
      // ---------------------------------------------
      const searchParams = new URLSearchParams();

      // Filiale
      if (filiale) {
        searchParams.append('filiale', filiale.value);
      }

      // Start- und Enddatum
      if (dateRange[0]) {
        searchParams.append('startdatum', dayjs(dateRange[0]).format('YYYY-MM-DD'));
      }
      if (dateRange[1]) {
        searchParams.append('enddatum', dayjs(dateRange[1]).format('YYYY-MM-DD'));
      }

      // Wochentage (Array)
      // => wochentag=Montag&wochentag=Dienstag (ohne eckige Klammern)
      if (wochentage.length) {
        wochentage.forEach((wt) => {
          searchParams.append('wochentag', wt.value);
        });
      }

      // 2) Erzeuge finale URL
      const url = '/frequenz/kundenstatistik/?' + searchParams.toString();

      // 3) GET-Request
      const response = await axiosInstance.get(url);

      // ---------------------------------------------
      // 4) response verarbeiten (falsche Mehrfach-Zeilen beheben)
      // ---------------------------------------------
      // Wir bauen pro (uhrzeit + Datum) eine eindeutige Summe auf, damit pro Tag/Uhrzeit
      // nur ein Wert existiert – selbst wenn das Backend mehrere Zeilen liefert.
      const hourDayMap = {}; // key = "HH:MM:SS-DD.MM.YYYY", value = Summenwert
      const uniqueDates = new Set(); // zum Speichern aller Datum-Werte

      response.data.results.forEach((row) => {
        const dateStr = dayjs(row.datum).format('DD.MM.YYYY');
        uniqueDates.add(dateStr);

        const hour = row.uhrzeit;
        const key = `${hour}-${dateStr}`;

        // Wert extrahieren (Umsatz oder Kundenanzahl)
        const value = displayOption === 'umsatz'
          ? parseFloat(row.umsatz)
          : parseInt(row.kundenanzahl || '0', 10);

        // Aufsummieren
        if (!hourDayMap[key]) {
          hourDayMap[key] = 0;
        }
        hourDayMap[key] += value;
      });

      // Jetzt summieren wir pro Uhrzeit ALLE Tage
      // => "hourTotals[uhrzeit]" = Gesamtsumme über alle Tage
      // => "hourCounts[uhrzeit]" = Anzahl Tage (nicht Rows!)
      const hourTotals = {};
      const hourCounts = {};

      // hourDayMap: key = "HH:MM:SS-DD.MM.YYYY"
      Object.entries(hourDayMap).forEach(([key, sum]) => {
        const [uhrzeit] = key.split('-'); // "06:00:00"
        if (!hourTotals[uhrzeit]) {
          hourTotals[uhrzeit] = 0;
          hourCounts[uhrzeit] = 0;
        }
        hourTotals[uhrzeit] += sum;
        // Pro Tag ein Zähler
        hourCounts[uhrzeit] += 1;
      });

      // Datenstruktur für Tabelle erstellen:
      // groupedData[uhrzeit] = { uhrzeit, "30.12.2024": x, "31.12.2024": y, ... }
      const groupedData = {};

      // Wir müssen hourDayMap in eine taugliche Tabellenform überführen
      Object.keys(hourDayMap).forEach((k) => {
        // k = "06:00:00-30.12.2024"
        const [uhrzeit, dateStr] = k.split('-');
        if (!groupedData[uhrzeit]) {
          groupedData[uhrzeit] = { uhrzeit };
        }
        // Summierter Wert für diese Stunde und diesen Tag
        const sum = hourDayMap[k];
        if (displayOption === 'umsatz') {
          groupedData[uhrzeit][dateStr] = sum
            ? `${sum.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} €`
            : 'NaN €';
        } else {
          groupedData[uhrzeit][dateStr] = sum?.toLocaleString('de-DE') || '0';
        }
      });

      // Aus hourTotals / hourCounts => calculatedAverageData (für Diagramm)
      const calculatedAverageData = Object.keys(hourTotals)
        .map((hour) => ({
          uhrzeit: hour,
          average: hourTotals[hour] / hourCounts[hour],
        }))
        .sort((a, b) => {
          const timeA = dayjs(a.uhrzeit, 'HH:mm');
          const timeB = dayjs(b.uhrzeit, 'HH:mm');
          return timeA.isBefore(timeB) ? -1 : 1;
        });

      // Sortierte Tabelle (nach Uhrzeit)
      const sortedData = Object.values(groupedData).sort((a, b) => {
        const timeA = dayjs(a.uhrzeit, 'HH:mm');
        const timeB = dayjs(b.uhrzeit, 'HH:mm');
        return timeA.isBefore(timeB) ? -1 : 1;
      });

      // ---------------------------------------------
      // 5) Neue Spalte "Durchschnitt" füllen
      // ---------------------------------------------
      sortedData.forEach((rowItem) => {
        const avgObj = calculatedAverageData.find(
          (avgItem) => avgItem.uhrzeit === rowItem.uhrzeit
        );
        if (!avgObj) {
          rowItem.durchschnitt = '-';
          return;
        }
        if (displayOption === 'umsatz') {
          rowItem.durchschnitt = `${avgObj.average.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} €`;
        } else {
          // Kundenanzahl eher ohne Nachkommastellen
          rowItem.durchschnitt = avgObj.average.toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      });

      // ---------------------------------------------
      // 6) Dynamic Columns um Durchschnitt-Spalte ergänzen
      // ---------------------------------------------
      const uniqueDatesArray = Array.from(uniqueDates).sort((a, b) =>
        dayjs(a, 'DD.MM.YYYY').isBefore(dayjs(b, 'DD.MM.YYYY')) ? -1 : 1
      );

      const dynamicColumns = [
        { accessorKey: 'uhrzeit', header: 'Uhrzeit' },
        ...uniqueDatesArray.map((dateStr) => ({
          accessorKey: dateStr,
          header: dateStr,
        })),
        {
          accessorKey: 'durchschnitt',
          header: 'Durchschnitt',
        },
      ];

      setColumns(dynamicColumns);
      setData(sortedData);
      setRowCount(response.data.count || 0);
      setAverageData(calculatedAverageData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Optionen für Filiale abrufen
  const fetchFilialeOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-filiale/');
      setFilialeOptions(
        response.data.map((item) => ({
          label: item.filiale,
          value: item.filiale,
        }))
      );
    } catch (error) {
      console.error('Error fetching filiale options:', error);
    }
  };

  // Initiales Laden
  useEffect(() => {
    fetchData(); 
    fetchFilialeOptions();
   
  }, []);

  // Wenn Filter geändert werden -> Daten neu laden
  useEffect(() => {
    fetchData();
   
  }, [displayOption, filiale, dateRange, wochentage]);

  // Filter zurücksetzen
  const resetFilters = () => {
    setFiliale(null);
    setDateRange([null, null]);
    setWochentage([]); // Array leeren
    setDisplayOption('umsatz');
    fetchData();
  };

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <PageHeader
            title="Umsatz- und Kundenfrequenz"
            subtitle="Dieser Bericht zeigt die Tagesverläufe der Umsätze und Kunden auf Filialebene."
            Icon={ViewTimelineOutlinedIcon}
          />
        </Box>

        {/* Filter Section */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 3 }}>
          {/* Filiale */}
          <Autocomplete
            options={filialeOptions}
            getOptionLabel={(option) => option.label}
            value={filiale}
            onChange={(event, newValue) => setFiliale(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filiale"
                size="small"
                sx={{ width: 200 }}
              />
            )}
          />

          {/* Datumsbereich */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DateRangePicker
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              slots={{ field: SingleInputDateRangeField }}
              calendars={1}
              slotProps={{
                shortcuts: {
                  items: [
                    {
                      label: 'Diese Woche',
                      getValue: () => [
                        dayjs().startOf('isoWeek'),
                        dayjs().endOf('isoWeek'),
                      ],
                    },
                    {
                      label: 'Letzte Woche',
                      getValue: () => [
                        dayjs().subtract(1, 'week').startOf('isoWeek'),
                        dayjs().subtract(1, 'week').endOf('isoWeek'),
                      ],
                    },
                    {
                      label: 'Letzte 6 Wochen',
                      getValue: () => [
                        dayjs().subtract(6, 'week').startOf('isoWeek'),
                        dayjs(),
                      ],
                    },
                    {
                      label: 'Letzter Monat',
                      getValue: () => [
                        dayjs().subtract(1, 'month').startOf('month'),
                        dayjs().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    {
                      label: 'Letzte 6 Monate',
                      getValue: () => [
                        dayjs().subtract(6, 'month').startOf('month'),
                        dayjs().endOf('month'),
                      ],
                    },
                    {
                      label: 'Letzte 12 Monate',
                      getValue: () => [
                        dayjs().subtract(12, 'month').startOf('month'),
                        dayjs().endOf('month'),
                      ],
                    },
                    { label: 'Zurücksetzen', getValue: () => [null, null] },
                  ],
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minWidth: '250px',
                  fontSize: '0.875rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
            />
          </LocalizationProvider>

          {/* Mehrfachauswahl Wochentage */}
          <Autocomplete
            multiple
            options={wochentagOptions}
            getOptionLabel={(option) => option.label}
            value={wochentage}
            onChange={(event, newValue) => setWochentage(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Wochentage"
                size="small"
                sx={{ width: 200 }}
              />
            )}
          />

          {/* Anzeigeoption (Umsatz oder Kundenanzahl) */}
          <TextField
            label="Anzeigen"
            value={displayOption}
            onChange={(e) => setDisplayOption(e.target.value)}
            select
            size="small"
            sx={{ width: 150 }}
          >
            <MenuItem value="umsatz">Umsatz</MenuItem>
            <MenuItem value="kundenanzahl">Kundenanzahl</MenuItem>
          </TextField>

          {/* Zurücksetzen-Button */}
          <Button
            variant="outlined"
            size="small"
            onClick={resetFilters}
            sx={{
              height: '40px',
              minWidth: '150px',
              fontSize: '0.875rem',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            Zurücksetzen
          </Button>
        </Box>

        {/* Tabelle und Diagramm */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {/* Tabelle */}
          <Grid item xs={12}>
            <BigTableCard
              title="Tagesbericht über Umsatz und Kunden pro Stunde"
              data={data}
              columns={columns}
              onRowClick={(row) => console.log('Row clicked:', row)}
            />
          </Grid>

          {/* Diagramm */}
          <Grid item xs={12}>
            <BigChartCardFrequenz
              data={averageData}
              title={
                displayOption === 'umsatz'
                  ? 'Durchschnittlicher Umsatz pro Stunde'
                  : 'Durchschnittliche Kundenanzahl pro Stunde'
              }
              infoContent="Dieses Diagramm zeigt den Durchschnittswert pro Stunde (aggregiert pro Tag) basierend auf den ausgewählten Daten."
              displayOption={displayOption}
            />
          </Grid>
        </Grid>

        {loading && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Kundenstatistik;
