import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Tabs,
  Tab,
  Box,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { FilterContext } from "../../../provider/FilterProvider";
import Score from '../../../components/score/Score';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import VerkaufsgebietDropdown from '../../../components/filter/VerkaufsgebietDropdown';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import FilialeMonatLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";

/** Hilfsfunktionen zum Sortieren */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // Bei Gleichstand nach Original-Index sortieren
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Overview = () => {
  const [data, setData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [wareData, setWareData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
  const [datumOptions, setDatumOptions] = useState([]);
  const [greeting, setGreeting] = useState('Hallo');

  const { filterState, handleFilialeChange, handleDatumChange } = useContext(FilterContext);
  const navigate = useNavigate();

  /**
   * STATES FÜR SORTIERUNG
   * Main-Tabelle (links)
   */
  const [mainOrder, setMainOrder] = useState('asc');
  const [mainOrderBy, setMainOrderBy] = useState('');

  /**
   * Performance-Tabelle (rechter Tab)
   */
  const [perfOrder, setPerfOrder] = useState('asc');
  const [perfOrderBy, setPerfOrderBy] = useState('');

  /**
   * Personal-Tabelle (rechter Tab)
   */
  const [persOrder, setPersOrder] = useState('asc');
  const [persOrderBy, setPersOrderBy] = useState('');

  /**
   * Ware-Tabelle (rechter Tab)
   */
  const [wareOrder, setWareOrder] = useState('asc');
  const [wareOrderBy, setWareOrderBy] = useState('');

  useEffect(() => {
    setGreetingMessage();
    fetchVerkaufsgebietOptions();
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchAllData();
    }
  }, [filterState.datum, verkaufsgebietFilter]);

  const setGreetingMessage = () => {
    const currentHour = dayjs().hour();
    if (currentHour < 12) {
      setGreeting('Guten Morgen');
    } else if (currentHour < 18) {
      setGreeting('Hallo');
    } else {
      setGreeting('Guten Abend');
    }
  };

  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
      setVerkaufsgebietOptions(
        response.data.map(item => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Error while fetching VerkaufsgebietOptions', error);
    }
  };

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map(item => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Error while fetching datum options', error);
    }
  };

  const fetchAllData = async () => {
    try {
      const params = {
        jahrMonat: filterState.datum?.value,
        aktiv: 'True',
      };
      if (verkaufsgebietFilter) {
        params.verkaufsgebiet = verkaufsgebietFilter.value;
      }

      const [performanceResponse, personalResponse, wareResponse] = await Promise.all([
        axiosInstance.get('scorecards_month/performance-monat/', { params }),
        axiosInstance.get('scorecards_month/personal-kennzahl/', { params }),
        axiosInstance.get('scorecards_month/ware-filiale-monat/', { params }),
      ]);

      const combinedData = performanceResponse.data.results.map((performanceItem) => {
        const filiale = performanceItem.filiale;
        const filialnummer = performanceItem.filialnummer; 
        const personalItem = personalResponse.data.results.find(
          item => item.filiale === filiale
        ) || {};
        const wareItem = wareResponse.data.results.find(
          item => item.filiale === filiale
        ) || {};

        return {
          filiale: filiale,
          filialnummer: filialnummer,
          performanceScore: performanceItem.scorePerformance || 0,
          personalScore: personalItem.scorePersonal || 0,
          wareScore: wareItem.scoreWareFiliale || 0,
          umsatz: performanceItem.scoreUmsatz || 0,
          kundenanzahl: performanceItem.scoreKundenanzahl || 0,
          kundenbon: performanceItem.scoreKundenbon || 0,
          leistung: performanceItem.scoreLeistung || 0,
          krankenquote: personalItem.scoreKrankenquote || 0,
          fluktuation: personalItem.scoreFluktuation || 0,
          bedarf: personalItem.scoreKöpfeDifferenzQuote || 0,
          auslastung: personalItem.scoreÜberstunden || 0,
          wachstum: wareItem.scoreProduktwachstumFiliale || 0,
          retoureA: wareItem.scoreSollRetoureFiliale || 0,
          verfügbarkeit: wareItem.scoreVerfügbarkeitFiliale || 0,
          retoureBC: wareItem.scoreRetourQuoteFilialeBC || 0,
        };
      });

      setData(combinedData);
      setPerformanceData(combinedData);
      setPersonalData(combinedData);
      setWareData(combinedData);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  /* -------------------------------------- SORTIER-HANDLER -------------------------------------- */
  const handleMainRequestSort = (event, property) => {
    const isAsc = mainOrderBy === property && mainOrder === 'asc';
    setMainOrder(isAsc ? 'desc' : 'asc');
    setMainOrderBy(property);
  };

  const handlePerfRequestSort = (event, property) => {
    const isAsc = perfOrderBy === property && perfOrder === 'asc';
    setPerfOrder(isAsc ? 'desc' : 'asc');
    setPerfOrderBy(property);
  };

  const handlePersRequestSort = (event, property) => {
    const isAsc = persOrderBy === property && persOrder === 'asc';
    setPersOrder(isAsc ? 'desc' : 'asc');
    setPersOrderBy(property);
  };

  const handleWareRequestSort = (event, property) => {
    const isAsc = wareOrderBy === property && wareOrder === 'asc';
    setWareOrder(isAsc ? 'desc' : 'asc');
    setWareOrderBy(property);
  };

  /* -------------------------------------- NAVIGATION -------------------------------------- */
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleRowClick = (item) => {
    const selectedFiliale = { label: item.filiale, value: item.filialnummer };
    handleFilialeChange(selectedFiliale);
    navigate('/scorecards/overview/cockpit');
  };

  /* -------------------------------------- RIGHT TABLES (TABS) -------------------------------------- */
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return renderPerformanceTable();
      case 1:
        return renderPersonalTable();
      case 2:
        return renderWareTable();
      default:
        return null;
    }
  };

  // PERFORMANCE-TABELLE
  const renderPerformanceTable = () => {
    const sortedData = stableSort(performanceData, getComparator(perfOrder, perfOrderBy));
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead
            sx={{
              position: 'sticky',
              top: -20,
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
          >
            <TableRow>
              {/* Filiale */}
              <TableCell sortDirection={perfOrderBy === 'filiale' ? perfOrder : false}>
                <TableSortLabel
                  active={perfOrderBy === 'filiale'}
                  direction={perfOrderBy === 'filiale' ? perfOrder : 'asc'}
                  onClick={(e) => handlePerfRequestSort(e, 'filiale')}
                >
                  <Typography>Filiale</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Umsatz */}
              <TableCell sortDirection={perfOrderBy === 'umsatz' ? perfOrder : false}>
                <TableSortLabel
                  active={perfOrderBy === 'umsatz'}
                  direction={perfOrderBy === 'umsatz' ? perfOrder : 'asc'}
                  onClick={(e) => handlePerfRequestSort(e, 'umsatz')}
                >
                  <Typography>Umsatz</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Kundenanzahl */}
              <TableCell sortDirection={perfOrderBy === 'kundenanzahl' ? perfOrder : false}>
                <TableSortLabel
                  active={perfOrderBy === 'kundenanzahl'}
                  direction={perfOrderBy === 'kundenanzahl' ? perfOrder : 'asc'}
                  onClick={(e) => handlePerfRequestSort(e, 'kundenanzahl')}
                >
                  <Typography>Kundenanzahl</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Kundenbon */}
              <TableCell sortDirection={perfOrderBy === 'kundenbon' ? perfOrder : false}>
                <TableSortLabel
                  active={perfOrderBy === 'kundenbon'}
                  direction={perfOrderBy === 'kundenbon' ? perfOrder : 'asc'}
                  onClick={(e) => handlePerfRequestSort(e, 'kundenbon')}
                >
                  <Typography>Kundenbon</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Leistung */}
              <TableCell sortDirection={perfOrderBy === 'leistung' ? perfOrder : false}>
                <TableSortLabel
                  active={perfOrderBy === 'leistung'}
                  direction={perfOrderBy === 'leistung' ? perfOrder : 'asc'}
                  onClick={(e) => handlePerfRequestSort(e, 'leistung')}
                >
                  <Typography>Leistung</Typography>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(item)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell align="left">
                  <Typography>{item.filiale}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Score score={item.umsatz} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.kundenanzahl} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.kundenbon} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.leistung} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // PERSONAL-TABELLE
  const renderPersonalTable = () => {
    const sortedData = stableSort(personalData, getComparator(persOrder, persOrderBy));
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead
            sx={{
              position: 'sticky',
              top: -20,
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
          >
            <TableRow>
              {/* Filiale */}
              <TableCell sortDirection={persOrderBy === 'filiale' ? persOrder : false}>
                <TableSortLabel
                  active={persOrderBy === 'filiale'}
                  direction={persOrderBy === 'filiale' ? persOrder : 'asc'}
                  onClick={(e) => handlePersRequestSort(e, 'filiale')}
                >
                  <Typography>Filiale</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Krankenquote */}
              <TableCell sortDirection={persOrderBy === 'krankenquote' ? persOrder : false}>
                <TableSortLabel
                  active={persOrderBy === 'krankenquote'}
                  direction={persOrderBy === 'krankenquote' ? persOrder : 'asc'}
                  onClick={(e) => handlePersRequestSort(e, 'krankenquote')}
                >
                  <Typography>Krankenquote</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Fluktuation */}
              <TableCell sortDirection={persOrderBy === 'fluktuation' ? persOrder : false}>
                <TableSortLabel
                  active={persOrderBy === 'fluktuation'}
                  direction={persOrderBy === 'fluktuation' ? persOrder : 'asc'}
                  onClick={(e) => handlePersRequestSort(e, 'fluktuation')}
                >
                  <Typography>Fluktuation</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Bedarf */}
              <TableCell sortDirection={persOrderBy === 'bedarf' ? persOrder : false}>
                <TableSortLabel
                  active={persOrderBy === 'bedarf'}
                  direction={persOrderBy === 'bedarf' ? persOrder : 'asc'}
                  onClick={(e) => handlePersRequestSort(e, 'bedarf')}
                >
                  <Typography>Bedarf</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Auslastung */}
              <TableCell sortDirection={persOrderBy === 'auslastung' ? persOrder : false}>
                <TableSortLabel
                  active={persOrderBy === 'auslastung'}
                  direction={persOrderBy === 'auslastung' ? persOrder : 'asc'}
                  onClick={(e) => handlePersRequestSort(e, 'auslastung')}
                >
                  <Typography>Auslastung</Typography>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(item)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell align="left">
                  <Typography>{item.filiale}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Score score={item.krankenquote} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.fluktuation} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.bedarf} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.auslastung} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // WARE-TABELLE
  const renderWareTable = () => {
    const sortedData = stableSort(wareData, getComparator(wareOrder, wareOrderBy));
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead
            sx={{
              position: 'sticky',
              top: -20,
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
          >
            <TableRow>
              {/* Filiale */}
              <TableCell sortDirection={wareOrderBy === 'filiale' ? wareOrder : false}>
                <TableSortLabel
                  active={wareOrderBy === 'filiale'}
                  direction={wareOrderBy === 'filiale' ? wareOrder : 'asc'}
                  onClick={(e) => handleWareRequestSort(e, 'filiale')}
                >
                  <Typography>Filiale</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Wachstum */}
              <TableCell sortDirection={wareOrderBy === 'wachstum' ? wareOrder : false}>
                <TableSortLabel
                  active={wareOrderBy === 'wachstum'}
                  direction={wareOrderBy === 'wachstum' ? wareOrder : 'asc'}
                  onClick={(e) => handleWareRequestSort(e, 'wachstum')}
                >
                  <Typography>Wachstum</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Retoure A */}
              <TableCell sortDirection={wareOrderBy === 'retoureA' ? wareOrder : false}>
                <TableSortLabel
                  active={wareOrderBy === 'retoureA'}
                  direction={wareOrderBy === 'retoureA' ? wareOrder : 'asc'}
                  onClick={(e) => handleWareRequestSort(e, 'retoureA')}
                >
                  <Typography>Retoure A</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Verfügbarkeit */}
              <TableCell sortDirection={wareOrderBy === 'verfügbarkeit' ? wareOrder : false}>
                <TableSortLabel
                  active={wareOrderBy === 'verfügbarkeit'}
                  direction={wareOrderBy === 'verfügbarkeit' ? wareOrder : 'asc'}
                  onClick={(e) => handleWareRequestSort(e, 'verfügbarkeit')}
                >
                  <Typography>Verfügbarkeit</Typography>
                </TableSortLabel>
              </TableCell>
              {/* Retoure B/C */}
              <TableCell sortDirection={wareOrderBy === 'retoureBC' ? wareOrder : false}>
                <TableSortLabel
                  active={wareOrderBy === 'retoureBC'}
                  direction={wareOrderBy === 'retoureBC' ? wareOrder : 'asc'}
                  onClick={(e) => handleWareRequestSort(e, 'retoureBC')}
                >
                  <Typography>Retoure B/C</Typography>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(item)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell align="left">
                  <Typography>{item.filiale}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Score score={item.wachstum} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.retoureA} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.verfügbarkeit} />
                </TableCell>
                <TableCell align="center">
                  <Score score={item.retoureBC} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  /* -------------------------------------- MAIN TABLE (LEFT) -------------------------------------- */
  const sortedMainData = stableSort(data, getComparator(mainOrder, mainOrderBy));

  return (
    <FilialeMonatLayout>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={8}>
          <PageHeader
            title="Monats Scorecards"
            subtitle="Monatliche Auswertung von Kennzahlen, um Fokus-Themen abzuleiten und in die Planung zu integrieren. Der Score gibt Aufschluss über die Einordnung im Vergleich zum Benchmark."
            Icon={StackedBarChartOutlinedIcon}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <DropdownFilter
              label="Datum"
              options={datumOptions}
              value={filterState.datum}
              onChange={handleDatumChange}
            />
            <VerkaufsgebietDropdown
              options={verkaufsgebietOptions}
              value={verkaufsgebietFilter}
              onChange={setVerkaufsgebietFilter}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* --- Linke Tabelle (Main) --- */}
        <Grid item xs={12} md={6}>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 900, overflowY: 'auto' }}
          >
            <Table stickyHeader>
              <TableHead
                sx={{
                  position: 'sticky',
                  top: -20,
                  zIndex: 1000,
                  backgroundColor: '#fff',
                }}
              >
                <TableRow>
                  {/* Filiale */}
                  <TableCell
                    sortDirection={mainOrderBy === 'filiale' ? mainOrder : false}
                  >
                    <TableSortLabel
                      active={mainOrderBy === 'filiale'}
                      direction={mainOrderBy === 'filiale' ? mainOrder : 'asc'}
                      onClick={(e) => handleMainRequestSort(e, 'filiale')}
                    >
                      <Typography>Filiale</Typography>
                    </TableSortLabel>
                  </TableCell>
                  {/* Performance */}
                  <TableCell
                    sortDirection={mainOrderBy === 'performanceScore' ? mainOrder : false}
                  >
                    <TableSortLabel
                      active={mainOrderBy === 'performanceScore'}
                      direction={mainOrderBy === 'performanceScore' ? mainOrder : 'asc'}
                      onClick={(e) => handleMainRequestSort(e, 'performanceScore')}
                    >
                      <Typography>Performance</Typography>
                    </TableSortLabel>
                  </TableCell>
                  {/* Personal */}
                  <TableCell
                    sortDirection={mainOrderBy === 'personalScore' ? mainOrder : false}
                  >
                    <TableSortLabel
                      active={mainOrderBy === 'personalScore'}
                      direction={mainOrderBy === 'personalScore' ? mainOrder : 'asc'}
                      onClick={(e) => handleMainRequestSort(e, 'personalScore')}
                    >
                      <Typography>Personal</Typography>
                    </TableSortLabel>
                  </TableCell>
                  {/* Ware */}
                  <TableCell
                    sortDirection={mainOrderBy === 'wareScore' ? mainOrder : false}
                  >
                    <TableSortLabel
                      active={mainOrderBy === 'wareScore'}
                      direction={mainOrderBy === 'wareScore' ? mainOrder : 'asc'}
                      onClick={(e) => handleMainRequestSort(e, 'wareScore')}
                    >
                      <Typography>Ware</Typography>
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMainData.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(item)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">
                      <Typography>{item.filiale}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Score score={item.performanceScore} />
                    </TableCell>
                    <TableCell align="center">
                      <Score score={item.personalScore} />
                    </TableCell>
                    <TableCell align="center">
                      <Score score={item.wareScore} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* --- Rechte Tabelle (Tabs) --- */}
        <Grid item xs={12} md={6}>
          <Paper>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Performance" />
              <Tab label="Personal" />
              <Tab label="Ware" />
            </Tabs>
            <Box p={2}>
              {renderTabContent()}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </FilialeMonatLayout>
  );
};

export default Overview;
