import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  LinearProgress,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Onboarding/Onboarding';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../provider/FilterProvider';

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [topics, setTopics] = useState([]);
  const [steps, setSteps] = useState([]);
  const [topicProgress, setTopicProgress] = useState([]);
  const [stepProgress, setStepProgress] = useState([]);
  const [categories, setCategories] = useState([]);

  // State für den ausgewählten Plan (Filter)
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  // State für die Suchleiste
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const { handleEmployeeChange } = useContext(FilterContext);

  const theme = useTheme();

  useEffect(() => {
    Promise.all([
      axiosInstance.get('/onboarding/onboarding-plans/'),
      axiosInstance.get('/onboarding/employee-assignments/'),
      axiosInstance.get('personaldaten/stammdaten-mitarbeiter/'),
      axiosInstance.get('/onboarding/topics/'),
      axiosInstance.get('/onboarding/onboarding-steps/'),
      axiosInstance.get('/onboarding/topic-progress/'),
      axiosInstance.get('/onboarding/step-progress/'),
      axiosInstance.get('/onboarding/categories/'),
    ])
      .then(([
        plansResponse,
        assignmentsResponse,
        employeesResponse,
        topicsResponse,
        stepsResponse,
        topicProgressResponse,
        stepProgressResponse,
        categoriesResponse,
      ]) => {
        setOnboardingPlans(plansResponse.data.results);
        setAssignments(assignmentsResponse.data.results);
        setEmployees(employeesResponse.data.results);
        setTopics(topicsResponse.data.results);
        setSteps(stepsResponse.data.results);
        setTopicProgress(topicProgressResponse.data.results);
        setStepProgress(stepProgressResponse.data.results);
        setCategories(categoriesResponse.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Daten:', error);
        setLoading(false);
      });
  }, []);

  // Nur aktive Pläne
  const activePlans = onboardingPlans.filter((plan) => plan.is_active);

  // Daten für die Cards
  const analyticsData = activePlans.map((plan) => {
    const inProgressAssignments = assignments.filter(
      (assignment) =>
        assignment.onboarding_plan === plan.id &&
        assignment.status === 'in_progress'
    );
    return {
      planId: plan.id,
      planName: plan.name,
      totalEmployees: inProgressAssignments.length,
    };
  });

  // Alle "in_progress" Assignments
  const activeAssignments = assignments.filter(
    (assignment) => assignment.status === 'in_progress'
  );

  // Plan-Filterung (wenn selectedPlanId gesetzt)
  const displayedAssignments = selectedPlanId
    ? activeAssignments.filter((a) => a.onboarding_plan === selectedPlanId)
    : activeAssignments;

  // Datumsformatierung
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  // ----------------------------------
  // Filter über das Suchfeld (Name, ID, Plan, Status, Datum, und jetzt auch Filiale)
  // ----------------------------------
  const filteredAssignments = displayedAssignments.filter((assignment) => {
    // Finde zugehörigen Mitarbeiter
    const employee = employees.find(
      (emp) => emp.mitarbeiterId === assignment.employee
    );
    if (!employee) return false;

    // Finde Onboarding-Plan
    const plan = onboardingPlans.find((p) => p.id === assignment.onboarding_plan);

    // NEU: Filiale (falls in `employee.stammkostenstelle`)
    const filialeStr = employee.stammkostenstelle || '';

    // Werte in Strings
    const mitarbeiterIdStr = String(employee.mitarbeiterId);
    const mitarbeiterNameStr = employee.name || '';
    const planNameStr = plan?.name || '';
    const statusStr = assignment.status || '';
    const assignedDateStr = assignment.assigned_at
      ? formatDate(assignment.assigned_at)
      : '';

    // Zusammenfassen
    const rowString = [
      mitarbeiterIdStr,
      mitarbeiterNameStr,
      filialeStr,          // <-- NEU: Filiale im Search-String
      planNameStr,
      statusStr,
      assignedDateStr,
    ]
      .join(' ')
      .toLowerCase();

    return rowString.includes(searchTerm.toLowerCase());
  });
  // ----------------------------------

  const handleViewEmployee = (employeeId) => {
    handleEmployeeChange(employeeId);
    navigate('/onboarding/overview/mitarbeiter');
  };

  return (
    <Layout>
      <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <PageHeader
          title="Onboarding"
          subtitle="Übersicht der Mitarbeiter die sich aktuell im Onboarding befinden."
          Icon={AssessmentIcon}
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Data Cards */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {analyticsData.length > 0 ? (
                analyticsData.map((data) => {
                  const isSelected = data.planId === selectedPlanId;
                  return (
                    <Grid item xs={12} sm={6} md={4} key={data.planId}>
                      <Card
                        sx={{
                          minWidth: 275,
                          cursor: 'pointer',
                          backgroundColor: isSelected
                            ? theme.palette.secondary.main
                            : theme.palette.background.paper,
                          transition: 'background-color 0.2s ease-in-out',
                          '&:hover': {
                            backgroundColor: isSelected
                              ? theme.palette.secondary.main
                              : theme.palette.grey[100],
                          },
                        }}
                        onClick={() => {
                          if (isSelected) {
                            // Deselektieren
                            setSelectedPlanId(null);
                          } else {
                            setSelectedPlanId(data.planId);
                          }
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" component="div">
                            {data.planName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Mitarbeiter in Bearbeitung:
                          </Typography>
                          <Typography variant="h4" sx={{ mt: 1 }}>
                            {data.totalEmployees}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Derzeit sind keine aktiven Onboarding-Pläne vorhanden.
                </Typography>
              )}
            </Grid>

            {/* Tabelle der aktiven Mitarbeiter */}
            <Paper sx={{ mt: 4, p: 2 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
              >
                <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                  Mitarbeiter in aktiven Onboarding-Plänen
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  label="Suchen"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      fontSize: '0.75rem',
                      padding: '2px 8px',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '4.5px 8px',
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '0.875rem',
                    },
                    '& .MuiInputBase-root': {
                      height: '30px',
                    },
                  }}
                />
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Mitarbeiter-ID</TableCell>
                    <TableCell>Name</TableCell>
                    {/* NEU: Spalte Filiale */}
                    <TableCell>Filiale</TableCell>
                    <TableCell>Onboarding-Plan</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Startzeitpunkt</TableCell>
                    <TableCell>Fortschritt Themen (MA)</TableCell>
                    <TableCell>Fortschritt Themen (VG)</TableCell>
                    <TableCell>Fortschritt Schritte</TableCell>
                    <TableCell>Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAssignments.length > 0 ? (
                    filteredAssignments.map((assignment) => {
                      const employeeId = assignment.employee;
                      const plan = onboardingPlans.find(
                        (p) => p.id === assignment.onboarding_plan
                      );
                      const employee = employees.find(
                        (emp) => emp.mitarbeiterId === employeeId
                      );

                      // Kategorien für den Onboarding-Plan filtern
                      const planCategories = categories.filter(
                        (cat) => cat.onboarding_plan === (plan?.id || 0)
                      );

                      // Themen für die Kategorien
                      const planTopics = topics.filter((topic) =>
                        planCategories.some(
                          (category) => category.id === topic.category
                        )
                      );

                      // Schritte für die Themen
                      const planSteps = steps.filter((step) =>
                        planTopics.some((topic) => topic.id === step.topic)
                      );

                      // Fortschritt
                      const employeeTopicProgress = topicProgress.filter(
                        (tp) => tp.employee === employeeId
                      );
                      const employeeStepProgress = stepProgress.filter(
                        (sp) => sp.employee === employeeId
                      );

                      // Auf Plan beschränken
                      const employeeTopicProgressForPlan =
                        employeeTopicProgress.filter((tp) =>
                          planTopics.some((topic) => topic.id === tp.topic_id)
                        );

                      const employeeStepProgressForPlan =
                        employeeStepProgress.filter((sp) =>
                          planSteps.some((st) => st.id === sp.onboarding_step_id)
                        );

                      // Themenfortschritt (MA)
                      const completedTopics =
                        employeeTopicProgressForPlan.filter(
                          (tp) => tp.employee_status === true
                        ).length;
                      const totalTopics = planTopics.length;
                      const topicCompletionPercentage =
                        totalTopics > 0
                          ? Math.round((completedTopics / totalTopics) * 100)
                          : 0;

                      // Themenfortschritt (VG)
                      const completedSupervisorTopics =
                        employeeTopicProgressForPlan.filter(
                          (tp) => tp.supervisor_status === true
                        ).length;
                      const supervisorTopicCompletionPercentage =
                        totalTopics > 0
                          ? Math.round(
                              (completedSupervisorTopics / totalTopics) * 100
                            )
                          : 0;

                      // Schrittfortschritt
                      const completedSteps =
                        employeeStepProgressForPlan.filter(
                          (sp) => sp.status === true
                        ).length;
                      const totalSteps = planSteps.length;
                      const stepCompletionPercentage =
                        totalSteps > 0
                          ? Math.round((completedSteps / totalSteps) * 100)
                          : 0;

                      return (
                        <TableRow key={assignment.id}>
                          <TableCell>{employeeId}</TableCell>
                          <TableCell>
                            {employee ? employee.name : 'N/A'}
                          </TableCell>
                          {/* NEU: Filiale hier ausgeben */}
                          <TableCell>
                            {employee ? employee.stammkostenstelle : '—'}
                          </TableCell>
                          <TableCell>{plan ? plan.name : 'N/A'}</TableCell>
                          <TableCell>
                            {assignment.status === 'in_progress'
                              ? 'In Bearbeitung'
                              : assignment.status === 'completed'
                              ? 'Abgeschlossen'
                              : assignment.status}
                          </TableCell>
                          <TableCell>{formatDate(assignment.assigned_at)}</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${topicCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={topicCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${supervisorTopicCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={supervisorTopicCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${stepCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={stepCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleViewEmployee(employeeId)}
                            >
                              <ArrowForwardOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        Keine aktiven Mitarbeiter in Onboarding-Plänen.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default Analytics;
