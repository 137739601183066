// frontend/src/pages/PhoneListOverview.jsx

import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../../services/axiosInstance';

// Material UI
import {
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';

import ChecklistIcon from '@mui/icons-material/Checklist';
import Layout from '../../../components/layout/Telefonliste/TelefonlisteLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';

const PhoneListOverview = () => {
  // --------------------------------------------------
  // States
  // --------------------------------------------------
  const [personnel, setPersonnel] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Sortier-Konfiguration
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // --------------------------------------------------
  // 1) Daten laden
  // --------------------------------------------------
  useEffect(() => {
    fetchAllEntries();
  }, []);

  const fetchAllEntries = async () => {
    try {
      const response = await axiosInstance.get('/telefonliste/telefonliste/');
      const results = response.data.results ?? response.data;
      setPersonnel(Array.isArray(results) ? results : []);
    } catch (error) {
      console.error('Fehler beim Laden der Einträge:', error);
    }
  };

  // --------------------------------------------------
  // 2) Sortierung
  // --------------------------------------------------
  const handleRequestSort = (columnKey) => {
    if (sortConfig.key === columnKey) {
      setSortConfig((prev) => ({
        ...prev,
        direction: prev.direction === 'asc' ? 'desc' : 'asc'
      }));
    } else {
      setSortConfig({ key: columnKey, direction: 'asc' });
    }
  };

  // --------------------------------------------------
  // 3) Suche
  // --------------------------------------------------
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // --------------------------------------------------
  // 4) Daten filtern & sortieren
  // --------------------------------------------------
  const filteredAndSortedPersonnel = useMemo(() => {
    let data = personnel;

    // 1) Filtern
    if (searchTerm.trim() !== '') {
      const lowerSearch = searchTerm.toLowerCase();
      data = data.filter((person) => {
        const { name, abteilung, telefonnummer, mobil, mail } = person;
        return (
          (name && name.toLowerCase().includes(lowerSearch)) ||
          (abteilung && abteilung.toLowerCase().includes(lowerSearch)) ||
          (telefonnummer && telefonnummer.toLowerCase().includes(lowerSearch)) ||
          (mobil && mobil.toLowerCase().includes(lowerSearch)) ||
          (mail && mail.toLowerCase().includes(lowerSearch))
        );
      });
    }

    // 2) Sortieren
    if (sortConfig.key) {
      data = [...data].sort((a, b) => {
        const aVal = a[sortConfig.key] || '';
        const bVal = b[sortConfig.key] || '';

        if (typeof aVal === 'string' && typeof bVal === 'string') {
          // Strings -> localeCompare
          return sortConfig.direction === 'asc'
            ? aVal.localeCompare(bVal, 'de', { numeric: true })
            : bVal.localeCompare(aVal, 'de', { numeric: true });
        } else {
          // Fallback
          return sortConfig.direction === 'asc'
            ? aVal > bVal ? 1 : -1
            : aVal < bVal ? 1 : -1;
        }
      });
    }

    return data;
  }, [personnel, searchTerm, sortConfig]);

  // --------------------------------------------------
  // 5) Gruppierung nach Abteilung
  // --------------------------------------------------
  const groupedByAbteilung = useMemo(() => {
    const groups = {};
    filteredAndSortedPersonnel.forEach((p) => {
      const abt = p.abteilung || 'Ohne Abteilung';
      if (!groups[abt]) groups[abt] = [];
      groups[abt].push(p);
    });
    return groups;
  }, [filteredAndSortedPersonnel]);

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader
          title="Telefonliste - Übersicht"
          subtitle="Nur Ansicht, Gruppierung, Sortierung & Suche"
          Icon={ChecklistIcon}
        />

        {/* Obere Leiste: Nur Suchfeld (rechts) */}
        <Paper sx={{ p: 2, mt: 2, mb: 2, borderRadius: 2 }}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <TextField
                label="Suchen..."
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ borderRadius: 2 }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Gruppierte Abteilungen */}
        {Object.keys(groupedByAbteilung).length === 0 ? (
          <Typography>Keine Einträge vorhanden.</Typography>
        ) : (
          Object.entries(groupedByAbteilung).map(([abt, persons]) => (
            <Paper
              key={abt}
              sx={{ p: 2, mb: 2, borderRadius: 3 }}
            >
              <Typography variant="h6" gutterBottom>
                Abteilung: {abt}
              </Typography>
              <Divider sx={{ mb: 2 }} />

              {/* Kleine Tabelle pro Abteilung (sortierbar) */}
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'name'}
                          direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'telefonnummer'}
                          direction={sortConfig.key === 'telefonnummer' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('telefonnummer')}
                        >
                          Telefon
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'mobil'}
                          direction={sortConfig.key === 'mobil' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('mobil')}
                        >
                          Mobil
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'mail'}
                          direction={sortConfig.key === 'mail' ? sortConfig.direction : 'asc'}
                          onClick={() => handleRequestSort('mail')}
                        >
                          Mail
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {persons.map((person) => (
                      <TableRow key={person.id}>
                        <TableCell>{person.name}</TableCell>
                        <TableCell>{person.telefonnummer}</TableCell>
                        <TableCell>{person.mobil}</TableCell>
                        <TableCell>{person.mail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))
        )}
      </Box>
    </Layout>
  );
};

export default PhoneListOverview;
