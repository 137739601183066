import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from "@mui/icons-material/Store";
import WindowIcon from '@mui/icons-material/Window';


const AufgabenLayout = ({children}) => {
  const menuItems = [
    {text: 'Übersicht', path: '/aufgaben/settings/aufgaben', icon: <WindowIcon/>, subModule: "overview"},
    {text: 'Vorlagen', path: '/aufgaben/settings/index', icon: <GroupIcon/>, subModule: "settings"},
  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"aufgaben"} menuItems={menuItems} title={'Erfolsplaner'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default AufgabenLayout;
