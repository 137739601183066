import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import MainTableArtikelPopup from './MainTableArtikelPopup'; // Falls du den auch noch auf verkaufsmenge umschreiben willst, analog anpassen

// Helper function to format numbers with '.' as thousand separator and ',' as decimal separator
const formatNumber = (value, fractionDigits = 2) => {
    if (value === null || value === undefined) return '';
    return value
        .toFixed(fractionDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const MainTablePopup = ({ open, onClose, filiale, filterState }) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    // State for the second popup
    const [artikelPopupOpen, setArtikelPopupOpen] = useState(false);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

    useEffect(() => {
        if (open) {
            fetchData();
        }
       
    }, [open]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const jahrKw = filterState.datumWoche?.value;

            // API call mit Filiale + jahrKw
            // Angenommen: /scorecard_week/ware-warengruppe-woche/
            // Hier interpretieren wir "verkaufsmenge" anstelle von retourwert/retourquote usw.
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    filiale: filiale,
                    jahrKw: jahrKw,
                },
            });

            // Data processing
            const popupData = response.data.results.map((item, index) => {
                const verkaufsmenge = formatNumber(item.verkaufsmenge || 0);
                // Falls du zusätzlich die Vorjahresmenge oder andere Kennzahlen hast, könntest du die hier ebenso anlegen.

                return {
                    id: index,
                    warengruppe: item.warengruppe || 'Unbekannt',
                    verkaufsmenge,
                };
            });

            // Optional: Filtern nur Warengruppen mit > 0
            const filteredData = popupData.filter(
                (row) => parseFloat(row.verkaufsmenge.replace(',', '.')) > 0
            );

            const popupColumns = [
                { accessorKey: 'warengruppe', header: 'Warengruppe', align: 'left' },
                {
                    accessorKey: 'verkaufsmenge',
                    header: 'Verkaufsmenge',
                    align: 'right',
                },
            ];

            setData(filteredData);
            setColumns(popupColumns);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Popup-Daten:', error);
            setLoading(false);
        }
    };

    // Handler when a Warengruppe row is clicked
    const handleRowClick = (row) => {
        const warengruppe = row.warengruppe;
        setSelectedWarengruppe(warengruppe);
        setArtikelPopupOpen(true);
    };

    return (
        <>
            <Popup
                open={open}
                title={`Warengruppen-Verkaufsmenge für Filiale ${filiale}`}
                onClose={onClose}
                fullWidth
                maxWidth={false}
            >
                <BigTableCard
                    data={data}
                    columns={columns}
                    loading={loading}
                    onRowClick={handleRowClick} // Handle row clicks
                />
            </Popup>

            {/* Second Popup */}
            {artikelPopupOpen && (
                <MainTableArtikelPopup
                    open={artikelPopupOpen}
                    onClose={() => setArtikelPopupOpen(false)}
                    filiale={filiale}
                    warengruppe={selectedWarengruppe}
                    filterState={filterState}
                />
            )}
        </>
    );
};

export default MainTablePopup;
