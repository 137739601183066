import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    List,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    ListItemText,
    CircularProgress,
    Button,
    Alert,
    Divider,
    Paper,
    ButtonGroup,
    IconButton,
    TextField,
} from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../../services/axiosInstance';

import SingleFilialeSelect from '../../components/filter/SingleFilialeSelect';
import SingleArtikelSelect from '../../components/filter/SingleArtikelSelect';  // <-- Neue Komponente
import { useAuth } from '../../provider/authProvider';

const CategoryCheckpointsForm = ({ checkId, onSaveSuccess }) => {
    // ----------------------------------------------------------------------------
    // Lokale States
    // ----------------------------------------------------------------------------
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [comments, setComments] = useState({});
    const [images, setImages] = useState({});
    const [imagePreviews, setImagePreviews] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    // Aktueller Benutzer
    const { user } = useAuth();

    // Filiale (als Objekt {filialnummer, filiale})
    const [selectedFiliale, setSelectedFiliale] = useState(null);

    // Artikel (als Objekt {artikelnummer, artikel})
    const [selectedArtikel, setSelectedArtikel] = useState(null);

    // Check-Infos
    const [maxRating, setMaxRating] = useState(10);
    const [checkName, setCheckName] = useState('');

    // Dialog-States
    const [selectionDialogOpen, setSelectionDialogOpen] = useState(true);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [currentCheckpoint, setCurrentCheckpoint] = useState(null);
    const [currentComment, setCurrentComment] = useState('');
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    // Sollgewicht in Gramm
    const [sollgewicht, setSollgewicht] = useState(null);

    // ----------------------------------------------------------------------------
    // Datum formatieren
    // ----------------------------------------------------------------------------
    const formatDate = (date) => {
        const d = new Date(date);
        return `${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(
            2,
            '0'
        )}.${d.getFullYear()}`;
    };
    const currentDate = formatDate(new Date());

    // ----------------------------------------------------------------------------
    // Check + Kategorien + Checkpoints laden
    // ----------------------------------------------------------------------------
    useEffect(() => {
        const fetchCategoriesAndCheckpoints = async () => {
            try {
                const checkResponse = await axiosInstance.get(`/qualitaet/checks/${checkId}`);
                setMaxRating(checkResponse.data.max);
                setCheckName(checkResponse.data.name);

                const categoryResponse = await axiosInstance.get('/qualitaet/categories/');
                const filteredCategories = categoryResponse.data.results.filter(
                    (category) => category.check_related === checkId
                );

                const checkpointResponse = await axiosInstance.get('/qualitaet/checkpoints/');
                const categorizedCheckpoints = filteredCategories.map((category) => ({
                    ...category,
                    checkpoints: checkpointResponse.data.results.filter(
                        (checkpoint) => checkpoint.category === category.id
                    ),
                }));

                setCategories(categorizedCheckpoints);
            } catch (error) {
                setError('Fehler beim Laden der Daten.');
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndCheckpoints();
    }, [checkId]);

    // ----------------------------------------------------------------------------
    // Sollgewicht laden, wenn ein Artikel ausgewählt wird (in Gramm)
    // ----------------------------------------------------------------------------
    useEffect(() => {
        const fetchArtikelDetails = async () => {
            if (selectedArtikel?.artikelnummer) {
                try {
                    // Beispiel: { gewicht: 1.2 } -> 1.2 kg
                    const response = await axiosInstance.get(
                        `/stammdaten/stammdatenartikel/?artikelnummer=${selectedArtikel.artikelnummer}`
                    );

                    if (response.data.results && response.data.results.length > 0) {
                        const artikelData = response.data.results[0];
                        const kg = parseFloat(artikelData.gewicht);
                        if (!isNaN(kg)) {
                            // Gramm = kg * 1000
                            const grams = parseInt(kg * 1000, 10);
                            setSollgewicht(grams);
                        } else {
                            setSollgewicht(null);
                        }
                    } else {
                        setSollgewicht(null);
                    }
                } catch (error) {
                    console.error('Fehler beim Laden der Artikeldetails (Sollgewicht):', error);
                    setSollgewicht(null);
                }
            } else {
                setSollgewicht(null);
            }
        };

        fetchArtikelDetails();
    }, [selectedArtikel]);

    // ----------------------------------------------------------------------------
    // Rating- und Kommentar-Handling
    // ----------------------------------------------------------------------------
    const handleRatingChange = (checkpointId, value) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [checkpointId]: value,
        }));
    };

    const openCommentDialog = (checkpointId) => {
        setCurrentCheckpoint(checkpointId);
        setCurrentComment(comments[checkpointId] || '');
        setCommentDialogOpen(true);
    };

    const handleCommentSave = () => {
        setComments((prevComments) => ({
            ...prevComments,
            [currentCheckpoint]: currentComment,
        }));
        setCommentDialogOpen(false);
    };

    // ----------------------------------------------------------------------------
    // Bild-Handling (Upload, Anzeigen, Löschen)
    // ----------------------------------------------------------------------------
    const handleImageUpload = (checkpointId) => {
        const fileInput = document.getElementById(`file-upload-${checkpointId}`);
        fileInput.click();

        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            if (!file) {
                console.error('Keine Datei ausgewählt');
                return;
            }

            const formData = new FormData();
            formData.append('image', file);
            formData.append('title', `Checkpoint ${checkpointId} Bild`);

            try {
                const response = await axiosInstance.post('/pictures/upload/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('Upload erfolgreich:', response.data);
                const image_id = response.data.image_id;

                setImages((prevImages) => ({
                    ...prevImages,
                    [checkpointId]: image_id,
                }));

                const imageUrl = URL.createObjectURL(file);
                setImagePreviews((prevPreviews) => ({
                    ...prevPreviews,
                    [checkpointId]: imageUrl,
                }));
            } catch (error) {
                console.error('Upload fehlgeschlagen:', error);
            }
        };
    };

    const handleImageDelete = (checkpointId) => {
        setImages((prevImages) => {
            const newImages = { ...prevImages };
            delete newImages[checkpointId];
            return newImages;
        });

        setImagePreviews((prevPreviews) => {
            const newPreviews = { ...prevPreviews };
            delete newPreviews[checkpointId];
            return newPreviews;
        });
    };

    const handleImageClick = async (checkpointId) => {
        const imageId = images[checkpointId];
        if (!imageId) {
            console.error('Keine Bild-ID verfügbar');
            return;
        }

        try {
            const response = await axiosInstance.get(`/pictures/get-image-url/${imageId}/`);
            setImageUrl(response.data.url);
            setImageDialogOpen(true);
        } catch (error) {
            console.error('Fehler beim Abrufen der Bild-URL:', error);
        }
    };

    // ----------------------------------------------------------------------------
    // Form-Submit
    // ----------------------------------------------------------------------------
    const handleSubmit = async (event) => {
        event.preventDefault();

        const ratingEntries = Object.entries(ratings);
        if (ratingEntries.length === 0) {
            setError('Bitte bewerten Sie mindestens einen Checkpoint.');
            return;
        }

        // Prüfen, ob Filiale & Artikel gesetzt sind
        if (!selectedFiliale || !selectedArtikel) {
            setError('Bitte wählen Sie eine Filiale und einen Artikel aus, bevor Sie fortfahren.');
            return;
        }

        try {
            const payload = ratingEntries.map(([checkpointId, value]) => {
                const checkpoint = categories
                    .flatMap((category) => category.checkpoints)
                    .find((check) => check.id === parseInt(checkpointId, 10));

                let transformedValue = value;
                if (checkpoint.rating_type === 'yesNo') {
                    transformedValue = value === 'yes' ? 1 : 0;
                } else if (checkpoint.rating_type === 'gewicht') {
                    // User gibt Gramm als Ganze Zahl ein
                    const parsedVal = parseInt(value, 10);
                    transformedValue = isNaN(parsedVal) ? 0 : parsedVal;
                } else {
                    // Skala
                    const parsedVal = parseInt(value, 10);
                    transformedValue = isNaN(parsedVal) ? 0 : parsedVal;
                }

                return {
                    value: transformedValue,
                    datum: currentDate.split('.').reverse().join('-'), // DD.MM.YYYY -> YYYY-MM-DD
                    user: user?.username || '',
                    filialnummer: parseInt(selectedFiliale.filialnummer, 10),
                    artikelnummer: parseInt(selectedArtikel.artikelnummer, 10),
                    checkpoint: parseInt(checkpointId, 10),
                    check_related: parseInt(checkId, 10),
                    comment: comments[checkpointId] || '',
                    image_id: images[checkpointId] || null,
                };
            });

            await axiosInstance.post('/qualitaet/ratings/', payload);

            // Reset
            setRatings({});
            setComments({});
            setImages({});
            setImagePreviews({});
            setSubmitSuccess(true);
            setError(null);
            onSaveSuccess();
        } catch (error) {
            setError('Fehler beim Speichern der Bewertungen.');
        }
    };

    // ----------------------------------------------------------------------------
    // Dialog-Logik
    // ----------------------------------------------------------------------------
    const handleSelectionDialogClose = () => {
        if (selectedFiliale && selectedArtikel) {
            setSelectionDialogOpen(false);
            setError(null);
        } else {
            setError('Bitte wählen Sie eine Filiale und einen Artikel aus, bevor Sie fortfahren.');
        }
    };

    // ----------------------------------------------------------------------------
    // Render
    // ----------------------------------------------------------------------------
    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            {/* Dialog zur Filial- und Artikelauswahl */}
            <Dialog open={selectionDialogOpen} onClose={handleSelectionDialogClose}>
                <DialogTitle>Filiale und Artikel auswählen</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {/* Filialauswahl */}
                    <SingleFilialeSelect
                        value={selectedFiliale}
                        onChange={(newValue) => {
                            setSelectedFiliale(newValue);
                        }}
                    />

                    {/* Artikel-Auswahl via Autocomplete */}
                    <SingleArtikelSelect
                        value={selectedArtikel}
                        onChange={(newValue) => {
                            setSelectedArtikel(newValue);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSelectionDialogClose} color="primary" variant="contained">
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog für Kommentare */}
            <Dialog open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)}>
                <DialogTitle>Kommentar hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Kommentar"
                        fullWidth
                        multiline
                        rows={4}
                        value={currentComment}
                        onChange={(e) => setCurrentComment(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCommentDialogOpen(false)} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleCommentSave} color="primary" variant="contained">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog zur Bildanzeige */}
            <Dialog
                open={imageDialogOpen}
                onClose={() => setImageDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <img src={imageUrl} alt="Checkpoint Bild" style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>

            {/* Hauptformular */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    maxWidth: { xs: '100%', sm: 800 },
                    mx: 'auto',
                    mt: 4,
                    p: { xs: 2, sm: 4 },
                }}
            >
                <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    {checkName}
                </Typography>

                {/* Anzeige von Filiale / Artikel / Datum */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        mb: 4,
                    }}
                >
                    <Typography variant="body1">
                        <strong>Filiale:</strong>{' '}
                        {selectedFiliale
                            ? selectedFiliale.filiale
                            : 'Keine Filiale ausgewählt'}
                    </Typography>

                    <Typography variant="body1">
                        <strong>Artikel:</strong>{' '}
                        {selectedArtikel
                            ? selectedArtikel.artikel
                            : 'Kein Artikel ausgewählt'}
                    </Typography>

                    <Typography variant="body1">
                        <strong>Datum:</strong> {currentDate}
                    </Typography>
                </Box>

                <Typography variant="h6" gutterBottom>
                    Checkpoints bewerten
                </Typography>

                {submitSuccess && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Bewertungen erfolgreich gespeichert!
                    </Alert>
                )}

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {categories.length > 0 ? (
                    <List>
                        {categories.map((category) => (
                            <Paper
                                key={category.id}
                                sx={{
                                    mb: 4,
                                    p: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: 2,
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {category.name}
                                </Typography>
                                <List>
                                    {category.checkpoints.map((checkpoint, index) => {
                                        const rawValue = ratings[checkpoint.id];
                                        const currentValue = parseInt(rawValue, 10) || 0;

                                        let warnung = false;
                                        let differenz = null;
                                        let prozentAbweichung = null;

                                        // Nur bei "gewicht": Abweichung prüfen
                                        if (
                                            checkpoint.rating_type === 'gewicht' &&
                                            sollgewicht !== null &&
                                            !isNaN(currentValue) &&
                                            currentValue > 0
                                        ) {
                                            differenz = Math.abs(currentValue - sollgewicht);
                                            prozentAbweichung = (differenz / sollgewicht) * 100;

                                            if (prozentAbweichung > 10) {
                                                warnung = true;
                                            }
                                        }

                                        return (
                                            <React.Fragment key={checkpoint.id}>
                                                <ListItem
                                                    sx={{
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                    }}
                                                >
                                                    {/* Kopfzeile: Checkpoint-Name, Kommentar-Icon, Foto-Icons */}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={checkpoint.name}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        <IconButton
                                                            onClick={() =>
                                                                openCommentDialog(checkpoint.id)
                                                            }
                                                            color="primary"
                                                        >
                                                            <AddCommentIcon />
                                                        </IconButton>
                                                        {checkpoint.allow_image_upload && (
                                                            <>
                                                                <input
                                                                    accept="image/*"
                                                                    id={`file-upload-${checkpoint.id}`}
                                                                    type="file"
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleImageUpload(
                                                                            checkpoint.id
                                                                        )
                                                                    }
                                                                    color="secondary"
                                                                >
                                                                    <PhotoCamera />
                                                                </IconButton>
                                                                {images[checkpoint.id] && (
                                                                    <>
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                handleImageClick(
                                                                                    checkpoint.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <PhotoLibraryIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleImageDelete(
                                                                                    checkpoint.id
                                                                                )
                                                                            }
                                                                            color="error"
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Box>

                                                    {/* Beschreibungstext */}
                                                    {checkpoint.description && (
                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                            <strong>Beschreibung:</strong>{' '}
                                                            {checkpoint.description}
                                                        </Typography>
                                                    )}

                                                    {/* Sollgewicht-Info (nur für 'gewicht') */}
                                                    {checkpoint.rating_type === 'gewicht' &&
                                                        sollgewicht !== null && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ mt: 1 }}
                                                            >
                                                                Sollgewicht: {sollgewicht} g
                                                            </Typography>
                                                        )}

                                                    {/* Bewertungselement (Yes/No, Skala oder Gewichts-Eingabe) */}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: {
                                                                xs: 'column',
                                                                sm: 'row',
                                                            },
                                                            alignItems: 'center',
                                                            mt: 1,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {checkpoint.rating_type === 'yesNo' ? (
                                                            <ButtonGroup
                                                                variant="outlined"
                                                                color="primary"
                                                                sx={{
                                                                    mt: { xs: 1, sm: 0 },
                                                                    mr: { sm: 2 },
                                                                }}
                                                            >
                                                                <Button
                                                                    variant={
                                                                        ratings[checkpoint.id] ===
                                                                        'yes'
                                                                            ? 'contained'
                                                                            : 'outlined'
                                                                    }
                                                                    onClick={() =>
                                                                        handleRatingChange(
                                                                            checkpoint.id,
                                                                            'yes'
                                                                        )
                                                                    }
                                                                >
                                                                    Ja
                                                                </Button>
                                                                <Button
                                                                    variant={
                                                                        ratings[checkpoint.id] ===
                                                                        'no'
                                                                            ? 'contained'
                                                                            : 'outlined'
                                                                    }
                                                                    onClick={() =>
                                                                        handleRatingChange(
                                                                            checkpoint.id,
                                                                            'no'
                                                                        )
                                                                    }
                                                                >
                                                                    Nein
                                                                </Button>
                                                            </ButtonGroup>
                                                        ) : checkpoint.rating_type === 'gewicht' ? (
                                                            <>
                                                                <TextField
                                                                    type="number"
                                                                    label="Gewicht (in g)"
                                                                    variant="outlined"
                                                                    sx={{
                                                                        mt: { xs: 1, sm: 0 },
                                                                        mr: { sm: 2 },
                                                                        width: '150px',
                                                                    }}
                                                                    value={ratings[checkpoint.id] || ''}
                                                                    onChange={(e) =>
                                                                        handleRatingChange(
                                                                            checkpoint.id,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                                {differenz !== null && (
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            mt: {
                                                                                xs: 1,
                                                                                sm: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        Abweichung: {differenz} g
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <ButtonGroup
                                                                variant="outlined"
                                                                color="primary"
                                                                sx={{
                                                                    mt: { xs: 1, sm: 0 },
                                                                    mr: { sm: 2 },
                                                                    flexWrap: 'wrap',
                                                                }}
                                                            >
                                                                {[...Array(maxRating).keys()].map(
                                                                    (num) => (
                                                                        <Button
                                                                            key={num + 1}
                                                                            variant={
                                                                                ratings[
                                                                                    checkpoint.id
                                                                                ] ===
                                                                                (num + 1).toString()
                                                                                    ? 'contained'
                                                                                    : 'outlined'
                                                                            }
                                                                            onClick={() =>
                                                                                handleRatingChange(
                                                                                    checkpoint.id,
                                                                                    (num + 1).toString()
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                minWidth: {
                                                                                    xs: 30,
                                                                                    sm: 40,
                                                                                },
                                                                                mb: {
                                                                                    xs: 1,
                                                                                    sm: 0,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {num + 1}
                                                                        </Button>
                                                                    )
                                                                )}
                                                            </ButtonGroup>
                                                        )}
                                                    </Box>

                                                    {/* Warnhinweis bei großer Abweichung */}
                                                    {warnung && (
                                                        <Alert severity="warning" sx={{ mt: 1 }}>
                                                            Achtung: Das eingegebene Gewicht weicht um
                                                            mehr als 10% vom Sollgewicht ab!
                                                        </Alert>
                                                    )}

                                                    {/* Kommentar-Vorschau + Bild-Vorschau */}
                                                    {comments[checkpoint.id] && (
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{ mt: 1 }}
                                                        >
                                                            <strong>Kommentar:</strong>{' '}
                                                            {comments[checkpoint.id]}
                                                        </Typography>
                                                    )}
                                                    {imagePreviews[checkpoint.id] && (
                                                        <Box sx={{ mt: 1 }}>
                                                            <img
                                                                src={imagePreviews[checkpoint.id]}
                                                                alt={`Checkpoint ${checkpoint.id}`}
                                                                style={{
                                                                    maxWidth: '100%',
                                                                    maxHeight: '200px',
                                                                }}
                                                            />
                                                        </Box>
                                                    )}
                                                    {index < category.checkpoints.length - 1 && (
                                                        <Divider sx={{ mt: 2 }} />
                                                    )}
                                                </ListItem>
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            </Paper>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">
                        Keine Kategorien und Checkpoints gefunden.
                    </Typography>
                )}

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, width: '100%' }}
                >
                    Speichern
                </Button>
            </Box>
        </>
    );
};

export default CategoryCheckpointsForm;
