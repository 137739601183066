import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import Layout from '../../../components/layout/Wiki/Wiki';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import {InfoBarContext} from "../../../provider/InfoBar";
import axiosInstance from '../../../services/axiosInstance';
import {useLocation, useNavigate} from "react-router-dom";

import DialogKategorien from '../../../components/wiki/settings/DialogKategorien';  // Dialog-Komponente für Kategorien

// Pill-Stil (runde Buttons)
const pillStyle = {
  borderRadius: '50px',
};

const SettingsIndex = () => {
  // ----------------------------
  // States
  // ----------------------------
  const [manuals, setManuals] = useState([]);
  const [loading, setLoading] = useState(true);

  // Manual erstellen
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newManual, setNewManual] = useState({ title: '', description: '' });
  const [creating, setCreating] = useState(false);

  // Kontext
  const { showError, showSuccess } = useContext(InfoBarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isSettings = location.pathname.includes('/wiki/settings/');

  // ----------------------------
  // Lokale Suche (inkl. Kategorie-Name)
  // ----------------------------
  const [localSearchQuery, setLocalSearchQuery] = useState('');

  // ----------------------------
  // Serverseitige Suche
  // ----------------------------
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  // Manual editieren
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedManualForEdit, setSelectedManualForEdit] = useState(null);
  const [editManualData, setEditManualData] = useState({
    title: '',
    description: '',
    category: null,
    group_ids: []
  });

  // Kategorien & Gruppen
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);

  // Kategorien verwalten
  const [manageCategoryOpen, setManageCategoryOpen] = useState(false);

  // Manual löschen
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [manualToDelete, setManualToDelete] = useState(null);

  // ----------------------------
  // useEffect: Daten laden
  // ----------------------------
  useEffect(() => {
    fetchManuals();
    fetchCategories();
    fetchGroups();
  }, []);

  const fetchManuals = async () => {
    try {
      const response = await axiosInstance.get('/wiki/manuals/');
      if (response.data.results && Array.isArray(response.data.results)) {
        setManuals(response.data.results);
      } else if (Array.isArray(response.data)) {
        setManuals(response.data);
      } else {
        console.error('Unerwartetes Format:', response.data);
        showError('Fehler beim Laden der Manuals.');
      }
    } catch (err) {
      console.error('Fehler beim Laden der Manuals:', err);
      showError('Fehler beim Laden der Manuals. Bitte später erneut versuchen.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await axiosInstance.get('/wiki/categories/');
      const fetchedCategories = Array.isArray(res.data.results)
        ? res.data.results
        : (Array.isArray(res.data) ? res.data : []);
      setCategories(fetchedCategories);
    } catch (err) {
      console.error('Fehler beim Laden der Kategorien:', err);
    }
  };

  const fetchGroups = async () => {
    try {
      const res = await axiosInstance.get('/auth/custom-groups/');
      setGroups(Array.isArray(res.data) ? res.data : []);
    } catch (err) {
      console.error('Fehler beim Laden der Gruppen:', err);
    }
  };

  // ----------------------------
  // Lokale Filterung (auch Kategorie)
  // ----------------------------
  const filteredManuals = manuals.filter((manual) => {
    const lcQuery = localSearchQuery.toLowerCase();

    // Titel & Beschreibung
    const titleMatch = manual.title.toLowerCase().includes(lcQuery);
    const descMatch = (manual.description || '').toLowerCase().includes(lcQuery);

    // Kategorie-Name
    const catObj = categories.find((cat) => cat.id === manual.category);
    const catName = catObj ? catObj.name.toLowerCase() : '';
    const catMatch = catName.includes(lcQuery);

    return titleMatch || descMatch || catMatch;
  });

  // ----------------------------
  // Manual erstellen
  // ----------------------------
  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewManual({ title: '', description: '' });
  };

  const handleCreateManual = async () => {
    if (!newManual.title) {
      showError('Bitte einen Titel eingeben.');
      return;
    }
    setCreating(true);
    try {
      const response = await axiosInstance.post('/wiki/manuals/', newManual);
      setManuals([...manuals, response.data]);
      handleCloseCreateDialog();
      showSuccess('Manual erfolgreich erstellt');
    } catch (err) {
      console.error('Fehler beim Erstellen des Manuals:', err);
      showError('Fehler beim Erstellen des Manuals.');
    } finally {
      setCreating(false);
    }
  };

  // ----------------------------
  // Navigation / Auswahl
  // ----------------------------
  const handleSelectManual = (manual) => {
    navigate(`/wiki/settings/${manual.slug}`);
  };

  // ----------------------------
  // Serverseitige Suche
  // ----------------------------
  const handleSearch = async () => {
    if (!searchQuery || searchQuery.length < 3) {
      setError('Bitte mind. 3 Zeichen eingeben.');
      return;
    }
    setSearchLoading(true);
    try {
      const response = await axiosInstance.get(
        `/wiki/search/?q=${encodeURIComponent(searchQuery)}`
      );
      setSearchResults(response.data);
      setError(null);
    } catch (err) {
      console.error('Search error:', err);
      setError('Fehler bei der Suche.');
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSearchResultClick = (type, item) => {
    setSearchResults(null);
    const base = isSettings ? '/wiki/settings' : '/wiki/overview';

    if (type === 'manual') {
      navigate(`${base}/${item.slug}`);
    } else if (type === 'chapter') {
      navigate(`${base}/${item.manual}/${item.slug}`);
    } else if (type === 'entry') {
      navigate(`${base}/${item.manual.slug}/${item.chapter}/${item.slug}`);
    }
  };

  // ----------------------------
  // Manual bearbeiten
  // ----------------------------
  const handleEdit = (manual) => {
    setSelectedManualForEdit(manual);
    setEditManualData({
      title: manual.title,
      description: manual.description || '',
      category: manual.category || null,
      group_ids: manual.group_ids || []
    });
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedManualForEdit(null);
  };

  const handleUpdateManual = async () => {
    if (!selectedManualForEdit) return;
    try {
      await axiosInstance.put(
        `/wiki/manuals/${selectedManualForEdit.slug}/`,
        editManualData
      );
      showSuccess('Manual erfolgreich aktualisiert');
      fetchManuals();
      handleEditDialogClose();
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Manuals:', error);
      showError('Fehler beim Aktualisieren des Manuals.');
    }
  };

  // ----------------------------
  // Kategorien verwalten
  // ----------------------------
  const handleOpenManageCategory = () => {
    setManageCategoryOpen(true);
  };

  const handleCloseManageCategory = () => {
    setManageCategoryOpen(false);
  };

  const handleCreateCategory = async (name) => {
    try {
      await axiosInstance.post('/wiki/categories/', { name });
      showSuccess('Kategorie erfolgreich erstellt');
      await fetchCategories();
    } catch (err) {
      console.error('Fehler beim Erstellen der Kategorie:', err);
      showError('Fehler beim Erstellen der Kategorie.');
    }
  };

  const handleUpdateCategory = async (id, newName) => {
    try {
      await axiosInstance.put(`/wiki/categories/${id}/`, { name: newName });
      showSuccess('Kategorie erfolgreich aktualisiert');
      await fetchCategories();
    } catch (err) {
      console.error('Fehler beim Aktualisieren der Kategorie:', err);
      showError('Fehler beim Aktualisieren der Kategorie.');
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axiosInstance.delete(`/wiki/categories/${id}/`);
      showSuccess('Kategorie gelöscht');
      await fetchCategories();
    } catch (err) {
      console.error('Fehler beim Löschen der Kategorie:', err);
      showError('Fehler beim Löschen der Kategorie.');
    }
  };

  // ----------------------------
  // Manual löschen
  // ----------------------------
  const handleDeleteManualClick = (manual) => {
    setManualToDelete(manual);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setManualToDelete(null);
  };

  const handleDeleteManual = async () => {
    if (!manualToDelete) return;
    try {
      await axiosInstance.delete(`/wiki/manuals/${manualToDelete.slug}/`);
      showSuccess('Manual erfolgreich gelöscht');
      fetchManuals();
    } catch (err) {
      console.error('Fehler beim Löschen des Manuals:', err);
      showError('Fehler beim Löschen des Manuals.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  // ----------------------------
  // Render
  // ----------------------------
  return (
    <Layout>
            <Grid container item spacing ={2} alignItems={"center"}>
            {/* Kopfzeile mit Titel & Suche (serverseitig) */}
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Grid item>
            <PageHeader
              title="Wiki"
              subtitle="Eurer zentralen Anlaufstelle für alle Fragen und Informationen, die euren Alltag betreffen."
              Icon={AnnouncementOutlinedIcon}
            />
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                variant="outlined"
                placeholder="Suche..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  width: '200px',
                  transition: 'width 0.3s ease',
                  '&:focus-within': {
                    width: '300px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '50px', // "Pill"-Form
                    fontSize: '0.75rem',
                    padding: '2px 8px',
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '4.5px 8px',
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        {searchResults && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Suchergebnisse</Typography>
            {searchLoading ? (
              <CircularProgress />
            ) : (
              <>
                {searchResults.manuals && searchResults.manuals.map((m) => (
                  <Typography
                    key={`manual-${m.id}`}
                    onClick={() => handleSearchResultClick('manual', m)}
                    sx={{ cursor: 'pointer', mb: 1 }}
                  >
                    {m.title} (Manual)
                  </Typography>
                ))}
                {searchResults.manuals?.length === 0 &&
                  searchResults.chapters?.length === 0 &&
                  searchResults.entries?.length === 0 && (
                    <Typography variant="body2">Keine Ergebnisse.</Typography>
                  )}
              </>
            )}
          </Box>
        )}

        {/* Jetzt ein Grid-Container, in dem wir die Card auf volle Breite platzieren */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Paper>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // Leicht transparente Card
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                  }}
                >
                  <CardHeader
                    title={
                      <Typography variant="h6" component="div">
                        Wikis
                      </Typography>
                    }
                    action={
                      isSettings && (
                        <Stack direction="row" spacing={2} alignItems="center">
                          {/* Neuer Suchstil (kleines TextField, Label="Suchen") */}
                          <Box sx={{ width: '200px' }}>
                            <TextField
                              fullWidth
                              label="Suchen"
                              value={localSearchQuery}
                              onChange={(e) => setLocalSearchQuery(e.target.value)}
                              size="small"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '50px',
                                  fontSize: '0.75rem',
                                  padding: '2px 8px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '4.5px 8px',
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.875rem',
                                },
                                '& .MuiInputBase-root': {
                                  height: '30px',
                                },
                              }}
                            />
                          </Box>

                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{
                              borderRadius: '50px',
                              fontSize: '0.75rem',
                              padding: '2px 8px',
                              minWidth: 'auto',
                            }}
                            onClick={handleOpenCreateDialog}
                          >
                            Neues Wiki anlegen
                          </Button>

                          <Button
                            variant="outlined"
                            startIcon={<FilterListOutlinedIcon />}
                            sx={{
                              borderRadius: '50px',
                              fontSize: '0.75rem',
                              padding: '2px 8px',
                              minWidth: 'auto',
                            }}
                            onClick={handleOpenManageCategory}
                          >
                            Kategorien verwalten
                          </Button>
                        </Stack>
                      )
                    }
                  />
                  <CardContent>
                    <Table
                      sx={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                      }}
                    >
                      <TableHead
                        sx={{
                          '& th': {
                            fontWeight: 'bold'
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>Titel</TableCell>
                          <TableCell>Beschreibung</TableCell>
                          <TableCell>Kategorie</TableCell>
                          <TableCell>Gruppen</TableCell>
                          <TableCell align="right">Aktionen</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredManuals.map((manual) => (
                          <TableRow key={manual.id}>
                            <TableCell>{manual.title}</TableCell>
                            <TableCell>{manual.description}</TableCell>
                            <TableCell>
                              {manual.category
                                ? categories.find((c) => c.id === manual.category)?.name
                                : 'Keine'}
                            </TableCell>
                            <TableCell>
                              {manual.group_ids && manual.group_ids.length > 0
                                ? manual.group_ids
                                    .map(
                                      (gid) =>
                                        groups.find((g) => g.id === gid)?.name || gid
                                    )
                                    .join(', ')
                                : 'Keine'}
                            </TableCell>
                            <TableCell align="right">
                              {/* Icons / Buttons */}
                              <IconButton
                                color="primary"
                                onClick={() => handleSelectManual(manual)}
                                sx={{ mr: 1, ...pillStyle }}
                              >
                                <ArrowForwardIosIcon />
                              </IconButton>
                              {isSettings && (
                                <>
                                  <IconButton
                                    onClick={() => handleEdit(manual)}
                                    sx={{ mr: 1, ...pillStyle }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    onClick={() => handleDeleteManualClick(manual)}
                                    sx={pillStyle}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Paper>
          </Grid>
        </Grid>

        {/* DIALOG: Neues Manual erstellen */}
        <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
          <DialogTitle>Neues Manual erstellen</DialogTitle>
          <DialogContent>
            <TextField
              label="Titel"
              value={newManual.title}
              onChange={(e) => setNewManual({ ...newManual, title: e.target.value })}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Beschreibung"
              value={newManual.description}
              onChange={(e) =>
                setNewManual({ ...newManual, description: e.target.value })
              }
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreateDialog}>Abbrechen</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateManual}
              disabled={creating}
              sx={pillStyle}
              startIcon={<AddIcon />}
            >
              {creating ? 'Erstellen...' : 'Erstellen'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG: Manual bearbeiten */}
        <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Manual bearbeiten</DialogTitle>
          <DialogContent>
            <TextField
              label="Titel"
              value={editManualData.title}
              onChange={(e) =>
                setEditManualData({ ...editManualData, title: e.target.value })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Beschreibung"
              value={editManualData.description}
              onChange={(e) =>
                setEditManualData({ ...editManualData, description: e.target.value })
              }
              fullWidth
              multiline
              sx={{ mb: 2 }}
            />
            {/* Kategorie-Auswahl */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Kategorie</InputLabel>
              <Select
                label="Kategorie"
                value={editManualData.category || ''}
                onChange={(e) =>
                  setEditManualData({ ...editManualData, category: e.target.value })
                }
              >
                <MenuItem value="">Keine</MenuItem>
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Gruppen-Auswahl */}
            <FormControl fullWidth>
              <InputLabel>Gruppen</InputLabel>
              <Select
                label="Gruppen"
                multiple
                value={editManualData.group_ids}
                onChange={(e) =>
                  setEditManualData({
                    ...editManualData,
                    group_ids: e.target.value
                  })
                }
              >
                {groups.map((grp) => (
                  <MenuItem key={grp.id} value={grp.id}>
                    {grp.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Abbrechen</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateManual}
              sx={pillStyle}
              startIcon={<EditIcon />}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG: Kategorien verwalten */}
        <DialogKategorien
          open={manageCategoryOpen}
          onClose={handleCloseManageCategory}
          categories={categories}
          onCreateCategory={handleCreateCategory}
          onUpdateCategory={handleUpdateCategory}
          onDeleteCategory={handleDeleteCategory}
        />

        {/* DIALOG: Manual löschen */}
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Manual löschen</DialogTitle>
          <DialogContent>
            <Typography>
              Möchten Sie das Manual "{manualToDelete?.title}" wirklich löschen?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Abbrechen</Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteManual}
              sx={pillStyle}
              startIcon={<DeleteIcon />}
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
        </Grid>
        </Layout>
  );
};

export default SettingsIndex;
