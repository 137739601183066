import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../../components/layout/Scorecards/FilialeWocheLayout';
import { 
  Box, 
  Container, 
  Grid, 
  CircularProgress, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography 
} from "@mui/material";
import dayjs from "dayjs";
import axiosInstance from "../../../services/axiosInstance";
import { tausenderTrennung } from "../../../utils/mathStuff";
import { useTheme } from '@mui/material/styles';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import VerkaufsgebietFilterSelection from "../../../components/tagesreport/VerkaufsgebietFilterSelection";
import TagesreportDetailsPopup from "../../../components/popup/TagesreportDetailsPopup";
import DataCard from "../../../components/card/scorecardsmonat/DataCardFilialeWoche";
import { FilterContext } from "../../../provider/FilterProvider";
import Score from '../../../components/score/Score'; // Score-Komponente importieren

const Cockpit = () => {
  const theme = useTheme();
  const { filterState } = useContext(FilterContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
  const [selectedFiliale, setSelectedFiliale] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [greeting, setGreeting] = useState('');
  const [userName, setUserName] = useState('');
  const [sparklineDataSets, setSparklineDataSets] = useState([]);
  const [rawData, setRawData] = useState([]);

  const selectedJahrKw = filterState?.datumWoche?.value || "2024.49";
  const [year, week] = selectedJahrKw.split('.').map(Number);

  const getLast6Kws = (year, week) => {
    const weeks = [];
    for (let i = 5; i >= 0; i--) {
      let w = week - i;
      let y = year;
      if (w < 1) {
        y = year - 1;
        w = 52 + w;
      }
      weeks.push(`${y}.${w}`);
    }
    return weeks;
  };

  const last6Kws = getLast6Kws(year, week);

  const setGreetingMessage = () => {
    const currentHour = dayjs().hour();
    if (currentHour < 12) {
      setGreeting('Guten Morgen');
    } else if (currentHour < 18) {
      setGreeting('Hallo');
    } else {
      setGreeting('Guten Abend');
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const response = await axiosInstance.get("auth/current_user/");
      if (response.data && response.data.username) {
        setUserName(response.data.username);
      }
    } catch (error) {
      console.error("Fehler beim Laden des Benutzers:", error);
    }
  };

  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
      setVerkaufsgebietOptions(response.data.map(item => ({ label: item, value: item })));
    } catch (error) {
      console.error('Error while fetching VerkaufsgebietOptions', error);
    }
  };

  const handleRowClick = (row) => {
    const { Datum, Filiale } = row;
    setSelectedFiliale({ Datum, Filiale });
    setPopupOpen(true);
  };

  // Um formatierte Strings (z.B. "1.234,56") wieder in eine Nummer zu verwandeln
  const parseNumberString = (str) => {
    if (!str) return 0;
    // Entferne Tausenderpunkte, ersetze Komma durch Punkt
    return parseFloat(str.replace(/\./g, '').replace(',', '.')) || 0;
  };

  const formatNumber = (value, decimals = 2) => {
    if (value === null || value === undefined) return '0';
    return tausenderTrennung(value, decimals);
  };

  const enhancedFetchData = async () => {
    setLoading(true);
    try {
      const mainResponse = await axiosInstance.get("/scorecard_week/performance-woche/", {
        params: {
          jahrKw: selectedJahrKw,
          verkaufsgebiet: verkaufsgebietFilter?.value || undefined,
        },
      });

      const sparklineResponses = await Promise.all(
        last6Kws.map(kw =>
          axiosInstance.get("/scorecard_week/performance-woche/", {
            params: {
              jahrKw: kw,
              verkaufsgebiet: verkaufsgebietFilter?.value || undefined,
            },
          })
        )
      );

      const mainData = Array.isArray(mainResponse.data.results) ? mainResponse.data.results : [];
      const sparkData = sparklineResponses.map(r => Array.isArray(r.data.results) ? r.data.results : []);

      setSparklineDataSets(sparkData);
      setRawData(mainData);

      const mappedData = mainData.map(item => {
        const umsatzFormatted = formatNumber(item.umsatz || 0, 2);
        const kundenanzahlFormatted = formatNumber(item.kundenanzahl || 0, 0);
        const kundenbonFormatted = formatNumber(item.kundenbon || 0, 2);
        const leistungFormatted = formatNumber(item.leistung || 0, 2);

        const scoreUmsatz = item.scoreUmsatz || 0;
        const scoreKundenanzahl = item.scoreKundenanzahl || 0;
        const scoreKundenbon = item.scoreKundenbon || 0;
        const scoreLeistung = item.scoreLeistung || 0;

        return {
          Filiale: item.filiale,
          umsatz: umsatzFormatted,
          scoreUmsatz,
          kundenanzahl: kundenanzahlFormatted,
          scoreKundenanzahl,
          kundenbon: kundenbonFormatted,
          scoreKundenbon,
          leistung: leistungFormatted,
          scoreLeistung,
          produktivstunden: item.produktivstunden || 0,
          Datum: item.datum,
        };
      });

      setData(mappedData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGreetingMessage();
    fetchCurrentUser();
    fetchVerkaufsgebietOptions();

  }, []);

  useEffect(() => {
    enhancedFetchData();
 
  }, [selectedJahrKw, verkaufsgebietFilter]);

  // Gesamtwerte für die Karten basierend auf rawData (numerisch):
  const totalUmsatzNum = rawData.reduce((acc, i) => acc + (i.umsatz || 0), 0);
  const totalKundenNum = rawData.reduce((acc, i) => acc + (i.kundenanzahl || 0), 0);
  const totalStdNum = rawData.reduce((acc, i) => acc + (i.produktivstunden || 0), 0);

  const totalKundenbonNum = totalKundenNum > 0 ? totalUmsatzNum / totalKundenNum : 0;
  const totalLeistungNum = totalStdNum > 0 ? totalUmsatzNum / totalStdNum : 0;

  const totalUmsatzRounded = formatNumber(totalUmsatzNum, 0);
  const totalKundenRounded = formatNumber(totalKundenNum, 0);
  const totalKundenbonRounded = formatNumber(totalKundenbonNum, 2);
  const totalLeistungRounded = formatNumber(totalLeistungNum, 2);

  const safeSparklineDataSets = Array.isArray(sparklineDataSets) ? sparklineDataSets : [];

  const aggregateMetricPerKw = (sparkDataSets, metric) => {
    return sparkDataSets.map(kwData => {
      const total = kwData.reduce((sum, item) => sum + (item[metric] || 0), 0);
      return total;
    });
  };

  const umsatzSparkline = aggregateMetricPerKw(safeSparklineDataSets, 'umsatz') || [];
  const kundenSparkline = aggregateMetricPerKw(safeSparklineDataSets, 'kundenanzahl') || [];

  const kundenbonSparkline = safeSparklineDataSets.map(kwData => {
    const sumUmsatz = kwData.reduce((sum, i) => sum + (i.umsatz || 0), 0);
    const sumKunden = kwData.reduce((sum, i) => sum + (i.kundenanzahl || 0), 0);
    return sumKunden > 0 ? sumUmsatz / sumKunden : 0;
  }) || [];

  const leistungSparkline = safeSparklineDataSets.map(kwData => {
    const sumUmsatz = kwData.reduce((sum, i) => sum + (i.umsatz || 0), 0);
    const sumStd = kwData.reduce((sum, i) => sum + (i.produktivstunden || 0), 0);
    return sumStd > 0 ? sumUmsatz / sumStd : 0;
  }) || [];

  // Footer Werte (optional)
  const footerValues = {
    Filiale: 'Gesamt',
    umsatz: formatNumber(totalUmsatzNum, 2),
    kundenanzahl: formatNumber(totalKundenNum, 0),
    kundenbon: formatNumber(totalKundenbonNum, 2),
    leistung: formatNumber(totalLeistungNum, 2),
    scoreUmsatz: '',
    scoreKundenanzahl: '',
    scoreKundenbon: '',
    scoreLeistung: '',
  };

  // Nur Filialen anzeigen, die nicht nur 0 in allen Kennzahlen haben
  const filteredData = data.filter((item) => {
    const umsatzVal = parseNumberString(item.umsatz);
    const kundenanzahlVal = parseNumberString(item.kundenanzahl);
    const kundenbonVal = parseNumberString(item.kundenbon);
    const leistungVal = parseNumberString(item.leistung);

    // Zeige Zeile nur an, wenn mindestens eines > 0 ist
    return (
      umsatzVal > 0 ||
      kundenanzahlVal > 0 ||
      kundenbonVal > 0 ||
      leistungVal > 0
    );
  });

  return (
    <Layout>
      <Container maxWidth={false} sx={{ padding: '0 16px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <PageHeader
            title={`Wochenreport KW ${selectedJahrKw}`}
            subtitle={`${greeting}, ${userName}! Hier der Report für KW ${selectedJahrKw}. Viel Erfolg!`}
            Icon={BarChartIcon}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            {/* Umsatz Card */}
            <Grid item md={3} sm={6}>
              <DataCard
                sparklineData={umsatzSparkline || []}
                category="Umsatz"
                value={`${totalUmsatzRounded}€`}
              />
            </Grid>
            {/* Kundenanzahl Card */}
            <Grid item md={3} sm={6}>
              <DataCard
                sparklineData={kundenSparkline || []}
                category="Kundenanzahl"
                value={totalKundenRounded}
              />
            </Grid>
            {/* Kundenbon Card */}
            <Grid item md={3} sm={6}>
              <DataCard
                sparklineData={kundenbonSparkline || []}
                category="Kundenbon"
                value={totalKundenbonRounded}
              />
            </Grid>
            {/* Leistung Card */}
            <Grid item md={3} sm={6}>
              <DataCard
                sparklineData={leistungSparkline || []}
                category="Leistung"
                value={totalLeistungRounded}
              />
            </Grid>
          </Grid>
        </Box>

        <br />
        <VerkaufsgebietFilterSelection
          verkaufsgebietFilter={verkaufsgebietFilter}
          setVerkaufsgebietFilter={setVerkaufsgebietFilter}
          verkaufsgebietOptions={verkaufsgebietOptions}
        />
        <br />

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress size={80} />
          </div>
        ) : (
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography>Filiale</Typography></TableCell>
                    <TableCell><Typography>Umsatz</Typography></TableCell>
                    <TableCell><Typography>ScoreUmsatz</Typography></TableCell>
                    <TableCell><Typography>Kundenanzahl</Typography></TableCell>
                    <TableCell><Typography>ScoreKundenanzahl</Typography></TableCell>
                    <TableCell><Typography>Kundenbon</Typography></TableCell>
                    <TableCell><Typography>ScoreKundenbon</Typography></TableCell>
                    <TableCell><Typography>Leistung</Typography></TableCell>
                    <TableCell><Typography>ScoreLeistung</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(item)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Typography>{item.Filiale}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.umsatz}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Score score={item.scoreUmsatz} />
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.kundenanzahl}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Score score={item.scoreKundenanzahl} />
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.kundenbon}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Score score={item.scoreKundenbon} />
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.leistung}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Score score={item.scoreLeistung} />
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* Footerzeile */}
                  <TableRow>
                    <TableCell>
                      <Typography>{footerValues.Filiale}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.umsatz}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.scoreUmsatz}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.kundenanzahl}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.scoreKundenanzahl}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.kundenbon}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.scoreKundenbon}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.leistung}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{footerValues.scoreLeistung}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        <TagesreportDetailsPopup
          open={popupOpen}
          handleClose={() => setPopupOpen(false)}
          filialeData={selectedFiliale}
        />
      </Container>
    </Layout>
  );
};

export default Cockpit;
