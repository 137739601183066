import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const RechartsLineChartFixedHeight = ({ data, title, height = 200 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Daten vorbereiten: Wir nutzen einfach den X-Wert so, wie er reinkommt.
  // Zusätzlich gruppieren wir Einträge mit dem gleichen X-Wert.
  const processedData = data.reduce((acc, item) => {
    const xValue = item.x; // kein Date-Parsing, wir nehmen den Wert unverändert
    let entry = acc.find(e => e.x === xValue);
    if (!entry) {
      entry = { x: xValue };
      acc.push(entry);
    }
    entry[item.label] = item.y || 0;
    return acc;
  }, []);

  // Alle Labels ermitteln (einzigartige `label` aus den Daten)
  const uniqueLabels = [...new Set(data.map(item => item.label))];

  // Min/Max für Y-Achse berechnen, damit der Chart etwas Luft hat
  const allYValues = data.map(item => item.y || 0);
  const minYValue = Math.min(...allYValues);
  const maxYValue = Math.max(...allYValues);
  const adjustedMinYValue =
    minYValue > 0
      ? Math.max(0, minYValue - (maxYValue - minYValue) * 0.2)
      : minYValue - (maxYValue - minYValue) * 0.2;
  const adjustedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.2;

  // Dynamische Schriftgrößen
  const getFontSize = () => {
    if (isSmallScreen) {
      return 8;
    } else if (isMediumScreen) {
      return 10;
    } else {
      return 14;
    }
  };

  // Hilfsfunktion für Zahlenformatierung
  const formatNumberWithCommas = (number, withDecimals = true) => {
    return number.toLocaleString('de-DE', {
      maximumFractionDigits: withDecimals ? 2 : 0,
      minimumFractionDigits: withDecimals ? 2 : 0,
    });
  };

  return (
    <Box>
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          data={processedData}
          margin={{ top: 10, right: 10, bottom: 10, left: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            tick={{ fontSize: getFontSize() }}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            tick={{ fontSize: getFontSize() }}
            width={isSmallScreen ? 50 : 70}
            tickFormatter={(value) => formatNumberWithCommas(value)}
            domain={[adjustedMinYValue, adjustedMaxYValue]}
          />
          <Tooltip
            contentStyle={{ fontSize: getFontSize() }}
            formatter={(value) => formatNumberWithCommas(value, true)}
          />
          <Legend
            wrapperStyle={{ fontSize: getFontSize() }}
            iconType="circle"
            iconSize={getFontSize()}
          />
          {uniqueLabels.map((label) => (
            <Line
              key={label}
              type="monotone"
              dataKey={label}
              stroke={
                label === 'Vorjahr'
                  ? theme.palette.chart?.pink || '#ff7777'
                  : theme.palette.primary.main
              }
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default RechartsLineChartFixedHeight;
