import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, List, Alert } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import AddIcon from '@mui/icons-material/Add';
import CategoryItem from './CategoryItem';
import CategoryDialog from './CategoryDialog';
import TopicDialog from './TopicDialog';
import StepDialog from './StepDialog';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const OnboardingPlanKonfiguration = ({ open, handleClose, planId, planName }) => {
    const [categoryName, setCategoryName] = useState('');
    const [topicName, setTopicName] = useState('');
    const [stepName, setStepName] = useState('');
    const [categories, setCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [steps, setSteps] = useState({});
    const [openTopics, setOpenTopics] = useState({});
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [openStepDialog, setOpenStepDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTopic, setEditingTopic] = useState(null);
    const [editingStep, setEditingStep] = useState(null);

    useEffect(() => {
        if (open) {
            axiosInstance.get(`/onboarding/categories/`)
                .then(response => {
                    const filteredCategories = response.data.results
                        .filter(category => category.onboarding_plan === planId)
                        .sort((a, b) => a.order - b.order);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Kategorien:', error);
                });

            axiosInstance.get(`/onboarding/topics/`)
                .then(response => {
                    const sortedTopics = response.data.results.sort((a, b) => a.order - b.order);
                    setTopics(sortedTopics);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Themen:', error);
                });

            axiosInstance.get(`/onboarding/onboarding-steps/`)
                .then(response => {
                    const data = response.data.results;
                    const stepsByTopic = data.reduce((acc, step) => {
                        if (!acc[step.topic]) {
                            acc[step.topic] = [];
                        }
                        acc[step.topic].push(step);
                        return acc;
                    }, {});

                    for (const tId in stepsByTopic) {
                        stepsByTopic[tId].sort((a, b) => a.order - b.order);
                    }
                    setSteps(stepsByTopic);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Schritte:', error);
                });
        }
    }, [open, planId]);

    const handleToggleTopic = (topicId) => {
        setOpenTopics(prevOpenTopics => ({
            ...prevOpenTopics,
            [topicId]: !prevOpenTopics[topicId]
        }));
        setSelectedTopic(topicId);
    };

    const handleSaveCategory = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            axiosInstance.patch(`/onboarding/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    const updatedCategories = categories.map(category => category.id === editingCategory.id ? response.data : category);
                    setCategories(updatedCategories);
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error(error);
                });
        } else {
            const newCatOrder = categories.length;
            axiosInstance.post('/onboarding/categories/', { name: categoryName, onboarding_plan: planId, order: newCatOrder })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error(error);
                });
        }
    };

    const handleSaveTopic = (name, wiki_link) => {
        if (name.trim() === '') {
            setError('Themenname darf nicht leer sein.');
            return;
        }

        if (editingTopic) {
            axiosInstance.patch(`/onboarding/topics/${editingTopic.id}/`, { name: name, wiki_link: wiki_link })
                .then((response) => {
                    const updatedTopics = topics.map(topic => topic.id === editingTopic.id ? response.data : topic);
                    setTopics(updatedTopics);
                    setEditingTopic(null);
                    setTopicName('');
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Themas.');
                    console.error(error);
                });
        } else {
            const orderForNewTopic = topics.filter(t => t.category === selectedCategory).length;
            axiosInstance.post('/onboarding/topics/', { name: name, category: selectedCategory, wiki_link: wiki_link, order: orderForNewTopic })
                .then((response) => {
                    setTopics([...topics, response.data]);
                    setTopicName('');
                    setError(null);
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Themas.');
                    console.error(error);
                });
        }
    };

    // Logik zum Speichern von Schritten
    const handleSaveStep = () => {
        if (stepName.trim() === '') {
            setError('Schrittname darf nicht leer sein.');
            return;
        }

        if (editingStep) {
            axiosInstance.patch(`/onboarding/onboarding-steps/${editingStep.id}/`, { description: stepName })
                .then(response => {
                    // Aktualisiere den Schritt im Zustand 'steps'
                    setSteps(prevSteps => ({
                        ...prevSteps,
                        [editingStep.topic]: prevSteps[editingStep.topic].map(step => step.id === editingStep.id ? response.data : step)
                    }));
                    setEditingStep(null);
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);
                })
                .catch(error => {
                    setError('Fehler beim Bearbeiten des Schritts.');
                    console.error(error);
                });
        } else {
            const currentSteps = steps[selectedTopic] || [];
            const orderForNewStep = currentSteps.length;
            axiosInstance.post('/onboarding/onboarding-steps/', {
                description: stepName,
                order: orderForNewStep,
                topic: selectedTopic
            })
                .then((response) => {
                    // Füge den neuen Schritt zur aktuellen Liste der Schritte unter dem ausgewählten Thema hinzu
                    setSteps(prevSteps => ({
                        ...prevSteps,
                        [selectedTopic]: [...(prevSteps[selectedTopic] || []), response.data]
                    }));
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Schritts.');
                    console.error(error);
                });
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            await axiosInstance.delete(`/onboarding/categories/${id}/`);
            const updatedCategories = categories.filter(category => category.id !== id);
            const reorderedCategories = updatedCategories.map((cat, idx) => ({ ...cat, order: idx }));
            setCategories(reorderedCategories);
            await updateCategoryOrder(reorderedCategories);
        } catch (error) {
                console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    const handleDeleteTopic = async (id) => {
        try {
            await axiosInstance.delete(`/onboarding/topics/${id}/`);
            const updatedTopics = topics.filter(topic => topic.id !== id);
            // Neu ordnen innerhalb der jeweiligen Kategorie
            const reorderedTopics = reorderWithinGroup(updatedTopics, 'category');
            setTopics(reorderedTopics);
            await updateTopicOrder(reorderedTopics);
        } catch (error) {
                console.error('Fehler beim Löschen des Themas:', error);
        }
    };

    const handleDeleteStep = async (step) => {
        try {
            await axiosInstance.delete(`/onboarding/onboarding-steps/${step.id}/`);
            const updatedSteps = { ...steps };
            updatedSteps[step.topic] = updatedSteps[step.topic].filter(s => s.id !== step.id);
            // Neu ordnen
            updatedSteps[step.topic] = updatedSteps[step.topic].map((s, idx) => ({ ...s, order: idx }));
            setSteps(updatedSteps);
            await updateStepOrder(updatedSteps[step.topic], step.topic);
        } catch (error) {
                console.error('Fehler beim Löschen des Schritts:', error);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditTopic = (topic) => {
        setEditingTopic(topic);
        setTopicName(topic.name);
        setOpenTopicDialog(true);
    };

    const handleEditStep = (step) => {
        setEditingStep(step);
        setStepName(step.description);
        setOpenStepDialog(true);
    };

    const handleAddTopicPlaceholderClick = (categoryId) => {
        setTopicName('');
        setEditingTopic(null);
        setSelectedCategory(categoryId);
        setOpenTopicDialog(true);
    };

    const handleAddStepPlaceholderClick = (topicId) => {
        setStepName('');
        setEditingStep(null);
        setSelectedTopic(topicId);
        setOpenStepDialog(true);
    };

    // Hilfsfunktionen zum Neuordnen
    const reorderWithinGroup = (items, groupField) => {
        const grouped = items.reduce((acc, item) => {
            const g = item[groupField];
            if (!acc[g]) acc[g] = [];
            acc[g].push(item);
            return acc;
        }, {});

        for (const g in grouped) {
            grouped[g].sort((a, b) => a.order - b.order);
            grouped[g] = grouped[g].map((it, idx) => ({ ...it, order: idx }));
        }

        return Object.values(grouped).flat();
    };

    // Update-Funktionen für die Reihenfolge im Backend
    const updateCategoryOrder = async (categories) => {
        try {
            const updated = categories.map((cat, idx) => ({ id: cat.id, order: idx }));
            await axiosInstance.post('/onboarding/categories/update_order/', { categories: updated });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Kategorienreihenfolge:', error);
        }
    };

    const updateTopicOrder = async (topics) => {
        try {
            const updated = topics.map((t) => ({ id: t.id, order: t.order }));
            await axiosInstance.post('/onboarding/topics/update_order/', { topics: updated });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Topic-Reihenfolge:', error);
        }
    };

    const updateStepOrder = async (stepsArray, topicId) => {
        try {
            const updated = stepsArray.map((s) => ({ id: s.id, order: s.order }));
            await axiosInstance.post('/onboarding/onboarding-steps/update_order/', { steps: updated });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Steps-Reihenfolge:', error);
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination, type, draggableId } = result;

        if (!destination) return;

        if (type === 'category') {
            if (source.index === destination.index) return;

            const reorderedCategories = Array.from(categories);
            const [movedCat] = reorderedCategories.splice(source.index, 1);
            reorderedCategories.splice(destination.index, 0, movedCat);
            reorderedCategories.forEach((cat, idx) => cat.order = idx);

            setCategories(reorderedCategories);
            await updateCategoryOrder(reorderedCategories);

        } else if (type === 'topic') {
            // Nur innerhalb der gleichen Kategorie verschieben
            if (source.droppableId !== destination.droppableId) {
                // Wenn versucht wird, in eine andere Kategorie zu verschieben, tu nichts
                return;
            }

            const catId = parseInt(source.droppableId, 10);
            // Filtere alle Topics dieser Kategorie
            const catTopics = topics.filter(t => t.category === catId);
            const [movedTopic] = catTopics.splice(source.index, 1);
            catTopics.splice(destination.index, 0, movedTopic);

            // entferne alte catTopics aus topics
            let otherTopics = topics.filter(t => t.category !== catId);
            // catTopics neu ordnen
            catTopics.forEach((t, i) => t.order = i);

            const updatedTopics = [...otherTopics, ...catTopics];
            setTopics(updatedTopics);
            await updateTopicOrder(updatedTopics);

        } else if (type === 'step') {
            if (source.droppableId !== destination.droppableId) {
                // Schritte nur innerhalb des gleichen Topics, wenn nötig
                return;
            }
            const topicId = parseInt(source.droppableId, 10);
            const currentSteps = steps[topicId] || [];
            const [movedStep] = currentSteps.splice(source.index, 1);
            currentSteps.splice(destination.index, 0, movedStep);
            currentSteps.forEach((s, idx) => s.order = idx);
            setSteps(prev => ({ ...prev, [topicId]: currentSteps }));
            await updateStepOrder(currentSteps, topicId);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Onboarding Plan Konfiguration - {planName}</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box component="form" sx={{ mb: 2 }}>
                            <Droppable droppableId="categories" type="category">
                                {(provided) => (
                                    <List
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {categories.length > 0 ? categories.map((category, index) => (
                                            <Draggable
                                    key={category.id}
                                                draggableId={`category-${category.id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <CategoryItem
                                    category={category}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    topics={topics.filter(topic => topic.category === category.id)}
                                    steps={steps}
                                    openTopics={openTopics}
                                    handleToggleTopic={handleToggleTopic}
                                    handleDeleteCategory={handleDeleteCategory}
                                    handleEditCategory={handleEditCategory}
                                    handleDeleteTopic={handleDeleteTopic}
                                    handleEditTopic={handleEditTopic}
                                    handleDeleteStep={handleDeleteStep}
                                    handleEditStep={handleEditStep}
                                    handleAddTopicPlaceholderClick={handleAddTopicPlaceholderClick}
                                    handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                />
                                                    </div>
                                                )}
                                            </Draggable>
                            )) : (
                                <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                            )}
                                        {provided.placeholder}
                        </List>
                                )}
                            </Droppable>

                        {/* Nur der "Neue Kategorie anlegen"-Button bleibt hier */}
                        <Box
                            sx={{
                                border: '2px dashed #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: '#888',
                                mt: 2,
                                width: '100%',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                                boxSizing: 'border-box',
                            }}
                                onClick={() => setOpenCategoryDialog(true)}
                        >
                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Neue Kategorie anlegen
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Schließen</Button>
                </DialogActions>
            </Dialog>
            </DragDropContext>

            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => {
                    setOpenCategoryDialog(false);
                    setEditingCategory(null);
                    setCategoryName('');
                    setError(null);
                }}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategory}
            />

            <TopicDialog
                open={openTopicDialog}
                onClose={() => {
                    setOpenTopicDialog(false);
                    setEditingTopic(null);
                    setTopicName('');
                    setError(null);
                }}
                topicName={topicName}
                setTopicName={setTopicName}
                handleSaveTopic={handleSaveTopic}
                editingTopic={editingTopic}
            />

            <StepDialog
                open={openStepDialog}
                onClose={() => {
                    setOpenStepDialog(false);
                    setEditingStep(null);
                    setStepName('');
                    setError(null);
                }}
                stepName={stepName}
                setStepName={setStepName}
                handleSaveStep={handleSaveStep}
            />
        </>
    );
};

export default OnboardingPlanKonfiguration;
