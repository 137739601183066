// src/components/card/Datentagebuch/DataCardMonat.jsx

import React from 'react';
import { Card, Typography, Stack, Box, CardActionArea } from '@mui/material';
import Sparkline from '../../charts/Sparkline2'; 
import { useTheme } from '@mui/material/styles';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';

// Neue Icons für Retoure & Warengruppen:
import UndoIcon from '@mui/icons-material/Undo'; // z.B. für Retoure
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'; // z.B. für Warengruppe

/**
 * Liefert je nach category ein Icon zurück.
 * Erweitert für 'retoure' und 'warengruppen'
 */
const getCategoryIcon = (category) => {
  const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };
  switch (category.toLowerCase()) {
    case 'umsatz':
      return <EuroIcon {...iconProps} />;
    case 'kundenanzahl':
      return <ShoppingCartIcon {...iconProps} />;
    case 'kundenbon':
      return <AddShoppingCartIcon {...iconProps} />;
    case 'leistung':
      return <TimerIcon {...iconProps} />;
    case 'retoure':
      return <UndoIcon {...iconProps} />;
    case 'warengruppen':
      return <BakeryDiningIcon {...iconProps} />;
    default:
      return null;
  }
};

export default function DataCardMonat({
  category = '',
  value = '',
  subHeaders = [],
  chartData = [],
  numDataPoints = 7, 
  onClick,
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
        mb: 2,
      }}
    >
      <CardActionArea onClick={onClick} sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Linker Teil (Icon, Überschrift, Hauptwert) */}
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              {getCategoryIcon(category)}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: theme.typography.h5.fontWeight,
                  fontSize: theme.typography.h6.fontSize,
                  color: theme.palette.primary.main,
                }}
              >
                {category}
              </Typography>
            </Stack>
            <Typography
              variant="h5"
              sx={{
                fontWeight: theme.typography.h4.fontWeight,
                fontSize: theme.typography.h4.fontSize,
                color: theme.palette.primary.main,
                mt: theme.spacing(1),
              }}
            >
              {value}
            </Typography>

            {/* SubHeaders */}
            {subHeaders.map((sub, idx) => (
              <Typography
                key={idx}
                variant="body2"
                sx={{
                  mt: idx === 0 ? theme.spacing(1) : theme.spacing(0.5),
                  color: theme.palette.text.secondary,
                }}
              >
                {sub}
              </Typography>
            ))}
          </Box>

          {/* Rechter Teil: Sparkline-Diagramm */}
          <Box sx={{ width: '40%', minWidth: '100px' }}>
            <Sparkline data={chartData} numDataPoints={numDataPoints} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
