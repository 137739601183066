import React from 'react';
import GeneralLayout from '../GeneralLayout';
import Sidebar from '../GeneralSidebar';
import WindowIcon from '@mui/icons-material/Window';
import SpeedIcon from '@mui/icons-material/Speed';
import TimelineIcon from '@mui/icons-material/Timeline';
import Euro from '@mui/icons-material/Euro';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import GroupIcon from '@mui/icons-material/Group';
import Vaccines from '@mui/icons-material/Vaccines';
import GroupRemove from '@mui/icons-material/GroupRemove';
import ManageSearch from '@mui/icons-material/ManageSearch';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import AutoGraph from '@mui/icons-material/AutoGraph';
import HighlightOff from '@mui/icons-material/HighlightOff';
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';

const FilialeMonatLayout = ({children}) => {
  const menuItems = [
    {
      text: 'Übersicht',
      path: '/scorecards/overview/index',
      icon: <WindowIcon/>,
      subModule: "overview",
    },
    {
      text: 'Cockpit',
      path: '/scorecards/overview/cockpit',
      icon: <SpeedIcon/>,
      subModule: "overview",
    },
    {
      text: 'Performance',
      icon: <TimelineIcon/>,
      subModule: "performance",
      subItems: [
        {text: 'Umsatz', path: '/scorecards/performance/umsatz', icon: <Euro/>},
        {text: 'Kunden', path: '/scorecards/performance/kundenanzahl', icon: <ShoppingCart/>},
        {text: 'Kundenbon', path: '/scorecards/performance/kundenbon', icon: <AddShoppingCart/>},
        {text: 'Leistung', path: '/scorecards/performance/leistung', icon: <Timer/>},
      ],
    },
    {
      text: 'Personal',
      icon: <GroupIcon/>,
      subModule: "personal",
      subItems: [
        {text: 'Krank', path: '/scorecards/personal/krankenquote', icon: <Vaccines/>},
        {text: 'Fluktuation', path: '/scorecards/personal/fluktuation', icon: <GroupRemove/>},
        {text: 'Bedarf', path: '/scorecards/personal/bedarf', icon: <ManageSearch/>},
        {text: 'Auslastung', path: '/scorecards/personal/auslastung', icon: <Battery3Bar/>},
      ],
    },
    {
      text: 'Ware',
      icon: <BakeryDiningIcon/>,
      subModule: "ware",
      subItems: [
        {text: 'Wachstum', path: '/scorecards/ware/wachstum', icon: <AutoGraph/>},
        {text: 'Verfügbarkeit', path: '/scorecards/ware/verfügbarkeit', icon: <HighlightOff/>},
        {text: 'Retoure A', path: '/scorecards/ware/retoure-a', icon: <PublishedWithChanges/>},
        {text: 'Retoure B', path: '/scorecards/ware/retoure-bc', icon: <CurrencyExchange/>},
      ],
    },
  ];

  return (
      <GeneralLayout
          SidebarComponent={(props) => (
              <Sidebar {...props} moduleName={"scorecards"} menuItems={menuItems} title="Monats Scorecards" monthFilter={true} filialFilter={true}/>
          )}
      >
        {children}
      </GeneralLayout>
  );
};

export default FilialeMonatLayout;
