import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Slider, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import axiosInstance from '../../../services/axiosInstance';
import {FilterContext} from "../../../provider/FilterProvider";
import BigTableCard from '../../../components/card/BigTableCard';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import LineChartVariableYAxis from '../../../components/charts/LineChartVariableYAxis';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import FilialeWocheLayout from "../../../components/layout/Scorecards/FilialeWocheLayout";


dayjs.extend(weekOfYear);

function formatGermanNumber(value) {
  if (value === null || value === undefined || isNaN(value)) return 'N/A';
  const number = parseFloat(value);
  if (isNaN(number)) return 'N/A';
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return formatter.format(number);
}

function getLast6Weeks(year, week) {
  const last6Weeks = [];
  const current = dayjs().year(year).week(week).startOf('week');
  for (let i = 5; i >= 0; i--) {
    const w = current.subtract(i, 'week');
    const y = w.year();
    const ww = String(w.week()).padStart(2, '0');
    last6Weeks.push(`${y}.${ww}`);
  }
  return last6Weeks;
}

const Wachstum = () => {
  const {filterState} = useContext(FilterContext);
  const [tableData, setTableData] = useState([]); // Tabelle für diese Woche
  const [lineData, setLineData] = useState([]); // Daten für 6-Wochen-Liniendiagramm
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);
  const [artikelData, setArtikelData] = useState([]);

  const [openTableModal, setOpenTableModal] = useState(false); // Modal für 6-Wochen-Tabellen-Daten
  const [openFullscreenChart, setOpenFullscreenChart] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [yAxisMax, setYAxisMax] = useState(null); // Einstellbare Y-Achse nur im Vollbild
  const [lineTableData, setLineTableData] = useState([]);
  const [lineTableColumns, setLineTableColumns] = useState([]);
  const [lineKeys, setLineKeys] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (filterState.filiale && filterState.datumWoche) {
      fetchCurrentWeekData();
      fetchWarengruppenLineData();
    }
  }, [filterState.filiale, filterState.datumWoche]);

  // Diese Funktion ist optional, falls Monatsdaten benötigt werden
  const fetchData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(6, 'month');

      const response = await axiosInstance.get('scorecards_month/ware-filiale-monat/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });
      // Hier könnte man die Monatsdaten verarbeiten, falls notwendig.
    } catch (error) {
      console.error('Fehler beim Abrufen der Monatsdaten:', error);
    }
  };

  // Tabelle für aktuelle Woche
  const fetchCurrentWeekData = async () => {
    try {
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const startDate = dayjs().year(year).week(week).startOf('week');
      const endDate = dayjs().year(year).week(week).endOf('week');

      const response = await axiosInstance.get('scorecard_week/ware-warengruppe-woche', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const rawData = response.data.results || [];

      const formattedData = rawData
          .filter(item => (item.verkaufsmenge || 0) > 1)
          .map(item => ({
            ...item,
            verkaufsmenge: formatGermanNumber(item.verkaufsmenge),
            verkaufsmengeVJ: formatGermanNumber(item.verkaufsmengeVJ),
            verkaufsmenge6W: formatGermanNumber(item.verkaufsmenge6W),
            scoreProduktwachstumJ: formatGermanNumber(item.scoreProduktwachstumJ),
            scoreProduktwachstum6W: formatGermanNumber(item.scoreProduktwachstum6W)
          }));

      setTableData(formattedData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Warengruppen-Daten (aktuelle Woche):', error);
    }
  };

  // Liniendiagrammdaten für 6 Wochen (Top 6 Warengruppen)
  const fetchWarengruppenLineData = async () => {
    try {
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const last6WeeksArray = getLast6Weeks(year, week);

      const promises = last6WeeksArray.map((yw) => {
        const [y, w] = yw.split('.').map(Number);
        const startDate = dayjs().year(y).week(w).startOf('week');
        const endDate = dayjs().year(y).week(w).endOf('week');

        return axiosInstance.get('scorecard_week/ware-warengruppe-woche', {
          params: {
            startdatum: startDate.format('YYYY-MM-DD'),
            enddatum: endDate.format('YYYY-MM-DD'),
            filiale: filterState.filiale?.label
          }
        }).then(res => ({week: yw, data: res.data.results}));
      });

      const results = await Promise.all(promises);

      const aggregated = {};
      results.forEach(({week, data}) => {
        data.forEach(item => {
          const wg = item.warengruppe;
          const vm = item.verkaufsmenge || 0;
          if (!aggregated[wg]) aggregated[wg] = {};
          aggregated[wg][week] = vm;
        });
      });

      let newLineData = last6WeeksArray.map(yw => {
        const entry = {name: yw};
        Object.keys(aggregated).forEach(wg => {
          entry[wg] = aggregated[wg][yw] || 0;
        });
        return entry;
      });

      // Top 6 Warengruppen
      const wgSums = Object.keys(aggregated).map(wg => {
        const sum = last6WeeksArray.reduce((acc, yw) => acc + (aggregated[wg][yw] || 0), 0);
        return {warengruppe: wg, sum};
      });
      const top6 = wgSums.sort((a, b) => b.sum - a.sum).slice(0, 6).map(x => x.warengruppe);

      newLineData = newLineData.map(entry => {
        const filteredEntry = {name: entry.name};
        top6.forEach(wg => {
          filteredEntry[wg] = entry[wg];
        });
        return filteredEntry;
      });

      setLineData(newLineData);
      setLineKeys(top6);

      // Y-Achsen-Max berechnen
      const maxVal = Math.max(...newLineData.flatMap(d => top6.map(k => d[k])));
      const bufferedMax = Math.ceil(maxVal * 1.1);
      setYAxisMax(bufferedMax);

      // Tabelle für die 6-Wochen-Daten im Modal
      const columns = [
        {accessorKey: 'warengruppe', header: 'Warengruppe'},
        ...last6WeeksArray.map(yw => ({accessorKey: yw, header: yw}))
      ];

      const lineTableArray = top6.map(wg => {
        const row = {warengruppe: wg};
        newLineData.forEach(ld => {
          row[ld.name] = formatGermanNumber(ld[wg]);
        });
        return row;
      });

      setLineTableColumns(columns);
      setLineTableData(lineTableArray);

    } catch (error) {
      console.error('Fehler beim Abrufen der Warengruppen-LineData (6 Wochen):', error);
    }
  };

  const warengruppenColumns = [
    {accessorKey: 'warengruppe', header: 'Warengruppe'},
    {accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge'},
    {accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ'},
    {accessorKey: 'verkaufsmenge6W', header: '∅ 6W'},
    {accessorKey: 'scoreProduktwachstumJ', header: 'Score VJ'},
    {accessorKey: 'scoreProduktwachstum6W', header: 'Score 6W'},
  ];

  const handleWarengruppeRowClick = (rowData) => {
    const warengruppe = rowData.warengruppe;
    setSelectedWarengruppe(warengruppe);
    fetchArtikelData(warengruppe);
  };

  const fetchArtikelData = async (warengruppe) => {
    try {
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const startDate = dayjs().year(year).week(week).startOf('week');
      const endDate = dayjs().year(year).week(week).endOf('week');

      const response = await axiosInstance.get('scorecard_week_article/ware-artikel-woche', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label,
          warengruppe: warengruppe
        }
      });

      const rawData = response.data.results || [];
      const formattedArtikelData = rawData.map(item => {
        const artikelName = item.artikelnummer && item.artikelnummer.artikel
            ? item.artikelnummer.artikel
            : 'N/A';

        return {
          ...item,
          artikel: artikelName,
          verkaufsmenge: formatGermanNumber(item.verkaufsmenge),
          verkaufsmengeVJ: formatGermanNumber(item.verkaufsmengeVJ),
        };
      });

      setArtikelData(formattedArtikelData);
      setOpenPopup(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setArtikelData([]);
    setSelectedWarengruppe(null);
  };

  return (
    <FilialeWocheLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageHeader
          title="Warengruppenentwicklung"
          subtitle="Übersicht über die Entwicklung der einzelnen Warengruppen"
          Icon={BarChartIcon}
        />
      </Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          {/* Liniendiagramm über der Tabelle */}
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              <Typography variant="h6">
                Verkaufsmenge der letzten 6 Wochen (pro Warengruppe)
              </Typography>
              </Box>
              <Box>
                <Tooltip title="Informationen zum Diagramm" arrow placement="top">
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Details in Tabellenform anzeigen" arrow placement="top">
                  <IconButton onClick={() => setOpenTableModal(true)}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Diagramm im Vollbildmodus anzeigen" arrow placement="top">
                  <IconButton onClick={() => setOpenFullscreenChart(true)}>
                    <FullscreenIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* KEIN Slider hier oben, nur im Vollbildmodus */}
              <Box sx={{p: 2}}>
                {lineData && lineData.length > 0 && lineKeys.length > 0 ? (
                    <LineChartVariableYAxis
                        data={lineData}
                        dataKeys={lineKeys}
                        xAxisKey="name"
                        yAxisMax={yAxisMax || 'auto'}
                        yAxisMin={0}
                        height={300}
                        width="100%"
                        lineColors={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c']}
                        legendNames={lineKeys}
                    />
                ) : (
                    <Typography variant="body2" color="text.secondary">
                      Keine Daten verfügbar
                    </Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <BigTableCard
                data={tableData}
                columns={warengruppenColumns}
                title="Warengruppenübersicht (letzte Woche)"
                infoContent={
                  <>
                    Diese Tabelle zeigt die Kennzahlen auf Warengruppenebene für die ausgewählte Woche.<br/><br/>
                    <strong>Warengruppe:</strong> Name der Warengruppe<br/>
                    <strong>Verkaufsmenge:</strong> Verkäufe im ausgewählten Zeitraum<br/>
                    <strong>Verkaufsmenge VJ:</strong> Vergleichsverkäufe aus dem Vorjahr<br/>
                    <strong>∅ 6W:</strong> Durchschnittliche Verkaufsmenge der letzten 6 Wochen<br/>
                    <strong>Score VJ / Score 6W:</strong> Bewertungswerte
                  </>
                }
                onRowClick={handleWarengruppeRowClick}
            />
          </Grid>
        </Grid>

        {/* Popup für Artikeldaten */}
        <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth="xl">
          <DialogTitle>
            Artikeldetails für {selectedWarengruppe}
            <IconButton
                aria-label="close"
                onClick={handleClosePopup}
                sx={{position: 'absolute', right: 8, top: 8}}
            >
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <BigTableCard
                data={artikelData}
                columns={[
                  {accessorKey: 'artikel', header: 'Artikel'},
                  {accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge'},
                  {accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ'}
                ]}
                title={`Artikelübersicht - ${selectedWarengruppe}`}
                infoContent={
                  <>
                    Diese Tabelle zeigt die Artikeldaten für die ausgewählte Warengruppe und Woche.<br/><br/>
                    <strong>Artikel:</strong> Artikelname<br/>
                    <strong>Verkaufsmenge:</strong> Verkäufe im ausgewählten Zeitraum<br/>
                    <strong>Verkaufsmenge VJ:</strong> Vergleichsverkäufe aus dem Vorjahr
                  </>
                }
            />
          </DialogContent>
        </Dialog>

        {/* Vollbild-Diagramm */}
        <Dialog
            open={openFullscreenChart}
            onClose={() => setOpenFullscreenChart(false)}
            fullScreen
            aria-labelledby="fullscreen-chart-title"
        >
          <DialogTitle id="fullscreen-chart-title">
            Verkaufsmenge der letzten 6 Wochen (pro Warengruppe)
            <IconButton
                aria-label="schließen"
                onClick={() => setOpenFullscreenChart(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
            >
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {lineData && lineData.length > 0 && lineKeys.length > 0 ? (
                <>
                  <Typography variant="body2" gutterBottom>Y-Achsen Maximum einstellen:</Typography>
                  <Slider
                      value={yAxisMax || 0}
                      min={0}
                      max={yAxisMax || 0}
                      onChange={(e, newVal) => setYAxisMax(newVal)}
                      step={yAxisMax ? Math.round(yAxisMax / 20) : 100}
                  />
                  <LineChartVariableYAxis
                      data={lineData}
                      dataKeys={lineKeys}
                      xAxisKey="name"
                      yAxisMax={yAxisMax || 'auto'}
                      yAxisMin={0}
                      height={windowHeight * 0.8}
                      width="100%"
                      lineColors={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c']}
                      legendNames={lineKeys}
                  />
                </>
            ) : (
                <Typography variant="body2" color="text.secondary">
                  Keine Daten verfügbar
                </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenFullscreenChart(false)} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>

        {/* Tabellen-Dialog für das Diagramm */}
        <Dialog
            open={openTableModal}
            onClose={() => setOpenTableModal(false)}
            fullWidth
            maxWidth="lg"
        >
          <DialogTitle>
            Verkaufsdaten in Tabellenform (6 Wochen)
            <IconButton
                aria-label="close"
                onClick={() => setOpenTableModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
            >
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {lineTableData && lineTableData.length > 0 && lineTableColumns.length > 0 ? (
                <BigTableCard
                    title="Verkaufsdaten (letzte 6 Wochen)"
                    data={lineTableData}
                    columns={lineTableColumns}
                />
            ) : (
                <Typography variant="body2">Keine zusätzlichen Tabellen-Daten für 6 Wochen im Moment.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </FilialeWocheLayout>
  );
};

export default Wachstum;
