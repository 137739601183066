import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Button,
    Alert,
    Divider,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    IconButton,
    TextField
} from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import { FilterContext } from '../../provider/FilterProvider';
import { useAuth } from '../../provider/authProvider';  // <-- Hinzugefügt: useAuth importieren
import AddCommentIcon from '@mui/icons-material/AddComment';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DeleteIcon from '@mui/icons-material/Delete';

// SingleFilialeSelect importieren (Pfad ggf. anpassen)
import SingleFilialeSelect from '../filter/SingleFilialeSelect';

const CategoryCheckpointsForm = ({ checkId, onSaveSuccess }) => {
    // FilterContext für Filiale
    const { filterState, handleFilialeChange } = useContext(FilterContext);

    // useAuth für aktuellen User
    const { user } = useAuth();  // <-- Hier bekommst du den aktuellen User!

    // Lokale States
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [comments, setComments] = useState({});
    const [images, setImages] = useState({});
    const [imagePreviews, setImagePreviews] = useState({});

    // Lade- und Fehlermanagement
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    // Dialog für Filialauswahl
    const [filialeDialogOpen, setFilialeDialogOpen] = useState(true);

    // Check-Infos
    const [maxRating, setMaxRating] = useState(10);
    const [checkName, setCheckName] = useState('');

    // Kommentar-Dialog
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [currentCheckpoint, setCurrentCheckpoint] = useState(null);
    const [currentComment, setCurrentComment] = useState('');

    // Bildanzeige-Dialog
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    // Refs für Bild-Dateiupload pro Checkpoint
    const fileInputRefMap = useRef({});

    // Aktuelles Datum im Format DD.MM.YYYY
    const formatDate = (date) => {
        const d = new Date(date);
        return `${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(2, '0')}.${d.getFullYear()}`;
    };
    const currentDate = formatDate(new Date());

    /**
     * 1) Kategorien & Checkpoints laden
     */
    useEffect(() => {
        const fetchCategoriesAndCheckpoints = async () => {
            try {
                // Hole Infos zum Check (z.B. maxRating, Name)
                const checkResponse = await axiosInstance.get(`/filialcheck/checks/${checkId}`);
                setMaxRating(checkResponse.data.max);
                setCheckName(checkResponse.data.name);

                // Kategorien holen, die zu diesem Check gehören
                const categoryResponse = await axiosInstance.get('/filialcheck/categories/');
                const filteredCategories = categoryResponse.data.results
                    .filter((cat) => cat.check_related === checkId);

                // Checkpoints laden
                const checkpointResponse = await axiosInstance.get('/filialcheck/checkpoints/');
                const categorizedCheckpoints = filteredCategories.map((category) => ({
                    ...category,
                    checkpoints: checkpointResponse.data.results.filter(
                        (cp) => cp.category === category.id
                    ),
                }));

                setCategories(categorizedCheckpoints);
            } catch (err) {
                setError('Fehler beim Laden der Daten.');
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndCheckpoints();
    }, [checkId]);

    /**
     * 2) Kommentar-Dialog
     */
    const openCommentDialog = (checkpointId) => {
        setCurrentCheckpoint(checkpointId);
        setCurrentComment(comments[checkpointId] || '');
        setCommentDialogOpen(true);
    };

    const handleCommentSave = () => {
        setComments((prev) => ({
            ...prev,
            [currentCheckpoint]: currentComment,
        }));
        setCommentDialogOpen(false);
    };

    /**
     * 3) Ratings (Yes/No vs. Scale)
     */
    const handleRatingChange = (checkpointId, value) => {
        setRatings((prev) => ({
            ...prev,
            [checkpointId]: value,
        }));
    };

    /**
     * 4) Bilder-Upload / Löschen / Anzeigen
     */
    const handleImageUploadClick = (checkpointId) => {
        if (fileInputRefMap.current[checkpointId]) {
            fileInputRefMap.current[checkpointId].click();
        }
    };

    const handleFileChange = async (event, checkpointId) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('Keine Datei ausgewählt');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('title', `Checkpoint ${checkpointId} Bild`);

        try {
            const response = await axiosInstance.post('/pictures/upload/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const image_id = response.data.image_id;

            // Bild-ID speichern
            setImages((prev) => ({
                ...prev,
                [checkpointId]: image_id,
            }));

            // Vorschau
            const imageUrl = URL.createObjectURL(file);
            setImagePreviews((prev) => ({
                ...prev,
                [checkpointId]: imageUrl,
            }));
        } catch (err) {
            console.error('Upload fehlgeschlagen:', err);
        }
    };

    const handleImageDelete = (checkpointId) => {
        setImages((prev) => {
            const newImages = { ...prev };
            delete newImages[checkpointId];
            return newImages;
        });
        setImagePreviews((prev) => {
            const newPreviews = { ...prev };
            delete newPreviews[checkpointId];
            return newPreviews;
        });
    };

    const handleImageClick = async (checkpointId) => {
        const imageId = images[checkpointId];
        if (!imageId) {
            console.error('Keine Bild-ID verfügbar');
            return;
        }

        try {
            const response = await axiosInstance.get(`/pictures/get-image-url/${imageId}/`);
            setImageUrl(response.data.url);
            setImageDialogOpen(true);
        } catch (error) {
            console.error('Fehler beim Abrufen der Bild-URL:', error);
        }
    };

    /**
     * 5) Formular absenden
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Mindestens ein Checkpoint muss bewertet sein
        const ratingEntries = Object.entries(ratings);
        if (ratingEntries.length === 0) {
            setError('Bitte bewerten Sie mindestens einen Checkpoint.');
            return;
        }

        try {
            const payload = ratingEntries.map(([checkpointId, value]) => {
                // Finde den Checkpoint im categories-Array
                const checkpoint = categories
                    .flatMap((cat) => cat.checkpoints)
                    .find((cp) => cp.id === parseInt(checkpointId));

                // Yes/No => 1/0
                const transformedValue =
                    checkpoint.rating_type === 'yesNo'
                        ? value === 'yes'
                            ? 1
                            : 0
                        : value;

                return {
                    value: transformedValue,
                    // Datum als YYYY-MM-DD
                    datum: currentDate.split('.').reverse().join('-'),
                    // --- Hier ersetzen wir "databites" durch den aktuellen User ---
                    user: user?.username || 'unbekannt', 
                    // Nur Filialnummer an die API
                    filialnummer: parseInt(filterState.filiale?.filialnummer),
                    checkpoint: checkpointId,
                    check_related: checkId,
                    comment: comments[checkpointId] || '',
                    image_id: images[checkpointId] || null,
                };
            });

            await axiosInstance.post('/filialcheck/ratings/', payload);

            // Reset
            setRatings({});
            setComments({});
            setImages({});
            setImagePreviews({});
            setSubmitSuccess(true);
            setError(null);

            if (onSaveSuccess) onSaveSuccess();
        } catch (err) {
            setError('Fehler beim Speichern der Bewertungen.');
        }
    };

    /**
     * 6) Dialog schließen, wenn Filiale vorhanden
     */
    const handleDialogClose = () => {
        if (filterState.filiale) {
            setFilialeDialogOpen(false);
        } else {
            setError('Bitte wählen Sie eine Filiale aus, bevor Sie fortfahren.');
        }
    };

    // Loading-Handling
    if (loading) {
        return <CircularProgress />;
    }
    // Fehler-Handling
    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    return (
        <>
            {/* Dialog für Filialauswahl */}
            <Dialog open={filialeDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Filiale auswählen</DialogTitle>
                <DialogContent>
                    <SingleFilialeSelect
                        onChange={(newValue) => {
                            // Ganzes Objekt im FilterContext speichern
                            handleFilialeChange(newValue);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="contained"
                        color="primary"
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Kommentar-Dialog */}
            <Dialog open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)}>
                <DialogTitle>Kommentar hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Kommentar"
                        fullWidth
                        multiline
                        rows={4}
                        value={currentComment}
                        onChange={(e) => setCurrentComment(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setCommentDialogOpen(false)}
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleCommentSave}
                        color="primary"
                        variant="contained"
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Bildanzeige-Dialog */}
            <Dialog
                open={imageDialogOpen}
                onClose={() => setImageDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <img
                        src={imageUrl}
                        alt="Checkpoint Bild"
                        style={{ width: '100%' }}
                    />
                </DialogContent>
            </Dialog>

            {/* Hauptformular */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    maxWidth: { xs: '100%', sm: 800 },
                    mx: 'auto',
                    mt: 4,
                    p: { xs: 2, sm: 4 },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}
                >
                    {checkName}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        mb: 4,
                    }}
                >
                    {/* Filialname anzeigen (sofern vorhanden) */}
                    <Typography variant="body1">
                        <strong>Filiale:</strong>{" "}
                        {filterState.filiale?.filiale || "Keine Filiale ausgewählt"}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Datum:</strong> {currentDate}
                    </Typography>
                </Box>

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                >
                    Checkpoints bewerten
                </Typography>

                {submitSuccess && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Bewertungen erfolgreich gespeichert!
                    </Alert>
                )}

                {/* Kategorien + Checkpoints */}
                {categories.length > 0 ? (
                    <List>
                        {categories.map((category) => (
                            <Paper
                                key={category.id}
                                sx={{ mb: 4, p: 2, border: '1px solid #ccc', borderRadius: 2 }}
                            >
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                                >
                                    {category.name}
                                </Typography>
                                <List>
                                    {category.checkpoints.map((checkpoint, index) => (
                                        <React.Fragment key={checkpoint.id}>
                                            <ListItem
                                                sx={{
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                {/* Titel & Icons */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={checkpoint.name}
                                                        sx={{ flexGrow: 1 }}
                                                    />
                                                    <IconButton
                                                        onClick={() => openCommentDialog(checkpoint.id)}
                                                        color="primary"
                                                    >
                                                        <AddCommentIcon />
                                                    </IconButton>

                                                    {checkpoint.allow_image_upload && (
                                                        <>
                                                            <input
                                                                accept="image/*"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={(el) =>
                                                                    (fileInputRefMap.current[checkpoint.id] = el)
                                                                }
                                                                onChange={(event) =>
                                                                    handleFileChange(event, checkpoint.id)
                                                                }
                                                            />
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleImageUploadClick(checkpoint.id)
                                                                }
                                                                color="secondary"
                                                            >
                                                                <PhotoCamera />
                                                            </IconButton>

                                                            {images[checkpoint.id] && (
                                                                <>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() => handleImageClick(checkpoint.id)}
                                                                    >
                                                                        <PhotoLibraryIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            handleImageDelete(checkpoint.id)
                                                                        }
                                                                        color="error"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Box>

                                                {/* Bewertung (Yes/No vs. Scale) */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: { xs: 'column', sm: 'row' },
                                                        alignItems: 'center',
                                                        mt: 1,
                                                        width: '100%',
                                                    }}
                                                >
                                                    {checkpoint.rating_type === 'yesNo' ? (
                                                        <ButtonGroup
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{
                                                                mt: { xs: 1, sm: 0 },
                                                                mr: { sm: 2 },
                                                            }}
                                                        >
                                                            <Button
                                                                variant={
                                                                    ratings[checkpoint.id] === "yes"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    handleRatingChange(checkpoint.id, "yes")
                                                                }
                                                            >
                                                                Ja
                                                            </Button>
                                                            <Button
                                                                variant={
                                                                    ratings[checkpoint.id] === "no"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    handleRatingChange(checkpoint.id, "no")
                                                                }
                                                            >
                                                                Nein
                                                            </Button>
                                                        </ButtonGroup>
                                                    ) : (
                                                        <ButtonGroup
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{
                                                                mt: { xs: 1, sm: 0 },
                                                                mr: { sm: 2 },
                                                                flexWrap: 'wrap',
                                                            }}
                                                        >
                                                            {[...Array(maxRating).keys()].map((num) => (
                                                                <Button
                                                                    key={num + 1}
                                                                    variant={
                                                                        ratings[checkpoint.id] === num + 1
                                                                            ? "contained"
                                                                            : "outlined"
                                                                    }
                                                                    onClick={() =>
                                                                        handleRatingChange(checkpoint.id, num + 1)
                                                                    }
                                                                    sx={{
                                                                        minWidth: { xs: 30, sm: 40 },
                                                                        mb: { xs: 1, sm: 0 },
                                                                    }}
                                                                >
                                                                    {num + 1}
                                                                </Button>
                                                            ))}
                                                        </ButtonGroup>
                                                    )}
                                                </Box>

                                                {/* Kommentar anzeigen */}
                                                {comments[checkpoint.id] && (
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        sx={{ mt: 1 }}
                                                    >
                                                        <strong>Kommentar:</strong>{" "}
                                                        {comments[checkpoint.id]}
                                                    </Typography>
                                                )}

                                                {/* Bildvorschau anzeigen */}
                                                {imagePreviews[checkpoint.id] && (
                                                    <Box sx={{ mt: 1 }}>
                                                        <img
                                                            src={imagePreviews[checkpoint.id]}
                                                            alt={`Checkpoint ${checkpoint.id}`}
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '200px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                {index < category.checkpoints.length - 1 && (
                                                    <Divider sx={{ mt: 2 }} />
                                                )}
                                            </ListItem>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Paper>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">
                        Keine Kategorien und Checkpoints gefunden.
                    </Typography>
                )}

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, width: '100%' }}
                >
                    Speichern
                </Button>
            </Box>
        </>
    );
};

export default CategoryCheckpointsForm;
