// components/popup/unternehmen/ArticleDetailsPopupRetoure.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { Close as CloseIcon } from '@mui/icons-material';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import BigTableCard from '../../card/BigTableCard';

// FilterContext importieren
import { FilterContext } from '../../../provider/FilterProvider';

export default function ArticleDetailsPopupRetoure({
  open,
  onClose,
  warengruppe,
  jahrMonat,
}) {
  // Context auslesen
  const { filterState } = useContext(FilterContext);

  const [articleData, setArticleData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (open) {
      fetchTableData();
      fetchChartData();
    }
  }, [open, warengruppe, jahrMonat]);

  const fetchTableData = async () => {
    try {
      const previousYear =
        (parseInt(jahrMonat.split('.')[0]) - 1).toString() +
        '.' +
        jahrMonat.split('.')[1];

      // 1) API-Call für das aktuelle Jahr
      const currentYearData = await axiosInstance.get(
        '/scorecard_month_article/ware-artikel-monat/',
        {
          params: {
            warengruppe,
            jahrMonat,
            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
          },
        }
      );

      // 2) API-Call für das Vorjahr (ggf. für Vergleich)
      const previousYearData = await axiosInstance.get(
        '/scorecard_month_article/ware-artikel-monat/',
        {
          params: {
            warengruppe,
            jahrMonat: previousYear,
            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
          },
        }
      );

      const articleMap = {};

      // --- Aktuelles Jahr ---
      currentYearData.data.results.forEach((item) => {
        const {
          artikelnummer,
          // Mengen
          retourmenge = 0,
          liefermenge = 0,
          korrekturmenge = 0,
          verkaufsmenge = 0,

          // Werte
          lieferwert = 0,
          retourwert = 0,
          korrekturwert = 0,
        } = item;

        const artikel = artikelnummer.artikel;
        if (!articleMap[artikel]) {
          articleMap[artikel] = {
            // Mengen
            retourmenge: 0,
            liefermenge: 0,
            korrekturmenge: 0,
            verkaufsmenge: 0,

            // Werte
            lieferwert: 0,
            retourwert: 0,
            korrekturwert: 0,
          };
        }

        // Aufsummieren
        articleMap[artikel].retourmenge += retourmenge;
        articleMap[artikel].liefermenge += liefermenge;
        articleMap[artikel].korrekturmenge += korrekturmenge;
        articleMap[artikel].verkaufsmenge += verkaufsmenge;

        articleMap[artikel].lieferwert += lieferwert;
        articleMap[artikel].retourwert += retourwert;
        articleMap[artikel].korrekturwert += korrekturwert;
      });

      // --- Vorjahr (falls du etwas damit tun möchtest) ---
      previousYearData.data.results.forEach((item) => {
        const {
          artikelnummer,
          retourmenge = 0,
          liefermenge = 0,
          korrekturmenge = 0,
          verkaufsmenge = 0,

          lieferwert = 0,
          retourwert = 0,
          korrekturwert = 0,
        } = item;
        const artikel = artikelnummer.artikel;

        if (!articleMap[artikel]) {
          articleMap[artikel] = {
            // Mengen
            retourmenge: 0,
            liefermenge: 0,
            korrekturmenge: 0,
            verkaufsmenge: 0,

            // Werte
            lieferwert: 0,
            retourwert: 0,
            korrekturwert: 0,
          };
        }
        // Wenn du Vorjahreswerte separat speichern willst, könntest du hier z.B.
        // articleMap[artikel].retourwertVJ = ...
        // oder ähnliches machen.
      });

      // Tabelle formatieren
      const formattedTableData = Object.entries(articleMap).map(
        ([artikel, data]) => {
          // Werte für die Retourenquote
          const totalBasisWert = data.lieferwert + data.korrekturwert;
          const retourQuote =
            totalBasisWert > 0
              ? ((data.retourwert / totalBasisWert) * 100).toFixed(2) + '%'
              : '0.00%';

          return {
            artikel,
            // Mengenwerte
            liefermenge: data.liefermenge.toLocaleString('de-DE'),
            retourmenge: data.retourmenge.toLocaleString('de-DE'),
            korrekturmenge: data.korrekturmenge.toLocaleString('de-DE'),
            verkaufsmenge: data.verkaufsmenge.toLocaleString('de-DE'),

            // Die Wert-Felder NICHT ausgeben, nur intern genutzt
            retourQuote, // spalte mit wertbasierter Retourenquote
          };
        }
      );

      setArticleData(formattedTableData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
    }
  };

  const fetchChartData = async () => {
    try {
      // Nur aktueller Monat
      const currentMonth = jahrMonat;

      const response = await axiosInstance.get(
        '/scorecard_month_article/ware-artikel-monat/',
        {
          params: {
            warengruppe,
            jahrMonat: currentMonth,
            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
          },
        }
      );

      const data = response.data.results;
      const articleMap = {};

      data.forEach((item) => {
        const {
          artikelnummer,
          // Werte
          lieferwert = 0,
          retourwert = 0,
          korrekturwert = 0,
          // Mengen
          verkaufsmenge = 0,
          retourmenge = 0,
          liefermenge = 0,
          korrekturmenge = 0,
        } = item;

        const artikel = artikelnummer.artikel;
        if (!articleMap[artikel]) {
          articleMap[artikel] = {
            lieferwert: 0,
            retourwert: 0,
            korrekturwert: 0,

            verkaufsmenge: 0,
            retourmenge: 0,
            liefermenge: 0,
            korrekturmenge: 0,
          };
        }

        articleMap[artikel].lieferwert += lieferwert;
        articleMap[artikel].retourwert += retourwert;
        articleMap[artikel].korrekturwert += korrekturwert;

        articleMap[artikel].verkaufsmenge += verkaufsmenge;
        articleMap[artikel].retourmenge += retourmenge;
        articleMap[artikel].liefermenge += liefermenge;
        articleMap[artikel].korrekturmenge += korrekturmenge;
      });

      // Retourquote auf Wertbasis
      const filteredArticles = Object.entries(articleMap).map(
        ([artikel, data]) => {
          const totalBasisWert = data.lieferwert + data.korrekturwert;
          const retourQuote =
            totalBasisWert > 0 ? (data.retourwert / totalBasisWert) * 100 : 0;

          return {
            artikel,
            verkaufsmenge: data.verkaufsmenge,
            retourQuote: parseFloat(retourQuote.toFixed(2)),
          };
        }
      );

      // Nur Artikel mit Retourquote > 0 im Chart (je nach Wunsch)
      const chartFiltered = filteredArticles.filter(
        (item) => item.retourQuote > 0
      );

      setChartData(chartFiltered);
    } catch (error) {
      console.error('Fehler beim Abrufen der Retouren-Chartdaten:', error);
    }
  };

  // Nur die Spalten, die du wirklich anzeigen möchtest (keine *wert-Felder)
  const columns = [
    { accessorKey: 'artikel', header: 'Artikel' },
    { accessorKey: 'liefermenge', header: 'Liefermenge', align: 'right' },
    { accessorKey: 'retourmenge', header: 'Retourmenge', align: 'right' },
    { accessorKey: 'korrekturmenge', header: 'Korrekturmenge', align: 'right' },
    { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge', align: 'right' },
    {
      accessorKey: 'retourQuote',
      header: 'Retourenquote % (Wert)',
      align: 'right',
    },
  ];

  // Farben für die Scatter-Punkte
  const scatterColors = [
    '#8884d8',
    '#82ca9d',
    '#ffc658',
    '#ff7300',
    '#d0ed57',
    '#a4de6c',
    '#a4de6c',
    '#8dd1e1',
    '#83a6ed',
    '#8dd1e1',
    '#ff8042',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
  ];

  // Custom Tooltip fürs Scatter-Chart
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <Typography variant="subtitle2">{data.artikel}</Typography>
          <Typography variant="body2">
            Verkaufsmenge: {data.verkaufsmenge.toLocaleString('de-DE')}
          </Typography>
          <Typography variant="body2">
            Retourenquote (Wert): {data.retourQuote}%
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Artikeldetails – {warengruppe} – {jahrMonat}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Scatter Chart */}
        <Box sx={{ width: '100%', height: 400, mb: 3 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid />
              <XAxis
                dataKey="verkaufsmenge"
                name="Verkaufsmenge"
                type="number"
                tick={{ fontSize: 12 }}
                label={{
                  value: 'Verkaufsmenge',
                  position: 'insideBottom',
                  offset: -10,
                }}
              />
              <YAxis
                dataKey="retourQuote"
                name="Retourenquote (Wert) %"
                label={{
                  value: 'Retourenquote (Wert) (%)',
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              {chartData.length > 0 &&
                chartData.map((artikel, index) => (
                  <Scatter
                    key={artikel.artikel}
                    name={artikel.artikel}
                    data={[artikel]}
                    fill={scatterColors[index % scatterColors.length]}
                  />
                ))}
            </ScatterChart>
          </ResponsiveContainer>
        </Box>

        {/* Data Table */}
        <BigTableCard
          title="Artikeldetails"
          data={articleData}
          columns={columns}
          onRowClick={(row) => console.log('Row clicked:', row)}
          muiTableBodyCellProps={{ align: 'right' }}
        />
      </DialogContent>
    </Dialog>
  );
}
