import React from 'react';
import GeneralLayout from '../GeneralLayout';
import Sidebar from '../GeneralSidebar';
import WindowIcon from '@mui/icons-material/Window';
import TimelineIcon from '@mui/icons-material/Timeline';
import Euro from '@mui/icons-material/Euro';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import GroupIcon from '@mui/icons-material/Group';
import Vaccines from '@mui/icons-material/Vaccines';
import GroupRemove from '@mui/icons-material/GroupRemove';
import ManageSearch from '@mui/icons-material/ManageSearch';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import AutoGraph from '@mui/icons-material/AutoGraph';

const UnternehmenMonatLayout = ({ children }) => {
  const menuItems = [
    {
      text: 'Übersicht',
      path: '/unternehmen/overview/cockpit',
      icon: <WindowIcon sx={{ color: 'primary.main' }} />,
      subModule: "overview",
    },
    {
      text: 'Performance',
      icon: <TimelineIcon sx={{ color: 'primary.main' }} />,
      subModule: "performance",
      subItems: [
        { text: 'Umsatz', path: '/unternehmen/performance/umsatz', icon: <Euro sx={{ color: 'primary.main' }} /> },
        { text: 'Kunden', path: '/unternehmen/performance/kundenanzahl', icon: <ShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Kundenbon', path: '/unternehmen/performance/kundenbon', icon: <AddShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Leistung', path: '/unternehmen/performance/leistung', icon: <Timer sx={{ color: 'primary.main' }} /> },
      ],
    },
    {
      text: 'Personal',
      icon: <GroupIcon sx={{ color: 'primary.main' }} />,
      subModule: "personal",
      subItems: [
        { text: 'Krank', path: '/unternehmen/personal/krankenquote', icon: <Vaccines sx={{ color: 'primary.main' }} /> },
        { text: 'Fluktuation', path: '/unternehmen/personal/fluktuation', icon: <GroupRemove sx={{ color: 'primary.main' }} /> },
        { text: 'Bedarf', path: '/unternehmen/personal/bedarf', icon: <ManageSearch sx={{ color: 'primary.main' }} /> },
        { text: 'Auslastung', path: '/unternehmen/personal/auslastung', icon: <Battery3Bar sx={{ color: 'primary.main' }} /> },
      ],
    },
    {
      text: 'Ware',
      icon: <BakeryDiningIcon sx={{ color: 'primary.main' }} />,
      subModule: "ware",
      subItems: [
        { text: 'Verkaufsmenge', path: '/unternehmen/ware/verkaufsmenge', icon: <WindowIcon sx={{ color: 'primary.main' }} /> },
        { text: 'Retoure', path: '/unternehmen/ware/retoure', icon: <AutoGraph sx={{ color: 'primary.main' }} /> },
      ],
    },
  ];

  return (
      
      <GeneralLayout
          SidebarComponent={(props) => (
              <Sidebar
                  {...props}
                  moduleName={"unternehmen"}
                  menuItems={menuItems}
                  title="Scorecard Unternehmen"
                  monthFilter={true}
                  bestehendeFlaecheSwitch={true}
              />
          )}
      >
        {children}
      </GeneralLayout>
  );
};

export default UnternehmenMonatLayout;





