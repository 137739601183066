import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCard';
import { FilterContext } from "../../../provider/FilterProvider";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ScorecardsNotes from '../../../components/formular/Scorecards/ScorecardsNotes';
import BigTableCardPersonal from '../../../components/card/BigTableCardPersonal';
import EmployeeDetailsPopup from "../../../components/popup/EmployeeDetailsPopup";
import { Alert } from "@mui/material";
import { getAlertSeverity } from "../../../components/utility/AlertPopup";
import TAPersonalAuslastung from '../../../components/scorecards/tiefenanalyse/TAPersonalAuslastung';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import FilialeWocheLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";


const activeCategory = 'Auslastung';

const Auslastung = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null); // Zustand für ausgewählten Mitarbeiter
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Zustand für Popup
    const [isDialogOpen, setIsDialogOpen] = useState(false);  // Zustand für den Dialog
    const { filterState } = useContext(FilterContext);

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Krankenquote: item.scoreKrankenquote,
                    Fluktuation: item.scoreFluktuation,
                    Bedarf: item.scoreKöpfeStunden,
                    Auslastung: item.scoreÜberstunden,
                }));

            const comparisonCardData = response.data.results.slice(-1);

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const responseStammdaten = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
                params: {
                    filiale: filterState.filiale?.label
                }
            });
    
            const filteredMonth = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
    
            const responseAnwesenheiten = await axiosInstance.get('personaldaten/anwesenheiten/', {
                params: {
                    filialeStamm: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value
                }
            });
    
            const responseAbwesenheiten = await axiosInstance.get('personaldaten/abwesenheiten/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value
                }
            });
    
            // Aggregiere die Arbeitszeit und Abwesenheiten auf Mitarbeiter-Ebene
            const anwesenheitenAggregiert = responseAnwesenheiten.data.results.reduce((acc, item) => {
                if (!acc[item.mitarbeiterId]) {
                    acc[item.mitarbeiterId] = 0;
                }
                acc[item.mitarbeiterId] += item.anwesenheitsstunden;
                return acc;
            }, {});
    
            const abwesenheitenAggregiert = responseAbwesenheiten.data.results.reduce((acc, item) => {
                if (!acc[item.mitarbeiterId]) {
                    acc[item.mitarbeiterId] = 0;
                }
                acc[item.mitarbeiterId] += item.abwesenheitsstunden;
                return acc;
            }, {});
    
            const dataTableData = responseStammdaten.data.results
                .filter(item => {
                    const austrittDatum = item.austritt ? dayjs(item.austritt, 'YYYY-MM-DD') : null;
                    const eintrittDatum = item.eintritt ? dayjs(item.eintritt, 'YYYY-MM-DD') : null;
                    // Filterlogik: Mitarbeiter, die vor oder am gefilterten Monat eingetreten sind und nach dem gefilterten Monat ausgetreten sind (oder noch aktiv sind)
                    return (!austrittDatum || austrittDatum.isAfter(filteredMonth)) &&
                        eintrittDatum && eintrittDatum.isSameOrBefore(filteredMonth);
                })
                .map(item => {
                    const wochensoll = Math.round((item.wochensollBerechnet || 0) * 4.35); // Multipliziere und runde
                    const arbeitszeit = Math.round(anwesenheitenAggregiert[item.mitarbeiterId] || 0); // Rundung der Arbeitszeit
                    const abwesenheiten = Math.round(abwesenheitenAggregiert[item.mitarbeiterId] || 0);
                    const differenz = Math.round(Math.abs(wochensoll - (arbeitszeit + abwesenheiten))); // Rundung der Differenz
    
                    return {
                        Name: item.name,
                        mitarbeiterId: item.mitarbeiterId, // Mitarbeiter-ID hinzufügen
                        Arbeitnehmertyp: item.arbeitnehmertyp,
                        Wochensoll: wochensoll,
                        Arbeitszeit: arbeitszeit,
                        Abwesenheiten: abwesenheiten,
                        Differenz: differenz, // Runde die Differenz auf ganze Zahlen
                    };
                });
    
            // Berechne die Gesamtsummen für die Fußzeile
            const totalWochensoll = dataTableData.reduce((sum, row) => sum + row.Wochensoll, 0);
            const totalArbeitszeit = dataTableData.reduce((sum, row) => sum + row.Arbeitszeit, 0);
            const totalAbwesenheiten = dataTableData.reduce((sum, row) => sum + row.Abwesenheiten, 0);
            const totalDifferenz = dataTableData.reduce((sum, row) => sum + row.Differenz, 0);
    
            const newColumns = [
                { id: 'name', accessorKey: 'Name', header: 'Name' },
                { id: 'arbeitnehmertyp', accessorKey: 'Arbeitnehmertyp', header: 'Arbeitnehmertyp' },
                { id: 'wochensoll', accessorKey: 'Wochensoll', header: 'Monatssoll h', footer: totalWochensoll }, // Footer für Wochensoll
                { id: 'arbeitszeit', accessorKey: 'Arbeitszeit', header: 'Arbeitszeit h', footer: totalArbeitszeit }, // Footer für Arbeitszeit
                { id: 'abwesenheiten', accessorKey: 'Abwesenheiten', header: 'Abwesenheiten h', footer: totalAbwesenheiten }, // Footer für Abwesenheiten
                { id: 'differenz', accessorKey: 'Differenz', header: 'Differenz h', footer: totalDifferenz }, // Footer für Differenz
            ];
    
            setTableColumns(newColumns);
            setTableData(dataTableData);
    
        } catch (error) {
            console.error('An error occurred while fetching table data:', error);
        }
    };
    

    const handleRowClick = async (employee) => {
        console.log("Selected Employee Data:", employee);  // Füge dies hinzu, um die Daten zu überprüfen
    
        if (!employee.mitarbeiterId) {
            console.error("MitarbeiterId is undefined. Employee data:", employee);
            return;
        }
    
        setSelectedEmployee(employee);
        setIsPopupOpen(true);  // Öffne das Popup, nachdem der Mitarbeiter ausgewählt wurde
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedEmployee(null);
    };

    return (
        <FilialeWocheLayout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
                        {['Krankenquote', 'Fluktuation', 'Bedarf', 'Auslastung'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="personal"
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                    <Grid container spacing={2}>

                            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} order={{ xs: 2, md: 1 }}>

                                <ComparisonCard
                                    zeitraum="Unternehmen"
                                    data={comparisonCardData}
                                    rangField="rangÜberstundenAbs"
                                    extraField1="überstundenAbs"
                                    extraLabel1="Auslastung"
                                    extraIcon1={<Battery3Bar sx={{ color: 'action.active' }} />}
                                    scoreField="scoreÜberstunden"
                                    TiefenanalyseComponent={TAPersonalAuslastung}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>

                            <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreÜberstunden"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackÜberstunden"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                            </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>

                            <BigTableCardPersonal
                                title={"Auslastung"}
                                data={tableData}
                                columns={tableColumns}
                                onRowClick={handleRowClick} // OnClick-Event hinzufügen
                            />
                                                    </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <ScorecardsNotes category="Personal" />


            <EmployeeDetailsPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                employee={selectedEmployee} // Nur den ausgewählten Mitarbeiter übergeben
                jahrMonat={filterState.datum?.value}
            />
        </FilialeWocheLayout>
    );
};

export default Auslastung;
