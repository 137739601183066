import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WindowIcon from '@mui/icons-material/Window';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';





const FilialcheckLayout = ({children}) => {
  const menuItems = [
    {text: 'Checks', path: '/filialcheck/overview/index', icon: <WindowIcon/>, subModule: "overview"},
    {text: 'Analyse', path: '/filialcheck/analyse/index', icon: <InsertChartOutlinedTwoToneIcon/>, subModule: "analyse"},
    {text: 'Analyse Managemet', path: '/filialcheck/analysemanagement/index', icon: <BusinessCenterOutlinedIcon/>, subModule: "analysemanagement"},
    {text: 'Konfiguration', path: '/filialcheck/settings/index', icon: <BuildOutlinedIcon/>, subModule: "settings"},
    

  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"filialcheck"} menuItems={menuItems} title={'Filialcheck'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default FilialcheckLayout;
