import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Paper, ListItemText, CardHeader, ListItemIcon, CircularProgress, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardContent, Switch, TextField } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QualitaetHinzufuegen from './QualitaetHinzufuegen';
import QualitaetKonfiguration from './QualitaetKofiguration';

const QualitaetChecksListe = ({ refresh }) => {
    const [qualitaetChecks, setQualitaetChecks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);  
    const [editDialogOpen, setEditDialogOpen] = useState(false);  
    const [selectedCheck, setSelectedCheck] = useState(null);  
    const [editingCheckId, setEditingCheckId] = useState(null);
    const [editedName, setEditedName] = useState('');

    const fetchQualitaetChecks = async () => {
        try {
            const response = await axiosInstance.get('/qualitaet/checks/');
            if (Array.isArray(response.data)) {
                setQualitaetChecks(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setQualitaetChecks(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Qualitätschecks:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQualitaetChecks();
    }, [refresh]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        fetchQualitaetChecks(); // Aktualisiere die Liste nach dem Schließen des Dialogs
    };

    const handleEditDialogOpen = (check) => {
        setSelectedCheck(check);  
        setEditDialogOpen(true);  
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        fetchQualitaetChecks(); // Aktualisiere die Liste nach dem Schließen des Bearbeitungsdialogs
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Möchtest du diesen Qualitätscheck und alle zugehörigen Einträge wirklich löschen?");
        if (confirmed) {
            try {
                await axiosInstance.delete(`/qualitaet/checks/${id}/`);  // DELETE-Request an das Backend
                
                // Filtere den gelöschten Check aus der Liste heraus, ohne die Seite neu zu laden
                setQualitaetChecks(qualitaetChecks.filter(check => check.id !== id));
            } catch (error) {
                console.error('Fehler beim Löschen des Qualitätschecks:', error);
            }
        }
    };

    const handleToggleActive = async (check, event) => {
        event.stopPropagation(); // Verhindert das Öffnen des Bearbeitungsdialogs
        try {
            await axiosInstance.patch(`/qualitaet/checks/${check.id}/`, { is_active: !check.is_active });  // Aktiv/Passiv Toggle
            // Aktualisiere den Status des Checks in der Liste
            setQualitaetChecks(qualitaetChecks.map(c => c.id === check.id ? { ...c, is_active: !check.is_active } : c));
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    const handleNameEdit = (check) => {
        setEditingCheckId(check.id);
        setEditedName(check.name);
    };

    const handleNameUpdate = async (check) => {
        try {
            await axiosInstance.patch(`/qualitaet/checks/${check.id}/`, { name: editedName });  // Update the name
            // Update the name in the local state
            setQualitaetChecks(qualitaetChecks.map(c => c.id === check.id ? { ...c, name: editedName } : c));
        } catch (error) {
            console.error('Fehler beim Ändern des Namens:', error);
        } finally {
            setEditingCheckId(null);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Paper>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                }}
            >
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Liste der Qualitätschecks
                        </Typography>
                    }
                    action={
<Button
  variant="outlined"
  color="primary"
  size="small" // Basis: small
  startIcon={<AddCircleIcon />}
  onClick={handleDialogOpen}
  sx={{
    borderRadius: '50px',
    fontSize: '0.75rem',    // Schriftgröße reduzieren
    padding: '2px 8px',     // Weniger Padding
    minWidth: 'auto'        // Mindestbreite anpassen, falls erforderlich
  }}
>
  Qualitätscheck hinzufügen
</Button>

                    }
                />
                <CardContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {Array.isArray(qualitaetChecks) && qualitaetChecks.length > 0 ? (
                            qualitaetChecks.map((check) => (
                                <React.Fragment key={check.id}>
                                    <ListItem>  
                                        <ListItemIcon>
                                            <ChecklistIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={
                                                editingCheckId === check.id ? (
                                                    <TextField
                                                        value={editedName}
                                                        onChange={(e) => setEditedName(e.target.value)}
                                                        onBlur={() => handleNameUpdate(check)}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleNameUpdate(check);
                                                            }
                                                        }}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography onClick={() => handleNameEdit(check)}>
                                                        {check.name}
                                                    </Typography>
                                                )
                                            } 
                                            secondary={check.is_active ? "Aktiv" : "Passiv"} 
                                        />
                                        <Switch
                                            edge="end"
                                            checked={check.is_active}
                                            onChange={(e) => handleToggleActive(check, e)} 
                                            inputProps={{ 'aria-label': 'Aktiv/Passiv umschalten' }}
                                        />
                                        <IconButton edge="end" aria-label="edit" onClick={(e) => {e.stopPropagation(); handleEditDialogOpen(check);}}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => {e.stopPropagation(); handleDelete(check.id);}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="h6">Keine Qualitätschecks gefunden</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>
            
            <QualitaetHinzufuegen 
                open={dialogOpen} 
                handleClose={handleDialogClose} 
            />

            <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Qualitätscheck Konfiguration</DialogTitle>
                <DialogContent>
                    {selectedCheck && (
                        <QualitaetKonfiguration
                            open={editDialogOpen}
                            handleClose={handleEditDialogClose}
                            checkId={selectedCheck.id}  
                            checkName={selectedCheck.name}  
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            </Paper>
    );
};

export default QualitaetChecksListe;
