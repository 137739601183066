import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import ComparisonCard from '../../../components/card/ComparisonCardFilialeWoche';
import BigChartCard from '../../../components/charts/scorecardsWoche/LineChartWoche';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import FilialeWocheLayout from '../../../components/layout/Scorecards/FilialeWocheLayout';

dayjs.extend(weekOfYear);

// ========================
// Hilfsfunktionen
// ========================

// Zahlen mit deutschem Format (Dezimal, Tausenderpunkte etc.)
function formatGermanNumber(value, decimals = 0) {
  if (value === null || value === undefined || isNaN(value)) return 'N/A';
  const number = parseFloat(value.toString());
  if (isNaN(number)) return 'N/A';
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  return formatter.format(number);
}

function isPercentageField(fieldName) {
  return fieldName.toLowerCase().includes('wachstum');
}

// Formatierung für ComparisonCards
function formatComparisonValue(value, fieldName) {
  if (value === 'N/A' || value === undefined || value === null) return 'N/A';
  const num = parseFloat(value);
  if (isNaN(num)) return value;

  // Kundenbon & Leistung immer 2 Nachkommastellen
  if (
    fieldName.toLowerCase().includes('kundenbon') ||
    fieldName.toLowerCase().includes('leistung')
  ) {
    return formatGermanNumber(num, 2);
  }

  // Wachstumsfelder sind in Prozent
  if (isPercentageField(fieldName)) {
    return `${num.toFixed(2)} %`;
  }

  // Umsatz / Kundenanzahl -> ganze Zahl
  if (fieldName === 'umsatz' || fieldName === 'kundenanzahl') {
    return formatGermanNumber(num, 0);
  }

  // Sonstiger Fall
  return value;
}

// ========================
// Hauptkomponente
// ========================

const Umsatz = () => {
  const { filterState } = useContext(FilterContext);

  // State für Tabelle, Diagramm und Comparison-Daten
  const [tableData, setTableData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [mergedObj, setMergedObj] = useState({});
  
  // Optional, falls du comparisonCardData als Array brauchst:
  const [comparisonCardData, setComparisonCardData] = useState([]);

  useEffect(() => {
    if (filterState.filiale && filterState.datumWoche) {
      fetchData();
    }
 
  }, [filterState.filiale, filterState.datumWoche]);

  const fetchData = async () => {
    try {
      // Aus dem Filter: Jahr und KW
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);

      // Wir betrachten die letzten 6 Wochen
      const startDate = dayjs()
        .year(year)
        .week(week)
        .startOf('week')
        .subtract(5, 'week');
      const endDate = dayjs().year(year).week(week).endOf('week');

      // ==============
      // 1) Monats-Endpoint für Filiale (Scores etc.)
      //    => liefert i.d.R. nur wenige Zeilen
      // ==============
      const responseMonth = await axiosInstance.get(
        'scorecards_month/performance-monat/',
        {
          params: {
            startdatum: startDate.format('YYYY-MM-DD'),
            enddatum: endDate.format('YYYY-MM-DD'),
            filiale: filterState.filiale?.label
          }
        }
      );

      // ==============
      // 2) Wochen-Endpoint für Unternehmen
      //    => wöchentliche Unternehmenswerte
      // ==============
      const responseUnternehmen = await axiosInstance.get(
        'scorecard_week/performance-unternehmen-woche/',
        {
          params: {
            startdatum: startDate.format('YYYY-MM-DD'),
            enddatum: endDate.format('YYYY-MM-DD')
          }
        }
      );

      // ==============
      // 3) fact/kundenstatistik für Filiale (täglich)
      // ==============
      const responseKundenstatistik = await axiosInstance.get(
        'fact/kundenstatistik',
        {
          params: {
            startdatum: startDate.format('YYYY-MM-DD'),
            enddatum: endDate.format('YYYY-MM-DD'),
            filiale: filterState.filiale?.value
          }
        }
      );

      // ==============
      // A) "baseObj" + "additionalObj" mergen
      //    -> Aus den Endpoints holst du dir die letzten Datensätze
      //       (für z.B. Score-Felder).
      // ==============
      const baseObj = responseMonth.data.results.slice(-1)[0] || {};
      const additionalObj = responseUnternehmen.data.results.slice(-1)[0] || {};
      let merged = { ...baseObj, ...additionalObj };

      // ==============
      // B) Tabelle aus "factData"
      //    -> Tagesbasierte Zeilen
      // ==============
      const factData = responseKundenstatistik.data.results || [];
      const factTableData = factData.map((item) => {
        const d = dayjs(item.datum);
        const jahr = d.year();
        const kw = String(d.week()).padStart(2, '0');

        const umsatz = item.umsatz || 0;
        const kundenanzahl = item.kundenanzahl || 0;
        const produktivstunden = item.produktivstunden || 1;
        const kundenbon = kundenanzahl > 0 ? umsatz / kundenanzahl : 0;
        const leistung = produktivstunden > 0 ? umsatz / produktivstunden : 0;

        return {
          jahrKw: `${jahr}.${kw}`,
          datum: item.datum,
          umsatz,
          kundenanzahl,
          kundenbon,
          leistung,
          produktivstunden
        };
      });
      setTableData(factTableData);

      // ==============
      // C) Diagramm-Daten
      //    Filiale: Aggregation (factData) -> pro KW
      // ==============
      const weeklyMapFiliale = {};

      factTableData.forEach((row) => {
        if (!weeklyMapFiliale[row.jahrKw]) {
          weeklyMapFiliale[row.jahrKw] = 0;
        }
        weeklyMapFiliale[row.jahrKw] += row.umsatz;
      });

      const branchLineChartData = Object.entries(weeklyMapFiliale).map(
        ([kwKey, sumUmsatz]) => {
          return {
            x: kwKey,      // z.B. "2023.39"
            y: sumUmsatz,  // Summierter Umsatz pro KW
            label: 'Filiale'
          };
        }
      );
      // Sortierung
      branchLineChartData.sort((a, b) => {
        const [ayear, aweek] = a.x.split('.').map(Number);
        const [byear, bweek] = b.x.split('.').map(Number);
        if (ayear !== byear) return ayear - byear;
        return aweek - bweek;
      });

      // Unternehmen (Wochenebene)
      const companyLineChartData = responseUnternehmen.data.results.map(
        (item) => {
          const d = dayjs(item.datum);
          const kwKey = `${d.year()}.${String(d.week()).padStart(2, '0')}`;
          return {
            x: kwKey,
            y: item.umsatz,
            label: 'Unternehmen'
          };
        }
      );
      companyLineChartData.sort((a, b) => {
        const [ayear, aweek] = a.x.split('.').map(Number);
        const [byear, bweek] = b.x.split('.').map(Number);
        if (ayear !== byear) return ayear - byear;
        return aweek - bweek;
      });

      // Kombinieren
      const combinedLineChartData = [
        ...branchLineChartData,
        ...companyLineChartData
      ];
      setLineChartData(combinedLineChartData);

      // ==============
      // D) Aggregation für ComparisonCards:
      //    "merged" bekommt die aktuellen Werte (umsatz, kundenanzahl, etc.)
      //    aus der gewählten KW. Score-Felder & Co. bleiben erhalten.
      // ==============
      const selectedWeekData = factTableData.filter((row) => {
        const [y, w] = row.jahrKw.split('.').map(Number);
        return y === year && w === week;
      });

      const sumUmsatz = selectedWeekData.reduce((acc, r) => acc + r.umsatz, 0);
      const sumKunden = selectedWeekData.reduce((acc, r) => acc + r.kundenanzahl, 0);
      const sumProd = selectedWeekData.reduce(
        (acc, r) => acc + (r.produktivstunden || 0),
        0
      );

      const aggKundenbon = sumKunden > 0 ? sumUmsatz / sumKunden : 0;
      const aggLeistung = sumProd > 0 ? sumUmsatz / sumProd : 0;

      // Diese Felder überschreiben wir (damit die Cards die KW-Werte anzeigen)
      merged.umsatz = sumUmsatz;
      merged.kundenanzahl = sumKunden;
      merged.kundenbon = aggKundenbon;
      merged.leistung = aggLeistung;

      // Alles speichern
      setMergedObj(merged);
      setComparisonCardData([merged]);
    } catch (error) {
      console.error('Ein Fehler ist beim Abrufen der Daten aufgetreten:', error);
    }
  };

  // ==========================
  // Tabellen-Definition
  // ==========================
  const columns = [
    {
      field: 'datum',
      headerName: 'Datum',
      valueFormatter: ({ value }) => dayjs(value).format('DD.MM.YY')
    },
    {
      field: 'umsatz',
      headerName: 'Umsatz',
      valueFormatter: ({ value }) => formatGermanNumber(value, 2)
    },
    {
      field: 'kundenanzahl',
      headerName: 'Kunden',
      valueFormatter: ({ value }) => formatGermanNumber(value, 0)
    },
    {
      field: 'kundenbon',
      headerName: 'Kundenbon',
      valueFormatter: ({ value }) => formatGermanNumber(value, 2)
    },
    {
      field: 'leistung',
      headerName: 'Leistung',
      valueFormatter: ({ value }) => formatGermanNumber(value, 2)
    }
  ];

  const initialGrouping = ['jahrKw'];

  const aggregationFunctions = {
    umsatz: (items) => {
      return items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
    },
    kundenanzahl: (items) => {
      return items.reduce((sum, i) => sum + (i.kundenanzahl || 0), 0);
    },
    kundenbon: (items) => {
      const sumU = items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
      const sumK = items.reduce((sum, i) => sum + (i.kundenanzahl || 0), 0);
      return sumK > 0 ? sumU / sumK : 0;
    },
    leistung: (items) => {
      const sumU = items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
      const sumP = items.reduce((sum, i) => sum + (i.produktivstunden || 0), 0);
      return sumP > 0 ? sumU / sumP : 0;
    },
    datum: () => null
  };

  const groupingHeaders = {
    jahrKw: 'Jahr.KW'
  };

  // ==========================
  // Render
  // ==========================
  return (
    <FilialeWocheLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageHeader
          title={`Performance Kennzahlen für die Filiale: ${
            filterState?.filiale?.label ?? ''
          }`}
          subtitle="Übersicht über die Performance Kennzahlen"
          Icon={BarChartIcon}
        />
      </Box>

      <Grid container justifyContent="flex-start" alignItems="stretch" spacing={2}>
        
        {/* Linke Spalte: ComparisonCards (genau wie zuvor) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {/* UMATZ CARD */}
          <ComparisonCard
            zeitraum="Umsatz"
            data={mergedObj}
            fields={[
              { label: 'Umsatz', valueField: 'umsatz' },
              { label: 'Score VJ', valueField: 'scoreUmsatzWachstumJ' },
              { label: 'VJ', valueField: 'umsatzWachstumJ' },
              { label: 'Score Ø\n6W', valueField: 'scoreUmsatzWachstum6W' },
              { label: 'Ø 6W %', valueField: 'umsatzWachstum6W' }
            ]}
            formatValue={formatComparisonValue}
          />

          {/* KUNDENANZAHL CARD */}
          <ComparisonCard
            zeitraum="Kundenanzahl"
            data={mergedObj}
            fields={[
              { label: 'Kundenanzahl', valueField: 'kundenanzahl' },
              { label: 'Score VJ', valueField: 'scoreKundenanzahlWachstumJ' },
              { label: 'VJ', valueField: 'kundenanzahlWachstumJ' },
              { label: 'Score Ø\n6W', valueField: 'scoreKundenanzahlWachstum6W' },
              { label: 'Ø 6W %', valueField: 'kundenanzahlWachstum6W' }
            ]}
            formatValue={formatComparisonValue}
          />

          {/* KUNDENBON CARD */}
          <ComparisonCard
            zeitraum="Kundenbon"
            data={mergedObj}
            fields={[
              { label: 'Kundenbon', valueField: 'kundenbon' },
              { label: 'Score VJ', valueField: 'scoreKundenbonWachstumJ' },
              { label: 'VJ', valueField: 'kundenbonVJ' },
              { label: 'VJ %', valueField: 'kundenbonWachstumJ' }
            ]}
            formatValue={formatComparisonValue}
          />

          {/* LEISTUNG CARD */}
          <ComparisonCard
            zeitraum="Leistung"
            data={mergedObj}
            fields={[
              { label: 'Leistung', valueField: 'leistung' },
              { label: 'Score VJ', valueField: 'scoreLeistungWachstumJ' },
              { label: 'VJ', valueField: 'leistungVJ' },
              { label: 'VJ %', valueField: 'leistungWachstumJ' }
            ]}
            formatValue={formatComparisonValue}
          />
        </Grid>

        {/* Rechte Spalte: Diagramm */}
        <Grid item xs={12} md={6}>
          <BigChartCard
            data={lineChartData}
            title="Umsatzverlauf (Wochenwerte)"
            infoContent={
              <>
                <strong>Umsatzverlauf</strong> (6 Wochen Aggregation)
                <br />
                Dunkel = Filiale (aus fact/kundenstatistik, wöchentlich summiert)
                <br />
                Hell = Unternehmen (aus performance-unternehmen-woche)
                <br />
                X-Achse in Form: Jahr.KW
              </>
            }
          />
        </Grid>

        {/* Ganze Breite: Tabelle */}
        <Grid item xs={12} mt={2}>
          <VariableTreeMUI
            data={tableData}
            columns={columns}
            initialGrouping={initialGrouping}
            aggregationFunctions={aggregationFunctions}
            title="Detailtabelle (Jahr.KW > Tage)"
            groupingHeaders={groupingHeaders}
          />
        </Grid>
      </Grid>
    </FilialeWocheLayout>
  );
};

export default Umsatz;
