// src/pages/unternehmenwoche/performance/kundenbon.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack, CircularProgress, Typography, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/UnternehmenWocheLayout';
import DataCard from '../../../components/card/scorecardsmonat/DataCardWochePage';
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import FilialePopup from '../../../components/popup/unternehmenWoche/FilialePopup';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import { tausenderTrennung } from '../../../utils/mathStuff';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/de';
import { useTheme } from '@mui/material';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.locale('de');

// Definition der Spalten für die Haupttabelle (VariableTreeMUI)
const mainTableColumns = [
    { field: 'verkaufsgebiet', headerName: 'Verkaufsgebiet', align: 'left' },
    { field: 'filiale', headerName: 'Filiale', align: 'left' },
    {
        field: 'score',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? tausenderTrennung(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'umsatz',
        headerName: 'Umsatz',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)} €` : '',
        hide: false,
    },
    {
        field: 'kundenanzahl',
        headerName: 'Kundenanzahl',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value)}` : '',
        hide: false,
    },
    {
        field: 'kundenbon',
        headerName: 'Kundenbon',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)} €` : '',
        hide: false,
    },
    {
        field: 'kundenbonWachstum6W',
        headerName: '∅ 6W',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)}%` : '',
        hide: false,
    },
    {
        field: 'kundenbonWachstumVJ',
        headerName: 'VJ',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${tausenderTrennung(params.value, 2)}%` : '',
        hide: false,
    },
];

// Definition der Spalten für die Popup-Tabelle (BigTableCard)
const popupTableColumns = [
    { accessorKey: 'datum', header: 'Datum', align: 'left' },
    {
        accessorKey: 'umsatz',
        header: 'Umsatz',
        align: 'right',
        valueFormatter: (value) =>
            value !== null && value !== undefined ? `${tausenderTrennung(value, 2)} €` : '',
    },
    {
        accessorKey: 'kundenanzahl',
        header: 'Kundenanzahl',
        align: 'right',
        valueFormatter: (value) =>
            value !== null && value !== undefined ? `${tausenderTrennung(value)}` : '',
    },
    {
        accessorKey: 'kundenbon',
        header: 'Kundenbon',
        align: 'right',
        valueFormatter: (value) =>
            value !== null && value !== undefined ? `${tausenderTrennung(value, 2)} €` : '',
    },
];

const aggregationFunctions = {
    score: (items) => {
        const totalScore = items.reduce((sum, item) => sum + (item.score || 0), 0);
        const count = items.length;
        return count > 0 ? parseFloat((totalScore / count).toFixed(2)) : 0.00;
    },
    umsatz: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        return totalUmsatz;
    },
    kundenanzahl: (items) => {
        const totalKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
        return totalKundenanzahl;
    },
    kundenbon: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        const totalKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
        const kundenbon = totalKundenanzahl > 0 ? parseFloat((totalUmsatz / totalKundenanzahl).toFixed(2)) : 0.00;
        return kundenbon;
    },
    kundenbonWachstum6W: (items) => {
        const validItems = items.filter(item => item.kundenbonWachstum6W !== undefined && item.kundenbonWachstum6W !== null);
        const totalWachstum6W = validItems.reduce((sum, item) => sum + (parseFloat(item.kundenbonWachstum6W) || 0), 0);
        const count = validItems.length;
        return count > 0 ? parseFloat((totalWachstum6W / count).toFixed(2)) : 0.00;
    },
    kundenbonWachstumVJ: (items) => {
        const totalKundenbon = items.reduce((sum, item) => sum + (item.kundenbon || 0), 0);
        const totalKundenbonVJ = items.reduce((sum, item) => sum + (item.kundenbonVJ || 0), 0);
        return totalKundenbonVJ > 0 ? parseFloat(((totalKundenbon / totalKundenbonVJ - 1) * 100).toFixed(2)) : 0.00;
    },
};

const Kundenbon = () => {
    const { filterState } = useContext(FilterContext);
    const theme = useTheme();
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        kundenanzahl: 0,
        kundenbon: 0,
        leistung: 0,
    });
    const [growthData, setGrowthData] = useState({
        umsatzWachstumJ: '0.00',
        kundenanzahlWachstumJ: '0.00',
        kundenbonWachstum: '0.00',
        leistungsWachstum: '0.00',
        kundenbonWachstum6W: '0.00',
    });
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupChartData, setPopupChartData] = useState([]);
    const [popupTableData, setPopupTableData] = useState([]);
    const [popupLines, setPopupLines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (filterState.datumWoche) {
            fetchData();
            fetchChartData();
        }
    }, [filterState.datumWoche, filterState.bestehendeFlaeche]); // Aktualisierte Dependency-Liste

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            await Promise.all([fetchPerformanceData(), fetchTableData()]);
        } catch (err) {
            setError(true);
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    const fetchPerformanceData = async () => {
        try {
            const [performanceResponse, produktivstundenResponse] = await Promise.all([
                axiosInstance.get('/scorecard_week/performance-woche/', {
                    params: {
                        jahrKw: filterState.datumWoche?.value,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
                axiosInstance.get('/scorecard_week/performance-produktivstunden/', {
                    params: {
                        jahrKw: filterState.datumWoche?.value,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
            ]);

            const performanceResults = performanceResponse.data.results;
            const produktivstundenResults = produktivstundenResponse.data.results;

            const produktivstundenMap = {};
            produktivstundenResults.forEach(item => {
                produktivstundenMap[item.filiale] = item.produktivstunden6W || 0;
            });

            const aggregatedData = performanceResults.reduce(
                (acc, item) => {
                    acc.umsatz += item.umsatz || 0;
                    acc.kundenanzahl += item.kundenanzahl || 0;
                    acc.produktivstunden += item.produktivstunden || 0;
                    acc.umsatzVJ += item.umsatzVJ || 0;
                    acc.kundenanzahlVJ += item.kundenanzahlVJ || 0;
                    acc.produktivstundenVJ += item.produktivstundenVJ || 0;
                    return acc;
                },
                { 
                    umsatz: 0, 
                    kundenanzahl: 0, 
                    produktivstunden: 0, 
                    umsatzVJ: 0, 
                    kundenanzahlVJ: 0, 
                    produktivstundenVJ: 0 
                }
            );

            const kundenbon = aggregatedData.kundenanzahl > 0 ? aggregatedData.umsatz / aggregatedData.kundenanzahl : 0;
            const leistung = aggregatedData.produktivstunden > 0 ? aggregatedData.umsatz / aggregatedData.produktivstunden : 0;

            const umsatzWachstumJ = aggregatedData.umsatzVJ > 0 ? ((aggregatedData.umsatz / aggregatedData.umsatzVJ - 1) * 100).toFixed(2) : '0.00';
            const kundenanzahlWachstumJ = aggregatedData.kundenanzahlVJ > 0 ? ((aggregatedData.kundenanzahl / aggregatedData.kundenanzahlVJ - 1) * 100).toFixed(2) : '0.00';
            const kundenbonVJ = aggregatedData.kundenanzahlVJ > 0 ? aggregatedData.umsatzVJ / aggregatedData.kundenanzahlVJ : 0;
            const kundenbonWachstum = kundenbonVJ > 0 ? ((kundenbon / kundenbonVJ - 1) * 100).toFixed(2) : '0.00';
            const leistungVJ = aggregatedData.produktivstundenVJ > 0 ? aggregatedData.umsatzVJ / aggregatedData.produktivstundenVJ : 0;
            const leistungsWachstum = leistungVJ > 0 ? ((leistung / leistungVJ - 1) * 100).toFixed(2) : '0.00';

            setPerformanceData({
                umsatz: tausenderTrennung(aggregatedData.umsatz, 2),
                kundenanzahl: tausenderTrennung(aggregatedData.kundenanzahl, 0),
                kundenbon: tausenderTrennung(kundenbon, 2),
                leistung: tausenderTrennung(leistung, 2),
            });

            setGrowthData({
                umsatzWachstumJ: umsatzWachstumJ,
                kundenanzahlWachstumJ: kundenanzahlWachstumJ,
                kundenbonWachstum: kundenbonWachstum,
                leistungsWachstum: leistungsWachstum,
                kundenbonWachstum6W: '0.00', // Initialisieren, falls benötigt
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
            throw error;
        }
    };

    const fetchTableData = async () => {
        try {
            // Aktuelle Woche
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);

            // Letzte 6 Wochen
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            // Daten der letzten 6 Wochen abrufen
            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', { 
                        params: { 
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            // Aggregation der Daten pro Filiale über die letzten 6 Wochen
            const dataPerFiliale = {};

            responses.forEach(response => {
                response.data.results.forEach(item => {
                    const filiale = item.filiale;
                    if (!dataPerFiliale[filiale]) {
                        dataPerFiliale[filiale] = {
                            umsatz: 0,
                            kundenanzahl: 0,
                            umsatzVJ: 0,
                            kundenanzahlVJ: 0,
                        };
                    }
                    dataPerFiliale[filiale].umsatz += item.umsatz || 0;
                    dataPerFiliale[filiale].kundenanzahl += item.kundenanzahl || 0;
                    dataPerFiliale[filiale].umsatzVJ += item.umsatzVJ || 0;
                    dataPerFiliale[filiale].kundenanzahlVJ += item.kundenanzahlVJ || 0;
                });
            });

            // Daten der aktuellen Woche abrufen
            const currentWeekResponse = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const formattedData = currentWeekResponse.data.results
                .filter((item) => item.umsatz > 0 || item.scoreKundenbon > 0)
                .map((item, index) => {
                    const filiale = item.filiale;
                    const data6W = dataPerFiliale[filiale] || {
                        umsatz: 0,
                        kundenanzahl: 0,
                        umsatzVJ: 0,
                        kundenanzahlVJ: 0,
                    };
                    const kundenbon6W = data6W.kundenanzahl > 0 ? data6W.umsatz / data6W.kundenanzahl : 0;
                    const kundenbon6WVJ = data6W.kundenanzahlVJ > 0 ? data6W.umsatzVJ / data6W.kundenanzahlVJ : 0;
                    const kundenbonWachstum6W = kundenbon6WVJ > 0 ? ((kundenbon6W / kundenbon6WVJ - 1) * 100).toFixed(2) : '0.00';

                    const kundenbonWachstumVJ = item.kundenanzahlVJ > 0 
                        ? ((item.kundenanzahl > 0 ? item.umsatz / item.kundenanzahl : 0) / (item.kundenanzahlVJ > 0 ? item.umsatzVJ / item.kundenanzahlVJ : 1) - 1) * 100 
                        : '0.00';

                    return {
                        id: index,
                        verkaufsgebiet: item.verkaufsgebiet || 'Unbekannt',
                        filiale: item.filiale,
                        score: item.scoreKundenbon || 0,
                        umsatz: item.umsatz || 0,
                        kundenanzahl: item.kundenanzahl || 0,
                        kundenbon: item.kundenanzahl > 0 ? parseFloat((item.umsatz / item.kundenanzahl).toFixed(2)) : 0,
                        kundenanzahlVJ: item.kundenanzahlVJ || 0,
                        kundenbonVJ: item.kundenanzahlVJ > 0 ? parseFloat((item.umsatzVJ / item.kundenanzahlVJ).toFixed(2)) : 0,
                        kundenbonWachstum6W: kundenbonWachstum6W,
                        kundenbonWachstumVJ: kundenbonWachstumVJ,
                    };
                });

            setTableData(formattedData);
        } catch (error) {
            console.error('Error fetching table data:', error);
            throw error;
        }
    };

    const fetchChartData = async () => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const chartDataArr = responses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        acc.umsatz += item.umsatz || 0;
                        acc.kundenanzahl += item.kundenanzahl || 0;
                        acc.umsatzVJ += item.umsatzVJ || 0;
                        acc.kundenanzahlVJ += item.kundenanzahlVJ || 0;
                        return acc;
                    },
                    { umsatz: 0, kundenanzahl: 0, umsatzVJ: 0, kundenanzahlVJ: 0 }
                );

                const kundenbon = aggregatedData.kundenanzahl > 0 ? aggregatedData.umsatz / aggregatedData.kundenanzahl : 0;
                const kundenbonVJ = aggregatedData.kundenanzahlVJ > 0 ? aggregatedData.umsatzVJ / aggregatedData.kundenanzahlVJ : 0;

                return {
                    x: week,
                    'Kundenbon': parseFloat(kundenbon.toFixed(2)),
                    'Kundenbon Vorjahr': parseFloat(kundenbonVJ.toFixed(2)),
                };
            });

            setChartData(chartDataArr);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    const handleRowClick = async (row) => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);

            const weekStart = currentWeek.startOf('isoWeek');
            const weekEnd = currentWeek.endOf('isoWeek');

            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const chartResponses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: { 
                            jahrKw: wk, 
                            filiale: row.filiale,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const popupChartDataArr = chartResponses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        acc.umsatz += item.umsatz || 0;
                        acc.kundenanzahl += item.kundenanzahl || 0;
                        acc.umsatzVJ += item.umsatzVJ || 0;
                        acc.kundenanzahlVJ += item.kundenanzahlVJ || 0;
                        return acc;
                    },
                    { umsatz: 0, kundenanzahl: 0, umsatzVJ: 0, kundenanzahlVJ: 0 }
                );

                const kundenbon = aggregatedData.kundenanzahl > 0 ? aggregatedData.umsatz / aggregatedData.kundenanzahl : 0;
                const kundenbonVJ = aggregatedData.kundenanzahlVJ > 0 ? aggregatedData.umsatzVJ / aggregatedData.kundenanzahlVJ : 0;

                return {
                    x: week,
                    'Kundenbon': parseFloat(kundenbon.toFixed(2)),
                    'Kundenbon Vorjahr': parseFloat(kundenbonVJ.toFixed(2)),
                };
            });

            const tableResponse = await axiosInstance.get('/fact/kundenstatistik/', {
                params: { filiale: row.filiale, jahrKw: filterState.datumWoche?.value },
            });

            const popupTableDataArr = tableResponse.data.results
                .filter((item) => {
                    const itemDate = dayjs(item.datum);
                    return itemDate.isBetween(weekStart, weekEnd, 'day', '[]');
                })
                .sort((a, b) => dayjs(a.datum).isAfter(dayjs(b.datum)) ? 1 : -1)
                .map((item) => ({
                    id: `${item.filiale}-${item.datum}`,
                    datum: dayjs(item.datum).format('dddd DD.MM.YY'),
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    kundenbon: item.kundenanzahl > 0 ? parseFloat((item.umsatz / item.kundenanzahl).toFixed(2)) : 0,
                }));

            const formattedPopupTableDataArr = popupTableDataArr.map(item => ({
                ...item,
                umsatz: `${tausenderTrennung(item.umsatz, 2)} €`,
                kundenanzahl: tausenderTrennung(item.kundenanzahl, 0),
                kundenbon: `${tausenderTrennung(item.kundenbon, 2)} €`,
            }));

            const popupChartLines = [
                { dataKey: 'Kundenbon', name: 'Kundenbon', color: theme.palette.primary.main },
                { dataKey: 'Kundenbon Vorjahr', name: 'Kundenbon Vorjahr', color: theme.palette.chart.pink },
            ];

            setPopupChartData(popupChartDataArr);
            setPopupTableData(formattedPopupTableDataArr);
            setPopupLines(popupChartLines);
            setPopupOpen(true);
        } catch (error) {
            console.error('Error fetching popup data:', error);
        }
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const mainChartLines = [
        { dataKey: 'Kundenbon', name: 'Kundenbon', color: theme.palette.primary.main },
        { dataKey: 'Kundenbon Vorjahr', name: 'Kundenbon Vorjahr', color: theme.palette.chart.pink },
    ];

    return (
        <Layout>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error">Fehler beim Laden der Daten.</Typography>
                </Box>
            ) : (
                <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
                    {/* Data Cards */}
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                            {/* Umsatz-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Umsatz"
                                    sector="performance"
                                    value={`${performanceData.umsatz} €`}
                                    subHeaders={[`${tausenderTrennung(growthData.umsatzWachstumJ, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Kundenanzahl-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Kundenanzahl"
                                    sector="performance"
                                    value={performanceData.kundenanzahl}
                                    subHeaders={[`${tausenderTrennung(growthData.kundenanzahlWachstumJ, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Kundenbon-Datenkarte (aktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                                <DataCard
                                    category="Kundenbon"
                                    sector="performance"
                                    value={`${performanceData.kundenbon} €`}
                                    subHeaders={[`${tausenderTrennung(growthData.kundenbonWachstum, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Leistung-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Leistung"
                                    sector="performance"
                                    value={`${performanceData.leistung} €`}
                                    subHeaders={[`${tausenderTrennung(growthData.leistungsWachstum, 2)}% VJ`]}
                                />
                            </Grid>
                        </Stack>
                    </Grid>

                    {/* Haupt Line Chart */}
                    <Grid item xs={12} mt={3}>
                        <BigChartCardUnternehmenWoche 
                            data={chartData}
                            title="Kundenbon und Kundenbon Vorjahr der letzten 6 Wochen"
                            lines={mainChartLines}
                            height={400}
                            infoContent="Dies ist eine Info über den Kundenbonchart."
                        />
                    </Grid>

                    {/* Haupttabelle */}
                    <Grid item xs={12} mt={3}>
                        <VariableTreeMUI
                            title="Übersicht nach Verkaufsgebieten"
                            data={tableData}
                            columns={mainTableColumns}
                            initialGrouping={['verkaufsgebiet']}
                            groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                            aggregationFunctions={aggregationFunctions}
                            onRowClick={handleRowClick}
                        />
                    </Grid>

                    {/* Popup */}
                    <FilialePopup
                        open={popupOpen}
                        onClose={closePopup}
                        chartData={popupChartData}
                        tableData={popupTableData}
                        tableColumns={popupTableColumns}
                        chartTitle="Kundenbonentwicklung der letzten 6 Wochen"
                        tableTitle="Tagesübersicht"
                        lines={popupLines}
                    />
                </Grid>
            )}
        </Layout>
    );
};

export default Kundenbon;
