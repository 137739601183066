import React, { useEffect, useState } from 'react';
import {
    Box, Grid, Paper, Card, CardHeader, CardContent, Typography,
    CircularProgress, Divider, Table, TableHead, TableRow, TableCell, TableBody,
    Button
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import TaskResponseDialog from '../../../components/Aufgaben/FormularDialog'; // Pfad ggf. anpassen

function MeineAufgabenSeite() {
    const [instances, setInstances] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openTaskDialog, setOpenTaskDialog] = useState(false);
    const [currentTaskInstanceId, setCurrentTaskInstanceId] = useState(null);

    // Anzahl der Fragen für dieses TaskTemplate
    const [taskQuestionsCount, setTaskQuestionsCount] = useState({});

    const fetchInstances = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/aufgaben/task-instances/`);
            const data = response.data.results || [];
            setInstances(data);
            setLoading(false);
        } catch (error) {
            console.error("Fehler beim Laden der Instanzen:", error);
            setInstances([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInstances();
    }, []);

    const handleMarkDone = async (id) => {
        try {
            await axiosInstance.patch(`/aufgaben/task-instances/${id}/`, {
                is_done: true,
                status: 'done',
                completed_time: new Date().toISOString()
            });
            fetchInstances();
        } catch (error) {
            console.error("Fehler beim Aktualisieren der Aufgabe:", error);
        }
    };

    const RHYTHM_ORDER = [
        { value: 'once', label: 'Einmalig' },
        { value: 'daily', label: 'Täglich' },
        { value: 'weekly', label: 'Wöchentlich' },
        { value: 'monthly', label: 'Monatlich' },
        { value: 'yearly', label: 'Jährlich' }
    ];

    const getInstancesByRhythm = (rhythm) => {
        return instances.filter(inst => inst.template && inst.template.rhythm === rhythm);
    };

    const handleOpenTaskDialog = (taskInstanceId) => {
        setCurrentTaskInstanceId(taskInstanceId);
        setOpenTaskDialog(true);
    };

    const handleCloseTaskDialog = () => {
        setOpenTaskDialog(false);
        setCurrentTaskInstanceId(null);
        fetchInstances();
    };

    // Lädt die Anzahl der Fragen für ein TaskTemplate
    const loadTaskQuestionsCount = async (template) => {
        if (!template) return 0;
        let count = 0;
        if (Array.isArray(template.task_sections)) {
            template.task_sections.forEach(sec => {
                count += sec.questions.length;
            });
        }
        return count;
    };

    const renderTable = (rhythmValue, rhythmLabel) => {
        const filtered = getInstancesByRhythm(rhythmValue);

        if (filtered.length === 0) {
            return null;
        }

        return (
            <Paper sx={{ mb: 4 }} key={rhythmValue}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '0px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <CardHeader
                        title={
                            <Typography variant="h6" component="div">
                                {`Aufgaben - ${rhythmLabel}`}
                            </Typography>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Table sx={{ minWidth: 650 }} aria-label={`${rhythmLabel} Aufgabentabelle`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Titel</strong></TableCell>
                                    <TableCell><strong>Rhythmus</strong></TableCell>
                                    <TableCell><strong>Fälligkeitsdatum</strong></TableCell>
                                    <TableCell><strong>Zugewiesener Benutzer</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>
                                    <TableCell><strong>Fällig</strong></TableCell>
                                    <TableCell><strong>Überfällig</strong></TableCell>
                                    <TableCell><strong>Eskalation</strong></TableCell>
                                    <TableCell><strong>Aufgabe beantworten</strong></TableCell>
                                    <TableCell><strong>Fragenanzahl</strong></TableCell>
                                    <TableCell><strong>Erledigen</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filtered.map((inst) => {
                                    const { id, template, due_datetime, status, is_done, is_due_active, is_overdue_active, is_escalated_active, assigned_users } = inst;
                                    const title = template?.title || 'Unbekannt';
                                    const rhythm = template?.rhythm || 'Unbekannt';
                                    const dueDate = due_datetime ? new Date(due_datetime).toLocaleString() : 'Kein Datum';

                                    const assigned = Array.isArray(assigned_users) && assigned_users.length > 0 ? assigned_users : [null];

                                    return assigned.map((userId, index) => {
                                        const rowKey = `${id}-${userId || 'none'}-${index}`;

                                        // Anzahl der Fragen berechnen
                                        let fieldsCount = 0;
                                        if (template && template.task_sections) {
                                            fieldsCount = template.task_sections.reduce((acc, sec) => acc + sec.questions.length, 0);
                                        }

                                        return (
                                            <TableRow key={rowKey}>
                                                <TableCell>{title}</TableCell>
                                                <TableCell>{rhythm}</TableCell>
                                                <TableCell>{dueDate}</TableCell>
                                                <TableCell>{userId || 'Keine'}</TableCell>
                                                <TableCell>{status}</TableCell>
                                                <TableCell>{is_due_active ? 'Ja' : 'Nein'}</TableCell>
                                                <TableCell>{is_overdue_active ? 'Ja' : 'Nein'}</TableCell>
                                                <TableCell>{is_escalated_active ? 'Ja' : 'Nein'}</TableCell>
                                                <TableCell>
                                                    {fieldsCount > 0 ? (
                                                        <Button variant="outlined" onClick={() => handleOpenTaskDialog(id)}>
                                                            Aufgabe beantworten
                                                        </Button>
                                                    ) : (
                                                        <Typography variant="body2">Keine Fragen</Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>{fieldsCount}</TableCell>
                                                <TableCell>
                                                    {is_done ? (
                                                        <Typography variant="body2">Bereits erledigt</Typography>
                                                    ) : (
                                                        <Button variant="contained" color="primary" onClick={() => handleMarkDone(id)}>
                                                            Erledigen
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    });
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Paper>
        );
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                p: 3,
                maxWidth: 1200,
                margin: '0 auto',
                bgcolor: 'transparent',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Meine Aufgaben (Instanzen)
            </Typography>
            <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={12}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        RHYTHM_ORDER.map(r => renderTable(r.value, r.label))
                    )}
                </Grid>
            </Grid>

            {openTaskDialog && (
                <TaskResponseDialog
                    open={openTaskDialog}
                    onClose={handleCloseTaskDialog}
                    taskInstanceId={currentTaskInstanceId}
                />
            )}
        </Box>
    );
}

export default MeineAufgabenSeite;
