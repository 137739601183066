import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import {FilterContext} from "../../../provider/FilterProvider";
import Score from '../../../components/score/Score';
import DropdownFilter from '../../../components/filter/DropdownFilter'; // Import the DropdownFilter component
import VerkaufsgebietDropdown from '../../../components/filter/VerkaufsgebietDropdown'; // Import the VerkaufsgebietDropdown component
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Import der neuen Komponente
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import FilialeWocheLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";



const Overview = () => {
  const [data, setData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [wareData, setWareData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
  const [datumOptions, setDatumOptions] = useState([]);  // Add datumOptions state
  const [greeting, setGreeting] = useState('Hallo');

  const {filterState, handleFilialeChange, handleDatumChange} = useContext(FilterContext);  // Add handleDatumChange
  const navigate = useNavigate();

  useEffect(() => {
    setGreetingMessage();  // Set greeting message based on the time of day
    fetchVerkaufsgebietOptions();
    fetchDatumOptions(); // Fetch datum options
  }, []);

  useEffect(() => {
    console.log("Datum oder VerkaufsgebietFilter geändert:", filterState.datum, verkaufsgebietFilter);
    if (filterState.datum) {
      fetchAllData();
    }
  }, [filterState.datum, verkaufsgebietFilter]);

  const setGreetingMessage = () => {
    const currentHour = dayjs().hour();
    if (currentHour < 12) {
      setGreeting('Guten Morgen');
    } else if (currentHour < 18) {
      setGreeting('Hallo');
    } else {
      setGreeting('Guten Abend');
    }
  };

  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
      setVerkaufsgebietOptions(response.data.map(item => ({label: item, value: item})));
    } catch (error) {
      console.error('Error while fetching VerkaufsgebietOptions', error);
    }
  };

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(response.data.map(item => ({label: item, value: item})));
    } catch (error) {
      console.error('Error while fetching datum options', error);
    }
  };

  const fetchAllData = async () => {
    try {
      const params = {
        jahrMonat: filterState.datum?.value,
        aktiv: 'True',
      };
      if (verkaufsgebietFilter) {
        params.verkaufsgebiet = verkaufsgebietFilter.value;
      }

      // Fetch all data in parallel
      const [performanceResponse, personalResponse, wareResponse] = await Promise.all([
        axiosInstance.get('scorecards_month/performance-monat/', {params}),
        axiosInstance.get('scorecards_month/personal-kennzahl/', {params}),
        axiosInstance.get('scorecards_month/ware-filiale-monat/', {params}),
      ]);


      const combinedData = performanceResponse.data.results.map((performanceItem) => {
        const filiale = performanceItem.filiale;
        const personalItem = personalResponse.data.results.find(item => item.filiale === filiale) || {};
        const wareItem = wareResponse.data.results.find(item => item.filiale === filiale) || {};

        return {
          filiale: filiale,
          performanceScore: performanceItem.scorePerformance || 0,
          personalScore: personalItem.scorePersonal || 0,
          wareScore: wareItem.scoreWareFiliale || 0,
          umsatz: performanceItem.scoreUmsatz || 0,
          kundenanzahl: performanceItem.scoreKundenanzahl || 0,
          kundenbon: performanceItem.scoreKundenbon || 0,
          leistung: performanceItem.scoreLeistung || 0,
          krankenquote: personalItem.scoreKrankenquote || 0,
          fluktuation: personalItem.scoreFluktuation || 0,
          bedarf: personalItem.scoreKöpfeDifferenzQuote || 0,
          auslastung: personalItem.scoreÜberstunden || 0,
          wachstum: wareItem.scoreProduktwachstumFiliale || 0,
          retoureA: wareItem.scoreSollRetoureFiliale || 0,
          verfügbarkeit: wareItem.scoreVerfügbarkeitFiliale || 0,
          retoureBC: wareItem.scoreRetourQuoteFilialeBC || 0,
        };
      });

      setData(combinedData);
      setPerformanceData(combinedData);
      setPersonalData(combinedData);
      setWareData(combinedData);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleRowClick = (filiale) => {
    const selectedFiliale = {label: filiale, value: filiale};
    handleFilialeChange(selectedFiliale);
    navigate('/scorecards/overview/cockpit');  // Redirect to the cockpit page
  };

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return renderPerformanceTable();
      case 1:
        return renderPersonalTable();
      case 2:
        return renderWareTable();
      default:
        return null;
    }
  };

  const renderPerformanceTable = () => (
      <TableContainer component={Paper} sx={{maxHeight: 800, overflowY: 'auto'}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography>Filiale</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Umsatz</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Kundenanzahl</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Kundenbon</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Leistung</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {performanceData.map((item, index) => (
                <TableRow
                    key={index}
                    onClick={() => handleRowClick(item.filiale)}  // Handle row click to set the filter and navigate
                    sx={{cursor: 'pointer'}}
                >
                  <TableCell align="left">
                    <Typography>{item.filiale}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.umsatz}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.kundenanzahl}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.kundenbon}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.leistung}/>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );

  const renderPersonalTable = () => (
      <TableContainer component={Paper} sx={{maxHeight: 800, overflowY: 'auto'}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography>Filiale</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Krankenquote</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Fluktuation</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Bedarf</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Auslastung</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personalData.map((item, index) => (
                <TableRow
                    key={index}
                    onClick={() => handleRowClick(item.filiale)}  // Handle row click to set the filter and navigate
                    sx={{cursor: 'pointer'}}
                >
                  <TableCell align="left">
                    <Typography>{item.filiale}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.krankenquote}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.fluktuation}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.bedarf}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.auslastung}/>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );

  const renderWareTable = () => (
      <TableContainer component={Paper} sx={{maxHeight: 800, overflowY: 'auto'}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography>Filiale</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Wachstum</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Retoure A</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Verfügbarkeit</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Retoure B/C</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wareData.map((item, index) => (
                <TableRow
                    key={index}
                    onClick={() => handleRowClick(item.filiale)}  // Handle row click to set the filter and navigate
                    sx={{cursor: 'pointer'}}
                >
                  <TableCell align="left">
                    <Typography>{item.filiale}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.wachstum}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.retoureA}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.verfügbarkeit}/>
                  </TableCell>
                  <TableCell align="center">
                    <Score score={item.retoureBC}/>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );

  return (
      <FilialeWocheLayout>
        <Grid container item spacing={2} alignItems={"center"}>
          {/* Titel und Subtitle mit Icon */}
          <Grid item xs={8}>
            <PageHeader
                title="Monats Scorecards "
                subtitle=" Monatliche Auswertung von Kennzahlen, um Fokus-Themen abzuleiten und in die Planung zu integrieren. Der Score gibt Aufschluss über die Einordnung im Vergleich zum Benchmark."
                Icon={AnalyticsOutlinedIcon}
            />
          </Grid>


          <Grid item xs={12} md={4}>
            <Stack
                direction={{xs: 'column', sm: 'row'}}  // Auf kleinen Bildschirmen untereinander, ab "sm" nebeneinander
                spacing={2}  // Abstand zwischen den Filtern
            >
              <DropdownFilter
                  label="Datum"
                  options={datumOptions}
                  value={filterState.datum}
                  onChange={handleDatumChange}
              />
              <VerkaufsgebietDropdown
                  options={verkaufsgebietOptions}
                  value={verkaufsgebietFilter}
                  onChange={setVerkaufsgebietFilter}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper} sx={{maxHeight: 900, overflowY: 'auto'}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography>Filiale</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>Performance</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>Personal</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>Ware</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                      <TableRow
                          key={index}
                          onClick={() => handleRowClick(item.filiale)}  // Handle row click to set the filter and navigate
                          sx={{cursor: 'pointer'}}
                      >
                        <TableCell align="left">
                          <Typography>{item.filiale}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Score score={item.performanceScore}/>
                        </TableCell>
                        <TableCell align="center">
                          <Score score={item.personalScore}/>
                        </TableCell>
                        <TableCell align="center">
                          <Score score={item.wareScore}/>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
              >
                <Tab label="Performance"/>
                <Tab label="Personal"/>
                <Tab label="Ware"/>
              </Tabs>
              <Box p={2}>
                {renderTabContent()}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </FilialeWocheLayout>
  );
};

export default Overview;
