import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  IconButton
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const DialogKategorien = ({
  open,
  onClose,
  categories,
  onCreateCategory,
  onUpdateCategory,
  onDeleteCategory
}) => {
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedName, setEditedName] = useState('');

  // Für die neue Kategorie
  const [newCategoryName, setNewCategoryName] = useState('');

  // ---------------------------------------------
  // Erstellen neuer Kategorie
  // ---------------------------------------------
  const handleCreate = async () => {
    if (!newCategoryName.trim()) return;
    await onCreateCategory(newCategoryName);
    setNewCategoryName('');
  };

  // ---------------------------------------------
  // Bearbeiten bestehender Kategorie
  // ---------------------------------------------
  const handleEditClick = (cat) => {
    setEditingCategory(cat);
    setEditedName(cat.name);
  };

  const handleEditSave = async () => {
    if (editingCategory && editedName.trim()) {
      await onUpdateCategory(editingCategory.id, editedName);
      setEditingCategory(null);
      setEditedName('');
    }
  };

  const handleEditCancel = () => {
    setEditingCategory(null);
    setEditedName('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Kategorien verwalten</DialogTitle>

      <DialogContent>
        {/* Neue Kategorie anlegen */}
        <Box sx={{ mt: 1, mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Neue Kategorie erstellen
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              label="Name der neuen Kategorie"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              size="small"
              fullWidth
            />
            <Button variant="contained" color="primary" onClick={handleCreate}>
              Erstellen
            </Button>
          </Stack>
        </Box>

        {/* Bestehende Kategorien */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((cat) => (
              <TableRow key={cat.id}>
                <TableCell>
                  {editingCategory && editingCategory.id === cat.id ? (
                    <TextField
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      fullWidth
                      size="small"
                    />
                  ) : (
                    cat.name
                  )}
                </TableCell>
                <TableCell>
                  {editingCategory && editingCategory.id === cat.id ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditSave}
                        sx={{ mr: 1 }}
                      >
                        Speichern
                      </Button>
                      <Button onClick={handleEditCancel}>Abbrechen</Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(cat)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => onDeleteCategory(cat.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogKategorien;
