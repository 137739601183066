import Layout from "../../../components/layout/Tagesreport/TagesreportLayout";
import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import VerkaufsgebietFilterSelection from "../../../components/tagesreport/VerkaufsgebietFilterSelection";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import axiosInstance from "../../../services/axiosInstance";
import {
  roundToDecimals,
  formatPercentageWithoutMultiplier,
  formatCurrency,
  tausenderTrennung,
} from "../../../utils/mathStuff";
import BigBarChartHorizontalCard from "../../../components/card/BigBarChartHorizontalCard";
import BigTableCard from "../../../components/card/BigTableCard";
import { useTheme } from "@mui/material/styles";
import TagesreportRetoureWarengruppeDetail from "../../../components/popup/TagesreportRetoureWarengruppeDetail";
import TagesreportDetailsPopup from "../../../components/popup/TagesreportDetailsPopup";
import PageHeader from "../../../components/layout/Title/TitelSmall";
import BarChartIcon from "@mui/icons-material/BarChart";
import { getLast6WeeksSameWeekday } from "../../../utils/DateUtils";

// NEU: Button-Filter-Komponente für das schnelle Datumswählen
import ButtonGroupFilter from "../../../components/filter/ButtonGroupFilter";

const Retouren = () => {
  const theme = useTheme();

  // Datum-State
  const [Datum, setDatum] = useState(dayjs().subtract(1, "day").format("YYYY-MM-DD"));

  // NEU: State für Datumsauswahl-Optionen (z. B. letzte 4 Tage)
  const [datumOptions, setDatumOptions] = useState([]);

  // Daten für untere Tabelle (Filialen) aus /fact/kundenstatistik
  const [kundenStatistikData, setKundenStatistikData] = useState([]);

  // Daten für obere Tabelle (Warengruppe) aus /fact/facts
  const [warengruppeData, setWarengruppeData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [selectedFiliale, setSelectedFiliale] = useState(null);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  // Summen/Fußzeile
  const [totals, setTotals] = useState({
    Umsatz: 0,
    Umsatz6WSchnitt: 0,
    Retourquote: 0,
    Retourmengen: 0,
    Retourwert: 0,
  });

  // Warengruppe-Detail-Popup
  const [warengruppePopupOpen, setWarengruppePopupOpen] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);

  const date = new Date(Datum);
  const dayName = date.toLocaleDateString("de-DE", { weekday: "long" });

  // Hilfsfunktion, um z. B. die letzten 4 Tage als Optionen zu generieren
  const generateLast4DaysOptions = () => {
    const options = [];
    for (let i = 1; i <= 4; i++) {
      const d = dayjs().subtract(i, "day").format("YYYY-MM-DD");
      options.push({
        label: dayjs(d).format("DD.MM"),
        value: d,
      });
    }
    return options;
  };

  useEffect(() => {
    // Verkaufsgebiete laden
    fetchVerkaufsgebietOptions();
    // Datumsauswahl-Optionen setzen
    setDatumOptions(generateLast4DaysOptions());
  }, []);

  // Bei jeder Änderung von Datum oder Filter: Daten neu laden
  useEffect(() => {
    fetchData();
  }, [Datum, verkaufsgebietFilter]);

  // Verkaufsgebiete
  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get("/stammdaten/unique-verkaufsgebiet/");
      setVerkaufsgebietOptions(response.data.map((item) => ({ label: item, value: item })));
    } catch (error) {
      console.error("Error while fetching VerkaufsgebietOptions", error);
    }
  };

  // Haupt-Fetch-Funktion
  const fetchData = async () => {
    const formattedDatum = dayjs(Datum).format("YYYY-MM-DD");
    const letzten6WochenDatum = getLast6WeeksSameWeekday(Datum);

    try {
      setLoading(true);

      // --- 1) Warengruppen-Daten aus /fact/facts (für obere Tabelle + BarChart) ---
      const warengruppeResponse = await axiosInstance.get("/fact/facts/", {
        params: {
          startdatum: formattedDatum,
          enddatum: formattedDatum,
          verkaufsgebiet: verkaufsgebietFilter?.value,
        },
      });

      // warengruppeData aufbereiten
      if (Array.isArray(warengruppeResponse.data.results)) {
        const groupedWarengruppeData = warengruppeResponse.data.results.reduce((acc, item) => {
          const warengruppe = item.warengruppe;
          if (!acc[warengruppe]) {
            acc[warengruppe] = {
              Warengruppe: warengruppe,
              Retourmenge: 0,
              Retourwert: 0,
              Lieferwert: 0,
              Korrekturwert: 0,
            };
          }
          acc[warengruppe].Retourmenge += item.retourmenge || 0;
          acc[warengruppe].Retourwert += item.retourwert || 0;
          acc[warengruppe].Lieferwert += item.lieferwert || 0;
          acc[warengruppe].Korrekturwert += item.korrekturwert || 0;
          return acc;
        }, {});

        const mappedWarengruppeData = Object.values(groupedWarengruppeData)
          .filter((item) => item.Retourmenge > 0)
          .map((item) => ({
            Warengruppe: item.Warengruppe,
            Retourmenge: item.Retourmenge,
            formatiertRetourmenge: tausenderTrennung(item.Retourmenge),
            Retourwert: roundToDecimals(item.Retourwert, 2),
            formatiertRetourwert: formatCurrency(item.Retourwert, 2),
            Retourquote: formatPercentageWithoutMultiplier(
              (item.Retourwert / (item.Lieferwert + item.Korrekturwert)) * 100
            ),
          }));

        setWarengruppeData(mappedWarengruppeData);

        // Zusätzliche Summen für Warengruppe
        setTotals((prevTotals) => ({
          ...prevTotals,
          Retourmengen: roundToDecimals(
            mappedWarengruppeData.reduce((acc, item) => acc + item.Retourmenge, 0),
            2
          ),
          Retourwert: roundToDecimals(
            mappedWarengruppeData.reduce((acc, item) => acc + item.Retourwert, 0),
            2
          ),
        }));
      }

      // --- 2) Filial-Daten aus /fact/kundenstatistik (für untere Tabelle) ---
      // 2a) Holen der Daten für den aktuellen Tag + der letzten 6 Wochen parallel
      const [kundenStatistikResponse, ...last6WeeksResponses] = await Promise.all([
        axiosInstance.get("/fact/kundenstatistik/", {
          params: {
            startdatum: formattedDatum,
            enddatum: formattedDatum,
            verkaufsgebiet: verkaufsgebietFilter?.value,
          },
        }),
        ...letzten6WochenDatum.map((weekDate) =>
          axiosInstance.get("/fact/kundenstatistik/", {
            params: {
              startdatum: dayjs(weekDate).format("YYYY-MM-DD"),
              enddatum: dayjs(weekDate).format("YYYY-MM-DD"),
              verkaufsgebiet: verkaufsgebietFilter?.value,
            },
          })
        ),
      ]);

      // 2b) Datenverarbeitung für Filialen
      if (Array.isArray(kundenStatistikResponse.data.results)) {
        // Alle Datensätze der letzten 6 Wochen in ein Array packen
        const last6WeeksFacts = last6WeeksResponses.flatMap((res) => res.data.results);

        // Alle Filial-Einträge gruppieren
        const groupedData = kundenStatistikResponse.data.results.reduce((acc, item) => {
          const filiale = item.filiale;
          if (!acc[filiale]) {
            acc[filiale] = {
              Filiale: filiale,
              Umsatz: 0,
              Umsatz6WSchnitt: 0,
              Retourwert: item.retourwert || 0, // Falls vorhanden
              Lieferwert: item.lieferwert || 0,
              Korrekturwert: item.korrekturwert || 0,
            };
          }
          acc[filiale].Umsatz += item.umsatz || 0;
          // Optional: Retourwerte addieren, falls in kundenstatistik vorhanden
          acc[filiale].Retourwert += item.retourwert || 0;
          acc[filiale].Lieferwert += item.lieferwert || 0;
          acc[filiale].Korrekturwert += item.korrekturwert || 0;
          return acc;
        }, {});

        // 6-Wochen-Umsatz pro Filiale ermitteln
        last6WeeksFacts.forEach((item) => {
          const filiale = item.filiale;
          if (groupedData[filiale]) {
            groupedData[filiale].Umsatz6WSchnitt += item.umsatz || 0;
          }
        });

        // Durchschnitt berechnen
        Object.keys(groupedData).forEach((filiale) => {
          groupedData[filiale].Umsatz6WSchnitt = roundToDecimals(
            groupedData[filiale].Umsatz6WSchnitt / letzten6WochenDatum.length,
            2
          );
        });

        // Objekt in Array mappen
        const mappedKundenStatistikData = Object.values(groupedData).map((item) => ({
          Filiale: item.Filiale,
          Umsatz: roundToDecimals(item.Umsatz, 2),
          formatiertUmsatz: formatCurrency(item.Umsatz),
          Umsatz6WSchnitt: item.Umsatz6WSchnitt,
          formatiertUmsatz6WSchnitt: formatCurrency(item.Umsatz6WSchnitt),
          // Retourquote aus den Werten, falls benötigt:
          Retourquote: item.Lieferwert + item.Korrekturwert
            ? `${roundToDecimals(
                (item.Retourwert / (item.Lieferwert + item.Korrekturwert)) * 100,
                2
              )} %`
            : "0 %",
        }));

        // State für Filialen setzen
        setKundenStatistikData(mappedKundenStatistikData);

        // Summen aktualisieren (Umsatz, etc.)
        setTotals((prevTotals) => ({
          ...prevTotals,
          Umsatz: roundToDecimals(
            mappedKundenStatistikData.reduce((acc, item) => acc + item.Umsatz, 0),
            2
          ),
          Umsatz6WSchnitt: roundToDecimals(
            mappedKundenStatistikData.reduce((acc, item) => acc + item.Umsatz6WSchnitt, 0),
            2
          ),
          Retourquote: roundToDecimals(
            mappedKundenStatistikData.reduce(
              (acc, item) => acc + parseFloat(item.Retourquote),
              0
            ) / mappedKundenStatistikData.length,
            2
          ),
        }));
      }
    } catch (error) {
      console.error("Error while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  // Spaltenkonfiguration für die Filialen (untere Tabelle)
  const kundenStatistikColumns = [
    { accessorKey: "Filiale", header: "Filiale", footer: "Gesamt", size: 200 },
    {
      accessorKey: "formatiertUmsatz",
      header: "Umsatz",
      footer: formatCurrency(totals.Umsatz),
      size: 170,
    },
    {
      accessorKey: "formatiertUmsatz6WSchnitt",
      header: "Umsatz 6W Schnitt",
      footer: formatCurrency(totals.Umsatz6WSchnitt),
      size: 170,
    },
    {
      accessorKey: "Retourquote",
      header: "Retourquote",
      footer: `${totals.Retourquote}%`,
      size: 170,
    },
  ];

  // Spaltenkonfiguration für die Warengruppen (obere Tabelle)
  const warengruppeColumns = [
    { accessorKey: "Warengruppe", header: "Warengruppe", footer: "", size: 200 },
    {
      accessorKey: "formatiertRetourmenge",
      header: "Retourmenge",
      footer: totals.Retourmengen,
      size: 170,
    },
    {
      accessorKey: "formatiertRetourwert",
      header: "Retourwert",
      footer: formatCurrency(totals.Retourwert),
      size: 170,
    },
    {
      accessorKey: "Retourquote",
      header: "Retourquote",
      footer: `${totals.Retourquote}%`,
      size: 170,
    },
  ];

  // Daten für den horizontalen BarChart
  const chartData = {
    labels: warengruppeData.map((item) => item.Warengruppe),
    data: warengruppeData.map((item) => parseFloat(item.Retourquote)),
  };

  // Klick auf Warengruppe-Zeile
  const handleWarengruppeClick = (warengruppe) => {
    setSelectedWarengruppe(warengruppe);
    setWarengruppePopupOpen(true);
  };

  // Klick auf Filialen-Zeile (untere Tabelle)
  const handleRowClick = (row) => {
    const { Filiale } = row;
    setSelectedFiliale({ Datum, Filiale });
    setPopupOpen(true);
  };

  return (
    <Layout>
      <Container
        maxWidth={false}
        sx={{ padding: "0 16px", width: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* Kopfzeile + Datums-ButtonGroup */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <PageHeader
            title={`Retoure vom ${dayjs(Datum).format('dddd, D. MMMM YYYY')}`}
            subtitle="Übersicht über die Retourwerte für den ausgewählten Tag."
            Icon={BarChartIcon}
          />

          {/* ButtonGroupFilter für Datumsauswahl */}
          <ButtonGroupFilter
            options={datumOptions}
            value={{ value: Datum }}
            onChange={(option) => {
              if (option) {
                setDatum(option.value);
              }
            }}
          />
        </Box>

        {/* Verkaufsgebiets-Filter */}
        <VerkaufsgebietFilterSelection
          verkaufsgebietFilter={verkaufsgebietFilter}
          setVerkaufsgebietFilter={setVerkaufsgebietFilter}
          verkaufsgebietOptions={verkaufsgebietOptions}
        />

        <br />
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "50vh" }}
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {/* OBERE TABELLE: Warengruppen */}
            <Grid item xs={12} md={6} sm={12}>
              <BigTableCard
                columns={warengruppeColumns}
                data={warengruppeData}
                sx={{ width: "100%" }}
                onRowClick={(row) => handleWarengruppeClick(row.Warengruppe)}
              />
            </Grid>

            {/* Horizontaler BarChart */}
            <Grid item xs={12} md={6}>
              <BigBarChartHorizontalCard
                title="Retouren im Warengruppenvergleich"
                labels={chartData.labels}
                data={chartData.data}
              />
            </Grid>

            {/* UNTERE TABELLE: Filialen (aus /fact/kundenstatistik) */}
            <Grid item xs={12}>
              <BigTableCard
                columns={kundenStatistikColumns}
                data={kundenStatistikData}
                sx={{ width: "100%" }}
                onRowClick={handleRowClick}
              />
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Popup: Filiale (untere Tabelle) */}
      <TagesreportDetailsPopup
        open={popupOpen}
        handleClose={() => setPopupOpen(false)}
        filialeData={selectedFiliale}
      />

      {/* Popup: Warengruppe (obere Tabelle) */}
      {selectedWarengruppe && (
        <TagesreportRetoureWarengruppeDetail
          open={warengruppePopupOpen}
          handleClose={() => setWarengruppePopupOpen(false)}
          warengruppe={selectedWarengruppe}
          datum={Datum}
          verkaufsgebiet={verkaufsgebietFilter?.value}
        />
      )}
    </Layout>
  );
};

export default Retouren;
