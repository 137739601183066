import React, { useEffect, useState } from 'react';
import Layout from "../../../components/layout/Tagesreport/TagesreportLayout";
import { Box, Container, Grid } from "@mui/material";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import {
    getLast6WeeksSameWeekday,
    getSameWeekdayOneYearBack
} from "../../../utils/DateUtils";
import {
    calculateTotal,
    calculateTotalAverage,
    roundToDecimals,
    calculatePercentageDifference,
    tausenderTrennung
} from "../../../utils/mathStuff";
import VerkaufsgebietFilterSelection from "../../../components/tagesreport/VerkaufsgebietFilterSelection";
import DataCard from "../../../components/card/TagesreportDataCard";
import { useTheme } from '@mui/material/styles';
import BigTableCard from "../../../components/card/BigTableCard";
import TagesreportDetailsPopup from "../../../components/popup/TagesreportDetailsPopup";
import ButtonGroupFilter from "../../../components/filter/ButtonGroupFilter";
import axiosInstance from "../../../services/axiosInstance";
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';

const Overview = () => {
    const theme = useTheme();
    const [datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
    const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
    const [last6WeeksData, setLast6WeeksData] = useState([]);
    const [selectedFiliale, setSelectedFiliale] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [greeting, setGreeting] = useState('');
    const [userName, setUserName] = useState('');
    const [datumOptions, setDatumOptions] = useState([]);

    const letzten6WochenDatum = getLast6WeeksSameWeekday(datum).reverse();

    const handleRowClick = (row) => {
        const { Datum, Filiale } = row;
        setSelectedFiliale({ Datum, Filiale });
        setPopupOpen(true);
    };

    const setGreetingMessage = () => {
        const currentHour = dayjs().hour();
        if (currentHour < 12) {
            setGreeting('Guten Morgen');
        } else if (currentHour < 18) {
            setGreeting('Hallo');
        } else {
            setGreeting('Guten Abend');
        }
    };

    const generateLast4DaysOptions = () => {
        const options = [];
        for (let i = 1; i <= 4; i++) {
            const date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
            options.push({
                label: dayjs(date).format('DD.MM'),
                value: date
            });
        }
        return options;
    };

    useEffect(() => {
        setGreetingMessage();
        fetchCurrentUser();
        fetchVerkaufsgebietOptions();
        setDatumOptions(generateLast4DaysOptions());
        fetchData();
        // eslint-disable-next-line
    }, [datum]);

    useEffect(() => {
        fetchVerkaufsgebietOptions();
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [datum, verkaufsgebietFilter]);

    // Aktuellen User auslesen
    const fetchCurrentUser = async () => {
        try {
            const response = await axiosInstance.get("auth/current_user/");
            if (response.data && response.data.username) {
                setUserName(response.data.username);
            }
        } catch (error) {
            console.error("Fehler beim Laden des Benutzers:", error);
        }
    };

    // Verkaufsgebiete laden
    const fetchVerkaufsgebietOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
            setVerkaufsgebietOptions(
                response.data.map(item => ({ label: item, value: item }))
            );
        } catch (error) {
            console.error('Error while fetching VerkaufsgebietOptions', error);
        }
    };

    // Daten laden
    const fetchData = async () => {
        const formattedDatum = dayjs(datum).format('YYYY-MM-DD');
        try {
            setLoading(true);

            // API Calls für den aktuellen Tag + die letzten 6 Wochen
            const [response, ...last6WeeksResponses] = await Promise.all([
                axiosInstance.get("/fact/kundenstatistik/", {
                    params: {
                        startdatum: formattedDatum,
                        enddatum: formattedDatum,
                        mindestKunden: 1,
                        verkaufsgebiet: verkaufsgebietFilter?.value,
                    },
                }),
                ...letzten6WochenDatum.map(date =>
                    axiosInstance.get("/fact/kundenstatistik/", {
                        params: {
                            startdatum: date,
                            enddatum: date,
                            mindestKunden: 1,
                            verkaufsgebiet: verkaufsgebietFilter?.value,
                        },
                    })
                ),
            ]);

            // Daten der letzten 6 Wochen flach zusammengefasst
            const last6WeeksResult = last6WeeksResponses.flatMap(res => res.data.results);
            setLast6WeeksData(last6WeeksResult);

            // Erzeuge ein Objekt mit allen 6-Wochen-Datensätzen pro Filiale
            const filialeDataMap = {};
            last6WeeksResult.forEach(item => {
                // Hier auf item.filiale achten (falls die API-Eigenschaft filiale heißt)
                const filiale = item.filiale;

                if (!filialeDataMap[filiale]) {
                    filialeDataMap[filiale] = [];
                }
                filialeDataMap[filiale].push({
                    datum: item.datum,
                    umsatz: item.umsatz,
                    kundenanzahl: item.kundenanzahl,
                    lieferwert: item.lieferwert,
                    retourwert: item.retourwert,
                    korrekturwert: item.korrekturwert,
                });
            });

            // Umwandeln in ein Array, damit wir pro Filiale ein Objekt mit data bekommen
            const filialeDataArray = Object.keys(filialeDataMap).map(filiale => ({
                filiale: filiale,
                data: filialeDataMap[filiale],
            }));

            // Ausgabe in der Konsole
            console.log("6-Wochen-Daten pro Filiale:", filialeDataArray);

            // Verarbeite die Daten vom aktuellen Tag
            if (Array.isArray(response.data.results)) {
                const mappedData = response.data.results.map(item => {
                    const VJ_Umsatz = item.umsatzVJ ?? 0;
                    const VJ_Kunden = item.kundenanzahlVJ ?? 0;
                    const Planumsatz = item.planumsatz ?? 0;

                    const UmsatzDiff = calculatePercentageDifference(item.umsatz, VJ_Umsatz);
                    const KundenDiff = calculatePercentageDifference(item.kundenanzahl, VJ_Kunden);
                    const PlanumsatzDiff = calculatePercentageDifference(item.umsatz, Planumsatz);

                    // 6-Wochen-Durchschnitt anhand unserer Map
                    const filiale6WData = filialeDataMap[item.filiale] || [];
                    const filteredData = filiale6WData.filter(d => d.umsatz > 0);
                    const totalUmsatz6W = filteredData.reduce((acc, d) => acc + d.umsatz, 0);
                    const avgUmsatz6W = filteredData.length ? totalUmsatz6W / filteredData.length : 0;

                    const Umsatz6WDiff = calculatePercentageDifference(item.umsatz, avgUmsatz6W);
                    const Stundenleistung = roundToDecimals(item.umsatz / (item.produktivstunden || 1), 2);

                    return {
                        Datum: item.datum,
                        Filiale: item.filiale,
                        Umsatz: item.umsatz,
                        Planumsatz: Planumsatz,
                        PlanumsatzDiff: `${tausenderTrennung(PlanumsatzDiff, 2)} %`,
                        formatiertUmsatz: tausenderTrennung(item.umsatz, 2),
                        DurchschnittUmsatz6W: avgUmsatz6W,
                        DurchschnittUmsatz6W_Diff: `${tausenderTrennung(Umsatz6WDiff, 2)} %`,
                        VJ_Umsatz: VJ_Umsatz,
                        VJ_Umsatz_Diff: `${tausenderTrennung(UmsatzDiff, 2)} %`,
                        Kunden: item.kundenanzahl,
                        VJ_Kunden: VJ_Kunden,
                        VJ_Kunden_Diff: `${tausenderTrennung(KundenDiff, 2)} %`,
                        Euro_h: roundToDecimals(Stundenleistung, 0),
                        h: roundToDecimals(item.produktivstunden, 0),
                        Euro_Kunde: tausenderTrennung(item.umsatz / item.kundenanzahl, 2),
                        Retourquote: `${tausenderTrennung(calculateRetourquote(item) * 100, 2)} %`,
                        Lieferwert: item.lieferwert,
                        Retourwert: item.retourwert,
                        Korrekturwert: item.korrekturwert,
                    };
                });
                setData(mappedData);
            } else {
                console.error('Unexpected response structure for current data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateRetourquote = (item) => {
        const { retourwert = 0, lieferwert = 0, korrekturwert = 0 } = item;
        const totalWert = lieferwert + korrekturwert;
        return totalWert > 0 ? retourwert / totalWert : 0;
    };

    // Summenberechnungen
    const totalUmsatz = roundToDecimals(calculateTotal(data, 'Umsatz'), 0);
    const totalPlanumsatz = roundToDecimals(calculateTotal(data, 'Planumsatz'), 0);
    const totalVorjahresUmsatz = roundToDecimals(calculateTotal(data, 'VJ_Umsatz'), 0);
    const totalVorjahresUmsatzDiff = calculatePercentageDifference(totalUmsatz, totalVorjahresUmsatz);
    const totalPlanumsatzDiff = calculatePercentageDifference(totalUmsatz, totalPlanumsatz);
    const totalKunden = roundToDecimals(calculateTotal(data, 'Kunden'), 0);
    const totalVorjahresKundenDiff = calculatePercentageDifference(
        totalKunden,
        calculateTotal(data, 'VJ_Kunden')
    );
    const totalAverageUmsatz6W = roundToDecimals(calculateTotal(data, 'DurchschnittUmsatz6W'), 0);
    const totalAverageUmsatz6WDiff = calculatePercentageDifference(totalUmsatz, totalAverageUmsatz6W);
    const totalStunden = roundToDecimals(calculateTotal(data, 'h'), 0);
    const totalStundenleistung = roundToDecimals(totalUmsatz / totalStunden);
    const totalLieferwert = roundToDecimals(calculateTotal(data, 'Lieferwert'), 0);
    const totalRetourwert = roundToDecimals(calculateTotal(data, 'Retourwert'), 0);
    const totalKorrekturwert = roundToDecimals(calculateTotal(data, 'Korrekturwert'), 0);
    const totalRetourquote = totalRetourwert / (totalLieferwert + totalKorrekturwert);

    // Berechnungen für die Charts (z.B. letzte 6 Wochen)
    const calculateTotalsForLast6Weeks = (data, metrics) => {
        const totals = metrics.reduce((acc, metric) => {
            acc[metric] = [];
            return acc;
        }, {});

        letzten6WochenDatum.forEach((date) => {
            metrics.forEach((metric) => {
                const total = data.reduce((acc, item) => {
                    if (dayjs(item.datum).startOf('day').isSame(dayjs(date).startOf('day'))) {
                        return acc + (item[metric] || 0);
                    }
                    return acc;
                }, 0);
                totals[metric].push({ total });
            });
        });

        // Aktueller Tag wird hinten angehängt
        totals['umsatz'].push({ total: totalUmsatz });
        totals['kundenanzahl'].push({ total: totalKunden });
        totals['korrekturwert'].push({ total: totalKorrekturwert });
        totals['lieferwert'].push({ total: totalLieferwert });
        totals['retourwert'].push({ total: totalRetourwert });
        totals['produktivstunden'].push({ total: totalStunden });

        // Stundenleistung
        totals['stundenleistung'] = totals['umsatz'].map((umsatzItem, index) => {
            const produktivstundenItem = totals['produktivstunden'][index];
            const stundenleistung = produktivstundenItem.total !== 0
                ? umsatzItem.total / produktivstundenItem.total
                : 0;
            return { total: stundenleistung };
        });

        // Retourquote
        totals['retourquote'] = totals['retourwert'].map((retourwertItem, index) => {
            const lieferwertItem = totals['lieferwert'][index];
            const korrekturwertItem = totals['korrekturwert'][index];
            const sum = lieferwertItem.total + korrekturwertItem.total;
            const retourquote = sum !== 0
                ? retourwertItem.total / sum
                : 0;
            return { total: retourquote };
        });

        return totals;
    };

    const metrics = [
        'umsatz',
        'kundenanzahl',
        'produktivstunden',
        'lieferwert',
        'retourwert',
        'korrekturwert'
    ];
    const totalsLast6Weeks = calculateTotalsForLast6Weeks(last6WeeksData, metrics);

    // Prüfe, ob Plan-Daten vorhanden sind
    const hasPlanData = data.some(item => {
        const numericVal = parseFloat(item.PlanumsatzDiff);
        return !isNaN(numericVal) && numericVal !== 0;
    });

    // Spalten-Definition für die BigTableCard
    const columns = [
        { accessorKey: 'Filiale', header: 'Filiale' },
        { accessorKey: 'formatiertUmsatz', header: 'Umsatz (€)', minSize: 1 },
        ...(hasPlanData ? [
            { accessorKey: 'PlanumsatzDiff', header: 'Plan', minSize: 1 }
        ] : []),
        { accessorKey: 'DurchschnittUmsatz6W_Diff', header: 'ø6W ', minSize: 1 },
        { accessorKey: 'VJ_Umsatz_Diff', header: 'VJ ', minSize: 1 },
        { accessorKey: 'Kunden', header: 'Kunden', minSize: 1 },
        { accessorKey: 'VJ_Kunden_Diff', header: 'VJ ', minSize: 1 },
        { accessorKey: 'Euro_h', header: '€/h (€)', minSize: 1 },
        { accessorKey: 'h', header: 'h', minSize: 1 },
        { accessorKey: 'Euro_Kunde', header: '€/Kunde (€)', minSize: 1 },
        {
            accessorKey: 'Retourquote',
            header: 'Retourquote',
            minSize: 1,
            cell: ({ cell }) => `${roundToDecimals(cell.getValue() * 100, 2)} %`
        },
    ];

    // Werte für den Tabellen-Footer
    const footerValues = {
        'Filiale': 'Gesamt',
        'Umsatz': roundToDecimals(totalUmsatz, 0),
        'DurchschnittUmsatz6W_Diff': roundToDecimals(totalAverageUmsatz6WDiff, 2),
        'VJ_Umsatz_Diff': roundToDecimals(totalVorjahresUmsatzDiff, 2),
        'Kunden': roundToDecimals(totalKunden, 0),
        'VJ_Kunden_Diff': roundToDecimals(totalVorjahresKundenDiff, 2),
        'Euro_h': roundToDecimals(calculateTotalAverage(data, 'Euro_h'), 2),
        'h': roundToDecimals(calculateTotal(data, 'h'), 0),
        'Euro_Kunde': roundToDecimals(calculateTotalAverage(data, 'Euro_Kunde'), 2),
        'RetourquoteTest': `${roundToDecimals(calculateTotalAverage(data, 'Retourquote') * 100, 2)} %`,
    };

    // Daten für die Stundenleistungs-Kachel
    const stundenleistungData = [
        {
            datum: dayjs(datum).format('YYYY-MM-DD'),
            total: roundToDecimals(totalStundenleistung, 2),
        },
        ...letzten6WochenDatum.map((date, index) => ({
            datum: dayjs(date).format('YYYY-MM-DD'),
            total: roundToDecimals(totalsLast6Weeks.stundenleistung[index].total, 2),
        })),
    ].sort((a, b) => new Date(a.datum) - new Date(b.datum));

    return (
        <Layout>
            <Container maxWidth={false} sx={{ padding: '0 16px' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                    <PageHeader
                        title={`Tagesreport vom ${dayjs(datum).format('dddd, D. MMMM YYYY')}`}
                        subtitle={`${greeting}, ${userName}! Hier der Tagesreport für den ${dayjs(datum).format('dddd, D. MMMM YYYY')}`}
                        Icon={BarChartIcon}
                    />
                    
                    {/* ButtonGroupFilter zum schnellen Umschalten des Datums */}
                    <ButtonGroupFilter
                        options={datumOptions}
                        value={{ value: datum }}
                        onChange={(option) => setDatum(option ? option.value : datum)}
                    />
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item md={3} sm={6}>
                            <DataCard
                                chartData={totalsLast6Weeks.umsatz.map(item => item.total)}
                                category="Umsatz"
                                value={`${tausenderTrennung(totalUmsatz)}€`}
                                subHeader={`${roundToDecimals(totalVorjahresUmsatzDiff, 2)}%`}
                            />
                        </Grid>
                        <Grid item md={3} sm={6}>
                            <DataCard
                                chartData={totalsLast6Weeks.kundenanzahl.map(item => item.total)}
                                category="Kundenanzahl"
                                value={tausenderTrennung(totalKunden)}
                                subHeader={`${roundToDecimals(totalVorjahresKundenDiff, 2)}%`}
                            />
                        </Grid>
                        <Grid item md={3} sm={6}>
                            <DataCard
                                chartData={stundenleistungData.map(item => item.total)}
                                category="Stundenleistung"
                                value={roundToDecimals(totalStundenleistung, 2)}
                            />
                        </Grid>
                        <Grid item md={3} sm={6}>
                            <DataCard
                                chartData={totalsLast6Weeks.retourquote.map(item => item.total)}
                                category="Retourquote"
                                value={`${roundToDecimals(totalRetourquote * 100, 2)}%`}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <br />
                <VerkaufsgebietFilterSelection
                    verkaufsgebietFilter={verkaufsgebietFilter}
                    setVerkaufsgebietFilter={setVerkaufsgebietFilter}
                    verkaufsgebietOptions={verkaufsgebietOptions}
                />
                <br />
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh'
                        }}
                    >
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <BigTableCard
                        title="Filialübersicht"
                        data={data}
                        columns={columns}
                        onRowClick={handleRowClick}
                        footerValues={footerValues}
                    />
                )}

                <TagesreportDetailsPopup
                    open={popupOpen}
                    handleClose={() => setPopupOpen(false)}
                    filialeData={selectedFiliale}
                />
            </Container>
        </Layout>
    );
};

export default Overview;
