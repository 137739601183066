import React from 'react';
import { Grid, Box } from '@mui/material';
import QualitaetFiliale from '../../../components/qualitaet/QualitaetFiliale';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import ChecklistIcon from '@mui/icons-material/Checklist';  // Import des Icons


const Index = () => {
    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Qualität" 
                    subtitle="
Verwende dieses Modul, um Qualitätsmeldungen für deine Produkte schnell und strukturiert zu erfassen. So könnt ihr gemeinsam an Verbesserungen arbeiten und sicherstellen, dass alle Qualitätsziele in der Filiale erreicht werden."
                    Icon={ChecklistIcon}  // Übergabe des Icons
                />
                
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={12} md={8}>
                        <QualitaetFiliale />
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default Index;
