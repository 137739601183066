import React from 'react';
import GeneralLayout from '../GeneralLayout';
import Sidebar from '../GeneralSidebar';
import WindowIcon from '@mui/icons-material/Window';
import TimelineIcon from '@mui/icons-material/Timeline';
import Euro from '@mui/icons-material/Euro';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import AutoGraph from '@mui/icons-material/AutoGraph';

const UnternehmenWocheLayout = ({ children }) => {
  const menuItems = [
    {
      text: 'Übersicht',
      path: '/unternehmenwoche/overview/cockpit',
      icon: <WindowIcon sx={{ color: 'primary.main' }} />,
      subModule: "overview",
    },
    {
      text: 'Performance',
      icon: <TimelineIcon sx={{ color: 'primary.main' }} />,
      subModule: "performance",
      subItems: [
        { text: 'Umsatz', path: '/unternehmenwoche/performance/umsatz', icon: <Euro sx={{ color: 'primary.main' }} /> },
        { text: 'Kunden', path: '/unternehmenwoche/performance/kundenanzahl', icon: <ShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Kundenbon', path: '/unternehmenwoche/performance/kundenbon', icon: <AddShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Leistung', path: '/unternehmenwoche/performance/leistung', icon: <Timer sx={{ color: 'primary.main' }} /> },
      ],
    },
    {
      text: 'Ware',
      icon: <BakeryDiningIcon sx={{ color: 'primary.main' }} />,
      subModule: "ware",
      subItems: [
        { text: 'Verkaufsmenge', path: '/unternehmenwoche/ware/verkaufsmenge', icon: <WindowIcon sx={{ color: 'primary.main' }} /> },
        { text: 'Retoure', path: '/unternehmenwoche/ware/retoure', icon: <AutoGraph sx={{ color: 'primary.main' }} /> },
      ],
    },
  ];

  return (
      <GeneralLayout
          SidebarComponent={(props) => (
              <Sidebar
                  {...props}
                  moduleName={"unternehmenwoche"}
                  menuItems={menuItems}
                  title="Scorecard Unternehmen Woche"
                  weekFilter={true}
                  bestehendeFlaecheSwitch={true}
              />
          )}
      >
        {children}
      </GeneralLayout>
  );
};

export default UnternehmenWocheLayout;
