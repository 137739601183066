// src/pages/controlling/datentagebuch/index.jsx

import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Paper,
  Box,
  Collapse,
  CardActions,
  IconButton,
} from '@mui/material';
import Layout from '../../../components/layout/datentagebuch/datentagebuchLayout';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';

// Filter / Utilities
import { FilterContext } from '../../../provider/FilterProvider';
import WochentagFilter from '../../../components/filter/WochentagFilter';
import { tausenderTrennung, formatCurrency } from '../../../utils/mathStuff';
import VariableTable from '../../../components/table/VariableTable';
import PageHeader from '../../../components/layout/Title/TitelSmall';

// Icons
import TimelineIcon from '@mui/icons-material/Timeline';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import GroupIcon from '@mui/icons-material/Group';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// Chart-/Card-Komponenten
import BigChartCard from '../../../components/card/scorecardsmonat/BigChartCardWithTable';
import DataCard from '../../../components/card/Datentagebuch/DataCardMonat';  // Sparkline-Karte
import BarChartCardFlexibleYAxis from '../../../components/card/scorecardsmonat/BarChartCardFlexibleYAxis';
import BigTableCard from '../../../components/card/BigTableCard';

// Popup & Co.
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche';
import WarengruppePopup from '../../../components/popup/datentagebuch/monat/retourePopupMonat';

// Filialfilter
import DropdownFilter from '../../../components/filter/DropdownFilter';

// Optional: deutsches Sprachpaket für dayjs
import 'dayjs/locale/de';

/** 
 * Liefert den letzten abgeschlossenen Monat (Format "YYYY.MM").
 */
function getLastCompletedMonth() {
  return dayjs().subtract(1, 'month').format('YYYY.MM');
}

/** 
 * Hilfsfunktionen, um Vormonat & Vorjahr zu ermitteln
 */
function getVormonatMonth(monthStr) {
  return dayjs(monthStr, 'YYYY.MM').subtract(1, 'month').format('YYYY.MM');
}
function getVorjahrMonth(monthStr) {
  return dayjs(monthStr, 'YYYY.MM').subtract(1, 'year').format('YYYY.MM');
}

/**
 * Hilfsfunktion, um die letzten X Monate (inkl. des aktuellen) in **aufsteigender** Reihenfolge
 * zu liefern (Index 0 = ältester, Index x-1 = aktuellster).
 * Beispiel: currentMonth="2023.09" => ["2023.04","2023.05","2023.06","2023.07","2023.08","2023.09"]
 */
function getLastXMonthsAsc(currentMonth, x = 6) {
  const arr = [];
  const start = dayjs(currentMonth, 'YYYY.MM');
  // Wir gehen rückwärts, aber fügen die Werte in aufsteigender Reihenfolge ein:
  for (let i = x - 1; i >= 0; i--) {
    arr.push(start.subtract(i, 'month').format('YYYY.MM'));
  }
  return arr;
}

/**
 * Hilfsfunktion, um die letzten X Monate (inkl. des aktuellen) 
 * in absteigender Reihenfolge zu holen und dann umzudrehen.
 */
function getLastXMonths(currentMonth, x = 6) {
  const lastMonths = [];
  const start = dayjs(currentMonth, 'YYYY.MM');
  for (let i = 0; i < x; i++) {
    lastMonths.push(start.subtract(i, 'month').format('YYYY.MM'));
  }
  return lastMonths.reverse();
}

/**
 * Helper: "Monate als Spalten" + 2 Zeilen (Aktuell, Vorjahr)
 * für die ExpandableCard-Tabellen
 */
function createMonthAsColumnsTable(perfArray, labelAktuell, labelVorjahr) {
  const monthsCols = perfArray.map((item) => item.month);
  const rowAktuell = { label: labelAktuell };
  const rowVorjahr = { label: labelVorjahr };

  perfArray.forEach((item) => {
    const valAktuell = item.umsatz ?? item.aktuell ?? 0;
    const valVorjahr = item.umsatzVJ ?? item.vorjahr ?? 0;
    rowAktuell[item.month] = tausenderTrennung(valAktuell, 0);
    rowVorjahr[item.month] = tausenderTrennung(valVorjahr, 0);
  });

  const columns = [
    { accessorKey: 'label', header: '' },
    ...monthsCols.map((m) => ({
      accessorKey: m,
      header: m,
    })),
  ];
  const data = [rowAktuell, rowVorjahr];
  return { columns, data };
}

/**
 * Minimale Render-Hilfsfunktion, um optional die "große" Chart + Tabelle 
 * im ExpandableCard-Collapse zu rendern
 */
function RenderCollapse({
  expandedState,
  chartData,
  title,
  info,
  tableColumns,
  tableData,
}) {
  return (
    <Collapse in={expandedState} timeout="auto" unmountOnExit>
      <Box sx={{ mt: 2 }}>
        <BigChartCard data={chartData} title={title} infoContent={info} />
        {tableColumns && tableData && (
          <Box sx={{ mt: 2 }}>
            <VariableTable columns={tableColumns} data={tableData} />
          </Box>
        )}
      </Box>
    </Collapse>
  );
}

/**
 * ExpandableCard (für PERFORMANCE-KARTEN => Umsatz, Kundenanzahl, Kundenbon, Leistung)
 */
function ExpandableCard({
  category,
  value,
  subHeaders,
  chartData,
  sparklineData,
  infoContent,
  expandedState,
  setExpandedState,
  titleForChart,
  monthlyTable,
}) {
  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
        mb: 2,
      }}
    >
      <CardContent>
        <DataCard
          category={category}
          value={value}
          subHeaders={subHeaders}
          chartData={sparklineData}
          onClick={() => setExpandedState((prev) => !prev)}
        />
      </CardContent>

      <RenderCollapse
        expandedState={expandedState}
        chartData={chartData}
        title={titleForChart}
        info={infoContent}

      />
    </Card>
  );
}

export default function Datentagebuch() {
  // ------------------------------------------------
  // STATES
  // ------------------------------------------------
  // Filial-Filter
  const [filialenOptions, setFilialenOptions] = useState([]);
  const [selectedFiliale, setSelectedFiliale] = useState(null);

  // Monats-Filter
  const [selectedMonth, setSelectedMonth] = useState(getLastCompletedMonth());

  // Wochentag-Filter
  const { filterState } = useContext(FilterContext);
  const [wochentagFilter, setWochentagFilter] = useState(null);
  const [wochentagOptions, setWochentagOptions] = useState([]);

  // Kundenstatistik-Tabelle
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  // PERFORMANCE-DATEN (Filiale & Monat)
  const [performanceData, setPerformanceData] = useState({
    umsatzSumme: 0,
    umsatzAbweichungVM: 0,
    umsatzAbweichung6M: 0,
    umsatzAbweichungVJ: 0,

    kundenanzahlSumme: 0,
    kundenanzahlAbweichungVM: 0,
    kundenanzahlAbweichung6M: 0,
    kundenanzahlAbweichungVJ: 0,

    kundenbon: 0,
    kundenbonVJ: 0,
    kundenbonAbweichungVM: 0,
    kundenbonAbweichung6M: 0,
    kundenbonAbweichungVJ: 0,

    leistung: 0,
    leistungVJ: 0,
    leistungAbweichungVM: 0,
    leistungAbweichung6M: 0,
    leistungAbweichungVJ: 0,
  });

  // 6-Monats-Daten (für Umsatz, Kunden, Bon, Leistung)
  const [performance6MUmsatz, setPerformance6MUmsatz] = useState([]);
  const [performance6MKunden, setPerformance6MKunden] = useState([]);
  const [performance6MKundenbon, setPerformance6MKundenbon] = useState([]);
  const [performance6MLeistung, setPerformance6MLeistung] = useState([]);

  // Expand/Collapse => PERFORMANCE-KARTEN
  const [expandedUmsatz, setExpandedUmsatz] = useState(false);
  const [expandedKundenanzahl, setExpandedKundenanzahl] = useState(false);
  const [expandedKundenbon, setExpandedKundenbon] = useState(false);
  const [expandedLeistung, setExpandedLeistung] = useState(false);

  // -----------------------------------
  // Retoure & Warengruppen => DataCard + Collapse
  // -----------------------------------
  // Sparkline
  const [retoureSparkline, setRetoureSparkline] = useState([]);
  const [warengruppenSparkline, setWarengruppenSparkline] = useState([]);

  // Retoure
  const [retoureQuoteAktuell, setRetoureQuoteAktuell] = useState(0);
  const [retoureQuoteVM, setRetoureQuoteVM] = useState(0);
  const [retoureQuoteVJ, setRetoureQuoteVJ] = useState(0);
  const [expandedRetoure, setExpandedRetoure] = useState(false);
  const [retoureChartData, setRetoureChartData] = useState([]);
  const [retoureLines, setRetoureLines] = useState([]);
  const [retoureTableData, setRetoureTableData] = useState([]);
  const [retoureCurrentMonth, setRetoureCurrentMonth] = useState(null);
  const [openWarengruppePopup, setOpenWarengruppePopup] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);

  // Warengruppen
  const [warengruppen6MValues, setWarengruppen6MValues] = useState([]);
  const [currentWarengruppenMenge, setCurrentWarengruppenMenge] = useState(0);
  const [expandedWarengruppen, setExpandedWarengruppen] = useState(false);
  const [warengruppenDevData, setWarengruppenDevData] = useState([]);
  const [wgTableData, setWgTableData] = useState([]);
  const [wgTableColumns, setWgTableColumns] = useState([]);
  const [wgMengeAktuell, setWgMengeAktuell] = useState(0);
  const [wgMengeVM, setWgMengeVM] = useState(0);
  const [wgMengeVJ, setWgMengeVJ] = useState(0);

  // Abweichungen Retoure/Warengruppen
  const retoureAbwVM = retoureQuoteVM !== 0
    ? ((retoureQuoteAktuell - retoureQuoteVM) / retoureQuoteVM) * 100
    : 0;
  const retoureAbwVJ = retoureQuoteVJ !== 0
    ? ((retoureQuoteAktuell - retoureQuoteVJ) / retoureQuoteVJ) * 100
    : 0;

  const warengruppeMengeVMAbw = wgMengeVM !== 0
    ? ((wgMengeAktuell - wgMengeVM) / wgMengeVM) * 100
    : 0;
  const warengruppeMengeVJAbw = wgMengeVJ !== 0
    ? ((wgMengeAktuell - wgMengeVJ) / wgMengeVJ) * 100
    : 0;

  // -----------------------------------
  // KRANKENTAGE => DataCard + Collapse
  // -----------------------------------
  const [krankentageSum, setKrankentageSum] = useState(0);
  const [krankentageSparkline, setKrankentageSparkline] = useState([]);
  const [expandedKrankentage, setExpandedKrankentage] = useState(false);
  const [krankentageDetail, setKrankentageDetail] = useState([]);

  // ------------------------------------------------
  // 1) Filialen laden
  // ------------------------------------------------
  useEffect(() => {
    axiosInstance
      .get('/stammdaten/unique-filiale/')
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data)) {
          const options = data.map((item) => ({
            name: item.filialnummer.toString(),
            label: item.filiale,
          }));
          setFilialenOptions(options);
        }
      })
      .catch((err) => console.error('Fehler beim Laden der Filial-Liste:', err));
  }, []);

  // ------------------------------------------------
  // Monat-Pfeile
  // ------------------------------------------------
  const handlePrevMonth = () => {
    const newMonth = dayjs(selectedMonth, 'YYYY.MM').subtract(1, 'month');
    setSelectedMonth(newMonth.format('YYYY.MM'));
  };
  const handleNextMonth = () => {
    const newMonth = dayjs(selectedMonth, 'YYYY.MM').add(1, 'month');
    setSelectedMonth(newMonth.format('YYYY.MM'));
  };

  // ------------------------------------------------
  // 2) Kundenstatistik-Tabelle
  // ------------------------------------------------
  useEffect(() => {
    if (selectedFiliale && selectedMonth) {
      fetchTableData();
    }
  }, [selectedFiliale, selectedMonth, wochentagFilter]);

  async function fetchTableData() {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/fact/kundenstatistik/', {
        params: {
          filiale: selectedFiliale?.label,
          filialnummer: selectedFiliale?.name,
          jahrMonat: selectedMonth,
          wochentag: wochentagFilter?.value,
        },
      });

      if (Array.isArray(response.data.results)) {
        const mappedData = response.data.results.map((item) => ({
          datum: dayjs(item.datum.split(' ')[0]).format('dddd, D. MMMM YYYY'),
          umsatz: tausenderTrennung(item.umsatz, 2),
          kunden: item.kundenanzahl,
          kundenbon:
            item.kundenanzahl > 0
              ? tausenderTrennung(item.umsatz / item.kundenanzahl, 2)
              : '0,00',
        }));
        setTableData(mappedData);
      }
    } catch (error) {
      console.error('Error while fetching table data:', error);
    } finally {
      setLoading(false);
    }
  }

  // ------------------------------------------------
  // 3) Wochentag-Options
  // ------------------------------------------------
  useEffect(() => {
    fetchWochentagOptions();
  }, []);

  async function fetchWochentagOptions() {
    try {
      const response = await axiosInstance.get('stammdaten/unique-wochentag/');
      const data = response.data;
      setWochentagOptions(data.map((item) => ({ label: item, value: item })));
    } catch (error) {
      console.error('Fehler beim Abrufen der Wochentag-Optionen:', error);
    }
  }
  const handleWochentagFilterChange = (newValue) => {
    setWochentagFilter(newValue);
  };

  // ------------------------------------------------
  // 4) Performance-Daten & 6-Monats
  // ------------------------------------------------
  useEffect(() => {
    if (selectedFiliale && selectedMonth) {
      fetchPerformanceData();
      fetchAll6Months();

      // Retoure & Warengruppen
      fetchRetoureAndWarengruppenData(selectedMonth);
      fetchRetoureAndWarengruppenVM();
      fetchRetoureAndWarengruppenVJ();
      fetchRetoureSparkline6M();
      fetchWarengruppenSparkline6M();
    }
  }, [selectedFiliale, selectedMonth]);

  /**
   * Holt den Datensatz (Performance) für die ausgewählte Filiale & Monat
   */
  async function fetchPerformanceData() {
    try {
      const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: selectedMonth,
        },
      });
      const allData = response.data.results || [];
      const single = allData.length > 0 ? allData[0] : {};

      setPerformanceData({
        // Umsatz
        umsatzSumme: single.umsatz ?? 0,
        umsatzAbweichungVM: single.umsatzWachstumM ?? 0,
        umsatzAbweichung6M: single.umsatzWachstum6M ?? 0,
        umsatzAbweichungVJ: single.umsatzWachstumJ ?? 0,

        // Kundenanzahl
        kundenanzahlSumme: single.kundenanzahl ?? 0,
        kundenanzahlAbweichungVM: single.kundenanzahlWachstumM ?? 0,
        kundenanzahlAbweichung6M: single.kundenanzahlWachstum6M ?? 0,
        kundenanzahlAbweichungVJ: single.kundenanzahlWachstumJ ?? 0,

        // Kundenbon
        kundenbon: single.kundenbon ?? 0,
        kundenbonVJ: single.kundenbonVJ ?? 0,
        kundenbonAbweichungVM: single.kundenbonVM
          ? (single.kundenbon / single.kundenbonVM) - 1
          : 0,
        kundenbonAbweichung6M: single.kundenbonWachstum6M ?? 0,
        kundenbonAbweichungVJ: single.kundenbonWachstumJ ?? 0,

        // Leistung
        leistung: single.leistung ?? 0,
        leistungVJ: single.leistungVJ ?? 0,
        leistungAbweichungVM: single.leistungVM
          ? (single.leistung / single.leistungVM) - 1
          : 0,
        leistungAbweichung6M: single.leistungWachstum6M ?? 0,
        leistungAbweichungVJ: single.leistungWachstumJ ?? 0,
      });
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  }

  /**
   * Holt die letzten 6 Monate => Performance-Daten
   */
  async function fetchAll6Months() {
    if (!selectedMonth || !selectedFiliale) return;
    const last6Months = getLastXMonths(selectedMonth, 6);

    try {
      const responses = await Promise.all(
        last6Months.map((m) =>
          axiosInstance.get('/scorecards_month/performance-monat/', {
            params: {
              filialnummer: selectedFiliale?.name,
              jahrMonat: m,
            },
          })
        )
      );

      const aggregatedUmsatz = [];
      const aggregatedKunden = [];
      const aggregatedKundenbon = [];
      const aggregatedLeistung = [];

      responses.forEach((resp, idx) => {
        const data = resp.data.results || [];
        const single = data.length > 0 ? data[0] : {};

        aggregatedUmsatz.push({
          month: last6Months[idx],
          umsatz: single.umsatz || 0,
          umsatzVJ: single.umsatzVJ || 0,
        });
        aggregatedKunden.push({
          month: last6Months[idx],
          aktuell: single.kundenanzahl || 0,
          vorjahr: single.kundenanzahlVJ || 0,
        });
        aggregatedKundenbon.push({
          month: last6Months[idx],
          aktuell: single.kundenbon || 0,
          vorjahr: single.kundenbonVJ || 0,
        });
        aggregatedLeistung.push({
          month: last6Months[idx],
          aktuell: single.leistung || 0,
          vorjahr: single.leistungVJ || 0,
        });
      });

      setPerformance6MUmsatz(aggregatedUmsatz);
      setPerformance6MKunden(aggregatedKunden);
      setPerformance6MKundenbon(aggregatedKundenbon);
      setPerformance6MLeistung(aggregatedLeistung);

    } catch (error) {
      console.error('Error fetching 6-month data:', error);
    }
  }

  // -----------------------------------
  // Retoure & Warengruppen => Sparkline
  // -----------------------------------
  async function fetchRetoureSparkline6M() {
    if (!selectedFiliale) return;
    try {
      const monthsAsc = getLastXMonthsAsc(selectedMonth, 6);
      const tempArray = [];

      for (const m of monthsAsc) {
        const response = await axiosInstance.get('/scorecards_month/ware-filiale-monat/', {
          params: {
            filialnummer: selectedFiliale?.name,
            jahrMonat: m,
          },
        });
        const single = response.data.results?.[0];
        if (single) {
          const denom = (single.lieferwert ?? 0) + (single.korrekturwert ?? 0);
          const quote = denom > 0 ? (single.retourwert / denom) * 100 : 0;
          tempArray.push(quote);
        } else {
          tempArray.push(0);
        }
      }
      setRetoureSparkline(tempArray); 
    } catch (error) {
      console.error('Fehler bei fetchRetoureSparkline6M:', error);
    }
  }

  async function fetchWarengruppenSparkline6M() {
    if (!selectedFiliale) return;
    try {
      const monthsAsc = getLastXMonthsAsc(selectedMonth, 6);
      const tempArray = [];

      for (const m of monthsAsc) {
        const response = await axiosInstance.get('/scorecards_month/ware-filiale-monat/', {
          params: {
            filialnummer: selectedFiliale?.name,
            jahrMonat: m,
          },
        });
        const single = response.data.results?.[0];
        if (single) {
          tempArray.push(single.verkaufsmenge ?? 0);
        } else {
          tempArray.push(0);
        }
      }
      setWarengruppenSparkline(tempArray);
    } catch (error) {
      console.error('Fehler bei fetchWarengruppenSparkline6M:', error);
    }
  }

  // -----------------------------------
  // Retoure & Warengruppen => Aktuell, VM, VJ
  // -----------------------------------
  async function fetchRetoureAndWarengruppenData(monthStr) {
    if (!selectedFiliale) return;
    try {
      const response = await axiosInstance.get('/scorecards_month/ware-filiale-monat/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: monthStr,
        },
      });
      const allResults = response.data.results || [];
      const single = allResults.length > 0 ? allResults[0] : {};

      // Retoure
      const retDenom = (single.lieferwert ?? 0) + (single.korrekturwert ?? 0);
      setRetoureQuoteAktuell(retDenom > 0 ? single.retourwert / retDenom : 0);

      // Warengruppen
      setWgMengeAktuell(single.verkaufsmenge ?? 0);

    } catch (error) {
      console.error('Fehler bei fetchRetoureAndWarengruppenData:', error);
    }
  }

  async function fetchRetoureAndWarengruppenVM() {
    if (!selectedFiliale) return;
    const vmMonth = getVormonatMonth(selectedMonth);
    try {
      const response = await axiosInstance.get('/scorecards_month/ware-filiale-monat/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: vmMonth,
        },
      });
      const allResults = response.data.results || [];
      const single = allResults.length > 0 ? allResults[0] : {};

      const retDenom = (single.lieferwert ?? 0) + (single.korrekturwert ?? 0);
      setRetoureQuoteVM(retDenom > 0 ? single.retourwert / retDenom : 0);

      setWgMengeVM(single.verkaufsmenge ?? 0);
    } catch (err) {
      console.error('Fehler bei fetchRetoureAndWarengruppenVM:', err);
    }
  }

  async function fetchRetoureAndWarengruppenVJ() {
    if (!selectedFiliale) return;
    const vjMonth = getVorjahrMonth(selectedMonth);
    try {
      const response = await axiosInstance.get('/scorecards_month/ware-filiale-monat/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: vjMonth,
        },
      });
      const allResults = response.data.results || [];
      const single = allResults.length > 0 ? allResults[0] : {};

      const retDenom = (single.lieferwert ?? 0) + (single.korrekturwert ?? 0);
      setRetoureQuoteVJ(retDenom > 0 ? single.retourwert / retDenom : 0);

      setWgMengeVJ(single.verkaufsmenge ?? 0);
    } catch (err) {
      console.error('Fehler bei fetchRetoureAndWarengruppenVJ:', err);
    }
  }

  // -----------------------------------
  // KRANKENTAGE: Daten laden (DataCard + Collapse)
  // -----------------------------------
  useEffect(() => {
    if (selectedFiliale && selectedMonth) {
      fetchKrankentageAktuellerMonat();
      fetchKrankentageSparkline6M();
    }
  }, [selectedFiliale, selectedMonth]);

  async function fetchKrankentageAktuellerMonat() {
    try {
      const response = await axiosInstance.get('/personaldaten/krankenzeiten/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: selectedMonth,
        },
      });
      const results = response.data.results || [];

      let sumKrankentage = 0;
      const tagesDetails = {};

      results.forEach((item) => {
        sumKrankentage += (item.krankentage || 0);

        // "2025-01-13T00:00:00" -> "2025-01-13"
        const dateKey = item.datum.split('T')[0];
        if (!tagesDetails[dateKey]) {
          // Format: dd.mm.yyyy
          tagesDetails[dateKey] = {
            datum: dayjs(dateKey).format('DD.MM.YYYY'),
            krankentage: 0,
            krankheitsstunden: 0,
          };
        }
        tagesDetails[dateKey].krankentage += (item.krankentage || 0);
        tagesDetails[dateKey].krankheitsstunden += (item.abwesenheitsstunden || 0);
      });

      // sortieren nach Datum aufsteigend (dd.mm.yyyy)
      const tagesArray = Object.values(tagesDetails).sort(
        (a, b) =>
          dayjs(a.datum, 'DD.MM.YYYY').unix() - dayjs(b.datum, 'DD.MM.YYYY').unix()
      );

      setKrankentageSum(sumKrankentage);
      setKrankentageDetail(tagesArray);
    } catch (error) {
      console.error('Fehler bei fetchKrankentageAktuellerMonat:', error);
    }
  }

  async function fetchKrankentageSparkline6M() {
    try {
      const monthsAsc = getLastXMonthsAsc(selectedMonth, 6);
      const tempArray = [];

      for (const m of monthsAsc) {
        const response = await axiosInstance.get('/personaldaten/krankenzeiten/', {
          params: {
            filialnummer: selectedFiliale?.name,
            jahrMonat: m,
          },
        });
        const dataArr = response.data.results || [];

        let sumKrank = 0;
        dataArr.forEach((item) => {
          sumKrank += (item.krankentage || 0);
        });
        tempArray.push(sumKrank);
      }
      setKrankentageSparkline(tempArray);
    } catch (err) {
      console.error('Fehler bei fetchKrankentageSparkline6M:', err);
    }
  }

  // -----------------------------------
  // RETOURE-DATEN (Diagramm + Tabelle)
  // -----------------------------------
  useEffect(() => {
    if (selectedFiliale && selectedMonth) {
      fetchRetoureData();
    }
  }, [selectedFiliale, selectedMonth]);

  async function fetchRetoureData() {
    if (!selectedFiliale) return;
    const last6Months = getLastXMonths(selectedMonth, 6);

    try {
      const responses = await Promise.all(
        last6Months.map((m) =>
          axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
            params: {
              filialnummer: selectedFiliale?.name,
              jahrMonat: m,
            },
          })
        )
      );

      const warengruppenSet = new Set();
      responses.forEach((resp) => {
        resp.data.results.forEach((item) => warengruppenSet.add(item.warengruppe));
      });
      const warengruppeList = [...warengruppenSet];

      const chartData = last6Months.map((month, idx) => {
        const dataArr = responses[idx].data.results;
        const aggMap = {};
        dataArr.forEach((item) => {
          const wg = item.warengruppe;
          if (!aggMap[wg]) {
            aggMap[wg] = { retourwert: 0, lieferwert: 0, korrekturwert: 0 };
          }
          aggMap[wg].retourwert += item.retourwert || 0;
          aggMap[wg].lieferwert += item.lieferwert || 0;
          aggMap[wg].korrekturwert += item.korrekturwert || 0;
        });
        const obj = { x: month };
        warengruppeList.forEach((wg) => {
          const val = aggMap[wg];
          if (val) {
            const denom = val.lieferwert + val.korrekturwert;
            const quote = denom > 0 ? (val.retourwert / denom) * 100 : 0;
            obj[wg] = parseFloat(quote.toFixed(2));
          } else {
            obj[wg] = 0;
          }
        });
        return obj;
      });

      const wgsWithValues = warengruppeList.filter((wg) =>
        chartData.some((row) => row[wg] > 0)
      );
      const finalChart = chartData.map((row) => {
        const newRow = { x: row.x };
        wgsWithValues.forEach((wg) => {
          newRow[wg] = row[wg];
        });
        return newRow;
      });
      setRetoureChartData(finalChart);

      const colors = [
        '#8884d8', '#82ca9d', '#ffc658', '#ff7300',
        '#d0ed57', '#a4de6c', '#8884d8', '#82ca9d',
      ];
      const lines = wgsWithValues.map((wg, i) => ({
        dataKey: wg,
        name: wg,
        color: colors[i % colors.length],
      }));
      setRetoureLines(lines);

      const lastData = responses[responses.length - 1].data.results || [];
      const tableMap = {};
      lastData.forEach((item) => {
        const wg = item.warengruppe;
        if (!tableMap[wg]) {
          tableMap[wg] = {
            retourwert: 0, lieferwert: 0, korrekturwert: 0,
            retourwertA: 0, lieferwertA: 0, korrekturwertA: 0,
            retourwertBC: 0, lieferwertBC: 0, korrekturwertBC: 0,
          };
        }
        tableMap[wg].retourwert += item.retourwert || 0;
        tableMap[wg].lieferwert += item.lieferwert || 0;
        tableMap[wg].korrekturwert += item.korrekturwert || 0;
        tableMap[wg].retourwertA += item.retourwertA || 0;
        tableMap[wg].lieferwertA += item.lieferwertA || 0;
        tableMap[wg].korrekturwertA += item.korrekturwertA || 0;
        tableMap[wg].retourwertBC += item.retourwertBC || 0;
        tableMap[wg].lieferwertBC += item.lieferwertBC || 0;
        tableMap[wg].korrekturwertBC += item.korrekturwertBC || 0;
      });
      const tableArr = Object.keys(tableMap).map((wg, idx) => {
        const val = tableMap[wg];
        const denomGesamt = val.lieferwert + val.korrekturwert;
        const denomA = val.lieferwertA + val.korrekturwertA;
        const denomBC = val.lieferwertBC + val.korrekturwertBC;

        const quote = denomGesamt > 0 ? (val.retourwert / denomGesamt) * 100 : 0;
        const quoteA = denomA > 0 ? (val.retourwertA / denomA) * 100 : 0;
        const quoteBC = denomBC > 0 ? (val.retourwertBC / denomBC) * 100 : 0;

        return {
          id: idx,
          warengruppe: wg,
          retourquote: `${quote.toFixed(2)}%`,
          retourquoteA: `${quoteA.toFixed(2)}%`,
          retourquoteBC: `${quoteBC.toFixed(2)}%`,
        };
      });
      const filteredTab = tableArr.filter(
        (row) =>
          parseFloat(row.retourquote) > 0 ||
          parseFloat(row.retourquoteA) > 0 ||
          parseFloat(row.retourquoteBC) > 0
      );
      setRetoureTableData(filteredTab);

      const newestMonth = last6Months[last6Months.length - 1];
      setRetoureCurrentMonth(newestMonth);
    } catch (error) {
      console.error('Fehler beim Abrufen der Retoure-Daten:', error);
    }
  }

  const handleRetoureRowClick = (row) => {
    setSelectedWarengruppe(row?.warengruppe);
    setOpenWarengruppePopup(true);
  };

  // -----------------------------------
  // Warengruppen => Sparkline + Expand
  // -----------------------------------
  async function fetchWarengruppenSummary6M() {
    if (!selectedMonth || !selectedFiliale) return;
    const last6 = getLastXMonths(selectedMonth, 6);

    try {
      const responses = await Promise.all(
        last6.map((m) =>
          axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
            params: {
              filialnummer: selectedFiliale?.name,
              jahrMonat: m,
            },
          })
        )
      );

      const mengeArray = [];
      let aktuellVal = 0;

      responses.forEach((resp, idx) => {
        const arr = resp.data.results || [];
        let sumMenge = 0;
        arr.forEach((item) => {
          sumMenge += item.verkaufsmenge || 0;
        });
        mengeArray.push(sumMenge);
        if (idx === responses.length - 1) {
          aktuellVal = sumMenge;
        }
      });

      setWarengruppen6MValues(mengeArray);
      setCurrentWarengruppenMenge(aktuellVal);
    } catch (error) {
      console.error('Fehler beim Abrufen der Warengruppen-6M:', error);
    }
  }

  useEffect(() => {
    if (selectedFiliale && selectedMonth) {
      fetchWarengruppenData();
    }
  }, [selectedFiliale, selectedMonth]);

  async function fetchWarengruppenData() {
    try {
      const resp = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
        params: {
          filialnummer: selectedFiliale?.name,
          jahrMonat: selectedMonth,
        },
      });

      let dataMap = {};
      resp.data.results.forEach((item) => {
        const wgName = item.warengruppe;
        if (!dataMap[wgName]) {
          dataMap[wgName] = { warengruppe: wgName, value: 0, previousValue: 0 };
        }
        dataMap[wgName].value += item.verkaufsmenge || 0;
        dataMap[wgName].previousValue += item.verkaufsmengeVJ || 0;
      });

      let warengruppenList = Object.values(dataMap);
      warengruppenList.sort((a, b) => b.value - a.value);
      let top7 = warengruppenList.slice(0, 7);

      const barData = top7.map((wg) => ({
        warengruppe: wg.warengruppe,
        value: wg.value,
        previousValue: wg.previousValue,
      }));
      setWarengruppenDevData(barData);

      // Wachstumstabelle
      const tableDataNew = resp.data.results.map((item) => {
        const diffVJ = item.verkaufsmenge - item.verkaufsmengeVJ;
        const verkaufsmengeProzent = item.verkaufsmengeVJ
          ? ((item.verkaufsmenge - item.verkaufsmengeVJ) / item.verkaufsmengeVJ) * 100
          : 0;
        return {
          Warengruppe: item.warengruppe,
          Warengruppennummer: item.warengruppennummer,
          Verkaufsmenge: tausenderTrennung(item.verkaufsmenge, 0),
          VerkaufsmengeVJ: tausenderTrennung(item.verkaufsmengeVJ, 0),
          DiffVJ: tausenderTrennung(diffVJ, 0),
          VerkaufsmengeProzent: `${tausenderTrennung(verkaufsmengeProzent, 2)} %`,
        };
      });

      const newColumns = [
        { accessorKey: 'Warengruppe', header: 'Warengruppe' },
        { accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge Stk.' },
        { accessorKey: 'VerkaufsmengeVJ', header: 'Vorjahr Stk.' },
        { accessorKey: 'DiffVJ', header: 'Diff. VJ Stk.' },
        { accessorKey: 'VerkaufsmengeProzent', header: 'VJ %' },
      ];

      setWgTableData(tableDataNew);
      setWgTableColumns(newColumns);
    } catch (error) {
      console.error('Fehler beim Abrufen der Warengruppenentwicklung:', error);
    }
  }

  function WarengruppenEntwicklungDiagramm({ data }) {
    if (!data || data.length === 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          Keine Daten verfügbar
        </Typography>
      );
    }
    const legendNames = ['Aktuell', 'Vorjahr'];
    const maxValue = useMemo(() => {
      let vals = data.flatMap((item) => [item.value, item.previousValue]);
      let maxVal = Math.max(...vals);
      return Math.ceil(maxVal * 1.1);
    }, [data]);

    return (
      <BarChartCardFlexibleYAxis
        data={data}
        dataKeys={['value', 'previousValue']}
        xAxisKey="warengruppe"
        title="Verkaufsmengen der Warengruppen"
        infoContent="Top 7 Warengruppen nach Verkaufsmenge (Aktuell vs. Vorjahr)"
        height={300}
        yAxisMin={0}
        yAxisMax={maxValue}
        legendNames={legendNames}
      />
    );
  }

  // ------------------------------------------------
  // RENDER
  // ------------------------------------------------
  return (
    <Layout>
      {/* Kopfzeile: Performance + Monat + Filiale */}
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <PageHeader title="Performance" Icon={TimelineIcon} />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <IconButton onClick={handlePrevMonth}>
            <ArrowLeftIcon />
          </IconButton>

          <Typography variant="h6">{selectedMonth}</Typography>

          <IconButton onClick={handleNextMonth}>
            <ArrowRightIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} md={4} display="flex" justifyContent="flex-end">
          <Box sx={{ minWidth: 200 }}>
            <DropdownFilter
              label="Filiale (Einfach)"
              options={filialenOptions}
              value={selectedFiliale}
              onChange={setSelectedFiliale}
              useNameAsLabel={false}
              multiple={false}
            />
          </Box>
        </Grid>
      </Grid>

      {/* PERFORMANCE-KARTEN => ExpandableCards */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Umsatz */}
        <Grid item xs={12}>
          <ExpandableCard
            category="Umsatz"
            value={tausenderTrennung(performanceData.umsatzSumme.toFixed(0), 0)}
            subHeaders={[
              `${(performanceData.umsatzAbweichungVM * 100).toFixed(2)}% zum Vormonat`,
              `${(performanceData.umsatzAbweichung6M * 100).toFixed(2)}% zum 6M-Schnitt`,
              `${(performanceData.umsatzAbweichungVJ * 100).toFixed(2)}% vs. VJ`,
            ]}
            chartData={performance6MUmsatz.flatMap((item) => [
              { x: item.month, y: item.umsatz, label: 'Aktuell' },
              { x: item.month, y: item.umsatzVJ, label: 'Vorjahr' },
            ])}
            sparklineData={performance6MUmsatz.map((item) => item.umsatz)}
            infoContent={<strong>Umsatz</strong>}
            expandedState={expandedUmsatz}
            setExpandedState={setExpandedUmsatz}
            titleForChart="Umsatz (6M)"
            monthlyTable={createMonthAsColumnsTable(performance6MUmsatz, 'Aktuell', 'Vorjahr')}
          />
        </Grid>

        {/* Kundenanzahl */}
        <Grid item xs={12}>
          <ExpandableCard
            category="Kundenanzahl"
            value={tausenderTrennung(performanceData.kundenanzahlSumme.toFixed(0), 0)}
            subHeaders={[
              `${(performanceData.kundenanzahlAbweichungVM * 100).toFixed(2)}% zum Vormonat`,
              `${(performanceData.kundenanzahlAbweichung6M * 100).toFixed(2)}% zum 6M-Schnitt`,
              `${(performanceData.kundenanzahlAbweichungVJ * 100).toFixed(2)}% vs. VJ`,
            ]}
            chartData={performance6MKunden.flatMap((item) => [
              { x: item.month, y: item.aktuell, label: 'Aktuell' },
              { x: item.month, y: item.vorjahr, label: 'Vorjahr' },
            ])}
            sparklineData={performance6MKunden.map((item) => item.aktuell)}
            infoContent={<strong>Kundenanzahl</strong>}
            expandedState={expandedKundenanzahl}
            setExpandedState={setExpandedKundenanzahl}
            titleForChart="Kundenanzahl (6M)"
            monthlyTable={createMonthAsColumnsTable(performance6MKunden, 'Aktuell', 'Vorjahr')}
          />
        </Grid>

        {/* Kundenbon */}
        <Grid item xs={12}>
          <ExpandableCard
            category="Kundenbon"
            value={
              performanceData.kundenbon
                ? formatCurrency(performanceData.kundenbon)
                : '0,00 €'
            }
            subHeaders={[
              `${(performanceData.kundenbonAbweichungVM * 100).toFixed(2)}% zum Vormonat`,
              `${(performanceData.kundenbonAbweichungVJ * 100).toFixed(2)}% vs. VJ`,
            ]}
            chartData={performance6MKundenbon.flatMap((item) => [
              { x: item.month, y: item.aktuell, label: 'Aktuell' },
              { x: item.month, y: item.vorjahr, label: 'Vorjahr' },
            ])}
            sparklineData={performance6MKundenbon.map((item) => item.aktuell)}
            infoContent={<strong>Kundenbon</strong>}
            expandedState={expandedKundenbon}
            setExpandedState={setExpandedKundenbon}
            titleForChart="Kundenbon (6M)"
            monthlyTable={createMonthAsColumnsTable(performance6MKundenbon, 'Aktuell', 'Vorjahr')}
          />
        </Grid>

        {/* Leistung */}
        <Grid item xs={12}>
          <ExpandableCard
            category="Leistung"
            value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
            subHeaders={[
              `${(performanceData.leistungAbweichungVM * 100).toFixed(2)}% zum Vormonat`,
              `${(performanceData.leistungAbweichungVJ * 100).toFixed(2)}% vs. VJ`,
            ]}
            chartData={performance6MLeistung.flatMap((item) => [
              { x: item.month, y: item.aktuell, label: 'Aktuell' },
              { x: item.month, y: item.vorjahr, label: 'Vorjahr' },
            ])}
            sparklineData={performance6MLeistung.map((item) => item.aktuell)}
            infoContent={<strong>Leistung</strong>}
            expandedState={expandedLeistung}
            setExpandedState={setExpandedLeistung}
            titleForChart="Leistung (6M)"
            monthlyTable={createMonthAsColumnsTable(performance6MLeistung, 'Aktuell', 'Vorjahr')}
          />
        </Grid>
      </Grid>

      {/* WARE => Retoure + Warengruppen jeweils DataCard + Collapse */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <PageHeader title="Ware" Icon={BakeryDiningIcon} />
        </Grid>

        {/* Retoure => DataCard + Collapse */}
        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              mb: 2,
            }}
          >
            <CardContent>
              <DataCard
                category="Retoure"
                value={`${(retoureQuoteAktuell * 100).toFixed(2)} %`}
                subHeaders={[
                  `${retoureAbwVM.toFixed(2)}% vs. VM`,
                  `${retoureAbwVJ.toFixed(2)}% vs. VJ`,
                ]}
                chartData={retoureSparkline}
                numDataPoints={6}
                onClick={() => setExpandedRetoure((prev) => !prev)}
              />
            </CardContent>

            <Collapse in={expandedRetoure} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <BigChartCardUnternehmenWoche
                      data={retoureChartData}
                      lines={retoureLines}
                      title="Retourquote pro Warengruppe (6M)"
                      height={400}
                      infoContent="Hier das Retoure-Diagramm (Monat)."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BigTableCard
                      title="Retourquoten nach Warengruppe"
                      data={retoureTableData}
                      columns={[
                        { accessorKey: 'warengruppe', header: 'Warengruppe' },
                        { accessorKey: 'retourquote', header: 'Retourquote' },
                        { accessorKey: 'retourquoteA', header: 'Retourquote A' },
                        { accessorKey: 'retourquoteBC', header: 'Retourquote BC' },
                      ]}
                      onRowClick={(row) => {
                        setSelectedWarengruppe(row?.warengruppe);
                        setOpenWarengruppePopup(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Card>
        </Grid>

        {/* Warengruppen => DataCard + Collapse */}
        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              mb: 2,
            }}
          >
            <CardContent>
              <DataCard
                category="Warengruppen"
                value={`${tausenderTrennung(wgMengeAktuell, 0)} Stk.`}
                subHeaders={[
                  `${warengruppeMengeVMAbw.toFixed(2)}% vs. VM`,
                  `${warengruppeMengeVJAbw.toFixed(2)}% vs. VJ`,
                ]}
                chartData={warengruppenSparkline}
                numDataPoints={6}
                onClick={() => setExpandedWarengruppen((prev) => !prev)}
              />
            </CardContent>

            <Collapse in={expandedWarengruppen} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <WarengruppenEntwicklungDiagramm data={warengruppenDevData} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        Wachstum
                      </Typography>
                      <VariableTable
                        columns={wgTableColumns}
                        data={wgTableData}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Card>
        </Grid>
      </Grid>

      {/* PERSONAL => KRANKENTAGE (DataCard + Collapse) */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <PageHeader title="Personal" Icon={GroupIcon} />
        </Grid>

        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              mb: 2,
            }}
          >
            <CardContent>
              <DataCard
                category="Krankentage"
                value={`${krankentageSum} Tage`}
                subHeaders={[]} // ggf. hier VM/VJ-Prozentangaben
                chartData={krankentageSparkline}
                numDataPoints={6}
                onClick={() => setExpandedKrankentage((prev) => !prev)}
              />
            </CardContent>

            <Collapse in={expandedKrankentage} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Krankentage (6M)
                </Typography>

                {/* Optional: Diagramm mit BigChartCard für den Verlauf */}
                <Box sx={{ mt: 2 }}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Details (Tagesebene)
                    </Typography>
                    <VariableTable
                      columns={[
                        { accessorKey: 'datum', header: 'Datum' },
                        { accessorKey: 'krankheitsstunden', header: 'Krankheitsstunden' },
                        { accessorKey: 'krankentage', header: 'Krankentage' },
                      ]}
                      data={krankentageDetail}
                    />
                  </Paper>
                </Box>
              </Box>
            </Collapse>
          </Card>
        </Grid>
      </Grid>

      {/* POPUP: Warengruppe mit ausgewähltem Monat */}
      <WarengruppePopup
        open={openWarengruppePopup}
        onClose={() => setOpenWarengruppePopup(false)}
        warengruppe={selectedWarengruppe}
        filiale={selectedFiliale?.name}
        monat={retoureCurrentMonth}
      />
    </Layout>
  );
}
