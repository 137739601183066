// src/pages/unternehmenwoche/retoure/retoure.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/UnternehmenWocheLayout';
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche';
import BigTableCard from '../../../components/card/BigTableCard';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/de';
import { useTheme } from '@mui/material';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';

// Import der Popups
import MainTablePopup from '../../../components/popup/unternehmenWoche/retoure/MainTablePopup';
import FilialeWarengruppePopup from '../../../components/popup/unternehmenWoche/retoure/FilialeWarengruppePopup';
import WarengruppePopup from '../../../components/popup/unternehmenWoche/retoure/WarengruppePopup';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.locale('de');

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
    if (value === null || value === undefined) return '';
    return value
        .toFixed(fractionDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const Retoure = () => {
    const { filterState } = useContext(FilterContext);
    const theme = useTheme();
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [warengruppeTableData, setWarengruppeTableData] = useState([]);
    const [filialeDayTableData, setFilialeDayTableData] = useState([]);
    const [filialeDayTableColumns, setFilialeDayTableColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // State-Variablen für Popups
    const [mainTablePopupOpen, setMainTablePopupOpen] = useState(false);
    const [selectedFilialeForMainPopup, setSelectedFilialeForMainPopup] = useState('');
    const [filialeWarengruppePopupOpen, setFilialeWarengruppePopupOpen] = useState(false);
    const [selectedFiliale, setSelectedFiliale] = useState('');
    const [warengruppePopupOpen, setWarengruppePopupOpen] = useState(false);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

    // Neue State-Variablen für Verfügbarkeits-Tabelle
    const [verfuegbarkeitData, setVerfuegbarkeitData] = useState([]);
    const [verfuegbarkeitTableColumns, setVerfuegbarkeitTableColumns] = useState([]);

    useEffect(() => {
        if (filterState && filterState.datumWoche && filterState.datumWoche.value) {
            fetchChartData();
            fetchTableData();
            fetchWarengruppeTableData();
            fetchFilialeDayTableData();
            fetchVerfuegbarkeitData(); // Neue Datenabruf-Funktion aufrufen
        }
    }, [filterState.datumWoche, filterState.bestehendeFlaeche]);

    // Funktion zum Abrufen der Chart-Daten
    const fetchChartData = async () => {
        try {
            setLoading(true);
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const warengruppenSet = new Set();

            // Sammeln aller Warengruppen
            responses.forEach((response) => {
                response.data.results.forEach((item) => {
                    warengruppenSet.add(item.warengruppe);
                });
            });
            const warengruppen = Array.from(warengruppenSet);

            // Erstellung der Chart-Daten aggregiert über alle Filialen
            const chartDataArr = last6Weeks.map((week, index) => {
                const dataForWeek = responses[index].data.results;

                // Aggregation der Werte pro Warengruppe
                const aggregatedData = {};
                dataForWeek.forEach((item) => {
                    const wg = item.warengruppe;
                    if (!aggregatedData[wg]) {
                        aggregatedData[wg] = {
                            retourwert: 0,
                            lieferwert: 0,
                            korrekturwert: 0,
                        };
                    }
                    aggregatedData[wg].retourwert += item.retourwert || 0;
                    aggregatedData[wg].lieferwert += item.lieferwert || 0;
                    aggregatedData[wg].korrekturwert += item.korrekturwert || 0;
                });

                const dataObj = { x: week };

                warengruppen.forEach((wg) => {
                    const agg = aggregatedData[wg];
                    if (agg && (agg.retourwert > 0 || agg.lieferwert > 0 || agg.korrekturwert > 0)) {
                        const denominator = agg.lieferwert + agg.korrekturwert;
                        const retourquote = denominator > 0 ? (agg.retourwert / denominator) * 100 : 0;
                        dataObj[wg] = parseFloat(retourquote.toFixed(2));
                    } else {
                        dataObj[wg] = 0;
                    }
                });

                return dataObj;
            });

            // Nur Warengruppen mit Retourquote > 0 in den letzten 6 Wochen anzeigen
            const warengruppenMitWerten = warengruppen.filter((wg) =>
                chartDataArr.some((data) => data[wg] > 0)
            );

            // Entfernen von Warengruppen ohne Werte aus den Chart-Daten
            const filteredChartDataArr = chartDataArr.map((data) => {
                const newData = { x: data.x };
                warengruppenMitWerten.forEach((wg) => {
                    newData[wg] = data[wg];
                });
                return newData;
            });

            setChartData(filteredChartDataArr);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Chart-Daten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Tabellendaten
    const fetchTableData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/scorecard_week/ware-filiale-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const formattedData = response.data.results.map((item, index) => ({
                id: index,
                verkaufsgebiet: item.verkaufsgebiet || 'Unbekannt',
                filiale: item.filiale,
                retourquote:
                    item.retourQuote !== null && item.retourQuote !== undefined
                        ? parseFloat((item.retourQuote * 100).toFixed(2))
                        : 0,
                retourquoteA:
                    item.retourQuoteA !== null && item.retourQuoteA !== undefined
                        ? parseFloat((item.retourQuoteA * 100).toFixed(2))
                        : 0,
                retourquoteBC:
                    item.retourQuoteBC !== null && item.retourQuoteBC !== undefined
                        ? parseFloat((item.retourQuoteBC * 100).toFixed(2))
                        : 0,
                retourwert: item.retourwert || 0,
                lieferwert: item.lieferwert || 0,
                korrekturwert: item.korrekturwert || 0,
                lieferwertA: item.lieferwertA || 0,
                retourwertA: item.retourwertA || 0,
                korrekturwertA: item.korrekturwertA || 0,
                lieferwertBC: item.lieferwertBC || 0,
                retourwertBC: item.retourwertBC || 0,
                korrekturwertBC: item.korrekturwertBC || 0,
            }));

            setTableData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Tabellendaten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Warengruppen-Tabellendaten
    const fetchWarengruppeTableData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            // Aggregation der Werte pro Warengruppe über alle Filialen
            const aggregatedData = {};
            response.data.results.forEach((item) => {
                const wg = item.warengruppe || 'Unbekannt';
                if (!aggregatedData[wg]) {
                    aggregatedData[wg] = {
                        retourwert: 0,
                        lieferwert: 0,
                        korrekturwert: 0,
                        retourwertA: 0,
                        lieferwertA: 0,
                        korrekturwertA: 0,
                        retourwertBC: 0,
                        lieferwertBC: 0,
                        korrekturwertBC: 0,
                    };
                }
                // Gesamtwerte aggregieren
                aggregatedData[wg].retourwert += item.retourwert || 0;
                aggregatedData[wg].lieferwert += item.lieferwert || 0;
                aggregatedData[wg].korrekturwert += item.korrekturwert || 0;
                // A-Werte aggregieren
                aggregatedData[wg].retourwertA += item.retourwertA || 0;
                aggregatedData[wg].lieferwertA += item.lieferwertA || 0;
                aggregatedData[wg].korrekturwertA += item.korrekturwertA || 0;
                // BC-Werte aggregieren
                aggregatedData[wg].retourwertBC += item.retourwertBC || 0;
                aggregatedData[wg].lieferwertBC += item.lieferwertBC || 0;
                aggregatedData[wg].korrekturwertBC += item.korrekturwertBC || 0;
            });

            // Berechnung der Retourquote pro Warengruppe und Formatierung der Werte
            const formattedData = Object.keys(aggregatedData)
                .map((wg, index) => {
                    const agg = aggregatedData[wg];
                    // Gesamt-Retourquote
                    const denominator = agg.lieferwert + agg.korrekturwert;
                    const retourquote = denominator > 0 ? (agg.retourwert / denominator) * 100 : 0;

                    // Retourquote A
                    const denominatorA = agg.lieferwertA + agg.korrekturwertA;
                    const retourquoteA = denominatorA > 0 ? (agg.retourwertA / denominatorA) * 100 : 0;

                    // Retourquote BC
                    const denominatorBC = agg.lieferwertBC + agg.korrekturwertBC;
                    const retourquoteBC = denominatorBC > 0 ? (agg.retourwertBC / denominatorBC) * 100 : 0;

                    return {
                        id: index,
                        warengruppe: wg,
                        retourquote: retourquote > 0 ? `${formatNumber(retourquote)}%` : '0,00%',
                        retourquoteA: retourquoteA > 0 ? `${formatNumber(retourquoteA)}%` : '0,00%',
                        retourquoteBC: retourquoteBC > 0 ? `${formatNumber(retourquoteBC)}%` : '0,00%',
                    };
                })
                .filter((item) => parseFloat(item.retourquote.replace(',', '.')) > 0);

            setWarengruppeTableData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Warengruppe-Tabellendaten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Filiale-Tag-Daten
    const fetchFilialeDayTableData = async () => {
        try {
            setLoading(true);
            const [year, weekNumber] = filterState.datumWoche.value.split('.').map(Number);
            const weekStart = dayjs().year(year).isoWeek(weekNumber).startOf('isoWeek');
            const weekEnd = dayjs().year(year).isoWeek(weekNumber).endOf('isoWeek');

            // Erstellung der Wochentage
            const daysOfWeek = [];
            for (let i = 0; i < 7; i++) {
                daysOfWeek.push(weekStart.add(i, 'day').format('YYYY-MM-DD'));
            }

            // API-Aufruf mit startdatum und enddatum
            const response = await axiosInstance.get('/fact/kundenstatistik/', {
                params: {
                    startdatum: weekStart.format('YYYY-MM-DD'),
                    enddatum: weekEnd.format('YYYY-MM-DD'),
                },
            });

            // Erstellung der Spalten für die Tabelle
            const columns = [
                { accessorKey: 'filiale', header: 'Filiale', align: 'left' },
                ...daysOfWeek.map((date) => ({
                    accessorKey: date,
                    header: dayjs(date).format('ddd DD.MM.'),
                    align: 'right',
                })),
            ];

            setFilialeDayTableColumns(columns);

            // Gruppierung der Daten nach Filiale und Datum
            const dataByFiliale = {};

            response.data.results
                .filter((item) => {
                    const itemDate = dayjs(item.datum);
                    return itemDate.isBetween(weekStart, weekEnd, 'day', '[]');
                })
                .forEach((item) => {
                    const filiale = item.filiale || 'Unbekannt';
                    const date = dayjs(item.datum).format('YYYY-MM-DD');

                    if (!dataByFiliale[filiale]) {
                        dataByFiliale[filiale] = { filiale };
                    }

                    dataByFiliale[filiale][date] = formatNumber(item.retourwert || 0);
                });

            const formattedData = Object.values(dataByFiliale);

            // Filtern von Filialen mit Werten > 0
            const filteredData = formattedData.filter((filialeData) => {
                const dailyValues = Object.keys(filialeData)
                    .filter((key) => key !== 'filiale')
                    .map((key) => {
                        const valueStr = filialeData[key];
                        const value = parseFloat(valueStr.replace('.', '').replace(',', '.')) || 0;
                        return value;
                    });
                return dailyValues.some((value) => value > 0);
            });

            setFilialeDayTableData(filteredData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Filiale-Tag-Daten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Neue Funktion zum Abrufen der Verfügbarkeitsdaten
    const fetchVerfuegbarkeitData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/scorecard_week_article/verfuegbarkeit-woche/', {
                params: {
                    jahrKw: filterState.datumWoche.value,
                    ABCValue: 'A',
                },
            });

            const results = response.data.results;

            // Erstellen einer Liste für die Daten und ein Set für die Filialen
            const data = [];
            const filialeSet = new Set();

            results.forEach((item) => {
                const filiale = item.filialnummer.filiale || 'Unbekannt';
                const artikelnummer = item.artikelnummer.artikelnummer;
                const artikelbezeichnung = item.artikelnummer.artikelbezeichnung;

                filialeSet.add(filiale);

                data.push({
                    id: `${filiale}-${artikelnummer}`, // Eindeutige ID
                    parentId: filiale, // Eltern-ID für die Hierarchie
                    filiale,
                    artikelnummer,
                    artikelbezeichnung,
                    ausverkauftTage: 1,
                });
            });

            // Aggregation der Ausverkauft Tage pro Filiale und Artikel
            const dataById = {};

            data.forEach((item) => {
                const id = item.id;
                if (dataById[id]) {
                    dataById[id].ausverkauftTage += 1;
                } else {
                    dataById[id] = item;
                }
            });

            // Umwandlung in ein Array
            const aggregatedData = Object.values(dataById);

            // Hinzufügen der Filialen als oberste Ebene
            filialeSet.forEach((filiale) => {
                aggregatedData.push({
                    id: filiale,
                    filiale,
                    artikelnummer: null,
                    artikelbezeichnung: null,
                    ausverkauftTage: null,
                });
            });

            setVerfuegbarkeitData(aggregatedData);

            // Definition der Spalten für die Verfügbarkeits-Tabelle
            setVerfuegbarkeitTableColumns([
                { field: 'filiale', headerName: 'Filiale', align: 'left' },
                { field: 'artikelnummer', headerName: 'Artikelnummer', align: 'left' },
                { field: 'artikelbezeichnung', headerName: 'Artikelbezeichnung', align: 'left' },
                { field: 'ausverkauftTage', headerName: 'Ausverkauft Tage', type: 'number', align: 'right' },
            ]);

            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Verfügbarkeits-Daten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Handler für Zeilenklick in VariableTreeMUI
    const handleMainTableRowClick = (row) => {
        if (row.filiale) {
            const filiale = row.filiale;
            setSelectedFilialeForMainPopup(filiale);
            setMainTablePopupOpen(true);
        }
    };

    // Handler für Zeilenklick in der Filiale-Tag-Tabelle
    const handleFilialeRowClick = (row) => {
        const filiale = row.filiale;
        setSelectedFiliale(filiale);
        setFilialeWarengruppePopupOpen(true);
    };

    // Handler für Zeilenklick in der Warengruppe-Tabelle
    const handleWarengruppeRowClick = (row) => {
        const warengruppe = row.warengruppe;
        setSelectedWarengruppe(warengruppe);
        setWarengruppePopupOpen(true);
    };

    // Haupttabellenspalten
    const mainTableColumns = [
        { field: 'verkaufsgebiet', headerName: 'Verkaufsgebiet', align: 'left' },
        { field: 'filiale', headerName: 'Filiale', align: 'left' },
        {
            field: 'retourquote',
            headerName: 'Retourquote',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)}%`
                    : '',
            hide: false,
        },
        {
            accessorKey: 'retourquoteA',
            headerName: 'Retourquote A',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)}%`
                    : '',
            hide: false,
        },
        {
            field: 'retourquoteBC',
            headerName: 'Retourquote BC',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)}%`
                    : '',
            hide: false,
        },
        {
            field: 'retourwert',
            headerName: 'Retourwert',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)} €`
                    : '',
            hide: false,
        },
        {
            field: 'lieferwert',
            headerName: 'Lieferwert',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)} €`
                    : '',
            hide: false,
        },
        {
            field: 'korrekturwert',
            headerName: 'Korrekturwert',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)} €`
                    : '',
            hide: false,
        },
    ];

    // Aggregationsfunktionen für die Haupttabelle
    const aggregationFunctions = {
        retourquote: (items) => {
            const totalRetourwert = items.reduce((sum, item) => sum + (item.retourwert || 0), 0);
            const totalLieferwert = items.reduce((sum, item) => sum + (item.lieferwert || 0), 0);
            const totalKorrekturwert = items.reduce((sum, item) => sum + (item.korrekturwert || 0), 0);
            const denominator = totalLieferwert + totalKorrekturwert;

            return denominator > 0 ? parseFloat(((totalRetourwert / denominator) * 100).toFixed(2)) : 0.0;
        },
        retourquoteA: (items) => {
            const totalRetourwertA = items.reduce((sum, item) => sum + (item.retourwertA || 0), 0);
            const totalLieferwertA = items.reduce((sum, item) => sum + (item.lieferwertA || 0), 0);
            const totalKorrekturwertA = items.reduce((sum, item) => sum + (item.korrekturwertA || 0), 0);
            const denominator = totalLieferwertA + totalKorrekturwertA;

            return denominator > 0 ? parseFloat(((totalRetourwertA / denominator) * 100).toFixed(2)) : 0.0;
        },
        retourquoteBC: (items) => {
            const totalRetourwertBC = items.reduce((sum, item) => sum + (item.retourwertBC || 0), 0);
            const totalLieferwertBC = items.reduce((sum, item) => sum + (item.lieferwertBC || 0), 0);
            const totalKorrekturwertBC = items.reduce((sum, item) => sum + (item.korrekturwertBC || 0), 0);
            const denominator = totalLieferwertBC + totalKorrekturwertBC;

            return denominator > 0 ? parseFloat(((totalRetourwertBC / denominator) * 100).toFixed(2)) : 0.0;
        },
        retourwert: (items) => {
            const totalRetourwert = items.reduce((sum, item) => sum + (item.retourwert || 0), 0);
            return totalRetourwert;
        },
        lieferwert: (items) => {
            const totalLieferwert = items.reduce((sum, item) => sum + (item.lieferwert || 0), 0);
            return totalLieferwert;
        },
        korrekturwert: (items) => {
            const totalKorrekturwert = items.reduce((sum, item) => sum + (item.korrekturwert || 0), 0);
            return totalKorrekturwert;
        },
    };

    // Angepasste Warengruppen-Tabellenspalten
    const warengruppeTableColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe', align: 'left' },
        {
            accessorKey: 'retourquote',
            header: 'Retourquote',
            align: 'right',
        },
        {
            accessorKey: 'retourquoteA',
            header: 'Retourquote A',
            align: 'right',
        },
        {
            accessorKey: 'retourquoteBC',
            header: 'Retourquote BC',
            align: 'right',
        },
    ];

    // Definition der Linien für das Diagramm mit unterschiedlichen Farben
    const mainChartLines = () => {
        if (chartData.length === 0) return [];

        const warengruppen = Object.keys(chartData[0]).filter((key) => key !== 'x');

        const colors = [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.chart.green,
            theme.palette.chart.yellow,
            theme.palette.chart.orange,
            theme.palette.chart.pink,
            theme.palette.chart.blue,
            theme.palette.chart.red,
            theme.palette.chart.violet,
            theme.palette.chart.teal,
        ];

        return warengruppen.map((wg, index) => ({
            dataKey: wg,
            name: wg,
            color: colors[index % colors.length],
        }));
    };

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader
                    title="Retouren"
                    subtitle="Übersicht über die Retouren im Sortiment"
                    Icon={BarChartIcon}
                />
            </Box>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error">Fehler beim Laden der Daten.</Typography>
                </Box>
            ) : (
                <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
                    {/* Diagramm und Warengruppen-Tabelle nebeneinander */}
                    <Grid item xs={12} md={6}>
                        <BigChartCardUnternehmenWoche
                            data={chartData}
                            title="Retourquote pro Warengruppe der letzten 6 Wochen"
                            lines={mainChartLines()}
                            height={400}
                            infoContent="Dies ist eine Info über den Retourquote-Chart."
                            isPercentage
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BigTableCard
                            data={warengruppeTableData}
                            columns={warengruppeTableColumns}
                            title="Retourquoten nach Warengruppe"
                            height={400}
                            onRowClick={handleWarengruppeRowClick}
                        />
                    </Grid>

                    {/* Haupttabelle */}
                    <Grid item xs={12}>
                        <VariableTreeMUI
                            title="Übersicht nach Verkaufsgebieten und Filialen"
                            data={tableData}
                            columns={mainTableColumns}
                            initialGrouping={['verkaufsgebiet']}
                            groupingHeaders={{ verkaufsgebiet: 'Verkaufsgebiet' }}
                            aggregationFunctions={aggregationFunctions}
                            onRowClick={handleMainTableRowClick}
                        />
                    </Grid>

                    {/* Filiale-Tag-Tabelle */}
                    <Grid item xs={12}>
                        <BigTableCard
                            data={filialeDayTableData}
                            columns={filialeDayTableColumns}
                            title="Retourenwerte pro Filiale pro Tag"
                            onRowClick={handleFilialeRowClick}
                        />
                    </Grid>

                    {/* Neue Verfügbarkeits-Tabelle */}
                    <Grid item xs={12}>
                        <VariableTreeMUI
                            title="Verfügbarkeit der Artikel (ABC = A)"
                            data={verfuegbarkeitData}
                            columns={verfuegbarkeitTableColumns}
                            initialGrouping={['filiale']}
                            groupingHeaders={{ filiale: 'Filiale' }}
                            // Stellen Sie sicher, dass treeData aktiviert ist
                            treeData
                            getTreeDataPath={(row) => [row.filiale, row.artikelnummer]}
                            // Optional: aggregationFunctions, onRowClick etc.
                        />
                    </Grid>
                </Grid>
            )}

            {/* MainTablePopup */}
            {mainTablePopupOpen && (
                <MainTablePopup
                    open={mainTablePopupOpen}
                    onClose={() => setMainTablePopupOpen(false)}
                    filiale={selectedFilialeForMainPopup}
                    filterState={filterState}
                />
            )}

            {/* FilialeWarengruppePopup */}
            {filialeWarengruppePopupOpen && (
                <FilialeWarengruppePopup
                    open={filialeWarengruppePopupOpen}
                    onClose={() => setFilialeWarengruppePopupOpen(false)}
                    filiale={selectedFiliale}
                    filterState={filterState}
                />
            )}

            {/* WarengruppePopup */}
            {warengruppePopupOpen && (
                <WarengruppePopup
                    open={warengruppePopupOpen}
                    onClose={() => setWarengruppePopupOpen(false)}
                    warengruppe={selectedWarengruppe}
                    filterState={filterState}
                />
            )}
        </Layout>
    );
};

export default Retoure;
