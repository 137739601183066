import React from 'react';
import { List, Box, ListItem, ListItemText, IconButton, Collapse, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StepList from './StepList';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Droppable, Draggable } from '@hello-pangea/dnd';

const TopicList = ({
    topics,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddStepPlaceholderClick,
    categoryId
}) => {
    if (!categoryId) {
        return <Typography variant="body2" color="error">Fehler: categoryId nicht übergeben.</Typography>;
    }

    return (
        <Droppable droppableId={categoryId.toString()} type="topic">
            {(provided) => (
                <List
                    sx={{ width: '100%' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {topics.length > 0 ? (
                        topics.map((topic, index) => (
                            <Draggable
                                key={topic.id}
                                draggableId={`topic-${topic.id}`}
                                index={index}
                            >
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            p: 2,
                                            mb: 1,
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            backgroundColor: '#fafafa'
                                        }}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    {/* Pfeil zum Auf- und Zuklappen der Steps */}
                                                    <IconButton edge="end" aria-label="expand" onClick={() => handleToggleTopic(topic.id)}>
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditTopic(topic)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTopic(topic.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText primary={topic.name} />
                                        </ListItem>
                                        <Collapse in={openTopics[topic.id]} timeout="auto" unmountOnExit>
                                            <StepList
                                                topicId={topic.id}
                                                steps={steps[topic.id] || []}
                                                handleDeleteStep={handleDeleteStep}
                                                handleEditStep={handleEditStep}
                                                handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                            />
                                        </Collapse>
                                    </Box>
                                )}
                            </Draggable>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">Keine Themen vorhanden.</Typography>
                    )}
                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    );
};

export default TopicList;
