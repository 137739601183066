import React, {useContext, useEffect, useState} from 'react';
import {Grid, Stack, Typography} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import {FilterContext} from "../../../provider/FilterProvider";
import TimelineIcon from '@mui/icons-material/Timeline';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import GroupIcon from '@mui/icons-material/Group';
import Score from '../../../components/score/Score';
import FilialeMonatLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";


const Cockpit = () => {
  // State for PerformanceKundenbon Data
  const [performanceDataCardData, setPerformanceDataCardData] = useState([]);
  const [performanceScore, setPerformanceScore] = useState(0);

  // State for PersonalAuslastung Data
  const [personalDataCardData, setPersonalDataCardData] = useState([]);
  const [personalScore, setPersonalScore] = useState(0);

  // State for Ware (Wachstum) Data
  const [wareDataCardData, setWareDataCardData] = useState([]);
  const [wareScore, setWareScore] = useState(0);

  const {filterState} = useContext(FilterContext);

  useEffect(() => {
    if (filterState.filiale && filterState.datum) {
      fetchPerformanceData();
      fetchPersonalData();
      fetchWareData();
    }
  }, [filterState.filiale, filterState.datum]);

  // Fetch PerformanceKundenbon Data
  const fetchPerformanceData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(4, 'month');

      const response = await axiosInstance.get('scorecards_month/performance-monat/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label,
        }
      });

      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Umsatz: item.scoreUmsatz,
            Kundenanzahl: item.scoreKundenanzahl,
            Kundenbon: item.scoreKundenbon,
            Leistung: item.scoreLeistung,
          }));

      setPerformanceScore(response.data.results.slice(-1)[0]?.scorePerformance || 0);
      setPerformanceDataCardData(dataCardData);
    } catch (error) {
      console.error('An error occurred while fetching performance data:', error);
    }
  };

  // Fetch PersonalAuslastung Data
  const fetchPersonalData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(6, 'month');

      const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Krankenquote: item.scoreKrankenquote,
            Fluktuation: item.scoreFluktuation,
            Bedarf: item.scoreKöpfeStunden,
            Auslastung: item.scoreÜberstunden,
          }));

      setPersonalScore(response.data.results.slice(-1)[0]?.scorePersonal || 0);
      setPersonalDataCardData(dataCardData);
    } catch (error) {
      console.error('An error occurred while fetching personal data:', error);
    }
  };

  // Fetch Ware (Wachstum) Data
  const fetchWareData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(6, 'month');

      const response = await axiosInstance.get('scorecards_month/ware-filiale-monat/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Wachstum: item.scoreProduktwachstumFiliale,
            Verfügbarkeit: item.scoreVerfügbarkeitFiliale,
            RetoureA: item.scoreSollRetoureFiliale,
            RetoureBC: item.scoreRetourQuoteFilialeBC,
          }));

      setWareScore(response.data.results.slice(-1)[0]?.scoreWareFiliale || 0);
      setWareDataCardData(dataCardData);
    } catch (error) {
      console.error('An error occurred while fetching ware data:', error);
    }
  };

  return (
      <FilialeMonatLayout>
        <Grid container spacing={2} className="db_cockpit">
          {/* PerformanceKundenbon Data Cards */}
          <Grid item xs={12} md={4}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between"
                     style={{padding: "0 calc(2rem + 2px) 0 0"}}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TimelineIcon/>
                  <Typography variant="h4">Performance</Typography>
                </Stack>
                <Score score={performanceScore}/>
              </Stack>
              {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                  <DataCard
                      key={index}
                      data={performanceDataCardData}
                      category={category}
                      sector="performance"
                  />
              ))}
            </Stack>
          </Grid>

          {/* PersonalAuslastung Data Cards */}
          <Grid item xs={12} md={4}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between"
                     style={{padding: "0 calc(2rem + 2px) 0 0"}}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <GroupIcon/>
                  <Typography variant="h4">Personal</Typography>
                </Stack>
                <Score score={personalScore}/>
              </Stack>
              {['Krankenquote', 'Fluktuation', 'Bedarf', 'Auslastung'].map((category, index) => (
                  <DataCard
                      key={index}
                      data={personalDataCardData}
                      category={category}
                      sector="personal"
                  />
              ))}
            </Stack>
          </Grid>

          {/* Ware (Wachstum) Data Cards */}
          <Grid item xs={12} md={4}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between"
                     style={{padding: "0 calc(2rem + 2px) 0 0"}}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <BakeryDiningIcon/>
                  <Typography variant="h4">Ware</Typography>
                </Stack>
                <Score score={wareScore}/>
              </Stack>
              {['Wachstum', 'Verfügbarkeit', 'RetoureA', 'RetoureBC'].map((category, index) => (
                  <DataCard
                      key={index}
                      data={wareDataCardData}
                      category={category}
                      sector="ware"
                      specialTitle={
                        category === "RetoureA"
                            ? "Retoure A" : category === "RetoureBC"
                                ? "Retoure B/C" : null
                      }
                  />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </FilialeMonatLayout>
  );
};

export default Cockpit;
