import React, { useEffect, useState } from 'react';
import Popup from '../../../popup/Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import dayjs from 'dayjs';

// Import des (unveränderten) WarengruppeArtikelPopup – sofern dieser ebenfalls auf Verkaufsmenge angepasst werden soll,
// müsstest du ihn analog anpassen.
import WarengruppeArtikelPopup from './WarengruppeArtikelPopup';

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
    if (value === null || value === undefined) return '0,00';
    return value
        .toFixed(fractionDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const FilialeWarengruppePopup = ({ open, onClose, filiale, filterState }) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    // Zustandsvariablen für das WarengruppeArtikelPopup
    const [warengruppeArtikelPopupOpen, setWarengruppeArtikelPopupOpen] = useState(false);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

    useEffect(() => {
        if (open) {
            fetchData();
        }
        
    }, [open]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [year, weekNumber] = filterState.datumWoche.value.split('.').map(Number);
            const weekStart = dayjs().year(year).isoWeek(weekNumber).startOf('isoWeek');
            const weekEnd = dayjs().year(year).isoWeek(weekNumber).endOf('isoWeek');

            // Erstellung der Tage der Woche
            const daysOfWeek = [];
            for (let i = 0; i < 7; i++) {
                daysOfWeek.push(weekStart.add(i, 'day').format('YYYY-MM-DD'));
            }

            // Beispielhafter Endpunkt: /fact/facts/
            // Hier wird angenommen, dass "verkaufsmenge" anstelle von "retourwert" im Datensatz vorhanden ist.
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    filiale: filiale,
                    startdatum: weekStart.format('YYYY-MM-DD'),
                    enddatum: weekEnd.format('YYYY-MM-DD'),
                },
            });

            // Gruppierung der Daten nach Warengruppe und Datum
            const dataByWarengruppe = {};

            response.data.results.forEach((item) => {
                const warengruppe = item.warengruppe || 'Unbekannt';
                const date = dayjs(item.datum).format('YYYY-MM-DD');

                if (!dataByWarengruppe[warengruppe]) {
                    dataByWarengruppe[warengruppe] = { warengruppe };
                }

                // Hier statt retourwert einfach die verkaufsmenge addieren
                if (!dataByWarengruppe[warengruppe][date]) {
                    dataByWarengruppe[warengruppe][date] = 0;
                }
                dataByWarengruppe[warengruppe][date] += item.verkaufsmenge || 0;
            });

            // Berechnung der Totals und Formatierung
            const popupData = Object.values(dataByWarengruppe)
                .map((wgData) => {
                    let total = 0;
                    daysOfWeek.forEach((date) => {
                        if (wgData[date]) {
                            total += wgData[date];
                            wgData[date] = formatNumber(wgData[date]);
                        } else {
                            wgData[date] = formatNumber(0);
                        }
                    });
                    wgData['Total'] = formatNumber(total);
                    return wgData;
                })
                // Filtern der Warengruppen mit einem Gesamtwert > 0
                .filter((wgData) => {
                    const totalValue = parseFloat(
                        wgData['Total'].replace(/\./g, '').replace(',', '.')
                    );
                    return totalValue > 0;
                });

            // Erstellung der Spalten für das Popup, inkl. Total
            const popupColumns = [
                { accessorKey: 'warengruppe', header: 'Warengruppe', align: 'left' },
                ...daysOfWeek.map((date) => ({
                    accessorKey: date,
                    header: dayjs(date).format('ddd DD.MM.'),
                    align: 'right',
                })),
                { accessorKey: 'Total', header: 'Total', align: 'right' },
            ];

            setData(popupData);
            setColumns(popupColumns);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Popup-Daten:', error);
            setLoading(false);
        }
    };

    // Event-Handler für das Klicken auf eine Zeile
    const handleRowClick = (row) => {
        const warengruppe = row.warengruppe;
        setSelectedWarengruppe(warengruppe);
        setWarengruppeArtikelPopupOpen(true);
    };

    return (
        <>
            <Popup
                open={open}
                title={`Verkaufsmenge für Filiale ${filiale}`}
                onClose={onClose}
                fullWidth
                maxWidth={false}
            >
                <BigTableCard
                    data={data}
                    columns={columns}
                    loading={loading}
                    onRowClick={handleRowClick}
                />
            </Popup>

            {/* WarengruppeArtikelPopup (falls du dieses ebenfalls anpassen möchtest, müsstest du dort analog die verkaufsmenge statt retourwert verwenden) */}
            {warengruppeArtikelPopupOpen && (
                <WarengruppeArtikelPopup
                    open={warengruppeArtikelPopupOpen}
                    onClose={() => setWarengruppeArtikelPopupOpen(false)}
                    filiale={filiale}
                    warengruppe={selectedWarengruppe}
                    filterState={filterState}
                />
            )}
        </>
    );
};

export default FilialeWarengruppePopup;
