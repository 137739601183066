import React, {useContext, useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../../provider/authProvider";
import {Box} from "@mui/material";
import PictureUploadButton from "./PictureUploadButton";
import axiosInstance from "../../services/axiosInstance";
import {InfoBarContext} from "../../provider/InfoBar";

const smallSize = 35;

const ProfilePictureWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const ProfilePicture = ({altText = 'Profile Picture', size = 45, withText = false, smallVersion = false}) => {
  const {user: currentUser, fetchUserData} = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const {showError } = useContext(InfoBarContext);

  const didRun = useRef(false);

  useEffect(() => {
    console.log("WANTING TO RUN")
    if (didRun.current) return; // Prevent re-execution
    didRun.current = true;

    console.log("RUNNING", );
    if (currentUser && currentUser.profile_picture_id) {
      getProfilePictureUrl();
    }
  }, []);

  async function getProfilePictureUrl() {
    try {
      const response = await axiosInstance.get(`/pictures/get-image-url/${currentUser.profile_picture_id}/`);
      const image_url = response.data.url;
      if (image_url) {
        setImageUrl(image_url);
      }
    } catch (error) {
      console.error('Fehler beim Laden des Profilbildes:', error);
      showError('Fehler beim Laden des Profilbildes');
    }
  }

  const afterImageUpload = async (image_id) => {
    try {
      // Update profile picture id of current user object
      await axiosInstance.post('/auth/profile-picture/', {
        profile_picture_id: image_id,
      });
      await fetchUserData();
      await getProfilePictureUrl();
    } catch (error) {
      console.error('Fehler beim hochladen des Profil bildes:', error);
      showError('Fehler beim hochladen des Profil bildes');
    }
    handleClose();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/logout');
    handleClose();
  };

  return (
      <ProfilePictureWrapper>
        {smallVersion ? (
            <Button
                onClick={handleOpen}
                sx={{
                  padding: 0,
                  minWidth: 0, // Prevent unnecessary padding or width
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt:2,
                  borderRadius: '50%',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                }}
            >
              <Avatar
                  sx={{
                    width: smallSize,
                    height: smallSize,
                    border: '2px solid #ccc'
                  }}
              >
                {imageUrl ? (
                    <img src={imageUrl} alt={altText} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                ) : (
                    <AccountCircleIcon sx={{fontSize: smallSize * 0.6}}/>
                )}
              </Avatar>
            </Button>
        ) : (
            <Button
                onClick={handleOpen}
                sx={{
                  padding: 0,
                  pr: 1,
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mt: 2,
                  borderRadius: '24px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                }}
            >
              <Avatar
                  sx={{
                    width: size,
                    height: size,
                    border: '2px solid #ccc'
                  }}
              >
                {imageUrl ? (
                    <img src={imageUrl} alt={altText} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                ) : (
                    <AccountCircleIcon sx={{fontSize: size}}/>
                )}

              </Avatar>
              {withText && (
                  <Box>{currentUser?.username}</Box>
              )}
            </Button>
        )}
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Enhanced shadow
                borderRadius: '8px', // Optional: Rounded corners for a softer look
              },
            }}
        >
          <div style={{padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <PictureUploadButton iconButton={false} afterImageUploadFunction={afterImageUpload}/>
            <Button variant="outlined" color="error" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Popover>

      </ProfilePictureWrapper>
  );
};

export default ProfilePicture;
