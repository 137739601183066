import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  ListItemSecondaryAction,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CircularProgress from '@mui/material/CircularProgress';

import Layout from '../../../components/layout/Aufgaben/AufgabenLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AufgabeErstellenDialog from '../../../components/Aufgaben/Aufgaben/AufgabeErstellenDialog';

import axiosInstance from '../../../services/axiosInstance';
import { useTheme } from '@mui/material/styles';

// =============================================
// 1) Inline-Komponente: Kategorienverwaltung
// =============================================
function DialogKategorien({
  open,
  onClose,
  categories,
  onCreateCategory,
  onUpdateCategory,
  onDeleteCategory,
}) {
  // Neue Kategorie
  const [newCatName, setNewCatName] = useState('');
  const [newCatDescription, setNewCatDescription] = useState('');

  // Bearbeitung
  const [editCatId, setEditCatId] = useState(null);
  const [editCatName, setEditCatName] = useState('');
  const [editCatDescription, setEditCatDescription] = useState('');

  // Neue Kategorie erstellen
  const handleAddNewCategory = () => {
    if (!newCatName.trim()) return;
    onCreateCategory({
      name: newCatName.trim(),
      description: newCatDescription.trim(),
    });
    setNewCatName('');
    setNewCatDescription('');
  };

  // Bearbeiten starten
  const handleStartEdit = (cat) => {
    setEditCatId(cat.id);
    setEditCatName(cat.name);
    setEditCatDescription(cat.description || '');
  };

  // Bearbeiten abbrechen
  const handleCancelEdit = () => {
    setEditCatId(null);
    setEditCatName('');
    setEditCatDescription('');
  };

  // Bearbeiten speichern
  const handleSaveEdit = () => {
    if (!editCatName.trim()) return;
    onUpdateCategory(editCatId, {
      name: editCatName.trim(),
      description: editCatDescription.trim(),
    });
    handleCancelEdit();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Kategorien verwalten</DialogTitle>
      <DialogContent dividers>
        {/* Neue Kategorie erstellen */}
        <Typography variant="subtitle1" gutterBottom>
          Neue Kategorie erstellen
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
          <TextField
            label="Name"
            fullWidth
            value={newCatName}
            onChange={(e) => setNewCatName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNewCategory}
            startIcon={<AddCircleIcon />}
          >
            ERSTELLEN
          </Button>
        </Box>

        {/* Vorhandene Kategorien */}
        {(!categories || categories.length === 0) ? (
          <Typography variant="body2">Keine Kategorien vorhanden.</Typography>
        ) : (
          <List>
            {categories.map((cat) => {
              const isEditing = editCatId === cat.id;
              return (
                <React.Fragment key={cat.id}>
                  <ListItem>
                    {isEditing ? (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField
                          variant="standard"
                          label="Name"
                          fullWidth
                          value={editCatName}
                          onChange={(e) => setEditCatName(e.target.value)}
                        />
                        <TextField
                          variant="standard"
                          label="Beschreibung"
                          fullWidth
                          value={editCatDescription}
                          onChange={(e) => setEditCatDescription(e.target.value)}
                        />
                      </Box>
                    ) : (
                      <ListItemText
                        primary={cat.name}
                        secondary={cat.description || ''}
                      />
                    )}

                    <ListItemSecondaryAction>
                      {isEditing ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveEdit}
                          >
                            Speichern
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelEdit}
                            sx={{ ml: 1 }}
                          >
                            Abbrechen
                          </Button>
                        </>
                      ) : (
                        <>
                          <IconButton onClick={() => handleStartEdit(cat)} sx={{ mr: 1 }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => onDeleteCategory(cat.id)}
                            sx={{ color: 'red' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>SCHLIESSEN</Button>
      </DialogActions>
    </Dialog>
  );
}

// =============================================
// 2) Hauptkomponente
// =============================================
const AufgabenKonfigurationPage = () => {
  const theme = useTheme();

  // Aufgaben
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);

  // Kategorien
  const [taskCategories, setTaskCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  // Dialog: neue Aufgabe
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  // Dialog: Aufgabe bearbeiten
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');

  // Dialog: Aufgabe löschen
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Toggle: inaktive Aufgaben anzeigen
  const [showInactive, setShowInactive] = useState(false);

  // Kategorien-Dialog (zentral)
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);

  // --------------------------------------------
  // 1) Aufgaben & Kategorien laden
  // --------------------------------------------
  const fetchTasks = async () => {
    try {
      setLoadingTasks(true);
      const response = await axiosInstance.get('/aufgaben/task-templates/');
      const data = response.data.results || [];
      setTaskTemplates(data);
      setLoadingTasks(false);
    } catch (error) {
      console.error('Fehler beim Laden der Aufgaben:', error);
      setTaskTemplates([]);
      setLoadingTasks(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoadingCategories(true);
      const response = await axiosInstance.get('/aufgaben/task-categories/');
      const data = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setTaskCategories(data);
      setLoadingCategories(false);
    } catch (error) {
      console.error('Fehler beim Laden der Kategorien:', error);
      setTaskCategories([]);
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchTasks();
    fetchCategories();
  }, []);

  // --------------------------------------------
  // 2) Dialog: Neue Aufgabe
  // --------------------------------------------
  const handleAddOpen = () => {
    setAddDialogOpen(true);
  };
  const handleAddClose = () => {
    setAddDialogOpen(false);
    fetchTasks();
  };

  // --------------------------------------------
  // 3) Aufgabe bearbeiten
  // --------------------------------------------
  const handleEdit = (template) => {
    setSelectedTemplate(template);
    setEditedTitle(template.title);
    setEditedDescription(template.description || '');
    setEditDialogOpen(true);
  };
  const handleEditSave = async () => {
    if (!selectedTemplate) return;
    try {
      await axiosInstance.put(`/aufgaben/task-templates/${selectedTemplate.id}/`, {
        title: editedTitle,
        description: editedDescription,
      });
      setEditDialogOpen(false);
      setSelectedTemplate(null);
      fetchTasks();
    } catch (error) {
      console.error('Fehler beim Speichern der Aufgabe:', error);
    }
  };
  const handleEditCancel = () => {
    setEditDialogOpen(false);
    setSelectedTemplate(null);
  };

  // --------------------------------------------
  // 4) Aufgabe löschen
  // --------------------------------------------
  const handleDeleteClick = (template) => {
    setSelectedTemplate(template);
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    if (!selectedTemplate) return;
    try {
      await axiosInstance.delete(`/aufgaben/task-templates/${selectedTemplate.id}/`);
      setDeleteDialogOpen(false);
      setSelectedTemplate(null);
      fetchTasks();
    } catch (error) {
      console.error('Fehler beim Löschen der Aufgabe:', error);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedTemplate(null);
  };

  // --------------------------------------------
  // 5) Kategorien: zentrales Dialog-Fenster
  // --------------------------------------------
  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
  };
  const handleCloseCategoryModal = () => {
    setCategoryModalOpen(false);
  };

  const handleCreateCategory = async ({ name, description }) => {
    try {
      await axiosInstance.post('/aufgaben/task-categories/', {
        name,
        description,
      });
      fetchCategories();
    } catch (err) {
      console.error('Fehler beim Anlegen der Kategorie:', err);
    }
  };

  const handleUpdateCategory = async (id, { name, description }) => {
    try {
      await axiosInstance.put(`/aufgaben/task-categories/${id}/`, {
        name,
        description,
      });
      fetchCategories();
    } catch (err) {
      console.error('Fehler beim Updaten der Kategorie:', err);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axiosInstance.delete(`/aufgaben/task-categories/${id}/`);
      fetchCategories();
    } catch (err) {
      console.error('Fehler beim Löschen der Kategorie:', err);
    }
  };

  // --------------------------------------------
  // 6) is_active Toggle
  // --------------------------------------------
  const handleToggleIsActive = async (template) => {
    const newIsActive = !template.is_active;
    try {
      await axiosInstance.patch(`/aufgaben/task-templates/${template.id}/`, {
        is_active: newIsActive,
      });
      fetchTasks();
    } catch (err) {
      console.error('Fehler beim Umschalten von is_active:', err);
    }
  };

  // --------------------------------------------
  // 7) Inaktive Aufgaben ein-/ausblenden
  // --------------------------------------------
  const handleToggleInactive = () => {
    setShowInactive((prev) => !prev);
  };

  // --------------------------------------------
  // 8) Hilfsfunktionen: Tasks filtern & gruppieren
  // --------------------------------------------
  // Nur aktive oder auch inaktive?
  const tasksToShow = useMemo(() => {
    return showInactive
      ? taskTemplates // alle
      : taskTemplates.filter((t) => t.is_active);
  }, [taskTemplates, showInactive]);

  // tasks nach category gruppieren
  const groupedByCategory = useMemo(() => {
    const map = {};
    tasksToShow.forEach((tmpl) => {
      const cat = tmpl.category || 'NO_CAT'; // fallback
      if (!map[cat]) map[cat] = [];
      map[cat].push(tmpl);
    });
    return map;
  }, [tasksToShow]);

  // Sortiere categories so, dass "Ohne Kategorie" am Ende
  const sortedCategories = useMemo(() => {
    const catMap = {};
    taskCategories.forEach((c) => {
      catMap[c.id] = c;
    });

    const catKeys = Object.keys(groupedByCategory);
    const realCats = catKeys.filter((ck) => ck !== 'NO_CAT');
    const noCats = catKeys.filter((ck) => ck === 'NO_CAT');

    // alphabetisch sortieren
    realCats.sort((a, b) => {
      const nameA = catMap[a]?.name?.toLowerCase() || '';
      const nameB = catMap[b]?.name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });

    return [...realCats, ...noCats];
  }, [groupedByCategory, taskCategories]);

  // --------------------------------------------
  // Render
  // --------------------------------------------
  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader title="Liste der Aufgaben" subtitle="" Icon={ChecklistIcon} />

        {/* Buttons */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mb: 2,
            justifyContent: 'flex-end', // <-- Buttons alle rechtsbündig
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={handleAddOpen}
            sx={{ borderRadius: '50px' }}
          >
            Aufgabe hinzufügen
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={handleOpenCategoryModal}
            sx={{ borderRadius: '50px' }}
          >
            Kategorien verwalten
          </Button>
          <Button
            variant="outlined"
            color={showInactive ? 'secondary' : 'primary'}
            sx={{ borderRadius: '50px' }}
            onClick={handleToggleInactive}
          >
            {showInactive ? 'Inaktive ausblenden' : 'Inaktive anzeigen'}
          </Button>
        </Box>

        {/* Aufgabenliste (ggf. Ladeindikator) */}
        {loadingTasks ? (
          <CircularProgress />
        ) : (
          <>
            {sortedCategories.map((catId) => {
              const tasks = groupedByCategory[catId];
              if (!tasks || tasks.length === 0) return null;

              let catObj = null;
              if (catId !== 'NO_CAT') {
                catObj = taskCategories.find((c) => c.id === catId);
              }
              const categoryName = catObj ? catObj.name : 'Ohne Kategorie';

              return (
                <Paper key={catId} sx={{ mb: 4, p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {categoryName}
                  </Typography>

                  <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {tasks.map((template) => (
                      <React.Fragment key={template.id}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          secondaryAction={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {/* is_active Schalter */}
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={!!template.is_active}
                                    onChange={() => handleToggleIsActive(template)}
                                    color="primary"
                                  />
                                }
                                label=""
                                sx={{ marginRight: 1 }}
                              />

                              {/* Bearbeiten */}
                              <IconButton
                                edge="end"
                                onClick={() => handleEdit(template)}
                                sx={{ mr: 1 }}
                              >
                                <EditIcon />
                              </IconButton>

                              {/* Löschen */}
                              <IconButton
                                edge="end"
                                onClick={() => handleDeleteClick(template)}
                                sx={{ color: theme.palette.error.main }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {template.title}
                              </Typography>
                            }
                            secondary={
                              template.description ? (
                                <Typography variant="body2">{template.description}</Typography>
                              ) : null
                            }
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              );
            })}
          </>
        )}
      </Box>

      {/* Dialog: Neue Aufgabe erstellen */}
      <AufgabeErstellenDialog open={addDialogOpen} handleClose={handleAddClose} />

      {/* Dialog: Aufgabe bearbeiten */}
      <Dialog open={editDialogOpen} onClose={handleEditCancel} maxWidth="sm" fullWidth>
        <DialogTitle>Aufgabe bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Titel"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Beschreibung"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel}>Abbrechen</Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog: Aufgabe löschen */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Aufgabe löschen</DialogTitle>
        <DialogContent>
          <Typography>
            Möchten Sie diese Aufgabe wirklich löschen?
            <br />
            {selectedTemplate && <strong>{selectedTemplate.title}</strong>}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Abbrechen</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="error">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog: Kategorien verwalten (zentral) */}
      <DialogKategorien
        open={categoryModalOpen}
        onClose={handleCloseCategoryModal}
        categories={taskCategories}
        onCreateCategory={handleCreateCategory}
        onUpdateCategory={handleUpdateCategory}
        onDeleteCategory={handleDeleteCategory}
      />
    </Layout>
  );
};

export default AufgabenKonfigurationPage;
