import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from '../../services/axiosInstance'; // Pfad anpassen

/**
 * @param {Object} value - Ausgewähltes Objekt (z.B. { filialnummer, filiale } oder null)
 * @param {Function} onChange - Callback, der bei Änderungen aufgerufen wird (newValue als Objekt)
 */
const SingleFilialeSelect = ({ value, onChange }) => {
  const [filialen, setFilialen] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get('/stammdaten/stammdatenfiliale/')
      .then((response) => {
        const data = response.data;
        if (data && data.results) {
          // Mappen auf { filialnummer, filiale }
          const mappedFilialen = data.results.map((item) => ({
            filialnummer: item.filialnummer,
            filiale: item.filiale,
          }));
          setFilialen(mappedFilialen);
        }
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Filialdaten:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Autocomplete
      loading={loading}
      options={filialen}
      // Zeigt NUR den Filialnamen an
      getOptionLabel={(option) => option.filiale || ''}
      value={value}  // z.B. { filialnummer: 7057, filiale: "Saarlouis, Lidl 7057" }
      onChange={(event, newValue) => {
        onChange(newValue);  // Gibt das vollständige Objekt zurück
      }}
      isOptionEqualToValue={(option, val) =>
        option.filialnummer === val?.filialnummer
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Filiale"
          variant="outlined"
          size="small"
        />
      )}
      sx={{ width: 300, marginBottom: 2 }}
    />
  );
};

export default SingleFilialeSelect;
