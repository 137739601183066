import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';

const TopicDialog = ({ open, onClose, topicName, setTopicName, handleSaveTopic, editingTopic }) => {
    const [entries, setEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState(null);

    useEffect(() => {
        if (open) {
            // Lade alle Entries
            axiosInstance.get('/wiki/entries_all/')
                .then(response => {
                    setEntries(response.data.results);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Entries:', error);
                });
        } else {
            // Dialog geschlossen, reset state
            setSelectedEntry(null);
        }
    }, [open]);

    useEffect(() => {
        if (open && entries.length > 0 && editingTopic) {
            // Prüfe, ob ein wiki_link vorhanden ist
            const wiki_link = editingTopic.wiki_link;
            if (wiki_link) {
                // Beispiel: http://demo.localhost:3000/wiki/overview/verkauf/bestellungen/aufschnitt
                // Wir müssen manual_slug, chapter, entry_slug extrahieren
                // Teile die URL an '/'
                const parts = wiki_link.split('/');
                // parts könnte so aussehen: ["http:", "", "demo.localhost:3000", "wiki", "overview", "verkauf", "bestellungen", "aufschnitt"]
                // manual_slug = parts[6] (index kann variieren, wir suchen 'overview' im Array)
                const overviewIndex = parts.indexOf('overview');
                // overviewIndex + 1 = manual_slug
                // overviewIndex + 2 = chapter
                // overviewIndex + 3 = entry_slug
                if (overviewIndex !== -1 && parts.length > overviewIndex + 3) {
                    const manual_slug = parts[overviewIndex + 1];
                    const chapter = parts[overviewIndex + 2];
                    const entry_slug = parts[overviewIndex + 3];

                    // Finde den Entry mit den passenden Feldern
                    const entry = entries.find(e =>
                        e.manual.slug === manual_slug &&
                        e.chapter === chapter &&
                        e.slug === entry_slug
                    );

                    if (entry) {
                        setSelectedEntry(entry);
                    } else {
                        setSelectedEntry(null);
                    }
                } else {
                    setSelectedEntry(null);
                }
            } else {
                // Kein wiki_link
                setSelectedEntry(null);
            }
        } else if (open && entries.length > 0 && !editingTopic) {
            // Neues Topic, kein wiki_link
            setSelectedEntry(null);
        }
    }, [open, entries, editingTopic]);

    const handleEntryChange = (event) => {
        const entryId = event.target.value;
        const entry = entries.find(e => e.id === entryId);
        setSelectedEntry(entry);
    };

    // Wiki-URL basierend auf selectedEntry bauen, wenn vorhanden
    const wikiUrl = selectedEntry 
        ? `${window.location.origin}/wiki/overview/${selectedEntry.manual.slug}/${selectedEntry.chapter}/${selectedEntry.slug}`
        : '';

    const onSave = () => {
        handleSaveTopic(topicName, wikiUrl); 
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{editingTopic ? 'Thema bearbeiten' : 'Neues Thema anlegen'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Thema Name"
                    fullWidth
                    value={topicName}
                    onChange={(e) => setTopicName(e.target.value)}
                    margin="normal"
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="entry-label">Eintrag auswählen</InputLabel>
                    <Select
                        labelId="entry-label"
                        value={selectedEntry ? selectedEntry.id : ''}
                        label="Eintrag auswählen"
                        onChange={handleEntryChange}
                    >
                        <MenuItem value="">
                            <em>Keinen Eintrag ausgewählt</em>
                        </MenuItem>
                        {entries.map(entry => (
                            <MenuItem key={entry.id} value={entry.id}>
                                {entry.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {wikiUrl && (
                    <TextField
                        label="Wiki Link"
                        fullWidth
                        value={wikiUrl}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant="contained" color="primary">Speichern</Button>
                <Button onClick={onClose} variant="outlined">Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TopicDialog;
