import React, {useContext, useEffect, useState} from 'react';
import {Alert, Grid, Paper} from '@mui/material';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import ComparisonCard from '../../../components/card/ComparisonCard';
import {FilterContext} from "../../../provider/FilterProvider";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigTableCardPersonal from "../../../components/card/BigTableCardPersonal";
import EmployeeDetailsPopup from "../../../components/popup/EmployeeDetailsPopup";
import {getAlertSeverity} from "../../../components/utility/AlertPopup";
import TAPersonalBedarf from '../../../components/scorecards/tiefenanalyse/TAPersonalBedarf';
import FilialeWocheLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";


const Bedarf = () => {
  const [comparisonCardData, setComparisonCardData] = useState([]);
  const [dataCardData, setDataCardData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {filterState} = useContext(FilterContext);
  const activeCategory = 'Bedarf';

  useEffect(() => {
    if (filterState.filiale && filterState.datum) {
      fetchData();
      fetchTableData();
    }
  }, [filterState.filiale, filterState.datum]);

  const fetchData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(6, 'month');

      const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Krankenquote: item.scoreKrankenquote,
            Fluktuation: item.scoreFluktuation,
            Bedarf: item.scoreKöpfeStunden,
            Auslastung: item.scoreÜberstunden,
          }));

      const comparisonCardData = response.data.results.slice(-1);

      setComparisonCardData(comparisonCardData);
      setDataCardData(dataCardData);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  const fetchTableData = async () => {
    try {
      const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
        params: {
          filiale: filterState.filiale?.label
        }
      });

      const filteredMonth = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');

      // Die gleiche Filterlogik wie in der Fluktuation-Komponente
      const dataTableData = response.data.results
          .filter(item => {
            const austrittDatum = item.austritt ? dayjs(item.austritt, 'YYYY-MM-DD') : null;
            const eintrittDatum = item.eintritt ? dayjs(item.eintritt, 'YYYY-MM-DD') : null;

            // Filterlogik für Eintritt und Austritt
            return (!austrittDatum || austrittDatum.isAfter(filteredMonth)) &&
                eintrittDatum.isSameOrBefore(filteredMonth);
          })
          .map(item => ({
            mitarbeiterId: item.mitarbeiterId,
            Name: item.name,
            arbeitsbereich: item.arbeitsbereich,
            arbeitnehmertyp: item.arbeitnehmertyp,
            wochensollBerechnet: item.wochensollBerechnet,
            kopf: item.kopf,
            eintritt: dayjs(item.eintritt).format('DD.MM.YYYY'),  // Eintrittsdatum formatieren
            austritt: item.austritt ? dayjs(item.austritt).format('DD.MM.YYYY') : '-',  // Austrittsdatum formatieren, falls vorhanden
          }));

      const newColumns = [
        {id: 'Name', accessorKey: 'Name', header: 'Name'},
        {id: 'arbeitsbereich', accessorKey: 'arbeitsbereich', header: 'Arbeitsbereich'},
        {id: 'arbeitnehmertyp', accessorKey: 'arbeitnehmertyp', header: 'Arbeitnehmertyp'},
        {id: 'wochensollBerechnet', accessorKey: 'wochensollBerechnet', header: 'Wochensoll h'},
        {id: 'kopf', accessorKey: 'kopf', header: 'Kopf'},
        {id: 'eintritt', accessorKey: 'eintritt', header: 'Eintritt'},
        {id: 'austritt', accessorKey: 'austritt', header: 'Austritt'},
      ];

      setTableColumns(newColumns);
      setTableData(dataTableData);

    } catch (error) {
      console.error('An error occurred while fetching table data:', error);
    }
  }

  const handleRowClick = (employee) => {
    setSelectedEmployee(employee);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedEmployee(null);
  };

  return (
      <FilialeWocheLayout>
        <Grid container justifyContent="flex-end" alignItems="stretch">
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{overflowX: 'auto'}}>
              {['Krankenquote', 'Fluktuation', 'Bedarf', 'Auslastung'].map((category, index) => (
                  <Grid
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      key={index}
                      className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                  >
                    <DataCard
                        data={dataCardData}
                        category={category}
                        sector="personal"
                    />
                  </Grid>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper className="db_datacard_active_content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}} order={{xs: 2, md: 1}}>
                  <ComparisonCard
                      zeitraum="Bedarf an Mitarbeitern mit anderen Filialen"
                      data={comparisonCardData}
                      rangField="rangKöpfeDifferenz"
                      extraField1="köpfeDifferenz"
                      extraLabel1="Köpfe   "
                      extraIcon1={<AddShoppingCartIcon sx={{color: 'action.active'}}/>}
                      scoreField="scoreKöpfeDifferenzQuote"
                      TiefenanalyseComponent={TAPersonalBedarf}
                  />
                  <ComparisonCard
                      zeitraum="Bedarf an Stunden mit anderen Filialen"
                      data={comparisonCardData}
                      rangField="rangStundenDifferenz"
                      extraField1="stundenDifferenz"
                      extraLabel1="Stundendifferenz"
                      extraIcon1={<AddShoppingCartIcon sx={{color: 'action.active'}}/>}
                      scoreField="scoreStundenDifferenz"
                      TiefenanalyseComponent={TAPersonalBedarf}
                  />
                </Grid>

                <Grid item xs={12} md={6} order={{xs: 1, md: 2}}>
                  <Alert
                      severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreKöpfeDifferenzQuote"] : null)}
                  >
                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackKöpfeStunden"] : 'Keine Rückmeldung verfügbar'}
                  </Alert>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{mt: 2}}>
                <BigTableCardPersonal
                    title={"Aktueller Personalstamm"}
                    data={tableData}
                    columns={tableColumns}
                    onRowClick={handleRowClick}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <ScorecardsNotes category="Personal"/>

        <EmployeeDetailsPopup
            open={isPopupOpen}
            onClose={handleClosePopup}
            employee={selectedEmployee}
            jahrMonat={filterState.datum?.value}
        />
      </FilialeWocheLayout>
  );
};

export default Bedarf;
