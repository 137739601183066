import React from 'react';
import GeneralLayout from '../GeneralLayout';
import Sidebar from '../GeneralSidebar';
import WindowIcon from '@mui/icons-material/Window';
import SpeedIcon from '@mui/icons-material/Speed';
import TimelineIcon from '@mui/icons-material/Timeline';
import Euro from '@mui/icons-material/Euro';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import AutoGraph from '@mui/icons-material/AutoGraph';
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';

const FilialeWocheLayout = ({children}) => {
  const menuItems = [
    {
      text: 'Cockpit',
      path: '/scorecardswoche/overview/cockpit',
      icon: <SpeedIcon/>,
      subModule: "overview",
    },
    {
      text: 'Performance',
      icon: <TimelineIcon/>,
      subModule: "performance",
      subItems: [
        {text: 'Performance', path: '/scorecardswoche/performance/performance', icon: <Euro/>},
      ],
    },
    {
      text: 'Ware',
      icon: <BakeryDiningIcon/>,
      subModule: "ware",
      subItems: [
        {text: 'Wachstum', path: '/scorecardswoche/ware/wachstum', icon: <AutoGraph/>},
        {text: 'Retoure', path: '/scorecardswoche/ware/retoure', icon: <PublishedWithChanges/>},
      ],
    },
  ];

  return (
      <GeneralLayout
          SidebarComponent={(props) => (
              <Sidebar {...props} moduleName={"scorecardswoche"} menuItems={menuItems} title="Filiale Woche" weekFilter={true} filialFilter={true}/>
          )}
      >
        {children}
      </GeneralLayout>
  );
};

export default FilialeWocheLayout;
