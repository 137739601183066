import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Alert,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Chip,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import Layout from '../../../components/layout/Filialfeed/Filialfeed';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PictureUploadButton from '../../../components/pictures/PictureUploadButton';

// Sichtbarkeits-Optionen
const visibilityOptions = ['Alle', 'Spezifische Filialen', 'Verkaufsgebiete'];

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [tagsError, setTagsError] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [saving, setSaving] = useState(false);

  // Dialog für Poll-Votes
  const [voteDialogOpen, setVoteDialogOpen] = useState(false);
  const [voteDialogPollId, setVoteDialogPollId] = useState(null);
  const [voteDialogPostId, setVoteDialogPostId] = useState(null);
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesLoading, setPollVotesLoading] = useState(false);
  const [pollVotesError, setPollVotesError] = useState(null);

  // Suchbegriff
  const [searchTerm, setSearchTerm] = useState('');

  // NEU: Lesebestätigungen
  const [readConfirmationsOpen, setReadConfirmationsOpen] = useState(false);
  const [readConfirmations, setReadConfirmations] = useState([]);
  const [readConfirmationsLoading, setReadConfirmationsLoading] = useState(false);
  const [readConfirmationsError, setReadConfirmationsError] = useState(null);
  const [selectedReadPost, setSelectedReadPost] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [postsRes, tagsRes] = await Promise.all([
          axiosInstance.get('/filialfeed/posts/'),
          axiosInstance.get('/filialfeed/tags/')
        ]);

        // Posts laden und absteigend sortieren
        let loadedPosts = postsRes.data.results || postsRes.data;
        loadedPosts.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));
        setPosts(loadedPosts);

        // Tags laden
        const tagData = tagsRes.data.results || tagsRes.data;
        setTags(tagData);

        setLoading(false);
        setTagsLoading(false);
      } catch (err) {
        console.error('Fehler beim Laden der Posts oder Tags:', err);
        setError('Fehler beim Laden der Daten');
        setLoading(false);
        setTagsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Filter: nach `title`
  const filteredPosts = posts.filter((post) => {
    const lowerSearch = searchTerm.toLowerCase();
    return post.title?.toLowerCase().includes(lowerSearch);
  });

  // future vs current
  const futurePosts = filteredPosts.filter(post => new Date(post.publish_date) > new Date());
  const currentPosts = filteredPosts.filter(post => new Date(post.publish_date) <= new Date());

  // Post löschen
  const handleDelete = async (id) => {
    if (window.confirm('Diesen Post wirklich löschen?')) {
      try {
        await axiosInstance.delete(`/filialfeed/posts/${id}/`);
        setPosts(prev => prev.filter(p => p.id !== id));
      } catch (err) {
        console.error('Fehler beim Löschen des Posts:', err);
        alert('Fehler beim Löschen des Posts.');
      }
    }
  };

  // Post bearbeiten
  const handleEdit = (post) => {
    const imageId = post.image_id || null;
    const imageUrl = post.image_url || null;

    setEditPost({
      ...post,
      tag_id: post.tag ? post.tag.id : null,
      image_id: imageId,
      image_url: imageUrl,
      allow_comments: post.allow_comments,
      requires_read_confirmation: post.requires_read_confirmation
    });
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditPost(null);
  };

  const handleEditSave = async () => {
    if (!editPost) return;
    setSaving(true);

    const {
      id,
      title,
      content,
      sender,
      tag_id,
      visibility,
      publish_date,
      image_id,
      allow_comments,
      requires_read_confirmation
    } = editPost;

    const updatedPost = {
      title,
      content,
      sender,
      tag_id,
      visibility,
      publish_date,
      image_id,
      allow_comments,
      requires_read_confirmation
    };

    try {
      await axiosInstance.put(`/filialfeed/posts/${id}/`, updatedPost);
      const newTag = tags.find(t => t.id === tag_id) || null;

      // State aktualisieren
      setPosts(prev =>
        prev.map(p =>
          p.id === id
            ? { ...p, ...updatedPost, tag: newTag }
            : p
        )
      );

      setEditDialogOpen(false);
      setEditPost(null);
    } catch (err) {
      console.error('Fehler beim Bearbeiten des Posts:', err);
      alert('Fehler beim Bearbeiten des Posts.');
    } finally {
      setSaving(false);
    }
  };

  // Bild-Upload
  const afterImageUploadFunction = async (uploadedImageId) => {
    try {
      const urlResponse = await axiosInstance.get(`/pictures/get-image-url/${uploadedImageId}/`);
      const image_url = urlResponse.data.url;

      setEditPost(prev => ({
        ...prev,
        image_id: uploadedImageId,
        image_url
      }));
    } catch (error) {
      console.error('Fehler beim Abrufen der Bild-URL:', error);
    }
  };

  // Poll-Votes
  const handleOpenVoteDialog = async (pollId, postId) => {
    setVoteDialogOpen(true);
    setVoteDialogPollId(pollId);
    setVoteDialogPostId(postId);
    setPollVotesLoading(true);
    setPollVotesError(null);

    try {
      const response = await axiosInstance.get('/filialfeed/poll-votes/', { params: { poll: pollId } });
      setPollVotes(response.data.results || response.data);
    } catch (err) {
      console.error('Fehler beim Laden der Votes:', err);
      setPollVotesError('Fehler beim Laden der Votes.');
    } finally {
      setPollVotesLoading(false);
    }
  };

  const handleCloseVoteDialog = () => {
    setVoteDialogOpen(false);
    setPollVotes([]);
    setVoteDialogPollId(null);
    setVoteDialogPostId(null);
    setPollVotesError(null);
    setPollVotesLoading(false);
  };

  const selectedPost = voteDialogPostId
    ? filteredPosts.find(p => p.id === voteDialogPostId)
    : null;

  // Lesebestätigungen
  const handleShowReadConfirmations = async (post) => {
    if (!post.requires_read_confirmation) {
      alert('Dieser Post hat keine Lesebestätigung aktiviert.');
      return;
    }

    try {
      setReadConfirmationsOpen(true);
      setSelectedReadPost(post);
      setReadConfirmationsLoading(true);
      setReadConfirmationsError(null);

      const response = await axiosInstance.get('/filialfeed/post-filiale/', {
        params: { post: post.id }
      });
      setReadConfirmations(response.data.results || response.data);

    } catch (err) {
      console.error('Fehler beim Laden der Lesebestätigungen:', err);
      setReadConfirmationsError('Fehler beim Laden der Lesebestätigungen.');
    } finally {
      setReadConfirmationsLoading(false);
    }
  };

  const handleCloseReadConfirmations = () => {
    setReadConfirmationsOpen(false);
    setReadConfirmations([]);
    setSelectedReadPost(null);
    setReadConfirmationsError(null);
    setReadConfirmationsLoading(false);
  };

  // Hilfsfunktion, um "Gelesen: X / Y" anzuzeigen
  const getReadCountDisplay = (post) => {
    if (!post.requires_read_confirmation || !post.postfiliale_set) {
      return null;
    }
    const total = post.postfiliale_set.length;
    const read = post.postfiliale_set.filter(pf => pf.is_read).length;
    return `Gelesen: ${read} / ${total}`;
  };

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1000, margin: '0 auto', bgcolor: 'transparent' }}>
        <PageHeader
          title="Post Liste"
          subtitle="Hier sehen Sie alle Posts. Sie können Posts bearbeiten, löschen, geplante Posts einsehen, Umfragen einsehen und Lesebestätigungen überprüfen."
          Icon={QuickreplyOutlinedIcon}
        />

        {/* Suchleiste */}
        <TextField
          label="Beiträge durchsuchen..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 3 }}
        />

        {loading || tagsLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : (error || tagsError) ? (
          <Alert severity="error">{error || tagsError}</Alert>
        ) : (
          <>
            {/* Zukünftige (geplante) Posts */}
            <Typography variant="h6" sx={{ mt:2 }}>
              Zukünftige (Geplante) Posts
            </Typography>
            {futurePosts.length === 0 ? (
              <Typography variant="body2">Keine zukünftigen Posts vorhanden.</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 1, mb: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Titel</TableCell>
                      <TableCell>Sichtbarkeit</TableCell>
                      <TableCell>Veröffentlichung</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell align="right">Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {futurePosts.map((post) => {
                      const pubDate = new Date(post.publish_date).toLocaleString();
                      const readText = getReadCountDisplay(post);

                      return (
                        <TableRow key={post.id}>
                          <TableCell>{post.title}</TableCell>
                          <TableCell>{post.visibility}</TableCell>
                          <TableCell>{pubDate}</TableCell>
                          <TableCell>
                            {post.tag && (
                              <Chip label={`Tag: ${post.tag.name}`} size="small" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => handleEdit(post)} color="primary">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(post.id)} color="secondary">
                              <DeleteIcon />
                            </IconButton>
                            {post.requires_read_confirmation && post.postfiliale_set && (
                              <Button
                                onClick={() => handleShowReadConfirmations(post)}
                                color="info"
                                variant="outlined"
                                size="small"
                                sx={{ ml: 1 }}
                              >
                                {readText}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Divider sx={{my:2}} />

            {/* Aktuelle / vergangene Posts */}
            <Typography variant="h6" sx={{ mt:2 }}>
              Aktuelle / Vergangene Posts
            </Typography>
            {currentPosts.length === 0 ? (
              <Typography variant="body2">
                Keine aktuellen oder vergangenen Posts.
              </Typography>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Titel</TableCell>
                      <TableCell>Sichtbarkeit</TableCell>
                      <TableCell>Veröffentlicht am</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell align="right">Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPosts.map((post) => {
                      const publishDate = new Date(post.publish_date).toLocaleString();
                      const readText = getReadCountDisplay(post);

                      return (
                        <TableRow key={post.id}>
                          <TableCell>{post.title}</TableCell>
                          <TableCell>{post.visibility}</TableCell>
                          <TableCell>{publishDate}</TableCell>
                          <TableCell>
                            {post.tag && (
                              <Chip label={`Tag: ${post.tag.name}`} size="small" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => handleEdit(post)} color="primary">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(post.id)} color="secondary">
                              <DeleteIcon />
                            </IconButton>
                            {/* Poll-Übersicht */}
                            {post.poll && (
                              <IconButton
                                onClick={() => handleOpenVoteDialog(post.poll.id, post.id)}
                                color="info"
                              >
                                <PeopleIcon />
                              </IconButton>
                            )}
                            {/* Lesebestätigung */}
                            {post.requires_read_confirmation && post.postfiliale_set && (
                              <Button
                                onClick={() => handleShowReadConfirmations(post)}
                                color="info"
                                variant="outlined"
                                size="small"
                                sx={{ ml: 1 }}
                              >
                                {readText}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>

      {/* Dialoge (Edit, Poll-Votes, Lesebestätigungen) bleiben wie in deinem bisherigen Code */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Post bearbeiten</DialogTitle>
        {editPost && (
          <DialogContent>
            <TextField
              label="Titel"
              variant="outlined"
              fullWidth
              value={editPost.title || ''}
              onChange={(e) => setEditPost(prev => ({...prev, title:e.target.value}))}
              sx={{ mb:2 }}
              required
            />
            <Typography variant="subtitle2" sx={{ mb:1 }}>Inhalt</Typography>
            <ReactQuill
              value={editPost.content || ''}
              onChange={(value) => setEditPost(prev => ({...prev, content:value}))}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image'],
                  ['clean'],
                ],
              }}
              style={{ marginBottom: '16px' }}
            />

            <TextField
              label="Absender"
              variant="outlined"
              fullWidth
              value={editPost.sender || ''}
              onChange={(e) => setEditPost(prev => ({...prev, sender:e.target.value}))}
              sx={{ mb:2 }}
              required
              InputProps={{
                readOnly: true
              }}
            />

            {/* Tag-Auswahl */}
            <TextField
              label="Tag"
              variant="outlined"
              fullWidth
              select
              value={editPost.tag_id || ''}
              onChange={(e) => setEditPost(prev => ({...prev, tag_id:e.target.value}))}
              sx={{ mb:2 }}
              required
            >
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </TextField>

            {/* Bild bearbeiten */}
            <Typography variant="subtitle2" sx={{ mb:1 }}>Bild</Typography>
            {editPost.image_url ? (
              <Box sx={{ mb:2 }}>
                <Typography variant="body2">Aktuelles Bild:</Typography>
                <img
                  src={editPost.image_url}
                  alt="Aktuelles Bild"
                  style={{ maxWidth: '100%', borderRadius: 8, marginBottom: '8px' }}
                />
              </Box>
            ) : (
              <Typography variant="body2" sx={{ mb:1 }}>
                Kein Bild vorhanden.
              </Typography>
            )}
            <PictureUploadButton
              title={editPost.title || 'Bild bearbeiten'}
              afterImageUploadFunction={afterImageUploadFunction}
              iconButton={false}
            />

            {/* Sichtbarkeit */}
            <TextField
              label="Sichtbarkeit"
              variant="outlined"
              fullWidth
              select
              value={editPost.visibility || 'Alle'}
              onChange={(e) => setEditPost(prev => ({...prev, visibility:e.target.value}))}
              sx={{ mb:2 }}
              required
            >
              {visibilityOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            {/* Veröffentlichungsdatum */}
            <TextField
              label="Veröffentlichungsdatum"
              type="datetime-local"
              fullWidth
              value={editPost.publish_date ? editPost.publish_date.slice(0,16) : ''}
              onChange={(e) => {
                setEditPost(prev => ({...prev, publish_date: e.target.value + ':00'}));
              }}
              InputLabelProps={{ shrink: true }}
              sx={{ mb:2 }}
              required
            />

            <Divider sx={{ my:2 }} />

            {/* Kommentare erlauben */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(editPost.allow_comments)}
                  onChange={(e) =>
                    setEditPost(prev => ({
                      ...prev,
                      allow_comments: e.target.checked
                    }))
                  }
                  color="primary"
                />
              }
              label="Kommentare erlauben?"
              sx={{ mb: 2 }}
            />

            {/* Lesebestätigung erforderlich */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(editPost.requires_read_confirmation)}
                  onChange={(e) =>
                    setEditPost(prev => ({
                      ...prev,
                      requires_read_confirmation: e.target.checked
                    }))
                  }
                  color="primary"
                />
              }
              label="Lesebestätigung erforderlich?"
              sx={{ mb: 2 }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleEditDialogClose} disabled={saving}>
            Abbrechen
          </Button>
          <Button onClick={handleEditSave} variant="contained" color="primary" disabled={saving}>
            {saving ? 'Speichere...' : 'Speichern'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Poll-Votes Dialog */}
      <Dialog open={voteDialogOpen} onClose={handleCloseVoteDialog} fullWidth maxWidth="sm">
        <DialogTitle>Abstimmungsübersicht</DialogTitle>
        <DialogContent>
          {pollVotesLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : pollVotesError ? (
            <Alert severity="error">{pollVotesError}</Alert>
          ) : (
            selectedPost && selectedPost.poll && (
              <>
                <Box sx={{ mb:2 }}>
                  <Typography variant="h6">
                    {selectedPost.poll.question}
                  </Typography>
                  {selectedPost.poll.options.map(opt => (
                    <Typography key={opt.id} variant="body2" sx={{ pl:2 }}>
                      {opt.option_text} ({opt.votes_count})
                    </Typography>
                  ))}
                </Box>
                <Divider sx={{my:2}} />
                {selectedPost.poll.options.map(opt => {
                  const votesForOption = pollVotes.filter(v => v.option_id === opt.id);
                  return (
                    <Box key={opt.id} sx={{ mb:2 }}>
                      <Typography variant="body1">
                        <strong>{opt.option_text}:</strong>
                      </Typography>
                      {votesForOption.length === 0 ? (
                        <Typography variant="body2" sx={{ pl:2 }}>
                          Keine Stimmen für diese Option.
                        </Typography>
                      ) : (
                        votesForOption.map(vote => (
                          <Typography
                            key={vote.id}
                            variant="body2"
                            sx={{ mb:1, pl:2 }}
                          >
                            <strong>{vote.user}</strong> hat für{' '}
                            <strong>{vote.option_text}</strong> gestimmt.
                          </Typography>
                        ))
                      )}
                    </Box>
                  );
                })}
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVoteDialog} color="primary">Schließen</Button>
        </DialogActions>
      </Dialog>

      {/* Lesebestätigungen-Dialog */}
      <Dialog
        open={readConfirmationsOpen}
        onClose={handleCloseReadConfirmations}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Lesebestätigungen
          {selectedReadPost && (
            <Typography variant="subtitle2">
              für "{selectedReadPost.title}"
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {readConfirmationsLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : readConfirmationsError ? (
            <Alert severity="error">{readConfirmationsError}</Alert>
          ) : readConfirmations.length === 0 ? (
            <Typography variant="body2">
              Keine Einträge vorhanden.
            </Typography>
          ) : (
            readConfirmations.map((pf) => {
              // pf => { id, post, filiale, is_read, read_at, ... }
              const filialeId = pf.filiale;
              const isRead = pf.is_read;
              const readAt = pf.read_at
                ? new Date(pf.read_at).toLocaleString()
                : null;

              return (
                <Box
                  key={pf.id}
                  sx={{
                    mb:2,
                    borderBottom:'1px solid #ddd',
                    pb:1
                  }}
                >
                  <Typography variant="body1">
                    Filiale: {filialeId}
                  </Typography>
                  {isRead ? (
                    <Typography variant="body2" color="text.secondary">
                      Gelesen am: {readAt || '---'}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="error">
                      Noch nicht gelesen
                    </Typography>
                  )}
                </Box>
              );
            })
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReadConfirmations} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default PostList;
