import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import WindowIcon from '@mui/icons-material/Window';


const GesprächspilotLayout = ({children}) => {
  const menuItems = [
    {text: 'Übersicht', path: '/dokumentation/overview/index', icon: <WindowIcon/>, subModule: "overview"},
  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"dokumentation"} menuItems={menuItems} title={'Gesprächspilot'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default GesprächspilotLayout;
