// src/components/popup/unternehmenWoche/retoure/WarengruppePopup.jsx

import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import { Box, Typography } from '@mui/material';

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
    .toFixed(fractionDigits)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * Popup zum Anzeigen der Artikel-Retourdaten für eine bestimmte Warengruppe
 * und eine bestimmte Filiale.
 *
 * @param {boolean} open - Ob das Popup geöffnet ist
 * @param {function} onClose - Schließt das Popup
 * @param {string} warengruppe - Name der Warengruppe (z. B. "Brot")
 * @param {string|number} filiale - Eindeutige Kennung deiner Filiale
 * @param {string} monat - Jahr/Monat z. B. "2023.06"
 */
const WarengruppePopup = ({ open, onClose, warengruppe, filiale, monat }) => {
  const [artikelData, setArtikelData] = useState([]);
  const [artikelColumns, setArtikelColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  // Artikel-Daten beim Öffnen laden
  useEffect(() => {
    if (open) {
      fetchArtikelData();
    }
  }, [open]);

  /**
   * Ruft Artikeldaten für die gegebene Warengruppe und Filiale (und Monat) ab.
   * Achtung: Passe den Endpoint und die Param-Namen je nach Backend an.
   */
  const fetchArtikelData = async () => {
    try {
      setLoading(true);

      // Beispielhafter API-Aufruf
      const response = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
        params: {
          warengruppe: warengruppe,
          filialnummer: filiale, // <- Wichtig: Hier nur die übergebene Filiale
          jahrMonat: monat,      // <- Statt jahrKw nutzen wir hier z. B. "jahrMonat"
        },
      });

      // Aggregation der Daten pro Artikel
      const aggregatedData = {};
      response.data.results.forEach((item) => {
        const artikel = item.artikelnummer?.artikel || 'Unbekannt';
        if (!aggregatedData[artikel]) {
          aggregatedData[artikel] = {
            retourwert: 0,
            lieferwert: 0,
            korrekturwert: 0,
          };
        }
        aggregatedData[artikel].retourwert += item.retourwert || 0;
        aggregatedData[artikel].lieferwert += item.lieferwert || 0;
        aggregatedData[artikel].korrekturwert += item.korrekturwert || 0;
      });

      // Berechnung der Retourquote pro Artikel
      const data = Object.keys(aggregatedData).map((artikel, index) => {
        const agg = aggregatedData[artikel];
        const denominator = agg.lieferwert + agg.korrekturwert;
        const retourquote = denominator > 0 ? (agg.retourwert / denominator) * 100 : 0;

        return {
          id: index,
          artikel: artikel,
          retourquote: `${formatNumber(retourquote)}%`,
          retourwert: formatNumber(agg.retourwert),
          lieferwert: formatNumber(agg.lieferwert),
          korrekturwert: formatNumber(agg.korrekturwert),
        };
      });

      const columns = [
        { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
        { accessorKey: 'retourquote', header: 'Retourquote', align: 'right' },
        { accessorKey: 'retourwert', header: 'Retourwert', align: 'right' },
        { accessorKey: 'lieferwert', header: 'Lieferwert', align: 'right' },
        { accessorKey: 'korrekturwert', header: 'Korrekturwert', align: 'right' },
      ];

      setArtikelData(data);
      setArtikelColumns(columns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
      setLoading(false);
    }
  };

  return (
    <Popup
      open={open}
      title={`Warengruppe: ${warengruppe} / Filiale: ${filiale} / Monat: ${monat}`}
      onClose={onClose}
      fullWidth
      maxWidth={false}
    >
      {artikelData.length === 0 && !loading && (
        <Typography variant="body2" color="text.secondary">
          Keine Artikeldaten verfügbar.
        </Typography>
      )}

      {artikelData.length > 0 && (
        <BigTableCard
          data={artikelData}
          columns={artikelColumns}
          loading={loading}
        />
      )}
    </Popup>
  );
};

export default WarengruppePopup;
