import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WindowIcon from '@mui/icons-material/Window';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';





const FilialcheckLayout = ({children}) => {
  const menuItems = [
    {text: 'Übersicht', path: '/onboarding/overview/index', icon: <WindowIcon/>, subModule: "overview"},
    {text: 'Zuordnung', path: '/onboarding/settings/zuordnung', icon: <InsertChartOutlinedTwoToneIcon/>, subModule: "settings"},
    {text: 'Konfiguration', path: '/onboarding/settings/konfiguration', icon: <BuildOutlinedIcon/>, subModule: "settings"},
    

  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"onboarding"} menuItems={menuItems} title={'Onboarding'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default FilialcheckLayout;
