import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import BreadcrumbsNavigation from "../../../components/wiki/settings/BreadcrumbsNavigation";
import TreeViewNavigation from "../../../components/wiki/settings/TreeViewNavigation";
import EntryEditor from "../../../components/wiki/settings/EntryEditor";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {InfoBarContext} from "../../../provider/InfoBar";
import axiosInstance from '../../../services/axiosInstance';

import CreateChapterDialog from '../../../components/wiki/settings/CreateChapterDialog';
import CreateEntryDialog from '../../../components/wiki/settings/CreateEntryDialog';

// Hier definieren wir unseren Pillen-Stil für Buttons
const pillStyle = {
  borderRadius: '50px',
  fontSize: '0.75rem',
  padding: '6px 12px',
  minWidth: 'auto',
};

const ManualPage = () => {
  const {showError, showSuccess} = useContext(InfoBarContext);
  const {manualSlug, chapterSlug, entrySlug} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isSettings = location.pathname.includes('/wiki/settings/');

  const [manual, setManual] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [entries, setEntries] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [editedEntry, setEditedEntry] = useState(null);

  const [mediaList, setMediaList] = useState([]);

  const [openCreateChapterDialog, setOpenCreateChapterDialog] = useState(false);
  const [openCreateEntryDialog, setOpenCreateEntryDialog] = useState(false);

  const [newChapter, setNewChapter] = useState({title: '', description: ''});
  const [newEntry, setNewEntry] = useState({title: '', content: ''});

  const [openDialog, setOpenDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  // Zustände für das Bearbeiten von Kapitel/Eintragsnamen
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editType, setEditType] = useState(null); // 'chapter' oder 'entry'
  const [editData, setEditData] = useState({ title: '', slug: '', parentSlug: '' });

  useEffect(() => {
    fetchManualAndContent(manualSlug);
  }, [manualSlug]);

  useEffect(() => {
    if (chapters.length > 0) {
      buildTreeData();
    } else {
      setTreeData([]);
    }
  }, [chapters, entries]);

  useEffect(() => {
    if (chapterSlug && chapters.length > 0) {
      const chapter = chapters.find(chap => chap.slug === chapterSlug);
      setSelectedChapter(chapter || null);
      setSelectedEntry(null);
      setEditedContent('');
      setEditedEntry(null);

      if (!entrySlug && chapter) {
        setSelectedNodeId(`chapter-${chapter.id}`);
      }
    } else {
      setSelectedChapter(null);
      setSelectedEntry(null);
      setEditedContent('');
      setEditedEntry(null);
      setSelectedNodeId(null);
    }
  }, [chapterSlug, chapters, entrySlug]);

  useEffect(() => {
    if (entrySlug && selectedChapter) {
      fetchEntry(manualSlug, selectedChapter.slug, entrySlug);
    } else if (!entrySlug) {
      setSelectedEntry(null);
      setEditedContent('');
      setEditedEntry(null);
    }
  }, [entrySlug, selectedChapter]);

  const fetchManualAndContent = async (slug) => {
    try {
      const response = await axiosInstance.get(`/wiki/manuals/${slug}/`);
      setManual(response.data);

      const chapterRes = await axiosInstance.get(`/wiki/manuals/${slug}/chapters/`);
      const chaptersData = Array.isArray(chapterRes.data.results)
        ? chapterRes.data.results
        : Array.isArray(chapterRes.data)
          ? chapterRes.data
          : [];

      // Laden der Einträge pro Kapitel
      const entriesPromises = chaptersData.map((chapter) =>
        axiosInstance.get(`/wiki/manuals/${slug}/chapters/${chapter.slug}/entries/`)
      );
      const entriesResponses = await Promise.all(entriesPromises);

      let allEntries = [];
      entriesResponses.forEach((res, index) => {
        const entriesData = Array.isArray(res.data.results) ? res.data.results
          : Array.isArray(res.data) ? res.data
          : [];
        // Hier sicherstellen, dass jedem Eintrag chapterSlug zugewiesen wird
        entriesData.forEach((entry) => {
          entry.chapterSlug = chaptersData[index].slug;
          entry.chapterId = chaptersData[index].id;
        });
        allEntries = [...allEntries, ...entriesData];
      });

      setChapters(chaptersData);
      setEntries(allEntries);
    } catch (error) {
      console.error('Error fetching manual content:', error);
      showError('Fehler beim Laden des Manuals. Bitte versuchen Sie es später erneut.');
      navigate('/wiki');
    }
  };

  const buildTreeData = () => {
    const items = chapters.map((chapter) => {
      const chapterEntries = entries.filter((entry) => entry.chapterSlug === chapter.slug);
      return {
        id: `chapter-${chapter.id}`,
        label: chapter.title,
        chapterSlug: chapter.slug,
        chapterId: chapter.id,
        children: chapterEntries.map((entry) => ({
          id: `entry-${entry.id}`,
          label: entry.title,
          chapterSlug: entry.chapterSlug,
          entrySlug: entry.slug,
        })),
      };
    });
    setTreeData(items);
  };

  const fetchEntry = async (manualSlug, chapterSlug, entrySlug) => {
    try {
      const response = await axiosInstance.get(
        `/wiki/manuals/${manualSlug}/chapters/${chapterSlug}/entries/${entrySlug}/`
      );
      const entryData = response.data;
      setSelectedEntry(entryData);
      setEditedContent(entryData.content);
      setEditedEntry(entryData);

      await fetchMediaForEntry(entryData.id);

      const chapter = chapters.find((chap) => chap.slug === chapterSlug);
      if (chapter) {
        const entry = entries.find((e) => e.slug === entrySlug && e.chapterSlug === chapterSlug);
        if (entry) {
          setSelectedNodeId(`entry-${entry.id}`);
        }
      }
    } catch (error) {
      console.error('Fehler beim Laden des Eintrags:', error);
      showError('Fehler beim Laden des Eintrags. Bitte versuchen Sie es später erneut.');
    }
  };

  const fetchMediaForEntry = async (entryId) => {
    try {
      const response = await axiosInstance.get(`/wiki/media/?entry_id=${entryId}`);
      const mediaData =
        Array.isArray(response.data.results)
          ? response.data.results
          : Array.isArray(response.data)
          ? response.data
          : [];

      if (!Array.isArray(mediaData)) {
        console.error('Erwartetes Medienarray nicht gefunden');
        showError('Fehler beim Laden der Medien: Unerwartetes Datenformat');
        return;
      }

      const mediaWithUrls = await Promise.all(
        mediaData.map(async (media) => {
          try {
            const imageResponse = await axiosInstance.get(`/pictures/get-image-url/${media.image_id}/`);
            return { ...media, url: imageResponse.data.url };
          } catch (error) {
            console.error('Fehler beim Abrufen der Bild-URL:', error);
            return media;
          }
        })
      );

      setMediaList(mediaWithUrls);
    } catch (error) {
      console.error('Fehler beim Laden der Medien:', error);
      showError('Fehler beim Laden der Medien');
    }
  };

  const handleBreadcrumbClick = (event, level) => {
    event.preventDefault();
    if (level === 'manual') {
      navigate('/wiki/overview/index');
    } else if (level === 'chapter') {
      if (selectedChapter) {
        const baseUrl = isSettings ? '/wiki/settings' : '/wiki/overview';
        navigate(`${baseUrl}/${manualSlug}/${selectedChapter.slug}`);
      }
    }
  };

  const handleOpenCreateChapterDialogFn = () => {
    if (!manual) {
      showError('Bitte wählen Sie zuerst ein Manual aus.');
      return;
    }
    setOpenCreateChapterDialog(true);
  };

  const handleCloseCreateChapterDialog = () => {
    setOpenCreateChapterDialog(false);
    setNewChapter({ title: '', description: '' });
  };

  const handleOpenCreateEntryDialogFn = () => {
    if (!selectedChapter) {
      showError('Bitte wählen Sie zuerst ein Kapitel aus.');
      return;
    }
    setOpenCreateEntryDialog(true);
  };

  const handleCloseCreateEntryDialog = () => {
    setOpenCreateEntryDialog(false);
    setNewEntry({ title: '', content: '' });
  };

  const handleCreateChapter = async () => {
    if (!newChapter.title) {
      showError('Bitte geben Sie einen Titel für das Kapitel ein.');
      return;
    }
    const newChapterOrdered = { ...newChapter, order: chapters.length + 1 };
    try {
      await axiosInstance.post(`/wiki/manuals/${manual.slug}/chapters/`, newChapterOrdered);
      await fetchManualAndContent(manual.slug);
      handleCloseCreateChapterDialog();
    } catch (error) {
      console.error('Fehler beim Erstellen des Kapitels:', error);
      showError('Fehler beim Erstellen des Kapitels. Bitte versuchen Sie es später erneut.');
    }
  };

  const handleCreateEntry = async () => {
    if (!newEntry.title) {
      showError('Bitte geben Sie einen Titel für den Eintrag ein.');
      return;
    }
    if (!selectedChapter) {
      showError('Bitte wählen Sie ein Kapitel aus.');
      return;
    }
    try {
      await axiosInstance.post(
        `/wiki/manuals/${manual.slug}/chapters/${selectedChapter.slug}/entries/`,
        {
          title: newEntry.title,
          content: newEntry.content,
        }
      );
      await fetchManualAndContent(manual.slug);
      handleCloseCreateEntryDialog();
    } catch (error) {
      console.error('Fehler beim Erstellen des Eintrags:', error);
      showError('Fehler beim Erstellen des Eintrags. Bitte versuchen Sie es später erneut.');
    }
  };

  const handleItemClick = (event, itemId) => {
    const prefix = isSettings ? '/wiki/settings' : '/wiki/overview';

    if (itemId.startsWith('entry-')) {
      const entryNode = findEntryById(treeData, itemId);
      if (entryNode) {
        navigate(`${prefix}/${manual.slug}/${entryNode.chapterSlug}/${entryNode.entrySlug}`);
      }
    } else if (itemId.startsWith('chapter-')) {
      const chapterNode = findChapterById(treeData, itemId);
      if (chapterNode) {
        navigate(`${prefix}/${manual.slug}/${chapterNode.chapterSlug}`);
      }
    }
  };

  const findEntryById = (nodes, nodeId) => {
    for (const node of nodes) {
      if (node.id === nodeId) {
        return node;
      }
      if (node.children) {
        const childResult = findEntryById(node.children, nodeId);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  };

  const findChapterById = (nodes, nodeId) => {
    return nodes.find((node) => node.id === nodeId) || null;
  };

  const handleSaveEntry = async () => {
    if (!selectedEntry || !selectedChapter) return;
    try {
      await axiosInstance.put(
        `/wiki/manuals/${manual.slug}/chapters/${selectedChapter.slug}/entries/${selectedEntry.slug}/`,
        {
          title: selectedEntry.title,
          content: editedContent,
        }
      );
      await fetchManualAndContent(manual.slug);
    } catch (error) {
      console.error('Fehler beim Speichern des Eintrags:', error);
      showError('Fehler beim Speichern des Eintrags. Bitte versuchen Sie es später erneut.');
    }
  };

  const handleContentChange = (content) => {
    setEditedContent(content);
  };

  const afterImageUploadFunction = async (image_id) => {
    if (!selectedEntry) return;
    try {
      await axiosInstance.post('/wiki/media/', {
        image_id: image_id,
        entry_id: selectedEntry.id,
      });
      await fetchMediaForEntry(selectedEntry.id);
    } catch (error) {
      console.error('Fehler beim Erstellen des Media-Eintrags:', error);
      showError('Fehler beim Erstellen des Media-Eintrags');
    }
  };

  const afterPictureDeleteFunction = async (pictureId) => {
    try {
      const mediaEntry = mediaList.find((media) => media.image_id === pictureId);
      if (!mediaEntry) {
        console.error('Media entry not found for image ID:', pictureId);
        showError('Fehler: Zugehöriger Media-Eintrag nicht gefunden');
        return;
      }
      await axiosInstance.delete(`/wiki/media/${mediaEntry.id}/`);
      if (selectedEntry) {
        await fetchMediaForEntry(selectedEntry.id);
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Bildes:', error);
      showError('Fehler beim Löschen des Bildes');
    }
  };

  const handleImageClick = (imageUrl) => {
    setImageUrl(imageUrl);
    setOpenDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenDialog(false);
    setImageUrl('');
  };

  const onUpdateOrder = () => {
    if (manual) {
      fetchManualAndContent(manual.slug);
    }
  };

  const handleClosePage = () => {
    const baseUrl = isSettings ? '/wiki/settings/index' : '/wiki/overview/index';
    navigate(baseUrl);
  };

  if (!manual) {
    return <Typography>Lädt das ausgewählte Manual...</Typography>;
  }

  const handleOpenConfirmDialog = (action) => {
    setConfirmAction(() => action);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setConfirmAction(null);
  };

  const handleConfirm = () => {
    if (confirmAction) confirmAction();
    handleCloseConfirmDialog();
  };

  const handleDeleteChapter = async () => {
    if (!selectedChapter) {
      showError("Kein Kapitel ausgewählt.");
      return;
    }
    handleOpenConfirmDialog(async () => {
      try {
        await axiosInstance.delete(`/wiki/manuals/${manual.slug}/chapters/${selectedChapter.slug}/`);
        showSuccess("Kapitel erfolgreich gelöscht.");
        await fetchManualAndContent(manual.slug);
      } catch (error) {
        console.error("Fehler beim Löschen des Kapitels:", error);
        showError("Fehler beim Löschen des Kapitels.");
      }
    });
  };

  const handleDeleteEntry = async () => {
    if (!selectedEntry || !selectedChapter) {
      showError("Kein Eintrag ausgewählt.");
      return;
    }
    handleOpenConfirmDialog(async () => {
      try {
        await axiosInstance.delete(
          `/wiki/manuals/${manual.slug}/chapters/${selectedChapter.slug}/entries/${selectedEntry.slug}/`
        );
        showSuccess("Eintrag erfolgreich gelöscht.");
        await fetchManualAndContent(manual.slug);
      } catch (error) {
        console.error("Fehler beim Löschen des Eintrags:", error);
        showError("Fehler beim Löschen des Eintrags.");
      }
    });
  };

  // Funktionen zum Bearbeiten von Kapitel-/Eintragsnamen
  const handleEditChapter = (chapter) => {
    setEditType('chapter');
    setEditData({ title: chapter.title, slug: chapter.slug, parentSlug: '' });
    setEditDialogOpen(true);
  };

  const handleEditEntry = (entry) => {
    // Falls aus irgendeinem Grund entry.chapterSlug nicht definiert ist, greife auf selectedChapter zu
    const chapterSlugForEntry = entry.chapterSlug || selectedChapter?.slug;
    if (!chapterSlugForEntry) {
      showError('Kapitel-Slug für diesen Eintrag ist nicht verfügbar.');
      return;
    }

    setEditType('entry');
    setEditData({ title: entry.title, slug: entry.slug, parentSlug: chapterSlugForEntry });
    setEditDialogOpen(true);
  };

  const handleUpdateName = async () => {
    try {
      if (editType === 'chapter') {
        await axiosInstance.patch(
          `/wiki/manuals/${manual.slug}/chapters/${editData.slug}/`,
          { title: editData.title }
        );
      } else if (editType === 'entry') {
        await axiosInstance.patch(
          `/wiki/manuals/${manual.slug}/chapters/${editData.parentSlug}/entries/${editData.slug}/`,
          { title: editData.title }
        );
      }
      showSuccess(`${editType === 'chapter' ? 'Kapitel' : 'Eintrag'} erfolgreich aktualisiert`);
      await fetchManualAndContent(manual.slug);
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Fehler beim Aktualisieren:', error);
      showError(`Fehler beim Aktualisieren des ${editType === 'chapter' ? 'Kapitels' : 'Eintrags'}.`);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, maxWidth: 2000, margin: '0 auto', bgcolor: 'transparent' }}>
      <Box sx={{ position: 'relative', mb: 2 }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClosePage}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4">{manual.title}</Typography>
      </Box>

      <BreadcrumbsNavigation
        selectedManual={manual}
        selectedChapter={selectedChapter}
        selectedEntry={selectedEntry}
        handleBreadcrumbClick={handleBreadcrumbClick}
      />

      {isSettings && (
        <Box sx={{ mb: 2 }}>
          {/* ERSTE REIHE: "Neues Kapitel erstellen" & "Kapitel löschen" */}
{/* ERSTE REIHE: "Neues Kapitel erstellen" & "Kapitel löschen" */}
<Grid container spacing={1} sx={{ mb: 1 }}>
  <Grid item>
    <Button
      variant="contained"
      color="primary"
      onClick={handleOpenCreateChapterDialogFn}
      sx={{ ...pillStyle }}
    >
      Neues Kapitel erstellen
    </Button>
  </Grid>
  <Grid item>
    <Button
      variant="contained"
      color="error"
      onClick={handleDeleteChapter}
      disabled={!selectedChapter}
      startIcon={<DeleteIcon />}
      sx={{ ...pillStyle }}
    >
      Kapitel löschen
    </Button>
  </Grid>
</Grid>

{/* ZWEITE REIHE: "Neuen Eintrag erstellen" & "Eintrag löschen" */}
<Grid container spacing={1}>
  <Grid item>
    <Button
      variant="contained"
      color="primary"
      onClick={handleOpenCreateEntryDialogFn}
      disabled={!selectedChapter}
      sx={{ ...pillStyle }}
    >
      Neuen Eintrag erstellen
    </Button>
  </Grid>
  <Grid item>
    <Button
      variant="contained"
      color="error"
      onClick={handleDeleteEntry}
      disabled={!selectedEntry}
      startIcon={<DeleteIcon />}
      sx={{ ...pillStyle }}
    >
      Eintrag löschen
    </Button>
  </Grid>
</Grid>

        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" gutterBottom>
            Inhaltsverzeichnis
          </Typography>
          <TreeViewNavigation
            treeData={treeData}
            handleItemClick={handleItemClick}
            selectedNodeId={selectedNodeId}
            manualSlug={manual.slug}
            onUpdateOrder={onUpdateOrder}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <EntryEditor
            selectedChapter={selectedChapter}
            isSettings={isSettings}
            selectedEntry={selectedEntry}
            entries={entries}
            handleItemClick={handleItemClick}
            afterImageUploadFunction={afterImageUploadFunction}
            mediaList={mediaList}
            handleImageClick={handleImageClick}
            afterPictureDeleteFunction={afterPictureDeleteFunction}
            editedContent={editedContent}
            handleContentChange={handleContentChange}
            handleSaveEntry={handleSaveEntry}
            onEditChapter={handleEditChapter}
            onEditEntry={handleEditEntry}
          />
        </Grid>
      </Grid>

      {isSettings && (
        <>
          <CreateChapterDialog
            open={openCreateChapterDialog}
            onClose={handleCloseCreateChapterDialog}
            newChapter={newChapter}
            setNewChapter={setNewChapter}
            handleCreateChapter={handleCreateChapter}
            selectedManual={manual}
          />

          <CreateEntryDialog
            open={openCreateEntryDialog}
            onClose={handleCloseCreateEntryDialog}
            newEntry={newEntry}
            setNewEntry={setNewEntry}
            handleCreateEntry={handleCreateEntry}
            selectedChapter={selectedChapter}
          />
        </>
      )}

      {/* Dialog zum Anzeigen eines Bildes (Image-Preview) */}
      <Dialog open={openDialog} onClose={handleCloseImageDialog} maxWidth="md" fullWidth>
        <DialogContent>
          <object
            data={imageUrl}
            width="100%"
            height="600px"
            style={{ width: '100%' }}
          />
        </DialogContent>
      </Dialog>

      {/* Bestätigungsdialog (Kapitel/Eintrag löschen) */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>Möchten Sie dies wirklich löschen?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleConfirm}
            color="error"
            sx={pillStyle} // <--- Pill-Stil
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Umbenennen von Kapitel/Eintrag */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>{editType === 'chapter' ? 'Kapitel umbenennen' : 'Eintrag umbenennen'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Titel"
            value={editData.title}
            onChange={(e) => setEditData({ ...editData, title: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Abbrechen</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateName}
            sx={pillStyle}  // <--- Pill-Stil
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManualPage;
