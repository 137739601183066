// src/pages/unternehmenwoche/retoure/retoure.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography, Box, Card, CardContent } from '@mui/material'; // Card und CardContent importieren
import Layout from '../../../components/layout/Scorecards/UnternehmenWocheLayout';
// Import des neuen Diagramm-Komponenten
import LineChartVariableYAxis from '../../../components/charts/LineChartVariableYAxis';
import BigTableCard from '../../../components/card/BigTableCard';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/de';
import { useTheme } from '@mui/material';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';

// Import der Popups
import MainTablePopup from '../../../components/popup/unternehmenWoche/verkaufsmenge/MainTablePopup';
import FilialeWarengruppePopup from '../../../components/popup/unternehmenWoche/verkaufsmenge/FilialeWarengruppePopup';
import WarengruppePopup from '../../../components/popup/unternehmenWoche/verkaufsmenge/WarengruppePopup';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.locale('de');

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
    if (value === null || value === undefined) return '';
    return value
        .toFixed(fractionDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const Verkaufsmenge = () => {
    const { filterState } = useContext(FilterContext);
    const theme = useTheme();
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [warengruppeTableData, setWarengruppeTableData] = useState([]);
    const [filialeDayTableData, setFilialeDayTableData] = useState([]);
    const [filialeDayTableColumns, setFilialeDayTableColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // State-Variablen für Popups
    const [mainTablePopupOpen, setMainTablePopupOpen] = useState(false);
    const [selectedFilialeForMainPopup, setSelectedFilialeForMainPopup] = useState('');
    const [filialeWarengruppePopupOpen, setFilialeWarengruppePopupOpen] = useState(false);
    const [selectedFiliale, setSelectedFiliale] = useState('');
    const [warengruppePopupOpen, setWarengruppePopupOpen] = useState(false);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

    useEffect(() => {
        if (filterState && filterState.datumWoche && filterState.datumWoche.value) {
            fetchChartData();
            fetchTableData();
            fetchWarengruppeTableData();
            fetchFilialeDayTableData();
        }
    }, [filterState.datumWoche, filterState.bestehendeFlaeche]); // Aktualisierte Dependency-Liste

    // Funktion zum Abrufen der Chart-Daten
    const fetchChartData = async () => {
        try {
            setLoading(true);
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }), // Hinzufügen des Filters
                        },
                    })
                )
            );

            // Aggregation der Verkaufsmenge pro Warengruppe über die letzten 6 Wochen
            const warengruppeSales = {};

            responses.forEach((response, index) => {
                // Prüfen, ob Daten vorhanden sind
                if (response.data && response.data.results) {
                    response.data.results.forEach((item) => {
                        const wg = item.warengruppe || 'Unbekannt';
                        if (!warengruppeSales[wg]) {
                            warengruppeSales[wg] = Array(6).fill(0);
                        }
                        warengruppeSales[wg][index] += item.verkaufsmenge || 0;
                    });
                }
            });

            // Gesamtverkaufsmenge pro Warengruppe berechnen
            const totalSalesPerWarengruppe = Object.keys(warengruppeSales).map((wg) => {
                const totalSales = warengruppeSales[wg].reduce((a, b) => a + b, 0);
                return { warengruppe: wg, totalSales };
            });

            // Top 6 Warengruppen mit den höchsten Verkaufsmengen
            totalSalesPerWarengruppe.sort((a, b) => b.totalSales - a.totalSales);
            const top6Warengruppen = totalSalesPerWarengruppe.slice(0, 6).map((item) => item.warengruppe);

            // Chart-Daten erstellen
            const chartDataArr = last6Weeks.map((week, index) => {
                const dataObj = { x: week };
                top6Warengruppen.forEach((wg) => {
                    dataObj[wg] = warengruppeSales[wg] ? warengruppeSales[wg][index] || 0 : 0;
                });
                return dataObj;
            });

            setChartData(chartDataArr);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Chart-Daten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Tabellendaten
    const fetchTableData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/scorecard_week/ware-filiale-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }), // Hinzufügen des Filters
                },
            });

            const formattedData = response.data.results.map((item, index) => ({
                id: index,
                verkaufsgebiet: item.verkaufsgebiet || 'Unbekannt',
                filiale: item.filiale,
                verkaufsmenge: item.verkaufsmenge || 0,
                verkaufsmengeVJ: item.verkaufsmengeVJ || 0,
                vjProzent: item.verkaufsmengeVJ > 0
                    ? ((item.verkaufsmenge - item.verkaufsmengeVJ) / item.verkaufsmengeVJ) * 100
                    : 0,
            }));

            setTableData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Tabellendaten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Warengruppen-Tabellendaten
    const fetchWarengruppeTableData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }), // Hinzufügen des Filters
                },
            });

            // Aggregation der Verkaufsmenge pro Warengruppe
            const aggregatedData = {};
            response.data.results.forEach((item) => {
                const wg = item.warengruppe || 'Unbekannt';
                if (!aggregatedData[wg]) {
                    aggregatedData[wg] = {
                        verkaufsmenge: 0,
                        verkaufsmengeVJ: 0,
                    };
                }
                aggregatedData[wg].verkaufsmenge += item.verkaufsmenge || 0;
                aggregatedData[wg].verkaufsmengeVJ += item.verkaufsmengeVJ || 0;
            });

            // Gesamtverkaufsmenge pro Warengruppe berechnen
            const totalSalesPerWarengruppe = Object.keys(aggregatedData).map((wg) => {
                const agg = aggregatedData[wg];
                return { warengruppe: wg, verkaufsmenge: agg.verkaufsmenge };
            });

            // Top 6 Warengruppen mit den höchsten Verkaufsmengen
            totalSalesPerWarengruppe.sort((a, b) => b.verkaufsmenge - a.verkaufsmenge);
            const top6Warengruppen = totalSalesPerWarengruppe.slice(0, 6).map((item) => item.warengruppe);

            // Berechnung von VJ% und Formatierung der Werte für die Top 6 Warengruppen
            const formattedData = top6Warengruppen.map((wg, index) => {
                const agg = aggregatedData[wg];
                const vjProzent = agg.verkaufsmengeVJ > 0
                    ? ((agg.verkaufsmenge - agg.verkaufsmengeVJ) / agg.verkaufsmengeVJ) * 100
                    : 0;

                return {
                    id: index,
                    warengruppe: wg,
                    verkaufsmenge: formatNumber(agg.verkaufsmenge, 0),
                    verkaufsmengeVJ: formatNumber(agg.verkaufsmengeVJ, 0),
                    vjProzent: `${formatNumber(vjProzent)}%`,
                };
            });

            setWarengruppeTableData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Warengruppe-Tabellendaten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Funktion zum Abrufen der Filiale-Tag-Daten
    const fetchFilialeDayTableData = async () => {
        try {
            setLoading(true);
            const [year, weekNumber] = filterState.datumWoche.value.split('.').map(Number);
            const weekStart = dayjs().year(year).isoWeek(weekNumber).startOf('isoWeek');
            const weekEnd = dayjs().year(year).isoWeek(weekNumber).endOf('isoWeek');

            // Erstellung der Wochentage
            const daysOfWeek = [];
            for (let i = 0; i < 7; i++) {
                daysOfWeek.push(weekStart.add(i, 'day').format('YYYY-MM-DD'));
            }

            // Verwendung des neuen API-Endpunkts und Anpassung der Parameter
            let allResults = [];
            let params = {
                startdatum: weekStart.format('YYYY-MM-DD'),
                enddatum: weekEnd.format('YYYY-MM-DD'),
            };

            // API-Aufruf ohne Paginierung
            const response = await axiosInstance.get('/fact/aggregated/by-datum-filialename/', { params });
            if (response.data && Array.isArray(response.data)) {
                allResults = allResults.concat(response.data);
            }

            // Erstellung der Spalten für die Tabelle
            const columns = [
                { accessorKey: 'filiale', header: 'Filiale', align: 'left' },
                ...daysOfWeek.map((date) => ({
                    accessorKey: date,
                    header: dayjs(date).format('ddd DD.MM.'),
                    align: 'right',
                })),
            ];

            setFilialeDayTableColumns(columns);

            // Gruppierung der Daten nach Filiale und Datum
            const dataByFiliale = {};

            allResults.forEach((item) => {
                const filiale = item.filialnummer__filiale || 'Unbekannt';
                const date = dayjs(item.datum__datum).format('YYYY-MM-DD');

                if (!dataByFiliale[filiale]) {
                    dataByFiliale[filiale] = { filiale };
                }

                if (!dataByFiliale[filiale][date]) {
                    dataByFiliale[filiale][date] = 0;
                }

                // Summiere die Verkaufsmenge für das jeweilige Datum und Filiale
                dataByFiliale[filiale][date] += item.total_verkaufsmenge || 0;
            });

            // Formatieren der Daten für die Anzeige
            const formattedData = Object.values(dataByFiliale).map((filialeData) => {
                const formattedFilialeData = { filiale: filialeData.filiale };
                daysOfWeek.forEach((date) => {
                    const value = filialeData[date] || 0;
                    formattedFilialeData[date] = formatNumber(value, 0);
                });
                return formattedFilialeData;
            });

            // Filtern von Filialen mit Verkaufsmenge > 0
            const filteredData = formattedData.filter((filialeData) => {
                const dailyValues = daysOfWeek.map((date) => {
                    const valueStr = filialeData[date];
                    const value = parseFloat(valueStr.replace('.', '').replace(',', '.')) || 0;
                    return value;
                });

                return dailyValues.some((value) => value > 0);
            });

            setFilialeDayTableData(filteredData);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Filiale-Tag-Daten:', error);
            setError(true);
            setLoading(false);
        }
    };

    // Handler für Zeilenklick in VariableTreeMUI
    const handleMainTableRowClick = (row) => {
        if (row.filiale) {
            const filiale = row.filiale;
            setSelectedFilialeForMainPopup(filiale);
            setMainTablePopupOpen(true);
        }
    };

    // Handler für Zeilenklick in der Filiale-Tag-Tabelle
    const handleFilialeRowClick = (row) => {
        const filiale = row.filiale;
        setSelectedFiliale(filiale);
        setFilialeWarengruppePopupOpen(true);
    };

    // Handler für Zeilenklick in der Warengruppe-Tabelle
    const handleWarengruppeRowClick = (row) => {
        const warengruppe = row.warengruppe;
        setSelectedWarengruppe(warengruppe);
        setWarengruppePopupOpen(true);
    };

    // Haupttabellenspalten
    const mainTableColumns = [
        { field: 'verkaufsgebiet', headerName: 'Verkaufsgebiet', align: 'left' },
        { field: 'filiale', headerName: 'Filiale', align: 'left' },
        {
            field: 'verkaufsmenge',
            headerName: 'Verkaufsmenge',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? formatNumber(params.value, 0)
                    : '',
            hide: false,
        },
        {
            field: 'verkaufsmengeVJ',
            headerName: 'Verkaufsmenge VJ',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? formatNumber(params.value, 0)
                    : '',
            hide: false,
        },
        {
            field: 'vjProzent',
            headerName: 'VJ%',
            type: 'number',
            align: 'right',
            valueFormatter: (params) =>
                params.value !== null && params.value !== undefined
                    ? `${formatNumber(params.value)}%`
                    : '',
            hide: false,
        },
    ];

    // Aggregationsfunktionen für die Haupttabelle
    const aggregationFunctions = {
        verkaufsmenge: (items) => {
            return items.reduce((sum, item) => sum + (item.verkaufsmenge || 0), 0);
        },
        verkaufsmengeVJ: (items) => {
            return items.reduce((sum, item) => sum + (item.verkaufsmengeVJ || 0), 0);
        },
        vjProzent: (items) => {
            const totalVerkaufsmenge = items.reduce((sum, item) => sum + (item.verkaufsmenge || 0), 0);
            const totalVerkaufsmengeVJ = items.reduce((sum, item) => sum + (item.verkaufsmengeVJ || 0), 0);
            return totalVerkaufsmengeVJ > 0
                ? ((totalVerkaufsmenge - totalVerkaufsmengeVJ) / totalVerkaufsmengeVJ) * 100
                : 0;
        },
    };

    // Warengruppen-Tabellenspalten
    const warengruppeTableColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe', align: 'left' },
        {
            accessorKey: 'verkaufsmenge',
            header: 'Verkaufsmenge',
            align: 'right',
        },
        {
            accessorKey: 'verkaufsmengeVJ',
            header: 'Verkaufsmenge VJ',
            align: 'right',
        },
        {
            accessorKey: 'vjProzent',
            header: 'VJ%',
            align: 'right',
        },
    ];

    // Definition der Linien für das Diagramm mit unterschiedlichen Farben
    const mainChartLines = () => {
        if (!Array.isArray(chartData) || chartData.length === 0 || !chartData[0]) return [];

        const warengruppen = Object.keys(chartData[0]).filter((key) => key !== 'x');

        const colors = [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.chart?.green || '#00FF00',
            theme.palette.chart?.yellow || '#FFFF00',
            theme.palette.chart?.orange || '#FFA500',
            theme.palette.chart?.pink || '#FFC0CB',
            theme.palette.chart?.blue || '#0000FF',
            theme.palette.chart?.red || '#FF0000',
            theme.palette.chart?.violet || '#EE82EE',
            theme.palette.chart?.teal || '#008080',
        ];

        return warengruppen.map((wg, index) => ({
            dataKey: wg,
            name: wg,
            color: colors[index % colors.length],
        }));
    };

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PageHeader
                    title='Verkaufsmengen'
                    subtitle='Übersicht über die Verkaufsmengen im Sortiment'
                    Icon={BarChartIcon}
                />
            </Box>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error">Fehler beim Laden der Daten.</Typography>
                </Box>
            ) : (
                <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
                    {/* Diagramm und Warengruppen-Tabelle nebeneinander */}
                    <Grid item xs={12} md={6}>
                        {/* Verwendung des neuen Diagramm-Komponenten */}
                        <Card sx={{ backgroundColor: 'white' }}> {/* Card-Komponente mit weißem Hintergrund */}
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Verkaufsmenge pro Warengruppe der letzten 6 Wochen
                                </Typography>
                                <LineChartVariableYAxis
                                    data={chartData}
                                    dataKeys={Object.keys(chartData[0] || {}).filter((key) => key !== 'x')}
                                    xAxisKey="x"
                                    height={400}
                                    lineColors={mainChartLines().map((line) => line.color)}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BigTableCard
                            data={warengruppeTableData}
                            columns={warengruppeTableColumns}
                            title="Verkaufsmenge nach Warengruppe"
                            height={400}
                            onRowClick={handleWarengruppeRowClick}
                        />
                    </Grid>

                    {/* Haupttabelle */}
                    <Grid item xs={12}>
                        <VariableTreeMUI
                            title="Übersicht nach Verkaufsgebieten und Filialen"
                            data={tableData}
                            columns={mainTableColumns}
                            initialGrouping={['verkaufsgebiet']}
                            groupingHeaders={{ verkaufsgebiet: 'Verkaufsgebiet' }}
                            aggregationFunctions={aggregationFunctions}
                            onRowClick={handleMainTableRowClick}
                        />
                    </Grid>

                    {/* Filiale-Tag-Tabelle */}
                    <Grid item xs={12}>
                        <BigTableCard
                            data={filialeDayTableData}
                            columns={filialeDayTableColumns}
                            title="Verkaufsmenge pro Filiale pro Tag"
                            onRowClick={handleFilialeRowClick}
                        />
                    </Grid>
                </Grid>
            )}

            {/* MainTablePopup */}
            {mainTablePopupOpen && (
                <MainTablePopup
                    open={mainTablePopupOpen}
                    onClose={() => setMainTablePopupOpen(false)}
                    filiale={selectedFilialeForMainPopup}
                    filterState={filterState}
                />
            )}

            {/* FilialeWarengruppePopup */}
            {filialeWarengruppePopupOpen && (
                <FilialeWarengruppePopup
                    open={filialeWarengruppePopupOpen}
                    onClose={() => setFilialeWarengruppePopupOpen(false)}
                    filiale={selectedFiliale}
                    filterState={filterState}
                />
            )}

            {/* WarengruppePopup */}
            {warengruppePopupOpen && (
                <WarengruppePopup
                    open={warengruppePopupOpen}
                    onClose={() => setWarengruppePopupOpen(false)}
                    warengruppe={selectedWarengruppe}
                    filterState={filterState}
                />
            )}
        </Layout>
    );
};

export default Verkaufsmenge;
