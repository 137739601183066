import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Alert,
  Paper
} from '@mui/material';
import dayjs from 'dayjs';
import axiosInstance from '../../services/axiosInstance';

function TaskResponseDialog({ open, onClose, taskInstanceId, onSavedAnswers }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Speichert alle Daten der TaskInstance (inkl. due_datetime).
  const [instanceData, setInstanceData] = useState(null);

  // Enthält das Template (Sections, Questions).
  const [taskTemplate, setTaskTemplate] = useState(null);

  // Falls es schon eine vorhandene TaskResponse gibt.
  const [taskResponseId, setTaskResponseId] = useState(null);

  // answers: Struktur { sectionId: { questionId: value } }
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (open && taskInstanceId) {
      loadTaskInstance(taskInstanceId);
    }
  }, [open, taskInstanceId]);

  const loadTaskInstance = async (instanceId) => {
    setLoading(true);
    setError(null);

    try {
      // 1) Lade die TaskInstance (inkl. Template).
      const instRes = await axiosInstance.get(`/aufgaben/task-instances/${instanceId}/`);
      const instance = instRes.data;
      setInstanceData(instance);

      const tmpl = instance.template;
      if (!tmpl || !tmpl.task_sections || tmpl.task_sections.length === 0) {
        // Keine Sections/Questions => Kein "Formular" zum Ausfüllen
        setError('Keine Fragen für diese Aufgabe vorhanden.');
        setLoading(false);
        return;
      }
      setTaskTemplate(tmpl);

      // 2) Prüfen, ob es bereits eine TaskResponse gibt.
      const respRes = await axiosInstance.get('/aufgaben/task-responses/', {
        params: { task_instance: instanceId }
      });
      const existingResponses = respRes.data.results || [];
      let existingResponseId = null;
      if (existingResponses.length > 0) {
        existingResponseId = existingResponses[0].id;
      }
      setTaskResponseId(existingResponseId);

      // 3) Alle Questions initial in "answers" ablegen
      const newAnswers = {};
      tmpl.task_sections.forEach((sec) => {
        newAnswers[sec.id] = {};
        sec.questions.forEach((q) => {
          newAnswers[sec.id][q.id] = ''; // Standard: leerer Wert
        });
      });

      // Falls es schon Answers gab, diese übernehmen
      if (existingResponseId) {
        const answersArr = existingResponses[0].answers || [];
        answersArr.forEach((ansObj) => {
          const questionId = ansObj.task_field;
          let foundSectionId = null;

          tmpl.task_sections.forEach((sec) => {
            const foundQ = sec.questions.find((q) => q.id === questionId);
            if (foundQ) {
              foundSectionId = sec.id;
            }
          });

          if (foundSectionId) {
            newAnswers[foundSectionId][questionId] = ansObj.value;
          }
        });
      }

      setAnswers(newAnswers);
      setLoading(false);
    } catch (err) {
      console.error('Fehler beim Laden der Task Instance:', err);
      setError('Fehler beim Laden der Task Instance.');
      setLoading(false);
    }
  };

  const handleFieldChange = (sectionId, questionId, newValue) => {
    setAnswers((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        [questionId]: newValue
      }
    }));
  };

  // Hilfsfunktion: Rendert ein Input-Feld basierend auf field_type
  // Anforderung: boolean-Felder bleiben wie bisher (Label + Checkbox nebeneinander)
  // Für alle anderen Felder: Zuerst die Frage (Typography), dann das Eingabefeld ohne eigenes Label
  const renderQuestion = (sectionId, question) => {
    const { id: qId, field_type, label, required, options } = question;
    const value = answers[sectionId]?.[qId] || '';

    // boolean bleibt wie bisher
    if (field_type === 'boolean') {
      return (
        <FormControlLabel
          sx={{ mb: 2 }}
          label={label}
          control={
            <Checkbox
              checked={value === 'true'}
              onChange={(e) =>
                handleFieldChange(sectionId, qId, e.target.checked ? 'true' : 'false')
              }
            />
          }
        />
      );
    }

    // Für alle anderen Felder: erst Frage, dann Feld
    switch (field_type) {
      case 'text':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <TextField
              fullWidth
              value={value}
              onChange={(e) => handleFieldChange(sectionId, qId, e.target.value)}
              // Label rausnehmen, da wir die Frage separat als Typography zeigen
              label=""
            />
          </Box>
        );

      case 'choice_single':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <RadioGroup
              value={value}
              onChange={(e) => handleFieldChange(sectionId, qId, e.target.value)}
            >
              {options?.choices?.map((opt) => (
                <FormControlLabel key={opt} value={opt} control={<Radio />} label={opt} />
              ))}
            </RadioGroup>
          </Box>
        );

      case 'choice_multiple': {
        const arrVal = Array.isArray(value) ? value : [];
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            {options?.choices?.map((opt) => (
              <FormControlLabel
                key={opt}
                label={opt}
                control={
                  <Checkbox
                    checked={arrVal.includes(opt)}
                    onChange={(e) => {
                      let newArr = [...arrVal];
                      if (e.target.checked) {
                        newArr.push(opt);
                      } else {
                        newArr = newArr.filter((x) => x !== opt);
                      }
                      handleFieldChange(sectionId, qId, newArr);
                    }}
                  />
                }
              />
            ))}
          </Box>
        );
      }

      case 'number':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={value}
              onChange={(e) => handleFieldChange(sectionId, qId, e.target.value)}
              label=""
            />
          </Box>
        );

      case 'date':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <TextField
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={(e) => handleFieldChange(sectionId, qId, e.target.value)}
              label=""
            />
          </Box>
        );

      case 'datetime':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <TextField
              fullWidth
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={(e) => handleFieldChange(sectionId, qId, e.target.value)}
              label=""
            />
          </Box>
        );

      case 'file':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                handleFieldChange(sectionId, qId, file ? file.name : '');
              }}
            />
          </Box>
        );

      case 'signature':
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {label}
              {required ? ' *' : ''}
            </Typography>
            <Typography variant="body2">
              [Signaturfeld Placeholder]
            </Typography>
          </Box>
        );

      default:
        return (
          <Typography sx={{ mb: 2, color: 'red' }}>
            Unbekannter Feldtyp: {field_type}
          </Typography>
        );
    }
  };

  const handleSubmit = async () => {
    if (!instanceData || !taskTemplate) return;

    try {
      let usedResponseId = taskResponseId;
      if (!usedResponseId) {
        // Falls noch keine TaskResponse existiert: neu erstellen
        const newResp = await axiosInstance.post('/aufgaben/task-responses/', {
          task_instance: taskInstanceId,
          submitted_by: null
        });
        usedResponseId = newResp.data.id;
        setTaskResponseId(usedResponseId);
      }

      // Alte Answers laden
      const oldAnswersRes = await axiosInstance.get('/aufgaben/task-answers/', {
        params: { task_response: usedResponseId }
      });
      const oldAnswers = oldAnswersRes.data.results || [];

      const oldMap = {};
      oldAnswers.forEach((a) => {
        oldMap[a.task_field] = a;
      });

      // Upsert-Logik
      for (const secId in answers) {
        for (const qId in answers[secId]) {
          const val = answers[secId][qId];
          if (oldMap[qId]) {
            // Patch
            await axiosInstance.patch(`/aufgaben/task-answers/${oldMap[qId].id}/`, {
              value: val
            });
          } else {
            // Neu
            await axiosInstance.post('/aufgaben/task-answers/', {
              task_response: usedResponseId,
              task_field: qId,
              value: val
            });
          }
        }
      }

      if (onSavedAnswers) {
        onSavedAnswers();
      }
      onClose();
    } catch (err) {
      console.error('Fehler beim Speichern der Antworten:', err);
      setError('Fehler beim Speichern der Antworten.');
    }
  };

  if (!open) return null;

  // Neuer Titel: <TemplateTitle> – <Datum>
  const templateTitle = taskTemplate?.title || '';
  const dueDateStr = instanceData
    ? dayjs(instanceData.due_datetime).format('DD.MM.YYYY HH:mm')
    : '';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {/* Überschrift => "Inventur – 09.01.2025 23:59" o.Ä. */}
      <DialogTitle>
        {templateTitle}
        {dueDateStr && ` – ${dueDateStr}`}
      </DialogTitle>

      <DialogContent>
        {loading && <Typography>Lade Aufgaben…</Typography>}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        {taskTemplate && instanceData && (
          <Box>
            {/* Template-Beschreibung nur, wenn vorhanden */}
            {taskTemplate.description && taskTemplate.description.trim() !== '' && (
              <Typography variant="body2" gutterBottom>
                {taskTemplate.description}
              </Typography>
            )}

            {/* Sektionen */}
            {taskTemplate.task_sections.map((sec) => (
              <Paper
                key={sec.id}
                variant="outlined"
                sx={{ p: 2, mb: 3, mt: 3, borderColor: '#ccc' }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {sec.name}
                </Typography>

                {/* Sektionsbeschreibung nur, wenn vorhanden */}
                {sec.description && sec.description.trim() !== '' && (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {sec.description}
                  </Typography>
                )}

                {sec.questions.map((q) => (
                  <Box key={q.id}>{renderQuestion(sec.id, q)}</Box>
                ))}
              </Paper>
            ))}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Abschicken
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskResponseDialog;
