// RightColumn.jsx
import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  // CardActions (falls du noch Aktionen einfügen willst)
} from '@mui/material';

const RightColumn = ({
  tasksOnce,
  tasksDaily,
  tasksWeekly,
  tasksMonthly,
  tasksYearly,
}) => {
  return (
    <Box sx={{ p: 2 }}>

      {/* Grid-Layout für die verschiedenen Rhythmus-Gruppen */}
      <Grid container spacing={2}>
        
        {/* Einmalige Aufgaben */}
        <Grid item xs={12} md={12}>
          <Paper elevation={2}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Einmalige Aufgaben
                  </Typography>
                }
              />
              <CardContent>
                {tasksOnce.map((inst) => (
                  <Paper key={inst.id} sx={{ mb: 1, p: 1 }} elevation={1}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title}
                    </Typography>
                    <Typography variant="body2">
                      {inst.template?.description}
                    </Typography>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        {/* Tägliche Aufgaben */}
        <Grid item xs={12} md={12}>
          <Paper elevation={2}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Tägliche Aufgaben
                  </Typography>
                }
              />
              <CardContent>
                {tasksDaily.map((inst) => (
                  <Paper key={inst.id} sx={{ mb: 1, p: 1 }} elevation={1}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title}
                    </Typography>
                    <Typography variant="body2">
                      {inst.template?.description}
                    </Typography>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        {/* Wöchentliche Aufgaben */}
        <Grid item xs={12} md={12}>
          <Paper elevation={2}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Wöchentliche Aufgaben
                  </Typography>
                }
              />
              <CardContent>
                {tasksWeekly.map((inst) => (
                  <Paper key={inst.id} sx={{ mb: 1, p: 1 }} elevation={1}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title}
                    </Typography>
                    <Typography variant="body2">
                      {inst.template?.description}
                    </Typography>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        {/* Monatliche Aufgaben */}
        <Grid item xs={12} md={12}>
          <Paper elevation={2}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Monatliche Aufgaben
                  </Typography>
                }
              />
              <CardContent>
                {tasksMonthly.map((inst) => (
                  <Paper key={inst.id} sx={{ mb: 1, p: 1 }} elevation={1}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title}
                    </Typography>
                    <Typography variant="body2">
                      {inst.template?.description}
                    </Typography>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        {/* Jährliche Aufgaben */}
        <Grid item xs={12} md={12}>
          <Paper elevation={2}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Jährliche Aufgaben
                  </Typography>
                }
              />
              <CardContent>
                {tasksYearly.map((inst) => (
                  <Paper key={inst.id} sx={{ mb: 1, p: 1 }} elevation={1}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title}
                    </Typography>
                    <Typography variant="body2">
                      {inst.template?.description}
                    </Typography>
                  </Paper>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RightColumn;
