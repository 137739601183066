import React, { useEffect, useState } from 'react';
import {
    Grid, Box, Typography, Paper, Card, CardHeader, CardContent, List,
    ListItem, ListItemText, ListItemIcon, Button, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import Layout from '../../../components/layout/Filialcheck/Filialcheck';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../../../services/axiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import FormularHinzufuegenDialog from '../../../components/Aufgaben/Formulare/FormularHinzufuegenDialog';

const AufgabenKofigurationPage = () => {
    const [formTemplates, setFormTemplates] = useState([]);
    const [loadingForms, setLoadingForms] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    // Für Form-Kategorien
    const [formCategories, setFormCategories] = useState([]);
    const [loadingFormCategories, setLoadingFormCategories] = useState(false);
    const [formCategoryDialogOpen, setFormCategoryDialogOpen] = useState(false);
    const [formCategoryEditMode, setFormCategoryEditMode] = useState(false);
    const [selectedFormCategory, setSelectedFormCategory] = useState(null);
    const [formCategoryName, setFormCategoryName] = useState('');
    const [formCategoryDescription, setFormCategoryDescription] = useState('');
    const [formCategoryDeleteDialogOpen, setFormCategoryDeleteDialogOpen] = useState(false);

    const fetchForms = async () => {
        try {
            setLoadingForms(true);
            const response = await axiosInstance.get('/aufgaben/form-templates/');
            const data = response.data.results || [];
            setFormTemplates(data);
            setLoadingForms(false);
        } catch (error) {
            console.error("Fehler beim Laden der Formulare:", error);
            setFormTemplates([]);
            setLoadingForms(false);
        }
    };

    const fetchFormCategories = async () => {
        try {
            setLoadingFormCategories(true);
            const response = await axiosInstance.get('/aufgaben/form-categories/');
            const data = Array.isArray(response.data) ? response.data : (response.data.results || []);
            setFormCategories(data);
            setLoadingFormCategories(false);
        } catch (error) {
            console.error("Fehler beim Laden der Formularkategorien:", error);
            setFormCategories([]);
            setLoadingFormCategories(false);
        }
    };

    useEffect(() => {
        fetchForms();
        fetchFormCategories();
    }, []);

    const handleDialogOpen = () => {
        setAddDialogOpen(true);
    };

    const handleDialogClose = () => {
        setAddDialogOpen(false);
        fetchForms();
    };

    const handleEdit = (template) => {
        setSelectedTemplate(template);
        setEditedName(template.name);
        setEditedDescription(template.description || '');
        setEditDialogOpen(true);
    };

    const handleEditSave = async () => {
        if (!selectedTemplate) return;
        
        try {
            await axiosInstance.put(`/aufgaben/form-templates/${selectedTemplate.id}/`, {
                name: editedName,
                description: editedDescription
            });
            setEditDialogOpen(false);
            setSelectedTemplate(null);
            fetchForms(); 
        } catch (error) {
            console.error("Fehler beim Speichern des Formulars:", error);
        }
    };

    const handleEditCancel = () => {
        setEditDialogOpen(false);
        setSelectedTemplate(null);
    };

    const handleDeleteClick = (template) => {
        setSelectedTemplate(template);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!selectedTemplate) return;
        
        try {
            await axiosInstance.delete(`/aufgaben/form-templates/${selectedTemplate.id}/`);
            setDeleteDialogOpen(false);
            setSelectedTemplate(null);
            fetchForms();
        } catch (error) {
            console.error("Fehler beim Löschen des Formulars:", error);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setSelectedTemplate(null);
    };

    // FormCategory hinzufügen / bearbeiten
    const handleFormCategoryDialogOpen = (category = null) => {
        if (category) {
            // Bearbeiten
            setFormCategoryEditMode(true);
            setSelectedFormCategory(category);
            setFormCategoryName(category.name);
            setFormCategoryDescription(category.description || '');
        } else {
            // Neu anlegen
            setFormCategoryEditMode(false);
            setSelectedFormCategory(null);
            setFormCategoryName('');
            setFormCategoryDescription('');
        }
        setFormCategoryDialogOpen(true);
    };

    const handleFormCategoryDialogClose = () => {
        setFormCategoryDialogOpen(false);
        setFormCategoryEditMode(false);
        setSelectedFormCategory(null);
        setFormCategoryName('');
        setFormCategoryDescription('');
    };

    const handleFormCategorySave = async () => {
        try {
            if (formCategoryEditMode && selectedFormCategory) {
                // Update
                await axiosInstance.put(`/aufgaben/form-categories/${selectedFormCategory.id}/`, {
                    name: formCategoryName,
                    description: formCategoryDescription
                });
            } else {
                // Create
                await axiosInstance.post('/aufgaben/form-categories/', {
                    name: formCategoryName,
                    description: formCategoryDescription
                });
            }
            handleFormCategoryDialogClose();
            fetchFormCategories();
        } catch (error) {
            console.error("Fehler beim Speichern der Formularkategorie:", error);
        }
    };

    const handleFormCategoryDeleteClick = (category) => {
        setSelectedFormCategory(category);
        setFormCategoryDeleteDialogOpen(true);
    };

    const handleFormCategoryDeleteConfirm = async () => {
        if (!selectedFormCategory) return;
        try {
            await axiosInstance.delete(`/aufgaben/form-categories/${selectedFormCategory.id}/`);
            setFormCategoryDeleteDialogOpen(false);
            setSelectedFormCategory(null);
            fetchFormCategories();
        } catch (error) {
            console.error("Fehler beim Löschen der Formularkategorie:", error);
        }
    };

    const handleFormCategoryDeleteCancel = () => {
        setFormCategoryDeleteDialogOpen(false);
        setSelectedFormCategory(null);
    };

    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader
                    title="Aufgaben-Settings"
                    subtitle="Verwalte deine Formulare und Formularkategorien nach den Bedürfnissen des Unternehmens."
                    Icon={ChecklistIcon}
                />

                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ mb:4 }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Liste der Formulare
                                        </Typography>
                                    }
                                    action={
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddCircleIcon />}
                                                onClick={handleDialogOpen}
                                                sx={{
                                                    borderRadius: '50px',
                                                    fontSize: '0.75rem',
                                                    padding: '2px 8px',
                                                    minWidth: 'auto'
                                                }}
                                            >
                                                Formular hinzufügen
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddCircleIcon />}
                                                onClick={() => handleFormCategoryDialogOpen(null)}
                                                sx={{
                                                    borderRadius: '50px',
                                                    fontSize: '0.75rem',
                                                    padding: '2px 8px',
                                                    minWidth: 'auto'
                                                }}
                                            >
                                                Formular-Kategorie hinzufügen
                                            </Button>
                                        </Box>
                                    }
                                />
                                <CardContent>
                                    {loadingForms ? (
                                        <CircularProgress />
                                    ) : (
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {Array.isArray(formTemplates) && formTemplates.length > 0 ? (
                                                formTemplates.map((template) => (
                                                    <React.Fragment key={template.id}>
                                                        <ListItem
                                                            secondaryAction={
                                                                <div>
                                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(template)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(template)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <ChecklistIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={template.name}
                                                                secondary={template.description || "Keine Beschreibung vorhanden"}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Typography variant="h6">Keine Formulare gefunden</Typography>
                                            )}
                                        </List>
                                    )}
                                </CardContent>
                            </Card>
                        </Paper>

                        <Paper>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Formular-Kategorien
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    {loadingFormCategories ? (
                                        <CircularProgress />
                                    ) : (
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {Array.isArray(formCategories) && formCategories.length > 0 ? (
                                                formCategories.map((cat) => (
                                                    <React.Fragment key={cat.id}>
                                                        <ListItem
                                                            secondaryAction={
                                                                <div>
                                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleFormCategoryDialogOpen(cat)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleFormCategoryDeleteClick(cat)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <ChecklistIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={cat.name}
                                                                secondary={cat.description || "Keine Beschreibung"}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Typography variant="h6">Keine Formular-Kategorien gefunden</Typography>
                                            )}
                                        </List>
                                    )}
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Dialog für Formular hinzufügen */}
            <FormularHinzufuegenDialog open={addDialogOpen} handleClose={handleDialogClose} />

            {/* Edit Dialog für Formulare */}
            <Dialog open={editDialogOpen} onClose={handleEditCancel}>
                <DialogTitle>Formular bearbeiten</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Name"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Beschreibung"
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditCancel}>Abbrechen</Button>
                    <Button onClick={handleEditSave} variant="contained" color="primary">Speichern</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog für Formulare */}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Formular löschen</DialogTitle>
                <DialogContent>
                    <Typography>Möchten Sie dieses Formular wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Abbrechen</Button>
                    <Button onClick={handleDeleteConfirm} variant="contained" color="error">Löschen</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog für Formular-Kategorie hinzufügen/bearbeiten */}
            <Dialog open={formCategoryDialogOpen} onClose={handleFormCategoryDialogClose}>
                <DialogTitle>{formCategoryEditMode ? "Formular-Kategorie bearbeiten" : "Formular-Kategorie hinzufügen"}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Name"
                        value={formCategoryName}
                        onChange={(e) => setFormCategoryName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Beschreibung"
                        value={formCategoryDescription}
                        onChange={(e) => setFormCategoryDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormCategoryDialogClose}>Abbrechen</Button>
                    <Button onClick={handleFormCategorySave} variant="contained" color="primary">{formCategoryEditMode ? "Speichern" : "Hinzufügen"}</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog für Formular-Kategorie */}
            <Dialog open={formCategoryDeleteDialogOpen} onClose={handleFormCategoryDeleteCancel}>
                <DialogTitle>Formular-Kategorie löschen</DialogTitle>
                <DialogContent>
                    <Typography>Möchten Sie diese Formular-Kategorie wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormCategoryDeleteCancel}>Abbrechen</Button>
                    <Button onClick={handleFormCategoryDeleteConfirm} variant="contained" color="error">Löschen</Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default AufgabenKofigurationPage;
