import React from 'react';
import GeneralLayout from '../GeneralLayout';
import Sidebar from '../GeneralSidebar';
import WindowIcon from '@mui/icons-material/Window';
import TimelineIcon from '@mui/icons-material/Timeline';
import Euro from '@mui/icons-material/Euro';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import GroupIcon from '@mui/icons-material/Group';
import Vaccines from '@mui/icons-material/Vaccines';
import GroupRemove from '@mui/icons-material/GroupRemove';
import ManageSearch from '@mui/icons-material/ManageSearch';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import AutoGraph from '@mui/icons-material/AutoGraph';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';




const TagesreportLayout = ({ children }) => {
    const menuItems = [
        { text: 'Übersicht', path: '/tagesreport/overview/index', icon: <WindowIcon sx={{ color: 'primary.main' }} />, subModule: "overview"},
        { text: 'Retouren', path: '/tagesreport/overview/retouren', icon: <PublishedWithChangesIcon sx={{ color: 'primary.main' }} />, subModule: "overview"},
        { text: 'Fehlmengen', path: '/tagesreport/overview/fehlmenge', icon: <BalanceOutlinedIcon sx={{ color: 'primary.main' }} />, subModule: "overview"},
        { text: 'Kassendifferenzen', path: '/tagesreport/overview/kassendifferenzen', icon: <AccountBalanceOutlinedIcon sx={{ color: 'primary.main' }} />, subModule: "overview"},
    ];

    return (
        <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"tagesreport"} menuItems={menuItems} title={'Einstellungen'} />}>
            {children}
        </GeneralLayout>
    );
};

export default TagesreportLayout;

