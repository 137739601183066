import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stepper,
    Step,
    StepLabel,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import SectionAndQuestionBuilder from '../SectionAndQuestionBuilder'; // Wichtig: Existierende Datei mit Drag&Drop

const steps = [
    'Allgemeine Informationen',
    'Ausführungsrhythmus & Fälligkeit',
    'Eskalation & Benachrichtigung',
    'Sektionen & Fragen definieren',
    'Zuweisung',
    'Abschluss-Anforderungen',
    'Zusammenfassung'
];

const RHYTHM_OPTIONS = [
    { value: 'once', label: 'Einmalig' },
    { value: 'daily', label: 'Täglich' },
    { value: 'weekly', label: 'Wöchentlich' },
    { value: 'monthly', label: 'Monatlich' },
    { value: 'yearly', label: 'Jährlich' },
];

const WEEKDAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

function AufgabeErstellenDialog({ open, handleClose }) {
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);

    // Allgemein (Schritt 0)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [validUntil, setValidUntil] = useState('');
    const [taskCategories, setTaskCategories] = useState([]);
    const [taskCategoryId, setTaskCategoryId] = useState('');
    const [isActive, setIsActive] = useState(true);

    // Rhythmus & Fälligkeit (Schritt 1)
    const [rhythm, setRhythm] = useState('once');
    const [dueTime, setDueTime] = useState('');
    const [reminderBeforeDeadlineDays, setReminderBeforeDeadlineDays] = useState('');
    const [reminderBeforeDeadlineHours, setReminderBeforeDeadlineHours] = useState('');
    const [reminderBeforeDeadlineMinutesValue, setReminderBeforeDeadlineMinutesValue] = useState('');
    const [weekdays, setWeekdays] = useState([]);
    const [dayOfMonth, setDayOfMonth] = useState('');
    const [monthOfYear, setMonthOfYear] = useState('');

    // Eskalation & Benachrichtigung (Schritt 2)
    const [dueDelayDays, setDueDelayDays] = useState('');
    const [overdueDelayDays, setOverdueDelayDays] = useState('');
    const [escalationDelayDays, setEscalationDelayDays] = useState('');
    const [dueDelayHours, setDueDelayHours] = useState('');
    const [dueDelayMinutesValue, setDueDelayMinutesValue] = useState('');
    const [overdueDelayHours, setOverdueDelayHours] = useState('');
    const [overdueDelayMinutesValue, setOverdueDelayMinutesValue] = useState('');
    const [escalationDelayHours, setEscalationDelayHours] = useState('');
    const [escalationDelayMinutesValue, setEscalationDelayMinutesValue] = useState('');

    const [dueNotifyUsers, setDueNotifyUsers] = useState([]);
    const [dueNotifyGroups, setDueNotifyGroups] = useState([]);
    const [overdueNotifyUsers, setOverdueNotifyUsers] = useState([]);
    const [overdueNotifyGroups, setOverdueNotifyGroups] = useState([]);
    const [escalatedNotifyUsers, setEscalatedNotifyUsers] = useState([]);
    const [escalatedNotifyGroups, setEscalatedNotifyGroups] = useState([]);

    // Sektionen & Fragen (Schritt 3)
    const [sections, setSections] = useState([]);

    // Vorlagen
    const [formTemplates, setFormTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');

    // Zuweisung (Schritt 4)
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);

    // Abschluss-Anforderungen (Schritt 5)
    const [completionRequiresSignature, setCompletionRequiresSignature] = useState(false);
    const [completionRequiresPersonalNumber, setCompletionRequiresPersonalNumber] = useState(false);

    // Bestätigungsdialog
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    // Daten laden
    useEffect(() => {
        if (open) {
            axiosInstance.get('/aufgaben/form-templates/')
                .then(res => setFormTemplates(res.data.results || []))
                .catch(err => console.error("Fehler beim Laden der FormTemplates:", err));

            axiosInstance.get('/auth/users/')
                .then(res => {
                    let data = res.data;
                    if (!Array.isArray(data)) {
                        data = Array.isArray(data.results) ? data.results : [data];
                    }
                    setAvailableUsers(data);
                })
                .catch(err => console.error("Fehler beim Laden der Benutzer:", err));

            axiosInstance.get('/auth/custom-groups/')
                .then(res => setAvailableGroups(res.data || []))
                .catch(err => console.error("Fehler beim Laden der CustomGroups:", err));

            axiosInstance.get('/aufgaben/task-categories/')
                .then(res => setTaskCategories(res.data.results || []))
                .catch(err => console.error("Fehler beim Laden der TaskCategories:", err));
        }
    }, [open]);

    // Hilfsfunktion: Tage/Stunden/Minuten => Minuten
    const toMinutes = (d, h, m) => {
        const dd = parseInt(d || '0', 10) || 0;
        const hh = parseInt(h || '0', 10) || 0;
        const mm = parseInt(m || '0', 10) || 0;
        return dd * 24 * 60 + hh * 60 + mm;
    };

    // Stepper
    const handleNext = () => {
        if (activeStep === 0) {
            if (title.trim() === '' || !startDate) {
                setError('Bitte Titel und Startdatum ausfüllen.');
                return;
            }
        } else if (activeStep === 1) {
            if (rhythm === 'weekly' && weekdays.length === 0) {
                setError('Bitte mindestens einen Wochentag auswählen.');
                return;
            }
            if (rhythm === 'monthly' && !dayOfMonth) {
                setError('Bitte einen Tag des Monats angeben.');
                return;
            }
            if (rhythm === 'yearly' && (!monthOfYear || !dayOfMonth)) {
                setError('Bitte Monat und Tag für den jährlichen Rhythmus angeben.');
                return;
            }
        }
        setError(null);
        setActiveStep(prev => prev + 1);
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    // Speichern & Schließen
    const handleSaveAndClose = async () => {
        // Erinnerung vor Fälligkeit
        let finalReminderBeforeDeadlineMinutes = null;
        if (reminderBeforeDeadlineDays || reminderBeforeDeadlineHours || reminderBeforeDeadlineMinutesValue) {
            finalReminderBeforeDeadlineMinutes = toMinutes(
                reminderBeforeDeadlineDays,
                reminderBeforeDeadlineHours,
                reminderBeforeDeadlineMinutesValue
            );
        }

        const hasDueTime = !!dueTime;
        let finalDueDelayMinutes = null;
        let finalOverdueDelayMinutes = null;
        let finalEscalationDelayMinutes = null;
        let finalDueDelayDays = null;
        let finalOverdueDelayDays = null;
        let finalEscalationDelayDays = null;

        if (hasDueTime) {
            if (dueDelayDays || dueDelayHours || dueDelayMinutesValue) {
                finalDueDelayMinutes = toMinutes(dueDelayDays, dueDelayHours, dueDelayMinutesValue);
            }
            if (overdueDelayDays || overdueDelayHours || overdueDelayMinutesValue) {
                finalOverdueDelayMinutes = toMinutes(overdueDelayDays, overdueDelayHours, overdueDelayMinutesValue);
            }
            if (escalationDelayDays || escalationDelayHours || escalationDelayMinutesValue) {
                finalEscalationDelayMinutes = toMinutes(escalationDelayDays, escalationDelayHours, escalationDelayMinutesValue);
            }
        } else {
            finalDueDelayDays = dueDelayDays ? parseInt(dueDelayDays, 10) : null;
            finalOverdueDelayDays = overdueDelayDays ? parseInt(overdueDelayDays, 10) : null;
            finalEscalationDelayDays = escalationDelayDays ? parseInt(escalationDelayDays, 10) : null;
        }

        const payload = {
            title,
            description,
            start_date: startDate,
            valid_until: validUntil || null,
            category: taskCategoryId || null,
            is_active: isActive,
            task_type: 'Zentrale',
            rhythm,
            due_time: dueTime || null,
            reminder_before_deadline_minutes: finalReminderBeforeDeadlineMinutes,
            assigned_users: assignedUsers,
            groups,
            completion_requires_signature: completionRequiresSignature,
            completion_requires_personal_number: completionRequiresPersonalNumber,
            weekdays: (rhythm === 'weekly') ? weekdays : undefined,
            day_of_month: (rhythm === 'monthly' || rhythm === 'yearly')
                ? (dayOfMonth ? parseInt(dayOfMonth, 10) : null)
                : undefined,
            month_of_year: (rhythm === 'yearly')
                ? (monthOfYear ? parseInt(monthOfYear, 10) : null)
                : undefined,

            due_delay_minutes: hasDueTime ? finalDueDelayMinutes : null,
            overdue_delay_minutes: hasDueTime ? finalOverdueDelayMinutes : null,
            escalation_delay_minutes: hasDueTime ? finalEscalationDelayMinutes : null,
            due_delay_days: hasDueTime ? null : finalDueDelayDays,
            overdue_delay_days: hasDueTime ? null : finalOverdueDelayDays,
            escalation_delay_days: hasDueTime ? null : finalEscalationDelayDays,

            due_notify_users:
                (hasDueTime ? finalDueDelayMinutes !== null : finalDueDelayDays !== null)
                    ? dueNotifyUsers
                    : [],
            due_notify_groups:
                (hasDueTime ? finalDueDelayMinutes !== null : finalDueDelayDays !== null)
                    ? dueNotifyGroups
                    : [],
            overdue_notify_users:
                (hasDueTime ? finalOverdueDelayMinutes !== null : finalOverdueDelayDays !== null)
                    ? overdueNotifyUsers
                    : [],
            overdue_notify_groups:
                (hasDueTime ? finalOverdueDelayMinutes !== null : finalOverdueDelayDays !== null)
                    ? overdueNotifyGroups
                    : [],
            escalated_notify_users:
                (hasDueTime ? finalEscalationDelayMinutes !== null : finalEscalationDelayDays !== null)
                    ? escalatedNotifyUsers
                    : [],
            escalated_notify_groups:
                (hasDueTime ? finalEscalationDelayMinutes !== null : finalEscalationDelayDays !== null)
                    ? escalatedNotifyGroups
                    : [],

            sections: sections.map((sec, sIndex) => ({
                name: sec.name,
                description: sec.description,
                order: sIndex,
                questions: (sec.fields || []).map((f, fIndex) => ({
                    label: f.label,
                    field_type: f.field_type,
                    required: f.required,
                    help_text: f.help_text,
                    options: f.options,
                    order: fIndex
                }))
            }))
        };

        try {
            await axiosInstance.post('/aufgaben/task-templates/create_with_sections/', payload);
            resetForm();
            handleClose();
        } catch (err) {
            console.error('Fehler beim Erstellen der Aufgabe:', err);
            setError('Fehler beim Erstellen der Aufgabe. Bitte versuchen Sie es erneut.');
        }
    };

    // Reset-Logik
    const resetForm = () => {
        setActiveStep(0);
        setTitle('');
        setDescription('');
        setStartDate('');
        setValidUntil('');
        setTaskCategoryId('');
        setIsActive(true);
        setRhythm('once');
        setDueTime('');
        setReminderBeforeDeadlineDays('');
        setReminderBeforeDeadlineHours('');
        setReminderBeforeDeadlineMinutesValue('');
        setWeekdays([]);
        setDayOfMonth('');
        setMonthOfYear('');
        setDueDelayDays('');
        setOverdueDelayDays('');
        setEscalationDelayDays('');
        setDueDelayHours('');
        setDueDelayMinutesValue('');
        setOverdueDelayHours('');
        setOverdueDelayMinutesValue('');
        setEscalationDelayHours('');
        setEscalationDelayMinutesValue('');
        setDueNotifyUsers([]);
        setDueNotifyGroups([]);
        setOverdueNotifyUsers([]);
        setOverdueNotifyGroups([]);
        setEscalatedNotifyUsers([]);
        setEscalatedNotifyGroups([]);
        setSections([]);
        setAssignedUsers([]);
        setGroups([]);
        setCompletionRequiresSignature(false);
        setCompletionRequiresPersonalNumber(false);
        setError(null);
        setSelectedTemplateId('');
    };

    // Abbrechen
    const handleCloseWithConfirmation = () => {
        const hasData = (
            title || description || startDate || validUntil || taskCategoryId || !isActive ||
            assignedUsers.length > 0 || groups.length > 0 || sections.length > 0 ||
            completionRequiresSignature || completionRequiresPersonalNumber ||
            rhythm !== 'once' || weekdays.length > 0 || dayOfMonth || monthOfYear ||
            dueTime !== '' ||
            reminderBeforeDeadlineDays || reminderBeforeDeadlineHours || reminderBeforeDeadlineMinutesValue ||
            dueDelayDays || overdueDelayDays || escalationDelayDays ||
            dueDelayHours || dueDelayMinutesValue ||
            overdueDelayHours || overdueDelayMinutesValue ||
            escalationDelayHours || escalationDelayMinutesValue ||
            dueNotifyUsers.length > 0 || dueNotifyGroups.length > 0 ||
            overdueNotifyUsers.length > 0 || overdueNotifyGroups.length > 0 ||
            escalatedNotifyUsers.length > 0 || escalatedNotifyGroups.length > 0
        );
        if (hasData) {
            setOpenConfirmDialog(true);
        } else {
            handleClose();
        }
    };
    const handleConfirmClose = () => {
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };
    const handleCancelClose = () => {
        setOpenConfirmDialog(false);
    };

    // Vorlagen übernehmen
    const applyFormTemplate = (templateId) => {
        const template = formTemplates.find(t => t.id === templateId);
        if (!template) return;

        const newSections = (template.sections || []).map(sec => ({
            name: sec.name,
            description: sec.description || '',
            fields: (sec.fields || []).map(f => ({
                label: f.label,
                field_type: f.field_type,
                required: f.required,
                help_text: f.help_text || '',
                options: f.options || {}
            }))
        }));
        setSections(newSections);
    };

    // Schritt-Inhalte
    const renderStepContent = (step) => {
        switch (step) {
            // Schritt 0
            case 0:
                return (
                    <Box sx={{ width: '100%' }}>
                        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Titel der Aufgabe"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Beschreibung (optional)"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={4}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="date"
                            label="Startdatum"
                            InputLabelProps={{ shrink: true }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            type="date"
                            label="Gültig bis (optional)"
                            InputLabelProps={{ shrink: true }}
                            value={validUntil}
                            onChange={(e) => setValidUntil(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Kategorie (optional)</InputLabel>
                            <Select
                                value={taskCategoryId}
                                label="Kategorie (optional)"
                                onChange={(e) => setTaskCategoryId(e.target.value)}
                            >
                                <MenuItem value="">Keine Kategorie</MenuItem>
                                {taskCategories.map(cat => (
                                    <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                            label="Aufgabe ist aktiv"
                            sx={{ mb: 2 }}
                        />
                    </Box>
                );
            // Schritt 1
            case 1:
                return (
                    <Box>
                        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Rhythmus</InputLabel>
                            <Select
                                value={rhythm}
                                label="Rhythmus"
                                onChange={(e) => setRhythm(e.target.value)}
                            >
                                {RHYTHM_OPTIONS.map(r => (
                                    <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {rhythm === 'weekly' && (
                            <Box sx={{ mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel>Wochentage</InputLabel>
                                    <Select
                                        multiple
                                        value={weekdays}
                                        onChange={(e) => setWeekdays(e.target.value)}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {WEEKDAYS.map(day => (
                                            <MenuItem key={day} value={day}>{day}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {rhythm === 'monthly' && (
                            <TextField
                                margin="normal"
                                fullWidth
                                type="number"
                                label="Tag des Monats"
                                InputLabelProps={{ shrink: true }}
                                value={dayOfMonth}
                                onChange={(e) => setDayOfMonth(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                        )}

                        {rhythm === 'yearly' && (
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    type="number"
                                    label="Monat des Jahres (1-12)"
                                    InputLabelProps={{ shrink: true }}
                                    value={monthOfYear}
                                    onChange={(e) => setMonthOfYear(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    type="number"
                                    label="Tag im Monat"
                                    InputLabelProps={{ shrink: true }}
                                    value={dayOfMonth}
                                    onChange={(e) => setDayOfMonth(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                            </Box>
                        )}

                        <TextField
                            margin="normal"
                            fullWidth
                            type="time"
                            label="Fälligkeitsuhrzeit (optional)"
                            InputLabelProps={{ shrink: true }}
                            value={dueTime}
                            onChange={(e) => setDueTime(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Erinnerung vor Fälligkeit (optional):
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                            <TextField
                                type="number"
                                label="Tage"
                                value={reminderBeforeDeadlineDays}
                                onChange={(e) => setReminderBeforeDeadlineDays(e.target.value)}
                                sx={{ width: 80 }}
                            />
                            <TextField
                                type="number"
                                label="Stunden"
                                value={reminderBeforeDeadlineHours}
                                onChange={(e) => setReminderBeforeDeadlineHours(e.target.value)}
                                sx={{ width: 80 }}
                            />
                            <TextField
                                type="number"
                                label="Minuten"
                                value={reminderBeforeDeadlineMinutesValue}
                                onChange={(e) => setReminderBeforeDeadlineMinutesValue(e.target.value)}
                                sx={{ width: 80 }}
                            />
                        </Box>
                    </Box>
                );
            // Schritt 2
            case 2: {
                const hasDueTime = !!dueTime;

                const renderDelayFields = (label, dayValue, setDayValue, hourValue, setHourValue, minuteValue, setMinuteValue) => {
                    return (
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {label}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                {!hasDueTime && (
                                    <TextField
                                        type="number"
                                        label="Tage"
                                        value={dayValue}
                                        onChange={(e) => setDayValue(e.target.value)}
                                        sx={{ width: 80 }}
                                    />
                                )}
                                {hasDueTime && (
                                    <>
                                        <TextField
                                            type="number"
                                            label="Tage"
                                            value={dayValue}
                                            onChange={(e) => setDayValue(e.target.value)}
                                            sx={{ width: 80 }}
                                        />
                                        <TextField
                                            type="number"
                                            label="Stunden"
                                            value={hourValue}
                                            onChange={(e) => setHourValue(e.target.value)}
                                            sx={{ width: 80 }}
                                        />
                                        <TextField
                                            type="number"
                                            label="Minuten"
                                            value={minuteValue}
                                            onChange={(e) => setMinuteValue(e.target.value)}
                                            sx={{ width: 80 }}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    );
                };

                const renderNotifySelectors = (stageName, notifyUsers, setNotifyUsers, notifyGroups, setNotifyGroups) => {
                    return (
                        <>
                            <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
                                Benachrichtigung bei {stageName} an Benutzer:
                            </Typography>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Benutzer</InputLabel>
                                <Select
                                    multiple
                                    value={notifyUsers}
                                    label="Benutzer"
                                    onChange={(e) => setNotifyUsers(e.target.value)}
                                    renderValue={(selected) => {
                                        const names = selected.map(id => {
                                            const user = availableUsers.find(u => u.id === id);
                                            return user ? user.username : id;
                                        });
                                        return names.join(', ');
                                    }}
                                >
                                    {Array.isArray(availableUsers) && availableUsers.map(u => (
                                        <MenuItem key={u.id} value={u.id}>{u.username}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Typography variant="body2" sx={{ mb: 1 }}>
                                Benachrichtigung bei {stageName} an Gruppen:
                            </Typography>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Gruppen</InputLabel>
                                <Select
                                    multiple
                                    value={notifyGroups}
                                    label="Gruppen"
                                    onChange={(e) => setNotifyGroups(e.target.value)}
                                    renderValue={(selected) => {
                                        const names = selected.map(id => {
                                            const g = availableGroups.find(gr => gr.id === id);
                                            return g ? g.name : id;
                                        });
                                        return names.join(', ');
                                    }}
                                >
                                    {Array.isArray(availableGroups) && availableGroups.map(gr => (
                                        <MenuItem key={gr.id} value={gr.id}>{gr.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    );
                };

                const showNotifyFieldsForDue = hasDueTime
                    ? (dueDelayDays || dueDelayHours || dueDelayMinutesValue)
                    : dueDelayDays;
                const showNotifyFieldsForOverdue = hasDueTime
                    ? (overdueDelayDays || overdueDelayHours || overdueDelayMinutesValue)
                    : overdueDelayDays;
                const showNotifyFieldsForEscalation = hasDueTime
                    ? (escalationDelayDays || escalationDelayHours || escalationDelayMinutesValue)
                    : escalationDelayDays;

                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>Eskalation & Benachrichtigung</Typography>
                        <Typography variant="body2" gutterBottom>
                            Alle Felder sind optional. Bei gesetzter Fälligkeitszeit können Tage, Stunden und Minuten eingegeben werden, sonst nur Tage.
                        </Typography>

                        {/* Fällig */}
                        {renderDelayFields(
                            'Fällig nach',
                            dueDelayDays, setDueDelayDays,
                            dueDelayHours, setDueDelayHours,
                            dueDelayMinutesValue, setDueDelayMinutesValue
                        )}
                        { showNotifyFieldsForDue && renderNotifySelectors('Fälligkeit', dueNotifyUsers, setDueNotifyUsers, dueNotifyGroups, setDueNotifyGroups)}

                        {/* Überfällig */}
                        {renderDelayFields(
                            'Überfällig nach',
                            overdueDelayDays, setOverdueDelayDays,
                            overdueDelayHours, setOverdueDelayHours,
                            overdueDelayMinutesValue, setOverdueDelayMinutesValue
                        )}
                        { showNotifyFieldsForOverdue && renderNotifySelectors('Überfälligkeit', overdueNotifyUsers, setOverdueNotifyUsers, overdueNotifyGroups, setOverdueNotifyGroups)}

                        {/* Eskaliert */}
                        {renderDelayFields(
                            'Eskalation nach',
                            escalationDelayDays, setEscalationDelayDays,
                            escalationDelayHours, setEscalationDelayHours,
                            escalationDelayMinutesValue, setEscalationDelayMinutesValue
                        )}
                        { showNotifyFieldsForEscalation && renderNotifySelectors('Eskalation', escalatedNotifyUsers, setEscalatedNotifyUsers, escalatedNotifyGroups, setEscalatedNotifyGroups)}
                    </Box>
                );
            }

            // Schritt 3: Hier nur SectionAndQuestionBuilder
            case 3:
                return (
                    <Box>
                        <SectionAndQuestionBuilder
                            sections={sections}
                            onChange={setSections}
                        />
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                            <Box>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel>Vorlagen auswählen</InputLabel>
                                    <Select
                                        value={selectedTemplateId}
                                        label="Vorlagen auswählen"
                                        onChange={(e) => setSelectedTemplateId(e.target.value)}
                                    >
                                        <MenuItem value="">Keine Vorlage</MenuItem>
                                        {formTemplates.map(ft => (
                                            <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={!selectedTemplateId}
                                    onClick={() => {
                                        if (selectedTemplateId) {
                                            applyFormTemplate(selectedTemplateId);
                                        }
                                    }}
                                    sx={{ mr: 2 }}
                                >
                                    Vorlage verwenden
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                        const payload = {
                                            name: `Aus Aufgabe: ${title || 'Unbenannte Aufgabe'}`,
                                            description: `Erstellt aus der Aufgabe "${title}"`,
                                            sections: sections.map((sec, sIndex) => ({
                                                name: sec.name,
                                                description: sec.description,
                                                order: sIndex,
                                                fields: sec.fields.map((f, fIndex) => ({
                                                    label: f.label,
                                                    field_type: f.field_type,
                                                    required: f.required,
                                                    help_text: f.help_text,
                                                    options: f.options,
                                                    order: fIndex
                                                }))
                                            }))
                                        };
                                        try {
                                            await axiosInstance.post('/aufgaben/form-templates/', payload);
                                            alert('Formular wurde erfolgreich aus den Sektionen/Fragen erstellt!');
                                        } catch (err) {
                                            console.error('Fehler beim Erstellen des Formulars:', err);
                                            alert('Fehler beim Erstellen des Formulars!');
                                        }
                                    }}
                                >
                                    Als Vorlage speichern
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                );

            // Schritt 4: Zuweisung
            case 4:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>Zuweisung</Typography>
                        <Typography variant="body2" gutterBottom>
                            Wählen Sie, welche Benutzer oder Gruppen diese Aufgabe erhalten sollen.
                        </Typography>

                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <InputLabel>Benutzer</InputLabel>
                            <Select
                                multiple
                                value={assignedUsers}
                                label="Benutzer"
                                onChange={(e) => setAssignedUsers(e.target.value)}
                                renderValue={(selected) => {
                                    const names = selected.map(id => {
                                        const user = availableUsers.find(u => u.id === id);
                                        return user ? user.username : id;
                                    });
                                    return names.join(', ');
                                }}
                            >
                                {Array.isArray(availableUsers) && availableUsers.map(u => (
                                    <MenuItem key={u.id} value={u.id}>{u.username}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Gruppen</InputLabel>
                            <Select
                                multiple
                                value={groups}
                                label="Gruppen"
                                onChange={(e) => setGroups(e.target.value)}
                                renderValue={(selected) => {
                                    const names = selected.map(id => {
                                        const g = availableGroups.find(gr => gr.id === id);
                                        return g ? g.name : id;
                                    });
                                    return names.join(', ');
                                }}
                            >
                                {Array.isArray(availableGroups) && availableGroups.map(gr => (
                                    <MenuItem key={gr.id} value={gr.id}>{gr.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                );

            // Schritt 5: Abschluss-Anforderungen
            case 5:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>Abschluss-Anforderungen</Typography>
                        <Typography variant="body2" gutterBottom>
                            Legen Sie fest, ob beim Abschluss der Aufgabe eine Personalnummer oder eine Unterschrift erforderlich ist.
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={completionRequiresPersonalNumber}
                                    onChange={(e) => setCompletionRequiresPersonalNumber(e.target.checked)}
                                />
                            }
                            label="Abschluss erfordert Personalnummer"
                            sx={{ mb: 2 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={completionRequiresSignature}
                                    onChange={(e) => setCompletionRequiresSignature(e.target.checked)}
                                />
                            }
                            label="Abschluss erfordert Unterschrift"
                            sx={{ mb: 2 }}
                        />
                    </Box>
                );

            // Schritt 6: Zusammenfassung
            case 6: {
                let selectedUserNames = assignedUsers.map(id => {
                    const user = availableUsers.find(u => u.id === id);
                    return user ? user.username : id;
                }).join(', ') || 'Keine';

                let selectedGroupNames = groups.map(id => {
                    const g = availableGroups.find(gr => gr.id === id);
                    return g ? g.name : id;
                }).join(', ') || 'Keine';

                let fieldCount = 0;
                sections.forEach(s => {
                    fieldCount += (s.fields?.length || 0);
                });

                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>Zusammenfassung</Typography>
                        <Typography variant="body2" gutterBottom>
                            Überprüfen Sie Ihre Eingaben. Wenn alles korrekt ist, schließen Sie den Prozess ab.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>Titel: {title || 'Kein Titel'}</Typography>
                        <Typography variant="subtitle1">Beschreibung: {description || 'Keine Beschreibung'}</Typography>
                        <Typography variant="subtitle1">Startdatum: {startDate || 'Kein Datum'}</Typography>
                        <Typography variant="subtitle1">Gültig bis: {validUntil || 'Kein Datum'}</Typography>
                        <Typography variant="subtitle1">
                            Kategorie: {taskCategoryId
                                ? (taskCategories.find(cat => cat.id === taskCategoryId)?.name || taskCategoryId)
                                : 'Keine Kategorie'}
                        </Typography>
                        <Typography variant="subtitle1">Aktiv: {isActive ? 'Ja' : 'Nein'}</Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Rhythmus: {RHYTHM_OPTIONS.find(r => r.value === rhythm)?.label || 'Einmalig'}
                        </Typography>
                        {rhythm === 'weekly' && (
                            <>
                                <Typography variant="subtitle1">
                                    Wochentage: {weekdays.join(', ') || 'Keine'}
                                </Typography>
                            </>
                        )}

                        {rhythm === 'monthly' && (
                            <Typography variant="subtitle1">
                                Tag des Monats: {dayOfMonth || 'Nicht gesetzt'}
                            </Typography>
                        )}

                        {rhythm === 'yearly' && (
                            <>
                                <Typography variant="subtitle1">
                                    Monat des Jahres: {monthOfYear || 'Nicht gesetzt'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    Tag im Monat: {dayOfMonth || 'Nicht gesetzt'}
                                </Typography>
                            </>
                        )}

                        <Typography variant="subtitle1">
                            Fälligkeitsuhrzeit: {dueTime || 'Keine Uhrzeit'}
                        </Typography>

                        {/* Zusammenfassung: Erinnerung vor Fälligkeit */}
                        <Typography variant="subtitle1">
                            Erinnerung vor Fälligkeit:
                            {(reminderBeforeDeadlineDays || reminderBeforeDeadlineHours || reminderBeforeDeadlineMinutesValue)
                                ? `${reminderBeforeDeadlineDays || 0}T/${reminderBeforeDeadlineHours || 0}Std/${reminderBeforeDeadlineMinutesValue || 0}Min`
                                : 'Keine'}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Fällig nach: {dueTime
                                ? `${dueDelayDays || 0} Tage ${dueDelayHours || 0} Std ${dueDelayMinutesValue || 0} Min`
                                : (dueDelayDays ? `${dueDelayDays} Tage` : 'Keine')}
                        </Typography>
                        <Typography variant="subtitle1">
                            Überfällig nach: {dueTime
                                ? `${overdueDelayDays || 0} Tage ${overdueDelayHours || 0} Std ${overdueDelayMinutesValue || 0} Min`
                                : (overdueDelayDays ? `${overdueDelayDays} Tage` : 'Keine')}
                        </Typography>
                        <Typography variant="subtitle1">
                            Eskalation nach: {dueTime
                                ? `${escalationDelayDays || 0} Tage ${escalationDelayHours || 0} Std ${escalationDelayMinutesValue || 0} Min`
                                : (escalationDelayDays ? `${escalationDelayDays} Tage` : 'Keine')}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Benachrichtigung Fällig (Benutzer): {dueNotifyUsers.length > 0
                                ? dueNotifyUsers.map(id => {
                                    const u = availableUsers.find(us => us.id === id);
                                    return u ? u.username : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>
                        <Typography variant="subtitle1">
                            Benachrichtigung Fällig (Gruppen): {dueNotifyGroups.length > 0
                                ? dueNotifyGroups.map(id => {
                                    const g = availableGroups.find(gr => gr.id === id);
                                    return g ? g.name : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Benachrichtigung Überfällig (Benutzer): {overdueNotifyUsers.length > 0
                                ? overdueNotifyUsers.map(id => {
                                    const u = availableUsers.find(us => us.id === id);
                                    return u ? u.username : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>
                        <Typography variant="subtitle1">
                            Benachrichtigung Überfällig (Gruppen): {overdueNotifyGroups.length > 0
                                ? overdueNotifyGroups.map(id => {
                                    const g = availableGroups.find(gr => gr.id === id);
                                    return g ? g.name : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Benachrichtigung Eskaliert (Benutzer): {escalatedNotifyUsers.length > 0
                                ? escalatedNotifyUsers.map(id => {
                                    const u = availableUsers.find(us => us.id === id);
                                    return u ? u.username : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>
                        <Typography variant="subtitle1">
                            Benachrichtigung Eskaliert (Gruppen): {escalatedNotifyGroups.length > 0
                                ? escalatedNotifyGroups.map(id => {
                                    const g = availableGroups.find(gr => gr.id === id);
                                    return g ? g.name : id;
                                }).join(', ')
                                : 'Keine'}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Zugewiesene Benutzer: {selectedUserNames}
                        </Typography>
                        <Typography variant="subtitle1">
                            Zugewiesene Gruppen: {selectedGroupNames}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Abschluss erfordert Personalnummer: {completionRequiresPersonalNumber ? 'Ja' : 'Nein'}
                        </Typography>
                        <Typography variant="subtitle1">
                            Abschluss erfordert Unterschrift: {completionRequiresSignature ? 'Ja' : 'Nein'}
                        </Typography>

                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            Anzahl Sektionen: {sections.length}
                        </Typography>
                        <Typography variant="subtitle1">
                            Anzahl Fragen gesamt: {fieldCount}
                        </Typography>
                    </Box>
                );
            }

            default:
                return null;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseWithConfirmation}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Neue Aufgabe erstellen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {renderStepContent(activeStep)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleCloseWithConfirmation}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Bestätigungsdialog für Abbrechen */}
            <Dialog
                open={openConfirmDialog}
                onClose={handleCancelClose}
            >
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchten Sie wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Nein
                    </Button>
                    <Button onClick={handleConfirmClose} color="secondary">
                        Ja, abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AufgabeErstellenDialog;
