import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from "@mui/icons-material/Store";
import WindowIcon from '@mui/icons-material/Window';


const ErfolgsplanerLayout = ({children}) => {
  const menuItems = [
    {text: 'Übersicht', path: '/targets/overview/index', icon: <WindowIcon/>, subModule: "overview"},

  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"targets"} menuItems={menuItems} title={'Erfolsplaner'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default ErfolgsplanerLayout;
