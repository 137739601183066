import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WindowIcon from '@mui/icons-material/Window';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';







const QualitaetLayout = ({children}) => {
  const menuItems = [
    {text: 'Quali-Checks', path: '/qualitaet/overview/index', icon: <WindowIcon/>, subModule: "overview"},
    {text: 'Produktion', path: '/qualitaet/analyse/index', icon: <PrecisionManufacturingOutlinedIcon/>, subModule: "analyse"},
    {text: 'Analyse', path: '/qualitaet/analyse/report', icon: <BusinessCenterOutlinedIcon/>, subModule: "overview"},
    {text: 'Analyse Managemet', path: '/qualitaet/analysemanagement/index', icon: <BusinessCenterOutlinedIcon/>, subModule: "analysemanagement"},
    {text: 'Konfiguration', path: '/qualitaet/settings/index', icon: <BuildOutlinedIcon/>, subModule: "settings"},

    

  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"qualitaet"} menuItems={menuItems} title={'Qualität'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default QualitaetLayout;
