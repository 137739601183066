import React, {useContext, useEffect, useState} from 'react';
import {Grid, IconButton, Stack, Typography} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import {FilterContext} from "../../../provider/FilterProvider";
import BigTableCard from "../../../components/card/BigTableCard";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigPieChartCard from '../../../components/card/BigPieChartCard';
import ArticleTablePopup from '../../../components/popup/ArticleTablePopup';
import ABCDialog from '../../../components/popup/ABC';
import TAWareWachstum from '../../../components/scorecards/tiefenanalyse/TAWareWachstum'; // Import the new Popup component
import AbcIcon from '@mui/icons-material/Abc';
import {tausenderTrennung} from '../../../utils/mathStuff';
import SearchIcon from '@mui/icons-material/QueryStats';
import FilialeMonatLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";

const Wachstum = () => {
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [comparisonCardData, setComparisonCardData] = useState([]);
  const [dataCardData, setDataCardData] = useState([]);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);
  const [selectedWarengruppeName, setSelectedWarengruppeName] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [abcPopupOpen, setAbcPopupOpen] = useState(false);
  const [taWareWachstumOpen, setTaWareWachstumOpen] = useState(false); // State for new Popup
  const [barChartData, setBarChartData] = useState({labels: [], data: []});
  const {filterState} = useContext(FilterContext);
  const activeCategory = 'Wachstum';

  useEffect(() => {
    if (filterState.filiale && filterState.datum) {
      fetchData();
      fetchTableData();
    }
  }, [filterState.filiale, filterState.datum]);

  const fetchData = async () => {
    try {
      const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
      const startDate = endDate.subtract(6, 'month');

      const response = await axiosInstance.get('scorecards_month/ware-filiale-monat/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.label
        }
      });

      const dataCardData = response.data.results
          .slice(-4)
          .map(item => ({
            x: item.datum,
            Wachstum: item.scoreProduktwachstumFiliale,
            Verfügbarkeit: item.scoreVerfügbarkeitFiliale,
            RetoureA: item.scoreSollRetoureFiliale,
            RetoureBC: item.scoreRetourQuoteFilialeBC,
          }));

      const comparisonCardData = response.data.results.slice(-1);

      setComparisonCardData(comparisonCardData);
      setDataCardData(dataCardData);
    } catch (error) {
      console.error('Ein Fehler ist beim Abrufen der Daten aufgetreten:', error);
    }
  };

  const fetchTableData = async () => {
    try {
      const response = await axiosInstance.get('scorecards_month/ware-warengruppe-monat/', {
        params: {
          filiale: filterState.filiale?.label,
          jahrMonat: filterState.datum?.value
        }
      });

      const dataTableData = response.data.results.map(item => {
        const diffVJ = item.verkaufsmenge - item.verkaufsmengeVJ;
        const verkaufsmengeProzent = item.verkaufsmengeVJ
            ? ((item.verkaufsmenge - item.verkaufsmengeVJ) / item.verkaufsmengeVJ) * 100
            : 0;

        return {
          Warengruppe: item.warengruppe,
          Warengruppennummer: item.warengruppennummer,
          Umsatz: item.umsatz,
          Verkaufsmenge: item.verkaufsmenge,
          VerkaufsmengeFormatiert: tausenderTrennung(item.verkaufsmenge, 0),
          VerkaufsmengeVJ: tausenderTrennung(item.verkaufsmengeVJ, 0),
          ScoreWachstumJ: item.scoreProduktwachstumJ,
          VerkaufsmengeProzent: tausenderTrennung(verkaufsmengeProzent, 2) + ' %',
          DiffVJ: tausenderTrennung(diffVJ, 0)
        };
      });

      const top5Warengruppen = [...dataTableData]
          .sort((a, b) => b.umsatz - a.umsatz)
          .slice(0, 5);

      const labels = top5Warengruppen.map(item => item.Warengruppe);
      const data = top5Warengruppen.map(item => parseFloat(item.VerkaufsmengeProzent));

      setBarChartData({labels, data});

      const newColumns = [
        {id: 'Warengruppe', accessorKey: 'Warengruppe', header: 'Warengruppe', grow: 1},
        {
          id: 'ScoreWachstumJ',
          accessorKey: 'ScoreWachstumJ',
          header: 'Score',
          size: 80,
          grow: true,
          Cell: ({cell}) => {
            const score = cell.getValue();

            // Bestimme die Farbe basierend auf dem Score-Wert
            let color;
            if (score < 4) {
              color = 'red';  // z.B. für niedrige Scores
            } else if (score >= 4 && score < 7) {
              color = '#EFB358';  // für mittlere Scores
            } else {
              color = 'green';  // für hohe Scores
            }

            // Gib die formatierte Zelle mit angepasster Farbe zurück
            return (
                <Typography sx={{color}}>
                  {score}
                </Typography>
            );
          },
        },
        {id: 'Verkaufsmenge', accessorKey: 'VerkaufsmengeFormatiert', header: 'Verkaufsmenge Stk.', size: 150, grow: true},
        {id: 'VerkaufsmengeVJ', accessorKey: 'VerkaufsmengeVJ', header: 'Vorjahr Stk.', size: 60, grow: true},
        {id: 'DiffVJ', accessorKey: 'DiffVJ', header: 'Diff. VJ Stk.', size: 80, grow: true},
        {id: 'VerkaufsmengeProzent', accessorKey: 'VerkaufsmengeProzent', header: 'VJ %', size: 60, grow: true},
      ];


      setTableColumns(newColumns);
      setTableData(dataTableData);
    } catch (error) {
      console.error('Ein Fehler ist beim Abrufen der Tabellendaten aufgetreten:', error);
    }
  };

  const handleRowClick = (row) => {
    setSelectedWarengruppe(row.Warengruppennummer);
    setSelectedWarengruppeName(row.Warengruppe);
    setPopupOpen(true);
  };

  const handleTaWareWachstumOpen = () => {
    setTaWareWachstumOpen(true);
  };

  const handleTaWareWachstumClose = () => {
    setTaWareWachstumOpen(false);
  };

  const muiTableBodyCellProps = ({cell}) => ({
    sx: {
      backgroundColor: cell.column.id === 'DiffVJ'
          ? cell.getValue() > 0
              ? 'rgba(0, 255, 0, 0.1)'
              : 'rgba(255, 0, 0, 0.1)'
          : 'inherit',
    },
  });

  return (
      <FilialeMonatLayout>
        <Grid container justifyContent="flex-end" alignItems="stretch">
          <Grid item xs={12}>
            <Stack direction="row" alignItems="stretch" spacing={2} sx={{overflowX: 'auto'}}>
              {['Wachstum', 'Verfügbarkeit', 'RetoureA', 'RetoureBC'].map((category, index) => (
                  <Grid
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      key={index}
                      className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                  >
                    <DataCard
                        data={dataCardData}
                        category={category}
                        sector="ware"
                        categoryData={
                          activeCategory === category
                              ? comparisonCardData.length > 0 ? comparisonCardData[0].bedarf : null
                              : ''
                        }
                        specialTitle={
                          category === "RetoureA"
                              ? "Retoure A" : category === "RetoureBC"
                                  ? "Retoure B/C" : null
                        }
                    />
                  </Grid>
              ))}
            </Stack>
          </Grid>

          <Grid container item xs={12} mt={3} spacing={2}>
            <Grid item xs={12} md={8}>
              <BigTableCard
                  title={
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="h6">Wachstum</Typography>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                            color="primary"
                            onClick={() => setAbcPopupOpen(true)}
                            sx={{fontSize: 40}}
                        >
                          <AbcIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            onClick={handleTaWareWachstumOpen} // Open the new Popup
                            sx={{fontSize: 30}}
                        >
                          <SearchIcon fontSize="inherit"/>
                        </IconButton>
                      </Stack>
                    </Stack>
                  }
                  data={tableData}
                  columns={tableColumns}
                  onRowClick={handleRowClick}
                  muiTableBodyCellProps={muiTableBodyCellProps}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BigPieChartCard
                  data={tableData}
                  title={"Verteilung Warengruppen"}
              />
            </Grid>
          </Grid>

        </Grid>

        <ScorecardsNotes category="Ware"/>

        <ArticleTablePopup
            open={popupOpen}
            handleClose={() => setPopupOpen(false)}
            warengruppe={selectedWarengruppe}
            filiale={filterState.filiale?.label}
            jahrMonat={filterState.datum?.value}
            warengruppeName={selectedWarengruppeName}
        />

        <TAWareWachstum
            open={taWareWachstumOpen} // Make sure this state changes correctly
            handleClose={handleTaWareWachstumClose}
            filiale={filterState.filiale?.label}
            jahrMonat={filterState.datum?.value}
        />
        <ABCDialog
            open={abcPopupOpen}
            onClose={() => setAbcPopupOpen(false)}
            filiale={filterState.filiale?.label}
            monat={filterState.datum?.value}
        />
      </FilialeMonatLayout>
  );
};

export default Wachstum;
