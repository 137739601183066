// Sidebar.js

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppsIcon from '@mui/icons-material/Apps';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { ReactComponent as DatabitesLogo } from '../../assets/images/logo_dashboard.svg';
import { ReactComponent as DatabitesLogoSmall } from '../../assets/images/logo_dashboard_small.svg';
import DropdownFilter from '../filter/DropdownFilter';
import axiosInstance from '../../services/axiosInstance';
import { FilterContext } from '../../provider/FilterProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '../../provider/authProvider';
import { InfoBarContext } from '../../provider/InfoBar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import dayjs from 'dayjs';
import ProfilePicture from '../pictures/ProfilePicture';

const drawerWidth = 280;

/** MUI-Styles für geöffneten und geschlossenen Drawer */
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'clip',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'clip',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const PermanentDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'clip',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

/**
 * SubMenuItem-Komponente
 * - einzelne Unterpunkte
 * - Prüft, ob aktiv -> vergibt .active
 */
const SubMenuItem = React.memo(({ subItem, navigate, open, isMenuItemActive }) => {
  const active = isMenuItemActive(subItem.path);

  return (
    <ListItem
      disablePadding
      sx={{ display: 'block' }}
      className={`db_sidebar_submenu_item${active ? ' active' : ''}`}
    >
      <ListItemButton
        onClick={() => navigate(subItem.path)}
        sx={{ minHeight: 36, justifyContent: open ? 'initial' : 'center', pl: 7 }}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: 'center' }}>
          {subItem.icon}
        </ListItemIcon>
        <ListItemText primary={subItem.text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
});

/**
 * MenuItem-Komponente
 * - Hauptpunkt + optionales Submenü
 * - Prüft, ob aktiv -> vergibt .active
 */
const MenuItem = React.memo(
  ({
    menuItem,
    open,
    handleClick,
    navigate,
    isSectionActive,
    isMenuItemActive,
    openSections,
      toggleDrawer,
  }) => {
    // Aktiv, wenn entweder das Hauptmenü selbst oder eines seiner SubItems aktiv ist
    const active =
      (menuItem.subItems && isSectionActive(menuItem.subItems)) ||
      (!menuItem.subItems && isMenuItemActive(menuItem.path));

    return (
      <React.Fragment>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          className={`db_sidebar_menu_item${active ? ' active' : ''}`}
        >
          <ListItemButton
              onClick={() => {
                if (menuItem.subItems && menuItem.subItems.length > 0) {
                  if (!open) {
                    toggleDrawer(); // Ensure the sidebar is expanded if collapsed
                  }
                  handleClick(menuItem.text); // Open the group
                } else {
                  navigate(menuItem.path);
                }
              }}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: open ? 2.5 : 1,
              borderRadius: '8px',
            }}
          >
            <ListItemIcon
              sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}
            >
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText
              primary={menuItem.text}
              sx={{ opacity: open ? 1 : 0 }}
            />
            {open && menuItem.subItems && (
              openSections[menuItem.text] ? <ExpandLess /> : <ExpandMore />
            )}
          </ListItemButton>
        </ListItem>

        {/* Submenu (Collapse) */}
        {open && menuItem.subItems && (
          <Collapse
            in={openSections[menuItem.text]}
            timeout="auto"
            unmountOnExit
            className="db_sidebar_submenu"
          >
            <List component="div" disablePadding>
              {menuItem.subItems.map((subItem) => (
                <SubMenuItem
                  key={subItem.text}
                  subItem={subItem}
                  navigate={navigate}
                  open={open}
                  isMenuItemActive={isMenuItemActive}
                />
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  }
);

/**
 * Haupt-Sidebar-Komponente
 */
const Sidebar = ({
  open,
  toggleDrawer,
  variant,
  moduleName,
  menuItems = [],
  title,
  monthFilter = false,
  weekFilter = false,
  filialFilter = false,
  bestehendeFlaecheSwitch = false,
}) => {
  // Filter-Kontext
  const {
    filterState,
    handleFilialeChange,
    handleDatumChange,
    handleDatumWocheChange,
    handleBestehendeFlaecheChange,
  } = useContext(FilterContext);

  // States für Filial- und Datumsoptionen
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [datumOptions, setDatumOptions] = useState([]);

  // State zum Offen-/Geschlossen-Status der Submenüs
  const [openSections, setOpenSections] = useState({});

  // Router / MUI
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Auth-Context (Rollen)
  const { user: currentUser } = useAuth();
  const { showError } = useContext(InfoBarContext);

  // Ermitteln, welche Submodule der User darf
  const userSubmodules = currentUser.role.module_permissions.find(
    (module) => module.name === moduleName
  );
  const nameOfSubmodules = userSubmodules.submodules.map(
    (submodule) => submodule.name
  );

  // Nur erlaubte Menüeinträge
  const filteredMenuItems = useMemo(
    () => menuItems.filter((item) => nameOfSubmodules.includes(item.subModule)),
    [menuItems, nameOfSubmodules]
  );

  // Daten laden
  useEffect(() => {
    if (filialFilter) fetchFilialeOptions();
    if (monthFilter) fetchMonthOptions();
    else if (weekFilter) fetchWeekOptions();
    // eslint-disable-next-line
  }, []);

  /** Lade Monatsoptionen */
  const fetchMonthOptions = async () => {
    try {
      const datumResponse = await axiosInstance.get('stammdaten/unique-jahr-monat/');
      const allDatumOptions = datumResponse.data;

      // Letzten Monat bestimmen (z. B. 2025.01)
      const lastMonth = dayjs().subtract(1, 'month').format('YYYY.MM');

      // Filtere alle Monate bis einschließlich letzten Monat
      const filteredDatum = allDatumOptions
        .filter((item) =>
          dayjs(item, 'YYYY.MM').isBefore(dayjs().startOf('month'), 'month')
        )
        .sort((a, b) => dayjs(b, 'YYYY.MM').diff(dayjs(a, 'YYYY.MM')));

      const datumOpts = filteredDatum.map((item) => ({ label: item, value: item }));
      setDatumOptions(datumOpts);

      if (!filterState.datum && datumOpts.length > 0) {
        // Standardmäßig: letzter Monat oder erster Eintrag
        const defaultDatum =
          datumOpts.find((opt) => opt.value === lastMonth) || datumOpts[0];
        handleDatumChange(defaultDatum);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Optionen:', error);
      showError('Fehler beim Abrufen der Datums Optionen');
    }
  };

  /** Lade Wochenoptionen (aktualisierte Version) */
  const fetchWeekOptions = async () => {
    try {
      const response = await axiosInstance.get('stammdaten/unique-jahr-woche/');
      // Rohdaten in ein Array von { label, value } umwandeln
      let allOptions = response.data.map((item) => ({ label: item, value: item }));

      // Heutiges Datum & Jahr/Woche bestimmen
      const today = new Date();
      let currentYear = today.getFullYear();
      let currentWeek = Math.ceil(
        ((today - new Date(today.getFullYear(), 0, 1)) / 86400000 +
          today.getDay() +
          1) /
          7
      );

      // Letzte abgeschlossene KW ermitteln
      let lastCompletedWeek = currentWeek - 1;
      let lastCompletedYear = currentYear;

      // Falls currentWeek = 1, müssen wir ins Vorjahr wechseln
      if (lastCompletedWeek < 1) {
        lastCompletedYear = currentYear - 1;

        // Letzte KW des Vorjahres berechnen
        const lastYearEnd = new Date(lastCompletedYear, 11, 31); // 31.12.(Vorjahr)
        lastCompletedWeek = Math.ceil(
          ((lastYearEnd - new Date(lastYearEnd.getFullYear(), 0, 1)) / 86400000 +
            lastYearEnd.getDay() +
            1) /
            7
        );
      }

      // Format "YYYY.WW" -> z. B. "2025.01"
      const lastCompletedWeekString = `${lastCompletedYear}.${String(
        lastCompletedWeek
      ).padStart(2, '0')}`;

      // Hilfsfunktionen zum Parsen und Vergleichen
      const parseYearWeek = (val) => {
        const [y, w] = val.split('.');
        return { year: parseInt(y, 10), week: parseInt(w, 10) };
      };

      const compareYearWeek = (a, b) => {
        if (a.year < b.year) return -1;
        if (a.year > b.year) return 1;
        if (a.week < b.week) return -1;
        if (a.week > b.week) return 1;
        return 0;
      };

      // Filtere alle Optionen, die <= letzte abgeschlossene KW sind
      allOptions = allOptions.filter((item) => {
        const itemParsed = parseYearWeek(item.value);
        const lastCWParsed = parseYearWeek(lastCompletedWeekString);
        return compareYearWeek(itemParsed, lastCWParsed) <= 0;
      });

      // Sortiere absteigend: zuerst die neueste KW
      allOptions.sort((a, b) => {
        const aParsed = parseYearWeek(a.value);
        const bParsed = parseYearWeek(b.value);
        // b-a, damit es absteigend ist
        return compareYearWeek(bParsed, aParsed);
      });

      setDatumOptions(allOptions);

      // Falls noch kein Filter gesetzt, nimm die letzte abgeschlossene KW
      if (!filterState.datumWoche && allOptions.length > 0) {
        // Nach dem Sortieren ist allOptions[0] bereits die letzte abgeschlossene KW
        handleDatumWocheChange(allOptions[0]);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Datumsoptionen:', error);
      showError('Fehler beim Abrufen der Datums Optionen');
    }
  };

  /** Lade Filialoptionen */
  const fetchFilialeOptions = async () => {
    try {
      const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
      const filialeOpts = filialeResponse.data.map((item) => ({
        label: item.filiale,
        value: item.filiale,
      }));
      setFilialeOptions(filialeOpts);

      if (!filterState.filiale && filialeOpts.length > 0) {
        handleFilialeChange(filialeOpts[0]);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Optionen:', error);
      showError('Fehler beim Abrufen der Filial Optionen');
    }
  };

  /** Öffnet/Schließt das Submenü */
  const handleClick = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  /** Prüft, ob ein Menüpfad mit der aktuellen URL identisch ist */
  const isMenuItemActive = (path) => location.pathname === path;

  /** Prüft, ob eines der Submenü-Items aktiv ist */
  const isSectionActive = (subItems) =>
    Array.isArray(subItems) &&
    subItems.some((item) => location.pathname === item.path);

  /** Aufbau des Sidebar-Inhalts */
  const SidebarContent = (
    <Box
      className="db_sidebar_head"
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: isMobile ? theme.spacing(10) : 0,
      }}
    >
      {/* Kopfbereich (Logo, Titel, Filter) */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {open ? (
          <DatabitesLogo
            className="db_logo"
            style={{ width: isMobile ? '120px' : '200px', height: 'auto' }}
          />
        ) : (
          <DatabitesLogoSmall
            className="db_logo db_logo_small"
            style={{ width: '40px', height: 'auto' }}
          />
        )}

        <Typography
          variant="h4"
          className="db_sidebar_headline"
          sx={{ display: open ? 'block' : 'none', textAlign: isMobile ? 'center' : 'left' }}
        >
          {title}
        </Typography>

        <br />

        {open && filialFilter && (
          <DropdownFilter
            label="Filiale"
            options={filialeOptions}
            value={filterState.filiale}
            onChange={handleFilialeChange}
          />
        )}

        {open && monthFilter && (
          <DropdownFilter
            label="Datum"
            options={datumOptions}
            value={filterState.datum}
            onChange={handleDatumChange}
          />
        )}

        {open && weekFilter && !monthFilter && (
          <DropdownFilter
            label="Datum"
            options={datumOptions}
            value={filterState.datumWoche}
            onChange={handleDatumWocheChange}
          />
        )}

        {open && bestehendeFlaecheSwitch && (
          <FormControlLabel
            control={
              <Switch
                checked={filterState.bestehendeFlaeche}
                onChange={(event) => handleBestehendeFlaecheChange(event.target.checked)}
                color="primary"
              />
            }
            label="Bestehende Fläche"
          />
        )}
      </Box>

      {/* Haupt-Menüliste */}
      <List className="db_sidebar_menu">
        {filteredMenuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.text}
            menuItem={menuItem}
            open={open}
            handleClick={handleClick}
            navigate={navigate}
            isSectionActive={isSectionActive}
            isMenuItemActive={isMenuItemActive}
            openSections={openSections}
            toggleDrawer={toggleDrawer}
          />
        ))}
      </List>

      {/* Profilbild unten */}
      {open ? (
        <Box sx={{ marginTop: 'auto', ml: 2, display: 'flex', justifyContent: 'flex-start' }}>
          <ProfilePicture withText />
        </Box>
      ) : (
        <Box sx={{ marginTop: 'auto' }}>
          <ProfilePicture smallVersion />
        </Box>
      )}

      {/* Footer: Data Apps & Toggle-Button */}
      <Box
        className="db_sidebar_foot"
        sx={{
          display: 'flex',
          flexDirection: open ? 'row' : 'column',
          alignItems: 'center',
          padding: theme.spacing(2),
          gap: theme.spacing(2),
          justifyContent: open ? 'space-between' : 'center',
        }}
      >
        {open ? (
          <Button
            className="db_all_apps_button"
            variant="outlined"
            startIcon={<AppsIcon sx={{ color: 'primary.main' }} />}
            onClick={() => navigate('/')}
          >
            <Typography sx={{ opacity: open ? 1 : 0 }}>Data Apps</Typography>
          </Button>
        ) : (
          <IconButton onClick={() => navigate('/')}>
            <AppsIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        )}

        <IconButton onClick={toggleDrawer}>
          {open ? (
            <ChevronLeftIcon sx={{ color: 'primary.main' }} />
          ) : (
            <ChevronRightIcon sx={{ color: 'primary.main' }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );

  // Drawer abhängig von "variant"
  return variant === 'temporary' ? (
    <MuiDrawer
      variant="temporary"
      open={open}
      onClose={toggleDrawer}
      className="db_sidebar"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: { width: drawerWidth, height: '100vh' },
      }}
    >
      {SidebarContent}
    </MuiDrawer>
  ) : (
    <PermanentDrawer variant="permanent" open={open} className="db_sidebar">
      {SidebarContent}
    </PermanentDrawer>
  );
};

export default Sidebar;
