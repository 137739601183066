// SingleDatePicker.jsx
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

const SingleDatePicker = ({ label = "Datum auswählen", value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        format="DD.MM.YYYY"         // <-- Anzeigeformat TT.MM.JJJJ
        sx={{
          '& .MuiInputBase-root': {
            height: '40px',
            minWidth: '250px',
            fontSize: '0.875rem',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default SingleDatePicker;
