import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout/Tagesreport/TagesreportLayout';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import VerkaufsgebietFilterSelection from '../../../components/tagesreport/VerkaufsgebietFilterSelection';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { roundToDecimals } from '../../../utils/mathStuff';
import BigTableCard from '../../../components/card/BigTableCard';

// NEU: ButtonGroupFilter für die schnelle Datumsauswahl
import ButtonGroupFilter from '../../../components/filter/ButtonGroupFilter';

const Fehlmenge = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Datum-State (default: gestern)
  const [datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));

  // NEU: State für die Quick-Select-Datumsoptionen (z.B. letzte 4 Tage)
  const [datumOptions, setDatumOptions] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);

  const date = new Date(datum);
  const dayName = date.toLocaleDateString('de-DE', { weekday: 'long' });
  const [totals, setTotals] = useState({
    Liefermenge: 0,
    Korrektur: 0,
    Verkaufsmenge: 0,
    Retourmenge: 0,
    Fehlmenge: 0,
  });

  // Helper-Funktion: generiert die letzten 4 Tage als Button-Options
  const generateLast4DaysOptions = () => {
    const options = [];
    for (let i = 1; i <= 4; i++) {
      const d = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
      options.push({
        label: dayjs(d).format('DD.MM'),  // z.B. "03.01"
        value: d,
      });
    }
    return options;
  };

  // Laden der Quick-Select-Optionen + Laden der Verkaufsgebiet-Optionen beim ersten Rendern
  useEffect(() => {
    setDatumOptions(generateLast4DaysOptions());
    fetchVerkaufsgebietOptions();
  }, []);

  // Bei Änderung von datum oder Filter => Daten erneut holen
  useEffect(() => {
    fetchData();
  }, [datum, verkaufsgebietFilter]);

  // API-Call für Verkaufsgebiet-Auswahl
  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
      setVerkaufsgebietOptions(
        response.data.map((item) => ({ label: item, value: item })),
      );
    } catch (error) {
      console.error('Error while fetching VerkaufsgebietOptions', error);
    }
  };

  // API-Call für Daten
  const fetchData = async () => {
    const formattedDatum = dayjs(datum).format('YYYY-MM-DD');
    try {
      setLoading(true);
      const response = await axiosInstance.get('/fact/facts/', {
        params: {
          startdatum: formattedDatum,
          enddatum: formattedDatum,
          mindestLiefermenge: 1,
          verkaufsgebiet: verkaufsgebietFilter?.value,
        },
      });

      if (Array.isArray(response.data.results)) {
        const mappedData = response.data.results
          .map((item) => ({
            Filiale: item.filiale,
            Artikel: item.artikel,
            Liefermenge: item.liefermenge || 0,
            Korrektur: item.korrekturmenge || 0,
            Verkaufsmenge: item.verkaufsmenge || 0,
            Retourmenge: item.retourmenge || 0,
            Fehlmenge:
              (item.liefermenge || 0) -
              (item.verkaufsmenge || 0) -
              (item.retourmenge || 0) +
              (item.korrekturmenge || 0),
          }))
          .filter((item) => item.Fehlmenge !== 0);

        setData(mappedData);

        // Totals-Objekt befüllen
        setTotals({
          Liefermenge: response.data.totals.total_liefermenge || 0,
          Korrektur: roundToDecimals(response.data.totals.total_korrektur, 2) || 0,
          Verkaufsmenge: roundToDecimals(response.data.totals.total_verkaufsmenge, 2) || 0,
          Retourmenge: roundToDecimals(response.data.totals.total_retourmenge, 2) || 0,
          Fehlmenge: roundToDecimals(response.data.totals.total_fehlmenge, 2) || 0,
        });
      } else {
        console.error('Response data is not an array', response.data);
      }
    } catch (error) {
      console.error('Error while fetching Tagesreport Fehlmenge', error);
    } finally {
      setLoading(false);
    }
  };

  // Tabellen-Konfiguration
  const columns = [
    { accessorKey: 'Filiale', header: 'Filiale', footer: 'Gesamt', size: 200 },
    { accessorKey: 'Artikel', header: 'Artikel', footer: '', size: 200 },
    { accessorKey: 'Liefermenge', header: 'Liefermenge', footer: totals.Liefermenge, size: 170 },
    { accessorKey: 'Korrektur', header: 'Korrektur', footer: totals.Korrektur, size: 140 },
    { accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge', footer: totals.Verkaufsmenge, size: 150 },
    { accessorKey: 'Retourmenge', header: 'Retourmenge', footer: totals.Retourmenge, size: 150 },
    { accessorKey: 'Fehlmenge', header: 'Fehlmenge', footer: totals.Fehlmenge, size: 150 },
  ];

  return (
    <Layout>
      <Container
        maxWidth={false}
        sx={{
          padding: '0 16px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isMobile ? theme.spacing(8) : 0,
        }}
      >
        {/* Kopfzeile plus Datums-Quick-Select */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <PageHeader
            title={`Fehlmenge vom ${dayjs(datum).format('dddd, D. MMMM YYYY')}`}
            subtitle="Übersicht über die Fehlmengen für den ausgewählten Tag."
            Icon={BarChartIcon}
          />

          {/* NEU: ButtonGroupFilter für die schnelle Datumsauswahl */}
          <ButtonGroupFilter
            options={datumOptions}
            value={{ value: datum }}
            onChange={(option) => {
              if (option) {
                setDatum(option.value);
              }
            }}
          />
        </Box>

        <VerkaufsgebietFilterSelection
          verkaufsgebietFilter={verkaufsgebietFilter}
          setVerkaufsgebietFilter={setVerkaufsgebietFilter}
          verkaufsgebietOptions={verkaufsgebietOptions}
        />

        <br />
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress size={80} />
          </div>
        ) : (
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <BigTableCard columns={columns} data={data} sx={{ width: '100%' }} />
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Fehlmenge;
