import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// DnD
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Feldtypen
const FIELD_TYPES = [
    { value: 'text', label: 'Freitext' },
    { value: 'boolean', label: 'Ja/Nein' },
    { value: 'choice_single', label: 'Auswahl (einfach)' },
    { value: 'choice_multiple', label: 'Auswahl (mehrfach)' },
    { value: 'number', label: 'Zahl' },
    { value: 'date', label: 'Datum' },
    { value: 'datetime', label: 'Datum/Uhrzeit' },
    { value: 'file', label: 'Dateiupload' },
    { value: 'signature', label: 'Unterschrift' },
];

// Vorschau eines Feldes
function renderFieldPreview(field) {
    const { field_type, options } = field;
    switch (field_type) {
        case 'text':
            return <TextField fullWidth placeholder="Antwort" InputProps={{ readOnly: true }} />;
        case 'boolean':
            return <FormControlLabel control={<Checkbox disabled />} label="Ja/Nein (Placeholder)" />;
        case 'choice_single':
        case 'choice_multiple':
            // Nur eine grobe Vorschau
            return (
                <Box>
                    {options?.choices?.map((opt, i) => (
                        <FormControlLabel
                            key={i}
                            control={<Checkbox disabled />}
                            label={opt}
                        />
                    ))}
                </Box>
            );
        case 'number':
            return <Typography>Zahl-Feld (Vorschau)</Typography>;
        case 'date':
            return <TextField type="date" disabled fullWidth />;
        case 'datetime':
            return <TextField type="datetime-local" disabled fullWidth />;
        case 'file':
            return <Button variant="outlined" disabled>Datei hochladen</Button>;
        case 'signature':
            return <Typography>Signatur-Feld (Vorschau)</Typography>;
        default:
            return <Typography>Unbekannter Typ</Typography>;
    }
}

// Hauptkomponente
export default function SectionAndQuestionBuilder({ sections, onChange }) {
    // Dialog-States für Sektionen
    const [openSectionDialog, setOpenSectionDialog] = useState(false);
    const [editingSectionIndex, setEditingSectionIndex] = useState(null);
    const [sectionName, setSectionName] = useState('');
    const [sectionDescription, setSectionDescription] = useState('');

    // Dialog-States für Felder
    const [openFieldDialog, setOpenFieldDialog] = useState(false);
    const [editingFieldSectionIndex, setEditingFieldSectionIndex] = useState(null);
    const [editingFieldIndex, setEditingFieldIndex] = useState(null);

    const [fieldLabel, setFieldLabel] = useState('');
    const [fieldType, setFieldType] = useState('text');
    const [fieldRequired, setFieldRequired] = useState(false);
    const [fieldHelpText, setFieldHelpText] = useState('');
    const [optionsInput, setOptionsInput] = useState('');
    const [optionsList, setOptionsList] = useState([]);
    const [showHelpText, setShowHelpText] = useState(false);

    // Expand states
    const [expandedSections, setExpandedSections] = useState({});
    const [expandedFields, setExpandedFields] = useState({});

    // ---------- Dialog-Funktionen: Sektion ----------
    const handleOpenSectionDialog = (index = null) => {
        // Falls eine vorhandene Sektion bearbeitet wird
        if (index !== null) {
            setEditingSectionIndex(index);
            setSectionName(sections[index].name);
            setSectionDescription(sections[index].description || '');
        } else {
            // Neuer Eintrag
            setEditingSectionIndex(null);
            setSectionName('');
            setSectionDescription('');
        }
        setOpenSectionDialog(true);
    };

    const handleCloseSectionDialog = () => {
        setOpenSectionDialog(false);
        setEditingSectionIndex(null);
        setSectionName('');
        setSectionDescription('');
    };

    const handleSaveSection = () => {
        const updated = [...sections];
        if (editingSectionIndex !== null) {
            // bearbeiten
            updated[editingSectionIndex] = {
                ...updated[editingSectionIndex],
                name: sectionName,
                description: sectionDescription || ''
            };
        } else {
            // neu anlegen
            updated.push({
                name: sectionName,
                description: sectionDescription || '',
                fields: []
            });
        }
        onChange(updated);
        handleCloseSectionDialog();
    };

    const handleDeleteSection = (secIndex) => {
        const updated = [...sections];
        updated.splice(secIndex, 1);
        onChange(updated);
    };

    const toggleSectionExpand = (secIndex) => {
        setExpandedSections(prev => ({ ...prev, [secIndex]: !prev[secIndex] }));
    };

    // ---------- Dialog-Funktionen: Feld ----------
    const handleOpenFieldDialog = (secIndex, fieldIndex = null) => {
        setEditingFieldSectionIndex(secIndex);

        if (fieldIndex !== null) {
            // Feld bearbeiten
            setEditingFieldIndex(fieldIndex);
            const field = sections[secIndex].fields[fieldIndex];
            setFieldLabel(field.label);
            setFieldType(field.field_type);
            setFieldRequired(field.required || false);
            setFieldHelpText(field.help_text || '');
            setOptionsList(field.options?.choices || []);
            setShowHelpText(!!field.help_text);
        } else {
            // Neues Feld
            setEditingFieldIndex(null);
            setFieldLabel('');
            setFieldType('text');
            setFieldRequired(false);
            setFieldHelpText('');
            setOptionsList([]);
            setShowHelpText(false);
        }
        setOptionsInput('');
        setOpenFieldDialog(true);
    };

    const handleCloseFieldDialog = () => {
        setOpenFieldDialog(false);
        setEditingFieldSectionIndex(null);
        setEditingFieldIndex(null);
        setFieldLabel('');
        setFieldType('text');
        setFieldRequired(false);
        setFieldHelpText('');
        setOptionsList([]);
        setShowHelpText(false);
        setOptionsInput('');
    };

    const handleSaveField = () => {
        const updated = [...sections];
        const secCopy = { ...updated[editingFieldSectionIndex] };
        if (!secCopy.fields) secCopy.fields = [];

        let finalOptions = null;
        if (fieldType === 'choice_single' || fieldType === 'choice_multiple') {
            finalOptions = { choices: optionsList };
        }

        const newField = {
            label: fieldLabel,
            field_type: fieldType,
            required: fieldRequired,
            help_text: fieldHelpText,
            options: finalOptions
        };

        if (editingFieldIndex !== null) {
            // Bearbeitung
            secCopy.fields[editingFieldIndex] = newField;
        } else {
            // Neuanlage
            secCopy.fields.push(newField);
        }
        updated[editingFieldSectionIndex] = secCopy;
        onChange(updated);
        handleCloseFieldDialog();
    };

    const handleDeleteField = (secIndex, fieldIndex) => {
        const updated = [...sections];
        const secCopy = { ...updated[secIndex] };
        secCopy.fields.splice(fieldIndex, 1);
        updated[secIndex] = secCopy;
        onChange(updated);
    };

    const toggleFieldExpand = (secIndex, fieldIndex) => {
        const key = `${secIndex}-${fieldIndex}`;
        setExpandedFields(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleAddChoiceOption = () => {
        if (!optionsInput.trim()) return;
        setOptionsList([...optionsList, optionsInput.trim()]);
        setOptionsInput('');
    };

    // ---------- Drag & Drop ----------
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination, type } = result;

        if (type === 'SECTION') {
            // Sektionen verschieben
            const updated = Array.from(sections);
            const [moved] = updated.splice(source.index, 1);
            updated.splice(destination.index, 0, moved);
            onChange(updated);
        } else {
            // Felder verschieben
            const sourceSecIndex = parseInt(source.droppableId.replace('section-', ''), 10);
            const destSecIndex = parseInt(destination.droppableId.replace('section-', ''), 10);

            const updated = [...sections];
            const sourceFields = Array.from(updated[sourceSecIndex].fields);
            const [movedItem] = sourceFields.splice(source.index, 1);

            if (sourceSecIndex === destSecIndex) {
                // Gleiches Section
                sourceFields.splice(destination.index, 0, movedItem);
                updated[sourceSecIndex].fields = sourceFields;
            } else {
                // Andere Section
                const destFields = Array.from(updated[destSecIndex].fields);
                destFields.splice(destination.index, 0, movedItem);
                updated[sourceSecIndex].fields = sourceFields;
                updated[destSecIndex].fields = destFields;
            }
            onChange(updated);
        }
    };

    const renderFieldsForSection = (sec, secIndex) => {
        return (
            <Droppable droppableId={`section-${secIndex}`} type="QUESTION">
                {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {sec.fields && sec.fields.length > 0 ? (
                            sec.fields.map((field, fieldIndex) => {
                                const key = `${secIndex}-${fieldIndex}`;
                                const expanded = expandedFields[key] || false;
                                return (
                                    <Draggable key={key} draggableId={key} index={fieldIndex}>
                                        {(providedField, snapshotField) => (
                                            <Card
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                ref={providedField.innerRef}
                                                {...providedField.draggableProps}
                                                style={{
                                                    ...providedField.draggableProps.style,
                                                    boxShadow: snapshotField.isDragging
                                                        ? '0 0 10px rgba(0,0,0,0.2)'
                                                        : 'none'
                                                }}
                                            >
                                                <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => toggleFieldExpand(secIndex, fieldIndex)}
                                                        sx={{
                                                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                                            transition: 'transform 0.2s'
                                                        }}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                                        {fieldIndex + 1}. {field.label}
                                                    </Typography>
                                                    <IconButton size="small" {...providedField.dragHandleProps}>
                                                        <DragIndicatorIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleOpenFieldDialog(secIndex, fieldIndex)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => handleDeleteField(secIndex, fieldIndex)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardContent>
                                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                    <CardContent>
                                                        {renderFieldPreview(field)}
                                                        <Box sx={{ mt: 2 }}>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={field.required} disabled />}
                                                                label="Pflichtfeld"
                                                            />
                                                        </Box>
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        )}
                                    </Draggable>
                                );
                            })
                        ) : (
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Noch keine Fragen in dieser Sektion.
                            </Typography>
                        )}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        );
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Sektionen &amp; Fragen definieren
            </Typography>
            <Typography variant="body2" gutterBottom>
                Klicke auf „Neue Sektion“ oder „Neue Frage hinzufügen“, um ein Dialogfenster zu öffnen.
            </Typography>

            {/* Button zum Neuanlegen einer Sektion */}
            <Box sx={{ mt: 2 }}>
                <Button
                    variant="text"
                    startIcon={<AddCircleIcon />}
                    onClick={() => handleOpenSectionDialog(null)}
                >
                    Neue Sektion hinzufügen
                </Button>
            </Box>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sections" type="SECTION">
                    {(provided) => (
                        <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ mt: 2 }}>
                            {sections.length === 0 && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Keine Sektionen vorhanden.
                                </Typography>
                            )}
                            {sections.map((sec, secIndex) => {
                                const expanded = expandedSections[secIndex] || false;
                                return (
                                    <Draggable
                                        key={`section-${secIndex}`}
                                        draggableId={`section-${secIndex}`}
                                        index={secIndex}
                                    >
                                        {(providedSec, snapshotSec) => (
                                            <Card
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                ref={providedSec.innerRef}
                                                {...providedSec.draggableProps}
                                                style={{
                                                    ...providedSec.draggableProps.style,
                                                    boxShadow: snapshotSec.isDragging
                                                        ? '0 0 10px rgba(0,0,0,0.2)'
                                                        : 'none'
                                                }}
                                            >
                                                <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setExpandedSections((prev) => ({
                                                                ...prev,
                                                                [secIndex]: !prev[secIndex]
                                                            }))
                                                        }
                                                        sx={{
                                                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                                            transition: 'transform 0.2s'
                                                        }}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                                        {secIndex + 1}. {sec.name}
                                                    </Typography>
                                                    <IconButton size="small" {...providedSec.dragHandleProps}>
                                                        <DragIndicatorIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleOpenSectionDialog(secIndex)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDeleteSection(secIndex)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardContent>

                                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                    <CardContent>
                                                        {sec.description && (
                                                            <Typography variant="body2" sx={{ mb: 2 }}>
                                                                {sec.description}
                                                            </Typography>
                                                        )}
                                                        {/* Button: Neue Frage hinzufügen */}
                                                        <Button
                                                            variant="text"
                                                            startIcon={<AddCircleIcon />}
                                                            sx={{ mb: 2 }}
                                                            onClick={() => handleOpenFieldDialog(secIndex, null)}
                                                        >
                                                            Neue Frage hinzufügen
                                                        </Button>

                                                        {/* Felder in dieser Sektion */}
                                                        {renderFieldsForSection(sec, secIndex)}
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>

            {/* ----------------- DIALOG: Sektion ----------------- */}
            <Dialog open={openSectionDialog} onClose={handleCloseSectionDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {editingSectionIndex !== null
                        ? 'Sektion bearbeiten'
                        : 'Neue Sektion'}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name der Sektion"
                        value={sectionName}
                        onChange={(e) => setSectionName(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        label="Beschreibung (optional)"
                        value={sectionDescription}
                        onChange={(e) => setSectionDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSectionDialog}>
                        Abbrechen
                    </Button>
                    <Button variant="contained" onClick={handleSaveSection}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>

            {/* ----------------- DIALOG: Feld (Frage) ----------------- */}
            <Dialog open={openFieldDialog} onClose={handleCloseFieldDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {editingFieldIndex !== null ? 'Frage bearbeiten' : 'Neue Frage'}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Label der Frage"
                        value={fieldLabel}
                        onChange={(e) => setFieldLabel(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        select
                        fullWidth
                        label="Fragetyp"
                        value={fieldType}
                        onChange={(e) => setFieldType(e.target.value)}
                        sx={{ mt: 2 }}
                    >
                        {FIELD_TYPES.map(ft => (
                            <MenuItem key={ft.value} value={ft.value}>{ft.label}</MenuItem>
                        ))}
                    </TextField>

                    <FormControlLabel
                        control={<Checkbox checked={fieldRequired} onChange={(e) => setFieldRequired(e.target.checked)} />}
                        label="Pflichtfeld"
                        sx={{ mt: 2 }}
                    />

                    <FormControlLabel
                        control={<Checkbox checked={showHelpText} onChange={(e) => setShowHelpText(e.target.checked)} />}
                        label="Hilfetext anzeigen"
                        sx={{ mt: 2 }}
                    />

                    {showHelpText && (
                        <TextField
                            label="Hilfetext"
                            value={fieldHelpText}
                            onChange={(e) => setFieldHelpText(e.target.value)}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    )}

                    {/* Falls choice_single oder choice_multiple => Optionen */}
                    {(fieldType === 'choice_single' || fieldType === 'choice_multiple') && (
                        <Box sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 1, p: 2 }}>
                            <Typography variant="subtitle2">Optionen</Typography>
                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                <TextField
                                    label="Neue Option"
                                    value={optionsInput}
                                    onChange={(e) => setOptionsInput(e.target.value)}
                                    sx={{ flexGrow: 1 }}
                                />
                                <Button variant="outlined" onClick={handleAddChoiceOption}>
                                    Hinzufügen
                                </Button>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                {optionsList.length > 0 ? (
                                    <ul>
                                        {optionsList.map((opt, i) => <li key={i}>{opt}</li>)}
                                    </ul>
                                ) : (
                                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                        Keine Optionen vorhanden
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFieldDialog}>
                        Abbrechen
                    </Button>
                    <Button variant="contained" onClick={handleSaveField}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
