import React, {useContext, useEffect, useState} from 'react';
import {Grid,} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCard';
import {FilterContext} from "../../../provider/FilterProvider";
import AlertField from "../../../components/utility/AlertField";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigChartCard from "../../../components/card/BigChartCard";
import TitleCard from "../../../components/card/TitleCard";


const PerformanceUmsatz = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const {filterState} = useContext(FilterContext);

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(12, 'month');

            const response = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value
                }
            });

            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-unternehmen-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD')
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Umsatz: item.scoreUmsatz,
                    Kundenanzahl: item.scoreKundenanzahl,
                    Kundenbon: item.scoreKundenbon,
                    Leistung: item.scoreLeistung,
                }));

            const branchLineChartData = response.data.results.map(item => ({
                x: item.datum,
                y: item.umsatz,
                label: 'Filiale'
            }));

            const companyLineChartData = responseUnternehmen.data.results.map(item => ({
                x: item.datum,
                y: item.umsatz,
                label: 'Unternehmen'
            }));

            const combinedLineChartData = [...branchLineChartData, ...companyLineChartData];

            const comparisonCardData = response.data.results.slice(-1);

            console.log("comparisoncarddata", comparisonCardData);

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
            setLineChartData(combinedLineChartData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    return (
        <Layout>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{overflowX: 'auto'}}>
                        {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                            <Grid item xs={12} md={6} lg={3} key={index}>
                                <DataCard data={dataCardData} category={category} sector={"performance"}/>
                            </Grid>
                        ))}
                    </Stack>
                </Grid>
                <br/>
                <Grid item xs={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{alignItems: 'stretch'}}>
                        <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            <TitleCard
                                title={"Umsatz"}
                                subTitle={`${comparisonCardData.length > 0 ? comparisonCardData[0].umsatz : null} €`}
                            />
                            <ComparisonCard
                                zeitraum="Vorjahr"
                                data={comparisonCardData}
                                rangField="rangUmsatzWachstumJ"
                                percentageField="umsatzWachstumJ"
                                scoreField="scoreUmsatzWachstumJ"
                            />
                            <ComparisonCard
                                zeitraum="∅ 6. Monate"
                                data={comparisonCardData}
                                rangField="rangUmsatzWachstum6M"
                                percentageField="umsatzWachstum6M"
                                scoreField="scoreUmsatzWachstum6M"
                            />
                            <ComparisonCard
                                zeitraum="Vormonat"
                                data={comparisonCardData}
                                rangField="rangmUmsatzWachstumMonat"
                                percentageField="umsatzWachstumM"
                                scoreField="scoreUmsatzWachstumM"
                            />
                        </Grid>
                        <BigChartCard
                            title={"Umsatzentwicklung Unternehmen und Filiale"}
                            data={lineChartData}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <AlertField
                data={comparisonCardData}
                feedbackField={"feedbackUmsatz"}
                scoreField={"scoreUmsatz"}
            />
            <ScorecardsNotes category="Performance" />
        </Layout>
    );
};

export default PerformanceUmsatz;
