import React, {useEffect, useState} from 'react';
import {AppBar, Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Toolbar, Typography} from '@mui/material';
import Instance from '../../../services/axiosInstance';
import KategorieListeKomponente from '../../../components/formular/Filialcheck/KategorieListeKomponente';
import NeueKategorieForm from '../../../components/formular/Filialcheck/NeueKategorieForm';
import CheckpointListeKomponente from '../../../components/formular/Filialcheck/CheckpointListeKomponente';
import NeueCheckpointForm from '../../../components/formular/Filialcheck/NeueCheckpointForm';
import FilialeMonatLayout from "../../../components/layout/Scorecards/FilialeMonatLayout";

const PerformanceLeistung = () => {
    const [value, setValue] = useState(0);
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [filialnummer, setFilialnummer] = useState('');
    const [filialnummern, setFilialnummern] = useState([]); // Hinzugefügt
    const [user, setUser] = useState('');
    const [error, setError] = useState(null);
    const [dashboardData, setDashboardData] = useState({categoryData: [], userCheckCounts: []});
    const [highestCheckId, setHighestCheckId] = useState(null);
    const [newCheckId, setNewCheckId] = useState(null);

    useEffect(() => {
        // Fetch categories
        Instance.get('/filialcheck/categories/')
            .then(response => {
                if (response.data && Array.isArray(response.data.results)) {
                    setCategories(response.data.results);
                } else {
                    throw new Error('Die API hat keine Array-Daten im "results"-Feld zurückgegeben.');
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden der Daten');
            });

        // Fetch current user
        Instance.get('auth/current_user/')
            .then(response => {
                if (response.data && response.data.username) {
                    setUser(response.data.username);
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden des Benutzers');
            });

        // Fetch filialnummern
        Instance.get('/filialcheck/filialnummern/')
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setFilialnummern(response.data);
                } else {
                    throw new Error('Die API hat keine Array-Daten zurückgegeben.');
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden der Filialnummern');
            });
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 3) {
            loadDashboardData();
        }
    };

    const getNextCheckId = async () => {
        try {
            const response = await Instance.get('/filialcheck/highest-checkid/');
            console.log(response); // Ausgabe des gesamten Antwortobjekts zur Überprüfung
            const highestCheckId = response.data.highest_checkId ?? 0; // Verwenden Sie `highest_checkId` genau wie in der Antwort
            setHighestCheckId(highestCheckId);
            const nextCheckId = highestCheckId + 1;
            setNewCheckId(nextCheckId);
            return nextCheckId;
        } catch (error) {
            console.error("Error fetching highest checkId:", error);
            setHighestCheckId(0);
            setNewCheckId(1);
            return 1; // Default to 1 if there's an error
        }
    };

    useEffect(() => {
        if (value === 2) {
            getNextCheckId();
        }
    }, [value]);

    const addCategory = (name) => {
        Instance.post('/filialcheck/categories/', {name})
            .then(response => {
                setCategories([...categories, response.data]);
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Hinzufügen der Kategorie');
            });
    };

    const handleCategoryNameChange = (categoryId, newName) => {
        Instance.patch(`/filialcheck/categories/${categoryId}/`, {name: newName})
            .then(response => {
                setCategories(categories.map(category =>
                    category.id === categoryId ? {...category, name: newName} : category
                ));
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Ändern des Kategorienamens');
            });
    };

    const handleCategoryDelete = (categoryId) => {
        Instance.delete(`/filialcheck/categories/${categoryId}/`)
            .then(() => {
                setCategories(categories.filter(category => category.id !== categoryId));
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Löschen der Kategorie');
            });
    };

    const addCheckpoint = (name, category) => {
        Instance.post('/filialcheck/checkpoints/', {name, category})
            .then(response => {
                setCategories(categories.map(cat =>
                    cat.id === category ? {...cat, checkpoints: [...cat.checkpoints, response.data]} : cat
                ));
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Hinzufügen des Checkpoints');
            });
    };

    const handleRatingChange = (checkpointId, value) => {
        setRatings({
            ...ratings,
            [checkpointId]: value,
        });
    };

    const handleSubmit = async () => {
        const currentDate = new Date().toISOString().split('T')[0]; // aktuelles Datum im YYYY-MM-DD Format
        const checkId = await getNextCheckId(); // Ermitteln der nächsten checkId

        const ratingPromises = Object.entries(ratings).map(([checkpointId, value]) => {
            return Instance.post('/filialcheck/ratings/', {
                checkpoint: checkpointId,
                value: value,
                user: user,
                filialnummer: filialnummer,
                datum: currentDate,
                checkId: checkId, // Hinzufügen der checkId
            });
        });

        try {
            await Promise.all(ratingPromises);
            console.log('Bewertungen erfolgreich gesendet');
        } catch (error) {
            setError(error.message || 'Fehler beim Senden der Bewertungen');
            console.error("Error details:", error);
        }
    };

    const loadDashboardData = () => {
        Instance.get('/filialcheck/dashboard/')
            .then(response => {
                setDashboardData(response.data);
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden der Dashboard-Daten');
            });
    };

    return (
        <FilialeMonatLayout>
            <Container maxWidth="sm">
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Performance Leistung
                        </Typography>
                    </Toolbar>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="Kategorien"/>
                        <Tab label="Überprüfungspunkte"/>
                        <Tab label="Bewertungen"/>
                        <Tab label="Dashboard"/>
                    </Tabs>
                </AppBar>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4}}>
                    {error && (
                        <Typography variant="body1" color="error">
                            {error}
                        </Typography>
                    )}
                    {value === 0 && (
                        <>
                            <Typography variant="h3" component="div" gutterBottom>
                                Kategorien
                            </Typography>
                            <KategorieListeKomponente
                                categories={categories}
                                onCategoryNameChange={handleCategoryNameChange}
                                onCategoryDelete={handleCategoryDelete}
                            />
                            <NeueKategorieForm onAddCategory={addCategory}/>
                        </>
                    )}
                    {value === 1 && (
                        <>
                            <Typography variant="h3" component="div" gutterBottom>
                                Überprüfungspunkte
                            </Typography>
                            <NeueCheckpointForm categories={categories} onAddCheckpoint={addCheckpoint}/>
                        </>
                    )}
                    {value === 2 && (
                        <>
                            <Typography variant="h3" component="div" gutterBottom>
                                Bewertungen
                            </Typography>
                            <TextField
                                label="User"
                                value={user}
                                onChange={(e) => setUser(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Filialnummer</InputLabel>
                                <Select
                                    value={filialnummer}
                                    onChange={(e) => setFilialnummer(e.target.value)}
                                >
                                    {filialnummern.map((filiale, index) => (
                                        <MenuItem key={index} value={filiale.filialnummer}>
                                            {filiale.filialnummer}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box sx={{mt: 2, mb: 2}}>
                                <Typography variant="h6">Höchste Check-ID: {highestCheckId}</Typography>
                                <Typography variant="h6">Neue Check-ID: {newCheckId}</Typography>
                            </Box>
                            {categories.map(category => (
                                <div key={category.id}>
                                    <h2>{category.name}</h2>
                                    <CheckpointListeKomponente
                                        checkpoints={category.checkpoints}
                                        onRatingChange={handleRatingChange}
                                        ratings={ratings}
                                    />
                                </div>
                            ))}
                            <Button onClick={handleSubmit} variant="contained" sx={{mt: 2}}>
                                Bewertungen absenden
                            </Button>
                        </>
                    )}
                    {value === 3 && (
                        <>
                            <Typography variant="h3" component="div" gutterBottom>
                                Dashboard
                            </Typography>
                            <Box sx={{mt: 4, width: '100%'}}>
                                {dashboardData.categoryData && dashboardData.categoryData.length > 0 ? (
                                    dashboardData.categoryData.map((data, index) => (
                                        <Box key={index} sx={{mb: 2}}>
                                            <Typography variant="h6">Filialnummer: {data.filialnummer}</Typography>
                                            <Typography variant="body1">Kategorie: {data['checkpoint__category__name']}</Typography>
                                            <Typography variant="body1">Anzahl der Check-IDs: {data.checkCount}</Typography>
                                            <Typography variant="body1">Durchschnittlicher Wert: {data.avgValue.toFixed(2)}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body1">Keine Daten verfügbar</Typography>
                                )}
                                <Typography variant="h4" component="div" gutterBottom>
                                    User Check Counts
                                </Typography>
                                {dashboardData.userCheckCounts && dashboardData.userCheckCounts.length > 0 ? (
                                    dashboardData.userCheckCounts.map((data, index) => (
                                        <Box key={index} sx={{mb: 2}}>
                                            <Typography variant="body1">User: {data.user}</Typography>
                                            <Typography variant="body1">Anzahl der Checks: {data.userCheckCount}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body1">Keine Daten verfügbar</Typography>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </FilialeMonatLayout>
    );
};

export default PerformanceLeistung;
