import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Container, Card, CardContent, Grid, CardHeader, LinearProgress } from '@mui/material';
import Layout from '../../../components/layout/Documentation/Documentation';
import axiosInstance from '../../../services/axiosInstance';
import VariableTable from '../../../components/table/VariableTable';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from "../../../provider/FilterProvider";
import FilialeSummary from '../../../components/documentation/FilialeSummary';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const Overview = () => {
  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [data, setData] = useState([]);
  const [datumOptions, setDatumOptions] = useState([]);
  const [checksCount, setChecksCount] = useState(0);
  const [verkaufsgebietCounts, setVerkaufsgebietCounts] = useState([]);
  const [filialen, setFilialen] = useState([]);
  const [selectedFiliale, setSelectedFiliale] = useState(null);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  useEffect(() => {
    const fetchDatumOptions = async () => {
      try {
        const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
        const options = response.data.map(item => ({ label: item, value: item }));
        setDatumOptions(options);

        if (!filterState.datum) {
          const lastMonth = dayjs().subtract(1, 'month').format('YYYY.MM');
          const defaultOption = options.find(option => option.value === lastMonth);
          if (defaultOption) {
            handleDatumChange(defaultOption);
          }
        }
      } catch (error) {
        console.error('Error while fetching datum options', error);
      }
    };

    fetchDatumOptions();
  }, [handleDatumChange, filterState.datum]);

  useEffect(() => {
    const fetchFilialen = async () => {
      try {
        const response = await axiosInstance.get('/stammdaten/stammdatenfiliale/');
        setFilialen(response.data.results || []);
      } catch (error) {
        console.error('Fehler beim Abrufen der Filialdaten:', error);
      }
    };

    fetchFilialen();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!filterState.datum) return;
  
      try {
        const response = await axiosInstance.get('/scorecard_documentation/documentation/', {
          params: { jahrMonat: filterState.datum.value },
        });
        const results = response.data.results;
  
        const enrichedResults = results.map(result => {
          const filialeData = filialen.find(f => f.filialnummer === result.filialnummer);
          return {
            ...result,
            filiale: filialeData ? filialeData.filiale : 'Unbekannt',
            verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
            datum: dayjs(result.datum).format('DD.MM.YYYY'), // Datum direkt hier formatiert
          };
        });
  
        setData(enrichedResults);
        setChecksCount(results.length);
  
        const counts = {};
        enrichedResults.forEach(result => {
          const verkaufsgebiet = result.verkaufsgebiet || 'Unbekannt';
          counts[verkaufsgebiet] = (counts[verkaufsgebiet] || 0) + 1;
        });
  
        const verkaufsgebietCountsArray = Object.keys(counts).map(key => ({
          verkaufsgebiet: key,
          count: counts[key],
        }));
  
        setVerkaufsgebietCounts(verkaufsgebietCountsArray);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };
  
    fetchData();
  }, [filterState.datum, filialen]);
  

  const columns = [
    { accessorKey: 'filiale', header: 'Filiale' },
    { accessorKey: 'datum', header: 'Datum', Cell: ({ cell }) => dayjs(cell.getValue()).format('YYYY-MM-DD') },
    { accessorKey: 'user', header: 'Führungskraft' },
  ];

  const handleRowClick = (row) => {
    if (row && row.filialnummer) {
      setSelectedFiliale(row.filialnummer);
      setIsSummaryOpen(true);
    } else {
      console.error("Filialnummer is not defined in the row data:", row);
    }
  };

  const handleCloseSummary = () => {
    setIsSummaryOpen(false);
    setSelectedFiliale(null);
  };

  const maxChecks = Math.max(...verkaufsgebietCounts.map(item => item.count), 0);

  return (
    <Layout>
      <Container>
        <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <PageHeader
              title="Gesprächspilot"
              subtitle="
Verschaffe dir einen klaren Überblick über die dokumentierten Ergebnisse und Analyseergebnisse deiner Führungsarbeit in den Scorecards."
              Icon={SummarizeOutlinedIcon}
            />

            {/* Dropdown-Filter auf der gleichen Linie */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <DropdownFilter
                label="Datum"
                options={datumOptions}
                value={filterState.datum}
                onChange={handleDatumChange}
              />
            </Box>
          </Box>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6" component="div">
                      Anzahl der Dokumentationen nach Verkaufsgebiet im {filterState.datum?.label}
                    </Typography>
                  }
                />
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      Gesamt
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {checksCount}
                    </Typography>
                  </Box>

                  {verkaufsgebietCounts.map((item, index) => (
                    <Box key={index} sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body1">
                          <strong>{item.verkaufsgebiet}: {item.count} </strong>
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={(item.count / maxChecks) * 100}
                          sx={{ height: 10, borderRadius: 5, mt: 1 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3 }}>
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Übersicht
                </Typography>
              }
            />
            <CardContent>
              <VariableTable columns={columns} data={data} onRowClick={handleRowClick} />
            </CardContent>
          </Card>
        </Box>
      </Container>

      <FilialeSummary
        open={isSummaryOpen}
        onClose={handleCloseSummary}
        filiale={selectedFiliale}
        datum={filterState.datum?.value}
      />
    </Layout>
  );
};

export default Overview;
