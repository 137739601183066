import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Slider
} from '@mui/material';

import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Abfragen/AbfragenLayout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import ChecklistIcon from '@mui/icons-material/Checklist';  // Import des Icons



const Overview = () => {
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [answerState, setAnswerState] = useState({});
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [userSubmissions, setUserSubmissions] = useState([]);

  useEffect(() => {
    const loadAvailablePolls = async () => {
      try {
        const response = await axiosInstance.get('/abfragen/polls/');
        // Nur aktive Umfragen anzeigen:
        let data = response.data.results || response.data;
        data = data.filter((poll) => poll.is_manually_active);
        setPolls(data);
      } catch (error) {
        console.error('Fehler beim Laden der Umfragen:', error);
      } finally {
        setLoading(false);
      }
    };
    loadAvailablePolls();

    // Zusätzlich alle Submissions des aktuellen Nutzers laden, um den Status anzuzeigen
    axiosInstance.get('/abfragen/submissions/')
      .then(resp => {
        setUserSubmissions(resp.data.results || resp.data);
      })
      .catch(err => console.error("Fehler beim Laden der Submissions:", err));
  }, []);

  const handleSelectPoll = async (poll) => {
    setSelectedPoll(poll);
    try {
      const submissionResponse = await axiosInstance.get('/abfragen/submissions/', {
        params: { poll: poll.id }
      });
      const allSubs = submissionResponse.data.results || [];
      const existingSubmission = allSubs.find(s => s.poll === poll.id);

      if (existingSubmission) {
        setSubmission(existingSubmission);

        // Antworten aus existingSubmission laden
        const loadedAnswers = {};
        if (existingSubmission.answers) {
          existingSubmission.answers.forEach((ans) => {
            if (ans.text_answer !== null) {
              loadedAnswers[ans.question] = ans.text_answer;
            } else if (ans.selected_option !== null) {
              loadedAnswers[ans.question] = ans.selected_option;
            } else if (ans.rating_answer !== null) {
              loadedAnswers[ans.question] = ans.rating_answer;
            } else if (ans.date_answer !== null) {
              loadedAnswers[ans.question] = dayjs(ans.date_answer);
            }
          });
        }
        setAnswerState(loadedAnswers);
      } else {
        setSubmission(null);
        // Noch nicht beantwortet: Antworten initialisieren
        const initialAnswers = {};
        if (poll && poll.questions) {
          poll.questions.forEach((q) => {
            if (q.question_type === "text") {
              initialAnswers[q.id] = '';
            } else if (q.question_type === "single_choice") {
              initialAnswers[q.id] = null;
            } else if (q.question_type === "rating") {
              initialAnswers[q.id] = q.min_rating || 1;
            } else if (q.question_type === "date") {
              initialAnswers[q.id] = null;
            }
          });
        }
        setAnswerState(initialAnswers);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Submission:", error);
      setSubmission(null);
    }
  };

  const handleAnswerChange = (question, value) => {
    // Nur erlauben, wenn noch keine Submission existiert
    if (submission) return;
    setAnswerState(prev => ({ ...prev, [question.id]: value }));
  };

  const handleSubmitAnswers = async () => {
    if (!selectedPoll || submission) return; // Bereits beantwortet => kein Absenden

    const submissionData = {
      poll: selectedPoll.id,
      answers: []
    };

    selectedPoll.questions.forEach(q => {
      let answerObj = {
        question: q.id,
        text_answer: null,
        selected_option: null,
        rating_answer: null,
        date_answer: null
      };

      if (q.question_type === 'text') {
        answerObj.text_answer = answerState[q.id] || '';
      } else if (q.question_type === 'single_choice') {
        answerObj.selected_option = answerState[q.id];
      } else if (q.question_type === 'rating') {
        answerObj.rating_answer = answerState[q.id];
      } else if (q.question_type === 'date') {
        const dateVal = answerState[q.id];
        answerObj.date_answer = dateVal ? dateVal.format('YYYY-MM-DD') : null;
      }

      submissionData.answers.push(answerObj);
    });

    console.log('Bevor POST-Request:', submissionData);

    try {
      const response = await axiosInstance.post('/abfragen/answers/bulk_create_answers/', submissionData);
      console.log('Nach erfolgreichem POST-Request:', response.data);
      alert('Antworten erfolgreich übermittelt!');

      // Neue Submission lokal simulieren
      const newSubmission = {
        poll: selectedPoll.id,
        user: 'current_user', // Platzhalter
        submitted_at: new Date().toISOString(),
        answers: submissionData.answers
      };
      setSubmission(newSubmission);
      setUserSubmissions(prev => [...prev, newSubmission]);

      setSubmitDialogOpen(false);

    } catch (error) {
      console.error('Fehler beim Absenden der Antworten:', error.response ? error.response.data : error);
      alert('Fehler beim Absenden. Siehe Konsole für Details.');
    }
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Layout>

<Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >     
<PageHeader 
                    title="Abfragen" 
                    subtitle="
Hier findest du aktuelle Umfragen, die du beantworten kannst. Klicke auf eine Umfrage, um sie zu beantworten."
                    Icon={ChecklistIcon}  // Übergabe des Icons
                />
      {/* Übersicht der Umfragen oder Detailansicht */}
      {!selectedPoll ? (
        <Box>
          
          {polls.length === 0 && (
            <Typography>Keine (aktiven) Umfragen verfügbar.</Typography>
          )}

          {/* Container für die "Karten" (Cards) der Umfragen */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2, // Abstand zwischen den Karten
            }}
          >
            {polls.map((poll) => {
              const sub = userSubmissions.find(s => s.poll === poll.id);
              let desc = "nicht beantwortet";
              if (sub) {
                desc = `wurde am ${new Date(sub.submitted_at).toLocaleDateString()} beantwortet`;
              }

              return (
                <Box
                  key={poll.id}
                  onClick={() => handleSelectPoll(poll)}
                  sx={{
                    width: 220,
                    minHeight: 120,
                    borderRadius: 2,
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 2,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: '#f5f5f5'
                    }
                  }}
                >
                  <Typography 
                    variant="subtitle1" 
                    sx={{ fontWeight: 'bold', mb: 0.5 }}
                  >
                    {poll.title}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'gray' }}>
                    {desc}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {selectedPoll.title}
          </Typography>

          {/* Detailansicht der Fragen */}
          {selectedPoll.questions.map((q, i) => {
            const currentValue = answerState[q.id];
            return (
              <Box
                key={q.id}
                sx={{
                  border: '1px solid #ccc',
                  p: 2,
                  mb: 2,
                  borderRadius: '4px'
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Frage {i + 1}: {q.question_text}
                </Typography>

                {q.question_type === "text" && (
                  <TextField
                    fullWidth
                    placeholder="Ihre Antwort"
                    variant="outlined"
                    margin="normal"
                    value={currentValue || ''}
                    onChange={(e) => handleAnswerChange(q, e.target.value)}
                    InputProps={{ readOnly: !!submission }}
                  />
                )}

                {q.question_type === "single_choice" && (
                  <RadioGroup
                    value={currentValue || ''}
                    onChange={(e) =>
                      handleAnswerChange(q, parseInt(e.target.value, 10))
                    }
                  >
                    {q.options.map(opt => (
                      <FormControlLabel
                        key={opt.id}
                        value={opt.id}
                        control={<Radio disabled={!!submission} />}
                        label={opt.option_text}
                      />
                    ))}
                  </RadioGroup>
                )}

                {q.question_type === "rating" && (
                  <Box sx={{ mt: 2 }}>
                    <Slider
                      min={q.min_rating || 1}
                      max={q.max_rating || 10}
                      value={currentValue || (q.min_rating || 1)}
                      onChange={(event, newValue) => handleAnswerChange(q, newValue)}
                      marks={[
                        {
                          value: q.min_rating || 1,
                          label: `${q.min_rating || 1}`,
                        },
                        {
                          value: q.max_rating || 10,
                          label: `${q.max_rating || 10}`,
                        },
                      ]}
                      step={1}
                      valueLabelDisplay="auto"
                      disabled={!!submission}
                    />
                  </Box>
                )}

                {q.question_type === "date" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Bitte Datum wählen"
                      value={currentValue || null}
                      onChange={(newValue) => handleAnswerChange(q, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          fullWidth
                          disabled={!!submission}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              </Box>
            );
          })}

          <Box sx={{ mt: 2 }}>
            {!submission && (
              <Button variant="contained" onClick={() => setSubmitDialogOpen(true)}>
                Absenden
              </Button>
            )}
            {submission && (
              <Typography>
                Sie haben diese Umfrage bereits beantwortet und können Ihre Antwort nur ansehen.
              </Typography>
            )}
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => setSelectedPoll(null)}
            >
              Zurück zur Übersicht
            </Button>
          </Box>
        </Box>
      )}

      {/* Dialog zum Bestätigen der Antwort-Absendung */}
      <Dialog
        open={submitDialogOpen}
        onClose={() => setSubmitDialogOpen(false)}
      >
        <DialogTitle>Antworten absenden</DialogTitle>
        <DialogContent>
          <Typography>Möchten Sie Ihre Antworten wirklich abschicken?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSubmitDialogOpen(false)}>Abbrechen</Button>
          <Button variant="contained" onClick={handleSubmitAnswers}>
            Ja, absenden
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Layout>

  );
};

export default Overview;
