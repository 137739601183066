import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  Card,
  CardContent,
  LinearProgress
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // NEU: Icon importieren
import axiosInstance from '../../../services/axiosInstance'; 
import Layout from '../../../components/layout/Onboarding/Onboarding'; 
import PageHeader from '../../../components/layout/Title/TitelSmall'; 
import ChecklistIcon from '@mui/icons-material/Checklist';
import { FilterContext } from '../../../provider/FilterProvider';
import {useAuth} from "../../../provider/authProvider";

const Mitarbeiter = () => {
  const [employees, setEmployees] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [steps, setSteps] = useState({});
  const [topicProgress, setTopicProgress] = useState({});
  const [stepProgress, setStepProgress] = useState({});
  const [planSteps, setPlanSteps] = useState([]);
  const [planTopics, setPlanTopics] = useState([]);
  const [loading, setLoading] = useState(false);

  const {user} = useAuth(); 
  const userGroupIds = user?.custom_groups?.map(g => g.id) || [];
  const { filterState, handleEmployeeChange } = useContext(FilterContext);
  const selectedEmployee = filterState.selectedEmployee;

  const selectedEmployeeData = employees.find(
    (employee) => employee.mitarbeiterId === selectedEmployee
  );

  useEffect(() => {
    if (!selectedEmployee) return;

    setLoading(true);

    const employeePromise = axiosInstance.get('personaldaten/stammdaten-mitarbeiter/');
    const assignmentsPromise = axiosInstance.get('/onboarding/employee-assignments/');
    const plansPromise = axiosInstance.get('/onboarding/onboarding-plans/');
    const categoriesPromise = axiosInstance.get('/onboarding/categories/');
    const topicsPromise = axiosInstance.get('/onboarding/topics/');
    const stepsPromise = axiosInstance.get('/onboarding/onboarding-steps/');
    const topicProgPromise = axiosInstance.get(`/onboarding/topic-progress/`, {
      params: { employee: selectedEmployee }
    });
    const stepProgPromise = axiosInstance.get(`/onboarding/step-progress/`, {
      params: { employee: selectedEmployee }
    });

    Promise.all([
      employeePromise,
      assignmentsPromise,
      plansPromise,
      categoriesPromise,
      topicsPromise,
      stepsPromise,
      topicProgPromise,
      stepProgPromise
    ])
    .then(([empRes, assRes, planRes, catRes, topRes, stepRes, tProgRes, sProgRes]) => {
      setEmployees(empRes.data.results);
      setAssignments(assRes.data.results);
      setOnboardingPlans(planRes.data.results);

      const assignment = assRes.data.results.find((a) => a.employee === selectedEmployee);
    if (assignment && assignment.onboarding_plan) {
      const planId = assignment.onboarding_plan;
        const filteredCategories = catRes.data.results.filter(
            (category) => category.onboarding_plan === planId
          );
          setCategories(filteredCategories);

        const allTopics = topRes.data.results;
              const filteredTopics = allTopics.filter((topic) =>
                filteredCategories.some((category) => category.id === topic.category)
              );
              setTopics(filteredTopics);
        setPlanTopics(filteredTopics);

        const allSteps = stepRes.data.results;
                  const filteredSteps = allSteps.filter((step) =>
                    filteredTopics.some((topic) => topic.id === step.topic)
                  );
                  const stepsByTopic = filteredSteps.reduce((acc, step) => {
                    if (!acc[step.topic]) {
                      acc[step.topic] = [];
                    }
                    acc[step.topic].push(step);
                    return acc;
                  }, {});
                  setSteps(stepsByTopic);
        setPlanSteps(filteredSteps);
      }

      const tData = tProgRes.data.results.reduce((acc, item) => {
            acc[item.topic_id] = {
              employee_status: item.employee_status,
              supervisor_status: item.supervisor_status,
              id: item.id,
          employee_updated_at: item.employee_updated_at,
          supervisor_updated_at: item.supervisor_updated_at,
          employee_updated_by: item.employee_updated_by,       
          supervisor_updated_by: item.supervisor_updated_by    
            };
            return acc;
          }, {});
      setTopicProgress(tData);

      const sData = sProgRes.data.results.reduce((acc, item) => {
            acc[item.onboarding_step_id] = {
              status: item.status,
              id: item.id,
          updated_at: item.updated_at,
          updated_by: item.updated_by  
            };
            return acc;
          }, {});
      setStepProgress(sData);
        })
        .catch((error) => {
      console.error('Fehler beim Laden der Daten:', error);
    })
    .finally(() => {
      setLoading(false);
        });
  }, [selectedEmployee]);

  const calculateProgress = () => {
    const totalTopics = planTopics.length;
    const completedTopics = planTopics.filter(
      (topic) => topicProgress[topic.id]?.employee_status === true
    ).length;
    const topicCompletionPercentage =
      totalTopics > 0 ? Math.round((completedTopics / totalTopics) * 100) : 0;

    const completedSupervisorTopics = planTopics.filter(
      (topic) => topicProgress[topic.id]?.supervisor_status === true
    ).length;
    const supervisorTopicCompletionPercentage =
      totalTopics > 0 ? Math.round((completedSupervisorTopics / totalTopics) * 100) : 0;

    const totalSteps = planSteps.length;
    const completedSteps = planSteps.filter(
      (step) => stepProgress[step.id]?.status === true
    ).length;
    const stepCompletionPercentage =
      totalSteps > 0 ? Math.round((completedSteps / totalSteps) * 100) : 0;

    return {
      topicCompletionPercentage,
      supervisorTopicCompletionPercentage,
      stepCompletionPercentage,
    };
  };

  const {
    topicCompletionPercentage,
    supervisorTopicCompletionPercentage,
    stepCompletionPercentage,
  } = calculateProgress();

  const handleStepChange = (stepId) => {
    const progress = stepProgress[stepId];
    const newStatus = !progress?.status;
    const progressId = progress?.id;

    if (progressId) {
      axiosInstance
        .patch(`/onboarding/step-progress/${progressId}/`, {
          status: newStatus,
        })
        .then((response) => {
          const updatedData = response.data; 
          setStepProgress((prevState) => ({
            ...prevState,
            [stepId]: {
              ...prevState[stepId],
              status: updatedData.status,
              updated_at: updatedData.updated_at,
              updated_by: updatedData.updated_by
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren des Schrittfortschritts:', error);
        });
    }
  };

  const handleTopicChange = (topicId, field) => {
    const progress = topicProgress[topicId];
    const newStatus = !progress?.[field];
    const progressId = progress?.id;

    if (progressId) {
      axiosInstance
        .patch(`/onboarding/topic-progress/${progressId}/`, {
          [field]: newStatus,
        })
        .then((response) => {
          const updatedData = response.data; 
          let updatedFields = {
            [field]: updatedData[field]
          };
          if (field === 'employee_status') {
            updatedFields.employee_updated_at = updatedData.employee_updated_at;
            updatedFields.employee_updated_by = updatedData.employee_updated_by;
          } else {
            updatedFields.supervisor_updated_at = updatedData.supervisor_updated_at;
            updatedFields.supervisor_updated_by = updatedData.supervisor_updated_by;
          }
          setTopicProgress((prevState) => ({
            ...prevState,
            [topicId]: {
              ...prevState[topicId],
              ...updatedFields
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren des Themenfortschritts:', error);
        });
    }
  };

  let canEditSupervisor = false;
  const assignment = assignments.find((a) => a.employee === selectedEmployee);

  if (assignment && assignment.custom_group) {
    const groupId = assignment.custom_group;
    canEditSupervisor = userGroupIds.includes(groupId);
  }

  if (!selectedEmployee || loading) {
  return (
    <Layout>
        <Box sx={{ width: '100%', mx: 'auto', mt: 4, px: 2 }}>
        <PageHeader
          title="Onboarding Konfiguration"
          subtitle="Verwalte deine Onboarding-Themen und -Schritte."
            Icon={ChecklistIcon}
          />
          {loading && <CircularProgress />}
          {!selectedEmployee && (
            <Select
              value={selectedEmployee || ''}
              onChange={(e) => handleEmployeeChange(e.target.value)}
              displayEmpty
              sx={{ mb: 4, width: '100%' }}
            >
              <MenuItem value="" disabled>
                Mitarbeiter auswählen
              </MenuItem>
              {employees.map((employee) => (
                <MenuItem key={employee.mitarbeiterId} value={employee.mitarbeiterId}>
                  {employee.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box sx={{ width: '100%', mx: 'auto', mt: 4, px: 2 }}>
      
        {selectedEmployeeData && (
          <>
            <Typography variant="h5" gutterBottom>
            Onboarding: {selectedEmployeeData.name}
            </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Themenfortschritt (Mitarbeiter)
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${topicCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress variant="determinate" value={topicCompletionPercentage} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Schrittfortschritt
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${stepCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress variant="determinate" value={stepCompletionPercentage} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Themenfortschritt (Vorgesetzter)
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${supervisorTopicCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={supervisorTopicCompletionPercentage}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}

        <Paper sx={{ mt: 4, p: 4,  width: '100%', overflowX: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Onboarding-Plan:{' '}
              {onboardingPlans.find(
                (plan) =>
                  plan.id ===
                  assignments.find((a) => a.employee === selectedEmployee)?.onboarding_plan
              )?.name || 'Kein Plan zugewiesen'}
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kategorie/Topic/Step</TableCell>
                <TableCell></TableCell>
                  <TableCell>Mitarbeiter</TableCell>
                <TableCell> aktualisiert am</TableCell>
                <TableCell> bearbeitet von</TableCell>
                  <TableCell>Vorgesetzter</TableCell>
                <TableCell> aktualisiert am</TableCell>
                <TableCell> bearbeitet von</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <React.Fragment key={category.id}>
                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                    <TableCell colSpan={8}>
                      <Typography variant="h6" sx={{ color: '#black' }}>
                          {category.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {topics
                      .filter((topic) => topic.category === category.id)
                    .map((topic) => {
                      const tProg = topicProgress[topic.id] || {};
                      return (
                        <React.Fragment key={topic.id}>
                          <TableRow sx={{ backgroundColor: '#f9f9f9' }}>
                            <TableCell>{topic.name}</TableCell>
                            <TableCell>
                              {topic.wiki_link ? (
                                <a href={topic.wiki_link} target="_blank" rel="noopener noreferrer">
                                  <InfoOutlinedIcon sx={{ color: 'gray', cursor: 'pointer' }} />
                                </a>
                              ) : (
                                '—'
                              )}
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                checked={tProg.employee_status || false}
                                onChange={() => handleTopicChange(topic.id, 'employee_status')}
                              />
                            </TableCell>
                            <TableCell>
                              {tProg.employee_updated_at ? new Date(tProg.employee_updated_at).toLocaleString() : '—'}
                            </TableCell>
                            <TableCell>
                              {tProg.employee_updated_by || '—'}
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                checked={tProg.supervisor_status || false}
                                onChange={() => handleTopicChange(topic.id, 'supervisor_status')}
                                disabled={!canEditSupervisor}
                              />
                            </TableCell>
                            <TableCell>
                              {tProg.supervisor_updated_at ? new Date(tProg.supervisor_updated_at).toLocaleString() : '—'}
                            </TableCell>
                            <TableCell>
                              {tProg.supervisor_updated_by || '—'}
                            </TableCell>
                          </TableRow>

                          {steps[topic.id]?.map((step) => {
                            const sProg = stepProgress[step.id] || {};
                            return (
                            <TableRow key={step.id}>
                              <TableCell sx={{ pl: 4 }}>{step.description}</TableCell>
                                <TableCell />
                              <TableCell>
                                <Checkbox
                                    checked={sProg.status || false}
                                  onChange={() => handleStepChange(step.id)}
                                />
                              </TableCell>
                                <TableCell>
                                  {sProg.updated_at ? new Date(sProg.updated_at).toLocaleString() : '—'}
                                </TableCell>
                                <TableCell>
                                  {sProg.updated_by || '—'}
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                            )
                          })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
      </Box>
    </Layout>
  );
};

export default Mitarbeiter;
