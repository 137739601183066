import React, { useState, useMemo } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

// Wichtig: Hier deinen Pfad anpassen
// import RechartsLineChart from "../../charts/LineChartVJ";
import RechartsLineChartFixedHeight from "../../charts/RechartsLineChartFixedHeight";

/**
 * Hilfsfunktion, um die Chart-Daten in eine Pivot-Tabellenstruktur zu bringen.
 *
 * @param {Array} data - Array von Datenpunkten, z.B.
 *   [
 *     { x: '2020.01', label: 'Vorjahr', y: 10 },
 *     { x: '2020.01', label: 'Aktuell', y: 15 },
 *     ...
 *   ]
 *
 * @returns {Object} - Objekt mit:
 *   {
 *     xValues:  [ '2020.01', '2020.02', ... ],
 *     labels:   [ 'Vorjahr', 'Aktuell', ... ],
 *     tableData: [
 *       { label: 'Vorjahr', '2020.01': 10, '2020.02': 12, ... },
 *       { label: 'Aktuell', '2020.01': 15, '2020.02': 18, ... },
 *       ...
 *     ]
 *   }
 */
function pivotChartData(data = []) {
  const xValuesSet = new Set();
  const labelsSet = new Set();

  // Daten sichten, um alle x-Werte und label-Werte zu sammeln
  data.forEach((item) => {
    xValuesSet.add(item.x);
    labelsSet.add(item.label);
  });

  const xValues = Array.from(xValuesSet).sort(); // z.B. ["2020.01", "2020.02", ...]
  const labels = Array.from(labelsSet);

  // Leerer "Pivot"-Container: pro Label ein Objekt
  const tableData = labels.map((label) => {
    return {
      label,
      // Initiale Werte für jede Spalte auf null oder 0 setzen
      ...xValues.reduce((acc, x) => {
        acc[x] = null;
        return acc;
      }, {}),
    };
  });

  // Nun die Y-Werte in die Pivot-Struktur einfüllen
  data.forEach((item) => {
    // Finde den Eintrag mit passendem Label
    const rowObj = tableData.find((row) => row.label === item.label);
    if (rowObj) {
      rowObj[item.x] = item.y ?? 0;
    }
  });

  return { xValues, labels, tableData };
}

/**
 * Optionale Formatierungsfunktion für die Zahlen.
 */
function formatNumber(value) {
  if (value === null || value === undefined) return "-";
  return value.toLocaleString("de-DE", {
    maximumFractionDigits: 2,
  });
}

const BigChartCardWithTable = ({
  data = [],
  title,
  infoContent,
  height,
  width,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Pivot-Daten nur einmal berechnen (Memo)
  const { xValues, labels, tableData } = useMemo(() => {
    return pivotChartData(data);
  }, [data]);

  // Umschalten des Vollbildmodus
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  // Dynamische Höhe für verschiedene Bildschirmgrößen
  // Abzüglich CardHeader / CardContent-Padding etc.
  const chartHeight = height ? height - 100 : isSmallScreen ? 200 : 300;

  // Inhalt des Diagramms, um Duplizierung zu vermeiden
  const ChartContent = (
    <>
      {data && data.length > 0 ? (
        // Dein existierender Chart oder der mit fester Höhe
        <RechartsLineChartFixedHeight data={data} height={chartHeight} />
      ) : (
        <Typography variant="body2" color="text.secondary">
          Keine Daten verfügbar
        </Typography>
      )}
    </>
  );

  // Tabelle unter dem Chart
  const TableContent = (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}></TableCell>
            {xValues.map((xVal) => (
              <TableCell key={xVal} sx={{ fontWeight: "bold" }}>
                {xVal}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                {row.label}
              </TableCell>
              {xValues.map((xVal) => (
                <TableCell key={xVal}>
                  {formatNumber(row[xVal])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Paper>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            height: height || "100%",
            width: width || "100%",
            padding: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(10px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            }
            action={
              <>
                {infoContent && (
                  <Tooltip title={infoContent} arrow placement="top">
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton onClick={toggleFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </>
            }
          />
          <CardContent sx={{ flexGrow: 1, padding: isSmallScreen ? "8px" : "16px" }}>
            {/* Chart */}
            {ChartContent}

            {/* Tabelle */}
            {data && data.length > 0 && TableContent}
          </CardContent>
        </Card>
      </Paper>

      {/* Vollbild-Dialog */}
      <Dialog open={isFullscreen} onClose={toggleFullscreen} maxWidth={false} fullScreen>
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={toggleFullscreen}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Chart im Vollbild */}
          {ChartContent}

          {/* Tabelle */}
          {data && data.length > 0 && TableContent}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BigChartCardWithTable;
