import * as React from 'react';
import { Card, Typography, Stack, Box, CardHeader, CardActionArea, CardContent } from '@mui/material';
import Sparkline from '../../charts/Sparkline2';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";

const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };

    switch (category) {
        case 'Umsatz':
            return <EuroIcon {...iconProps} />;
        case 'Kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'Kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        case 'Leistung':
            return <TimerIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({ category, sector, value, subHeaders = [], numDataPoints = 7, sparklineData = [] }) {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleNavigate = () => {
        navigate(`/unternehmenwoche/${sector}/${category.toLowerCase()}`); // Pfad nach Bedarf anpassen
    };

    return (
        <Card sx={{
            padding: '2px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
        }}>
            <CardActionArea onClick={handleNavigate}>
                <CardHeader
                    className="db_datacard_cardheader"
                    title={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center">
                                {getCategoryIcon(category)}
                                <Typography variant="h2" className="db_datacard_category">
                                    {category}
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                />
                <CardContent className="db_datacard_cardcontent">
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center' 
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {value && (
                                <Typography className="db_datacard_categorydata" sx={{ textAlign: 'left', ml: 2 }}>
                                    {value}
                                </Typography>
                            )}

                            {subHeaders.map((subHeader, index) => (
                                <Typography
                                    key={index}
                                    variant="body2"
                                    className="db_datacard_subheader"
                                    sx={{
                                        fontSize: theme.typography.body2.fontSize,
                                        color: theme.palette.text.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: 2,
                                    }}
                                >
                                    <Box sx={{ mr: 1 }}>
                                        {subHeader.startsWith('-') ? (
                                            <Box sx={{ color: theme.palette.error.main }}>↓</Box>
                                        ) : (
                                            <Box sx={{ color: theme.palette.success.main }}>↑</Box>
                                        )}
                                    </Box>
                                    {subHeader}
                                </Typography>
                            ))}
                        </Box>

                        {/* Sparkline rechts daneben */}
                        <Box sx={{ ml: 2 }}>
                            <Sparkline data={sparklineData} />
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
