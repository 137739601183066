import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import Layout from '../../../components/layout/datentagebuch/datentagebuchLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';

import { parseISO, getISOWeek } from 'date-fns';

// Alte DataCards
import DataCard from '../../../components/card/TagebuchDataCard';

// Popup (Details)
import TagesreportDetailsPopup from '../../../components/popup/TagesreportDetailsPopup';

/** Hilfsfunktionen wie gehabt **/
function getLastCompletedMonth() {
  return dayjs().subtract(1, 'month').format('YYYY.MM');
}
function formatDateGerman(dateString) {
  return dayjs(dateString).format('DD.MM.YYYY');
}
function tausenderTrennung(value, decimals = 2) {
  if (isNaN(value)) return '-';
  return parseFloat(value)
    .toFixed(decimals)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
function calculateRetourquote(item) {
  const { retourwert = 0, lieferwert = 0, korrekturwert = 0 } = item;
  const total = lieferwert + korrekturwert;
  return total > 0 ? retourwert / total : 0;
}
function groupByWeek(items) {
  const groups = {};
  items.forEach((item) => {
    const isoWeek = getISOWeek(parseISO(item.datum));
    if (!groups[isoWeek]) groups[isoWeek] = [];
    groups[isoWeek].push(item);
  });
  return groups;
}
function calculateWeekTotals(weekItems) {
  let sumUmsatz = 0;
  let sumPlanumsatz = 0;
  let sumUmsatzVJ = 0;
  let sumProdStd = 0;
  let sumLieferwert = 0;
  let sumRetourwert = 0;
  let sumKorrekturwert = 0;

  weekItems.forEach((row) => {
    sumUmsatz += parseFloat(row.umsatz) || 0;
    sumPlanumsatz += parseFloat(row.planumsatz) || 0;
    sumUmsatzVJ += parseFloat(row.umsatzVJ) || 0;
    sumProdStd += parseFloat(row.produktivstunden) || 0;

    sumLieferwert += parseFloat(row.lieferwert) || 0;
    sumRetourwert += parseFloat(row.retourwert) || 0;
    sumKorrekturwert += parseFloat(row.korrekturwert) || 0;
  });
  const sumTotal = sumLieferwert + sumKorrekturwert;
  const sumRetourquote = sumTotal > 0 ? sumRetourwert / sumTotal : 0;

  return {
    sumUmsatz,
    sumPlanumsatz,
    sumUmsatzVJ,
    sumProdStd,
    sumRetourquote
  };
}
function isWeekend(dateStr) {
  const weekday = dayjs(dateStr).day();
  return weekday === 6 || weekday === 0;
}

// Emojis
const emojiOptions = ['😎', '😊', '😐', '😞', '😡'];

const Tagesbuch = () => {
  const { filterState, handleFilialeChange } = useContext(FilterContext);

  const [selectedMonth, setSelectedMonth] = useState(getLastCompletedMonth());
  const [filialeOptions, setFilialeOptions] = useState([]);

  // Tages-Daten (fact/kundenstatistik)
  const [rows, setRows] = useState([]);

  // Popup
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  /**
   * emojiSelections:
   *   key = z. B. "2024-12-01" (Datum)
   *   value = { id: 12, emoji: '😎' }
   * So wissen wir, ob wir per POST oder PATCH an /api/targets/emojis/ gehen müssen.
   */
  const [emojiSelections, setEmojiSelections] = useState({});

  // -- DEBUGGING: Zeige aktuellen Stand von emojiSelections:
  useEffect(() => {
    console.log('Aktuelle emojiSelections:', emojiSelections);
  }, [emojiSelections]);

  // 1) Filial-Liste laden
  useEffect(() => {
    loadFilialeOptions();
  }, []);

  const loadFilialeOptions = async () => {
    try {
      const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
      const opts = filialeResponse.data.map((item) => ({
        label: item.filiale,
        value: item.filialnummer,
      }));
      setFilialeOptions(opts);
    } catch (error) {
      console.error('Fehler beim Laden der Filialoptionen:', error);
      setFilialeOptions([]);
    }
  };

  // Monats-Pfeile
  const handlePrevMonth = () => {
    const newMonth = dayjs(selectedMonth, 'YYYY.MM').subtract(1, 'month');
    setSelectedMonth(newMonth.format('YYYY.MM'));
  };
  const handleNextMonth = () => {
    const newMonth = dayjs(selectedMonth, 'YYYY.MM').add(1, 'month');
    setSelectedMonth(newMonth.format('YYYY.MM'));
  };

  // 2) Daten laden, wenn Filiale/Monat wechselt
  useEffect(() => {
    if (!filterState.filiale?.value) {
      setRows([]);
      return;
    }
    // 1) Hole Tagesdaten (fact/kundenstatistik)
    fetchTagesDaten();
    // 2) Hole Emojis
    fetchEmojiData();
  }, [selectedMonth, filterState.filiale]);

  /**
   * 2a) Holt Kundenstatistik-Daten
   */
  const fetchTagesDaten = async () => {
    try {
      const response = await axiosInstance.get('/fact/kundenstatistik/', {
        params: {
          filialnummer: filterState.filiale.value,
          jahrMonat: selectedMonth,
        },
      });
      const results = response.data.results || [];
      const mapped = results.map((item) => ({
        datum: item.datum,
        umsatz: item.umsatz || 0,
        planumsatz: item.planumsatz || 0,
        umsatzVJ: item.umsatzVJ || 0,
        produktivstunden: item.produktivstunden || 0,
        lieferwert: item.lieferwert || 0,
        retourwert: item.retourwert || 0,
        korrekturwert: item.korrekturwert || 0,
        kundenanzahl: item.kundenanzahl || 0
      }));
      setRows(mapped);
    } catch (error) {
      console.error('Fehler beim Laden der Kundenstatistik:', error);
      setRows([]);
    }
  };

  /**
   * 2b) Holt bereits gespeicherte Emojis (EmojiTagesfazit).
   * Wir nehmen an, dass dein Backend GET /api/targets/emojis/
   * filtern kann, z. B. ?filialnummer=xxx&yearMonth=yyy.
   */
  const fetchEmojiData = async () => {
    try {
      if (!filterState.filiale?.value) return;
      const filialNr = filterState.filiale.value;

      // Optional: wir filtern serverseitig per yearMonth / filiale
      const response = await axiosInstance.get('/targets/emojis/', {
        params: {
          filialnummer: filialNr,
          jahrMonat: selectedMonth,
        },
      });

      const results = response.data.results;
      // results = Array of { id, filialnummer, datum, emoji, ... }
      // Wir mappen sie in { [dateKey]: { id, emoji } }
      const newObj = {};
      results.forEach((item) => {
        // "2024-12-16T00:00:00" => format zu "YYYY-MM-DD"
        const dateKey = dayjs(item.datum).format('YYYY-MM-DD');
        newObj[dateKey] = { id: item.id, emoji: item.emoji };
      });
      setEmojiSelections(newObj);
    } catch (error) {
      console.error('Fehler beim Laden der Emoji-Daten:', error);
    }
  };

  // 3) Summen für DataCards
  const totalUmsatzMonth = rows.reduce((acc, r) => acc + r.umsatz, 0);
  const totalKundenMonth = rows.reduce((acc, r) => acc + r.kundenanzahl, 0);
  const sumProdStdMonth = rows.reduce((acc, r) => acc + r.produktivstunden, 0);
  const totalStundenleistung = sumProdStdMonth > 0 ? totalUmsatzMonth / sumProdStdMonth : 0;

  const sumLieferwertMonth = rows.reduce((acc, r) => acc + r.lieferwert, 0);
  const sumRetourwertMonth = rows.reduce((acc, r) => acc + r.retourwert, 0);
  const sumKorrekturwertMonth = rows.reduce((acc, r) => acc + r.korrekturwert, 0);
  const totalRetourquote =
    sumLieferwertMonth + sumKorrekturwertMonth > 0
      ? sumRetourwertMonth / (sumLieferwertMonth + sumKorrekturwertMonth)
      : 0;

  // 4) Klick auf Tabellenzeile => Popup
  const handleRowClick = (rowItem) => {
    const filialeLabel = filterState.filiale?.label || 'Unbekannte Filiale';
    setSelectedRow({
      Datum: rowItem.datum,
      Filiale: filialeLabel,
    });
    setPopupOpen(true);
  };

  /**
   * 5) Speichern des Emojis:
   *    - Prüfe, ob wir eine ID haben (PATCH) oder nicht (POST)
   */
  const saveEmoji = async (datumKey, newEmoji) => {
    try {
      const existing = emojiSelections[datumKey];
      const filialNr = filterState.filiale.value;

      // Datum als YYYY-MM-DD
      const dateObj = dayjs(datumKey, 'YYYY-MM-DD').format('YYYY-MM-DD');

      if (existing && existing.id) {
        // PATCH
        const patchUrl = `/targets/emojis/${existing.id}/`;
        const patchData = {
          emoji: newEmoji,
        };
        const patchResp = await axiosInstance.patch(patchUrl, patchData);
        console.log('PATCH erfolgreich:', patchResp.data);

        setEmojiSelections((prev) => ({
          ...prev,
          [datumKey]: { id: existing.id, emoji: newEmoji },
        }));
      } else {
        // POST
        const postUrl = '/targets/emojis/';
        const postData = {
          filialnummer: filialNr,
          datum: dateObj,
          emoji: newEmoji,
        };
        const postResp = await axiosInstance.post(postUrl, postData);
        console.log('POST erfolgreich:', postResp.data);

        setEmojiSelections((prev) => ({
          ...prev,
          [datumKey]: {
            id: postResp.data.id,
            emoji: postResp.data.emoji,
          },
        }));
      }
    } catch (error) {
      console.error('Fehler beim Speichern des Emojis:', error);
    }
  };

  /**
   * 6) onChange in <Select> =>
   *    - Local state updaten
   *    - sofort an Backend schicken (saveEmoji)
   */
  const handleEmojiChange = (datumKey, newEmoji) => {
    // Erst local state updaten:
    setEmojiSelections((prev) => ({
      ...prev,
      [datumKey]: {
        id: prev[datumKey]?.id || null,
        emoji: newEmoji,
      },
    }));

    // Dann an die API
    saveEmoji(datumKey, newEmoji);
  };

  // 7) Gruppierung der rows nach ISO-Kalenderwoche
  const groupedData = groupByWeek(rows);
  const sortedWeekNumbers = Object.keys(groupedData)
    .map((k) => parseInt(k, 10))
    .sort((a, b) => a - b);

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        {/* Kopfzeile */}
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <PageHeader
              title="Datentagebuch"
              subtitle="Führe dein tägliches Filial-Tagebuch"
              Icon={HailOutlinedIcon}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <IconButton onClick={handlePrevMonth}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography variant="h6">{selectedMonth}</Typography>
            <IconButton onClick={handleNextMonth}>
              <ArrowRightIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={4} display="flex" justifyContent="flex-end">
            <Box sx={{ minWidth: 200 }}>
              <DropdownFilter
                label="Filiale"
                options={filialeOptions}
                value={filterState.filiale}
                onChange={handleFilialeChange}
              />
            </Box>
          </Grid>
        </Grid>

        {/* 4 DataCards */}
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6}>
              <DataCard
                chartData={[]}
                category="Umsatz"
                value={`${tausenderTrennung(totalUmsatzMonth, 0)}€`}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <DataCard
                chartData={[]}
                category="Kundenanzahl"
                value={tausenderTrennung(totalKundenMonth, 0)}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <DataCard
                chartData={[]}
                category="Stundenleistung"
                value={tausenderTrennung(totalStundenleistung, 2)}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <DataCard
                chartData={[]}
                category="Retourquote"
                value={`${tausenderTrennung(totalRetourquote * 100, 2)}%`}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Tabelle => inkl. Emoji-Spalte (POST/PATCH) */}
        <Box mt={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" mb={2}>
              Tagesliste (inkl. Planumsatz, Umsatz VJ, Emoji-Spalte)
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell align="right">Umsatz (€)</TableCell>
                  <TableCell align="right">Planumsatz (€)</TableCell>
                  <TableCell align="right">Umsatz VJ (€)</TableCell>
                  <TableCell align="right">Prod.-Std.</TableCell>
                  <TableCell align="right">Kundenanzahl</TableCell>
                  <TableCell align="right">Retourquote</TableCell>
                  <TableCell align="center">Tagesfazit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography variant="body2">Keine Daten vorhanden.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedWeekNumbers.map((weekNr) => {
                    const weekItems = groupedData[weekNr];
                    const totals = calculateWeekTotals(weekItems);

                    return (
                      <React.Fragment key={weekNr}>
                        {weekItems.map((rowItem, idx) => {
                          // Hier kannst du weitere Logs einbauen:
                          console.log(
                            'Zeile rendern => Datum:', rowItem.datum,
                            '| Filiale:', filterState.filiale.value
                          );

                          const weekend = isWeekend(rowItem.datum);
                          const dailyRetour = calculateRetourquote(rowItem);

                          // Datum als Key => "YYYY-MM-DD"
                          const dateKey = dayjs(rowItem.datum).format('YYYY-MM-DD');
                          // Aus local state: { id, emoji } oder ''
                          const storedEmoji = emojiSelections[dateKey]?.emoji || '';

                          // Logge auch den Abgleich:
                          console.log(
                            '  --> dateKey:', dateKey,
                            ' | storedEmoji:', storedEmoji
                          );

                          return (
                            <TableRow
                              key={`${rowItem.datum}-${idx}`}
                              sx={{
                                backgroundColor: weekend ? '#f0f0f0' : 'inherit',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleRowClick(rowItem)}
                            >
                              <TableCell>{formatDateGerman(rowItem.datum)}</TableCell>
                              <TableCell align="right">
                                {tausenderTrennung(rowItem.umsatz, 2)}
                              </TableCell>
                              <TableCell align="right">
                                {tausenderTrennung(rowItem.planumsatz, 2)}
                              </TableCell>
                              <TableCell align="right">
                                {tausenderTrennung(rowItem.umsatzVJ, 2)}
                              </TableCell>
                              <TableCell align="right">
                                {tausenderTrennung(rowItem.produktivstunden, 2)}
                              </TableCell>
                              <TableCell align="right">{rowItem.kundenanzahl}</TableCell>
                              <TableCell align="right">
                                {tausenderTrennung(dailyRetour * 100, 2)} %
                              </TableCell>
                              {/* Emoji-Spalte => POST/PATCH an /api/targets/emojis/ */}
                              <TableCell
                                align="center"
                                onClick={(e) => {
                                  // Verhindert, dass Zeilenklick das Popup öffnet
                                  e.stopPropagation();
                                }}
                              >
                                <FormControl size="small" sx={{ minWidth: 60 }}>
                                  <Select
                                    value={storedEmoji}
                                    onChange={(e) => handleEmojiChange(dateKey, e.target.value)}
                                    displayEmpty
                                  >
                                    <MenuItem value="" disabled>
                                      -
                                    </MenuItem>
                                    {emojiOptions.map((emoji) => (
                                      <MenuItem key={emoji} value={emoji}>
                                        {emoji}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        {/* Zwischensumme je KW */}
                        <TableRow sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                          <TableCell>KW {weekNr} Zwischensumme</TableCell>
                          <TableCell align="right">
                            {tausenderTrennung(totals.sumUmsatz, 2)}
                          </TableCell>
                          <TableCell align="right">
                            {tausenderTrennung(totals.sumPlanumsatz, 2)}
                          </TableCell>
                          <TableCell align="right">
                            {tausenderTrennung(totals.sumUmsatzVJ, 2)}
                          </TableCell>
                          <TableCell align="right">
                            {tausenderTrennung(totals.sumProdStd, 2)}
                          </TableCell>
                          <TableCell />
                          <TableCell align="right">
                            {tausenderTrennung(totals.sumRetourquote * 100, 2)}%
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                {/* Falls du eine Gesamtsumme pro Monat willst */}
              </TableFooter>
            </Table>
          </Paper>
        </Box>
      </Box>

      {/* Popup */}
      <TagesreportDetailsPopup
        open={popupOpen}
        handleClose={() => setPopupOpen(false)}
        filialeData={selectedRow}
      />
    </Layout>
  );
};

export default Tagesbuch;
