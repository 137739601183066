import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/Tagesplaner/Tagesplaner';
import {
  Box,
  Paper,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import TagesplanerBackhinweis from '../../../components/popup/TagesplanerBackhinweis';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from "../../../provider/FilterProvider";
import { useAuth } from "../../../provider/authProvider";

// Recharts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Dot } from 'recharts';

import HourSection from './HourSection';
import TaskResponseDialog from '../../../components/Aufgaben/FormularDialog';

function Timetable() {
  const theme = useTheme();
  const { user, loading } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { filterState, handleFilialeChange } = useContext(FilterContext);

  const [filialeOptions, setFilialeOptions] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const [currentHour, setCurrentHour] = useState(dayjs().hour());
  const [showAllHours, setShowAllHours] = useState(false);

  const [tagesplanerData, setTagesplanerData] = useState([]);
  const [allInstances, setAllInstances] = useState([]);
  const [filialInstancesToday, setFilialInstancesToday] = useState([]); 
  const [instancesByHour, setInstancesByHour] = useState({});

  // Fortschritt: { instanceId: { answeredCount, totalCount } }
  const [progressMap, setProgressMap] = useState({});

  const timetableRef = useRef(null);
  const hours = Array.from({ length: 18 }, (_, index) => index + 4);
  const visibleHours = showAllHours ? hours : hours.filter((h) => h >= currentHour);

  // Dialog-States
  const [taskTemplateDialogOpen, setTaskTemplateDialogOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);
  // Fix: Kein taskType in newTaskTemplateData, da immer Filialaufgabe
  const [newTaskTemplateData, setNewTaskTemplateData] = useState({
    title: '',
    description: '',
    due_time: ''
  });

  const [articleDialogOpen, setArticleDialogOpen] = useState(false);
  const [articleDialogHour, setArticleDialogHour] = useState(null);
  const [chartDialogOpen, setChartDialogOpen] = useState(false);

  const [taskResponseDialogOpen, setTaskResponseDialogOpen] = useState(false);
  const [currentResponseInstanceId, setCurrentResponseInstanceId] = useState(null);

  // -------------------------------------------
  // 1) Filialauswahl
  // -------------------------------------------
  useEffect(() => {
    if (!user) return;

    if (user.user_type === 'FILIALE') {
      // Automatisch die Filiale des eingeloggten Filial-Users wählen
      if (user.filialen?.length > 0) {
        const fil = user.filialen[0];
        const filObj = { label: fil.filiale, value: fil.filialnummer };
        if (!filterState.filiale || filterState.filiale.value !== fil.filialnummer) {
          handleFilialeChange(filObj);
        }
      }
    } else {
      // Bei anderen User-Typen: Filialliste laden
      const fetchFilialeOptions = async () => {
        try {
          const res = await axiosInstance.get('stammdaten/unique-filiale/');
          const opts = res.data.map((item) => ({
            label: item.filiale,
            value: item.filialnummer
          }));
          setFilialeOptions(opts);
          if (opts.length > 0 && !filterState.filiale) {
            handleFilialeChange(opts[0]);
          }
        } catch (err) {
          console.error('Fehler beim Laden der Filialen:', err);
          setSnackbar({ open: true, message: 'Fehler beim Laden der Filialen.', severity: 'error' });
        }
      };
      fetchFilialeOptions();
    }
  }, [user, filterState.filiale, handleFilialeChange]);

  // -------------------------------------------
  // 2) Tagesplaner (Kundenanzahl)
  // -------------------------------------------
  useEffect(() => {
    const fetchTagesplanerData = async () => {
      if (!user || !filterState.filiale) return;
      const currentDate = dayjs().format('YYYY-MM-DD');
      const filialParam = filterState.filiale.label;

      try {
        const response = await axiosInstance.get('tagesplaner/tagesplaner-summary/', {
          params: { filialbezeichnung: filialParam, datum: currentDate }
        });
        setTagesplanerData(response.data.results || []);
      } catch (error) {
        console.error('Fehler beim Laden der Tagesplaner-Daten:', error);
        setTagesplanerData([]);
      }
    };
    if (user && filterState.filiale) {
      fetchTagesplanerData();
    }
  }, [user, filterState.filiale]);

  // -------------------------------------------
  // 3) Alle TaskInstances laden (Filiale + Zentral)
  // -------------------------------------------
  useEffect(() => {
    const fetchInstances = async () => {
      if (!user || !filterState.filiale) return;

      let assignedUserIds = [];
      if (user.user_type === 'FILIALE') {
        // bei Filial-User => nur seinen User (Einzelperson)
        assignedUserIds = [user.id];
      } else {
        // bei anderem UserType => alle Filial-User der gewählten Filiale
        const filnr = filterState.filiale.value;
        const userUrl = `/auth/users/?filialnummer=${filnr}&user_type=FILIALE`;
        const userRes = await axiosInstance.get(userUrl);
        const userArray = Array.isArray(userRes.data) ? userRes.data : userRes.data.results || [];
        assignedUserIds = userArray.map((u) => u.id);
      }

      let combined = [];
      for (const filUserId of assignedUserIds) {
        const resp = await axiosInstance.get('/aufgaben/task-instances/', {
          params: {
            assigned_user: filUserId
          }
        });
        const partial = resp.data.results || [];
        combined = combined.concat(partial);
      }

      setAllInstances(combined);
    };

    if (user && filterState.filiale) {
      fetchInstances();
    }
  }, [user, filterState.filiale]);

  // -------------------------------------------
  // 4) Linke Spalte: Filialaufgaben -> nur "heute"
  // -------------------------------------------
  useEffect(() => {
    const filtered = allInstances.filter((inst) => {
      if (inst.template?.task_type !== 'Filialaufgabe') return false;
      if (inst.is_done) return false;

      // Nur am heutigen Tag
      const dueDay = dayjs(inst.due_datetime);
      return dueDay.isSame(dayjs(), 'day');
    });

    setFilialInstancesToday(filtered);

    const map = {};
    filtered.forEach((inst) => {
      const h = dayjs(inst.due_datetime).hour();
      if (!map[h]) map[h] = [];
      map[h].push(inst);
    });
    setInstancesByHour(map);
  }, [allInstances]);

  // -------------------------------------------
  // 5) Fortschritt berechnen (nur Zentrale)
  // -------------------------------------------
  useEffect(() => {
    const calcProgress = async () => {
      const newMap = {};
      for (const inst of allInstances) {
        if (inst.template?.task_type !== 'Zentrale') continue;
        if (!inst.template?.task_sections?.length) continue;

        // totalQ:
        let totalQ = 0;
        inst.template.task_sections.forEach((sec) => {
          totalQ += (sec.questions?.length || 0);
        });
        if (totalQ === 0) continue;

        try {
          const resp = await axiosInstance.get('/aufgaben/task-responses/', {
            params: { task_instance: inst.id }
          });
          const results = resp.data.results || [];
          if (results.length === 0) {
            newMap[inst.id] = { answeredCount: 0, totalCount: totalQ };
          } else {
            const tr = results[0];
            let answeredCount = 0;
            for (const ans of tr.answers) {
              if (ans.value !== '' && ans.value !== null) {
                answeredCount++;
              }
            }
            newMap[inst.id] = { answeredCount, totalCount: totalQ };
          }
        } catch (err) {
          console.error('Fehler beim Laden der TaskResponses:', err);
        }
      }
      setProgressMap(newMap);
    };
    calcProgress();
  }, [allInstances]);

  // -------------------------------------------
  // 6) Scrollen zur aktuellen Stunde
  // -------------------------------------------
  useEffect(() => {
    if (timetableRef.current) {
      const el = timetableRef.current.querySelector(`#hour-${currentHour}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentHour]);

  // -------------------------------------------
  // 7) Jede Minute => currentHour
  // -------------------------------------------
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(dayjs().hour());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // -------------------------------------------
  // getProgressText
  // -------------------------------------------
  const getProgressText = (instanceId) => {
    const prog = progressMap[instanceId];
    if (!prog) return null;
    const { answeredCount, totalCount } = prog;
    return `${answeredCount}/${totalCount}`;
  };

  // -------------------------------------------
  // Neues TaskTemplate (immer Filialaufgabe)
  // -------------------------------------------
  const handleOpenTaskTemplateDialog = (hour) => {
    setSelectedHour(hour);
    const hhStr = hour < 10 ? `0${hour}` : `${hour}`;
    setNewTaskTemplateData({
      title: '',
      description: '',
      due_time: `${hhStr}:00`
    });
    setTaskTemplateDialogOpen(true);
  };

  const handleCloseTaskTemplateDialog = () => {
    setTaskTemplateDialogOpen(false);
    setSelectedHour(null);
  };

  const handleSaveTaskTemplate = async () => {
    if (!newTaskTemplateData.title) {
      setSnackbar({ open: true, message: 'Bitte einen Titel eingeben.', severity: 'error' });
      return;
    }
    try {
      const todayStr = dayjs().format('YYYY-MM-DD');
      let dueTimeStr = newTaskTemplateData.due_time;
      if (dueTimeStr && dueTimeStr.length === 5) {
        dueTimeStr += ':00';
      }

      // 1) Variable in JS: assignedUsers
      let assignedUsers = [];
      if (user.user_type === 'FILIALE') {
        // wenn User vom Typ FILIALE -> ihn selbst
        assignedUsers = [user.id];
      } else {
        // wenn anderer User-Typ -> Filial-User der ausgewählten Filiale
        if (!filterState.filiale) {
          setSnackbar({ open: true, message: 'Bitte Filiale auswählen.', severity: 'error' });
          return;
        }
        const filnr = filterState.filiale.value;
        const userUrl = `/auth/users/?filialnummer=${filnr}&user_type=FILIALE`;
        const userRes = await axiosInstance.get(userUrl);
        const userArray = Array.isArray(userRes.data) ? userRes.data : userRes.data.results || [];
        if (!userArray.length) {
          setSnackbar({
            open: true,
            message: 'Kein Filialuser für diese Filiale vorhanden.',
            severity: 'error'
          });
          return;
        }
        assignedUsers = userArray.map((u) => u.id);
      }

      // 2) Payload an Backend: hier das Feld "assigned_users" (Unterstrich)
      const payload = {
        title: newTaskTemplateData.title,
        description: newTaskTemplateData.description,
        task_type: 'Filialaufgabe',
        rhythm: 'once',
        start_date: todayStr,
        due_time: dueTimeStr,
        assigned_users: assignedUsers, // hier das Unterstrich-Property
      };

      await axiosInstance.post('/aufgaben/task-templates/', payload);

      setSnackbar({
        open: true,
        message: 'Aufgabe (Template) erfolgreich erstellt!',
        severity: 'success'
      });
      handleCloseTaskTemplateDialog();
    } catch (error) {
      console.error('Fehler beim Anlegen des TaskTemplates:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Anlegen der Aufgabe (Template).',
        severity: 'error'
      });
    }
  };

  // -------------------------------------------
  // Artikel-Dialog
  // -------------------------------------------
  const handleOpenArticleDialog = (hour) => {
    setArticleDialogHour(hour);
    setArticleDialogOpen(true);
  };
  const handleCloseArticleDialog = () => {
    setArticleDialogOpen(false);
    setArticleDialogHour(null);
  };

  // -------------------------------------------
  // Diagramm
  // -------------------------------------------
  const handleOpenChartDialog = () => setChartDialogOpen(true);
  const handleCloseChartDialog = () => setChartDialogOpen(false);

  // -------------------------------------------
  // is_done toggeln
  // -------------------------------------------
  const handleToggleInstanceDone = async (instance) => {
    try {
      const newIsDone = !instance.is_done;
      const newCompletedTime = newIsDone ? new Date().toISOString() : null;

      await axiosInstance.patch(`/aufgaben/task-instances/${instance.id}/`, {
        is_done: newIsDone,
        completed_time: newCompletedTime
      });

      setAllInstances((prev) =>
        prev.map((inst) =>
          inst.id === instance.id
            ? { ...inst, is_done: newIsDone, completed_time: newCompletedTime }
            : inst
        )
      );
    } catch (err) {
      console.error('Fehler beim Aktualisieren der Instance:', err);
      setSnackbar({
        open: true,
        message: 'Fehler beim Aktualisieren des Status.',
        severity: 'error'
      });
    }
  };

  // -------------------------------------------
  // TaskResponseDialog
  // -------------------------------------------
  const handleOpenTaskResponseDialog = (instanceId) => {
    setCurrentResponseInstanceId(instanceId);
    setTaskResponseDialogOpen(true);
  };
  const handleCloseTaskResponseDialog = () => {
    setTaskResponseDialogOpen(false);
    setCurrentResponseInstanceId(null);
  };

  // -------------------------------------------
  // Chart-Daten
  // -------------------------------------------
  const chartData = useMemo(() => {
    if (!Array.isArray(tagesplanerData)) return [];
    return tagesplanerData.map((item) => ({
      uhrzeit: item.uhrzeit.substring(0, 5),
      kundenanzahl: item.kundenanzahl,
      scoreTagesplaner: item.scoreTagesplaner
    }));
  }, [tagesplanerData]);

  const getPointColor = (score) => {
    if (score >= 1 && score < 4) return theme.palette.success.main;
    if (score >= 4 && score < 7) return theme.palette.warning.main;
    if (score >= 7) return theme.palette.error.main;
    return theme.palette.grey[500];
  };

  // -------------------------------------------
  // Zentrale Aufgaben filtern (rechte Spalte)
  // -------------------------------------------
  const now = dayjs();
  const centralTasks = allInstances.filter((inst) => {
    if (inst.template?.task_type !== 'Zentrale') return false;
    if (inst.is_done) return false; // nur nicht erledigte

    const due = dayjs(inst.due_datetime);
    const rmdMin = inst.template?.reminder_before_deadline_minutes;

    if (typeof rmdMin === 'number' && rmdMin > 0) {
      const remindTime = due.subtract(rmdMin, 'minute');
      return now.isAfter(remindTime) || now.isAfter(due);
    } else {
      return due.isBefore(now, 'day') || due.isSame(now, 'day');
    }
  });

  // Rhythmus-Gruppierung für Zentrale Aufgaben
  const tasksOnce    = centralTasks.filter((i) => i.template?.rhythm === 'once');
  const tasksDaily   = centralTasks.filter((i) => i.template?.rhythm === 'daily');
  const tasksWeekly  = centralTasks.filter((i) => i.template?.rhythm === 'weekly');
  const tasksMonthly = centralTasks.filter((i) => i.template?.rhythm === 'monthly');
  const tasksYearly  = centralTasks.filter((i) => i.template?.rhythm === 'yearly');

  // Hilfsfunktion: Datum ggf. rot färben
  const getDueDateColor = (dueDate) => {
    const dueDayjs = dayjs(dueDate);
    if (dueDayjs.isBefore(dayjs())) {
      // In der Vergangenheit => rot
      return theme.palette.error.main;
    }
    // Sonst z.B. grau
    return 'gray';
  };

  return (
    <Layout>
      <PageHeader
        title="Tagesplaner"
        Icon={BakeryDiningIcon}
      />

      <Grid container spacing={2} sx={{ mt: 0 }} alignItems="flex-start">
        {/* Linke Spalte: Filialaufgaben (heute) */}
        <Grid item xs={12} md={6}>
          <Box ref={timetableRef} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              {user.user_type !== 'FILIALE' && (
                <DropdownFilter
                  label="Filiale"
                  options={filialeOptions}
                  value={filterState.filiale}
                  onChange={handleFilialeChange}
                />
              )}
              <Box>
                {!showAllHours ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowAllHours(true)}
                    sx={{ mr: 1 }}
                  >
                    Alle Stunden
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowAllHours(false)}
                    sx={{ mr: 1 }}
                  >
                    Stunden ausblenden
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenChartDialog}
                >
                  Kundenverlauf
                </Button>
              </Box>
            </Box>

            {visibleHours.map((hour) => {
              const dayplanItem = tagesplanerData.find(
                (x) => parseInt(x.uhrzeit.split(':')[0], 10) === hour
              ) || null;

              let borderColor = 'transparent';
              if (dayplanItem) {
                const sc = dayplanItem.scoreTagesplaner;
                if (sc >= 1 && sc < 4) borderColor = theme.palette.success.main;
                else if (sc >= 4 && sc < 7) borderColor = theme.palette.warning.main;
                else if (sc >= 7) borderColor = theme.palette.error.main;
              }

              const hourInstances = instancesByHour[hour] || [];
              return (
                <HourSection
                  key={hour}
                  hour={hour}
                  dataForHour={dayplanItem}
                  borderColor={borderColor}
                  currentHour={currentHour}
                  theme={theme}
                  hourInstances={hourInstances}
                  handleOpenTaskTemplateDialog={handleOpenTaskTemplateDialog}
                  handleOpenArticleDialog={handleOpenArticleDialog}
                  handleToggleInstanceDone={handleToggleInstanceDone}
                />
              );
            })}
          </Box>
        </Grid>

        {/* Rechte Spalte: Zentrale Aufgaben */}
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Zentrale Aufgaben
            </Typography>

            {/* Einmalige */}
            {tasksOnce.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>Einmalige Aufgaben</Typography>
                {tasksOnce.map((inst) => {
                  const progressText = getProgressText(inst.id);
                  const totalQs = inst.template?.task_sections?.reduce(
                    (acc, s) => acc + (s.questions?.length || 0),
                    0
                  ) || 0;
                  const hasQuestions = totalQs > 0;
                  const dueDateColor = getDueDateColor(inst.due_datetime);

                  return (
                    <React.Fragment key={inst.id}>
                      <Box
                        sx={{
                          mb: 1,
                          cursor: hasQuestions ? 'pointer' : 'default',
                          borderBottom: '1px solid #ccc',
                          pb: 1
                        }}
                        onClick={() => {
                          if (hasQuestions) {
                            setCurrentResponseInstanceId(inst.id);
                            setTaskResponseDialogOpen(true);
                          }
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {inst.template?.title}{' '}
                          {progressText && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'gray', ml: 1 }}
                            >
                              ({progressText})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {inst.template?.description}
                        </Typography>
                        {/* Fälligkeitsdatum in Rot, falls in Vergangenheit */}
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', color: dueDateColor }}
                        >
                          Fällig am {dayjs(inst.due_datetime).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Paper>
            )}

            {/* Täglich */}
            {tasksDaily.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>Tägliche Aufgaben</Typography>
                {tasksDaily.map((inst) => {
                  const progressText = getProgressText(inst.id);
                  const totalQs = inst.template?.task_sections?.reduce(
                    (acc, s) => acc + (s.questions?.length || 0),
                    0
                  ) || 0;
                  const hasQuestions = totalQs > 0;
                  const dueDateColor = getDueDateColor(inst.due_datetime);

                  return (
                    <React.Fragment key={inst.id}>
                      <Box
                        sx={{
                          mb: 1,
                          cursor: hasQuestions ? 'pointer' : 'default',
                          borderBottom: '1px solid #ccc',
                          pb: 1
                        }}
                        onClick={() => {
                          if (hasQuestions) {
                            setCurrentResponseInstanceId(inst.id);
                            setTaskResponseDialogOpen(true);
                          }
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {inst.template?.title}{' '}
                          {progressText && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'gray', ml: 1 }}
                            >
                              ({progressText})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {inst.template?.description}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', color: dueDateColor }}
                        >
                          Fällig am {dayjs(inst.due_datetime).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Paper>
            )}

            {/* Wöchentlich */}
            {tasksWeekly.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>Wöchentliche Aufgaben</Typography>
                {tasksWeekly.map((inst) => {
                  const progressText = getProgressText(inst.id);
                  const totalQs = inst.template?.task_sections?.reduce(
                    (acc, s) => acc + (s.questions?.length || 0),
                    0
                  ) || 0;
                  const hasQuestions = totalQs > 0;
                  const dueDateColor = getDueDateColor(inst.due_datetime);

                  return (
                    <React.Fragment key={inst.id}>
                      <Box
                        sx={{
                          mb: 1,
                          cursor: hasQuestions ? 'pointer' : 'default',
                          borderBottom: '1px solid #ccc',
                          pb: 1
                        }}
                        onClick={() => {
                          if (hasQuestions) {
                            setCurrentResponseInstanceId(inst.id);
                            setTaskResponseDialogOpen(true);
                          }
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {inst.template?.title}{' '}
                          {progressText && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'gray', ml: 1 }}
                            >
                              ({progressText})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {inst.template?.description}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', color: dueDateColor }}
                        >
                          Fällig am {dayjs(inst.due_datetime).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Paper>
            )}

            {/* Monatlich */}
            {tasksMonthly.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>Monatliche Aufgaben</Typography>
                {tasksMonthly.map((inst) => {
                  const progressText = getProgressText(inst.id);
                  const totalQs = inst.template?.task_sections?.reduce(
                    (acc, s) => acc + (s.questions?.length || 0),
                    0
                  ) || 0;
                  const hasQuestions = totalQs > 0;
                  const dueDateColor = getDueDateColor(inst.due_datetime);

                  return (
                    <React.Fragment key={inst.id}>
                      <Box
                        sx={{
                          mb: 1,
                          cursor: hasQuestions ? 'pointer' : 'default',
                          borderBottom: '1px solid #ccc',
                          pb: 1
                        }}
                        onClick={() => {
                          if (hasQuestions) {
                            setCurrentResponseInstanceId(inst.id);
                            setTaskResponseDialogOpen(true);
                          }
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {inst.template?.title}{' '}
                          {progressText && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'gray', ml: 1 }}
                            >
                              ({progressText})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {inst.template?.description}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', color: dueDateColor }}
                        >
                          Fällig am {dayjs(inst.due_datetime).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Paper>
            )}

            {/* Jährlich */}
            {tasksYearly.length > 0 && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>Jährliche Aufgaben</Typography>
                {tasksYearly.map((inst) => {
                  const progressText = getProgressText(inst.id);
                  const totalQs = inst.template?.task_sections?.reduce(
                    (acc, s) => acc + (s.questions?.length || 0),
                    0
                  ) || 0;
                  const hasQuestions = totalQs > 0;
                  const dueDateColor = getDueDateColor(inst.due_datetime);

                  return (
                    <React.Fragment key={inst.id}>
                      <Box
                        sx={{
                          mb: 1,
                          cursor: hasQuestions ? 'pointer' : 'default',
                          borderBottom: '1px solid #ccc',
                          pb: 1
                        }}
                        onClick={() => {
                          if (hasQuestions) {
                            setCurrentResponseInstanceId(inst.id);
                            setTaskResponseDialogOpen(true);
                          }
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {inst.template?.title}{' '}
                          {progressText && (
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: 'gray', ml: 1 }}
                            >
                              ({progressText})
                            </Typography>
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {inst.template?.description}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ display: 'block', color: dueDateColor }}
                        >
                          Fällig am {dayjs(inst.due_datetime).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Paper>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Dialog: Neue Filialaufgabe */}
      <Dialog
        open={taskTemplateDialogOpen}
        onClose={handleCloseTaskTemplateDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Neue Aufgabe erstellen (Filialaufgabe)</DialogTitle>
        <DialogContent>
          <TextField
            label="Titel"
            fullWidth
            variant="standard"
            margin="normal"
            value={newTaskTemplateData.title}
            onChange={(e) =>
              setNewTaskTemplateData((prev) => ({ ...prev, title: e.target.value }))
            }
          />
          <TextField
            label="Beschreibung"
            fullWidth
            variant="standard"
            margin="normal"
            multiline
            rows={3}
            value={newTaskTemplateData.description}
            onChange={(e) =>
              setNewTaskTemplateData((prev) => ({ ...prev, description: e.target.value }))
            }
          />
          <TextField
            label="Fälligkeitszeit (HH:MM)"
            type="time"
            fullWidth
            margin="normal"
            variant="standard"
            value={newTaskTemplateData.due_time}
            onChange={(e) =>
              setNewTaskTemplateData((prev) => ({ ...prev, due_time: e.target.value }))
            }
            inputProps={{ step: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTaskTemplateDialog} color="secondary">
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveTaskTemplate}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {/* Artikel-Dialog */}
      <TagesplanerBackhinweis
        open={articleDialogOpen}
        onClose={handleCloseArticleDialog}
        hour={articleDialogHour}
        filiale={filterState.filiale?.label || ''}
      />

      {/* Diagramm-Dialog */}
      <Dialog
        open={chartDialogOpen}
        onClose={handleCloseChartDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Kundenverlauf</DialogTitle>
        <DialogContent>
          <LineChart
            width={600}
            height={300}
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="uhrzeit" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="kundenanzahl"
              stroke={theme.palette.primary.main}
              dot={(props) => {
                const { cx, cy, payload } = props;
                const color = getPointColor(payload.scoreTagesplaner);
                return <Dot cx={cx} cy={cy} r={5} fill={color} />;
              }}
            />
          </LineChart>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChartDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* TaskResponseDialog */}
      {taskResponseDialogOpen && (
        <TaskResponseDialog
          open={taskResponseDialogOpen}
          onClose={handleCloseTaskResponseDialog}
          taskInstanceId={currentResponseInstanceId}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default Timetable;
