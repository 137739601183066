import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
    Stepper, Step, StepLabel, List, ListItem, ListItemText,
    IconButton, Collapse, Alert, Divider, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Dialog-Komponenten für Kategorien, Themen und Schritte
const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

const TopicDialog = ({ open, onClose, topicName, setTopicName, handleSaveTopic, editingTopic }) => {
    const [entries, setEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState(null);

    useEffect(() => {
        if (open) {
            // Lade alle Wiki Entries
            axiosInstance.get('/wiki/entries_all/')
                .then(response => {
                    setEntries(response.data.results || []);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Wiki-Einträge:', error);
                });
        } else {
            setSelectedEntry(null);
        }
    }, [open]);

    useEffect(() => {
        if (open && entries.length > 0 && editingTopic) {
            // Falls bearbeitetes Thema einen wiki_link hat
            const wiki_link = editingTopic.wiki_link;
            if (wiki_link) {
                const parts = wiki_link.split('/');
                const overviewIndex = parts.indexOf('overview');
                if (overviewIndex !== -1 && parts.length > overviewIndex + 3) {
                    const manual_slug = parts[overviewIndex + 1];
                    const chapter = parts[overviewIndex + 2];
                    const entry_slug = parts[overviewIndex + 3];

                    const entry = entries.find(e =>
                        e.manual.slug === manual_slug &&
                        e.chapter === chapter &&
                        e.slug === entry_slug
                    );

                    if (entry) {
                        setSelectedEntry(entry);
                    } else {
                        setSelectedEntry(null);
                    }
                } else {
                    setSelectedEntry(null);
                }
            } else {
                setSelectedEntry(null);
            }
        } else if (open && entries.length > 0 && !editingTopic) {
            setSelectedEntry(null);
        }
    }, [open, entries, editingTopic]);

    const handleEntryChange = (event) => {
        const entryId = event.target.value;
        const entry = entries.find(e => e.id === entryId);
        setSelectedEntry(entry || null);
    };

    const wikiUrl = selectedEntry
        ? `${window.location.origin}/wiki/overview/${selectedEntry.manual.slug}/${selectedEntry.chapter}/${selectedEntry.slug}`
        : '';

    const onSave = () => {
        handleSaveTopic(topicName, wikiUrl);
    };

    return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{editingTopic ? 'Thema bearbeiten' : 'Neues Thema anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Themenname"
                fullWidth
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                margin="normal"
            />
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="entry-label">Wiki-Eintrag</InputLabel>
                    <Select
                        labelId="entry-label"
                        value={selectedEntry ? selectedEntry.id : ''}
                        label="Wiki-Eintrag"
                        onChange={handleEntryChange}
                    >
                        <MenuItem value="">
                            <em>Kein Eintrag ausgewählt</em>
                        </MenuItem>
                        {entries.map(entry => (
                            <MenuItem key={entry.id} value={entry.id}>
                                {entry.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {wikiUrl && (
                    <TextField
                        label="Wiki Link"
                        fullWidth
                        value={wikiUrl}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                )}
        </DialogContent>
        <DialogActions>
                <Button onClick={onSave} variant="contained" color="primary">Speichern</Button>
                <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);
};

const StepDialog = ({ open, onClose, stepName, setStepName, handleSaveStep }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{stepName ? 'Schritt bearbeiten' : 'Neuen Schritt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Schrittbeschreibung"
                fullWidth
                value={stepName}
                onChange={(e) => setStepName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveStep} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);


const StepList = ({ topicId, steps, handleDeleteStep, handleEditStep }) => {
    if (!topicId) {
        return <Typography variant="body2" color="error">Fehler: topicId nicht übergeben.</Typography>;
    }
    return (
        <Droppable droppableId={topicId.toString()} type="step">
            {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <List sx={{ width: '100%' }}>
                        {steps.length > 0 ? (
                            steps.map((step, index) => (
                                <Draggable key={step.id} draggableId={`step-${step.id}`} index={index}>
                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                                border: '1px solid #ddd',
                                                borderRadius: '8px',
                                                p: 2,
                                                mb: 1,
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                backgroundColor: '#fff',
                                            }}
                                        >
                                            <ListItem
                                                secondaryAction={
                                                    <>
                                                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditStep(step)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStep(step.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                <ListItemText primary={step.description} />
                                            </ListItem>
                                        </Box>
                                    )}
                                </Draggable>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                Keine Schritte vorhanden.
                            </Typography>
                        )}
                        {provided.placeholder}
                    </List>
                </Box>
            )}
        </Droppable>
    );
};

const TopicItem = ({
    topic,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddStepPlaceholderClick,
    wikiEntries
}) => {
    const relatedSteps = steps[topic.id] || [];
    const wikiEntry = wikiEntries.find(entry => {
        // Prüfe ob topic.wiki_link existiert
        if (!topic.wiki_link) return false;
        const url = new URL(topic.wiki_link, window.location.origin);
        // Pfadelemente extrahieren
        const parts = url.pathname.split('/').filter(Boolean); 
        // expected: ['wiki','overview','manual','chapter','entry']
        const overviewIndex = parts.indexOf('overview');
        if (overviewIndex !== -1 && parts.length > overviewIndex + 3) {
            const manual_slug = parts[overviewIndex + 1];
            const chapter = parts[overviewIndex + 2];
            const entry_slug = parts[overviewIndex + 3];

            return (entry.manual.slug === manual_slug && entry.chapter === chapter && entry.slug === entry_slug);
        }
        return false;
    });

    return (
        <Draggable key={topic.id} draggableId={`topic-${topic.id}`} index={topic.order || 0}>
            {(provided) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        p: 2,
                        mb: 1,
                        width: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: '#fafafa'
                    }}
                >
                    <ListItem
                        secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="expand" onClick={() => handleToggleTopic(topic.id)}>
                                    <ExpandMoreIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditTopic(topic)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTopic(topic.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText
                            primary={topic.name}
                            secondary={wikiEntry ? `Wiki: ${wikiEntry.title}` : 'Kein Wiki-Eintrag'}
                        />
                    </ListItem>
                    <Collapse in={openTopics[topic.id]} timeout="auto" unmountOnExit>
                        <StepList
                            topicId={topic.id}
                            steps={relatedSteps}
                            handleDeleteStep={handleDeleteStep}
                            handleEditStep={handleEditStep}
                        />
                        <Box
                            sx={{
                                border: '2px dashed #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: '#888',
                                mt: 2,
                                width: '100%',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5'
                                },
                                boxSizing: 'border-box',
                            }}
                            onClick={() => handleAddStepPlaceholderClick(topic.id)}
                        >
                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Neuen Schritt anlegen
                        </Box>
                    </Collapse>
                </Box>
            )}
        </Draggable>
    );
};

const TopicList = ({
    categoryId,
    topics,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddStepPlaceholderClick,
    wikiEntries
}) => {
    if (!categoryId) {
        return <Typography variant="body2" color="error">Fehler: categoryId nicht übergeben.</Typography>;
    }

    const categoryTopics = topics.filter(t => t.category === categoryId).sort((a,b)=>a.order-b.order);

    return (
        <Droppable droppableId={categoryId.toString()} type="topic">
            {(provided) => (
                <List
                    sx={{ width: '100%' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {categoryTopics.length > 0 ? (
                        categoryTopics.map((topic) => (
                            <TopicItem
                                key={topic.id}
                                topic={topic}
                                steps={steps}
                                openTopics={openTopics}
                                handleToggleTopic={handleToggleTopic}
                                handleDeleteTopic={handleDeleteTopic}
                                handleEditTopic={handleEditTopic}
                                handleDeleteStep={handleDeleteStep}
                                handleEditStep={handleEditStep}
                                handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                wikiEntries={wikiEntries}
                            />
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">Keine Themen vorhanden.</Typography>
                    )}
                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    );
};

const CategoryItem = ({
    category,
    selectedCategory,
    setSelectedCategory,
    topics,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteCategory,
    handleEditCategory,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddTopicPlaceholderClick,
    handleAddStepPlaceholderClick,
    wikiEntries
}) => {
    const toggleCategory = () => {
        setSelectedCategory(selectedCategory === category.id ? null : category.id);
    };

    return (
        <Box
            sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2, backgroundColor: '#fafafa' }}
        >
            <ListItem
                secondaryAction={
                    <>
                        <IconButton edge="end" aria-label="expand" onClick={toggleCategory}>
                            <ExpandMoreIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                }
            >
                <ListItemText primary={category.name} />
            </ListItem>
            <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
                <TopicList
                    categoryId={category.id}
                    topics={topics}
                    steps={steps}
                    openTopics={openTopics}
                    handleToggleTopic={handleToggleTopic}
                    handleDeleteTopic={handleDeleteTopic}
                    handleEditTopic={handleEditTopic}
                    handleDeleteStep={handleDeleteStep}
                    handleEditStep={handleEditStep}
                    handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                    wikiEntries={wikiEntries}
                />
                <Box
                    sx={{
                        border: '2px dashed #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#888',
                        mt: 2,
                        width: '100%',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                        boxSizing: 'border-box',
                    }}
                    onClick={() => handleAddTopicPlaceholderClick(category.id)}
                >
                    <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Neues Thema hinzufügen
                </Box>
            </Collapse>
        </Box>
    );
};

const OnboardingHinzufuegen = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive] = useState(true);
    const [onboardingPlanId, setOnboardingPlanId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [topics, setTopics] = useState([]);
    const [topicName, setTopicName] = useState('');
    const [stepsData, setStepsData] = useState({});
    const [stepName, setStepName] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const navigate = useNavigate();

    // Zustände für die Dialoge und Bearbeitungsmodi
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [openStepDialog, setOpenStepDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTopic, setEditingTopic] = useState(null);
    const [editingStep, setEditingStep] = useState(null);

    const [selectedWikiEntry, setSelectedWikiEntry] = useState('');
    const [openTopicsState, setOpenTopicsState] = useState({});

    const steps = ['Name und Beschreibung', 'Kategorien hinzufügen', 'Themen/Schritte konfigurieren'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (onboardingPlanId === null) {
                // Neuen Onboarding-Plan erstellen
                try {
                    const response = await axiosInstance.post('/onboarding/onboarding-plans/', {
                        name,
                        description,
                        is_active: true
                    });
                    setOnboardingPlanId(response.data.id);
                } catch (error) {
                    console.error('Fehler beim Hinzufügen des Onboarding-Plans:', error);
                    return;
                }
            } else {
                // Bestehenden Onboarding-Plan aktualisieren
                try {
                    await axiosInstance.patch(`/onboarding/onboarding-plans/${onboardingPlanId}/`, {
                        name,
                        description,
                        is_active: true
                    });
                } catch (error) {
                    console.error('Fehler beim Aktualisieren des Onboarding-Plans:', error);
                    return;
                }
            }
        }

        setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    const handleSaveAndClose = () => {
        resetForm();
        handleClose();
        navigate('/onboarding/overview/onboardingkonfiguration');
    };

    const handleCloseWithConfirmation = () => {
        setOpenConfirmDialog(true);
    };

    const handleConfirmClose = async () => {
        if (onboardingPlanId) {
            try {
                for (let cat of categories) {
                    const catTopics = topics.filter(t => t.category === cat.id);
                    for (let t of catTopics) {
                        const tSteps = stepsData[t.id] || [];
                        for (let st of tSteps) {
                            await axiosInstance.delete(`/onboarding/onboarding-steps/${st.id}/`);
                        }
                        await axiosInstance.delete(`/onboarding/topics/${t.id}/`);
                    }
                    await axiosInstance.delete(`/onboarding/categories/${cat.id}/`);
                }
                await axiosInstance.delete(`/onboarding/onboarding-plans/${onboardingPlanId}/`);
        } catch (error) {
                console.error('Fehler beim Löschen des Plans:', error);
        }
        }
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };

    const handleCancelClose = () => setOpenConfirmDialog(false);

    const handleDialogClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            handleCloseWithConfirmation();
        }
    };

    useEffect(() => {
        if (activeStep === 2 && onboardingPlanId) {
            axiosInstance.get('/onboarding/categories/')
                .then(res => {
                    const filtered = res.data.results.filter(c => c.onboarding_plan === onboardingPlanId);
                    setCategories(filtered);
                })
                .catch(e=>console.error(e));

            axiosInstance.get('/onboarding/topics/')
                .then(res => setTopics(res.data.results))
                .catch(e=>console.error(e));

            axiosInstance.get('/onboarding/onboarding-steps/')
                .then(res => {
                    const data = res.data.results;
                    const stepsByTopic = data.reduce((acc, step) => {
                        if(!acc[step.topic]) acc[step.topic]=[];
                        acc[step.topic].push(step);
                        return acc;
                    },{});
                    for(const tId in stepsByTopic){
                        stepsByTopic[tId].sort((a,b)=>a.order-b.order);
                    }
                    setStepsData(stepsByTopic);
                })
                .catch(e=>console.error(e));

            axiosInstance.get('/wiki/entries_all/')
                .then(res=>{
                    // Wir brauchen die entries nur zum Anzeigen in TopicDialog
                    // Werden dort dynamisch geladen, hier optional
                })
                .catch(e=>console.error(e));
        }
    },[activeStep,onboardingPlanId]);

    const handleSaveCategoryInStep3 = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            // Bearbeitete Kategorie speichern
            axiosInstance.patch(`/onboarding/categories/${editingCategory.id}/`, { name: categoryName })
                .then(response => {
                    setCategories(categories.map(c => c.id===editingCategory.id?response.data:c));
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch(error=>{
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error(error);
                });
        } else {
            // Neue Kategorie speichern
            axiosInstance.post('/onboarding/categories/', { name: categoryName, onboarding_plan: onboardingPlanId })
                .then(response=>{
                    setCategories([...categories,response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch(error=>{
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error(error);
                });
        }
    };

    // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Themen
    const handleAddTopicPlaceholderClick = (categoryId) => {
        setTopicName('');
        setEditingTopic(null);
        setSelectedCategory(categoryId);
        setSelectedWikiEntry('');
        setOpenTopicDialog(true);
    };

    const handleSaveTopic = (tName, wikiUrl) => {
        if (tName.trim()===''){
            setError('Themenname darf nicht leer sein.');
            return;
        }
        const catTopics = topics.filter(t => t.category === selectedCategory);
        const orderForNewTopic = editingTopic? editingTopic.order : catTopics.length;

        const payload = {
            name: tName,
            category: selectedCategory,
            order: orderForNewTopic,
            wiki_link: wikiUrl || null
        };

        if(editingTopic){
            axiosInstance.patch(`/onboarding/topics/${editingTopic.id}/`, payload)
                .then(response=>{
                    setTopics(topics.map(t=>t.id===editingTopic.id?response.data:t));
                    setEditingTopic(null);
                    setTopicName('');
                    setSelectedWikiEntry('');
                    setOpenTopicDialog(false);
                })
                .catch(e=>{
                    setError('Fehler beim Bearbeiten des Themas.');
                    console.error(e);
                });
        } else {
            axiosInstance.post('/onboarding/topics/', payload)
                .then(response=>{
                    setTopics([...topics,response.data]);
                    setTopicName('');
                    setSelectedWikiEntry('');
                    setError(null);
                    setOpenTopicDialog(false);
                })
                .catch(error=>{
                    setError('Fehler beim Speichern des Themas.');
                    console.error(error);
                });
        }
    };

    const handleDeleteTopic = (topicId)=>{
        axiosInstance.delete(`/onboarding/topics/${topicId}`)
            .then(()=>{
                setTopics(topics.filter(t=>t.id!==topicId));
            })
            .catch(e=>console.error(e));
    };

    const handleEditTopic = (topic)=>{
        setEditingTopic(topic);
        setTopicName(topic.name);
        setSelectedCategory(topic.category);

        // wiki_link extrahieren
        if(topic.wiki_link){
            // Ist schon im Effekt des Dialogs eingebaut
        }
        setOpenTopicDialog(true);
    };

    // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Schritten
    const handleAddStepPlaceholderClick = (topicId) => {
        setStepName('');
        setEditingStep(null);
        setSelectedTopic(topicId);
        setOpenStepDialog(true);
    };

    const handleSaveStep = () => {
        if (stepName.trim()===''){
            setError('Schrittbeschreibung darf nicht leer sein.');
            return;
        }
        const currentSteps = stepsData[selectedTopic]||[];
        const orderForNewStep = editingStep? editingStep.order : currentSteps.length;
        const payload = { description: stepName, topic: selectedTopic, order: orderForNewStep };

        if(editingStep){
            axiosInstance.patch(`/onboarding/onboarding-steps/${editingStep.id}/`, payload)
                .then(response=>{
                    const updated = currentSteps.map(s=>s.id===editingStep.id?response.data:s);
                    setStepsData(prev=>({...prev,[selectedTopic]:updated}));
                    setEditingStep(null);
                    setStepName('');
                    setOpenStepDialog(false);
                })
                .catch(e=>{
                    setError('Fehler beim Bearbeiten des Schritts.');
                    console.error(e);
                });
        } else {
            axiosInstance.post('/onboarding/onboarding-steps/', payload)
                .then(response=>{
                    const updated = [...currentSteps,response.data];
                    setStepsData(prev=>({...prev,[selectedTopic]:updated}));
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);
                })
                .catch(error=>{
                    setError('Fehler beim Speichern des Schritts.');
                    console.error(error);
                });
        }
    };

    const handleDeleteStep = (stepId) => {
        for(const tId in stepsData){
            const arr = stepsData[tId];
            const idx = arr.findIndex(s=>s.id===stepId);
            if(idx!==-1){
        axiosInstance.delete(`/onboarding/onboarding-steps/${stepId}`)
                    .then(()=>{
                        arr.splice(idx,1);
                        arr.forEach((s,i)=>s.order=i);
                        setStepsData(prev=>({...prev,[tId]:arr}));
            })
                    .catch(e=>console.error(e));
                break;
            }
        }
    };

    const handleEditStep = (step)=>{
        setEditingStep(step);
        setStepName(step.description);
        setSelectedTopic(step.topic);
        setOpenStepDialog(true);
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setOnboardingPlanId(null);
        setCategories([]);
        setCategoryName('');
        setTopics([]);
        setTopicName('');
        setStepsData({});
        setStepName('');
        setError(null);
        setSelectedCategory(null);
        setSelectedTopic(null);
        setOpenCategoryDialog(false);
        setOpenTopicDialog(false);
        setOpenStepDialog(false);
        setEditingCategory(null);
        setEditingTopic(null);
        setEditingStep(null);
        setSelectedWikiEntry('');
    };

    const reorderWithinGroup = (items, groupField) => {
        const grouped = items.reduce((acc, item) => {
            const g = item[groupField];
            if (!acc[g]) acc[g] = [];
            acc[g].push(item);
            return acc;
        }, {});

        for (const g in grouped) {
            grouped[g].sort((a,b)=>a.order-b.order);
            grouped[g] = grouped[g].map((it, idx) => ({...it, order: idx}));
        }

        return Object.values(grouped).flat();
    };

    const updateCategoryOrder = async (categories) => {
        try {
            const updated = categories.map((cat) => ({ id: cat.id, order: cat.order }));
            await axiosInstance.post('/onboarding/categories/update_order/', { categories: updated });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Kategorienreihenfolge:', error);
        }
    };

    const updateTopicOrder = async (topics) => {
        try {
            const updated = topics.map((t) => ({ id: t.id, order: t.order }));
            await axiosInstance.post('/onboarding/topics/update_order/', { topics: updated });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Topic-Reihenfolge:', error);
                    }
    };

    const updateStepOrder = async (stepsArray, topicId) => {
        try {
            const updated = stepsArray.map((s) => ({ id: s.id, order: s.order }));
            await axiosInstance.post('/onboarding/steps/update_order/', { steps: updated });
            } catch (error) {
            console.error('Fehler beim Aktualisieren der Steps-Reihenfolge:', error);
        }
    };

    const [openTopicsObj, setOpenTopicsObj] = useState({});

    const handleToggleTopicState = (topicId) => {
        setOpenTopicsObj(prev => ({...prev, [topicId]: !prev[topicId]}));
    };

    const onDragEnd = async (result) => {
        const { source, destination, type } = result;

        if (!destination) return;

        if (type === 'category') {
            if (source.index === destination.index) return;

            const reorderedCategories = Array.from(categories);
            const [movedCat] = reorderedCategories.splice(source.index, 1);
            reorderedCategories.splice(destination.index, 0, movedCat);
            reorderedCategories.forEach((c,i)=>c.order=i);

            setCategories(reorderedCategories);
            await updateCategoryOrder(reorderedCategories);

        } else if (type === 'topic') {
            if (source.droppableId !== destination.droppableId) return;
            const catId = parseInt(source.droppableId,10);
            const catTopics = topics.filter(t=>t.category===catId).sort((a,b)=>a.order-b.order);
            const [movedTopic] = catTopics.splice(source.index,1);
            catTopics.splice(destination.index,0,movedTopic);
            catTopics.forEach((t,i)=>t.order=i);

            const otherTopics = topics.filter(t=>t.category!==catId);
            const updatedTopics = [...otherTopics,...catTopics];
            setTopics(updatedTopics);
            await updateTopicOrder(updatedTopics);

        } else if (type === 'step') {
            if (source.droppableId !== destination.droppableId) return;
            const topicId = parseInt(source.droppableId,10);
            const currentSteps = stepsData[topicId]||[];
            const [movedStep] = currentSteps.splice(source.index,1);
            currentSteps.splice(destination.index,0,movedStep);
            currentSteps.forEach((s,i)=>s.order=i);
            setStepsData(prev=>({...prev,[topicId]:currentSteps}));
            await updateStepOrder(currentSteps, topicId);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
            <Dialog
                open={open}
                    onClose={handleDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Neuen Onboarding-Plan hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Box component="form" sx={{ width: '100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Name des Onboarding-Plans"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Beschreibung"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>Kategorien hinzufügen</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <TextField
                                        label="Kategoriename"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        fullWidth
                                    />
                                        <IconButton color="primary" onClick={()=>{
                                            if(categoryName.trim()!==''){
                                                axiosInstance.post('/onboarding/categories/', { name: categoryName, onboarding_plan: onboardingPlanId })
                                                    .then(response=>{
                                                        setCategories([...categories, response.data]);
                                                        setCategoryName('');
                                                    })
                                                    .catch(error=>console.error('Fehler beim Hinzufügen der Kategorie:', error))
                                            }
                                        }}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                    <Droppable droppableId="categories" type="category">
                                        {(provided) => (
                                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                                {categories.map((cat, index) => (
                                                    <Draggable key={cat.id} draggableId={`category-${cat.id}`} index={index}>
                                                        {(provided) => (
                                                            <Box
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2, backgroundColor: '#fafafa' }}
                                                            >
                                            <ListItem
                                                secondaryAction={
                                                    <>
                                                                            <IconButton edge="end" aria-label="edit" onClick={()=>{
                                                                                setEditingCategory(cat);
                                                                                setCategoryName(cat.name);
                                                                                setOpenCategoryDialog(true);
                                                                            }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                                            <IconButton edge="end" aria-label="delete" onClick={()=> {
                                                                                axiosInstance.delete(`/onboarding/categories/${cat.id}/`)
                                                                                    .then(()=>setCategories(categories.filter(c=>c.id!==cat.id)))
                                                                                    .catch(error=>console.error(error))
                                                                            }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                                    <ListItemText primary={cat.name} />
                                            </ListItem>
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                    ))}
                                                {provided.placeholder}
                                </List>
                                        )}
                                    </Droppable>
                            </Box>
                        )}
                        {activeStep === 2 && onboardingPlanId && (
                            <Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                    <Droppable droppableId="categories" type="category">
                                        {(provided) => (
                                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                                {categories.length > 0 ? categories.map((category, catIndex) => (
                                            <CategoryItem
                                                key={category.id}
                                                        category={{...category, order:catIndex}}
                                                        selectedCategory={selectedCategory}
                                                        setSelectedCategory={setSelectedCategory}
                                                        topics={topics}
                                                        steps={stepsData}
                                                        openTopics={openTopicsState}
                                                        handleToggleTopic={id=>setOpenTopicsState(prev=>({...prev,[id]:!prev[id]}))}
                                                        handleDeleteCategory={(id)=>{
                                                            axiosInstance.delete(`/onboarding/categories/${id}/`)
                                                                .then(()=>setCategories(categories.filter(c=>c.id!==id)))
                                                                .catch(error=>console.error(error))
                                                        }}
                                                        handleEditCategory={(cat)=>{
                                                            setEditingCategory(cat);
                                                            setCategoryName(cat.name);
                                                            setOpenCategoryDialog(true);
                                                        }}
                                                handleDeleteTopic={handleDeleteTopic}
                                                        handleEditTopic={(t)=>{
                                                            setEditingTopic(t);
                                                            setTopicName(t.name);
                                                            setSelectedCategory(t.category);
                                                            // wiki_link wird im TopicDialog selbst ermittelt
                                                            setSelectedWikiEntry(''); 
                                                            setOpenTopicDialog(true);
                                                        }}
                                                handleDeleteStep={handleDeleteStep}
                                                handleEditStep={handleEditStep}
                                                handleAddTopicPlaceholderClick={handleAddTopicPlaceholderClick}
                                                handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                                        wikiEntries={[]}
                                            />
                                        )) : (
                                            <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                                        )}
                                                {provided.placeholder}
                                    </List>
                                        )}
                                    </Droppable>
                                    
                                    <Box
                                        sx={{
                                            border: '2px dashed #ddd',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            color: '#888',
                                            mt: 2,
                                            width: '100%',
                                            '&:hover': { backgroundColor: '#f5f5f5' },
                                            boxSizing: 'border-box',
                                        }}
                                        onClick={() => {
                                            setEditingCategory(null);
                                            setCategoryName('');
                                            setError(null);
                                            setOpenCategoryDialog(true);
                                        }}
                                    >
                                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                        Neue Kategorie anlegen
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                        <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined">Zurück</Button>
                        <Button onClick={handleCloseWithConfirmation} variant="outlined" color="secondary">Abbrechen</Button>
                    {activeStep === steps.length - 1 ? (
                            <Button onClick={handleSaveAndClose} variant="contained" color="primary">Fertigstellen</Button>
                    ) : (
                            <Button onClick={handleNext} variant="contained" color="primary" disabled={activeStep === 1 && categories.length === 0}>
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            </DragDropContext>

            <Dialog open={openConfirmDialog} onClose={handleCancelClose}>
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">Nein</Button>
                    <Button onClick={handleConfirmClose} color="secondary">Ja, abbrechen</Button>
                </DialogActions>
            </Dialog>

            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => {
                    setOpenCategoryDialog(false);
                    setEditingCategory(null);
                    setCategoryName('');
                    setError(null);
                }}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategoryInStep3}
            />

            <TopicDialog
                open={openTopicDialog}
                onClose={() => {
                    setOpenTopicDialog(false);
                    setEditingTopic(null);
                    setTopicName('');
                    setSelectedWikiEntry('');
                    setError(null);
                }}
                topicName={topicName}
                setTopicName={setTopicName}
                handleSaveTopic={handleSaveTopic}
                editingTopic={editingTopic}
            />

            <StepDialog
                open={openStepDialog}
                onClose={() => {
                    setOpenStepDialog(false);
                    setEditingStep(null);
                    setStepName('');
                    setError(null);
                }}
                stepName={stepName}
                setStepName={setStepName}
                handleSaveStep={handleSaveStep}
            />
        </>
    );
};

export default OnboardingHinzufuegen;
