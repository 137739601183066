// pages/retour/Retour.js

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search'; // Lupen-Icon
import FullscreenIcon from '@mui/icons-material/Fullscreen'; // Fullscreen-Icon
import CloseIcon from '@mui/icons-material/Close'; // Close-Icon
import Layout from '../../../components/layout/Scorecards/UnternehmenMonatLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import RetourCard from '../../../components/card/scorecardsmonat/RetourCard'; // Neue Card-Komponente
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import BigTableCard from '../../../components/card/BigTableCard';
import LineChartVariableYAxis from '../../../components/charts/LineChartVariableYAxis'; // Sicherstellen, dass die richtige Chart-Komponente importiert ist

export default function Retour() {
  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [warengruppenData, setWarengruppenData] = useState([]);
  const [yAxisMax, setYAxisMax] = useState(100);
  const [yAxisSliderMax, setYAxisSliderMax] = useState(100);
  const [combinedLineData, setCombinedLineData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  // Zustände für die Dialogs
  const [openTableModal, setOpenTableModal] = useState(false);
  const [openFullscreenChart, setOpenFullscreenChart] = useState(false);

  // Zustand für die Fenstergröße
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchMonthlyWarengruppenData();
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Fehler beim Abrufen der Datumsoptionen:', error);
    }
  };

  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  // Hilfsfunktion zum Parsen von 'YYYY.MM' in Date-Objekte
  function parseYYYYMM(str) {
    const [year, month] = str.split('.').map(Number);
    return new Date(year, month - 1);
  }

  const fetchMonthlyWarengruppenData = async () => {
    const last6MonthsArray = getLast6Months(filterState.datum?.value);

    try {
      const promises = last6MonthsArray.map((month) =>
        axiosInstance
          .get('/scorecards_month/ware-warengruppe-monat/', {
            params: {
              jahrMonat: month,
              ...(filterState.bestehendeFlaeche && { vorjahr: true }),
            },
          })
          .then((response) => ({ month, data: response.data.results }))
      );

      const results = await Promise.all(promises);

      const aggregatedData = {};

      results.forEach(({ month, data }) => {
        data.forEach((item) => {
          const warengruppennummer = item.warengruppennummer;
          const warengruppe = item.warengruppe;
          const retourwert = item.retourwert || 0;
          const retourwertA = item.retourwertA || 0;
          const retourwertBC = item.retourwertBC || 0;
          const lieferwert = item.lieferwert || 0;
          const lieferwertA = item.lieferwertA || 0;
          const lieferwertBC = item.lieferwertBC || 0;
          const korrekturwert = item.korrekturwert || 0;
          const korrekturwertA = item.korrekturwertA || 0;
          const korrekturwertBC = item.korrekturwertBC || 0;

          if (!aggregatedData[warengruppennummer]) {
            aggregatedData[warengruppennummer] = {
              warengruppe,
              data: {},
              retourwert: 0,
              retourwertA: 0,
              retourwertBC: 0,
              lieferwert: 0,
              lieferwertA: 0,
              lieferwertBC: 0,
              korrekturwert: 0,
              korrekturwertA: 0,
              korrekturwertBC: 0,
              verkaufswert: 0,
              verkaufswertA: 0,
              verkaufswertBC: 0,
            };
          }

          if (!aggregatedData[warengruppennummer].data[month]) {
            aggregatedData[warengruppennummer].data[month] = {
              retourwert: 0,
              retourwertA: 0,
              retourwertBC: 0,
              lieferwert: 0,
              lieferwertA: 0,
              lieferwertBC: 0,
              korrekturwert: 0,
              korrekturwertA: 0,
              korrekturwertBC: 0,
              verkaufswert: 0,
              verkaufswertA: 0,
              verkaufswertBC: 0,
            };
          }

          // Akkumulieren der Werte
          aggregatedData[warengruppennummer].data[month].retourwert += retourwert;
          aggregatedData[warengruppennummer].data[month].retourwertA += retourwertA;
          aggregatedData[warengruppennummer].data[month].retourwertBC += retourwertBC;
          aggregatedData[warengruppennummer].data[month].lieferwert += lieferwert;
          aggregatedData[warengruppennummer].data[month].lieferwertA += lieferwertA;
          aggregatedData[warengruppennummer].data[month].lieferwertBC += lieferwertBC;
          aggregatedData[warengruppennummer].data[month].korrekturwert += korrekturwert;
          aggregatedData[warengruppennummer].data[month].korrekturwertA += korrekturwertA;
          aggregatedData[warengruppennummer].data[month].korrekturwertBC += korrekturwertBC;

          // Berechnung der Verkaufsmenge auf monatlicher Ebene
          aggregatedData[warengruppennummer].data[month].verkaufswert +=
            lieferwert + korrekturwert;
          aggregatedData[warengruppennummer].data[month].verkaufswertA +=
            lieferwertA + korrekturwertA;
          aggregatedData[warengruppennummer].data[month].verkaufswertBC +=
            lieferwertBC + korrekturwertBC;

          // Aktualisieren der akkumulierten Werte auf Warengruppenebene
          aggregatedData[warengruppennummer].retourwert += retourwert;
          aggregatedData[warengruppennummer].retourwertA += retourwertA;
          aggregatedData[warengruppennummer].retourwertBC += retourwertBC;
          aggregatedData[warengruppennummer].lieferwert += lieferwert;
          aggregatedData[warengruppennummer].lieferwertA += lieferwertA;
          aggregatedData[warengruppennummer].lieferwertBC += lieferwertBC;
          aggregatedData[warengruppennummer].korrekturwert += korrekturwert;
          aggregatedData[warengruppennummer].korrekturwertA += korrekturwertA;
          aggregatedData[warengruppennummer].korrekturwertBC += korrekturwertBC;

          // Berechnung der Retourenquoten auf Warengruppenebene
          aggregatedData[warengruppennummer].retourQuote =
            (aggregatedData[warengruppennummer].lieferwert +
              aggregatedData[warengruppennummer].korrekturwert) > 0
              ? (
                  (aggregatedData[warengruppennummer].retourwert /
                    (aggregatedData[warengruppennummer].lieferwert +
                      aggregatedData[warengruppennummer].korrekturwert)) *
                  100
                ).toFixed(2)
              : '0.00';

          aggregatedData[warengruppennummer].retourQuoteA =
            (aggregatedData[warengruppennummer].lieferwertA +
              aggregatedData[warengruppennummer].korrekturwertA) > 0
              ? (
                  (aggregatedData[warengruppennummer].retourwertA /
                    (aggregatedData[warengruppennummer].lieferwertA +
                      aggregatedData[warengruppennummer].korrekturwertA)) *
                  100
                ).toFixed(2)
              : '0.00';

          aggregatedData[warengruppennummer].retourQuoteBC =
            (aggregatedData[warengruppennummer].lieferwertBC +
              aggregatedData[warengruppennummer].korrekturwertBC) > 0
              ? (
                  (aggregatedData[warengruppennummer].retourwertBC /
                    (aggregatedData[warengruppennummer].lieferwertBC +
                      aggregatedData[warengruppennummer].korrekturwertBC)) *
                  100
                ).toFixed(2)
              : '0.00';
        });
      });

      const formattedData = Object.values(aggregatedData)
        .filter((warengruppe) => parseFloat(warengruppe.retourQuote) > 0) // Nur Warengruppen mit retourQuote > 0
        .map((warengruppe) => ({
          warengruppe: warengruppe.warengruppe,
          data: last6MonthsArray.map((month) => ({
            name: month,
            retourQuote: warengruppe.data[month]?.lieferwert
              ? (
                  (warengruppe.data[month].retourwert /
                    (warengruppe.data[month].lieferwert +
                      warengruppe.data[month].korrekturwert)) *
                  100
                ).toFixed(2)
              : '0.00',
            retourQuoteA: warengruppe.data[month]?.lieferwertA
              ? (
                  (warengruppe.data[month].retourwertA /
                    (warengruppe.data[month].lieferwertA +
                      warengruppe.data[month].korrekturwertA)) *
                  100
                ).toFixed(2)
              : '0.00',
            retourQuoteBC: warengruppe.data[month]?.lieferwertBC
              ? (
                  (warengruppe.data[month].retourwertBC /
                    (warengruppe.data[month].lieferwertBC +
                      warengruppe.data[month].korrekturwertBC)) *
                  100
                ).toFixed(2)
              : '0.00',
            // Hinzufügen der Mengenfelder
            retourmenge: warengruppe.data[month]?.retourwert || 0,
            liefermenge: warengruppe.data[month]?.lieferwert || 0,
            korrekturmenge: warengruppe.data[month]?.korrekturwert || 0,
            verkaufsmenge: warengruppe.data[month]?.verkaufswert || 0,
            retourmengeA: warengruppe.data[month]?.retourwertA || 0,
            liefermengeA: warengruppe.data[month]?.lieferwertA || 0,
            korrekturmengeA: warengruppe.data[month]?.korrekturwertA || 0,
            verkaufsmengeA: warengruppe.data[month]?.verkaufswertA || 0,
            retourmengeBC: warengruppe.data[month]?.retourwertBC || 0,
            liefermengeBC: warengruppe.data[month]?.lieferwertBC || 0,
            korrekturmengeBC: warengruppe.data[month]?.korrekturwertBC || 0,
            verkaufsmengeBC: warengruppe.data[month]?.verkaufswertBC || 0,
          })),
          retourQuote: warengruppe.retourQuote,
          retourQuoteA: warengruppe.retourQuoteA,
          retourQuoteBC: warengruppe.retourQuoteBC,
          // Gesamtmengen hinzufügen
          retourmenge: warengruppe.retourwert,
          liefermenge: warengruppe.lieferwert,
          korrekturmenge: warengruppe.korrekturwert,
          verkaufsmenge: warengruppe.lieferwert + warengruppe.korrekturwert,
          verkaufsmengeA: warengruppe.lieferwertA + warengruppe.korrekturwertA,
          verkaufsmengeBC: warengruppe.lieferwertBC + warengruppe.korrekturwertBC,
        }));

      console.log('Aggregated Data:', aggregatedData); // Zum Debuggen
      console.log('Formatted Data:', formattedData); // Zum Debuggen

      setWarengruppenData(formattedData);

      // Daten für das Liniendiagramm vorbereiten (für Recharts)
      const combinedData = last6MonthsArray.map((month) => {
        const entry = { name: month };
        formattedData.forEach((warengruppe) => {
          const dataPoint = warengruppe.data.find((d) => d.name === month);
          entry[warengruppe.warengruppe] = dataPoint ? parseFloat(dataPoint.retourQuote) : 0;
        });
        return entry;
      });

      console.log('Combined Line Data:', combinedData); // Zum Debuggen
      setCombinedLineData(combinedData);

      // Berechnung des maximalen Rückgabewertes über alle Monate hinweg
      const maxDataValue = Math.max(
        ...formattedData.flatMap((wg) => [
          ...wg.data.map((d) => parseFloat(d.retourQuote)),
          ...wg.data.map((d) => parseFloat(d.retourQuoteA)),
          ...wg.data.map((d) => parseFloat(d.retourQuoteBC)),
        ])
      );
      const bufferedMax = Math.ceil(maxDataValue * 1.1); // Rundet auf die nächste ganze Zahl
      setYAxisSliderMax(bufferedMax);
      setYAxisMax(bufferedMax);

      // Tabellenspalten vorbereiten
      const newTableColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        ...last6MonthsArray.map((month) => ({
          accessorKey: month,
          header: month,
        })),
        { accessorKey: 'retourQuote', header: 'Retourenquote (%)' },
        { accessorKey: 'retourQuoteA', header: 'Retourenquote A (%)' },
        { accessorKey: 'retourQuoteBC', header: 'Retourenquote BC (%)' },
        // Verkaufsmenge-Spalten hinzufügen
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeA', header: 'Verkaufsmenge A' },
        { accessorKey: 'verkaufsmengeBC', header: 'Verkaufsmenge BC' },
      ];
      setTableColumns(newTableColumns);

      // Tabellendaten vorbereiten
      const newTableData = formattedData.map((warengruppe) => {
        const row = { warengruppe: warengruppe.warengruppe };
        warengruppe.data.forEach((point) => {
          row[point.name] = point.retourQuote;
        });
        row['retourQuote'] = warengruppe.retourQuote;
        row['retourQuoteA'] = warengruppe.retourQuoteA;
        row['retourQuoteBC'] = warengruppe.retourQuoteBC;
        // Verkaufsmenge hinzufügen
        row['verkaufsmenge'] = warengruppe.verkaufsmenge;
        row['verkaufsmengeA'] = warengruppe.verkaufsmengeA;
        row['verkaufsmengeBC'] = warengruppe.verkaufsmengeBC;
        return row;
      });
      setTableData(newTableData);
    } catch (error) {
      console.error('Fehler beim Abrufen der monatlichen Warengruppen-Daten:', error);
    }
  };

  const handleRowClick = (row) => {
    console.log('Zeile geklickt:', row);
    // Hier können Sie eine Aktion beim Klicken auf eine Tabellenzeile implementieren
  };

  return (
    <Layout>
      {/* Header und Filter */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title="Retouren"
            subtitle="Übersicht über die Retouren im Sortiment"
            Icon={BarChartIcon}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DropdownFilter
            label="Datum"
            options={datumOptions}
            value={filterState.datum}
            onChange={handleDatumChange}
          />
        </Box>
      </Box>

      {/* Diagrammsektion */}
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* Rechte Spalte: Liniendiagramm über die gesamte Breite */}
          <Grid item xs={12} md={12}>
            <Box sx={{ height: '100%' }}>
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6">
                      Retourenquoten der letzten 6 Monate
                    </Typography>
                  }
                  action={
                    <Box>
                      {/* Info-Icon */}
                      <Tooltip
                        title="Entwicklung der Retourenquoten pro Warengruppe über die letzten 6 Monate"
                        arrow
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Lupen-Icon */}
                      <Tooltip
                        title="Details in Tabellenform anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenTableModal(true)}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Fullscreen-Icon */}
                      <Tooltip
                        title="Diagramm im Vollbildmodus anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenFullscreenChart(true)}>
                          <FullscreenIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {combinedLineData && combinedLineData.length > 0 ? (
                    <LineChartVariableYAxis
                      data={combinedLineData} // Korrekte Datenübergabe
                      dataKeys={warengruppenData.map(wg => wg.warengruppe)} // DatenKeys korrekt setzen
                      xAxisKey="name"
                      yAxisMax={yAxisMax} // Mit Puffer gesetzt
                      yAxisMin={0}
                      height={300} // Gleiche feste Höhe
                      width="100%"
                      lineColors={warengruppenData.map((_, index) => {
                        const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c', '#a4de6c'];
                        return colors[index % colors.length];
                      })}
                      legendNames={[]} // Legende entfernt
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Keine Daten verfügbar
                    </Typography>
                  )}
                </CardContent>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Fullscreen Dialog für das LineChart */}
      <Dialog
        open={openFullscreenChart}
        onClose={() => setOpenFullscreenChart(false)}
        fullScreen
        aria-labelledby="fullscreen-chart-title"
      >
        <DialogTitle id="fullscreen-chart-title">
          Retourenquoten der letzten 6 Monate
          <IconButton
            aria-label="schließen"
            onClick={() => setOpenFullscreenChart(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {combinedLineData && combinedLineData.length > 0 ? (
            <LineChartVariableYAxis
              data={combinedLineData}
              dataKeys={warengruppenData.map(wg => wg.warengruppe)}
              xAxisKey="name"
              yAxisMax={yAxisMax} // Mit Puffer gesetzt
              yAxisMin={0}
              height={windowHeight * 0.8} // Dynamische Höhe basierend auf Fenstergröße
              width="100%"
              lineColors={warengruppenData.map((_, index) => {
                const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c', '#a4de6c'];
                return colors[index % colors.length];
              })}
              legendNames={[]} // Legende entfernt
            />
          ) : (
            <Typography variant="body2" color="text.secondary">
              Keine Daten verfügbar
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFullscreenChart(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog für die Tabelle */}
      <Dialog
        open={openTableModal}
        onClose={() => setOpenTableModal(false)}
        fullWidth
        maxWidth="lg" // Passe die Größe nach Bedarf an
      >
        <DialogTitle>Retourendaten in Tabellenform</DialogTitle>
        <DialogContent>
          <BigTableCard
            title="Retourendaten"
            data={tableData}
            columns={tableColumns}
            onRowClick={handleRowClick}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTableModal(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Datenkarten */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {warengruppenData
          .filter((wg) => parseFloat(wg.retourQuote) > 0) // Filterung hier
          .map((warengruppe) => (
            <Grid item xs={12} sm={6} md={4} key={warengruppe.warengruppe}>
              <RetourCard
                data={warengruppe.data}
                warengruppe={warengruppe.warengruppe}
                selectedMonth={filterState.datum?.value}
                retourQuoteA={warengruppe.retourQuoteA}
                retourQuoteBC={warengruppe.retourQuoteBC}
                verkaufsmenge={warengruppe.verkaufsmenge} // Hinzugefügt
                verkaufsmengeA={warengruppe.verkaufsmengeA} // Hinzugefügt
                verkaufsmengeBC={warengruppe.verkaufsmengeBC} // Hinzugefügt
              />
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
}
