import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Grid, Paper, Typography, Button, TextField, IconButton, Dialog,
  DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem,
  InputLabel, Collapse, Stack
} from '@mui/material';
import Layout from '../../../components/layout/Erfolgsplaner/ErfolgsplanerLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import axiosInstance from '../../../services/axiosInstance';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import dayjs from 'dayjs';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FilialeSummary from '../../../components/documentation/FilialeSummary';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../provider/FilterProvider';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { useAuth } from '../../../provider/authProvider'; // Falls du den User für Check-Ins brauchst
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Wir importieren den SingleDatePicker
import SingleDatePicker from '../../../components/filter/SingleDatePicker';

// Hilfsfunktion: Formatierung im deutschen Stil DD.MM.YYYY
function formatDateGerman(dateString) {
  if (!dateString) return '-';
  return dayjs(dateString).format('DD.MM.YYYY');
}

const pillStyle = {
  borderRadius: '50px',
};

const TARGET_CATEGORIES = [
  { value: 'performance', label: 'Performance' },
  { value: 'personal', label: 'Personal' },
  { value: 'ware', label: 'Ware' },
  { value: 'prozessqualitaet', label: 'Prozessqualität' },
  { value: 'sonstiges', label: 'Sonstiges' },
];

const TARGET_STATUS = [
  { value: 'nicht_begonnen', label: 'Nicht begonnen' },
  { value: 'im_plan', label: 'Im Plan' },
  { value: 'hinter', label: 'Hinter' },
  { value: 'gefaehrdet', label: 'Gefährdet' },
  { value: 'geschlossen', label: 'Geschlossen' },
];

const categoryKennzahlenMap = {
  performance: [
    { value: 'umsatz', label: 'Umsatz' },
    { value: 'kundenanzahl', label: 'Kundenanzahl' },
    { value: 'kundenbon', label: 'Kundenbon' },
    { value: 'leistung', label: 'Leistung' },
  ],
  personal: [
    { value: 'krankenquote', label: 'Krankenquote' },
    { value: 'fluktuation', label: 'Fluktuation' },
    { value: 'bedarf', label: 'Bedarf' },
    { value: 'auslastung', label: 'Auslastung' },
  ],
  ware: [
    { value: 'wachstum', label: 'Wachstum' },
    { value: 'verfuegbarkeit', label: 'Verfügbarkeit' },
    { value: 'retoure_a', label: 'Retoure A' },
    { value: 'retoure_bc', label: 'Retoure B/C' },
  ],
  prozessqualitaet: [
    { value: 'filialcheck', label: 'Filialcheck' },
    { value: 'qualitaet', label: 'Qualität' },
  ],
  sonstiges: [
    { value: 'sonstiges', label: 'Sonstiges' }
  ],
};

// Gibt einen Link zu einer Detailseite zurück, falls für die Kennzahl vorhanden.
// Wenn leer (z.B. "sonstiges"), returns "".
function getLinkForKennzahl(value) {
  switch(value) {
    // Performance
    case 'umsatz':
      return '/scorecards/performance/umsatz';
    case 'kundenanzahl':
      return '/scorecards/performance/kundenanzahl';
    case 'kundenbon':
      return '/scorecards/performance/kundenbon';
    case 'leistung':
      return '/scorecards/performance/leistung';

    // Personal
    case 'krankenquote':
      return '/scorecards/personal/krankenquote';
    case 'fluktuation':
      return '/scorecards/personal/fluktuation';
    case 'bedarf':
      return '/scorecards/personal/bedarf';
    case 'auslastung':
      return '/scorecards/personal/auslastung';

    // Ware
    case 'wachstum':
      return '/scorecards/ware/wachstum';
    case 'verfuegbarkeit':
      return '/scorecards/ware/verfuegbarkeit';
    case 'retoure_a':
      return '/scorecards/ware/retoure-a';
    case 'retoure_bc':
      return '/scorecards/ware/retoure-bc';

    // Prozessqualität
    case 'filialcheck':
      return '/filialcheck/analyse/index';
    case 'qualitaet':
      return '/qualitaet/overview/index';

    default:
      // Sonstiges (kein Link)
      return '';
  }
}

function getStatusLabel(value) {
  const s = TARGET_STATUS.find(st => st.value === value);
  return s ? s.label : value;
}

function getStatusStyles(value) {
  switch(value) {
    case 'nicht_begonnen': return { backgroundColor: '#E0E0E0', color: '#000' };
    case 'im_plan': return { backgroundColor: '#C5E1A5', color: '#000' };
    case 'hinter': return { backgroundColor: '#FFF9C4', color: '#000' };
    case 'gefaehrdet': return { backgroundColor: '#EF9A9A', color: '#000' };
    case 'geschlossen': return { backgroundColor: '#90CAF9', color: '#000' };
    default: return { backgroundColor: '#E0E0E0', color: '#000' };
  }
}

function getCategoryLabel(value) {
  const c = TARGET_CATEGORIES.find(ct => ct.value === value);
  return c ? c.label : value;
}

function getKennzahlLabel(value) {
  for (const cat in categoryKennzahlenMap) {
    const found = categoryKennzahlenMap[cat].find(k => k.value === value);
    if (found) return found.label;
  }
  return value;
}

const Overview = () => {
  const { filterState, handleFilialeChange } = useContext(FilterContext);
  const navigate = useNavigate();
  const { user } = useAuth(); // Falls du den User für Check-Ins brauchst

  const [targets, setTargets] = useState([]);
  const [filialeOptions, setFilialeOptions] = useState([]);

  // NEU: State, um geschlossene Ziele auszublenden/anzuzeigen
  const [showClosed, setShowClosed] = useState(false);

  const [openTargetDialog, setOpenTargetDialog] = useState(false);
  const [newTarget, setNewTarget] = useState({
    id: null,
    filialnummer: '',
    category: '',
    title: '',
    description: '',
    deadline: '',
    status: 'nicht_begonnen',
    kennzahl: '',
    zielwert: ''
  });

  const [selectedFilialeDialog, setSelectedFilialeDialog] = useState('');
  const [expandedTargets, setExpandedTargets] = useState(new Set());
  const [newCheckInNotes, setNewCheckInNotes] = useState({});
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [summaryFiliale, setSummaryFiliale] = useState(null);
  const [summaryDatum, setSummaryDatum] = useState('');

  useEffect(() => {
    loadTargets();
    loadFilialeOptions();
  }, [filterState.filiale]);

  const loadTargets = async () => {
    try {
      const params = {};
      if (filterState.filiale && filterState.filiale.value) {
        params.filiale = filterState.filiale.value;
      }
      const response = await axiosInstance.get('/targets/targets/', { params });
      setTargets(response.data.results || response.data || []);
    } catch (error) {
      console.error('Fehler beim Laden der Targets:', error);
      setTargets([]);
    }
  };

  const loadFilialeOptions = async () => {
    try {
      const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
      const opts = filialeResponse.data.map((item) => ({
        label: item.filiale,
        value: item.filialnummer,
      }));
      setFilialeOptions(opts);
    } catch (error) {
      console.error('Fehler beim Laden der Filialoptionen:', error);
      setFilialeOptions([]);
    }
  };

  const handleOpenTargetDialog = (target = null) => {
    if (target) {
      // Ziel bearbeiten
      setNewTarget({
        id: target.id,
        filialnummer: target.filialnummer,
        category: target.category,
        title: target.title,
        description: target.description || '',
        // Falls schon ein Wert vorhanden, in String lassen (z. B. "2024-12-10")
        deadline: target.deadline || '',
        status: target.status,
        kennzahl: target.kennzahl,
        zielwert: target.zielwert !== null ? String(target.zielwert) : ''
      });
      setSelectedFilialeDialog('');
    } else {
      // Neues Ziel anlegen
      let initialFilialnummer = '';
      if (filterState.filiale && filterState.filiale.value) {
        initialFilialnummer = filterState.filiale.value;
      }
      setNewTarget({
        id: null,
        filialnummer: initialFilialnummer,
        category: '',
        title: '',
        description: '',
        deadline: '',
        status: 'nicht_begonnen',
        kennzahl: '',
        zielwert: ''
      });
      setSelectedFilialeDialog(initialFilialnummer);
    }
    setOpenTargetDialog(true);
  };

  const handleSaveTarget = async () => {
    const data = { ...newTarget };

    // Filialnummer anpassen (neu vs. bearbeiten)
    if (data.id) {
      data.filialnummer = Number(data.filialnummer);
    } else {
      if (filterState.filiale && filterState.filiale.value) {
        data.filialnummer = Number(filterState.filiale.value);
      } else {
        if (!selectedFilialeDialog) {
          alert("Bitte wählen Sie eine Filiale aus.");
          return;
        }
        data.filialnummer = Number(selectedFilialeDialog);
      }
    }

    // deadline leer -> null
    if (data.deadline === "") {
      data.deadline = null;
    }

    // zielwert leer -> null, sonst parseFloat
    if (data.zielwert === "") {
      data.zielwert = null;
    } else {
      const floatValue = parseFloat(data.zielwert);
      if (isNaN(floatValue)) {
        data.zielwert = null;
      } else {
        data.zielwert = floatValue;
      }
    }

    try {
      if (data.id) {
        await axiosInstance.patch(`/targets/targets/${data.id}/`, data);
      } else {
        await axiosInstance.post('/targets/targets/', data);
      }
      setOpenTargetDialog(false);
      loadTargets();
    } catch (error) {
      console.error('Fehler beim Speichern des Targets:', error);
    }
  };

  const handleDeleteTarget = async (target) => {
    const confirmDelete = window.confirm(`Möchten Sie das Target "${target.title}" wirklich löschen?`);
    if (!confirmDelete) return;

    try {
      await axiosInstance.delete(`/targets/targets/${target.id}/`);
      loadTargets();
    } catch (error) {
      console.error('Fehler beim Löschen des Targets:', error);
    }
  };

  const handleToggleExpand = (targetId) => {
    const newSet = new Set(expandedTargets);
    if (newSet.has(targetId)) {
      newSet.delete(targetId);
    } else {
      newSet.add(targetId);
    }
    setExpandedTargets(newSet);
  };

  const handleCheckInChange = (targetId, note) => {
    setNewCheckInNotes(prev => ({ ...prev, [targetId]: note }));
  };

  const handleSaveCheckIn = async (target) => {
    const note = newCheckInNotes[target.id] || '';
    if (!note) return;

    const data = {
      target: target.id,
      note: note,
      user: user?.username || user?.email || 'unbekannt'
    };

    try {
      await axiosInstance.post('/targets/checkins/', data);
      await loadTargets();
      setNewCheckInNotes(prev => ({ ...prev, [target.id]: '' }));
    } catch (error) {
      console.error('Fehler beim Speichern des CheckIn:', error);
    }
  };

  const handleOpenSummary = () => {
    if (!filterState.filiale || !filterState.filiale.value) {
      alert("Bitte wählen Sie eine Filiale aus, um die Zusammenfassung anzuzeigen.");
      return;
    }
    const lastMonth = dayjs().subtract(1, 'month').format('YYYY.MM');
    setSummaryFiliale(filterState.filiale.value);
    setSummaryDatum(lastMonth);
    setIsSummaryOpen(true);
  };

  // 1) Wir bestimmen, welche Targets angezeigt werden:
  //    - Wenn showClosed = false -> wir filtern alle Ziele mit status="geschlossen" aus
  //    - Sonst zeigen wir sie mit an
  const displayedTargets = showClosed
    ? targets
    : targets.filter((t) => t.status !== 'geschlossen');

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <PageHeader
              title="ErfolgsPlaner"
              subtitle="Verwalte die Ziele deiner Filialen."
              Icon={HailOutlinedIcon}
            />
          </Grid>

          {/* Filter (Filiale) & Buttons */}
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <DropdownFilter
                  label="Filiale"
                  options={filialeOptions}
                  value={filterState.filiale}
                  onChange={handleFilialeChange}
                />
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={1}>

                  {/* 2) Button, um showClosed zu toggeln */}
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    startIcon={<CheckBoxOutlinedIcon />}
                    onClick={() => setShowClosed((prev) => !prev)}
                    sx={{
                      ...pillStyle,
                      color: 'grey.600',
                      borderColor: 'grey.600'
                    }}                  >
                    {showClosed ? 'Geschlossene ausblenden' : 'Geschlossene einblenden'}
                  </Button>

                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    startIcon={<SummarizeOutlinedIcon />}
                    onClick={handleOpenSummary}
                    sx={{
                      ...pillStyle,
                      color: 'grey.600',
                      borderColor: 'grey.600'
                    }}
                  >
                    Gesprächspilot anzeigen
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                    onClick={() => handleOpenTargetDialog()}
                    sx={pillStyle}
                  >
                    Neues Ziel anlegen
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          {/* 3) Statt targets.map() => displayedTargets.map() */}
          {displayedTargets.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center" sx={{ mt: 2 }}>Keine Ziele vorhanden.</Typography>
            </Grid>
          ) : (
            displayedTargets.map((t) => {
              const isExpanded = expandedTargets.has(t.id);
              const statusStyles = getStatusStyles(t.status);
              const link = getLinkForKennzahl(t.kennzahl);

              return (
                <Grid item xs={12} key={t.id}>
                  <Paper sx={{ p: 2 }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" fontWeight="bold" sx={{ flex: 1 }}>
                        {t.title}
                      </Typography>
                      <Box display="flex" alignItems="center" gap={2} justifyContent="flex-end">
                        <Box display="flex" alignItems="center" gap={0.5} sx={{ minWidth: "110px", justifyContent: "flex-end" }}>
                          <CalendarMonthIcon sx={{ fontSize: '1rem' }} />
                          {/* ANZEIGE: Deutsches Datumsformat */}
                          <Typography variant="body2" noWrap>
                            {formatDateGerman(t.deadline)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: statusStyles.backgroundColor,
                            color: statusStyles.color,
                            px: 1,
                            py: 0.5,
                            borderRadius: '4px',
                            minWidth: "120px",
                            display: "flex",
                            justifyContent: "center",
                            whiteSpace: 'nowrap'
                          }}
                        >
                          <Typography variant="body2" sx={{ lineHeight: 1, overflow: 'hidden' }} noWrap>
                            {getStatusLabel(t.status)}
                          </Typography>
                        </Box>

                        {/* Expand / Collapse */}
                        <IconButton size="small" onClick={() => handleToggleExpand(t.id)}>
                          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                        {/* Bearbeiten */}
                        <IconButton size="small" onClick={() => handleOpenTargetDialog(t)}>
                          <EditIcon />
                        </IconButton>

                        {/* Löschen */}
                        <IconButton size="small" sx={{ color: 'grey.600' }} onClick={() => handleDeleteTarget(t)}>
                          <DeleteIcon />
                        </IconButton>

                        {/* Immer ein Link-Icon, bei leerem link => disabled */}
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (link) navigate(link);
                          }}
                          disabled={!link}
                        >
                          <LinkIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>Weitere Details</Typography>
                        <Typography variant="body2">Filiale: {t.filialnummer}</Typography>
                        <Typography variant="body2">Kategorie: {getCategoryLabel(t.category)}</Typography>
                        <Typography variant="body2">Kennzahl: {getKennzahlLabel(t.kennzahl)}</Typography>
                        <Typography variant="body2">
                          Zielwert: {t.zielwert !== null ? t.zielwert : '-'}
                        </Typography>
                        <Typography variant="body2">
                          Beschreibung: {t.description || '-'}
                        </Typography>

                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Check-Ins</Typography>
                        {t.checkins && t.checkins.length > 0 ? (
                          t.checkins.map(ci => (
                            <Box key={ci.id} sx={{ mb: 1 }}>
                              <Typography variant="body2">
                                {/* ANZEIGE: Deutsches Datumsformat für Check-In */}
                                {formatDateGerman(ci.datum)}
                                {ci.user ? ` von ${ci.user}: ` : ': '}
                                {ci.note}
                              </Typography>
                            </Box>
                          ))
                        ) : (
                          <Typography>Kein Check-In vorhanden.</Typography>
                        )}
                        <TextField
                          label="Check-In hinzufügen"
                          multiline
                          rows={2}
                          fullWidth
                          sx={{ mt: 1 }}
                          value={newCheckInNotes[t.id] || ''}
                          onChange={(e) => handleCheckInChange(t.id, e.target.value)}
                        />
                        <Button
                          variant="outlined"
                          startIcon={<AddCircleIcon />}
                          sx={{ mt: 1, ...pillStyle }}
                          onClick={() => handleSaveCheckIn(t)}
                          disabled={!newCheckInNotes[t.id]}
                        >
                          Check-In hinzufügen
                        </Button>
                      </Box>
                    </Collapse>
                  </Paper>
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>

      {/* Ziel-Dialog */}
      <Dialog
        open={openTargetDialog}
        onClose={() => setOpenTargetDialog(false)}
        maxWidth="md"        
        fullWidth
      >
        <DialogTitle>{newTarget.id ? 'Ziel bearbeiten' : 'Neues Ziel anlegen'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          {/* Nur Filiale wählen, wenn wir neu anlegen und keine ausgewählt ist */}
          {!newTarget.id && !(filterState.filiale && filterState.filiale.value) && (
            <FormControl fullWidth>
              <InputLabel>Filiale auswählen</InputLabel>
              <Select
                label="Filiale auswählen"
                value={selectedFilialeDialog}
                onChange={(e) => setSelectedFilialeDialog(e.target.value)}
              >
                {filialeOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {newTarget.id && (
            <TextField
              label="Filialnummer"
              value={newTarget.filialnummer}
              InputProps={{ readOnly: true }}
              sx={{ mb: 2 }}
            />
          )}

          <FormControl fullWidth>
            <InputLabel>Kategorie</InputLabel>
            <Select
              label="Kategorie"
              value={newTarget.category}
              onChange={(e) => setNewTarget({ ...newTarget, category: e.target.value, kennzahl: '' })}
            >
              {TARGET_CATEGORIES.map(c => (
                <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Titel"
            fullWidth
            value={newTarget.title}
            onChange={(e) => setNewTarget({ ...newTarget, title: e.target.value })}
          />

          <TextField
            label="Beschreibung"
            fullWidth
            multiline
            rows={2}
            value={newTarget.description}
            onChange={(e) => setNewTarget({ ...newTarget, description: e.target.value })}
          />

          {/* SingleDatePicker für Deadline */}
          <SingleDatePicker
            label="Deadline"
            value={newTarget.deadline ? dayjs(newTarget.deadline) : null}
            onChange={(selectedDate) => {
              if (selectedDate) {
                setNewTarget({
                  ...newTarget,
                  deadline: selectedDate.format('YYYY-MM-DD')
                });
              } else {
                setNewTarget({
                  ...newTarget,
                  deadline: ''
                });
              }
            }}
          />

          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={newTarget.status}
              onChange={(e) => setNewTarget({ ...newTarget, status: e.target.value })}
            >
              {TARGET_STATUS.map(s => (
                <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Kennzahl-Auswahl, abhängig von der Kategorie */}
          <FormControl fullWidth disabled={!newTarget.category}>
            <InputLabel>Kennzahl (optional)</InputLabel>
            <Select
              label="Kennzahl (optional)"
              value={newTarget.kennzahl}
              onChange={(e) => setNewTarget({ ...newTarget, kennzahl: e.target.value })}
            >
              {newTarget.category && categoryKennzahlenMap[newTarget.category]?.map(k => (
                <MenuItem key={k.value} value={k.value}>{k.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Zielwert (optional)"
            type="text"
            fullWidth
            value={newTarget.zielwert}
            onChange={(e) => setNewTarget({ ...newTarget, zielwert: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTargetDialog(false)}>Abbrechen</Button>
          <Button
            onClick={handleSaveTarget}
            variant="contained"
            disabled={
              !newTarget.category ||
              !newTarget.title ||
              (!newTarget.id && !filterState.filiale?.value && !selectedFilialeDialog)
            }
          >
            {newTarget.id ? 'Aktualisieren' : 'Speichern'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog für Gesprächspilot / FilialeSummary */}
      <FilialeSummary
        open={isSummaryOpen}
        onClose={() => setIsSummaryOpen(false)}
        filiale={summaryFiliale}
        datum={summaryDatum}
      />
    </Layout>
  );
};

export default Overview;
