import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from '../../services/axiosInstance'; // <-- Pfad anpassen

/**
 * Props-Definition:
 * @param {Object} value - Ausgewähltes Objekt (oder null)
 * @param {Function} onChange - Callback, der bei Änderungen aufgerufen wird
 */
const SingleArtikelSelect = ({ value, onChange }) => {
  const [artikel, setArtikel] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get('/stammdaten/stammdatenartikel/')
      .then((response) => {
        const data = response.data;
        if (data && data.results) {
          const mappedArtikel = data.results.map((item) => ({
            artikelnummer: item.artikelnummer,
            artikel: item.artikel,
          }));
          setArtikel(mappedArtikel);
        }
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Artikeldaten:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Autocomplete
      loading={loading}
      options={artikel}
      // Zeigt z.B. "1001 - 1001 WASSERWECKEN" als Label
      getOptionLabel={(option) => `${option.artikelnummer} - ${option.artikel}`}
      value={value}                   // Erwartet ein { artikelnummer, artikel }-Objekt oder null
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      isOptionEqualToValue={(option, val) =>
        option.artikelnummer === val?.artikelnummer
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Artikel"
          variant="outlined"
          size="small"
        />
      )}
      sx={{ width: 300, marginBottom: 2 }}
    />
  );
};

export default SingleArtikelSelect;
