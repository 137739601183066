// Todo.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Snackbar,
  Alert,
  Typography,
  IconButton
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Layout from '../../../components/layout/Tagesplaner/Tagesplaner';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance';
import { useAuth } from '../../../provider/authProvider';

// -----------------------------------
// Konfiguration für Wochentage & Zeiten
// -----------------------------------
const WEEKDAYS = [
  { name: 'Montag', id: 0 },
  { name: 'Dienstag', id: 1 },
  { name: 'Mittwoch', id: 2 },
  { name: 'Donnerstag', id: 3 },
  { name: 'Freitag', id: 4 },
  { name: 'Samstag', id: 5 },
  { name: 'Sonntag', id: 6 },
];

const weekdays = WEEKDAYS.map((wd) => wd.name);
const hoursArray = Array.from({ length: 16 }, (_, i) => i + 5);

function Todo() {
  const { user } = useAuth();

  // Dialog: Erstellen
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  // Dialog: Bearbeiten
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Zu bearbeitender Datensatz
  const [editFormData, setEditFormData] = useState({});

  // Ausgewählte Stunde/Wochentag für die "Create"-Aktion
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedWeekday, setSelectedWeekday] = useState(null);

  // Formularfelder für "Create"
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    filialnummer: '',
    due_time: '',
    weekdays: [],
    start_date: '',
    end_date: '',
    todoType: 'daily',
  });

  // Filial-Optionen (für Nicht-FilialUser)
  const [filialeOptions, setFilialeOptions] = useState([]);

  // Statt multiple => Single-Select
  const [selectedFilialnummer, setSelectedFilialnummer] = useState('');

  // Alle Tasks (= Task-Templates)
  const [todos, setTodos] = useState([]);

  // Alle User laden, um z.B. Username statt ID anzuzeigen
  const [allUsers, setAllUsers] = useState([]);

  // Snackbar
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // -----------------------------------
  // Hilfsfunktion: ID -> Username
  // -----------------------------------
  const getUsernameById = (userId) => {
    const foundUser = allUsers.find((u) => u.id === userId);
    return foundUser ? foundUser.username : `User #${userId}`;
  };

  // -----------------------------------
  // 1) Filialen laden (falls user != 'FILIALE')
  // -----------------------------------
  useEffect(() => {
    if (user?.user_type !== 'FILIALE') {
      const fetchFilialeOptions = async () => {
        try {
          const response = await axiosInstance.get('stammdaten/unique-filiale/');
          setFilialeOptions(response.data);
        } catch (error) {
          console.error('Fehler beim Laden der Filialen:', error);
          setSnackbar({
            open: true,
            message: 'Fehler beim Laden der Filialen.',
            severity: 'error',
          });
        }
      };
      fetchFilialeOptions();
    }
  }, [user]);

  // -----------------------------------
  // 2) Task-Templates laden
  // -----------------------------------
  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/aufgaben/task-templates/');
      let dataArr = [];
      if (Array.isArray(response.data)) {
        dataArr = response.data;
      } else if (Array.isArray(response.data?.results)) {
        dataArr = response.data.results;
      }
      setTodos(dataArr);
    } catch (err) {
      console.error('Fehler beim Abrufen der Task Templates:', err);
      setSnackbar({ open: true, message: 'Fehler beim Laden der Task Templates.', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  // -----------------------------------
  // 3) Alle User laden (für Zuordnungen)
  // -----------------------------------
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axiosInstance.get('/auth/users/');
        // je nach Struktur: response.data oder data.results
        const dataArr = Array.isArray(response.data) ? response.data : response.data.results || [];
        setAllUsers(dataArr);
      } catch (err) {
        console.error('Fehler beim Laden aller User:', err);
      }
    };
    fetchAllUsers();
  }, []);

  // -----------------------------------
  // Change-Handler (Create)
  // -----------------------------------
  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // -----------------------------------
  // Change-Handler (Bearbeiten)
  // -----------------------------------
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
  };

  // -----------------------------------
  // Filialfilter (Single-Select)
  // -----------------------------------
  const handleFilialChange = (event) => {
    const { value } = event.target;
    setSelectedFilialnummer(value); // Single value
    // Falls du willst, dass die Create-Form die Filiale mitbekommt:
    setFormData((prev) => ({
      ...prev,
      filialnummer: value,
    }));
  };

  // -----------------------------------
  // Klick in Tabelle => Aufgabe erstellen
  // -----------------------------------
  const handleCellClick = (hour, weekdayId) => {
    setSelectedHour(hour);
    setSelectedWeekday(weekdayId);

    const today = new Date();
    const currentWeekday = today.getDay();
    // JS-Sonntag=0 => wir basteln uns 0=Montag:
    const jsMondayBased = (currentWeekday + 6) % 7;
    let dayOffset = 0;
    if (weekdayId >= jsMondayBased) {
      dayOffset = weekdayId - jsMondayBased;
    } else {
      dayOffset = 7 - jsMondayBased + weekdayId;
    }
    const selectedDate = new Date();
    selectedDate.setDate(today.getDate() + dayOffset);
    const y = selectedDate.getFullYear();
    const m = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const d = String(selectedDate.getDate()).padStart(2, '0');
    const defaultStartDate = `${y}-${m}-${d}`;

    setFormData((prev) => ({
      ...prev,
      due_time: `${String(hour).padStart(2, '0')}:00`,
      weekdays: [weekdayId],
      start_date: defaultStartDate,
      end_date: '',
      todoType: 'daily',
      title: '',
      description: '',
    }));
    setCreateDialogOpen(true);
  };

  // -----------------------------------
  // Create-Dialog schließen
  // -----------------------------------
  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
    setSelectedHour(null);
    setSelectedWeekday(null);
    setFormData({
      title: '',
      description: '',
      filialnummer: '',
      due_time: '',
      weekdays: [],
      start_date: '',
      end_date: '',
      todoType: 'daily',
    });
  };

  // -----------------------------------
  // Aufgabe erstellen (Submit)
  // -----------------------------------
  const handleSubmitCreate = async () => {
    const { title, due_time, start_date, filialnummer, weekdays, todoType } = formData;

    if (!title) {
      setSnackbar({ open: true, message: 'Bitte einen Titel eingeben.', severity: 'error' });
      return;
    }
    if (!due_time) {
      setSnackbar({ open: true, message: 'Bitte eine Uhrzeit wählen.', severity: 'error' });
      return;
    }
    if (!start_date) {
      setSnackbar({ open: true, message: 'Bitte ein Startdatum.', severity: 'error' });
      return;
    }

    // ----------------------------------------
    // assignedUsers für Filialaufgabe bestimmen
    // ----------------------------------------
    let assignedUsers = [];
    if (user?.user_type === 'FILIALE') {
      // Wenn user == Filial-User => nur ihn selbst
      assignedUsers = [user.id];
    } else {
      // Wenn user != Filiale => wir brauchen "filialnummer"
      if (!filialnummer) {
        setSnackbar({
          open: true,
          message: 'Keine Filiale gewählt. Bitte Filiale auswählen.',
          severity: 'error',
        });
        return;
      }
      try {
        // Wichtig: auf "all_filialen" filtern
        // z.B. /auth/users/?user_type=FILIALE&all_filialen=7010
        const res = await axiosInstance.get(
          `/auth/users/?user_type=FILIALE&all_filialen=${filialnummer}`
        );
        const userArr = Array.isArray(res.data) ? res.data : res.data.results || [];
        if (userArr.length === 0) {
          setSnackbar({
            open: true,
            message: 'Kein Filial-User für diese Filiale gefunden.',
            severity: 'error',
          });
          return;
        }
        assignedUsers = userArr.map((u) => u.id);
      } catch (err) {
        console.error('Filial-User-Suche fehlgeschlagen:', err);
        setSnackbar({
          open: true,
          message: 'Fehler beim Suchen nach Filial-User.',
          severity: 'error',
        });
        return;
      }
    }

    let finalRhythm = 'daily';
    if (todoType === 'weekly') {
      finalRhythm = 'weekly';
    } else if (todoType === 'once') {
      finalRhythm = 'once';
    }

    const idToStr = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
    const finalWeekdays = weekdays.map((id) => idToStr[id]);

    let dueTimeWithSeconds = due_time;
    if (dueTimeWithSeconds.length === 5) {
      dueTimeWithSeconds += ':00';
    }

    const payload = {
      title,
      description: formData.description,
      task_type: 'Filialaufgabe',
      rhythm: finalRhythm,
      start_date,
      valid_until: formData.end_date || null,
      due_time: dueTimeWithSeconds,
      assigned_users: assignedUsers,
      weekdays: finalRhythm === 'weekly' ? finalWeekdays : null,
      is_active: true,
      filialnummer // optionales Feld, falls dein Backend das will
    };

    try {
      await axiosInstance.post('/aufgaben/task-templates/', payload);
      setSnackbar({ open: true, message: 'Aufgabe erfolgreich erstellt!', severity: 'success' });
      handleCreateDialogClose();
      fetchTemplates();
    } catch (err) {
      console.error('Fehler beim Erstellen der Aufgabe:', err);
      setSnackbar({ open: true, message: 'Fehler beim Erstellen der Aufgabe.', severity: 'error' });
    }
  };

  // -----------------------------------
  // Bearbeiten-Dialog öffnen
  // -----------------------------------
  const handleEditClick = (task) => {
    setEditFormData(task);
    setEditDialogOpen(true);
  };

  // -----------------------------------
  // Bearbeiten-Dialog schließen
  // -----------------------------------
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditFormData({});
  };

  // -----------------------------------
  // Bearbeiten-Submit
  // -----------------------------------
  const handleSubmitEdit = async () => {
    if (!editFormData.id) return;
    try {
      const response = await axiosInstance.patch(
        `/aufgaben/task-templates/${editFormData.id}/`,
        editFormData
      );
      setTodos((prev) => prev.map((t) => (t.id === editFormData.id ? response.data : t)));
      setSnackbar({ open: true, message: 'Aufgabe erfolgreich bearbeitet!', severity: 'success' });
      handleEditDialogClose();
    } catch (err) {
      console.error('Fehler beim Bearbeiten:', err);
      setSnackbar({ open: true, message: 'Fehler beim Bearbeiten.', severity: 'error' });
    }
  };

  // -----------------------------------
  // Löschen
  // -----------------------------------
  const handleDeleteClick = async (task) => {
    if (!task.id) return;
    try {
      await axiosInstance.delete(`/aufgaben/task-templates/${task.id}/`);
      setTodos((prev) => prev.filter((t) => t.id !== task.id));
      setSnackbar({ open: true, message: 'Aufgabe erfolgreich gelöscht!', severity: 'success' });
    } catch (err) {
      console.error('Fehler beim Löschen:', err);
      setSnackbar({ open: true, message: 'Fehler beim Löschen.', severity: 'error' });
    }
  };

  // -----------------------------------
  // Filter-Logik für die Anzeige
  // -----------------------------------
  let displayedTodos = todos;

  if (user?.user_type === 'FILIALE') {
    // Nur Aufgaben anzeigen, bei denen der eingeloggte Filialuser in assigned_users ist
    displayedTodos = displayedTodos.filter((t) =>
      Array.isArray(t.assigned_users) && t.assigned_users.includes(user.id)
    );
  } else {
    // user != FILIALE => Filialfilter anwenden (falls ausgewählt)
    if (selectedFilialnummer) {
      // 1) Alle Filial-User dieser Filiale ermitteln (auf Basis von "all_filialen")
      const filialeUserIds = allUsers
        .filter((u) => {
          if (u.user_type !== 'FILIALE') return false;
          if (!Array.isArray(u.all_filialen)) return false;
          // Check, ob "selectedFilialnummer" in "all_filialen" enthalten ist
          return u.all_filialen.some((filnr) => String(filnr) === String(selectedFilialnummer));
        })
        .map((u) => u.id);

      // 2) Nur Tasks anzeigen, deren assigned_users >= 1 davon enthält
      displayedTodos = displayedTodos.filter((task) =>
        Array.isArray(task.assigned_users) &&
        task.assigned_users.some((uid) => filialeUserIds.includes(uid))
      );
    }
    // Falls selectedFilialnummer === '', zeigen wir (derzeit) alle Tasks an.
    // Optional: Du kannst stattdessen displayedTodos = [] machen, wenn du "keine Filiale = keine Tasks" willst.
  }

  // -----------------------------------
  // tasks in jeder Cell
  // -----------------------------------
  const getTasksForCell = (hour, weekdayId) => {
    return displayedTodos.filter((t) => {
      const [h] = (t.due_time || '00:00').split(':');
      const todoHour = parseInt(h, 10);
      if (!t.rhythm) return false;

      const idToStr = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
      const dayStr = idToStr[weekdayId];

      if (t.rhythm === 'daily') {
        return todoHour === hour;
      } else if (t.rhythm === 'weekly') {
        return todoHour === hour && t.weekdays?.includes(dayStr);
      } else if (t.rhythm === 'once') {
        return todoHour === hour && t.weekdays?.includes(dayStr);
      }
      return false;
    });
  };

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
        <PageHeader
          title="Filialcheck - Analyse & Todos Überblick"
          subtitle="Verwalte (tägliche, wöchentliche oder einmalige) Aufgaben"
          Icon={ChecklistIcon}
        />

        {/* Filialfilter nur anzeigen, wenn user_type != FILIALE */}
        {user?.user_type !== 'FILIALE' && (
          <Box mt={3} mb={3}>
            <FormControl fullWidth>
              <InputLabel id="filial-filter-label">Filiale</InputLabel>
              <Select
                labelId="filial-filter-label"
                id="filial-filter"
                value={selectedFilialnummer}
                onChange={handleFilialChange}
                input={<OutlinedInput label="Filiale" />}
              >
                <MenuItem value="">
                  <em>Alle Filialen</em>
                </MenuItem>
                {filialeOptions.map((f) => (
                  <MenuItem
                    key={f.filialnummer}
                    value={String(f.filialnummer)}
                  >
                    {f.filiale}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* Matrix: Stunden x Wochentage */}
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Uhrzeit</TableCell>
                  {WEEKDAYS.map((wd) => (
                    <TableCell key={wd.id} align="center">
                      {wd.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {hoursArray.map((hour) => (
                  <TableRow key={hour}>
                    <TableCell>{`${hour}:00`}</TableCell>
                    {WEEKDAYS.map((wd) => {
                      const cellTasks = getTasksForCell(hour, wd.id);
                      return (
                        <TableCell
                          key={wd.id}
                          align="center"
                          sx={{
                            cursor: 'pointer',
                            bgcolor: '#f5f5f5',
                            minWidth: 140,
                            height: 60,
                            verticalAlign: 'top',
                          }}
                        >
                          {cellTasks.length > 0 ? (
                            <Box>
                              {cellTasks.map((task) => (
                                <Paper
                                  key={task.id}
                                  sx={{
                                    mb: 1,
                                    p: 0.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleEditClick(task)}
                                >
                                  <Typography variant="body2" sx={{ maxWidth: '60%' }} noWrap>
                                    {task.title}
                                  </Typography>
                                  <Box>
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditClick(task);
                                      }}
                                    >
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteClick(task);
                                      }}
                                    >
                                      <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                </Paper>
                              ))}
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => handleCellClick(hour, wd.id)}
                              >
                                Neu +
                              </Button>
                            </Box>
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              onClick={() => handleCellClick(hour, wd.id)}
                            >
                              Klick zum Erstellen
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* CREATE-Dialog */}
        <Dialog
          open={createDialogOpen}
          onClose={handleCreateDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Neue Aufgabe erstellen</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Titel"
                name="title"
                value={formData.title}
                onChange={handleCreateChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Beschreibung"
                name="description"
                value={formData.description}
                onChange={handleCreateChange}
                margin="normal"
                multiline
                rows={3}
              />

              {/* Filiale nur für Nicht-FilialUser */}
              {user?.user_type !== 'FILIALE' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="filialnummer-label">Filiale</InputLabel>
                  <Select
                    labelId="filialnummer-label"
                    id="filialnummer"
                    name="filialnummer"
                    value={formData.filialnummer}
                    onChange={handleCreateChange}
                    required
                  >
                    <MenuItem value="">
                      <em>Bitte wählen</em>
                    </MenuItem>
                    {filialeOptions.map((fo) => (
                      <MenuItem key={fo.filialnummer} value={String(fo.filialnummer)}>
                        {fo.filiale}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <TextField
                fullWidth
                label="Uhrzeit"
                name="due_time"
                type="time"
                value={formData.due_time}
                onChange={handleCreateChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
                required
              />

              <TextField
                fullWidth
                label="Startdatum"
                name="start_date"
                type="date"
                value={formData.start_date}
                onChange={handleCreateChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />

              <TextField
                fullWidth
                label="Enddatum (optional)"
                name="end_date"
                type="date"
                value={formData.end_date}
                onChange={handleCreateChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel id="todoType-label">Aufgabenrhythmus</InputLabel>
                <Select
                  labelId="todoType-label"
                  id="todoType"
                  name="todoType"
                  value={formData.todoType}
                  onChange={handleCreateChange}
                >
                  <MenuItem value="daily">Täglich</MenuItem>
                  <MenuItem value="weekly">Wöchentlich</MenuItem>
                  <MenuItem value="once">Einmalig</MenuItem>
                </Select>
              </FormControl>

              {formData.todoType === 'weekly' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="weekdays-label">Wochentage</InputLabel>
                  <Select
                    labelId="weekdays-label"
                    id="weekdays"
                    multiple
                    name="weekdays"
                    value={formData.weekdays}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFormData((prev) => ({ ...prev, weekdays: value }));
                    }}
                    input={<OutlinedInput label="Wochentage" />}
                  >
                    {WEEKDAYS.map((day) => (
                      <MenuItem key={day.id} value={day.id}>
                        <Checkbox checked={formData.weekdays.includes(day.id)} />
                        <ListItemText primary={day.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateDialogClose} color="secondary">
              Abbrechen
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmitCreate}>
              Erstellen
            </Button>
          </DialogActions>
        </Dialog>

        {/* EDIT-Dialog */}
        <Dialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Aufgabe bearbeiten</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Titel"
                name="title"
                value={editFormData.title || ''}
                onChange={handleEditChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Beschreibung"
                name="description"
                value={editFormData.description || ''}
                onChange={handleEditChange}
                margin="normal"
                multiline
                rows={3}
              />

              {/* Anzeigefelder (readOnly) */}
              {editFormData?.rhythm && (
                <TextField
                  fullWidth
                  label="Rhythmus"
                  value={editFormData.rhythm}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                />
              )}

              {editFormData?.rhythm === 'weekly' && editFormData?.weekdays && (
                <TextField
                  fullWidth
                  label="Wochentage"
                  value={editFormData.weekdays.join(', ')}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                />
              )}

              {editFormData?.due_time && (
                <TextField
                  fullWidth
                  label="Uhrzeit"
                  value={editFormData.due_time}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                />
              )}

              {/* Zugeordnete User (Namen) */}
              {editFormData?.assigned_users && editFormData.assigned_users.length > 0 && (
                <TextField
                  fullWidth
                  label="Zugeordnete User (Namen)"
                  value={editFormData.assigned_users
                    .map((userId) => getUsernameById(userId))
                    .join(', ')}
                  margin="normal"
                  InputProps={{ readOnly: true }}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="secondary">
              Abbrechen
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmitEdit}>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
}

export default Todo;
