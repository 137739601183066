// FilialeSummary.jsx

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TimelineIcon from '@mui/icons-material/Timeline';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import GroupIcon from '@mui/icons-material/Group';
import VariableTable from '../../components/table/VariableTable'; // Passe den Pfad ggf. an
import axiosInstance from '../../services/axiosInstance';
import dayjs from 'dayjs';

const FilialeSummary = ({ open, onClose, filiale, datum }) => {
  // Haupt-State für die Daten und den Ladezustand
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // State für den aktuell gewählten Monat (als Day.js-Objekt),
  // basierend auf dem übergebenen 'datum' im Format "YYYY.MM"
  const [selectedMonth, setSelectedMonth] = useState(dayjs(datum, "YYYY.MM"));

  // Wenn der Dialog neu geöffnet wird, setzen wir nochmal den selectedMonth,
  // damit man bei erneutem Öffnen sofort den richtigen Monat sieht
  useEffect(() => {
    if (open) {
      setSelectedMonth(dayjs(datum, "YYYY.MM"));
    }
  }, [open, datum]);

  // Daten holen, sobald der Dialog offen ist und/oder sich selectedMonth ändert
  useEffect(() => {
    if (!open) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/scorecard_documentation/documentation/', {
          params: {
            filialnummer: filiale,
            jahrMonat: selectedMonth.format('YYYY.MM'), // z. B. "2023.09"
          },
        });
        setData(response.data.results);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filiale, selectedMonth, open]);

  // Handler für Monats-Navigation
  const handlePreviousMonth = () => {
    setSelectedMonth((prev) => prev.subtract(1, 'month'));
  };
  const handleNextMonth = () => {
    setSelectedMonth((prev) => prev.add(1, 'month'));
  };

  // Helper-Funktion, um die Daten in Kategorien aufzuteilen
  const categorizeData = (entry) => ({
    performance: [
      { label: 'Umsatz', score: entry.scoreUmsatz, remark: entry.bemerkungUmsatz },
      { label: 'Kunden', score: entry.scoreKunden, remark: entry.bemerkungKunden },
      { label: 'Kundenbon', score: entry.scoreKundenbon, remark: entry.bemerkungKundenbon },
      { label: 'Leistung', score: entry.scoreLeistung, remark: entry.bemerkungLeistung },
    ],
    personal: [
      { label: 'Krankenquote', score: entry.scoreKrankenquote, remark: entry.bemerkungKrankenquote },
      { label: 'Fluktuation', score: entry.scoreFluktuation, remark: entry.bemerkungFluktuation },
      { label: 'Bedarf', score: entry.scoreBedarf, remark: entry.bemerkungBedarf },
      { label: 'Auslastung', score: entry.scoreAuslastung, remark: entry.bemerkungAuslastung },
    ],
    waren: [
      { label: 'Retouren A', score: entry.scoreRetoureA, remark: entry.bemerkungRetoureA },
      { label: 'Retouren BC', score: entry.scoreRetoureBC, remark: entry.bemerkungRetoureBC },
      { label: 'Verfügbarkeit', score: entry.scoreVerfügbarkeit, remark: entry.bemerkungVerfügbarkeit },
      { label: 'Wachstum', score: entry.scoreWachstum, remark: entry.bemerkungWachstum },
    ],
    sonstiges: [
      { remark: entry.bemerkungSonstiges || 'Keine Bemerkung' },
    ],
  });

  // Spalten für die VariableTable
  const columns = [
    { accessorKey: 'label', header: 'Kategorie' },
    { accessorKey: 'score', header: 'Score' },
    { accessorKey: 'remark', header: 'Bemerkung' },
  ];

  const sonstigesColumns = [
    { accessorKey: 'remark', header: 'Bemerkung' },
  ];

  // Umwandeln in das Table-Format
  const transformToTableData = (categoryData, isSonstiges = false) =>
    categoryData.map((item) =>
      isSonstiges
        ? { remark: item.remark }
        : {
            label: item.label,
            score: item.score,
            remark: item.remark || 'Keine Bemerkung',
          }
    );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ position: 'relative' }}>
        {/* Dialog-Titel inkl. Monat anzeigen */}
        Zusammenfassung für Filiale: {filiale} im Monat: {selectedMonth.format('YYYY.MM')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : data.length > 0 ? (
          <Grid container spacing={3}>
            {/* Oberer Teil: Filialinformationen o. Ä. */}
            <Grid item xs={12}>
              <Card sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 3 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Filiale:</strong> {filiale}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Monat:</strong> {selectedMonth.format('YYYY.MM')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Erstellt von:</strong> {data[0]?.user || 'Keine Angabe'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Unterer Teil: Kategorien als Karten */}
            {data.map((entry, index) => {
              const categorizedData = categorizeData(entry);

              return (
                <React.Fragment key={index}>
                  {/* Performance */}
                  <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 3 }}>
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <TimelineIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Performance</Typography>
                        </Box>
                        <VariableTable
                          columns={columns}
                          data={transformToTableData(categorizedData.performance)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Personal */}
                  <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 3 }}>
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <GroupIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Personal</Typography>
                        </Box>
                        <VariableTable
                          columns={columns}
                          data={transformToTableData(categorizedData.personal)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Waren */}
                  <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 3 }}>
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <BakeryDiningIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Waren</Typography>
                        </Box>
                        <VariableTable
                          columns={columns}
                          data={transformToTableData(categorizedData.waren)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Sonstiges */}
                  <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 3 }}>
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <Typography variant="h6">Sonstiges</Typography>
                        </Box>
                        <VariableTable
                          columns={sonstigesColumns}
                          data={transformToTableData(categorizedData.sonstiges, true)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1">
            Keine Daten verfügbar für diese Filiale in diesem Monat.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        {/* Buttons für Monatsnavigation */}
        <Button onClick={handlePreviousMonth} color="primary">
          Vorheriger Monat
        </Button>
        <Button onClick={handleNextMonth} color="primary">
          Nächster Monat
        </Button>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilialeSummary;
