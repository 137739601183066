// frontend/src/pages/PhoneListSettings.jsx

import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../../services/axiosInstance';
import * as XLSX from 'xlsx';

// Material UI
import {
  Grid,
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
// Beispiel für ein "+" Icon (optional):
// import AddIcon from '@mui/icons-material/Add';

import ChecklistIcon from '@mui/icons-material/Checklist';

// Dein Layout & Header
import Layout from '../../../components/layout/Telefonliste/TelefonlisteLayout';
import PageHeader from '../../../components/layout/Title/TitelSmall';

const PhoneListSettings = () => {
  // --------------------------------------------------
  // States
  // --------------------------------------------------
  const [personnel, setPersonnel] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('create'); 
  const [selectedEntry, setSelectedEntry] = useState(null);

  // Formular-States für Create/Edit
  const [formData, setFormData] = useState({
    name: '',
    abteilung: '',
    telefonnummer: '',
    mobil: '',
    mail: '',
  });

  // Excel-Import
  const [excelFile, setExcelFile] = useState(null);

  // Suchbegriff (clientseitige Suche)
  const [searchTerm, setSearchTerm] = useState('');

  // --------------------------------------------------
  // 1) Daten laden
  // --------------------------------------------------
  useEffect(() => {
    fetchAllEntries();
  }, []);

  const fetchAllEntries = async () => {
    try {
      const response = await axiosInstance.get('/telefonliste/telefonliste/');
      const results = response.data.results ?? response.data;
      setPersonnel(Array.isArray(results) ? results : []);
    } catch (error) {
      console.error('Fehler beim Laden der Einträge:', error);
    }
  };

  // --------------------------------------------------
  // 2) Dialog öffnen/schließen
  // --------------------------------------------------
  const handleOpenCreate = () => {
    setDialogMode('create');
    setFormData({
      name: '',
      abteilung: '',
      telefonnummer: '',
      mobil: '',
      mail: '',
    });
    setSelectedEntry(null);
    setOpenDialog(true);
  };

  const handleOpenEdit = (entry) => {
    setDialogMode('edit');
    setSelectedEntry(entry);
    setFormData({
      name: entry.name || '',
      abteilung: entry.abteilung || '',
      telefonnummer: entry.telefonnummer || '',
      mobil: entry.mobil || '',
      mail: entry.mail || '',
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEntry(null);
  };

  // --------------------------------------------------
  // 3) Form-Änderung
  // --------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // --------------------------------------------------
  // 4) Speichern (Create/Edit)
  // --------------------------------------------------
  const handleSave = async () => {
    try {
      if (dialogMode === 'create') {
        // POST
        const response = await axiosInstance.post('/telefonliste/telefonliste/', formData);
        setPersonnel((prev) => [...prev, response.data]);
      } else if (dialogMode === 'edit' && selectedEntry?.id) {
        // PUT
        const response = await axiosInstance.put(
          `/telefonliste/telefonliste/${selectedEntry.id}/`,
          formData
        );
        setPersonnel((prev) =>
          prev.map((p) => (p.id === selectedEntry.id ? response.data : p))
        );
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Fehler beim Speichern:', error);
      alert('Fehler beim Speichern des Eintrags!');
    }
  };

  // --------------------------------------------------
  // 5) Löschen
  // --------------------------------------------------
  const handleDelete = async (id) => {
    if (!window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) return;

    try {
      await axiosInstance.delete(`/telefonliste/telefonliste/${id}/`);
      setPersonnel((prev) => prev.filter((p) => p.id !== id));
    } catch (error) {
      console.error('Fehler beim Löschen:', error);
      alert('Fehler beim Löschen des Eintrags!');
    }
  };

  // --------------------------------------------------
  // 6) Excel-Export (Client-Side)
  // --------------------------------------------------
  const handleExportExcelClientSide = () => {
    try {
      const ws = XLSX.utils.json_to_sheet(personnel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Telefonliste');
      XLSX.writeFile(wb, 'telefonliste.xlsx');
    } catch (error) {
      console.error('Fehler beim Excel-Export:', error);
      alert('Fehler beim Erstellen der Excel-Datei!');
    }
  };

  // --------------------------------------------------
  // 7) Excel-Vorlage herunterladen (Backend)
  // --------------------------------------------------
  const handleDownloadExcelTemplate = () => {
    window.open(
      axiosInstance.defaults.baseURL + '/telefonliste/telefonliste/download-excel-template/'
    );
  };

  // --------------------------------------------------
  // 8) Excel-Import
  // --------------------------------------------------
  const handleExcelFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setExcelFile(e.target.files[0]);
    }
  };

  const handleImportExcel = async () => {
    if (!excelFile) {
      alert('Bitte zuerst eine Excel-Datei auswählen!');
      return;
    }

    try {
      const formDataImport = new FormData();
      formDataImport.append('excel_file', excelFile);

      const response = await axiosInstance.post(
        '/telefonliste/telefonliste/import-excel/',
        formDataImport,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      alert('Excel-Import erfolgreich! ' + response.data.detail);
      fetchAllEntries();
      setExcelFile(null);
    } catch (error) {
      console.error('Fehler beim Excel-Import:', error);
      alert('Fehler beim Excel-Import! Siehe Konsole für weitere Infos.');
    }
  };

  // --------------------------------------------------
  // 9) Suche (clientseitiges Filtern)
  // --------------------------------------------------
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // --------------------------------------------------
  // 10) Gruppierung nach Abteilung + Filter
  // --------------------------------------------------
  const filteredPersonnel = useMemo(() => {
    if (!searchTerm.trim()) return personnel;
    const lowerSearch = searchTerm.toLowerCase();

    return personnel.filter((person) => {
      const { name, abteilung, telefonnummer, mobil, mail } = person;
      return (
        (name && name.toLowerCase().includes(lowerSearch)) ||
        (abteilung && abteilung.toLowerCase().includes(lowerSearch)) ||
        (telefonnummer && telefonnummer.toLowerCase().includes(lowerSearch)) ||
        (mobil && mobil.toLowerCase().includes(lowerSearch)) ||
        (mail && mail.toLowerCase().includes(lowerSearch))
      );
    });
  }, [personnel, searchTerm]);

  const groupedByAbteilung = useMemo(() => {
    const groups = {};
    filteredPersonnel.forEach((p) => {
      const abt = p.abteilung || 'Ohne Abteilung';
      if (!groups[abt]) {
        groups[abt] = [];
      }
      groups[abt].push(p);
    });
    return groups;
  }, [filteredPersonnel]);

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader
          title="PhoneList Settings"
          subtitle="Verwalte deine Telefonlisten, konfiguriere sie, exportiere oder importiere Excel-Dateien."
          Icon={ChecklistIcon}
        />

        {/* Alle Aktionen in einer Zeile */}
        <Paper sx={{ p: 2, mt: 2, mb: 2, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Telefonliste verwalten
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              alignItems: 'center',
            }}
          >
            {/* Suchfeld */}
            <TextField
              label="Suchen..."
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ borderRadius: 2 }}
            />

            {/* Neuer Eintrag */}
            <Button
              variant="contained"
              // startIcon={<AddIcon />} // optional
              onClick={handleOpenCreate}
              sx={{
                borderRadius: 2,
                minWidth: 180,
                fontWeight: 'bold',
              }}
            >
              + Neuer Eintrag
            </Button>

            {/* Excel exportieren */}
            <Button
              variant="outlined"
              onClick={handleExportExcelClientSide}
              sx={{
                borderRadius: 2,
                fontWeight: 'bold',
              }}
            >
              Excel exportieren
            </Button>

            {/* Excel-Vorlage herunterladen */}
            <Button
              variant="outlined"
              onClick={handleDownloadExcelTemplate}
              sx={{
                borderRadius: 2,
                fontWeight: 'bold',
              }}
            >
              Excel-Vorlage herunterladen
            </Button>

            {/* Datei auswählen */}
            <label htmlFor="excel-file-input-settings">
              <input
                type="file"
                accept=".xlsx"
                onChange={handleExcelFileChange}
                style={{ display: 'none' }}
                id="excel-file-input-settings"
              />
              <Button
                variant="contained"
                component="span"
                sx={{
                  borderRadius: 2,
                  fontWeight: 'bold',
                }}
              >
                Datei auswählen
              </Button>
            </label>

            {/* Excel importieren */}
            <Button
              variant="contained"
              onClick={handleImportExcel}
              sx={{
                borderRadius: 2,
                fontWeight: 'bold',
              }}
            >
              Excel importieren
            </Button>
          </Box>
        </Paper>

        {/* Gruppierte Abteilungen */}
        {Object.keys(groupedByAbteilung).length === 0 ? (
          <Typography variant="body1">Keine Einträge vorhanden</Typography>
        ) : (
          Object.entries(groupedByAbteilung).map(([abt, persons]) => (
            <Paper key={abt} sx={{ p: 2, mb: 2, borderRadius: 3 }}>
              <Typography variant="h6" gutterBottom>
                Abteilung: {abt}
              </Typography>
              <Divider sx={{ mb: 2 }} />

              {persons.map((person) => (
                <Box
                  key={person.id}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  {/* Links: Personendaten */}
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {person.name}
                    </Typography>
                    <Typography variant="body2">
                      Telefon: {person.telefonnummer} | Mobil: {person.mobil}
                    </Typography>
                    <Typography variant="body2">Mail: {person.mail}</Typography>
                  </Box>

                  {/* Rechts: Edit & Delete Icons */}
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenEdit(person)}
                      sx={{ borderRadius: 2 }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(person.id)}
                      sx={{ borderRadius: 2 }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Paper>
          ))
        )}
      </Box>

      {/* Dialog für Erstellen/Bearbeiten */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogMode === 'create' ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Abteilung"
            name="abteilung"
            value={formData.abteilung}
            onChange={handleChange}
          />
          <TextField
            label="Telefonnummer"
            name="telefonnummer"
            value={formData.telefonnummer}
            onChange={handleChange}
          />
          <TextField
            label="Mobil"
            name="mobil"
            value={formData.mobil}
            onChange={handleChange}
          />
          <TextField
            label="Mail"
            name="mail"
            value={formData.mail}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2 }}>
          <Button onClick={handleCloseDialog} color="inherit">
            Abbrechen
          </Button>
          <Button onClick={handleSave} variant="contained">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default PhoneListSettings;
