import React, {useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche';
import BigTableCard from '../../../components/card/BigTableCard';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import axiosInstance from '../../../services/axiosInstance';
import {FilterContext} from '../../../provider/FilterProvider';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/de';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import FilialeWocheLayout from "../../../components/layout/Scorecards/FilialeWocheLayout";


dayjs.extend(weekOfYear);
dayjs.locale('de');

// Gleiche formatNumber Funktion wie in retoure.jsx
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
      .toFixed(fractionDigits)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const getLast6Weeks = (year, week) => {
  const last6Weeks = [];
  const current = dayjs().year(year).week(week).startOf('week');
  for (let i = 5; i >= 0; i--) {
    const w = current.subtract(i, 'week');
    const y = w.year();
    const ww = String(w.week()).padStart(2, '0');
    last6Weeks.push(`${y}.${ww}`);
  }
  return last6Weeks;
};

// Popup für Artikeldetails (WarengruppePopup) mit den gewünschten Spalten
const WarengruppePopup = ({open, onClose, warengruppe, filterState}) => {
  const [artikelData, setArtikelData] = useState([]);

  useEffect(() => {
    if (open && warengruppe && filterState.filiale && filterState.datumWoche) {
      fetchArtikelData();
    }
  }, [open, warengruppe, filterState.filiale, filterState.datumWoche]);

  const fetchArtikelData = async () => {
    try {
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const startDate = dayjs().year(year).week(week).startOf('week');
      const endDate = dayjs().year(year).week(week).endOf('week');

      const response = await axiosInstance.get('scorecard_week_article/ware-artikel-woche', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.value,
          warengruppe: warengruppe
        }
      });

      const rawData = response.data.results || [];

      const formattedArtikelData = rawData.map(item => {
        const artikelName = item.artikelnummer && item.artikelnummer.artikel
            ? `${item.artikelnummer.artikel}`
            : 'N/A';

        const retourwert = item.retourwert || 0;
        const lieferwert = item.lieferwert || 0;
        const korrekturwert = item.korrekturwert || 0;
        const denominator = lieferwert + korrekturwert;
        const retourquote = denominator > 0 ? (retourwert / denominator) * 100 : 0;

        return {
          artikel: artikelName,
          retourquote: retourquote > 0 ? `${formatNumber(retourquote)}%` : '0,00%',
          retourwert: retourwert !== 0 ? formatNumber(retourwert) : '0,00',
          lieferwert: lieferwert !== 0 ? formatNumber(lieferwert) : '0,00',
          korrekturwert: korrekturwert !== 0 ? formatNumber(korrekturwert) : '0,00',
        };
      });

      setArtikelData(formattedArtikelData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
    }
  };

  return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
        <DialogTitle>
          Details für Warengruppe {warengruppe}
          <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{position: 'absolute', right: 8, top: 8}}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <BigTableCard
              data={artikelData}
              columns={[
                {accessorKey: 'artikel', header: 'Artikel'},
                {accessorKey: 'retourquote', header: 'Retourquote'},
                {accessorKey: 'retourwert', header: 'Retourwert'},
                {accessorKey: 'lieferwert', header: 'Lieferwert'},
                {accessorKey: 'korrekturwert', header: 'Korrekturwert'},
              ]}
              title={`Details für Warengruppe ${warengruppe}`}
              infoContent={
                <>
                  Diese Tabelle zeigt die Artikeldaten für die ausgewählte Warengruppe und Woche.<br/><br/>
                  <strong>Artikel:</strong> Artikelname<br/>
                  <strong>Retourquote:</strong> Prozentualer Anteil Retourwert an (Lieferwert+Korrekturwert)<br/>
                  <strong>Retourwert / Lieferwert / Korrekturwert:</strong> Wert in Euro
                </>
              }
          />
        </DialogContent>
      </Dialog>
  );
};

const RetourenFilialeUebersicht = () => {
  const {filterState} = useContext(FilterContext);
  const [chartData, setChartData] = useState([]);
  const [warengruppeTableData, setWarengruppeTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [warengruppePopupOpen, setWarengruppePopupOpen] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

  // Verfügbarkeits-Daten
  const [verfuegbarkeitData, setVerfuegbarkeitData] = useState([]);
  const [verfuegbarkeitTableColumns, setVerfuegbarkeitTableColumns] = useState([]);

  useEffect(() => {
    if (filterState && filterState.datumWoche && filterState.datumWoche.value && filterState.filiale) {
      fetchChartData();
      fetchWarengruppeTableData();
      fetchVerfuegbarkeitData(); // Verfügbarkeit hinzu
    }
  }, [filterState.filiale, filterState.datumWoche]);

  const fetchChartData = async () => {
    try {
      setLoading(true);
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const last6Weeks = getLast6Weeks(year, week);

      const responses = await Promise.all(
          last6Weeks.map((wk) =>
              axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                  jahrKw: wk,
                  filiale: filterState.filiale?.value
                },
              })
          )
      );

      const warengruppenSet = new Set();
      responses.forEach((res) => {
        res.data.results.forEach((item) => {
          warengruppenSet.add(item.warengruppe);
        });
      });
      const warengruppen = Array.from(warengruppenSet);

      const chartDataArr = last6Weeks.map((weekKey, index) => {
        const dataForWeek = responses[index].data.results;
        const aggregatedData = {};
        dataForWeek.forEach((item) => {
          const wg = item.warengruppe;
          const retourwert = item.retourwert || 0;
          const lieferwert = item.lieferwert || 0;
          const korrekturwert = item.korrekturwert || 0;
          const denominator = lieferwert + korrekturwert;
          const retourquote = denominator > 0 ? (retourwert / denominator) * 100 : 0;
          aggregatedData[wg] = retourquote;
        });

        const obj = {x: weekKey};
        warengruppen.forEach((wg) => {
          obj[wg] = aggregatedData[wg] || 0;
        });
        return obj;
      });

      const wgMitWerten = warengruppen.filter((wg) =>
          chartDataArr.some((data) => data[wg] > 0)
      );

      const filteredChartData = chartDataArr.map(d => {
        const newObj = {x: d.x};
        wgMitWerten.forEach(wg => {
          newObj[wg] = d[wg];
        });
        return newObj;
      });

      setChartData(filteredChartData);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Chart-Daten:', error);
      setError(true);
      setLoading(false);
    }
  };

  const fetchWarengruppeTableData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
        params: {
          jahrKw: filterState.datumWoche?.value,
          filiale: filterState.filiale?.value
        },
      });

      const aggregatedData = {};
      response.data.results.forEach((item, index) => {
        const wg = item.warengruppe || 'Unbekannt';
        const lieferwert = item.lieferwert || 0;
        const korrekturwert = item.korrekturwert || 0;
        const retourwert = item.retourwert || 0;
        const denominator = lieferwert + korrekturwert;
        const retourquote = denominator > 0 ? (retourwert / denominator) * 100 : 0;

        const lieferwertA = item.lieferwertA || 0;
        const korrekturwertA = item.korrekturwertA || 0;
        const retourwertA = item.retourwertA || 0;
        const denominatorA = lieferwertA + korrekturwertA;
        const retourquoteA = denominatorA > 0 ? (retourwertA / denominatorA) * 100 : 0;

        const lieferwertBC = item.lieferwertBC || 0;
        const korrekturwertBC = item.korrekturwertBC || 0;
        const retourwertBC = item.retourwertBC || 0;
        const denominatorBC = lieferwertBC + korrekturwertBC;
        const retourquoteBC = denominatorBC > 0 ? (retourwertBC / denominatorBC) * 100 : 0;

        aggregatedData[wg] = {
          id: index,
          warengruppe: wg,
          retourquote: retourquote > 0 ? `${formatNumber(retourquote)}%` : '0,00%',
          retourquoteA: retourquoteA > 0 ? `${formatNumber(retourquoteA)}%` : '0,00%',
          retourquoteBC: retourquoteBC > 0 ? `${formatNumber(retourquoteBC)}%` : '0,00%',
        };
      });

      const tableArray = Object.values(aggregatedData).filter((item) =>
          parseFloat(item.retourquote.replace(',', '.')) > 0
      );

      setWarengruppeTableData(tableArray);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Warengruppe-Tabelle:', error);
      setError(true);
      setLoading(false);
    }
  };

  const handleWarengruppeRowClick = (row) => {
    const wg = row.warengruppe;
    setSelectedWarengruppe(wg);
    setWarengruppePopupOpen(true);
  };

  // Verfügbarkeit Daten nur nach gewählter Filiale und Woche
  const fetchVerfuegbarkeitData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/scorecard_week_article/verfuegbarkeit-woche/', {
        params: {
          jahrKw: filterState.datumWoche.value,
          ABC: 'A',
          filiale: filterState.filiale?.value // Nur auf Filiale gefiltert
        },
      });

      const results = response.data.results;

      // Hier nehmen wir an, dass das Item auch eine warengruppe-Feld hat.
      // Wenn nicht, muss man herausfinden, wie die Warengruppe bestimmt wird.
      // Beispielhaft:
      const data = [];
      const warengruppeSet = new Set();

      results.forEach((item) => {
        const warengruppe = item.warengruppe || 'Unbekannt';
        const artikelnummer = item.artikelnummer.artikelnummer;
        const artikel = item.artikelnummer.artikel;

        warengruppeSet.add(warengruppe);

        data.push({
          id: `${warengruppe}-${artikelnummer}`,
          parentId: warengruppe,
          warengruppe,
          artikelnummer,
          artikel,
          ausverkauftTage: 1,
        });
      });

      const dataById = {};
      data.forEach((item) => {
        const id = item.id;
        if (dataById[id]) {
          dataById[id].ausverkauftTage += 1;
        } else {
          dataById[id] = item;
        }
      });

      const aggregatedData = Object.values(dataById);

      // Warengruppen als oberste Ebene hinzufügen
      warengruppeSet.forEach((wg) => {
        aggregatedData.push({
          id: wg,
          warengruppe: wg,
          artikelnummer: null,
          artikel: null,
          ausverkauftTage: null,
        });
      });

      setVerfuegbarkeitData(aggregatedData);

      setVerfuegbarkeitTableColumns([
        {field: 'warengruppe', headerName: 'Warengruppe', align: 'left'},
        {field: 'artikel', headerName: 'Artikel', align: 'left'},
        {field: 'ausverkauftTage', headerName: 'Ausverkauft Tage', type: 'number', align: 'right'},
      ]);

      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Verfügbarkeits-Daten:', error);
      setError(true);
      setLoading(false);
    }
  };

  return (
      <FilialeWocheLayout>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <PageHeader
              title="Retouren"
              subtitle="Übersicht über die Retouren im Sortiment (Filial-gefiltert)"
              Icon={BarChartIcon}
          />
        </Box>
        {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress/>
            </Box>
        ) : error ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography color="error">Fehler beim Laden der Daten.</Typography>
            </Box>
        ) : (
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <BigChartCardUnternehmenWoche
                    data={chartData}
                    title="Retourquote pro Warengruppe der letzten 6 Wochen"
                    infoContent="Dies ist eine Info über den Retourquote-Chart."
                    isPercentage
                    lines={
                      chartData.length > 0
                          ? Object.keys(chartData[0]).filter(k => k !== 'x').map((wg, i) => ({
                            dataKey: wg,
                            name: wg,
                            color: i === 0 ? '#8884d8' : '#82ca9d',
                          }))
                          : []
                    }
                    height={300}
                />
              </Grid>
              <Grid item xs={12}>
                <BigTableCard
                    data={warengruppeTableData}
                    columns={[
                      {accessorKey: 'warengruppe', header: 'Warengruppe'},
                      {accessorKey: 'retourquote', header: 'Retourquote'},
                      {accessorKey: 'retourquoteA', header: 'Retourquote A'},
                      {accessorKey: 'retourquoteBC', header: 'Retourquote BC'},
                    ]}
                    title="Retourquoten nach Warengruppe (ausgewählte Woche & Filiale)"
                    infoContent={
                      <>
                        Diese Tabelle zeigt die Retourquoten nach Warengruppe für die aktuell ausgewählte Woche und Filiale.<br/><br/>
                        <strong>Warengruppe:</strong> Name der Warengruppe<br/>
                        <strong>Retourquote, Retourquote A, Retourquote BC:</strong> Prozentuale Retourwerte<br/>
                      </>
                    }
                    onRowClick={(row) => {
                      const wg = row.warengruppe;
                      setSelectedWarengruppe(wg);
                      setWarengruppePopupOpen(true);
                    }}
                />
              </Grid>

              {/* Neue Verfügbarkeits-Tabelle (ABC = A), nach Warengruppe gruppiert */}
              <Grid item xs={12}>
                <VariableTreeMUI
                    title="Verfügbarkeit der Artikel (ABC = A)"
                    data={verfuegbarkeitData}
                    columns={verfuegbarkeitTableColumns}
                    initialGrouping={['warengruppe']} // jetzt nach Warengruppe gruppieren
                    groupingHeaders={{warengruppe: 'Warengruppe'}}
                    treeData
                    getTreeDataPath={(row) => [row.warengruppe, row.artikelnummer]}
                />
              </Grid>
            </Grid>
        )}

        <WarengruppePopup
            open={warengruppePopupOpen}
            onClose={() => setWarengruppePopupOpen(false)}
            warengruppe={selectedWarengruppe}
            filterState={filterState}
        />
      </FilialeWocheLayout>
  );
};

export default RetourenFilialeUebersicht;
