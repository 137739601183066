import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Badge
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import PeopleIcon from '@mui/icons-material/People';

const HourSection = ({
  hour,
  dataForHour,
  borderColor,
  currentHour,
  theme,
  hourInstances,
  handleOpenTaskTemplateDialog,
  handleOpenArticleDialog,
  handleToggleInstanceDone,
}) => {
  // Kundenanzahl ermitteln
  const kundenanzahl = dataForHour?.kundenanzahl ?? 0;

  // Einheitliches IconButton-Styling (vergrößert)
  const iconButtonStyle = {
    backgroundColor: theme.palette.grey[50],
    boxShadow: 1,          // leichte Schattierung
    borderRadius: '50%',   // kreisförmig
    width: 48,
    height: 48,
    p: 1,                  // Innenabstand, damit das Icon nicht zu nah am Rand ist
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  };

  // Einheitliches Icon-Styling (etwas größere Icons)
  const iconStyle = { fontSize: '1.4rem' };

  return (
    <Paper
      id={`hour-${hour}`}
      sx={{
        mb: 2,
        p: 2,
        position: 'relative',
        backgroundColor:
          currentHour === hour
            ? theme.palette.grey[200] // Helles Grau für aktuelle Stunde
            : theme.palette.background.paper,
        border: `2px solid ${borderColor}`,
      }}
    >
      {/* Kopfzeile: Stundenangabe + Icons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        
        <Typography variant="h6">
          {`${hour}:00 - ${hour + 1}:00`}
        </Typography>

        {/* Rechts: Button-Gruppe */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* IconButton: Kundenanzahl */}
          <IconButton sx={iconButtonStyle}>
            <Badge badgeContent={kundenanzahl} color="primary">
              <PeopleIcon sx={iconStyle} />
            </Badge>
          </IconButton>

          {/* IconButton: Neues TaskTemplate anlegen */}
          <IconButton
            onClick={() => handleOpenTaskTemplateDialog(hour)}
            color="primary"
            sx={iconButtonStyle}
          >
            <AddIcon sx={iconStyle} />
          </IconButton>

          {/* IconButton: Artikel-Dialog (Croissant-Icon) */}
          <IconButton
            onClick={() => handleOpenArticleDialog(hour)}
            color="primary"
            sx={iconButtonStyle}
          >
            <BakeryDiningIcon sx={iconStyle} />
          </IconButton>
        </Box>
      </Box>

      {/* Filialaufgaben als Checkbox-Liste */}
      {hourInstances.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Filialaufgaben:
          </Typography>
          <List sx={{ pl: 2 }}>
            {hourInstances.map((inst) => (
              <ListItem key={inst.id} sx={{ display: 'block', py: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={inst.is_done}
                    onChange={() => handleToggleInstanceDone(inst)}
                  />
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {inst.template?.title || 'Unbekannt'}
                    </Typography>
                    {inst.template?.description && (
                      <Typography variant="body2">
                        {inst.template.description}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Paper>
  );
};

export default HourSection;
