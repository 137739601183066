import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Paper,
  TextField,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';

const FilialTable = ({
  filialeData,
  expandedRows,
  handleRowClick,
  handleOpenFilialeDialog,
}) => {
  // Suchbegriff für Filialsuche
  const [searchTerm, setSearchTerm] = useState('');

  // Gefilterte Filialdaten, basierend auf `searchTerm`
  const filteredData = useMemo(() => {
    return filialeData.filter((filiale) => {
      // Hier kannst du weitere Felder einbeziehen,
      // z. B. filiale.verkaufsgebiet, filiale.checks, etc.
      const filialeNameMatch = filiale.filiale
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      const filialeNummerMatch = filiale.filialnummer
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      return filialeNameMatch || filialeNummerMatch;
    });
  }, [filialeData, searchTerm]);

  return (
    <div className="db_styled_table">

    <Box>
      {/* Suchfeld oberhalb der Tabelle */}
      <Box sx={{ width: '200px' }}>
            <TextField
              fullWidth
              label="Suchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{
                '& .MuiInputBase-root': {
                  height: '30px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '0.875rem',
                },
              }}
            />
          </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Filiale</TableCell>
              <TableCell>Anzahl Checks</TableCell>
              <TableCell>Durchschnittliche Bewertung</TableCell>
              <TableCell>Letztes Check-Datum</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((filiale, index) => {
              // Berechnung des Durchschnitts basierend auf check_max
              const averageRating =
                filiale.totalCheckMax > 0
                  ? ((filiale.totalValue / filiale.totalCheckMax) * 100).toFixed(2) + '%'
                  : 'N/A';

              const lastCheckDate = filiale.lastCheckDate
                ? dayjs(filiale.lastCheckDate).format('DD.MM.YYYY')
                : 'N/A';

              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <IconButton size="small" onClick={() => handleRowClick(index)}>
                        {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {filiale.filiale} ({filiale.filialnummer})
                    </TableCell>
                    <TableCell>{filiale.sessions.size}</TableCell>
                    <TableCell>{averageRating}</TableCell>
                    <TableCell>{lastCheckDate}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenFilialeDialog(filiale)}>
                        <SearchIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  {expandedRows[index] && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        {/* Details pro Check-Art */}
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Check-Art</TableCell>
                              <TableCell>Anzahl Checks</TableCell>
                              <TableCell>Durchschnittliche Bewertung</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filiale.checks.map((check, idx) => {
                              const averageCheckRating =
                                check.totalCheckMax > 0
                                  ? ((check.totalValue / check.totalCheckMax) * 100).toFixed(2) + '%'
                                  : 'N/A';

                              return (
                                <TableRow key={idx}>
                                  <TableCell>{check.checkName}</TableCell>
                                  <TableCell>{check.sessions.size}</TableCell>
                                  <TableCell>{averageCheckRating}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </div>

  );
};

export default FilialTable;
