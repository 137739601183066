import React from 'react';
import Sidebar from '../GeneralSidebar';
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from "@mui/icons-material/Store";

const SettingsLayout = ({children}) => {
  const menuItems = [
    {text: 'Nutzerverwaltung', path: '/settings/rechte/nutzer-verwalten', icon: <AccountCircleIcon/>, subModule: "rechte"},
    {text: 'Rollenverwaltung', path: '/settings/rechte/rollen-verwalten', icon: <GroupIcon/>, subModule: "rechte"},
    {text: 'Filialenzuweisung', path: '/settings/rechte/filialen-verwalten', icon: <StoreIcon/>, subModule: "rechte"},
  ];

  return (
      <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} moduleName={"settings"} menuItems={menuItems} title={'Einstellungen'}/>}>
        {children}
      </GeneralLayout>
  );
};

export default SettingsLayout;
