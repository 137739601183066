// components/popup/unternehmen/SalesRegionPopup.js

import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  Download as DownloadIcon,
  PictureAsPdf as PictureAsPdfIcon,
  GridOn as GridOnIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import axiosInstance from '../../../services/axiosInstance';

// Hier importierst du den Context:
import { FilterContext } from '../../../provider/FilterProvider';

export default function SalesRegionPopup({ open, onClose, warengruppe, jahrMonat, displayMode }) {
  // Context auslesen:
  const { filterState } = useContext(FilterContext);

  const [salesData, setSalesData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      fetchSalesData();
    }
  }, [open, displayMode]);

  const fetchSalesData = async () => {
    try {
      // Wir entscheiden anhand des displayMode, welches Feld "Aktuelles Jahr" und "Vorjahr" ist.
      const fieldName =
        displayMode === 'Verkaufsmenge' ? 'verkaufsmenge' : 'umsatz';
      const fieldNameVJ =
        displayMode === 'Verkaufsmenge' ? 'verkaufsmengeVJ' : 'umsatzVJ';

      // Hier kommt die Abfrage des filterState.bestehendeFlaeche ins Spiel:
      const response = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
        params: {
          warengruppe,
          jahrMonat,
          ...(filterState.bestehendeFlaeche && { vorjahr: true }),
        },
      });

      // salesMap als Zwischenspeicher, um pro Verkaufsgebiet und Filiale die Werte zu sammeln.
      const salesMap = {};

      // Daten verarbeiten
      response.data.results.forEach((item) => {
        const { verkaufsgebiet, filiale } = item;
        const currentValue = item[fieldName] || 0;
        const prevValue = item[fieldNameVJ] || 0;

        if (!salesMap[verkaufsgebiet]) {
          salesMap[verkaufsgebiet] = {};
        }
        if (!salesMap[verkaufsgebiet][filiale]) {
          salesMap[verkaufsgebiet][filiale] = {
            [fieldName]: 0,
            [fieldNameVJ]: 0,
          };
        }

        // Aufsummieren, falls mehrfach Zeilen zum selben verkaufsgebiet/filiale zurückkommen
        salesMap[verkaufsgebiet][filiale][fieldName] += currentValue;
        salesMap[verkaufsgebiet][filiale][fieldNameVJ] += prevValue;
      });

      // Aggregierte Daten formatieren
      const formattedData = Object.entries(salesMap)
        .map(([verkaufsgebiet, filials]) => {
          // Summen für das Verkaufsgebiet berechnen
          const totalCurrent = Object.values(filials).reduce(
            (acc, curr) => acc + curr[fieldName],
            0
          );
          const totalPrev = Object.values(filials).reduce(
            (acc, curr) => acc + curr[fieldNameVJ],
            0
          );
          const differenz = totalCurrent - totalPrev;
          const vjPercent = totalPrev
            ? ((differenz / totalPrev) * 100).toFixed(2) + '%'
            : 'N/A';

          // Filialen filtern
          const filteredFilials = Object.entries(filials)
            .filter(
              ([_, data]) => data[fieldName] > 0 || data[fieldNameVJ] > 0
            )
            .map(([filiale, data]) => {
              const filCurrent = data[fieldName];
              const filPrev = data[fieldNameVJ];
              return {
                filiale,
                verkaufsmenge: filCurrent,
                verkaufsmengeVJ: filPrev,
                differenz: filCurrent - filPrev,
                vjPercent: filPrev
                  ? (((filCurrent - filPrev) / filPrev) * 100).toFixed(2) +
                    '%'
                  : 'N/A',
              };
            });

          if (filteredFilials.length > 0 || totalCurrent > 0 || totalPrev > 0) {
            return {
              verkaufsgebiet,
              verkaufsmenge: totalCurrent,
              verkaufsmengeVJ: totalPrev,
              differenz,
              vjPercent,
              filials: filteredFilials,
            };
          }
          return null;
        })
        .filter((region) => region !== null);

      setSalesData(formattedData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Verkaufsdaten:', error);
    }
  };

  // Filter für Suchbegriff
  const filteredData = useMemo(() => {
    if (!searchTerm) return salesData;
    return salesData.filter(
      (region) =>
        region.verkaufsgebiet.toLowerCase().includes(searchTerm.toLowerCase()) ||
        region.filials.some((filiale) =>
          filiale.filiale.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
  }, [salesData, searchTerm]);

  // Ein-/Ausklappen der jeweiligen Verkaufsgebiete
  const handleExpandClick = (verkaufsgebiet) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [verkaufsgebiet]: !prevExpanded[verkaufsgebiet],
    }));
  };

  const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // CSV-Export
  const handleExportCSV = () => {
    const csvContent = [
      [
        'Verkaufsgebiet',
        'Filiale',
        displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz',
        displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ',
        'Differenz',
        'VJ %',
      ],
      ...salesData.flatMap((region) => [
        [
          region.verkaufsgebiet,
          '',
          region.verkaufsmenge,
          region.verkaufsmengeVJ,
          region.differenz,
          region.vjPercent,
        ],
        ...region.filials.map((filiale) => [
          '',
          filiale.filiale,
          filiale.verkaufsmenge,
          filiale.verkaufsmengeVJ,
          filiale.differenz,
          filiale.vjPercent,
        ]),
      ]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'table_data.csv');
  };

  // PDF-Export
  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      'Verkaufsgebiet',
      'Filiale',
      displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz',
      displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ',
      'Differenz',
      'VJ %',
    ];
    const tableRows = salesData.flatMap((region) => [
      [
        region.verkaufsgebiet,
        '',
        region.verkaufsmenge,
        region.verkaufsmengeVJ,
        region.differenz,
        region.vjPercent,
      ],
      ...region.filials.map((filiale) => [
        '',
        filiale.filiale,
        filiale.verkaufsmenge,
        filiale.verkaufsmengeVJ,
        filiale.differenz,
        filiale.vjPercent,
      ]),
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133] },
    });

    doc.save('table_data.pdf');
  };

  // Excel-Export
  const handleExportExcel = () => {
    // Vorbereitung der Daten als Array-Objekte
    const worksheetData = salesData.flatMap((region) => [
      {
        Verkaufsgebiet: region.verkaufsgebiet,
        Filiale: '',
        [displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz']:
          region.verkaufsmenge,
        [displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ']:
          region.verkaufsmengeVJ,
        Differenz: region.differenz,
        'VJ %': region.vjPercent,
      },
      ...region.filials.map((filiale) => ({
        Verkaufsgebiet: '',
        Filiale: filiale.filiale,
        [displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz']:
          filiale.verkaufsmenge,
        [displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ']:
          filiale.verkaufsmengeVJ,
        Differenz: filiale.differenz,
        'VJ %': filiale.vjPercent,
      })),
    ]);

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daten');
    XLSX.writeFile(workbook, 'tabelle_daten.xlsx');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={isFullscreen ? false : 'lg'}
      fullScreen={isFullscreen}
    >
      <DialogTitle>
        Verkaufsgebiet Details – {warengruppe}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <TextField
            label="Suchen"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
          />
          <Box>
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  handleExportCSV();
                  handleMenuClose();
                }}
              >
                <DownloadIcon sx={{ marginRight: 1 }} /> CSV exportieren
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleExportPDF();
                  handleMenuClose();
                }}
              >
                <PictureAsPdfIcon sx={{ marginRight: 1 }} /> PDF exportieren
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleExportExcel();
                  handleMenuClose();
                }}
              >
                <GridOnIcon sx={{ marginRight: 1 }} /> Excel exportieren
              </MenuItem>
            </Menu>
            <IconButton onClick={toggleFullscreen}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        </Box>

        <div className="db_styled_table">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Verkaufsgebiet</TableCell>
                  <TableCell>Filiale</TableCell>
                  <TableCell align="right">
                    {displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz'}
                  </TableCell>
                  <TableCell align="right">
                    {displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ'}
                  </TableCell>
                  <TableCell align="right">Differenz</TableCell>
                  <TableCell align="right">VJ %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((region, regionIndex) => (
                  <React.Fragment key={regionIndex}>
                    <TableRow>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <IconButton
                            onClick={() => handleExpandClick(region.verkaufsgebiet)}
                            size="small"
                          >
                            {expanded[region.verkaufsgebiet] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: expanded[region.verkaufsgebiet]
                                ? 'bold'
                                : 'normal',
                            }}
                          >
                            {region.verkaufsgebiet}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell />
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal',
                        }}
                      >
                        {region.verkaufsmenge.toLocaleString('de-DE')}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal',
                        }}
                      >
                        {region.verkaufsmengeVJ.toLocaleString('de-DE')}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal',
                        }}
                      >
                        {region.differenz.toLocaleString('de-DE')}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal',
                        }}
                      >
                        {region.vjPercent}
                      </TableCell>
                    </TableRow>

                    {expanded[region.verkaufsgebiet] &&
                      region.filials.map((filiale, filialeIndex) => (
                        <TableRow key={filialeIndex}>
                          <TableCell />
                          <TableCell>{filiale.filiale}</TableCell>
                          <TableCell align="right">
                            {filiale.verkaufsmenge.toLocaleString('de-DE')}
                          </TableCell>
                          <TableCell align="right">
                            {filiale.verkaufsmengeVJ.toLocaleString('de-DE')}
                          </TableCell>
                          <TableCell align="right">
                            {filiale.differenz.toLocaleString('de-DE')}
                          </TableCell>
                          <TableCell align="right">{filiale.vjPercent}</TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
}
