// components/popup/unternehmen/SalesRegionPopup.js

import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Box,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
    Close as CloseIcon,
    Fullscreen as FullscreenIcon,
    Download as DownloadIcon,
    PictureAsPdf as PictureAsPdfIcon,
    GridOn as GridOnIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import axiosInstance from '../../../services/axiosInstance';

// 1) Importiere den FilterContext:
import { FilterContext } from '../../../provider/FilterProvider';

export default function SalesRegionPopupRetoure({ open, onClose, warengruppe, jahrMonat }) {
    // 2) Context auslesen:
    const { filterState } = useContext(FilterContext);

    const [salesData, setSalesData] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            fetchSalesData();
        }
    }, [open]);

    const fetchSalesData = async () => {
        try {
            const previousYear = (parseInt(jahrMonat.split('.')[0]) - 1).toString() + '.' + jahrMonat.split('.')[1];

            // 3) Vorjahr-Flag einbinden (filterState.bestehendeFlaeche)
            const currentYearData = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
                params: {
                    warengruppe,
                    jahrMonat,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const previousYearData = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
                params: {
                    warengruppe,
                    jahrMonat: previousYear,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const salesMap = {};

            // Summe der Retouren, Lieferungen und Korrekturen für das aktuelle Jahr
            currentYearData.data.results.forEach(item => {
                const { verkaufsgebiet, filiale, retourmenge = 0, liefermenge = 0, korrekturmenge = 0 } = item;
                if (!salesMap[verkaufsgebiet]) {
                    salesMap[verkaufsgebiet] = {};
                }
                if (!salesMap[verkaufsgebiet][filiale]) {
                    salesMap[verkaufsgebiet][filiale] = {
                        retourmenge: 0,
                        liefermenge: 0,
                        korrekturmenge: 0,
                    };
                }
                salesMap[verkaufsgebiet][filiale].retourmenge += retourmenge;
                salesMap[verkaufsgebiet][filiale].liefermenge += liefermenge;
                salesMap[verkaufsgebiet][filiale].korrekturmenge += korrekturmenge;
            });

            // Summe der Retouren, Lieferungen und Korrekturen für das Vorjahr
            previousYearData.data.results.forEach(item => {
                const { verkaufsgebiet, filiale, retourmenge = 0, liefermenge = 0, korrekturmenge = 0 } = item;
                if (!salesMap[verkaufsgebiet]) {
                    salesMap[verkaufsgebiet] = {};
                }
                if (!salesMap[verkaufsgebiet][filiale]) {
                    salesMap[verkaufsgebiet][filiale] = {
                        retourmenge: 0,
                        liefermenge: 0,
                        korrekturmenge: 0,
                    };
                }
                // Vorjahresdaten könnten hier weiterverarbeitet werden, wenn benötigt
            });

            // Gesamtstruktur je Verkaufsgebiet formatieren
            const formattedData = Object.entries(salesMap)
                .map(([verkaufsgebiet, filials]) => {
                    const totalLiefermenge = Object.values(filials).reduce(
                        (acc, curr) => acc + curr.liefermenge,
                        0
                    );
                    const totalRetourmenge = Object.values(filials).reduce(
                        (acc, curr) => acc + curr.retourmenge,
                        0
                    );
                    const totalKorrekturmenge = Object.values(filials).reduce(
                        (acc, curr) => acc + curr.korrekturmenge,
                        0
                    );

                    const sumLieferKorrektur = totalLiefermenge + totalKorrekturmenge;
                    const retourQuote =
                        sumLieferKorrektur > 0
                            ? ((totalRetourmenge / sumLieferKorrektur) * 100).toFixed(2) + '%'
                            : '0.00%';

                    const filteredFilials = Object.entries(filials)
                        .filter(
                            ([, data]) =>
                                data.retourmenge > 0 ||
                                data.liefermenge > 0 ||
                                data.korrekturmenge > 0
                        )
                        .map(([filiale, data]) => {
                            const singleSum = data.liefermenge + data.korrekturmenge;
                            const filialeRetourQuote =
                                singleSum > 0
                                    ? ((data.retourmenge / singleSum) * 100).toFixed(2) + '%'
                                    : '0.00%';

                            return {
                                filiale,
                                liefermenge: data.liefermenge,
                                retourmenge: data.retourmenge,
                                korrekturmenge: data.korrekturmenge,
                                retourQuote: filialeRetourQuote,
                            };
                        });

                    if (
                        filteredFilials.length > 0 ||
                        totalLiefermenge > 0 ||
                        totalRetourmenge > 0 ||
                        totalKorrekturmenge > 0
                    ) {
                        return {
                            verkaufsgebiet,
                            liefermenge: totalLiefermenge,
                            retourmenge: totalRetourmenge,
                            korrekturmenge: totalKorrekturmenge,
                            retourQuote,
                            filials: filteredFilials,
                        };
                    }
                    return null;
                })
                .filter(region => region !== null);

            setSalesData(formattedData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Retourendaten:', error);
        }
    };

    // Suchfunktion
    const filteredData = useMemo(() => {
        if (!searchTerm) return salesData;
        return salesData.filter(region =>
            region.verkaufsgebiet.toLowerCase().includes(searchTerm.toLowerCase()) ||
            region.filials.some(filiale =>
                filiale.filiale.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [salesData, searchTerm]);

    const handleExpandClick = verkaufsgebiet => {
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [verkaufsgebiet]: !prevExpanded[verkaufsgebiet],
        }));
    };

    const toggleFullscreen = () => setIsFullscreen(prev => !prev);

    const handleMenuOpen = event => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    // CSV-Export
    const handleExportCSV = () => {
        const csvContent = [
            [
                'Verkaufsgebiet',
                'Filiale',
                'Liefermenge',
                'Retourmenge',
                'Korrekturmenge',
                'Retourenquote %',
            ],
            ...salesData.flatMap(region => [
                [
                    region.verkaufsgebiet,
                    '',
                    region.liefermenge,
                    region.retourmenge,
                    region.korrekturmenge,
                    region.retourQuote,
                ],
                ...region.filials.map(filiale => [
                    '',
                    filiale.filiale,
                    filiale.liefermenge,
                    filiale.retourmenge,
                    filiale.korrekturmenge,
                    filiale.retourQuote,
                ]),
            ]),
        ]
            .map(row => row.join(','))
            .join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'table_data.csv');
    };

    // PDF-Export
    const handleExportPDF = () => {
        const doc = new jsPDF();
        const tableColumn = [
            'Verkaufsgebiet',
            'Filiale',
            'Liefermenge',
            'Retourmenge',
            'Korrekturmenge',
            'Retourenquote %',
        ];
        const tableRows = salesData.flatMap(region => [
            [
                region.verkaufsgebiet,
                '',
                region.liefermenge,
                region.retourmenge,
                region.korrekturmenge,
                region.retourQuote,
            ],
            ...region.filials.map(filiale => [
                '',
                filiale.filiale,
                filiale.liefermenge,
                filiale.retourmenge,
                filiale.korrekturmenge,
                filiale.retourQuote,
            ]),
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [22, 160, 133] },
        });

        doc.save('table_data.pdf');
    };

    // Excel-Export
    const handleExportExcel = () => {
        const worksheetData = salesData.flatMap(region => [
            {
                Verkaufsgebiet: region.verkaufsgebiet,
                Filiale: '',
                Liefermenge: region.liefermenge,
                Retourmenge: region.retourmenge,
                Korrekturmenge: region.korrekturmenge,
                'Retourenquote %': region.retourQuote,
            },
            ...region.filials.map(filiale => ({
                Verkaufsgebiet: '',
                Filiale: filiale.filiale,
                Liefermenge: filiale.liefermenge,
                Retourmenge: filiale.retourmenge,
                Korrekturmenge: filiale.korrekturmenge,
                'Retourenquote %': filiale.retourQuote,
            })),
        ]);

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Daten');
        XLSX.writeFile(workbook, 'tabelle_daten.xlsx');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={isFullscreen ? false : 'lg'}
            fullScreen={isFullscreen}
        >
            <DialogTitle>
                Verkaufsgebiet Details – {warengruppe}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <TextField
                        label="Suchen"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        size="small"
                    />
                    <Box>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                            <MenuItem
                                onClick={() => {
                                    handleExportCSV();
                                    handleMenuClose();
                                }}
                            >
                                <DownloadIcon sx={{ marginRight: 1 }} /> CSV exportieren
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleExportPDF();
                                    handleMenuClose();
                                }}
                            >
                                <PictureAsPdfIcon sx={{ marginRight: 1 }} /> PDF exportieren
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleExportExcel();
                                    handleMenuClose();
                                }}
                            >
                                <GridOnIcon sx={{ marginRight: 1 }} /> Excel exportieren
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={toggleFullscreen}>
                            <FullscreenIcon />
                        </IconButton>
                    </Box>
                </Box>

                <div className="db_styled_table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Verkaufsgebiet</TableCell>
                                    <TableCell>Filiale</TableCell>
                                    <TableCell align="right">Liefermenge</TableCell>
                                    <TableCell align="right">Retourmenge</TableCell>
                                    <TableCell align="right">Korrekturmenge</TableCell>
                                    <TableCell align="right">Retourenquote %</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.map((region, regionIndex) => (
                                    <React.Fragment key={regionIndex}>
                                        <TableRow>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleExpandClick(region.verkaufsgebiet)
                                                        }
                                                        size="small"
                                                    >
                                                        {expanded[region.verkaufsgebiet] ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </IconButton>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            fontWeight: expanded[region.verkaufsgebiet]
                                                                ? 'bold'
                                                                : 'normal',
                                                        }}
                                                    >
                                                        {region.verkaufsgebiet}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell />
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontWeight: expanded[region.verkaufsgebiet]
                                                        ? 'bold'
                                                        : 'normal',
                                                }}
                                            >
                                                {region.liefermenge.toLocaleString('de-DE')}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontWeight: expanded[region.verkaufsgebiet]
                                                        ? 'bold'
                                                        : 'normal',
                                                }}
                                            >
                                                {region.retourmenge.toLocaleString('de-DE')}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontWeight: expanded[region.verkaufsgebiet]
                                                        ? 'bold'
                                                        : 'normal',
                                                }}
                                            >
                                                {region.korrekturmenge.toLocaleString('de-DE')}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontWeight: expanded[region.verkaufsgebiet]
                                                        ? 'bold'
                                                        : 'normal',
                                                }}
                                            >
                                                {region.retourQuote}
                                            </TableCell>
                                        </TableRow>

                                        {expanded[region.verkaufsgebiet] &&
                                            region.filials.map((filiale, filialeIndex) => (
                                                <TableRow key={filialeIndex}>
                                                    <TableCell />
                                                    <TableCell>{filiale.filiale}</TableCell>
                                                    <TableCell align="right">
                                                        {filiale.liefermenge.toLocaleString('de-DE')}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {filiale.retourmenge.toLocaleString('de-DE')}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {filiale.korrekturmenge.toLocaleString('de-DE')}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {filiale.retourQuote}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
    );
}
