import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';

// Helper function to format numbers
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
    .toFixed(fractionDigits)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const MainTableArtikelPopup = ({ open, onClose, filiale, warengruppe, filterState }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchData();
    }
    
  }, [open]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const jahrKw = filterState.datumWoche?.value;

      // API call with Filiale, Warengruppe, and jahrKw filters
      // Beispiel: '/scorecard_week_article/ware-artikel-woche/'
      // Hier nehmen wir 'verkaufsmenge' statt retourwert.
      const response = await axiosInstance.get('/scorecard_week_article/ware-artikel-woche/', {
        params: {
          filiale: filiale,
          warengruppe: warengruppe,
          jahrKw: jahrKw,
        },
      });

      // Verarbeitung der Daten
      const nestedPopupData = response.data.results.map((item, index) => {
        // Nehmen wir an, item enthält 'verkaufsmenge'
        const verkaufsmenge = formatNumber(item.verkaufsmenge || 0);

        // Falls du weitere Werte hast (z. B. Vorjahreswerte), kannst du sie hier ebenfalls ergänzen
        return {
          id: index,
          artikel: item.artikelnummer?.artikel || 'Unbekannt',
          abc: item.ABCValue || '',
          verkaufsmenge,
        };
      });

      // Definiere die gewünschten Spalten (z. B. Artikel, ABC, Verkaufsmenge, etc.)
      const nestedPopupColumns = [
        { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
        { accessorKey: 'abc', header: 'ABC', align: 'left' },
        {
          accessorKey: 'verkaufsmenge',
          header: 'Verkaufsmenge',
          align: 'right',
        },
      ];

      setData(nestedPopupData);
      setColumns(nestedPopupColumns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der verschachtelten Popup-Daten:', error);
      setLoading(false);
    }
  };

  return (
    <Popup
      open={open}
      title={`Artikel-Verkaufsmenge für Filiale ${filiale} und Warengruppe ${warengruppe}`}
      onClose={onClose}
      fullWidth
      maxWidth={false}
    >
      <BigTableCard data={data} columns={columns} loading={loading} />
    </Popup>
  );
};

export default MainTableArtikelPopup;
